import { type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

const Layer = tw.div`
  flex flex-col space-y-3
  w-full h-full
`;

const Label = tw.span`
text-white/80 font-sm
`;

const Content = tw.div`
  flex items-center
  w-full
  border border-white/10
  rounded-md
  bg-white/10
  text-white text-sm font-light
  p-4
`;

type Props = {
  title?: string;
  children: ReactNode;
};

export default function ContentBoxLayout({ title, children }: Props) {
  return (
    <Layer>
      {title ? <Label>{title}</Label> : null}
      <Content>{children}</Content>
    </Layer>
  );
}
