import { Perf } from 'r3f-perf';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
// import { useDispatch } from "react-redux";
import { SpineRenderCommon, SpineThreeJSCommon } from '../../../util/SpineRenderCommon';
import {
  Html,
  ContactShadows,
  Environment,
  useGLTF,
  OrbitControls,
  useProgress,
  Lightformer,
  useHelper,
  SoftShadows,
  PerspectiveCamera,
  AccumulativeShadows,
  RandomizedLight,
  Plane,
  RenderTexture,
  OrthographicCamera,
} from '@react-three/drei';

import {
  CustomLight,
  CustomEnv,
  CustomPostEffect,
  CustomPostEffectPowerBall,
} from './PowerBallEnvironment';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { LottomachineWhite } from './LottomachineWhite';
import { LottomachineRed } from './LottomachineRed';
import { Model } from './Lottomachine_background';
import { Model_Red } from './Lottomachine_background_red';


import { Kamdo } from './Kamdo';
import { PlaySound } from '../sound/soundPlayer';
import {
  useBettingNumberstore,
  useBettingStore,
  usePowerBallBettingStore,
  usePowerBallLotteryStore,
  usePowerBallResultTrigger,
  useResultStore,
} from '@/store/powerball';
// import GlassSampleRender from './glasssample'

import usePowerBallSocket from '../usePowerBallSocket';
import { getCookie, setCookie } from '../../../lib/cookie'

function Loader() {
  // console.log('@@@@loader runnubg/');
  // const { progress } = useProgress()
  // return <Html center>{progress} % loaded</Html>
  return <Html center></Html>;
}

let powerballinfo = null;
let isMobile = true;
export function PowerBallCanvas(props) {
  const { selectedTicketList, setSelectedTicketList } = usePowerBallLotteryStore();
  const curSFX = useSelector(state => state.curSFX);
  const curState = useSelector(state => state.curState);
  const Powerball = useSelector(state => state.Powerball);
  const [camPos, setcamPos] = useState([0, 1.1, 2]);
  const { openResultPopup, closeResultPopup } = useResultStore();
  const { ticketStore, setTicketStore } = usePowerBallBettingStore();
  const { callTrigger } = usePowerBallResultTrigger();

  useEffect(() => {
    isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  });

  useEffect(() => {
    // console.log('curState', curState);
    if (curState === 0) {
      // setfov(35);
      PlaySound();
    }
    if (curState === 1 || curState === 7) {
      // setfov(35);
      setcamPos([0, 1.1, 2]);
    } else if (curState === 6) {
      // setfov(15);
      setcamPos([0.65, 0.7, 0.6]);
    }

    if (curState === 7) {
      // && selectedTicketList !== undefined && selectedTicketList.length > 0)
      if (selectedTicketList !== undefined && selectedTicketList.length > 0) 
        openResultPopup();

      if (ticketStore !== undefined && Object.entries(ticketStore).length > 0) 
        openResultPopup();
      const timeout = 8000;
      setTimeout(() => {
        setSelectedTicketList([]);
        setTicketStore({});
        props.ReqOddsCondition(true);
        closeResultPopup();
        props.setRewardOddEven([]);
        callTrigger();
      }, timeout);
    }
  }, [curState]);

  useEffect(() => {
    if(Powerball)
    {
      powerballinfo = getCookie('prevgameinfo');
      powerballinfo = powerballinfo === "null" ? null : powerballinfo;
      if(powerballinfo)
      console.log('gameframe : ' + powerballinfo.gameframe + ' ' + Powerball.gameframe);
    }
  }, [Powerball]);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
        zIndex: '2',
        position: 'absolute',
      }}
    >
      {/* <SpineRenderCommon 
        path='powerball'
        file='back_image_1'
        aniName='bg'
      ></SpineRenderCommon>                */}
      {/* <SpineThreeJSCommon
        path='powerball'
        file='back_image_1'
        aniName='bg'>
      </SpineThreeJSCommon> */}

      <Canvas
        shadows
        gl={{
          preserveDrawingBuffer: true,
          shadows: true,
          dpr: [1, 1.5],
          clearColor: '#000000',
          // gl.clearColor(0, 0, 0, 0);
        }}
      >
        {/* <SpineRenderCommon 
        path='powerball'
        file='back_image_1'
        aniName='bg'
      ></SpineRenderCommon>                */}

        {/* <Perf position="bottom-left" style={{ margin: 10 }} /> */}
        {/* <color attach="background" args={["#fef4ef"]} /> */}

        {!isMobile && (
          <group classname="pc">
            <CustomPostEffect></CustomPostEffect>
          </group>
        )}
        <CustomLight 
          curState = {curState}
          ambientIntensity = {0.6}>
        </CustomLight>

        {/* <CustomOrbit></CustomOrbit> */}

        {/* <GlassSampleRender></GlassSampleRender> */}
        {/* <Kamdo position={[0,0.0,0]} scale={[0.2,0.2,0.2]} rotation={[0, Math.PI*0.5, 0]} /> */}

        <Suspense fallback={<Loader />}>
          <CustomGeometrys
            // regularNumbers={props.regularNumbers} powerballNumber={props.powerballNumber}
            // callUseDispatch = {props.callUseDispatch}
            Powerball={powerballinfo}
            curState={curState}
            curSFX={curSFX}
            CallDispatch={props.CallDispatch}
            CallDispatchSFX={props.CallDispatchSFX}
            gameType={props.gameType}
            navigate={props.navigate}
          ></CustomGeometrys>
        </Suspense>

        <PerspectiveCamera makeDefault position={camPos} fov={37} />
      </Canvas>
    </div>
  );
}

function CustomGeometrys(props) {
  const machineScale1 = 1.3;
  const machineScale2 = 1.3;
  const machinePos1 = [-0.65, -0.58, 0];
  const machinePos2 = [0.65, -0.58, 0];
  // useFrame(()=>{
  //   console.log('test');
  // })

  // let testpos = [-0.5, 1.0, 0];
  const backScale = 1.0;
  return (
    <group>
      {
      // <Model position={[0, -0.0, 0]} scale={[backScale, backScale, backScale]}></Model>
        props.gameType===0?
        <Model position={[0, -0.0, 0]} scale={[backScale, backScale, backScale]}></Model>:
        <Model_Red position={[0, -0.0, 0]} scale={[backScale, backScale, backScale]}></Model_Red>
      }
      
      <LottomachineWhite
        // name = 'modelLeft'
        position={machinePos1}
        scale={[machineScale1, machineScale1, machineScale1]}
        color="#ffffff"
        dispatch={true}
        callUseDispatch={props.callUseDispatch}
        curState={props.curState}
        curSFX={props.curSFX}
        CallDispatch={props.CallDispatch}
        CallDispatchSFX={props.CallDispatchSFX}
        Powerball={props.Powerball}
        navigate={props.navigate}
        // regularNumbers={props.regularNumbers} powerballNumber={props.powerballNumber}
      ></LottomachineWhite>
      <LottomachineRed
        // name = 'modelRight'
        position={machinePos2}
        scale={[machineScale2, machineScale2, machineScale2]}
        color="#ffffff"
        dispatch={false}
        callUseDispatch={props.callUseDispatch}
        curState={props.curState}
        curSFX={props.curSFX}
        CallDispatch={props.CallDispatch}
        CallDispatchSFX={props.CallDispatchSFX}
        Powerball={props.Powerball}
        // regularNumbers={props.regularNumbers} powerballNumber={props.powerballNumber}
      ></LottomachineRed>
    </group>
  );
}

function LottoMachineRenderTarget(props) {
  let wRate = 2.2;
  let hRate = 0.33;
  let rtRate = 18;
  let rect = {
    right: rtRate * wRate,
    left: -rtRate * wRate,
    bottom: -rtRate * hRate,
    top: rtRate * hRate,
  };

  let planeRate = 4;
  let planeXY = props.showBall ? [wRate * planeRate, hRate * planeRate] : [0, 0];
  const targetObject = new THREE.Object3D();
  targetObject.position.set(0, 0, 10);

  const camref = useRef();
  const state = useThree();

  useFrame(() => {});
  return (
    <group>
      <mesh
        position={[-0.05, -2.7, 0.0]}
        // scale={[scale, scale / widthRate ,0.1]}
      >
        <planeGeometry args={planeXY} />
        <meshStandardMaterial>
          <RenderTexture attach="map" anisotropy={16}>
            <OrthographicCamera
              ref={camref}
              makeDefault
              zoom={130}
              position={[0.08, -0.11, 3]}
              right={rect.right}
              left={rect.left}
              bottom={rect.bottom}
              top={rect.top}
            />

            {/* <CustomEnv background={false}>
              {!isMobile && <group classname='pc'>
                <CustomPostEffect></CustomPostEffect>
                <CustomLight></CustomLight>
              </group>} */}
            <CustomEnv></CustomEnv>
            <LottomachineWhite
              rotation={[Math.PI * 0.5, -0.05, 0]}
              position={[0, 0, 0]}
              scale={[1, 1, 1]}
              Powerball={props.Powerball}
            ></LottomachineWhite>
            {props.refCanvs && props.refCanvs.current}
          </RenderTexture>
        </meshStandardMaterial>
      </mesh>
    </group>
  );
}

export function PowerBallZoomCanvas(props) {
  const curState = useSelector(state => state.curState);
  const Powerball = useSelector(state => state.Powerball);

  useEffect(() => {
    if(Powerball)
    {      
      powerballinfo = getCookie('prevgameinfo');
      powerballinfo = powerballinfo === "null" ? null : powerballinfo;
      if(powerballinfo)
      console.log('gameframe : ' + powerballinfo.gameframe + ' ' + Powerball.gameframe);
    }    
  }, [Powerball]);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
        zIndex: '5',
        position: 'absolute',
      }}
    >
      <Canvas
        shadows
        gl={{
          preserveDrawingBuffer: true,
          shadows: true,
          dpr: [1, 1.5],
        }}
      >
        <Suspense fallback={<Loader />}>
          <LottoMachineRenderTarget
            // regularNumbers={props.regularNumbers} powerballNumber={props.powerballNumber}
            Powerball={powerballinfo}
            curState={curState}
            showBall={curState < props.whiteballShowState && curState > 0} // 6, 8
            refCanvs={props.refCanvs}
          ></LottoMachineRenderTarget>
        </Suspense>
        <CustomLight
          ambientIntensity = {1.2}
        ></CustomLight>
        
        {/* <CustomPostEffect2></CustomPostEffect2> */}
        
      </Canvas>
    </div>
  );
}

export default PowerBallCanvas;