import { useState, useEffect } from 'react';

import tw from 'tailwind-styled-components';
import { ReactComponent as ArrowLineLeftIcon } from '@/img/ArrowLineLeftIcon.svg';
import useLang from '../lib/hook/useLang';

const Container = tw.div`
  flex flex-row items-center justify-between w-full max-[640px]:w-fit
  ${({ $isEven }) => ($isEven ? 'bg-[#493156]' : 'bg-[#5F4B6C]')}
`;

const Content = tw.div`
  flex flex-row justify-center items-center
  py-[10px] px-[16px] max-[1180px]:px-2
  text-white text-[14px] font-normal
  min-[1800px]:w-full
  max-[640px]:w-[74px] max-[1180px]:first:!pr-2 
  text-center uppercase
  
`;

const listIndexType0 = [2, 4, 8];

export default function GameHistoryItem({
  item: { index, date, raceNumber, selected, rank, type, betting_value, reward_value, odds },
  rawGameHistories: { totalCount },
}) {
  const { lngT, currentLang } = useLang('mypage:gameHistory');
  const [isExpanded, setIsExpanded] = useState(false);
  // const struct = jsonToStruct(info);

  const [rowsMy, setRowsMy] = useState([]);
  const [rowsWin, setRowsWin] = useState([]);
  const [rowsWinner, setRowsWinner] = useState([]);

  const listLine = [
    // 'Odd',
    // 'Even',
    // 'Under',
    // 'Over',
    // 'Odd / Under',
    // 'Odd / Over',
    // 'Even / Under',
    // 'Even / Over',
    // 'Odd / Small',
    // 'Odd / Medium',
    // 'Odd / Large',
    // 'Even / Small',
    // 'Even / Medium',
    // 'Even / Large',
    lngT('contents:odd'), //'odd',
    lngT('contents:even'), //'even',
    lngT('contents:under'), //'under',
    lngT('contents:over'), //'over',
    lngT('contents:oddUnder'), //'odd under',
    lngT('contents:oddOver'), //'odd over',
    lngT('contents:evenUnder'), //'even under',
    lngT('contents:evenOver'), //'even over',
    lngT('contents:oddSmall'), //'odd small',
    lngT('contents:oddMedium'), //'odd medium',
    lngT('contents:oddLarge'), //'odd large',
    lngT('contents:evenSmall'), //'even small',
    lngT('contents:evenMedium'), //'even medium',
    lngT('contents:evenLarge'), //'even large',
  ];

  // console.log('item', index, selected, betting_value, reward_value, rank);

  useEffect(() => {
    let newRowsMy = [];
    let newRowsWin = [];
    let newRowsWinner = [];
    const rankArray = JSON.parse(rank);
    if (totalCount !== null) {
      for (let i = 0; i < index.length; i++) {
        //for (let n = 0; n < props.userbettingresult[i].length; n++) {
        let strNumber = selected;
        if (type === 'oddeven') {
          if (strNumber > 0 && strNumber < 15) {
            strNumber = listLine[strNumber - 1];
          } else {
            strNumber = 'odd';
          }
        } else if (type === 'single') {
          strNumber = strNumber + `-${lngT('contents:win')}`; //`${strNumber} - ${lngT('contents:win')}`; //WIN`;
        } else if (type === 'show') {
          strNumber = strNumber + `-${lngT('contents:show')}`; //`${strNumber} - ${lngT('contents:show')}`; //SHOW`;
        }
        newRowsMy.push({
          type: type,
          bet: strNumber,
        });
        //}
      }
    }
    if (rankArray !== null) {
      for (let i = 0; i < index.length; i++) {
        //for (let n = 0; n <; props.userbettingresult[i].length; n++) {
        const winOdd = rankArray[0] % 2 === 1 ? /*'ODD'*/ lngT('contents:odd') : /*'EVEN'*/ lngT('contents:even');
        const winUnder = rankArray[0] <= 6 ? /*'UNDER'*/ lngT('contents:under') : /*'OVER'*/ lngT('contents:over');
        const winOddUnder =
          rankArray[0] % 2 === 1
            ? rankArray[0] <= 6
              ? /*'ODD UNDER'*/ lngT('contents:oddUnder')
              : /*'ODD OVER'*/ lngT('contents:oddOver')
            : rankArray[0] <= 6
            ? /*'EVEN UNDER'*/ lngT('contents:evenUnder')
            : /*'EVEN OVER'*/ lngT('contents:evenOver');
        const winOddSmall =
          rankArray[0] % 2 === 1
            ? rankArray[0] <= 4
              ? /*'ODD SMALL'*/ lngT('contents:oddSmall')
              : rankArray[0] <= 8
              ? /*'ODD MEDIUM'*/ lngT('contents:oddMedium')
              : /*'ODD LARGE'*/ lngT('contents:oddLarge')
            : rankArray[0] <= 4
            ? /*'EVEN SMALL'*/ lngT('contents:evenSmall')
            : rankArray[0] <= 8
            ? /*'EVEN MEDIUM'*/ lngT('contents:evenMedium')
            : /*'EVEN LARGE'*/ lngT('contents:evenLarge');
        const winSingle = `${rankArray[0]}-${lngT('contents:win')}`;
        const winShow = `${rankArray[0]}.${rankArray[1]}.${rankArray[2]}-${lngT('contents:show')}`;
        //}
        newRowsWin.push({
          winOdd: winOdd,
          winUnder: winUnder,
          winOddUnder: winOddUnder,
          winOddSmall: winOddSmall,
          winSingle: winSingle,
          winShow: winShow,
        });
        let winnerResult;
        if (type === 'oddeven') {
          if (selected <= listIndexType0[0]) {
            winnerResult = winOdd;
          } else if (selected <= listIndexType0[1]) {
            winnerResult = winUnder;
          } else if (selected <= listIndexType0[2]) {
            winnerResult = winOddUnder;
          } else {
            winnerResult = winOddSmall;
          }
        } else if (type === 'single') {
          winnerResult = winSingle;
        } else if (type === 'show') {
          winnerResult = winShow;
        } else {
          winnerResult = ''; // Set a default value if needed.
        }

        newRowsWinner.push({ winner: winnerResult });
      }
    }
    setRowsMy(newRowsMy);
    setRowsWin(newRowsWin);
    setRowsWinner(newRowsWinner);
  }, [rank, currentLang]);

  // console.log('rowsMy', rowsMy);
  // console.log('rowsWin', rowsWin);
  console.log('rowsWinner', rowsWinner[0]);

  return (
    <Container $isEven={!(Number(index) % 2)}>
      <Content className="w-[100px] max-[640px]:w-[40px]">{index}</Content>
      <Content className="w-[400px]">{date}</Content>
      <Content className="w-[100px]">{raceNumber === null ? '-' : raceNumber}</Content>
      <Content className="w-[300px]">{rowsMy[0]?.bet}</Content>
      <Content className="w-[300px]">{rowsWinner[0]?.winner}</Content>
      {/* <Content className="w-[300px]">
        {' '}
        {type === 0
          ? rank[0] <= listIndexType0[0]
            ? rowsWin[0].winOdd
            : rank[0] <= listIndexType0[1]
            ? rowsWin[0].winUnder
            : rank[0] <= listIndexType0[2]
            ? rowsWin[0].winOddUnder
            : rowsWin[0].winOddSmall
          : type === 1
          ? rowsWin[0].winSingle
          : type === 2
          ? rowsWin[0].winShow
          : ''}
      </Content> */}
      {/* <Content className={`w-[300px] ${rowsMy[0]?.bet == rowsWinner[0]?.winner ? 'text-[#84FFE9]' : 'text-[#FF5656]'}`}> */}
      <Content className={`w-[300px] ${reward_value ? 'text-[#84FFE9]' : 'text-[#FF5656]'}`}>
        {/* {rowsMy[0]?.bet == rowsWinner[0]?.winner ? lngT('success') : lngT('fail')} */}
        {reward_value ? lngT('success') : lngT('fail')}
      </Content>
      <Content className="w-[250px]">
        <div className="w-[40px] flex justify-end">{betting_value}</div>
      </Content>
      <Content className="w-[250px]">
        {/* {reward_value / betting_value === 0 ? odds : (reward_value / betting_value).toFixed(2)} */}
        {odds.toFixed(2)}
      </Content>
      <Content className="w-[250px]">
        <div className="w-[50px] flex justify-end">{reward_value === null ? '-' : reward_value.toFixed(1)}</div>
      </Content>

      {/* <button
        className="flex flex-col py-[10px] px-[16px] text-white text-[14px] font-normal min-[1800px]:w-full w-[300px]"
        onClick={toggleDropdown}
      >
        <div className="flex flex-row justify-between items-center w-[90px]">
          {ExtractType(struct)}
          <ArrowLineLeftIcon className={`${isExpanded ? 'rotate-90' : 'rotate-[270deg]'}`} />
        </div>
        {isExpanded ? (
          <Content className="text-start px-0 text-white/60" style={{ 'white-space': 'pre-line' }}>
            {Tojsonstring(struct)}
          </Content>
        ) : (
          ''
        )}
      </button> */}
    </Container>
  );
}

function jsonToStruct(json) {
  let obj;

  try {
    obj = JSON.parse(json);
  } catch (e) {
    // console.error(e);
  }

  const struct = {};
  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'object') {
      struct[key] = value; //jsonToStruct(value);
    } else {
      if (Array.isArray(value)) {
        struct[key] = value.map(v => jsonToStruct(v));
      } else {
        struct[key] = value;
      }
    }
  }

  return struct;
}

// function Tojsonstring(items) {
//   var jsonstring = '';

//   for (var item in items) {
//     if (typeof items[item] === 'object') {
//       jsonstring += Tojsonstring(items[item]);
//     } else {
//       if (item === 'type') {
//         if (items[item] === 'stake') {
//           jsonstring += 'Lease\n';
//         }
//       } else if (items[item] === 'stakeType') {
//         switch (items[item]) {
//           case 1:
//             jsonstring += 'Regular\n';
//             break;
//           case 2:
//             jsonstring += 'Silver\n';
//             break;
//           case 3:
//             jsonstring += 'Gold\n';
//             break;
//           default:
//             jsonstring += '';
//         }
//       } else jsonstring += ``;
//     }

//     return jsonstring;
//   }
// }

function Tojsonstring(items) {
  var jsonstring = '';

  for (var item in items) {
    if (typeof items[item] === 'object') {
      jsonstring += Tojsonstring(items[item]);
    } else {
      if (item === 'type') {
        if (items[item] === 'stake') {
          jsonstring += '';
        }
      } else if (item === 'stakeType') {
        switch (items[item]) {
          case 1:
            jsonstring = 'Regular';
            break;
          case 2:
            jsonstring = 'Silver';
            break;
          case 3:
            jsonstring = 'Gold';
            break;
          default:
            jsonstring += '';
        }
        jsonstring = 'Type : ' + jsonstring;
      } else jsonstring += item.toUpperCase() + ' : ' + items[item] + '\n';
    }
  }
  return jsonstring;
}

// function Tojsonstring(items) {
//   var jsonstring = '';

//   for (var item in items) {
//     if (typeof items[item] === 'object') {
//       jsonstring += Tojsonstring(items[item]);
//     } else {
//       if (item === 'type') {
//         jsonstring += ``;
//       } else jsonstring += item.toUpperCase() + ' : ' + items[item] + '\n';
//     }
//   }

//   return jsonstring;
// }

function ExtractType(items) {
  for (var item in items) {
    if (item === 'type') {
      if (items[item] === 'stake') {
        return 'LEASE';
      }
      return `${items[item].toUpperCase()}`;
    }
    if (typeof items[item] === 'object') {
      const result = ExtractType(items[item]);
      if (result) {
        return result;
      }
    }
  }
  return null; // 해당 값이 없을 경우 null 반환
}
