import React, { useEffect, Component } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  PaginationItem,
  Stack,
  LinearProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './style/Histroy';
import { ReactComponent as ArrowBackIcon } from '../../img/ArrowBackIcon.svg';
import { ReactComponent as ArrowForwardIcon } from '../../img/ArrowForwardIcon.svg';
import { ReactComponent as ArrowLineLeftIcon } from '../../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowLineRightIcon } from '../../img/ArrowLineRightIcon.svg';
import { ReactComponent as WarningIcon } from '../../img/WarningIcon.svg';
import { ReactComponent as SuccessFilledIcon } from '../../img/SuccessFilledIcon.svg';
// import { ReactComponent as BettingIcon } from '../../img/BettingIcon.svg';
import HashDialog from '../../horsestall/progresscombine/HashDialog';
import CommonDialog from '../../components/betting/CommonDialog-v1';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

// import { GetAddHttpURL, SafeLog, ChangeProgress } from '../../util/jsUtil';
import { ChangeProgress_combine } from './jsUtil_combine';
import * as config from '../../config';
import useUser from '../../lib/hook/useUser';

import { getCookie } from '../../lib/cookie';
import { ChangeLanguage, Translation } from '../../components/Translation';

import useLang from '../../lib/hook/useLang';
import i18n from '../../lang/i18n';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
// import { useDispatch  } from "react-redux";
var { webSocketIO } = require('../../socketClient.js');

//function createData(no, time, classe, action, hash) {
//    return {no, time, classe, action, hash};
//  }

//  const rows = [
//    createData(123, '07-06 12:20:00', 'Random', 'replay', 'hash'),
//    createData(124, '07-06 12:20:00', 'Random', 'betting', 'hash'),
//    createData(125, '07-06 12:20:00', 'Random', 'live', 'hash'),
//    createData(126, '07-06 12:20:00', 'Random', 'live', 'hash'),
//    createData(127, '07-06 12:20:00', 'Random', 'ready', 'hash'),
//    createData(128, '07-06 12:20:00', 'Random', 'ready', 'hash'),
//    createData(129, '07-06 12:20:00', 'Random', 'ready', 'hash'),
//    createData(130, '07-06 12:20:00', 'Random', 'ready', 'hash'),
//    createData(131, '07-06 12:20:00', 'Random', 'ready', 'hash'),
//    createData(132, '07-06 12:20:00', 'Random', 'ready', 'hash'),
//  ];

function createData(id, index, time, rarity, track, progress, action, applied, block, hash) {
  return {
    id,
    index,
    time,
    rarity,
    track,
    progress,
    action,
    applied,
    horseList: [], //horseList
    block,
    hash,
  };
}

export default function History(props) {
  // const dispatch = useDispatch();
  const { lngT } = useLang('racing:timetable');

  const classes = useStyles();
  const [openHashDialog, setOpenHashDialog] = React.useState({
    open: false,
    block: 0,
    hash: '',
    progress: {},
  });
  const [openBetDialog, setOpenBetDialog] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);

  const [user] = useUser();
  const navigate = useCustomNavigate();

  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleClickOpenHashDialog = (block, hash, game, progress) => {
    setOpenHashDialog({
      open: true,
      block: block,
      hash: hash,
      progress: {
        game: game,
        userName: user?.name,
        progress: progress,
        lang: i18n.language,
      },
    });
  };
  const handleHashDialogClose = () => {
    setOpenHashDialog({
      ...openHashDialog,
      open: false,
    });
  };

  const handleClickOpenBetDialog = () => {
    setOpenBetDialog(true);
  };

  const handleClickOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleClose = () => {
    setOpenHashDialog(false);
    setOpenBetDialog(false);
    setOpenSuccess(false);
  };

  const handleChangePage = (event, newPage) => {
    webSocketIO.data.pageCurrent = newPage;
    setPage(newPage);
    getRaceList(newPage, rowsPerPage, false);
  };

  const handleChangeRowsPerPage = event => {
    webSocketIO.data.rowsPerPageCurrent = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getRaceList(0, parseInt(event.target.value, 10), false);
  };

  const getRaceList = (newPage, newRowsPerPage, force) => {
    var currentRows = rows;

    var option = '';
    if (webSocketIO.data.tabTimeTable === 1) {
      //option = 'mylist';
      //currentRows = rowsMy;
    }

    if (force) {
      currentRows = []; //new Array(rows.length);
    }
    var doReq = false;
    if (currentRows.length > 0) {
      for (var n = newPage * newRowsPerPage; n < (newPage + 1) * newRowsPerPage; n++) {
        if (currentRows.length <= n) {
          break;
        }
        if (currentRows[n] === undefined) {
          doReq = true;
          break;
        }
      }
      if (doReq === false) {
        return;
      }
    }

    // var token = Gettoken();
    // console.log('token : ' + user?.name, config.LOG_DEVELOPER_1);
    var url = config.GetPrefixURL() + config.GetBetServerURL();
    fetch(
      `${url}/api/v1/racelist?start=${newPage * newRowsPerPage}&end=${(newPage + 1) * newRowsPerPage}&user=${
        user?.name
      }&option=${option}`,
      {
        method: 'GET', // 또는 'PUT'
        headers: {
          'Content-Type': 'application/json',

          //"User": token2,
        },
        //body: JSON.stringify(params),
      }
    )
      .then(response => {
        //console.log("json : " + response);
        var jsondata = response.json();
        return jsondata;
      })
      .then(data => {
        console.log('racelist ' + JSON.stringify(data), config.LOG_DEVELOPER_1);

        var newRows = new Array(data.Total);
        for (var n = 0; n < currentRows.length; n++) {
          if (currentRows[n] === undefined) {
            continue;
          }
          newRows[n] = currentRows[n];
        }
        for (var n = 0; n < newRowsPerPage; n++) {
          if (newPage * newRowsPerPage + n >= data.Total) {
            break;
          }
          var action = '';
          if (data.RaceList[n].Progress === 0) {
            action = 'ready';
          } else if (data.RaceList[n].Progress === 1 || data.RaceList[n].Progress === 2) {
            action = 'betting';
          } else if (data.RaceList[n].Progress === 3) {
            action = 'live';
          } else if (data.RaceList[n].Progress === 4) {
            action = 'replay';
          } else {
            action = 'canceled';
          }
          var strTimeUTC = moment(data.RaceList[n].TimeRace).format('YYYY-MM-DD HH:mm:ss');
          strTimeUTC = strTimeUTC.replace(/-/g, '/');
          var time_utc = new Date(strTimeUTC + ' UTC');
          var time = moment(time_utc).format('MM-DD HH:mm:ss');

          newRows[newPage * newRowsPerPage + n] = createData(
            data.RaceList[n].ID,
            data.RaceList[n].index,
            time,
            data.RaceList[n].Rarity,
            data.RaceList[n].RaceTrack,
            '',
            action,
            data.RaceList[n].MyHorse === 1 ? true : false,
            data.RaceList[n].block,
            data.RaceList[n].hash
          );
        }
        if (webSocketIO.data.tabTimeTable === 1) {
          //setRowsMy(newRows);
        } else {
          setRows(newRows);
          console.log(newRows);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getRaceListInterval = () => {
    var option = '';
    if (webSocketIO.data.tabTimeTable == 1) {
      getRaceList(webSocketIO.data.pageCurrentMy, webSocketIO.data.rowsPerPageCurrentMy, true);
    } else {
      getRaceList(webSocketIO.data.pageCurrent, webSocketIO.data.rowsPerPageCurrent, true);
    }
    setTimeout(function () {
      getRaceListInterval();
    }, 1000 * 30);
  };

  const getRaceListInfo = () => {
    var url = config.GetPrefixURL() + config.GetBetServerURL();
    console.log(url);
    console.log('url : ' + url, config.LOG_DEVELOPER_1);
    fetch(`${url}/api/v1/racelistinfo`, {
      method: 'GET', // 또는 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(params),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log('racelistinfo : ' + JSON.stringify(data), config.LOG_DEVELOPER_1);

        webSocketIO.data.pageCurrent = page;
        webSocketIO.data.rowsPerPageCurrent = rowsPerPage;
        var newPage = Math.floor((data.Now - 1) / rowsPerPage);
        if (newPage > 0) {
          webSocketIO.data.pageCurrent = newPage;
          setPage(newPage);
        }
        getRaceListInterval();
        console.log('datanow' + data.Now);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (!user) navigate('/login');
  }, [user]);

  useEffect(() => {
    getRaceListInfo();
    var urlparam = getCookie('urlparam');
  }, []);

  const clickAction = async id => {
    var url = config.GetPrefixURL() + config.GetBetServerURL();
    console.log(`IEnumUTOSracedetail ClickAction1 id:${id}`);
    fetch(`${url}/api/v1/raceprogress?id=${/*props.row.id*/ id}`, {
      method: 'GET', // 또는 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(params),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log('raceprogress data : ' + data, config.LOG_DEVELOPER_1);

        if (data.Progress === 1 || data.Progress === 2 || data.Progress === 3 || data.Progress === 4) {
          console.log(`IEnumUTOSracedetail ClickAction2 id:${id}`);

          ChangeProgress_combine(id, user?.name, data.Progress, i18n.language, true, 0);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <Box className={classes.root}>
      <Container className={classes.container} maxWidth={'false'}>
        <Typography variant="h3" className={classes.titleStyle}>
          {/*Title*/ lngT('titleHistory')}
        </Typography>
        <Table className={classes.tableStyle}>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="11%">
                {/*NO*/ lngT('header:no:title')}
              </TableCell>
              <TableCell align="center" width="24%">
                {/*TIME*/ lngT('header:time:title')}
              </TableCell>
              <TableCell align="center" width="24%">
                {/*CLASS*/ lngT('header:class:title')}
              </TableCell>
              <TableCell align="center" width="24%">
                {/*ACTION*/ lngT('header:action:title')}
              </TableCell>
              <TableCell align="center" width="17%">
                HASH
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
              //row.action === 'replay' && (
              <TableRow key={row.index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width="11%" align="center" component="th" scope="row">
                  {row.index}
                </TableCell>
                <TableCell width="24%" align="center">
                  {row.time}
                </TableCell>
                <TableCell width="24%" align="center">
                  {/*'random'*/ lngT('header:class:random')}
                </TableCell>
                <TableCell width="24%" align="center">
                  {row.action === 'replay' && (
                    <Button
                      className={clsx(classes.buttonStyle, classes.outlineGradientStyle)}
                      onClick={() => {
                        clickAction(row.id);
                      }}
                      disableRipple
                    >
                      <span>{/*Replay*/ lngT('header:action:replay')}</span>
                    </Button>
                  )}
                  {row.action !== 'replay' && <span>-</span>}
                </TableCell>
                <TableCell width="17%" align="center">
                  <Button
                    onClick={() => {
                      handleClickOpenHashDialog(row.block, row.hash, row.id, 4);
                    }}
                    className={classes.buttonStyle2}
                    disabled={row.action !== 'replay' ? true : false}
                    disableRipple
                  >
                    {/*row.hash*/ 'hash'}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={Math.floor(rows.length / 10)}
          page={page}
          onChange={handleChangePage}
          shape="rounded"
          showFirstButton
          showLastButton
          className={classes.paginationStyle}
          renderItem={item => (
            <PaginationItem
              slots={{
                first: ArrowForwardIcon,
                previous: ArrowLineLeftIcon,
                next: ArrowLineRightIcon,
                last: ArrowBackIcon,
              }}
              {...item}
            />
          )}
        />

        {/* 해시 다얼로그 */}
        <HashDialog open={openHashDialog} onClose={handleHashDialogClose} />
        {/* 해시 다얼로그 */}

        {/* Betting */}
        <CommonDialog
          open={openBetDialog}
          onClose={handleClose}
          objectImg={<WarningIcon />}
          children={
            <Typography>
              Cancellation is not possible after placing a bet. <br /> Do you want to bet?
            </Typography>
          }
          cancel={'Cancel'}
          submit={'Confirm'}
        />

        {/* Success */}
        <CommonDialog
          open={openSuccess}
          onClose={handleClose}
          objectImg={<SuccessFilledIcon />}
          closeButton={true}
          title={'Success'}
          children={<Typography>Congraturation, Successfully betting! </Typography>}
          submit={'Confirm'}
        />

        {/* betting 중 */}
        <CommonDialog
          open={openSuccess}
          onClose={handleClose}
          // objectImg={<BettingIcon />}
          closeButton={true}
          title={'Betting'}
          children={<LinearProgress className={classes.progressBar} />}
        />
      </Container>
    </Box>
  );
}
