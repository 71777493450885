import React, { ReactNode, useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

type QueryMeta = {
  paginate?: {
    currentPage: number;
    perPage: number;
    totalCount: number;
  };
  sortBy?: 'ASC' | 'DESC';
  filterBy?: {
    [key: string]: string;
  };
  includes?: string;
  date?: {
    startDate: Date;
    endDate: Date;
  };
};

type ResWithError = {
  message: string;
  error: string;
  statusCode: number;
};

type ResWithQueryMeta<T> = {
  meta?: QueryMeta;
  data: T;
};

type Res<T> = ResWithQueryMeta<T> | ResWithError;

type Props<T> = {
  url: string;
  withCredential?: boolean;
  callback?: (data: T) => void;
};

interface CointainerProps<T> {
  children: ({
    data,
    isLoading,
    error,
    refresh,
  }: {
    data?: ResWithQueryMeta<T>;
    isLoading: boolean;
    error?: ResWithError;
    refresh: () => void;
  }) => ReactNode;
}

export default function genFetchContainer<T>({ url, withCredential = true, callback }: Props<T>) {
  const Container = React.memo(function Continer({ children }: CointainerProps<T>) {
    const { data: res, isLoading, mutate } = useSWRImmutable<Res<T>>(url, fetcher(withCredential));
    const [data, setData] = useState<ResWithQueryMeta<T>>();
    const [error, setError] = useState<ResWithError>();

    const refresh = () => mutate();

    useEffect(() => {
      if (res) {
        if (res.hasOwnProperty('message')) {
          const error = res as ResWithError;
          setError(error);
        } else {
          const data = res as ResWithQueryMeta<T>;
          setData(data);
          callback?.(data.data);
        }
      }
    }, [res]);

    useEffect(() => {
      mutate();
    }, [mutate]);

    return (
      <>
        {children({
          data,
          isLoading,
          error,
          refresh,
        })}
      </>
    );
  });

  return Container;
}

const fetcher = (withCredential: boolean) => (url: string) =>
  fetch(url, { credentials: withCredential ? 'include' : undefined })
    .then(r => r.json())
    .catch(e => console.log(e));
