import Notice from '../../../components/Notice';
import BannerSection from './Banner';
import BoxHowToDoSection from './BoxHowToDo';
import CtaSection from './CTA';
import DividerBanner from './DividerBanner';
import DividerBannerBlack from './DividerBannerBlack';
import FAQSection from './FAQ';

import Header from './Header';
import styles from './Mobile.module.css';
import VideoSection from './Video';
import VideoBoxIncomeSection from './VideoBoxIncome';
import BoxBonusSection from './boxBonus';
import BoxIncomeSection from './boxIncome';

const LandingPageMobile = () => {
  return (
    <main className={styles.container}>
      <Notice />
      <Header />
      <div className="bg-white flex flex-col space-y-[120px]">
        <BannerSection />
        <CtaSection />
      </div>
      <DividerBanner />
      <VideoBoxIncomeSection />
      <BoxHowToDoSection />
      <BoxIncomeSection />
      <DividerBanner />
      <VideoSection />
      {/* <BoxBonusSection /> */}
      <FAQSection />
      <BoxBonusSection />
      {/* <Footer /> */}
    </main>
  );
};

export default LandingPageMobile;
