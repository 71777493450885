import React, { useState, useEffect, Component } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  PaginationItem,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles/TablesStyles';
import { ReactComponent as ArrowBackIcon } from '../../img/ArrowBackIcon.svg';
import { ReactComponent as ArrowForwardIcon } from '../../img/ArrowForwardIcon.svg';
import { ReactComponent as ArrowLineLeftIcon } from '../../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowLineRightIcon } from '../../img/ArrowLineRightIcon.svg';

import * as config from '../../config';

import useLang from '../../lib/hook/useLang';
// import { SafeLog } from '../../util/jsUtil';

function createData(no, user, winner, odds, reward, number) {
  return { no, user, winner, odds, reward, number };
}

const rows = [
  createData('Odd Over', 'sally', 'Random', '3.6', '190', ''),
  createData('single', 'sally', 'Random', '3.6', '190', 2),
];

const listIndexType0 = [2, 4, 8];

function Row(props) {
  const { lngT } = useLang('racing:result');
  const { lngResultT } = useLang('racing:result');

  const classes = useStyles();

  const { row } = props;
  const listLine = [
    lngT('contents:odd'), //'odd',
    lngT('contents:even'), //'even',
    lngT('contents:under'), //'under',
    lngT('contents:over'), //'over',
    lngT('contents:oddUnder'), //'odd under',
    lngT('contents:oddOver'), //'odd over',
    lngT('contents:evenUnder'), //'even under',
    lngT('contents:evenOver'), //'even over',
    lngT('contents:oddSmall'), //'odd small',
    lngT('contents:oddMedium'), //'odd medium',
    lngT('contents:oddLarge'), //'odd large',
    lngT('contents:evenSmall'), //'even small',
    lngT('contents:evenMedium'), //'even medium',
    lngT('contents:evenLarge'), //'even large',
  ];

  const winOdd = props.rank[0] % 2 === 1 ? /*'ODD'*/ lngT('contents:odd') : /*'EVEN'*/ lngT('contents:even');
  const winUnder = props.rank[0] <= 6 ? /*'UNDER'*/ lngT('contents:under') : /*'OVER'*/ lngT('contents:over');
  const winOddUnder =
    props.rank[0] % 2 === 1
      ? props.rank[0] <= 6
        ? /*'ODD UNDER'*/ lngT('contents:oddUnder')
        : /*'ODD OVER'*/ lngT('contents:oddOver')
      : props.rank[0] <= 6
      ? /*'EVEN UNDER'*/ lngT('contents:evenUnder')
      : /*'EVEN OVER'*/ lngT('contents:evenOver');
  const winOddSmall =
    props.rank[0] % 2 === 1
      ? props.rank[0] <= 4
        ? /*'ODD SMALL'*/ lngT('contents:oddSmall')
        : props.rank[0] <= 8
        ? /*'ODD MEDIUM'*/ lngT('contents:oddMedium')
        : /*'ODD LARGE'*/ lngT('contents:oddLarge')
      : props.rank[0] <= 4
      ? /*'EVEN SMALL'*/ lngT('contents:evenSmall')
      : props.rank[0] <= 8
      ? /*'EVEN MEDIUM'*/ lngT('contents:evenMedium')
      : /*'EVEN LARGE'*/ lngT('contents:evenLarge');
  const winSingle = (
    <>
      <span className={classes.numberStyle}>{props.rank[0]}</span> {` - ${lngT('contents:single')}`}
    </>
  ); //` - ${lngT('contents:single')}`; //`${props.rank[0]} - ${lngT('contents:single')}`; //SINGLE`
  const winShow = (
    <>
      <span className={classes.numberStyle}>{props.rank[0]}</span>
      <span className={classes.numberStyle}>{props.rank[1]}</span>
      <span className={classes.numberStyle}>{props.rank[2]}</span>
      {` - ${lngT('contents:show')}`}
    </>
  ); //` - ${lngT('contents:show')}`; //`${props.rank[0]}, ${props.rank[1]}, ${props.rank[2]} - ${lngT('contents:show')}`; //SHOW`

  return (
    <>
      {/*
        <TableRow className={row.applied === true ? clsx(classes.trStyle, classes.trActiveStyle) : classes.trStyle}>
          <TableCell
            align="center"
            className={classes.tdRed}
            onClick={function () {
              //props.handleClickOpen(props.index);
            }}
          >
            <Box>{row.number}</Box>
          </TableCell>
          <TableCell align="center">
            <Box className={classes.tdYellow}>{row.name}</Box>
          </TableCell>
  
          <TableCell align="center">
            <Box className={classes.tdSky}>
              {row.type === 0
                ? row.no <= listIndexType0[0]
                  ? winOdd
                  : row.no <= listIndexType0[1]
                  ? winUnder
                  : row.no <= listIndexType0[2]
                  ? winOddUnder
                  : winOddSmall
                : row.type === 1
                ? winSingle
                : row.type === 2
                ? winShow
                : ''}
            </Box>
          </TableCell>
  
          <TableCell align="center">
            <Box className={classes.tdBlue}>{row.odds}</Box>
          </TableCell>
          <TableCell align="center">
            <Box className={classes.tdPurple}>{row.reward}</Box>
          </TableCell>
        </TableRow>
        */}
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell width="20%" align="center" component="th" scope="row">
          {/*row.no === 'single' &&
                            <>
                             <span className={classes.numberStyle}>{row.number}</span> -
                            </>
                        }
                    {row.no*/}
          {row.number}
        </TableCell>
        <TableCell width="20%" align="center">
          {row.name}
        </TableCell>

        <TableCell width="20%" align="center">
          {row.type === 0
            ? row.no <= listIndexType0[0]
              ? winOdd
              : row.no <= listIndexType0[1]
              ? winUnder
              : row.no <= listIndexType0[2]
              ? winOddUnder
              : winOddSmall
            : row.type === 1
            ? winSingle
            : row.type === 2
            ? winShow
            : ''}
        </TableCell>

        <TableCell width="20%" align="center">
          {row.odds}
        </TableCell>
        <TableCell width="20%" align="center">
          {row.reward}
        </TableCell>
      </TableRow>
    </>
  );
}

export default function WinnersTable(props) {
  const { lngT } = useLang('racing:result');

  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rowsMy, setRowsMy] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    //webSocketIO.data.pageCurrent = newPage;
    setPage(newPage);
    //getRaceList(newPage, rowsPerPage, false);
  };

  const handleChangeRowsPerPage = event => {
    //webSocketIO.data.rowsPerPageCurrent = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    //getRaceList(0, parseInt(event.target.value, 10), false);
  };

  const listLine = [
    lngT('contents:odd'), //'odd',
    lngT('contents:even'), //'even',
    lngT('contents:under'), //'under',
    lngT('contents:over'), //'over',
    lngT('contents:oddUnder'), //'odd under',
    lngT('contents:oddOver'), //'odd over',
    lngT('contents:evenUnder'), //'even under',
    lngT('contents:evenOver'), //'even over',
    lngT('contents:oddSmall'), //'odd small',
    lngT('contents:oddMedium'), //'odd medium',
    lngT('contents:oddLarge'), //'odd large',
    lngT('contents:evenSmall'), //'even small',
    lngT('contents:evenMedium'), //'even medium',
    lngT('contents:evenLarge'), //'even large',
  ];

  useEffect(() => {
    console.log('USEEFFECT LOAD', config.LOG_DEVELOPER_1);
    var newRows = [];
    if (props.bettingresult == null) return;

    for (let i = 0; i < props.bettingresult.length; i++) {
      for (let n = 0; n < props.bettingresult[i].length; n++) {
        let strNumber = props.bettingresult[i][n].no;
        if (props.bettingresult[i][n].type === 0) {
          //if (strNumber === 1) {
          //    strNumber = 'ODD';
          //} else {
          //    strNumber = 'EVEN';
          //}
          if (strNumber >= 1 && strNumber <= 14) {
            strNumber = listLine[strNumber - 1];
          } else {
            strNumber = 'ODD';
          }
        } else if (props.bettingresult[i][n].type === 1) {
          strNumber = (
            <>
              <span className={classes.numberStyle}>{strNumber}</span> {` - ${lngT('contents:single')}`}
            </>
          ); //`${strNumber} - ${lngT('contents:single')}`; //SINGLE`;
        } else if (props.bettingresult[i][n].type === 2) {
          strNumber = (
            <>
              <span className={classes.numberStyle}>{strNumber}</span> {` - ${lngT('contents:show')}`}
            </>
          ); //`${strNumber} - ${lngT('contents:show')}`; //SHOW`;
        }
        newRows.push({
          type: props.bettingresult[i][n].type,
          no: props.bettingresult[i][n].no,
          number: strNumber,
          name: props.bettingresult[i][n].nameUser,
          odds: Math.floor(props.bettingresult[i][n].odds * 1000) / 1000,
          reward: Math.floor(props.bettingresult[i][n].reward * 1000) / 1000,
        });
      }
    }
    setRows(newRows);

    /*var newRowsMy = [];
      if (props.userbettingresult !== null) {
        for (let i = 0; i < props.userbettingresult.length; i++) {
          //for (let n = 0; n < props.userbettingresult[i].length; n++) {
          let strNumber = props.userbettingresult[i].no;
          if (props.userbettingresult[i].type === 0) {
            //if (strNumber === 1) {
            //    strNumber = 'ODD';
            //} else {
            //    strNumber = 'EVEN';
            //}
            if (strNumber >= 1 && strNumber <= 14) {
              strNumber = listLine[strNumber - 1];
            } else {
              strNumber = 'ODD';
            }
          } else if (props.userbettingresult[i].type === 1) {
            strNumber = `${strNumber} - ${lngT('contents:single')}`; //SINGLE`;
          } else if (props.userbettingresult[i].type === 2) {
            strNumber = `${strNumber} - ${lngT('contents:show')}`; //SHOW`;
          }
          newRowsMy.push({
            type: props.userbettingresult[i].type,
            no: props.userbettingresult[i].no,
            number: strNumber,
            name: props.userbettingresult[i].value,
            odds: Math.floor(props.userbettingresult[i].odds * 1000) / 1000,
            reward: Math.floor(props.userbettingresult[i].reward * 1000) / 1000,
          });
          //}
        }
      }
      setRowsMy(newRowsMy);*/

    return () => {
      console.log('USEEFFECT UNLOAD', config.LOG_DEVELOPER_1);
    };
  }, [props]);

  return (
    <Box className={classes.root}>
      <Table className={classes.tableStyle} sx={{ minWidth: 320 }}>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="20%">
              {/*BET*/ lngT('header:bet:title')}
            </TableCell>
            <TableCell align="center" width="20%">
              {/*USER*/ lngT('header:user:title')}
            </TableCell>
            <TableCell align="center" width="20%">
              {/*WINNER*/ lngT('header:winner:title')}
            </TableCell>
            <TableCell align="center" width="20%">
              {/*ODDS*/ lngT('header:odds:title')}
            </TableCell>
            <TableCell align="center" width="20%">
              {/*REWARD*/ lngT('header:reward:title')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/*rows.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell width="20%" align="center" component="th" scope="row">
                        {row.no === 'single' &&
                            <>
                             <span className={classes.numberStyle}>{row.number}</span> -
                            </>
                        }
                        {row.no}
                    </TableCell>
                    <TableCell width="20%" align="center">{row.user}</TableCell>
                    <TableCell width="20%" align="center">{row.winner}</TableCell>
                    <TableCell width="20%" align="center">{row.odds}</TableCell>
                    <TableCell width="20%" align="center">{row.reward}</TableCell>
                    </TableRow>
                    ))*/}
          {/* Empty */}
          {/* <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                        <TableCell width="100%" colSpan={5} align="center" component="th">
                            <Typography className={classes.empty}>EMPTY</Typography>
                        </TableCell>
                    </TableRow> */}
          {/* Empty */}
          {
            rows.length > 0 ? (
              rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map(function (row, index) {
                if (row !== undefined) {
                  return <Row key={index} row={row} index={(page - 1) * rowsPerPage + index} rank={props.rank} />;
                } else {
                  return <></>;
                }
              })
            ) : (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell width="100%" colSpan={5} align="center" component="th">
                  <Typography className={classes.empty}>{/*EMPTY*/ lngT('empty')}</Typography>
                </TableCell>
              </TableRow>
            )
            // <Box className={classes.empty}>Empty</Box>
          }
        </TableBody>
      </Table>
      <Pagination
        count={Math.floor(rows.length / 10)}
        page={page}
        onChange={handleChangePage}
        shape="rounded"
        showFirstButton
        showLastButton
        className={classes.paginationStyle}
        renderItem={item => (
          <PaginationItem
            slots={{
              first: ArrowForwardIcon,
              previous: ArrowLineLeftIcon,
              next: ArrowLineRightIcon,
              last: ArrowBackIcon,
            }}
            {...item}
          />
        )}
      />
    </Box>
  );
}
