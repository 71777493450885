import { useState, useEffect } from 'react';
import tw from 'tailwind-styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../img/ArrowLineRightIcon.svg';
import HistoryBg from '../../img/powerball/b_bettinghistory_bg.png';
import HistoryClose from '../../img/powerball/b_bettinghistory_close.png';
import { usePowerModalStore } from '@/store/powerball';
import NumberHash from './NumberHash';

import { useLang } from '../../lib/hook';

import BettingHistoryTable from './component/betting/BettingHistoryTable';

const InfoContainer = tw.div`
  relative z-10
  w-full rounded-2xl
  max-[984px]:w-full
  overflow-x-hidden
`;
const InfoHeader = tw.div`
  relative
  w-full h-[50px]
  flex flex-row justify-center items-center
`;

const InfoTitleText = tw.div`
text-white text-base font-medium tracking-wider
uppercase
max-[420px]:text-sm
`;

const ArrowWrap = tw.div`
  w-[90%] absolute flex flex-row justify-between
`;

export default function BettingHistoryBoard({ historyOddEven, resPowerballFromHash }) {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [numberPadWidth, setNumberPadWidth] = useState(0);
  const [bettingHistory, setBettingHistory] = useState([]);
  const [blockHash, setBlockHash] = useState({
    block: 0,
    hash: '',
  });
  const { isHashModalOpen, openHashModalPopup } = usePowerModalStore();
  const [historyTypeIndex, setHistoryTypeIndex] = useState(0);
  const [gameType, setGameType] = useState('betting');
  const { lngT } = useLang('powerball');

  // console.log('resPowerballFromHash', resPowerballFromHash);
  const handleClickOpenHash = index => {
    openHashModalPopup();
    setGameType('betting');
    
    console.log('hash set1 : ' + JSON.stringify(resPowerballFromHash));
    // console.log('hash set1 : ' + JSON.stringify(bettingHistory[index]?.ball));
    

    setBlockHash({
      block: bettingHistory[index]?.block,
      hash: bettingHistory[index]?.hash,
      ball: bettingHistory[index]?.ball,
      powerBall: bettingHistory[index]?.powerball,
      // checkHash: resPowerballFromHash,
    });    
  };

  useEffect(()=>{
    if(resPowerballFromHash.balls === undefined )
      return;

    console.log('hash set1 : ' + JSON.stringify(resPowerballFromHash));

    const ballsSum = resPowerballFromHash.balls.reduce((a, b)=>a + b, 0);        
    
    setBlockHash({
      block: blockHash.block,
      hash: blockHash.hash,
      ball: [ballsSum % 2 === 1 ? 'O' : 'E', ballsSum > 77 ? 'O' : 'U', ballsSum < 70 ? 'S' : ballsSum < 86 ? 'M' : 'L'],
      powerBall: resPowerballFromHash.powerBall % 2 === 1 ? 'O' : 'E',
      // checkHash: resPowerballFromHash,
    });        

  },[resPowerballFromHash])

  useEffect(() => {
    const handleResize = () => {
      const newWidth = document.body.offsetWidth;
      setNumberPadWidth(newWidth);
      console.log('newWidth', newWidth);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [numberPadWidth]);

  const handleClickShowHistory = () => {
    if (numberPadWidth < 1426) {
      setIsHistoryOpen(!isHistoryOpen);
    }
  };

  useEffect(() => {
    if (numberPadWidth > 1426) {
      setIsHistoryOpen(true);
    } else {
      setIsHistoryOpen(false);
    }
  }, [numberPadWidth]);

  useEffect(() => {
    let newBettingHistory = [];

    if (historyOddEven !== null) {
      for (let i = 0; i < historyOddEven.length; i++) {
        const ballTotal = historyOddEven[i].ball.reduce((acc, curr) => acc + curr, 0);
        const powerballTotal = historyOddEven[i].powerBall;
        const ballOdd = ballTotal % 2 === 1 ? 'O' : 'E';
        const ballUnder = ballTotal <= 77 ? 'U' : 'O';
        const ballSmall = ballTotal <= 69 ? 'S' : ballTotal <= 85 ? 'M' : 'L';
        const powerballOdd = powerballTotal % 2 === 1 ? 'O' : 'E';
        const newBettingData = {
          ball: [ballOdd, ballUnder, ballSmall],
          powerball: powerballOdd,
          hash: historyOddEven[i].hash,
          block: historyOddEven[i].block,
          index: historyOddEven[i].index,
        };
        newBettingHistory.push(newBettingData);
      }
    }
    setBettingHistory(newBettingHistory);
  }, [historyOddEven]);

  return (
    <div className="relative flex flex-row max-[984px]:flex-col justify-evenly items-center">
      <img
        alt="HistoryBg"
        src={`${isHistoryOpen ? HistoryBg : HistoryClose}`}
        className="w-full h-full absolute top-0 z-1"
      />
      <InfoContainer className={`${isHistoryOpen ? 'h-full' : ''}`}>
        <InfoHeader>
          <div className="absolute z-10 w-[200px] h-[50px]" onClick={handleClickShowHistory}></div>
          <ArrowWrap>
            <ArrowLeftIcon onClick={() => historyTypeIndex > 0 && setHistoryTypeIndex(historyTypeIndex - 1)} />
            <ArrowRightIcon onClick={() => historyTypeIndex < 4 && setHistoryTypeIndex(historyTypeIndex + 1)} />
          </ArrowWrap>
          <InfoTitleText>
            {historyTypeIndex === 0
              ? /*History */ lngT('betting:history:title')
              : historyTypeIndex === 1
              ? /*Ball Odd / Even */ lngT('betting:history:ballOdd')
              : historyTypeIndex === 2
              ? /*Ball Under/ Over*/ lngT('betting:history:ballUnder')
              : historyTypeIndex === 3
              ? /*Ball Small / Medium  Large*/ lngT('betting:history:ballSmall')
              : historyTypeIndex === 4
              ? /*Power ball Odd / Even*/ lngT('betting:history:powerballOdd')
              : ''}
          </InfoTitleText>
        </InfoHeader>
        <div
          className={`${
            isHistoryOpen ? 'flex' : 'hidden'
          } flex-row max-[960px]:flex-col items-center justify-center gap-5`}
        >
          <div className={`${historyTypeIndex === 0 ? 'flex' : 'hidden'} `}>
            <LatestHistoryTable handleClickOpenHash={handleClickOpenHash} bettingHistory={bettingHistory} />
          </div>
          <div className={`${historyTypeIndex === 0 ? 'hidden' : 'flex'}`}>
            <BettingHistoryTable historyTypeIndex={historyTypeIndex}/>
          </div>
          
        </div>
        {isHashModalOpen && <NumberHash open={isHashModalOpen} blockHash={blockHash} gameType={gameType} />}
      </InfoContainer>
    </div>
  );
}

const LatestHistoryTable = ({ handleClickOpenHash, bettingHistory }) => {
  const { lngT } = useLang('powerball');
  return (
    <div>
      <div className="overflow-x-auto w-full max-[960px]:w-full max-[486px]:w-[96%]  max-[486px]:px-2 flex flex-col flex-wrap items-center justify-center my-2 mx-4 h-[320px] max-[960px]:flex-nowrap max-[960px]:justify-start custom-scrollbar max-[486px]:mx-2">
        {bettingHistory.map((history, index) => (
          <table
            key={index}
            className="w-[424px] text-sm text-left text-white font-medium max-[960px]:w-full max-[486px]:w-[320px] max-[340px]:w-[270px]"
          >
            {index === 0 || index === 5 ? (
              <thead
                className={`text-xs uppercase pb-1 ${index === 5 ? 'max-[960px]:hidden' : ''}`}
                style={{ fontWeight: 'lighter' }}
              >
                <tr className="border-b border-white/30 w-[90%]">
                  <th scope="col" className="w-[80px] py-2 text-center max-[486px]:w-4 max-[486px]:text-xs ">
                    {/* Round*/ lngT('lottery:history:round')}
                  </th>
                  <div className="text-center flex flex-row justify-center items-center pl-6 py-2 w-[260px] max-[486px]:w-[180px] max-[486px]:gap-8 max-[486px]:pl-4">
                    <th scope="col" className="text-center w-[120px] max-[486px]:w-[80px]">
                      {lngT('lottery:pad:whiteball')}
                    </th>
                    <th scope="col" className="text-center w-[100px] max-[486px]:px-0 max-[486px]:w-[80px]">
                      {lngT('lottery:pad:powerball')}
                    </th>
                  </div>
                  <th scope="col" className="text-center px-4 py-2 max-[486px]:px-1">
                    {/*  Hash */ lngT('lottery:history:hash')}
                  </th>
                </tr>
              </thead>
            ) : (
              ''
            )}
            <tbody>
              <tr className="pb-2">
                <td className="w-[80px] py-2 text-center max-[486px]:text-xs max-[486px]:px-0">{history.index}</td>
                <td className="w-[260px] py-2 flex flex-row justify-center items-center gap-14 max-[486px]:w-[180px] max-[486px]:gap-4">
                  <div className="flex flex-row items-center gap-2">
                    {history.ball.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-6 h-6 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-black"
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                  <div className="max-[486px]:mx-6 w-6 h-6 rounded-full flex flex-col items-center justify-center border border-red-500 bg-red-500/10 text-red-500 roudned-full bg-white">
                    {history.powerball}
                  </div>
                </td>
                <td className="px-6 py-2 text-center max-[486px]:px-1">
                  <button
                    onClick={() => handleClickOpenHash(index)}
                    className="w-14 h-7 py-1 rounded-lg justify-center items-center gap-2.5 inline-flex hover:brightness-75 bg-white text-gray-500 max-[486px]:text-xs max-[486px]:w-10"
                  >
                    {/*  Hash */ lngT('lottery:history:hash')}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </div>
    </div>
  );
};
