import styles from './video.module.css';
import Logo from '@/img/newLogo.png';
import Plataform from '@/img/plataforma.png';
import useLang from '@/lib/hook/useLang';

const VideoSection = () => {
  const { lngT } = useLang('landingPage:faq');
  return (
    <section className={styles.container}>
      <div className={styles.video}>
        <iframe
          src="https://www.youtube.com/embed/BZIssQ_1iV4?autoplay=1"
          title="YouTube video"
          allow="accelerometer; 
                    clipboard-write; encrypted-media; 
                    gyroscope;"
          allowFullScreen
          className={styles.video}
        ></iframe>
      </div>
      <div className={styles.description}>
        <img src={Logo} width={100} height={100} alt={'logo white'} />
        <p>{lngT('video:phrase:1')}</p>
        <img className={styles.plataform} width={380} src={Plataform} alt={''} />
      </div>
      <div className={styles.description}>
        <h3>
          <p>{lngT('video:title:1')}</p>{' '}
          <b>
            {' '}
            <p>{lngT('video:title:2')}</p>
          </b>
        </h3>
        <p>
          {lngT('video:phrase:2')} <br />
          {lngT('video:phrase:3')} <br />
          {lngT('video:phrase:4')} <br />
          {lngT('video:phrase:5')}
        </p>
      </div>
    </section>
  );
};

export default VideoSection;
