import { useState } from 'react';
import tw from 'tailwind-styled-components';
import useLang from '../lib/hook/useLang';

const Container = tw.div`
  flex flex-row
  ${({ $isEven }) => ($isEven ? 'bg-[#493156]' : 'bg-[#5F4B6C]')}
`;

const Content = tw.div`
  flex flex-row items-center
  py-[10px] px-[16px]
  text-white text-[14px] font-normal
  
`;

export default function ChargeHistoryItem({
  index,
  paymentType,
  exchangeRate,
  chargePoint,
  transaction_amount,
  status,
  qrCodeBase64,
  created,
  expired,
}) {
  const { lngT } = useLang('mypage:account');
  const [isShowQR, setShowQR] = useState(false);

  return (
    <div>
      {isShowQR && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-black/50 z-[99]">
          <img className="w-[200px] h-[200px]" src={`data:image/jpeg;base64,${qrCodeBase64}`} alt="" />
          <div className="text-white text-[20px] cursor-pointer" onClick={() => setShowQR(false)}>
            close
          </div>
        </div>
      )}
      <Container $isEven={!(Number(index) % 2)}>
        <Content className="w-[100px]">{index}</Content>
        <Content className="w-[180px]">{paymentType}</Content>
        <Content className="w-[180px]">{Math.ceil(exchangeRate * 100) / 100}</Content>
        <Content className="w-[180px]">{chargePoint}</Content>
        <Content className="w-[180px]">{transaction_amount}</Content>
        <Content className={`w-[180px] ${status === 'pending' ? 'text-red-400' : 'text-teal-400'}`}>
          {status === 'pending' ? lngT('history:pending') : lngT('history:approved')}
        </Content>
        <Content className="w-[180px]">
          <button className="text-white" onClick={() => setShowQR(true)}>
            {lngT('history:showQR')}
          </button>
        </Content>
        <Content className="w-[220px]">{new Date(created).toISOString().replace('T', ' ').slice(0, -5)}</Content>
        <Content className="w-[220px]">{new Date(expired).toISOString().replace('T', ' ').slice(0, -5)}</Content>
      </Container>
    </div>
  );
}
