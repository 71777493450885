import { useEffect, useState } from 'react';
import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { useTranslation } from 'react-i18next';

import { WEBSERVERPORT } from '../../config';
import useLang from '../../lib/hook/useLang';

import DrawerMenu from '../../components/DrawerMenu_faq';

const Container = tw.div`
  flex flex-col
  w-full
`;

export default function FAQ() {
  const { lngT } = useLang('contact:faq');
  const { i18n } = useTranslation();
  const { data, isLoading, error } = useSWR(`${process.env.REACT_APP_API_URL}/faq`);

  console.log(data);
  const [menus, setMenus] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null); // Change undefined to null
  const [filteredItems, setFilteredItems] = useState([]);

  const handleCategoryClick = categoryId => {
    if (selectedCategoryId === categoryId) {
      setSelectedCategoryId(null); // Toggle off if the same category is clicked
    } else {
      setSelectedCategoryId(categoryId);
    }
  };

  useEffect(() => {
    const lang = i18n.language;

    if (data) {
      const { faqCategories, faqItems } = data.faqElements;
      const menus = faqCategories?.map(category => ({
        categoryId: category.id,
        title: category.title[lang],
        onClick: () => handleCategoryClick(category.id),
      }));

      setMenus([
        {
          categoryId: null,
          title: lngT('allfaq'),
          onClick: () => handleCategoryClick(null), // Handle "All FAQ" click
        },
        ...menus,
      ]);

      if (selectedCategoryId === null) {
        setFilteredItems(faqItems);
      } else {
        const filteredItems = faqItems.filter(item => item.categoryId === selectedCategoryId);
        setFilteredItems(filteredItems);
      }
    }
  }, [data, i18n.language, selectedCategoryId]);

  return (
    <Container className="w-full D:px-[64px]">
      <div className="hidden D:flex flex-row D:justify-between items-center text-[24px] font-medium text-white T:pb-[24px]">
        <div className="text-[20px]">{lngT('title')}</div>
        {data && (
          <DrawerMenu
            className="w-full h-[50px] mt-[15px] T:mt-0 max-w-[200px] T:px-[20px]"
            menus={menus}
            selectedText={
              selectedCategoryId ? menus.find(menu => menu.categoryId === selectedCategoryId).title : lngT('allfaq')
            }
            handleCategoryClick={handleCategoryClick}
          />
        )}
      </div>
      <div className="hidden T:flex flex-row border-y-2 border-white/20 py-[8px] text-white/90 text-[14px] font-medium uppercase">
        <div className="flex justify-center items-center w-[80px]">{lngT('header:no')}</div>
        <div className="flex flex-1 justify-center items-center">{lngT('header:title')}</div>
        <div className="flex justify-center items-center w-[150px]">{lngT('header:category')}</div>
      </div>
      <div className="flex flex-col">
        {filteredItems?.map(({ categoryId, question, answer }, i) => (
          <FAQItem
            key={i}
            index={i + 1}
            categoryName={menus.find(menu => menu.categoryId === categoryId)?.title}
            question={question[i18n.language]}
            answer={answer[i18n.language]}
          />
        ))}
      </div>
    </Container>
  );
}

const FAQItem = ({ index, categoryName, question, answer }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="flex flex-col border-b border-white/20 cursor-pointer" onClick={() => setOpen(!isOpen)}>
      <div className="flex flex-row text-white hover:bg-[#62218d]/10 bg-[#1C012E]">
        <div className="hidden T:flex justify-center items-center w-[80px] text-[12px]">{index}</div>
        <div className="flex flex-row space-x-2 py-[16px] px-[10px] T:flex-1">
          <span className="font-bold pr-3">Q</span>
          <div className="flex flex-row items-center font-normal">{question}</div>
        </div>
        <div className="hidden T:flex justify-center items-center w-[150px] text-[14px] text-white/80">
          {categoryName}
        </div>
      </div>
      {isOpen && (
        <div className="flex flex-row pl-4 T:pl-[88px] pr-[30px] py-[12px] space-x-2 T:space-x-[20px] bg-white/10 text-white/90">
          <span className="font-bold">A</span>
          <div className="flex flex-row items-center font-light">{answer}</div>
        </div>
      )}
    </div>
  );
};
