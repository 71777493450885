import { create } from 'zustand';

interface CoindropState {
  isGamePopupOpen: boolean;
  openGamePopup: () => void;
  closeGamePopup: () => void;
}

export const useCoindropStore = create<CoindropState>(set => ({
  isGamePopupOpen: false,
  openGamePopup: () => set({ isGamePopupOpen: true }),
  closeGamePopup: () => set({ isGamePopupOpen: false }),
}));
