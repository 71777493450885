export const BETTING_PROGRESS_CLOSED = 0;
export const BETTING_PROGRESS_HORSE_INTRODUCE_UNITY = 1; // 2번이 안 오면 막아준다
export const BETTING_PROGRESS_BETTING = 2;
export const BETTING_PROGRESS_BETTING_CLOSING = 3; // progress 3번인데 remaintime 0이하면 막아버린다
export const BETTING_PROGRESS_GAME_PLAYING_UNITY = 4; // 3초동안 요청 패킷 보내는것 하지 말 것 시상 끝나면 풀 모드 끄고 미리 받아둔 베팅 결과 보여준다. 그 때부터는 3초패킷 보낸다
export const BETTING_PROGRESS_GAME_CLOSING = 5;

export const GAMEPLAY_EVNETINDEX = 14;
export const horseCount = 12;
export const oddevencount = 2;
export const contractLength = 10;
export const betendwaitdelay = 60;
export const GAMEPLAY_TIME = 150;
export const USE_SAFELOG = false;

// 테스트 후 삭제 할 변수
export const gameIndex = 3386;
export const eventIndex = GAMEPLAY_EVNETINDEX;
export default contractLength;

// PROGRESS
export const PROGRESS_MAINLOBBY = 0; // 베팅이 열리지 않은 상태
export const PROGRESS_INTRO1 = 1;
export const PROGRESS_INTRO2 = 2;
export const PROGRESS_LIVE = 3;
export const PROGRESS_REPLAY = 4;
export const PROGRESS_RESULT = 5;
export const PROGRESS_LOBBYGAME = 10; // 문제가 있을 때 로비로 보낸다 : 사용 X. 0번과 기능이 겹침.
export const PROGRESS_SIGN = 20; // 문제가 있을 때 로그인 창으로 보낸다

// BETTYPE
export const BETTYPE_ODDEVEN = 0;
export const BETTYPE_SINGLE = 1;
export const BETTYPE_SHOW = 2;
export const RESULTPAGE_WAITING_DELAY = 8000;

// ODDEVEN BETTYPE
export const ODDEVEN_BETTYPE_ODD = 1;
export const ODDEVEN_BETTYPE_EVEN = 2;
export const ODDEVEN_BETTYPE_UNDER = 3;
export const ODDEVEN_BETTYPE_OVER = 4;
export const ODDEVEN_BETTYPE_ODDUNDER = 5;
export const ODDEVEN_BETTYPE_ODDOVER = 6;
export const ODDEVEN_BETTYPE_EVENUNDER = 7;
export const ODDEVEN_BETTYPE_EVENOVER = 8;
export const ODDEVEN_BETTYPE_ODDSMALL = 9;
export const ODDEVEN_BETTYPE_ODDMEDIUM = 10;
export const ODDEVEN_BETTYPE_ODDLARGE = 11;
export const ODDEVEN_BETTYPE_EVENSMALL = 12;
export const ODDEVEN_BETTYPE_EVENMEDIUM = 13;
export const ODDEVEN_BETTYPE_EVENLARGE = 14;
export const SHOW_BETTING_BALANCE = true;
export const FILE_SERVER_URL = ''; //'https://metacross-brazil-s3-bucket.s3.sa-east-1.amazonaws.com';

export const settings = [];
settings['outside_01'] = {
  horseScale: 11,
  lightPosition: [-7.1, 64.3, 30],
  shadowlightPosition: [-35.5, 321.5, 150],
  horserotation: [0, -1.9, 0],
  horseposition: [-3, 0, 5],
};

// complete
settings['outside_02'] = {
  horseScale: 12,
  lightPosition: [-24, 34, -12],
  shadowlightPosition: [-120, 170, -60],
  horserotation: [0, Math.PI / 2 - 0.8 - 0.7, 0],
  horseposition: [-1, 0, 1],
};

// complete
settings['inside_01'] = {
  horseScale: 13,
  lightPosition: [-8, 83.1, 48],
  shadowlightPosition: [-40, 415.5, 240],
  horserotation: [0, -2.0, 0],
  //horseposition : [22,0,14]
  horseposition: [-8, 0, 13],
};

// complete
settings['inside_02'] = {
  horseScale: 13,
  lightPosition: [7.7, 42.6, 30.1],
  shadowlightPosition: [38.5, 213, 150.5],
  horserotation: [0, -1.55, 0],
  horseposition: [1, 0, 5],
};

// SOUND
export const SFX_BALLMOVE = 0;
export const SFX_LASER = 1;

// USE
export const USE_LANDING_POWERBALL = false;
export const USE_FREECOIN = true;


// GOODS
export const GoodsType = {
  FREECOIN: 0,
  CASHCOIN: 1,
};

export const GoodsTypeServer = ['free', 'coin'];

export const BLOCKCHAIN_TYPE = 'solana';//'ethereum', 'tron', 'solana'
export const BLOCKCHAIN_NAME = () =>{
  return BLOCKCHAIN_TYPE.toUpperCase();
}

export const BLOCKCHAIN_LINK = () =>{
  switch(BLOCKCHAIN_TYPE) {
    case 'solana': {
        return `https://solscan.io/block/`;
      }
      case 'tron': {
        return 'https://tronscan.org/#/block/';
      }
      case 'ethereum': {
        return 'etherscan.io/block/'
      }
      default:{
        return 'error'
      }
  }
  

  // tron 

}


// string func
export function format() {
  var args = Array.prototype.slice.call (arguments, 1);
  return arguments[0].replace (/\{(\d+)\}/g, function (match, index) {
     return args[index];
  });
}

// CONTENTS_TYPE
export const CONTENTS_REELGAME = 10;

// NEW CONTENTS SHOW/HIDE
export const SHOW_METACROSS_ONLY = true;