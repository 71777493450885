import tw from 'tailwind-styled-components';

const Layer = tw.p`
  text-lg T:text-2xls T:text-base font-normal text-white
`;

type Props = {
  text: string;
};

export default function TitleText({ text }: Props) {
  return <Layer>{text}</Layer>;
}
