import React, { useState } from 'react';
import Modal from 'react-modal';
import { Box, LinearProgress } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../img/CloseIcon.svg';
import useLang from '../../lib/hook/useLang';
import PopupBg from '../../img/powerball/racepopup.png';
import { useIconStore, usePowerModalStore, useTitleStore } from '@/store/powerball';

export default function PowerBallModal({ isOpen, children, cancel }) {
  const { closePowerModalPopup } = usePowerModalStore();
  const { iconComponent } = useIconStore();
  const { title, phrase, submit } = useTitleStore();

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      zIndex: 1000,
    },
    content: {
      zIndex: 1001,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      className="absolute w-[450px] h-[350px] max-[540px]:w-[80%] max-[540px]:h-[300px] max-[400px]:h-[250px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl"
      style={modalStyle}
    >
      {/* <button onClick={closePowerModalPopup} className="absolute -top-9 right-0 w-10 h-10 z-[99]">
        <CloseIcon className="w-5 h-5" />
      </button> */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex flex-col justify-center items-center w-full h-[70%] space-y-7 max-[540px]:space-y-5 max-[400px]:space-y-3">
        <div>{iconComponent}</div>
        <div className="flex flex-col justify-center items-center space-y-2 text-white w-[280px] max-[486px]:w-[240px]">
          <h2 className="text-white text-3xl font-medium max-[540px]:text-xl">{title}</h2>
          <p className=" max-[540px]:text-sm text-center max-[360px]:text-xs">{phrase}</p>
        </div>
        {!submit && (
          <div className="w-[80%] h-8 rounded-md">
            <LinearProgress
              style={{ height: '8px', borderRadius: '12px' }}
              color="secondary"
              sx={{
                '--LinearProgress-progressThickness': '8px',
                '--LinearProgress-progressRadius': '8px',
                '--LinearProgress-thickness': '9px',
                '--LinearProgress-radius': '9px',
              }}
            />
          </div>
        )}

        {/* {submit || cancel ? <button>cancel</button>)} */}
        {submit && (
          <div
            onClick={closePowerModalPopup}
            className="w-[80%] h-[50px]  bg-indigo-400 text-white rounded-lg mx-auto  flex flex-col items-center justify-center pointer-cursor max-[540px]:h-[32px]"
          >
            <button className=" mx-auto text-cneter h-full text-white max-[540px]:text-sm">confirm</button>
          </div>
        )}
      </div>
      <img alt="bg" src={PopupBg} className="w-full h-full absolute top-0 left-0 object-fit" />
    </Modal>
  );
}
