import { useState } from 'react';

import ListPagerContext, { type Context } from './Context';

interface UseProviderListPagerProps {
  initialCurrentPage: number;
}

function useProvideListPager<T>({ initialCurrentPage }: UseProviderListPagerProps): Context<T> {
  const [data, setData] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);

  return {
    data,
    setData: (data: T[]) => setData(data),
    currentPage,
    setCurrentPage: (page: number) => setCurrentPage(page),
  };
}

interface ProviderProps {
  children: React.ReactNode;
  initialCurrentPage?: number;
}

export default function Provider({ children, initialCurrentPage = 1 }: ProviderProps) {
  const listPager = useProvideListPager({ initialCurrentPage });

  return <ListPagerContext.Provider value={listPager}>{children}</ListPagerContext.Provider>;
}
