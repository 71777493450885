import UserPicture from '../../components/UserPicture';
import tw from 'tailwind-styled-components';
import UserEmail from '../../components/userInfo/UserEmail';
import UserNickname from '../../components/userInfo/UserNickname';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang';

import { generateContainer } from '@/page/common/lib';
import { env } from '@/env';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import SpinnerLoader from '@/components/SpinnerLoader';

const Line = tw.span`
  border-[0.5px] border-white/10 block
`;

const ResetPincodeButton = tw.button`
  flex justify-center items-center
  w-[200px] h-[40px]
  bg-[#860099] disabled:opacity-40 hover:opacity-80
  text-white
  rounded-lg
`;

type WithdrawalInfoType = {
  CPF: string;
  PIX_ACCOUNT: string;
  firstName: string;
  lastName: string;
};

export default function UserInfo() {
  const { lngT } = useLang('mypage:userInfo');
  const [user] = useUser();
  const [isLoading, setLoading] = useState(false);

  const UserAccountContainer = generateContainer<WithdrawalInfoType>(`${env.api.host}/withdrawal/info`);

  const handleClickResetPincodeLink = async () => {
    setLoading(true);

    const password = prompt('please enter password');

    try {
      const { data } = await axios.post(
        `${env.api.host}/user/me/validate-password`,
        { password },
        { withCredentials: true }
      );

      console.log(data);
      if (!data.ok) {
        return toast(lngT('reset:wrong-password'), { theme: 'dark', type: 'error' });
      }

      await axios.post(`${env.api.host}/withdrawal/pincode/reset/email`, {}, { withCredentials: true });
      toast(lngT('withdrawal:resetPincode:email:success'), { theme: 'dark', type: 'success' });
    } catch (error) {
      console.log(error);
      toast(lngT('withdrawal:resetPincode:email:fail'), { theme: 'dark', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col rounded-xl D:mx-[64px]">
      <div className="hidden D:flex flex-row T:justify-between items-center text-[24px] font-medium text-white T:pb-[24px]">
        <div className="text-[20px]">{lngT('title')}</div>
      </div>
      <div className="flex justify-center min-h-screen">
        <div className="flex flex-col w-full space-y-[40px] ">
          <section className="flex flex-col mt-[8px] space-y-[30px]">
            <div className="flex flex-col mt-[8px] space-y-[30px]">
              <div className="bg-white/10 rounded-lg px-6 py-4 mt-2 D:mt-0">
                <UserPicture />
              </div>
              {user && <UserEmail email={user?.email} />}
            </div>
            <Line />
            <div className="flex flex-col mt-[8px] space-y-[30px]">{user && <UserNickname name={user?.name} />}</div>
            <Line />
            <UserAccountContainer>
              {({ data }) => {
                if (!data?.data) return null;

                return (
                  <div className="flex flex-col space-y-4">
                    {/* <ContentBox title={lngT('withdrawal:info')} content={`TYPE: PIX PAY`} />
                    <ContentBox content={`CPF: ${data.data?.CPF}`} />
                    <ContentBox content={`PIX ACCOUNT: ${data.data?.PIX_ACCOUNT}`} /> */}
                    <Line />
                    <ResetPincodeButton disabled={isLoading} onClick={handleClickResetPincodeLink}>
                      {isLoading ? <SpinnerLoader className="w-8 fill-white" /> : lngT('withdrawal:resetPincode')}
                    </ResetPincodeButton>
                  </div>
                );
              }}
            </UserAccountContainer>
          </section>
        </div>
      </div>
    </div>
  );
}
