import { useEffect } from "react";
import SpineRenderCommonForReel from "../util/SpineRenderCommonForReel";
import { AudioPlay } from '../../../util/audiomanager'
import {useReelGameStore} from "@/store/ReelGameStore";

function UISpine(props) {
    const filePath = `assets/2d/${props.path}`;
    const {isIphone, isIOSMute} = useReelGameStore();

    useEffect(()=>{
        if(props.filename.includes('fx_jackpot_common') && props.visibles !== null)
        {
            let playaudio = false;
            props.visibles.forEach(element => {
                if(element !== -1)    
                {
                    playaudio = true;
                    return;
                }
            });

            if(playaudio)
            {
                AudioPlay(props.lineaudioname, false, isIphone, isIOSMute);
                console.log('lineeffect ' + props.filename + ' ' + props.lineaudioname +  ' '  + props.visibles);
            }
        }

    },[props.visibles])

    return (
        <SpineRenderCommonForReel
            path={filePath}
            file={props.filename}
            aniName={props.aniName}
            id={`player-containerslot${props.filename}`}
            className={props.visible ? {} : {visibility: 'hidden'}}
            loop={props.loop === undefined ? true : props.loop}
            visibles={props.visibles}
            ext={props.ext}
            enterfreespinanimationplaying={props.enterfreespinanimationplaying}
        />
    )
}

export default UISpine;
