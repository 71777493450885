import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function useCustomNavigate() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigater = useCallback(
    (url: string) => {
      const useVideo = searchParams.get('useVideo');
      const recommendCode = searchParams.get('recommenderCode');

      let urlWithQuery = url;

      if (useVideo === 'true' || recommendCode) {
        const newSearchParams = new URLSearchParams();

        if (useVideo === 'true') {
          newSearchParams.set('useVideo', 'true');
        }

        if (recommendCode) {
          newSearchParams.set('recommenderCode', recommendCode);
        }

        urlWithQuery = `${url}?${newSearchParams.toString()}`;
      }

      navigate(urlWithQuery);
    },
    [navigate, searchParams]
  );

  return navigater;
}
