import React, { useEffect } from 'react';
import * as config from '../config';

export default function FaviconTitle() {
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href =
      Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING)
        ? `${process.env.PUBLIC_URL}/racing-favicon.ico`
        : `${process.env.PUBLIC_URL}/powerball-favicon.ico`;
    document.title = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'U-podium' : 'UP7BET';
  }, []);
  return <div className="text-trasparent"></div>;
}
