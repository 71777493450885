import React, { useLayoutEffect, useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BettingWithStyle from './BettingWithStyle';
import { Hidden } from '@mui/material';
import QueryString from 'qs';

import { useStyles } from '../styles/BettingBoardStyles';
import { useLocation, useParams } from 'react-router';
import { ChangeProgress_combine } from '../../horsestall/progresscombine/jsUtil_combine';
import { useNavigate } from 'react-router-dom';

import ChattingComponent from './ChattingComponent';
import { setCookie, getCookie } from '../../lib/cookie';

import useLang from '../../lib/hook/useLang';
import i18n from '../../lang/i18n';

var { webSocketIO } = require('../../socketClient.js');

function TabPanel(props) {
  const navigate = useNavigate();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

var customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

//var _game=0;
//var _user='';

function debounceFunction(callback, delay) {
  let timer;
  return (...args) => {
    // 실행한 함수(setTimeout())를 취소
    clearTimeout(timer);
    // delay가 지나면 callback 함수를 실행
    timer = setTimeout(() => callback(...args), delay);
  };
}

function useResizeObserver(ref, callback, isResize) {
  // 1. Custom Hook에서 ResizeObserver 객체를 state로 관리
  const [observer] = useState(new ResizeObserver(entries => handleResize(entries)));

  // 2. Custom Hook에서 반환하는 변경된 width, height, top, left
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [top, setTop] = useState();
  const [left, setLeft] = useState();

  // 3. useCallback()을 사용하여 크기가 변경되었을 경우 Callback 함수를 호출한다.
  const handleResize = useCallback(
    entries => {
      // 3-1. 관측된 요소가 없으면 빈 값을 return한다.
      if (!Array.isArray(entries)) {
        return;
      }

      // 3-2. 관측된 요소가 있는 경우 첫 번쨰 항목을 가져온다.
      const entry = entries[0];
      const { width, height, top, left } = entry.contentRect;

      // 3-3. 변경된 width, height, top, left를 설정한다.
      setWidth(width);
      setHeight(height);
      setTop(top);
      setLeft(left);

      // 3-4. 콜백함수를 호출한다.
      if (callback) {
        debounceFunction(callback(entry), 2000);
      }
    },
    [callback]
  );

  // 4. isResize 값에 따라 관측 시작&종료한다.
  useEffect(() => {
    if (isResize) observer.observe(ref.current);
    else observer.unobserve(ref.current);
  }, [isResize]);

  // 5. 변경된 width, height, top, left를 반환한다.
  return [width, height, top, left];
}

var _resUserInitCallback = { isconfirm: false, endcallback: undefined };

export default function BettingTabsWithStyle(props) {
  const { lngT } = useLang('racing:intro');

  const classes = useStyles();
  const [newMsg, setNewMsg] = React.useState(0);
  const refChatting = useRef({});
  const ref = React.useRef(null);
  const [heightBox, setHeightBox] = useState(0);
  const { state } = useLocation();

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setHeightBox(ref.current.offsetHeight);
  }, []);

  const refBetting0 = useRef({});
  const refBetting1 = useRef({});
  const refBetting2 = useRef({});
  const [value, setValue] = React.useState(0);

  const [, updateState] = React.useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);

  const location = useLocation(); //바뀐 부분

  const [selectedinfo, setselectedinfo] = useState({
    type: 0,
    index: 0,
    value: 0,
    currency: 'coin',
  });

  const [modal, setModal] = useState({
    showModal: false,
    comfirmModal: false,
    confirmMemo: '',
  });

  const [width, height, top, left] = useResizeObserver(ref, printResize, true);

  function printResize(entry) {
    console.log(entry.target);
    console.log(`width: ${entry.contentRect.width}px; height: ${entry.contentRect.height}px`);
    console.log(`top: ${entry.contentRect.top}px; left: ${entry.contentRect.left}px`);
  }

  // betting popup confirm
  const handleComfirmModal = () => {
    setModal({ comfirmModal: true, confirmMemo: 'betting...' });
    callbetting(
      selectedinfo.type,
      selectedinfo.index,
      selectedinfo.value,
      selectedinfo.currency,
      true,
      showResultPopup
    );
  };

  var count = 0;
  const addCount = () => {
    setNewMsg(++count);
  };

  // betting popup cancel
  const handleCloseModal = () => {
    setModal({ showModal: false, comfirmModal: false });
  };

  const handleConfirmCloseModal = () => {
    setModal({ comfirmModal: false });
  };

  const showResultPopup = memo => {
    setModal({ comfirmModal: true, confirmMemo: memo });

    setTimeout(() => {
      setModal({ comfirmModal: false });
    }, 1000);
  };
  function handleSetModal(state, selinfo) {
    setModal(state);
    setselectedinfo(selinfo);
  }

  const callbetting = (type, index, value, currency, isconfirm, endcallback) => {
    webSocketIO.send(
      JSON.stringify({
        protocol: 'reqBetting',
        type: type,
        no: index,
        value: value,
        currency: currency,
      })
    );

    _resUserInitCallback.isconfirm = isconfirm;
    _resUserInitCallback.endcallback = endcallback;
  };

  function onMessage(e) {
    console.log(e.data, 'Message received');

    var data = JSON.parse(e.data);

    if (data.protocol === 'resBetting') {
      //(data.betting !== undefined) {
      var total = webSocketIO.data.total[data.type];
      //total += data.value;
      webSocketIO.data.total[data.type] = data.total;
      var index = data.no;
      if (data.idUser === webSocketIO.data.idUser) {
        webSocketIO.data.listRow[data.type][index].betting = data.value;
        //webSocketIO.data.listRow[data.type][index].currency = data.currency;
      }
      if (_resUserInitCallback.endcallback != undefined && data.error != undefined) {
        var resMsg = data.error;
        if (resMsg == 'notEnoughCoin') {
          resMsg = lngT(`contents:notEnoughCoin`);
        }
        _resUserInitCallback.endcallback(resMsg);
        return;
      }

      for (var n = 0; n < webSocketIO.data.listRow[data.type].length; n++) {
        //if (webSocketIO.data.listRow[data.type][n].betting > 0) {
        //  webSocketIO.data.listRow[data.type][n].odds = total/webSocketIO.data.listRow[data.type][n].betting;
        //}
        webSocketIO.data.listRow[data.type][n].odds = data.odds[n];
      }
      if (data.type === 0) {
        //webSocketIO.data.bettingOddEven = data.bettingOddEven;
        if (webSocketIO.data.total[0] > 0) {
          webSocketIO.data.bettingOddEven[0] = (data.bettingOddEven[0] / webSocketIO.data.total[0]) * 100;
          webSocketIO.data.bettingOddEven[1] = (data.bettingOddEven[1] / webSocketIO.data.total[0]) * 100;
        }
      }

      if (_resUserInitCallback.endcallback != undefined && data.idUser === webSocketIO.data.idUser) {
        var resMsg = data.error === undefined || data.error === '' ? 'Success' : 'Error : ' + data.error;
        _resUserInitCallback.endcallback(resMsg);
      }
    } else if (data.protocol === 'resUserInit') {
      //(data.userBetting !== undefined) {
      for (var n = 0; n < 3; n++) {
        for (var m = 0; m < webSocketIO.data.listRow[n].length; m++) {
          if (data.userBetting.betting == null) {
            webSocketIO.data.listRow[n][m].betting = 0;
          } else {
            webSocketIO.data.listRow[n][m].betting = data.userBetting.betting[n][m];
          }
          //webSocketIO.data.listRow[n][m].currency = data.userBetting.currency[n][m];
          webSocketIO.data.listRow[n][m].odds = data.odds[n][m];
        }
        webSocketIO.data.total[n] = data.total[n];
      }
      for (var n = 0; n < data.listBettingValue.length; n++) {
        webSocketIO.data.listBettingValue.push(data.listBettingValue[n]);
      }
      if (webSocketIO.data.total[0] > 0) {
        if (webSocketIO.data.total[0] <= 0) {
          webSocketIO.data.bettingOddEven[0] = 0;
          webSocketIO.data.bettingOddEven[1] = 0;
        } else {
          webSocketIO.data.bettingOddEven[0] = (data.bettingOddEven[0] / webSocketIO.data.total[0]) * 100;
          webSocketIO.data.bettingOddEven[1] = (data.bettingOddEven[1] / webSocketIO.data.total[0]) * 100;
        }
      }
      webSocketIO.data.isCounterOpen = data.isCounterOpen;
      webSocketIO.data.counter = 0;
      webSocketIO.data.idUser = data.idUser;

      webSocketIO.data.listChat = [];
    } else if (data.protocol === 'resCounter') {
      console.log('counter:', data.counter);
      webSocketIO.data.counter = data.counter;
      if (data.counter <= 0) {
        webSocketIO.data.isCounterOpen = data.isCounterOpen;
      }
    } else if (data.protocol === 'reqChatting') {
      webSocketIO.data.listChat.push({
        idUser: data.idUser,
        name: data.nameUser,
        type: data.type,
        msg: data.msg,
      });
      addCount();
    }

    forceUpdate();
  }

  useEffect(() => {
    // if (state === undefined) {
    //   return
    // }
    // const queryData = QueryString.parse(location.search, {
    //  ignoreQueryPrefix: true,
    // });
    var urlparam = getCookie('urlparam');
    webSocketIO.data.game = urlparam.game.game; //String(props.state.game);//
    webSocketIO.data.tokenUser = urlparam.user.user; //props.state.user//
    // ChangeLanguage(urlparam.lan);

    // webSocketIO.data.game = state.game;//queryData.game;//String(props.state.game);//
    // webSocketIO.data.tokenUser = state.user;//queryData.user;//props.state.user//
    if (webSocketIO.data.tokenUser == undefined || webSocketIO.data.tokenUser == 'undefined') {
      //      alert('tokenUser undefined!');
      ChangeProgress_combine(0, 0, 0, i18n.language);
      return;
    }

    webSocketIO.close();
    webSocketIO.connect();

    webSocketIO.websocket.onmessage = onMessage;

    const requestTerm = 3000;

    Interval();
    function Interval() {
      setTimeout(() => {
        if (!webSocketIO.data.isCounterOpen) {
          // state가 항상 0이어서 전역변수 사용 함
          ChangeProgress_combine(webSocketIO.data.game, webSocketIO.data.tokenUser, 3, i18n.language);
          return;
        }

        Interval();

        return () => {
          clearTimeout();
        };
      }, requestTerm);
    }

    return () => {
      webSocketIO.close();
      console.log('컴포넌트가 화면에서 사라짐');
    };
  }, [state]);

  useEffect(() => {
    var data = 0;
  }, [webSocketIO.data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/*<Box className={classes.root}> 
    <Typography variant={'h3'} className={classes.titleStyle}>BETTING BOARD</Typography>
    <Box>
        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
          <Tab label="ODD / EVEN"/>
          <Tab label="SINGLE" />
          <Tab label="SHOW" />
      </Tabs>
      {value === 0 && <><OddEven/><NotifyComponent title={oddEvenTitle} content={oddEvenContent} /></>}
      {value === 1 && <><Single/><NotifyComponent title={singleTitle} content={singleContent} /></>}
      {value === 2 && <><Show/><NotifyComponent title={showTitle} content={showContent} /></>}
    </Box>
  </Box>*/}

      <Box className={classes.root}>
        {/*<ChattingComponent height={height} ref={refChatting} newMsg={newMsg} />*/}
        <Box className={classes.container} ref={ref}>
          <Typography variant={'h3'} className={classes.titleStyle}>
            {/*'BETTING BOARD'*/ lngT('title')}
          </Typography>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} className={classes.tabs}>
              <Tab label={/*"ODD / EVEN"*/ lngT('buttons:oddeven')} {...a11yProps(0)} />
              <Tab label={/*"SINGLE"*/ lngT('buttons:single')} {...a11yProps(1)} />
              <Tab label={/*"SHOW"*/ lngT('buttons:show')} {...a11yProps(2)} />
            </Tabs>
          </Box>

          <BettingWithStyle ref={refBetting0} type={0} value={value} handleSetModal={handleSetModal} />
          <BettingWithStyle ref={refBetting1} type={1} value={value} handleSetModal={handleSetModal} />
          <BettingWithStyle ref={refBetting2} type={2} value={value} handleSetModal={handleSetModal} />
        </Box>

        <Modal isOpen={modal.showModal} style={customStyles} className="modal" overlayClassName="overlay">
          <div className="contents">
            <p>
              {/*'Cancellation is not possible after placing a bet.'*/ lngT('contents:bettingText')}
              <br />
              {/*'Do you want to bet?'*/ lngT('contents:askBetting')}
            </p>
          </div>
          <div className="actions">
            <button className="button" onClick={handleComfirmModal} onClose={handleCloseModal}>
              {/*'Confirm'*/ lngT('contents:confirm')}
            </button>
            <button className="button" onClick={handleCloseModal}>
              {/*'Cancel'*/ lngT('contents:cancel')}
            </button>
          </div>
        </Modal>
        <Modal isOpen={modal.comfirmModal} style={customStyles} className="modal" overlayClassName="overlay">
          <button onClick={handleConfirmCloseModal} className="btn-close"></button>
          <div className="contents onlyText">
            <p>{modal.confirmMemo}</p>
          </div>
        </Modal>
      </Box>
    </>
  );
}
