import * as define from '../define'

export const sideNavMenus = {
  
  mypage: define.SHOW_METACROSS_ONLY ? [
    {
      title: 'userInfo', //'User Info',
      route: '/mypage/userinfo',
    },
    {
      title: 'pointHistory', //'Point History',
      route: '/mypage/pointhistory',
    },
    {
      title: 'gameHistory', //'Game History',
      route: '/mypage/bettinghistory',
    },
    {
      title: 'account', //'Account',
      route: '/mypage/account',
    },
  ]:[
    {
      title: 'userInfo', //'User Info',
      route: '/mypage/userinfo',
    },    
    {
      title: 'account', //'Account',
      route: '/mypage/account',
    },
  ]
  ,
  contact: [
    {
      title: 'faq', //'FAQ',
      route: '/contact/faq',
    },
    {
      title: 'submitTicket', //'Submit a ticket',
      route: '/contact/question',
    },
  ],
  community: [
    {
      title: 'freeBoard', //'Discussion Board',
      route: '/community/freeboard',
    },
  ],
  horse: [
    {
      title: 'info',
      route: '/horse/info',
    },
    {
      title: 'stake',
      route: '/horse/stake',
    },
  ],
  event: [
    {
      title: 'event',
      route: '/event',
    },
  ],
};
