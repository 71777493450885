import { makeStyles } from '@mui/styles';

var itemOddActive = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemOddActiveOp = 'rgba(188, 105, 209, 0.20)'; //'radial-gradient(circle, rgba(247,150,192) 0%, rgb(42 5 68)  100%)';//
var itemEvenActive = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemEvenActiveOp = 'rgba(188, 105, 209, 0.20)'; //'rgb(25 118 210  / 41%)';
var itemRowUnder = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemRowUnderOp = 'rgba(136, 132, 250, 0.20)'; //'rgb(255 151 0 / 41%)';
var itemRowOver = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemRowOverOp = 'rgba(136, 132, 250, 0.20)'; //'rgb(77 204 198 / 41%)';
var itemRowBottom = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemRowBottomOp = 'rgba(233, 97, 177, 0.20)'; //'rgb(253 255 0 / 41%)';
var itemRowMiddle = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemRowMiddleOp = 'rgba(233, 97, 177, 0.20)'; //'rgb(42 255 2 / 41%)';
var itemRowTop = 'linear-gradient(90deg, #8287FF 0%, #F74AA2 100%)';
var itemRowTopOp = 'rgba(233, 97, 177, 0.20)'; //'rgb(255 31 171 / 41%)';
//background:'radial-gradient(circle, rgba(247,150,192,1) 0%, rgb(42 5 68)  100%)',

export const useOddEvenStyles = makeStyles(theme => ({
  root: {},
  bettingStyle: {
    display: 'grid',
    gridTemplateRows: 'repeat(7, 100px)',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridAutoRows: 60,
    marginTop: 20,
    '& button': {
      color: '#fff',
      borderRadius: 8,
      backgroundColor: 'rgba(0,0,0,0.2)',
      fontSize: '1.3rem',
      fontWeight: 400,
    },
  },

  item: {
    '&.MuiButton-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      border: '1px solid #222',
      boxShadow: '-6px 6px 4px 0px rgba(0, 0, 0, 0.1) inset;',
      backgroundBlendMode: 'soft-light, normal',
      filter: 'brightness(100%)', // 초기 밝기 설정
      transition: 'filter 0.3s ease', // transition 효과 추가
      '&:before': {
        height: '0%',
        width: 1,
      },
      '&:hover': {
        backgroundColor: 'auto!important',
        filter: 'brightness(120%)',
        boxShadow:
          '5px 5px 10px 0px #000, 2px -1px 3px 2px rgba(3, 3, 3, 0.15) inset, 2px 4px 4px 2px rgba(255, 255, 255, 0.10) inset, -1px -2px -2px -1px rgba(255, 255, 255, 0.10) inset',
        borderWidth: '2px',
      },
      '@media (min-width:280px) and (max-width:479.99px)': {
        fontSize: '0.875rem',
        letterSpacing: -0.5,
      },
    },
  },
  empty: {
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  },
  itemNum: {
    '&.MuiButtonBase-root': {
      fontSize: '1.625rem!important',
    },
  },
  itemNumBoxStyle: {
    '&.MuiButtonBase-root': {
      fontSize: '1.625rem!important',
    },
    '&.MuiButtonBase-root': {
      width: '100%',
      minHeight: 55,
      fontSize: '1.75rem',
      borderRadius: 0,
      filter: 'brightness(100%)',
      border: '1px solid #222',
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 3%, rgba(255, 255, 255, 0.60) 105.18%), #9B896C',
      backgroundBlendMode: 'soft-light, normal',
      borderRadius: '8',
      color: 'rgb(0 0 0 / 50%)',
      '&:hover, &:active': {
        background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 3%, rgba(255, 255, 255, 0.60) 105.18%), #9B896C',
        filter: 'brightness(100%)',
        backgroundBlendMode: 'soft-light, normal',
      },
    },
  },
  itemNumActive: {
    '&.MuiButtonBase-root, &.MuiButtonBase-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.20) 105.18%), #EEAA1C',
      boxShadow:
        '5px 5px 10px 0px #000, 4px -3px 10px 0px rgba(3, 3, 3, 0.15) inset, 6px 8px 4px 0px rgba(255, 255, 255, 0.40) inset',
      border: '0.5px solid #222',
      borderRadius: '8',
      color: '#fff',
    },
  },
  // itemNumAfterActive: {
  //   '&.MuiButtonBase-root, &.MuiButtonBase-root:hover': {
  //     background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.20) 0%, rgba(255, 255, 255, 0.20) 105.18%), #EEAA1C',
  //     opacity: '0.4',
  //     boxShadow:
  //       '5px 5px 10px 0px #000, 4px -3px 10px 0px rgba(3, 3, 3, 0.15) inset, 6px 8px 4px 0px rgba(255, 255, 255, 0.20) inset',
  //     border: '0.5px solid #222',
  //     borderRadius: '8',
  //     color: '#fff',
  //   },
  // },
  itemOdd: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.40) 105.18%), rgba(100, 57, 209, 0.50)',
    },
  },
  itemOddActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%), #6439D1', //itemOddActiveOp,
    },
    itemOddNotActive: {
      '&.MuiButton-root, &.MuiButton-root:hover': {
        background: 'rgba(100, 57, 209, 0.50)', //itemOddActiveOp,
        boxShadow: '-6px -5px 4px 0px rgba(0, 0, 0, 0.25) inset,  10px 8px 8px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  itemEven: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%), rgba(100, 57, 209, 0.50)',
    },
  },
  itemEvenActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),#6439D1', //itemEvenActiveOp,
      boxShadow:
        '2px 2px 4px 0 ' +
        itemEvenActive +
        ', -2px -2px 4px 0 ' +
        itemEvenActiveOp +
        ', inset -2px -2px 4px 0 ' +
        itemEvenActiveOp +
        ',inset 2px 2px 4px 0 ' +
        itemEvenActiveOp,
    },
    itemEvenNotActive: {
      '&.MuiButton-root, &.MuiButton-root:hover': {
        background: 'rgba(100, 57, 209, 0.50)',
        boxShadow: '-6px -5px 4px 0px rgba(0, 0, 0, 0.25) inset,  10px 8px 8px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
  itemRowUnder: {
    gridArea: '2 / 1 / span 3 / span 1',
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(154, 15, 103, 0.50)',
    },
  },
  itemRowUnderActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),#9A0F67', //itemRowUnderOp,
      boxShadow:
        '2px 2px 4px 0 ' +
        itemRowUnder +
        ', -2px -2px 4px 0 ' +
        itemRowUnderOp +
        ', inset -2px -2px 4px 0 ' +
        itemRowUnderOp +
        ',inset 2px 2px 4px 0 ' +
        itemRowUnderOp,
    },
  },
  itemRowOver: {
    gridArea: '5 / 1 / span 3 / span 1',
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(154, 15, 103, 0.50)',
    },
  },
  itemRowOverActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%), #9A0F67', //itemRowOverOp,
      boxShadow:
        '2px 2px 4px 0 ' +
        itemRowOver +
        ', -2px -2px 4px 0 ' +
        itemRowOverOp +
        ', inset -2px -2px 4px 0 ' +
        itemRowOverOp +
        ',inset 2px 2px 4px 0 ' +
        itemRowOverOp,
    },
  },
  itemRowBottom: {
    gridArea: '2 / 4 / span 2 / span 1',
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(51, 38, 181, 0.50)',
    },
    // '&.MuiButton-root, &.MuiButton-root:hover': {
    //   background:
    //     'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(51, 38, 181, 0.50)',
    // },
  },
  itemRowBottomActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),#3326B5', //itemRowBottomOp,
      boxShadow:
        '2px 2px 4px 0 ' +
        itemRowBottom +
        ', -2px -2px 4px 0 ' +
        itemRowBottomOp +
        ', inset -2px -2px 4px 0 ' +
        itemRowBottomOp +
        ',inset 2px 2px 4px 0 ' +
        itemRowBottomOp,
    },
  },
  itemRowBottomNotActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      color: 'rgba(255, 255, 255, 0.10)',
      border: '0.5px solid #222',
      background: 'rgba(51, 38, 181, 0.40)',
    },
  },
  itemRowMiddle: {
    gridArea: '4 / 4 / span 2 / span 1',
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(51, 38, 181, 0.50)',
    },
    // '&.MuiButton-root, &.MuiButton-root:hover': {
    //   borderColor: itemRowMiddle,
    //   background:
    //     'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(51, 38, 181, 0.50)',
    // },
  },
  itemRowMiddleActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),#3326B5', //itemRowMiddleOp,
      boxShadow:
        '2px 2px 4px 0 ' +
        itemRowMiddle +
        ', -2px -2px 4px 0 ' +
        itemRowMiddleOp +
        ', inset -2px -2px 4px 0 ' +
        itemRowMiddleOp +
        ',inset 2px 2px 4px 0 ' +
        itemRowMiddleOp,
    },
  },
  itemRowMiddleNotActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      color: 'rgba(255, 255, 255, 0.10)',
      border: '0.5px solid #222',
      background: 'rgba(51, 38, 181, 0.40)',
    },
  },
  itemRowTop: {
    gridArea: '6 / 4 / span 2 / span 1',
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background:
        'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(51, 38, 181, 0.50)',
    },
    // '&.MuiButton-root, &.MuiButton-root:hover': {
    //   background:
    //     'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),rgba(51, 38, 181, 0.50)',
    // },
  },
  itemRowTopActive: {
    '&.MuiButton-root, &.MuiButton-root:hover': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 0%, rgba(255, 255, 255, 0.30) 105.18%),#3326B5', //itemRowMiddleOp,
      boxShadow:
        '2px 2px 4px 0 ' +
        itemRowMiddle +
        ', -2px -2px 4px 0 ' +
        itemRowMiddleOp +
        ', inset -2px -2px 4px 0 ' +
        itemRowMiddleOp +
        ',inset 2px 2px 4px 0 ' +
        itemRowMiddleOp,
    },
  },
  itemRowTopNotActive: {
    gridArea: '6 / 4 / span 2 / span 1',
    '&.MuiButton-root, &.MuiButton-root:hover': {
      color: 'rgba(255, 255, 255, 0.10)',
      border: '0.5px solid #222',
      background: 'rgba(51, 38, 181, 0.40)',
    },
  },
}));
