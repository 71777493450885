import { useLocation } from 'react-router-dom';
import styles from './RentListCard.module.css';

import useLang from '@/lib/hook/useLang';
import * as define from '../../../../../../define'
const RentListCard = () => {
  const { lngT } = useLang('landingPage:guideSection');
  const { search } = useLocation();

  return (
    <div className={styles.boxDescription}>
      <span className={styles.line} />
      <div className={styles.boxContainer}>
        <div className={styles.box}>
          <span className={styles.indicator}>01</span>
          <h6> {lngT('guideRent:guide:1:title')}</h6>
          <p>
            {lngT('guideRent:guide:1:description')}
            <a href={define.SHOW_METACROSS_ONLY ? `/horse/stake${search}` : ''} className="cursor-pointer">
              {lngT('guideRent:guide:1:a')}
            </a>
          </p>
        </div>
        <div className={styles.box}>
          <span className={styles.indicator}>02</span>
          <h6> {lngT('guideRent:guide:2:title')} </h6>
          <p>{lngT('guideRent:guide:2:description')}</p>
        </div>
        <div className={styles.box}>
          <span className={styles.indicator}>03</span>
          <strong>
            {lngT('guideRent:guide:3:mobile1')} <h6> {lngT('guideRent:guide:3:mobile2')} </h6>
          </strong>
          <p>
            <b> {lngT('guideRent:guide:3:strong')} </b> {lngT('guideRent:guide:3:description')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RentListCard;
