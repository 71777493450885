import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useLang from '../../lib/hook/useLang';
import { env } from '@/env';
import {
  useIconStore,
  usePowerBallBettingStore,
  usePowerBallLotteryStore,
  usePowerModalStore,
  useTitleStore,
} from '@/store/powerball';

import { getCookie, setCookie } from '../../lib/cookie';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

import { ReactComponent as SuccessFilledIcon } from '../../img/SuccessFilledIcon.svg';
import BlockChainIcon from '../../img/Block_1.gif';
import { GoodsTypeServer } from '../../define';
const { webSocketIO } = require('../../socketGameClient.js');

// gameType = "lottery" | "betting"
export default function usePowerballSocket(gameType) {
  const { lngT } = useLang('powerball');
  const { lngT:lngTetc } = useLang('etcs');
  const [count, setCount] = useState(0);
  const [round, setRound] = useState(0);
  const [resPowerballHistory, setResPowerballHistory] = useState('');
  const [resOddsCondition, setResOddsCondition] = useState(['']);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [canBet, setCanBet] = useState(false);
  const [rewardOddEven, setRewardOddEven] = useState(null);
  const [historyOddEven, setHistoryOddEven] = useState([]);
  const [resPowerballFromHash, setResPowerballFromHash] = useState([]);
  const [lotteryResult, setLotteryResult] = useState('');
  const [powerballinfo, setpowerballinfo] = useState(null);
  // const [goodsType,setGoodsType] = useState(0);
  const navigate = useCustomNavigate();
  const callUseDispatch = useDispatch();
  const { setTicketStore } = usePowerBallBettingStore();
  const { setSelectedTicketList } = usePowerBallLotteryStore();
  const { setIconComponent } = useIconStore();
  const { setTitle, setPhrase, onSubmit, noSubmit } = useTitleStore();
  const { openPowerModalPopup, closePowerModalPopup } = usePowerModalStore();

  const setBettingPossible = canBet => {
    webSocketIO.data.showClock = canBet;
    setCanBet(canBet);
  };

  function connectSocket() {
    if (webSocketIO.page === 'powerBall') {
      return;
    }

    var token = getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);

    if (token !== undefined) {
      webSocketIO.data.tokenUser = token;
    }
    webSocketIO.connect(null,null,'powerball');
    webSocketIO.websocket.onmessage = onMessage;
    webSocketIO.websocket.onopen = onOpen;
    webSocketIO.oncloseCallback = onClose;

    return () => {
      webSocketIO.onClose();
      console.log('컴포넌트가 화면에서 사라짐');
    };
  }

  function CallSetRewardOddEven(rewards) {
    if (rewards && rewards.length > 0) {
      let newBettingReward = [];
      for (let i = 0; i < rewards.length; i++) {
        if (!rewards[i].value) rewards[i].value = rewards[i].place;
        const result = JSON.parse(rewards[i].result);
        const userBetBallType = rewards[i].value <= 7 ? lngT('lottery:pad:whiteball') : lngT('lottery:pad:powerball');
        const userBetOddType =
          rewards[i].value === 1
            ? 'O'
            : rewards[i].value === 2
            ? 'E'
            : rewards[i].value === 3
            ? 'U'
            : rewards[i].value === 4
            ? 'o'
            : rewards[i].value === 5
            ? 'S'
            : rewards[i].value === 6
            ? 'M'
            : rewards[i].value === 7
            ? 'L'
            : rewards[i].value === 8
            ? 'O'
            : rewards[i].value === 9
            ? 'E'
            : '';
        const resultBetOddType = result.balls % 2 === 1 ? 'O' : 'E';
        const resultBetUnderType = result.balls <= 77 ? 'U' : 'O';
        const resultBetSmallType = result.balls <= 69 ? 'S' : result.balls <= 85 ? 'M' : 'L';
        const resultPowerBetOddType = result.powerBall % 2 === 1 ? 'O' : 'E';
        const rewardData = {
          userBall: userBetBallType,
          userOdd: userBetOddType,
          resultOdd: [[resultBetOddType, resultBetUnderType, resultBetSmallType], resultPowerBetOddType],
          reward: rewards[i].reward,
          coinType: rewards[i].coinType,
        };

        if (rewardData.coinType === GetGoodsTypeStr()) newBettingReward.push(rewardData);
      }
      setRewardOddEven(newBettingReward);
    }
  }

  function onMessage(e) {
    const data = JSON.parse(e.data);

    if (data.protocol === 'powerball-resPowerballCounter') {
      if (data.prevCounter === undefined) {
        setBettingPossible(true);
      }
      setCount(webSocketIO.data.showClock ? data.counter : 0);
    } else if (data.protocol === 'powerball-resPowerballGame') {
      setBettingPossible(false);

      PowerballGame({
        counter: 0,
        ball: data.ball,
        powerBall: data.powerBall,
      });

      openPowerModalPopup();
      setIconComponent(<img src={BlockChainIcon} alt="block" />);
      setTitle(/*Wating Hash*/ lngT('hash:title'));
      setPhrase(' ... ');
      noSubmit();
      setTimeout(() => {
        closePowerModalPopup();
      }, 1500);

      //상금 보여주기
    } else if (data.protocol === 'powerball-resReward') {
      const rewards = data.rewards;

      if (gameType === 'betting') {
        if (rewards !== null) {
          CallSetRewardOddEven(rewards);
          // let newBettingReward = [];
          // for (let i = 0; i < rewards.length; i++) {
          //   const result = JSON.parse(rewards[i].result);
          //   const userBetBallType = rewards[i].value <= 7 ? 'Ball' : 'Power Ball';
          //   const userBetOddType =
          //     rewards[i].value === 1
          //       ? 'O'
          //       : rewards[i].value === 2
          //       ? 'E'
          //       : rewards[i].value === 3
          //       ? 'U'
          //       : rewards[i].value === 4
          //       ? 'o'
          //       : rewards[i].value === 5
          //       ? 'S'
          //       : rewards[i].value === 6
          //       ? 'M'
          //       : rewards[i].value === 7
          //       ? 'L'
          //       : rewards[i].value === 8
          //       ? 'O'
          //       : rewards[i].value === 9
          //       ? 'E'
          //       : '';
          //   const resultBetOddType = result.balls % 2 === 1 ? 'O' : 'E';
          //   const resultBetUnderType = result.balls <= 77 ? 'U' : 'O';
          //   const resultBetSmallType = result.balls <= 69 ? 'S' : result.balls <= 85 ? 'M' : 'L';
          //   const resultPowerBetOddType = result.powerBall % 2 === 1 ? 'O' : 'E';
          //   const rewardData = {
          //     userBall: userBetBallType,
          //     userOdd: userBetOddType,
          //     resultOdd: [[resultBetOddType, resultBetUnderType, resultBetSmallType], resultPowerBetOddType],
          //     reward: rewards[i].reward,
          //   };
          //   newBettingReward.push(rewardData);
          // }
          // setRewardOddEven(newBettingReward);
        }
      } else {
        setLotteryResult(data.rewards);
      }

      //User Init
    } else if (data.protocol === 'powerball-resUserInit') {
      setRewardOddEven([]);
      // 이유는 3번 결과창이 나오고 currState 가 7번이 되면서 리셋이 되야하는데
      // 7번이 호출되기 전에 뒤로가기
      // 다시 들어오면 currState는 8번이 되어 있다.
      //  | - 1.배팅시작 - | - 2.애니메이션 - | - 3.결과창 - | - 4.대기 - |
      // => 무조건 가져오면 안됨
      // => 1, 2, 3번은 가져와야함
      // => 4 번에서 리셋해줘야함
      // 배팅시작도 8번 대기 상태도 8번

      console.log('@@: canBet?', webSocketIO.data.showClock);

      if (data.prevGame) console.log('resuserinit :' + data.prevGame.counter);

      PowerballGame(data.prevGame);
      webSocketIO.data.index = data.prevGame ? data.prevGame.index : data.index;
      ReqOddsCondition(data.prevGame == null);

      const resUserInit = data.powerballTicket;

      let resBuyTicket;

      if (gameType === 'lottery') {
        resBuyTicket =
          data.prevPowerballTicket &&
          data.prevPowerballTicket.listPowerballTicket &&
          data.prevPowerballTicket.listPowerballTicket.length > 0
            ? data.prevPowerballTicket.listPowerballTicket
            : resUserInit.listPowerballTicket;

        const selectedTicketList = resBuyTicket.map(ticket => ({
          balls: ticket.balls,
          powerBall: ticket.powerball,
          price: ticket.price,
        }));

        setSelectedTicketList(selectedTicketList);
      } else {
        // console.log('@@:', data);
        const isBettingPhase =
          data?.prevPowerballTicket === null || data?.prevPowerballTicket?.listOddBevenBetting === null;
        const bettingData = data?.powerballTicket?.listOddBevenBetting;
        const bettingDoneData = data?.prevPowerballTicket?.listOddBevenBetting;
        const rewards = data.prevPowerballTicket?.listOddBevenBetting;
        if (rewards) {
          let rewardsEntries = Object.entries(rewards);
          let rewardsArr = [];
          const goodsTypeStr = GetGoodsTypeStr();
          for (let i = 0; i < rewardsEntries.length; ++i) {
            const rewardsEntrie = rewardsEntries[i][1];
            if (rewardsEntrie.coinType === goodsTypeStr) rewardsArr.push(rewardsEntrie);
          }

          CallSetRewardOddEven(rewardsArr);
        }

        const thisGameIssuedTicketList = Object.entries(isBettingPhase ? bettingData : bettingDoneData).reduce(
          (acc, [key, value]) => {
            acc[key] = {
              id: key,
              price: value.price,
              result: isBettingPhase ? null : JSON.parse(value.result),
              reward: value.reward,
              coinType: value.coinType,
            };
            return acc;
          },
          {}
        );

        for (const [key, value] of Object.entries(thisGameIssuedTicketList)) {
          console.log(`${key} : ${value}`);

          if (value.coinType !== GetGoodsTypeStr()) delete thisGameIssuedTicketList[key];
        }

        // let filterData = thisGameIssuedTicketList.remove;
        // if(thisGameIssuedTicketList.filter)
        //   filterData = thisGameIssuedTicketList.filter(info=>info.coinType === GoodsTypeServer[goodsType])
        setTicketStore(thisGameIssuedTicketList);
      }
    } else if (data.protocol === 'powerball-resBuyTicket') {
      console.log('data.message', JSON.parse(data.message));
      const ticketData = JSON.parse(data.message);
      if (data.message.includes('ok req')) {
        setOpenSuccess(true);
        setIconComponent(<SuccessFilledIcon />);
        setTitle(/*Success*/ lngT('success:title'));
        if (ticketData.ticket.powerball === 0) {
          setPhrase(/*Your betting has been completed*/ lngT('success:phrase1'));
        } else {
          setPhrase(/*You bought one Ticket now*/ lngT('success:phrase2'));
        }
        onSubmit();
      } else {
        if (data.message.includes('not enough point')) {
          openPowerModalPopup();
        }
      }
    } else if (data.protocol === 'powerball-resOddsCondition') {
      var obj = JSON.parse(data.message);

      // console.log('obj', data.message);
      setResOddsCondition(obj);

      webSocketIO.send(
        JSON.stringify({
          protocol: 'powerball-reqGameHistory',
        })
      );
    } else if (data.protocol === 'powerball-resGameHistory') {
      var objs = JSON.parse(data.message);

      //결과 후에 히스토리 업데이트 & 게임 라운드 업데이트
      if (gameType === 'lottery') {
        const viewCount = 5;
        if (objs.find(obj => obj.index >= webSocketIO.data.index)) objs.pop();
        // if (objs.length > viewCount) objs.shift();
        if (objs.length > viewCount) objs.splice(0, objs.length - viewCount);
        setResPowerballHistory(`round : ${round}`);
        setResPowerballHistory(objs);
      } else {
        const viewCount = 10;
        if (objs.find(obj => obj.index >= webSocketIO.data.index)) objs.pop();
        if (objs.length > viewCount) objs.shift();
        setHistoryOddEven(objs);
      }
      webSocketIO.data.index = webSocketIO.data.index + 1;
    } else if (data.protocol === 'powerball-resPowerballFromHash') {
      const hash = JSON.parse(data.message);
      setResPowerballFromHash(hash);
    } else if (data.protocol === 'powerball-resMaintenanceCloseServer') {      
      console.log('점검이다');
      alert(lngTetc('warning:maintenance'));
      navigate('/powerball/lobbyy');
    } else if (data.protocol === 'resMultiConnection') {      
      alert('You cannot log in with duplicate credentials.');
      console.log('중복로그인임');
      navigate('/powerball/lobbyy');
    }
  }

  function onClose() {
    console.log('call: oncloseCallback');

    // 현재 페이지에서 서버 접속이 끊긴경우만 lobby로 강제 이동 시켜준다. 유저가 다른페이지로 이동하는 경우에도 onClose로 들어오기 때문에 예외처리를 안하면 페이지를 두번 이동 하게 된다.
    if(window?.location?.pathname.includes('powerball/betting') || window?.location?.pathname.includes('powerball/lottery'))
    {
      navigate('/powerball/lobbyy');
    }     
  }

  function onOpen() {
    console.log('send: powerball-reqUserInit');
    console.log('webSocketIO.data', webSocketIO.data);
    console.log('WebSocket Request:', webSocketIO.websocket.protocol);
  }

  const PowerballGame = gameInfo => {
    // console.log('gameinfo0 : ' + gameInfo);
    // if(gameInfo)
    // {
    //   console.log('gameinfo0 counter : ' + gameInfo.counter);
    //   console.log('gameinfo0 ball : ' + gameInfo.ball);
    //   console.log('gameinfo0 ball : ' + gameInfo.powerBall);
    // }

    if (gameInfo === null || gameInfo === undefined) {
      setCookie('prevgameinfo', null, {
        path: '/', // 모든 경로에 적용
        maxAge: 24 * 60 * 60, // 30일 유지
        ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
      });
      return;
    }

    let gameframe = gameInfo.counter;

    callUseDispatch({
      type: 'POWERBALL',
      Powerball: {
        gameframe: gameframe,
        ball: gameInfo.ball,
        powerBall: gameInfo.powerBall,
      },
    });

    const prevgameinfo = {
      gameframe: gameframe,
      ball: gameInfo.ball,
      powerBall: gameInfo.powerBall,
    };

    setpowerballinfo({
      type: 'POWERBALL',
      Powerball: {
        gameframe: gameframe,
        ball: gameInfo.ball,
        powerBall: gameInfo.powerBall,
      },
    });

    setCookie('prevgameinfo', prevgameinfo, {
      path: '/', // 모든 경로에 적용
      maxAge: 24 * 60 * 60, // 30일 유지
      ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
    });

    if (prevgameinfo) console.log('set gameframe : ' + prevgameinfo.gameframe);
  };

  // 연출 끝나고 호출
  const ReqOddsCondition = () => {
    // selectedTicketList = {};
    setRound(webSocketIO.data.index);

    webSocketIO.send(
      JSON.stringify({
        protocol: 'powerball-reqOddsCondition',
      })
    );
  };

  const GetGoodsTypeStr = () => {
    var urlparam = getCookie('urlparam');
    const goodsType = urlparam !== undefined ? urlparam.goodsType : 0;

    return GoodsTypeServer[goodsType];
  };

  return {
    powerballinfo,
    canBet,
    openSuccess,
    count,
    round,
    resPowerballHistory,
    resOddsCondition,
    historyOddEven,
    rewardOddEven,
    resPowerballFromHash,
    lotteryResult,
    setRewardOddEven,
    connectSocket,
    ReqOddsCondition,
    // setGoodsType,
  };
}
