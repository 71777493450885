import styles from './boxBonus.module.css';
import BoxKnight from '@/img/landingPage/mobile/KnightHorseBoxBonus.png';
import Arrow from '@/img/landingPage/arrowButton.svg';
import LogoWhite from '@/img/landingPage/upodiumwhite.svg';

import useLang from '@/lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useLocation } from 'react-router-dom';
import { PlayGame, GetDefaultGoods } from '../../../../horsestall/progresscombine/jsUtil_combine';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';
const BoxBonusSection = () => {
  const { lngT } = useLang('landingPage:footer');
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const [user] = useUser();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  return (
    <section className={styles.container}>
      <div className={styles.box}>
        {/* <Image width={100} src={LogoWhite} alt={"logoWhite"} /> */}
        <img width={140} src={BoxKnight} alt={'BoxKnight'} />
        <div className={styles.textContainer}>
          <h3>
            {lngT('banner:1')} <br /> <b>{lngT('banner:2')}</b>
            <div className="text-sm text-yellow-300 leading-7">{lngT('banner:6')}</div>
          </h3>
          <button onClick={() => 
            PlayGame(navigate, user?.name, GetDefaultGoods(balance?.data))
            } className={styles.cta}>
            <img src={Arrow} alt="Arrow" />
            {lngT('banner:5')}
          </button>
          <p>{lngT('banner:4')}</p>
        </div>
      </div>
    </section>
  );
};

export default BoxBonusSection;
