import React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useReelGameSocket from './useReelGameSocket';
import * as THREE from 'three';

import {SpinState} from '../../util/reeldefine.js'
// import {ReelGameFrontEffect} from './effect/ReelGameFrontEffect'
import ReelGameFront from './controller/ReelGameFront'
import CanvasManager from "./monitor/CanvasManager";

import {withStyles} from "@mui/styles";
import {getStyleByBrand} from "./styles/StyleManager";
import {AudioManager} from  '../../util/audiomanager'
import BackSpine from "./effect/BackSpine";
import FPSStats from "react-fps-stats";
const styles = getStyleByBrand();
console.log("getStyleByBrand : ", styles);

function ReelGame(props) {    
    const { classes, isRacingService } = props;
    const dispatch = useDispatch();
    // const [spinState, setspinState] = useState(SpinState.NONE);
    const [isforcestop, setisforcestop] = useState(false);
    const [serverResult, setserverResult] = useState([[]]);
    const reelGameServerInfo = useSelector(state => state.reelGameServerInfo);
    // console.log('TESTSPIN ROOT ' + spinState);
    const {
        connectSocket,
        ReqRollSlotGame,
        resultSlot,
        slot33,
        reward,
        balance,
        freeroll,
    } = useReelGameSocket();

    useEffect(() => {
        const closeSocket = connectSocket();
        return () => closeSocket();
    }, []);

    useEffect(() => {
        let tempserverResult = []
        tempserverResult.push([1, 1, 1]);

        if (slot33?.length > 0) {
            tempserverResult.push(slot33[0]);
            tempserverResult.push(slot33[1]);
            tempserverResult.push(slot33[2]);
        }

        tempserverResult.push([1, 1, 1]);
        setserverResult(tempserverResult);
    }, [slot33])

    return (
        <div className={classes.base}>
            <div className={classes.root}>
                    {/* <FPSStats></FPSStats> */}
                    <AudioManager/>
                    <div className={classes.main}>
                        <div className={classes.main_sub}>
                            <ReelGameFront
                                freeroll = {freeroll}
                                resultSlot={resultSlot}
                                reward={reward}
                                ReqRollSlotGame={ReqRollSlotGame}
                                balance={balance}
                                reelGameServerInfo={reelGameServerInfo}
                                dispatch={dispatch}
                                isRacingService={isRacingService}
                                setisforcestop = {setisforcestop}
                                serverResult={serverResult}
                                isforcestop = {isforcestop}
                            />

                            <CanvasManager
                                resultSlot={resultSlot}
                                reward={reward}
                                reelGameServerInfo={reelGameServerInfo}
                                dispatch={dispatch}
                                serverResult={serverResult}
                                isforcestop = {isforcestop}
                            />
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default withStyles(styles)(ReelGame);

