import * as config from '../../../config';

export const IsVisible = (coinHistoryItem: CoinHistoryData) => {
  const type = coinHistoryTypeParser(coinHistoryItem);
  return Number(config.SERVICE_TYPE) === type || Number(config.SERVICE_TYPE) === Number(config.SERVICE_ALL);
};

const coinHistoryTypeParser = (coinHistoryItem: CoinHistoryData) => {
  const { info } = coinHistoryItem;

  try {
    const pInfo = JSON.parse(info);

    switch (pInfo.type) {
      case 'betting': {
        return config.SERVICE_RACING;
      }

      case 'stake': {
        return config.SERVICE_RACING;
      }

      case 'powerball': {
        return config.SERVICE_POWERBALL;
      }

      case 'reward': {
        if (pInfo.info.reward === 'betting') {
          return config.SERVICE_RACING;
        } else if (pInfo.info.reward === 'horse-stake') {
          return config.SERVICE_RACING;
        } else if (pInfo.info.reward === 'powerball') {
          return config.SERVICE_POWERBALL;
        } else {
          return config.SERVICE_ALL;
        }
      }

      case 'charge': {
        return config.SERVICE_ALL;
      }

      case 'withdrawal': {
        return config.SERVICE_ALL;
      }

      case 'airdrop': {
        return config.SERVICE_ALL;
      }

      default: {
        return config.SERVICE_ALL;
      }
    }
  } catch (error) {
    return '';
  }
};

export const coinHistoryInfoParser = (coinHistoryItem: CoinHistoryData, lngT: (name: string) => string) => {
  const { info } = coinHistoryItem;

  try {
    const pInfo = JSON.parse(info);

    switch (pInfo.type) {
      case 'betting': {
        return lngT('info:betting');
      }

      case 'stake': {
        return lngT('info:lease');
      }

      case 'powerball': {
        if (pInfo.info.gameType === 'betting') {
          return lngT('info:powerball:betting');
        } else if (pInfo.info.gameType === 'ticket') {
          return lngT('info:powerball:lottery');
        } else {
          return '';
        }
      }

      case 'reward': {
        if (pInfo.info.reward === 'betting') {
          return lngT('info:reward:betting');
        } else if (pInfo.info.reward === 'horse-stake') {
          return lngT('info:reward:lease');
        } else if (pInfo.info.reward === 'powerball') {
          if (pInfo.info.gameType === 'betting') {
            return lngT('info:reward:powerball');
          } else if (pInfo.info.gameType === 'ticket') {
            return lngT('info:reward:lottery');
          } else {
            return '';
          }
        } else {
          return '';
        }
      }

      case 'charge': {
        return lngT('info:charge');
      }

      case 'withdrawal': {
        return lngT('info:withdrawal');
      }

      case 'airdrop': {
        return lngT('info:airdrop');
      }

      case 'conversion': {
        return lngT('info:conversion');
      }

      case 'deposit':{
        return lngT('info:deposit');
      }

      case 'deposit-refund':{
        return lngT('info:deposit-refund');
      }

      default: {
        return '';
      }
    }
  } catch (error) {
    return '-';
  }
};
