import React, { Component } from 'react';
import Modal from 'react-modal';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { useStyles } from './styles/CommonDialogStyles';
import { ReactComponent as CloseIcon } from '../../img/CloseIcon.svg';

import useLang from '../../lib/hook/useLang';

export default function HashDialog(props) {
  const { lngT } = useLang('racing:intro');
  const classes = useStyles();
  const { onClose, open, title, children, submit, submitText, cancel, closeButton, objectImg, onConfirm } = props;

  // 배경 클릭 이벤트 핸들러
  const handleBackdropClick = event => {
    event.stopPropagation();
  };
  //const onSubmit = () => {
  //    onConfirm();
  //}

  return (
    <Dialog open={open} onClose={onClose} className={classes.root} onClick={handleBackdropClick}>
      <Box className={classes.dialogStyle}>
        {closeButton && (
          <IconButton disableRipple className={classes.buttonClose} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent>
          {objectImg && <Box className={classes.objectImgStyle}>{objectImg}</Box>}
          <Typography className={classes.titleStyle}>{title}</Typography>
          <Box className={classes.childrenStyle}>{children}</Box>
        </DialogContent>
        {(submit || cancel) && (
          <DialogActions justify={ (!cancel && 'center').toString()}>
            {cancel && (
              <Button onClick={onClose} className={classes.cancelButton} disableRipple>
                {/*Cancel*/ lngT('contents:cancel')}
              </Button>
            )}

            {submit && (
              <Button
                className={classes.submitButton}
                onClick={onConfirm}
                // disabled={!!disabled}
                disableRipple
                style={cancel ? {} : { minWidth: '100%' }}
              >
                {submitText ? submitText : /*'Confirm'*/ lngT('contents:confirm')}
              </Button>
            )}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
}
