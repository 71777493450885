import tw from 'tailwind-styled-components';

import BettingValueButton from './BettingValueButton';

import DrawsLine from '../../../../img/powerball/b_betting_line.png';
import DrawsLineL from '../../../../img/powerball/b_betting_line_L.png';
import DrawsLineM from '../../../../img/powerball/b_betting_line_M.png';

import { BettingTicketStore, usePowerBallBettingStore } from '@/store/powerball';
import { useLang } from '../../../../lib/hook';

const NumberBox = tw.div`
  rounded-2xl
  px-4 pt-2 pb-4 space-y-6 w-[380px] h-fit
  max-[1440px]:w-full
`;

const BoxText = tw.h2`
  font-bold text-base text-white upppercase
  max-[1440px]:text-sm
  max-[400px]:text-xs

`;

const NumberPadHeader = tw.div`
  flex flex-row
  justify-between
  px-2
`;

const NumberPadHeaderText = tw.div`
  flex flex-col gap-1 items-start w-full
`;

type PowerBallBettingValue = {
  id: number;
  name: string;
  odds: number;
  detail?: string;
};

type Props = {
  canBet: boolean;
  selectedBettingId: number | null;
  setSelectedBettingId: (selectednumber: number | null) => void;
};

// 함수를 만듬
// => betting Value를 넣었을때 내가 추가할 수 있는 상태인지 아닌지를 판별해내는 놈.

const ODD_EVEN = [1, 2];
const UNDER_OVER = [3, 4];
const SMALL_MEDIUM_LARGE = [5, 6, 7];

const POWERBALL_ODD_EVEN = [8, 9];

const checkIsPossibleBettingId = (ticketStore: BettingTicketStore, bettingId: number): boolean => {
  if (bettingId === 1 || bettingId === 2) {
    return !Object.keys(ticketStore).some(key => ODD_EVEN.includes(Number(key)));
  } else if (bettingId === 3 || bettingId === 4) {
    return !Object.keys(ticketStore).some(key => UNDER_OVER.includes(Number(key)));
  } else if (bettingId === 5 || bettingId === 6 || bettingId === 7) {
    return !Object.keys(ticketStore).some(key => SMALL_MEDIUM_LARGE.includes(Number(key)));
  } else if (bettingId === 8 || bettingId === 9) {
    return !Object.keys(ticketStore).some(key => POWERBALL_ODD_EVEN.includes(Number(key)));
  }

  return false;
};

const BettingValuePad = ({ canBet, selectedBettingId, setSelectedBettingId }: Props) => {
  const { ticketStore } = usePowerBallBettingStore();
  const { lngT } = useLang('powerball');

  const ballBettingValues: PowerBallBettingValue[] = [
    { id: 1, name: /*'ODD'*/ lngT('betting:pad:odd'), odds: 1.95 },
    { id: 2, name: /*'EVEN'*/ lngT('betting:pad:even'), odds: 1.95 },
    { id: 3, name: /*'UNDER'*/ lngT('betting:pad:under'), odds: 1.95, detail: '(15-77)' },
    { id: 4, name: /*'OVER'*/ lngT('betting:pad:over'), odds: 1.95, detail: '(78-140)' },
    { id: 5, name: /*'SMALL'*/ lngT('betting:pad:small'), odds: 2.7, detail: '(15-69)' },
    { id: 6, name: /*'MEDIUM'*/ lngT('betting:pad:medium'), odds: 2.7, detail: '(70-85)' },
    { id: 7, name: /*'LARGE'*/ lngT('betting:pad:large'), odds: 2.7, detail: '(86-140)' },
  ];
  //1:홀, 2:짝, 3.언더, 4.오버,  5:소, 6:중, 7:대, 8: 파워볼 홀, 9: 파워볼 짝

  const powerBallBettingValues: PowerBallBettingValue[] = [
    { id: 8, name: /*'ODD'*/ lngT('betting:pad:odd'), odds: 1.95 },
    { id: 9, name: /*'EVEN'*/ lngT('betting:pad:even'), odds: 1.95 },
  ];

  const handleClickBallBettingButton = (selectedId: number) => () =>
    setSelectedBettingId(selectedBettingId === selectedId ? null : selectedId);

  return (
    <div className="flex flex-col items-center justify-center space-y-1 pt-2 max-[1440px]:w-full numberPad">
      <NumberBox>
        <NumberPadHeader>
          <NumberPadHeaderText>
            <BoxText>{/*White Ball*/ lngT('lottery:pad:whiteball')}</BoxText>
            <p className="text-sm text-white/80 max-[400px]:text-xs">
              {/*Based on the sum of White ball numbers.*/ lngT('betting:pad:phrase1')}
            </p>
          </NumberPadHeaderText>
        </NumberPadHeader>
        <div className="relative grid grid-cols-6 gap-2 justify-items-center">
          {ballBettingValues.map((betting, i) => {
            return (
              <BettingValueButton
                key={i}
                bettingValue={betting}
                disabled={!canBet || !checkIsPossibleBettingId(ticketStore, betting.id)}
                isSelected={selectedBettingId === betting.id}
                onClick={handleClickBallBettingButton(betting.id)}
                stickerPrice={ticketStore.hasOwnProperty(betting.id) ? ticketStore[betting.id]?.price ?? null : null}
              />
            );
          })}
        </div>
      </NumberBox>
      <NumberBox>
        <NumberPadHeader>
          <NumberPadHeaderText>
            <BoxText>{/*PowerBall*/ lngT('lottery:pad:powerball')}</BoxText>
            <p className="text-sm text-white/80 max-[400px]:text-xs">
              {/*Based on PowerBall numbers.*/ lngT('betting:pad:phrase2')}
            </p>
          </NumberPadHeaderText>
        </NumberPadHeader>
        <div className="flex flex-row justify-items-center space-x-2">
          {powerBallBettingValues.map((betting, i) => (
            <BettingValueButton
              key={i}
              bettingValue={betting}
              disabled={!canBet || !checkIsPossibleBettingId(ticketStore, betting.id)}
              isSelected={selectedBettingId === betting.id}
              onClick={handleClickBallBettingButton(betting.id)}
              stickerPrice={ticketStore.hasOwnProperty(betting.id) ? ticketStore[betting.id]?.price ?? null : null}
            />
          ))}
        </div>
      </NumberBox>
      <picture className="w-[90%] max-[1440px]:w-full">
        <source srcSet={DrawsLine} media="(max-width: 500px)" />
        <source srcSet={DrawsLineM} media="(max-width: 984px)" />
        <source srcSet={DrawsLineL} media="(max-width: 1440px)" />
        <source srcSet={DrawsLine} media="(min-width:1441px)" />
        <img className="w-full max-[1440px]:w-full" src={DrawsLine} alt="DrawsLine"></img>
      </picture>
    </div>
  );
};

export default BettingValuePad;
