import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowBackIcon } from '../../../../img/ArrowBackIcon.svg';
import { ReactComponent as ArrowForwardIcon } from '../../../../img/ArrowForwardIcon.svg';
import { ReactComponent as ArrowLineLeftIcon } from '../../../../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowLineRightIcon } from '../../../../img/ArrowLineRightIcon.svg';
import { ReactComponent as CloseIcon } from '../../../../img/CloseIcon.svg';

import useLang from '@/lib/hook/useLang';
import * as jsUtil from '../../../../horsestall/progresscombine/jsUtil_combine';

type Props = {
  rewardHistoryItems: HorseStakeRewardHistoryItem[];
  total: number;
  prevAcc: number;
  itemsPerPage: number;
  handleCloseModal: () => void;
  handleChangePage: (page: number) => void;
};

export default function HorseStakeRewardHistoryItemUI({
  rewardHistoryItems,
  total,
  prevAcc,
  itemsPerPage,
  handleCloseModal,
  handleChangePage,
}: Props) {
  const { lngT } = useLang('horse:info');
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(total / itemsPerPage);
  const [hitoryItems, setHistoryItems] = useState<any[]>([]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    handleChangePage(page);
  };

  useEffect(() => {
    const historyItems: any[] = [];

    for (let i = rewardHistoryItems.length - 1; i >= 0; i--) {
      historyItems[i] = {
        ...rewardHistoryItems[i],
        accReward:
          i === rewardHistoryItems.length - 1
            ? prevAcc + rewardHistoryItems[i].reward
            : historyItems[i + 1].accReward + rewardHistoryItems[i].reward,
      };
    }

    setHistoryItems(historyItems);
  }, [rewardHistoryItems, prevAcc]);

  return (
    <div
      className="relative z-[90] w-full h-fit landscape:h-fit bg-[#1C012E] border border-white/10 flex flex-col items-center justify-center space-y-2 T:space-y-6 rounded-lg py-4 T:py-10"
      onClick={e => e.stopPropagation()}
    >
      <div className="absolute -top-8 right-2 text-white cursor-pointer" onClick={handleCloseModal}>
        <CloseIcon />
      </div>
      <div className="text-lg T:text-2xl text-white font-medium pb-6">{/*Winning Records*/ lngT('stake:winning')}</div>
      <div className="w-[95%] T:w-[80%] space-y-5">
        <div className="">
          <table className="w-full ">
            <thead className="bg-gray-50">
              <tr className="">
                <th
                  scope="col"
                  className="px-1 T:px-6 py-2 T:py-3 text-center text-[10px] T:text-sm font-medium text-gray-200 uppercase tracking-wider border-r-[1px] border-white/10 bg-[#860099]"
                >
                  {/*Date*/ lngT('table:date')}
                </th>
                <th
                  scope="col"
                  className="px-1 T:px-6 py-2 T:py-3 text-center text-[10px] T:text-sm font-medium text-gray-200 uppercase tracking-wider border-r-[1px] border-white/10 bg-[#860099]"
                >
                  {/*Round*/ lngT('table:round')}
                </th>
                <th
                  scope="col"
                  className="px-1 T:px-6 py-2 T:py-3 text-center text-[10px] T:text-sm font-medium text-gray-200 uppercase tracking-wider border-r-[1px] border-white/10 bg-[#860099]"
                >
                  {/*Rank*/ lngT('table:rank')}
                </th>
                <th
                  scope="col"
                  className="px-1 T:px-6 py-2 T:py-3 text-center text-[10px] T:text-sm font-medium text-gray-200 uppercase tracking-wider border-r-[1px] border-white/10 bg-[#860099]"
                >
                  {/*Rewards*/ lngT('table:rewards')}
                </th>
                <th
                  scope="col"
                  className="px-1 T:px-6 py-2 T:py-3 text-center text-[10px] T:text-sm font-medium text-gray-200 uppercase tracking-wider border-white/10  bg-[#860099]"
                >
                  {/*Acc Rewards*/ lngT('table:accRewards')}
                </th>
              </tr>
            </thead>
            {hitoryItems.length === 0 ? (
              <tr className="text-white h-[160px]">
                <td colSpan={5} className="text-center">
                  No reward records yet.
                </td>
              </tr>
            ) : (
              <tbody className="text-white">
                {hitoryItems.map((item, index) => (
                  <tr key={index} className={index % 2 === 0 ? ' bg-[#554FFF]/25' : ' bg-[#554FFF]/20'}>
                    <td className="px-1 T:px-6 py-4 text-[10px] T:text-sm text-center whitespace-nowrap border-r-[1px] border-white/10">
                      {new Date(item.raceDate).toISOString().replace('T', '').slice(0, -13)}
                    </td>
                    <td className="px-1 T:px-6 py-3 text-[10px] T:text-sm text-center whitespace-nowrap border-r-[1px] border-white/10">
                      {item.raceId}
                    </td>
                    <td className="px-1 T:px-6 py-3 text-[10px] T:text-sm text-center whitespace-nowrap border-r-[1px] border-white/10">
                      {item.rank}
                    </td>
                    <td className="px-1 T:px-6 py-3 text-[10px] T:text-sm text-center whitespace-nowrap border-r-[1px] border-white/10">
                      {jsUtil.Round(item.reward, 3)}
                    </td>
                    <td className="px-1 T:px-6 py-3 text-[10px] T:text-sm text-center whitespace-nowrap  border-white/10">
                      {jsUtil.Round(item.accReward, 3)}
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>

        {/* 페이지네이션 컴포넌트 */}
        {hitoryItems.length === 0 ? (
          ''
        ) : (
          <ul className="mt-4 flex justify-center items-center space-x-1 T:space-x-2">
            <div className="h-5 w-5 hover:bg-[#D470FF]/20 rounded-md">
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
                className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer ${
                  currentPage === 1 ? '' : ''
                }`}
              >
                <ArrowForwardIcon />
              </button>
            </div>
            <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer ${
                  currentPage === 1 ? '' : ''
                }`}
              >
                <ArrowLineLeftIcon />
              </button>
            </div>
            {/* {Array.from({ length: totalPages }).map((_, index) => (
              <li key={index} className="flex items-center justify-center">
                <button
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-2 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 focus:outline-none cursor-pointer ${
                    currentPage === index + 1 ? 'bg-[#D470FF]/50' : ''
                  }`}
                >
                  {totalPages >= 10
                    ? currentPage === index + 1
                      ? index + 1
                      : index === 0 || index === totalPages - 1
                      ? index + 1
                      : '...'
                    : index + 1}
                </button>
              </li>
            ))} */}
            {totalPages >= 5
              ? Array.from({ length: totalPages }).map((_, index) => {
                  const pageNum =
                    currentPage === index + 1
                      ? index + 1
                      : index === 0
                      ? 1
                      : currentPage === 1 && index === 1
                      ? 2
                      : index === totalPages - 1
                      ? totalPages
                      : currentPage <= index + 2 && currentPage > index - 1
                      ? '...'
                      : currentPage === 1 && index === totalPages - 2
                      ? '...'
                      : null;
                  return pageNum !== null ? (
                    <li key={index} className="flex items-center justify-center">
                      <button
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-2 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 focus:outline-none cursor-pointer ${
                          currentPage === index + 1 ? 'bg-[#D470FF]/50' : ''
                        }`}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ) : null;
                })
              : Array.from({ length: totalPages }).map((_, index) => (
                  <li key={index} className="flex items-center justify-center">
                    <button
                      onClick={() => handlePageChange(index + 1)}
                      className={`px-2 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 focus:outline-none cursor-pointer ${
                        currentPage === index + 1 ? 'bg-[#D470FF]/50' : ''
                      }`}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
            <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
              <button
                onClick={() => {
                  handlePageChange(currentPage + 1);
                }}
                disabled={currentPage === totalPages}
                className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer ${
                  currentPage === 1 ? '' : ''
                }`}
              >
                <ArrowLineRightIcon />
              </button>
            </div>
            <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
              <button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
                className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer ${
                  currentPage === totalPages ? '' : ''
                }`}
              >
                <ArrowBackIcon />
              </button>
            </div>
          </ul>
        )}
      </div>
    </div>
  );
}
