import { toast } from 'react-toastify';

import { useLang } from '@/lib/hook';
import { genFetchContainer, genRestfulContainer } from '@/lib/RestfulContainer';
import { env } from '@/env';
import { fixBalance } from '@/page/common/lib/utils';

import { ExchangePointContainer } from '@/page/mypage/container';

import { ContentBoxLayout } from '@/page/common/layout';
import { InfoText, Input, MainButton, PlainText } from '@/page/common/component';
import Icon from '@/page/common/icons';
import { CoinBoxLayout } from '@/page/mypage/layout';

import Style from './style';

export default function ConversionTab() {
  const { lngT } = useLang('mypage:userInfo');

  const FetchBalanceContainer = genFetchContainer<{ point: number; free: number }>({
    url: `${env.api.host}/balance`,
  });

  const PostConversionBalanceCotainer = genRestfulContainer<{ ok: boolean }>({
    url: `${env.api.host}/balance/conversion`,
    method: 'POST',
    withCredential: true,
  });

  return (
    <FetchBalanceContainer>
      {({ data, refresh }) => (
        <>
          <ContentBoxLayout title={lngT('conversion:availablePoint')}>
            <CoinBoxLayout type="free" tooltipAlways={false}>
              <span>{fixBalance(data?.data.free)}</span>
            </CoinBoxLayout>
          </ContentBoxLayout>
          <InfoText text={lngT('conversion:info')} />
          <ContentBoxLayout title={lngT('conversion:request')}>
            <ExchangePointContainer availablePoint={data?.data.free ?? 0}>
              {({ requestFree, willConvertedPoint, onClickPlus, onClickMinus, reset }) => (
                <Style.ExchangeLayer>
                  <Style.ExchangeCoinLayer>
                    <CoinBoxLayout type="free" tooltipAlways={false}>
                      <Input value={requestFree} disabled />
                      <Style.ExchangeCoinCounterLayer>
                        <Icon.PluseIcon className="cursor-pointer" onClick={onClickPlus} />
                        <Icon.MinusIcon className="cursor-pointer" onClick={onClickMinus} />
                      </Style.ExchangeCoinCounterLayer>
                    </CoinBoxLayout>
                    <Icon.RightArrow size={28} fill="white" />
                    <CoinBoxLayout type="point" tooltipAlways={false}>
                      <Input value={willConvertedPoint} disabled />
                    </CoinBoxLayout>
                  </Style.ExchangeCoinLayer>
                  <PostConversionBalanceCotainer
                    callback={{
                      onSuccess: data => {
                        if (data.ok) {
                          toast(lngT('conversion:message:success'), { theme: 'dark', type: 'success' });
                          reset();
                          refresh();
                        }
                      },
                      onFail: error => {
                        toast(lngT('conversion:message:fail'), { theme: 'dark', type: 'error' });
                      },
                    }}
                  >
                    {({ call, isLoading }) => (
                      <MainButton
                        content={lngT('conversion')}
                        isLoading={isLoading}
                        disabled={!willConvertedPoint}
                        onClick={() =>
                          call({
                            requestFree,
                            willConvertedPoint,
                          })
                        }
                      />
                    )}
                  </PostConversionBalanceCotainer>
                </Style.ExchangeLayer>
              )}
            </ExchangePointContainer>
          </ContentBoxLayout>
          <Style.AlertLayer>
            <Style.AlertTitleLayer>
              <Icon.AlertIcon size={20} fill="white" />
              <PlainText text={lngT('conversion:alert:title')} />
            </Style.AlertTitleLayer>
            <InfoText text={`- ${lngT('conversion:alert:p1')}`} />
            <InfoText text={`- ${lngT('conversion:alert:p2')}`} />
            <InfoText text={`- ${lngT('conversion:alert:p3')}`} />
            <InfoText text={`- ${lngT('conversion:alert:p4')}`} />
          </Style.AlertLayer>
        </>
      )}
    </FetchBalanceContainer>
  );
}
