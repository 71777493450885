import { useState } from 'react';

type Props = {
  init?: boolean;
};

type ReturnType = {
  Toggle: () => JSX.Element;
  isToggled: boolean;
  setIsToggled: (isToggled: boolean) => void;
};

export default function useToggle(props?: Props): ReturnType {
  const [isToggled, setIsToggled] = useState(props?.init ?? false);

  const Toggle = () => (
    <label className={`flex items-center cursor-pointer gap-x-2`}>
      <div className="relative">
        <input type="checkbox" className="sr-only" checked={isToggled} onChange={e => setIsToggled(e.target.checked)} />
        <div
          className={`block w-10 h-6 rounded-full ${isToggled ? 'bg-metacross-main/30' : 'bg-metacross-main/60'}`}
        ></div>
        <div
          className={`dot absolute left-1 top-1 w-4 h-4 rounded-full transition ${
            isToggled ? 'transform translate-x-full bg-white/50 ' : 'bg-metacross-main'
          }`}
        />
      </div>
    </label>
  );

  return {
    Toggle,
    isToggled,
    setIsToggled,
  };
}
