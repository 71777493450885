import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import OauthBox from '../../components/OauthBox';
import TextNavLinker from '../../components/TextLinker';
import EmailLoginForm from '../../components/EmailLoginForm';
import LogoButton from '../../components/LogoButton';

import useLang from '../../lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useUser } from '@/lib/hook';
import * as config from '../../config'

const Container = tw.section`
  flex flex-col justify-center
  w-full M:w-[360px]
`;

const AuthTitle = tw.div`
  font-semibold text-[30px]
`;

export default function Login() {
  const { lngT } = useLang('auth:login');
  const location = useLocation();
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const lngtParam = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'racing' : 'powerball';

  useEffect(() => {
    if (user) navigate('/');
  }, [user]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container>
        <div className="mb-[60px]">
          <LogoButton signup={true} />
        </div>
        <AuthTitle className="pb-[30px]">{lngT('title')}</AuthTitle>
        {/*<OauthBox />*/}
        <EmailLoginForm />
        <TextNavLinker
          text={lngT(`new:${lngtParam}:1`)}
          href={`/signup${location.search}`}
          hrefText={lngT(`new:${lngtParam}:2`)}
          className="text-[16px] mt-[20px]"
        />
      </Container>
    </div>
  );
}
