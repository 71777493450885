import tw from 'tailwind-styled-components';

import { DefaultAvatarImage } from '@/page/common/images';

const Container = tw.div`
  overflow-hidden
  rounded-full
  w-[48px] h-[48px] T:w-[64px] T:h-[64px]
`;

const UserAvatarImage = tw.img`
  w-full h-full
`;

type Props = {
  className?: string;
  avatarUrl?: string;
};

export default function UserAvatar({ className, avatarUrl }: Props) {
  return (
    <Container className={className ?? ''}>
      {avatarUrl ? <UserAvatarImage src={avatarUrl} alt="avatar" /> : <DefaultAvatarImage className="w-full h-full" />}
    </Container>
  );
}
