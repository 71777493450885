import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { PIXEL_ACCESS_TOKEN } from '../../config';

export default function Pixel({ children }) {
  useEffect(() => {
    ReactPixel.init(PIXEL_ACCESS_TOKEN);
    ReactPixel.pageView();
  }, []);

  return <div>{children}</div>;
}
