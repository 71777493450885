import { useState } from 'react';

import { useLang } from '@/lib/hook';
import { genRestfulContainer } from '@/lib/RestfulContainer';
import { env } from '@/env';

import { PopupLayout } from '@/page/common/layout';
import { InfoText, Input, MainButton, TitleText } from '@/page/common/component';

import Style from './style';
import { toast } from 'react-toastify';

type Props = {
  closeModal: () => void;
};

export default function ChangePinPopup({ closeModal }: Props) {
  const { lngT } = useLang('mypage:userInfo');
  const [password, setPassword] = useState('');

  const PostFindPINEmailContainer = genRestfulContainer<{ ok: boolean }>({
    url: `${env.api.host}/withdrawal/pincode/email`,
    method: 'POST',
  });

  return (
    <PopupLayout closeModal={closeModal} closeDisabled={false}>
      <Style.PopupLayer>
        <TitleText text={`🗝️  ${lngT('changePin:title')}`} />
        <TitleText text={lngT('changePin:subTitle')} />
        <InfoText text={lngT('changePin:info')} />
        <Input type="password" onChange={e => setPassword(e.target.value)} />
        <PostFindPINEmailContainer
          callback={{
            onSuccess: data => {
              if (data.ok) {
                toast(lngT('changePin:sendEmail:success'), { theme: 'dark', type: 'success' });
                closeModal();
              } else {
                toast(lngT('changePin:checkPassword:fail'), { theme: 'dark', type: 'error' });
              }
            },
            onFail: () => toast(lngT('changePin:sendEmail:fail'), { theme: 'dark', type: 'error' }),
          }}
        >
          {({ call, isLoading }) => (
            <MainButton
              content={lngT('changePin:subTitle')}
              disabled={!password || isLoading}
              isLoading={isLoading}
              onClick={() => call({ password })}
            />
          )}
        </PostFindPINEmailContainer>
      </Style.PopupLayer>
    </PopupLayout>
  );
}
