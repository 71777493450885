import { type ChangeEvent } from 'react';
import tw from 'tailwind-styled-components';

type Props = {
  startDate: string;
  endDate: string;
  onChangeStartDate: (e: ChangeEvent<HTMLInputElement>) => void;
  onChnageEndDate: (e: ChangeEvent<HTMLInputElement>) => void;
};

const Layer = tw.div`
  flex flex-row
  gap-y-2 T:gap-x-2
`;

const Span = tw.span`
  px-3 text-white
`;

const DateInput = tw.input`
  rounded-md
  px-2
  w-fit
`;

export default function DateFilter({ startDate, endDate, onChangeStartDate, onChnageEndDate }: Props) {
  return (
    <Layer>
      <DateInput type="date" value={startDate} onChange={onChangeStartDate} />
      <Span>~</Span>
      <DateInput type="date" value={endDate} onChange={onChnageEndDate} />
    </Layer>
  );
}
