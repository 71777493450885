import { legacy_createStore as createStore } from 'redux';
import * as config from '../config';
import {GoodsType} from '../define'

function Reducer(state, action){
    if(state === undefined)
    {
        //console.log("T1");
        //return {count:0, remaintime:0};  
        return {
            remaintime:0,
            game:0,
            user:'X',
            oldprogress:-1,
            progress:0,            
            lan:'',
            isHistory:false,
            horsepage:'',
            horsetexture:0,
            curState:0,
            Powerball:undefined,
            soundOn:false,
            curSFX:{curState: 0, sfx: -1},
            goodsType:GoodsType.CASHCOIN,
            reelGameAniStep:0,
            reelGameServerInfo:{
                userinit:false,
                freeroll:{size:0,count:0},
                needUpdateSpin:false
            },
            reelGameSpinState:0,
            lastjackpottime:undefined,
            isJackpot:false,
            reelGameResourceReady:false,
            enterfreespinanimationplaying:false,
        };
    }    
    
    if(action.type==='SOUND')
    {
        const newState = {...state};        
        newState.soundOn = !newState.soundOn;
        return newState;
    }
    else if(action.type==='REELGAME_SERVERINFO')
    {
        const newState = {...state};        

        const reelGameServerInfo = action.reelGameServerInfo;
        if(reelGameServerInfo.userinit !== undefined)
            newState.reelGameServerInfo.userinit = reelGameServerInfo.userinit;
        if(reelGameServerInfo.freeroll !== undefined)
            newState.reelGameServerInfo.freeroll = reelGameServerInfo.freeroll;
        if(reelGameServerInfo.needUpdateSpin !== undefined)
            newState.reelGameServerInfo.needUpdateSpin = reelGameServerInfo.needUpdateSpin;
        if(reelGameServerInfo.lastjackpottime !== undefined)        
            newState.reelGameServerInfo.lastjackpottime = reelGameServerInfo.lastjackpottime;        
        if(reelGameServerInfo.isJackpot !== undefined)        
            newState.reelGameServerInfo.isJackpot = reelGameServerInfo.isJackpot;
        
        return newState;        
    }    
    else if(action.type==='ENTERFREESPINANIMATIONPLAYING')
    {
        const newState = {...state};        
        newState.enterfreespinanimationplaying = action.enterfreespinanimationplaying;
        return newState;        
    }
    else if(action.type==='REELGAME_ANISTEP')
    {
        const newState = {...state};        
        newState.reelGameAniStep = action.reelGameAniStep;
        return newState;        
    }    
    else if(action.type==='REELGAME_SPINSTATE')
    {
        const newState = {...state};        
        newState.reelGameSpinState = action.reelGameSpinState;
        // console.log('storespinstate : ' + action.reelGameSpinState);
        return newState;        
    }       
    else if(action.type==='REELGAME_RESOURCEREADY')
    {
        const newState = {...state};        
        newState.reelGameResourceReady = action.reelGameResourceReady;
        return newState;        
    }   
    else if(action.type==='GOODSTYPE')
    {
        const newState = {...state};        
        newState.goodsType = action.goodsType;
        return newState;        
    }
    else if(action.type==='POWERBALL')
    {
        const newState = {...state};        
        newState.Powerball = action.Powerball;
        return newState;
    }
    else if(action.type==='CURFRAME')
    {
        const newState = {...state};
        newState.curState = action.curState;
        return newState;
    }
    else if(action.type==='CURFRAMESFX')
    {
        const newState = {...state};
        newState.curSFX = action.curSFX;
        return newState;
    }
    else if(action.type==='REMAINTIME')
    {
        //console.log("T2");
        const newState = {...state};
        --newState.remaintime;
        if(newState.remaintime<=0)
            newState.remaintime = 0;
        return newState;
    }
    else if(action.type==='SETREMAINTIME')
    {
        //console.log("T3");
        const newState = {...state};
        newState.remaintime=action.remaintime;
        if(newState.remaintime<=0)
            newState.remaintime = 0;
        return newState;        
    }
    else if(action.type==='CLIENTDATA')
    {
        //console.log("T3");
        //alert(action.user);
        
        const newState = {...state};
        newState.oldprogress = (action.oldprogress === null || action.oldprogress === undefined) ? 0 : action.oldprogress;
        newState.game=(action.game === null || action.game === undefined) ? newState.game : action.game;
        newState.user=(action.user === null || action.user === undefined)  ? newState.user : action.user;
        newState.progress=(action.progress === null || action.progress === undefined) ? newState.progress : action.progress;
        newState.lan=(action.lan === null || action.lan === undefined) ? newState.lan : action.lan;
        newState.isHistory=(action.isHistory === null || action.isHistory === undefined) ? newState.isHistory : action.isHistory;//
        // newState.goodsType=(action.goodsType === null || action.goodsType === undefined) ? newState.goodsType : action.goodsType;//
        // console.log(`CLIENTDATA : ${action.isHistory} ${newState.isHistory}`);
        return newState;        
    }   
    else if(action.type==='HORSESTALL')
    {
        const newState = {...state};
        newState.horsepage=(action.horsepage === null || action.horsepage === undefined) ? newState.horsepage : action.horsepage;
        newState.horsetexture=(action.horsetexture === null || action.horsetexture === undefined) ? newState.horsetexture : action.horsetexture;
        return newState;        
    }
    else
    {
        //console.log("T4");
        return state;
    }


  // switch(action.type)
  // {
  //     case 'REMAINTIME':
  //         {
  //             const newState = {...state};
  //             --newState.remaintime;
  //             return newState;
  //         }
  //     case 'SETREMAINTIME':
  //         {
  //             const newState = {...state};
  //             newState.remaintime=action.remaintime;
  //             return newState;
  //         }
  //     // case 'SUBSTITUDE':
  //     //     {
  //     //         const newState = {...state};
  //     //         newState.count = action.id;
  //     //         return newState;
  //     //     }
  //     // case 'PLUS':
  //     //     {
  //     //         const newState = {...state};
  //     //         ++newState.count;
  //     //         return newState;
  //     //     }
  //     // case 'MINUS':
  //     //     {
  //     //         const newState = {...state};
  //     //         --newState.count;
  //     //         return newState;
  //     //     }
  // }

  // return state;
}

export const store = createStore(Reducer);
