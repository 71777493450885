// import { extend, useLoader } from '@react-three/fiber'
// import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import {  
  useFBX, 
  useGLTF, 
  useAnimations,
  AccumulativeShadows,
  RandomizedLight,
  Center,
  Lightformer,
  Environment,
  Float,
  SoftShadows,
  Text 
} from '@react-three/drei'
import { useControls } from "leva"
import React, { useState, useEffect, useRef } from 'react'
import * as THREE from "three";
import {useFrame, extend} from  "@react-three/fiber"
import * as define from '../../src/define'
import { useSelector } from "react-redux";
var textures;

function LoadTextures(textureindex)
{
  //https://metacross-brazil-s3-bucket.s3.sa-east-1.amazonaws.com/horsestall/bg/2d/inside_01.png
  //root : https://metacross-brazil-s3-bucket.s3.sa-east-1.amazonaws.com

  var loader = new THREE.TextureLoader();  
  var textureIndex10 = textureindex < 10 ? `0${textureindex}` : textureindex;
  textures = loader.load(`${define.FILE_SERVER_URL}/horsestall/texture/${textureIndex10}.png`);
}    

var copyMaterials = null;
const aninames = ['bodystand01','bodystand02'];
var aniIndex = 0;
var currentTick = 0;
var maxTick = 1;
var loadingTime = 0;
var settings = null;
var horsePos = 0;
var horseRot = 0;
var horseScale = 0;
var opacity;
// var tempshowmodel = false;


var glb;
var texind;
//const group = useRef()    
//const { nodes, materials, animations } = glb;
//const { actions } = useAnimations(animations, group)    

function FbxRender (props) {
    const [showModel, setshowModel] = useState(true);
    const path = `/horsestall/glb/export.glb`;
    // console.log('path : ' + path);
    
    useEffect(()=>{

      for(var i = 0; i < aninames.length; ++i)
      {
        var aniName = aninames[i];
        var action = actions[aniName];
        if(action !== undefined)
        {
          actions[aniName].play();
          actions[aniName].reset().play().paused = true;  
        }
      }    
      opacity = 0.0;
    },[props.pintexture])

    useFrame((_, delta) => {
      var aniName = aninames[aniIndex];
      var action = actions[aniName];

      if(action !== undefined)
        actions[aniName].time = actions[aniName].getClip().duration * currentTick;
      
      currentTick += delta * 0.15;
      // currentTick += delta;
      if(currentTick > maxTick)
      {
        currentTick = 0;
        if(aniIndex===0)
          aniIndex = 1;
        else
          aniIndex = 0;
      }
      loadingTime += delta;
      if(loadingTime > 0)
        setrefreshRender(true);

      opacity += delta * 3;
      if(SkinnedMeshRef.current)
      {
        SkinnedMeshRef.current.material.opacity = opacity;
      }
    })

    const UseTest=()=>{
      glb = useGLTF(path);    
    }

    if(glb===undefined)
      UseTest();
    
    const group = useRef()    
    const { nodes, materials, animations } = glb;
    const { actions } = useAnimations(animations, group)    
    const SkinnedMeshRef = useRef();
    settings = define.settings[props.page];    
    
    //console.log(`1004 FbxModel page => props.texture: ${props.texture} ${props.pintexture} ${showModel}`);


    const boxRef = useRef();
    const [refreshRender, setrefreshRender] = useState(false);
    materials.real_horse_dif01.envMapIntensity = 1.0;
    materials.real_horse_dif01.metalness = 0.5;
    materials.real_horse_dif01.roughness = 0.5;
    materials.real_horse_dif01.clearcoat = 0.5;         
    materials.real_horse_dif01.normalScale.set(1.0, 1.0)
    // materials.real_horse_dif01.normalScale.set(2.5, 2.5)
    // materials.real_horse_dif01.normalScale.set(2.5, 2.5)

    
    if(texind !== props.texture)
    {
      texind = props.texture;
      // materials.real_horse_dif01.color = new THREE.Color("#f00");//.convertSRGBToLinear();
      copyMaterials = materials.real_horse_dif01.clone();    
      LoadTextures(texind);
      ChangeMaterial(texind);
    }
    horsePos = settings.horseposition;
    horseRot = settings.horserotation;    
    horseScale = settings.horseScale;    
    

    const groupName = `HorseExport ${props.texture}`;

    return (
      <group>        
        {/* <DebugTextComponent/>
        <TestComponent></TestComponent> */}
        <group ref={group} {...props} dispose={null}>                  
          <group>
            <group name={groupName}
                rotation={horseRot}
                position={horsePos} 
                scale={ horseScale}
                >              
                <group name="real_horse_body01">                
                  <group
                    name="Bip001"
                    position={[0, 1.065, -0.363]}
                    rotation={[-Math.PI / 2, 0, -1.571]}
                  >
                  <primitive object={nodes.Bip001_Pelvis} />                
                  </group>


                  {!refreshRender || !showModel ? '':                
                  <skinnedMesh ref={SkinnedMeshRef}       
                    castShadow
                    name="h_body"
                    geometry={nodes.h_body.geometry}
                    material={copyMaterials}
                    skeleton={nodes.h_body.skeleton}
                     rotation={[-Math.PI / 2, 0, 0]}
                  />
                  }

                </group>                   
            </group>                  
          </group>                  
        </group>
        {/* <SoftShadows {...config} /> */}
        </group>        
      );
  };

  function TestComponent()
  {
    const color="#18a36e";
    const position=settings.horseposition; //[-1, 0, 3];

    return (

      <mesh position={position}>
        <boxBufferGeometry args={[10, 10, 10]} attach="geometry" />
        <meshPhongMaterial color={color} attach="material" />
      </mesh>
    );
  }

  function ChangeMaterial(textureIndex)
  {
    var copyMat = copyMaterials.map;
    var wrap = copyMat.wrap == undefined ? null : copyMat.wrap;  
    var wrapS = copyMat.wrapS;  
    var wrapT = copyMat.wrapT;  
    var flipY = copyMat.flipY;  
    var encoding = copyMat.encoding;
    var userData = copyMat.userData;       

    copyMaterials.map = textures;
    copyMaterials.map.wrap = wrap;
    copyMaterials.map.wrapS = wrapS;
    copyMaterials.map.wrapT = wrapT;
    copyMaterials.map.flipY = flipY;
    copyMaterials.map.encoding = encoding;
    copyMaterials.map.userData = userData;

    copyMaterials.map.envMapIntensity = 1.5;
    copyMaterials.map.metalness = 0.5;
    copyMaterials.map.roughness = 0.5;
    copyMaterials.map.clearcoat = 0.5;
    
    copyMaterials.transparent = true;
    // copyMaterials.opacity = 0.0;
  }

  function DebugTextComponent()
  {
    return(
      <Text
      rotation={[0,0,0]}
      position={[0,30,0]}
      scale={[10, 10, 10]}
      color="red" // default
      anchorX="center" // default
      anchorY="middle" // default
    >
      {horsePos[0]},{horsePos[1]},{horsePos[2]},
    </Text>
)
}

export default FbxRender;