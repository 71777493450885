import ballmove from './resources/ballmove.mp3'

export const PlaySound = () => {
  // const audio = new Audio(ballmove);
  // audio.loop = false;
  // audio.play();

  console.log('PLAY SOUND');
};

// const rootElement = document.getElementById("root");
// ReactDOM.render(<Sound />, rootElement);

export default PlaySound;