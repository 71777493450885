import tw from 'tailwind-styled-components';

const Input = tw.input`
  h-10 w-full rounded-md
  border border-white/20
  bg-white/10
  text-white
  pl-2
  placeholder:text-right
  placeholder:pr-2
  focus:outline-none focus:border-[#860099] focus:ring-[#860099] focus:ring-1 sm:text-sm
  disabled:opacity-60
`;

export default Input;
