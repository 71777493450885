import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import useLang from '../lib/hook/useLang';

import Pincode from './Pincode';
import useUser from '../lib/hook/useUser';

export default function RegisterWithdrawalInfo() {
  const [_, { updateUser }] = useUser();
  const { lngT } = useLang('mypage:account');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [CPF, setCPF] = useState('');
  const [PIX_ACCOUNT, setPIX_ACCOUNT] = useState('');

  const [isPinCodeWindowOpened, setPinCodeWindowOpened] = useState(false);
  const [isConfirmWindowOpened, setConfirmWindowOpened] = useState(false);

  const [pinCode, setPinCode] = useState();
  const [isPinCodeConfirmed, setPinCodeConfirmed] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleClickRegister = async () => {
    if (!firstName || !lastName || !CPF || !PIX_ACCOUNT || !CPF.length === 11) return;

    setPinCodeWindowOpened(true);
  };

  const handleChangeCPFNumber = event => {
    const { value } = event.target;
    const newValue = value.replace(/[^0-9]/g, '').slice(0, 11);
    setCPF(newValue);
  };
  useEffect(() => {
    if (!firstName || !lastName || !CPF || !PIX_ACCOUNT || !pinCode || !isPinCodeConfirmed) return;

    (async function () {
      setLoading(true);

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/withdrawal/register`,
          {
            firstName,
            lastName,
            CPF,
            PIX_ACCOUNT,
            pincode: pinCode,
          },
          { withCredentials: true }
        );

        if (data.ok) {
          updateUser();
          setPinCodeConfirmed(false);
          toast(lngT('withdrawal:toaster:1'), { theme: 'dark', type: 'success' });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [isPinCodeConfirmed, firstName, lastName, CPF, PIX_ACCOUNT, pinCode, isPinCodeConfirmed]);

  return (
    <div className="flex flex-col w-full space-y-[15px]">
      {isPinCodeWindowOpened && (
        <Pincode
          title={lngT('withdrawal:setting_PINCODE')}
          closePinCode={() => setPinCodeWindowOpened(false)}
          handleEnterCompleted={pinCode => {
            setPinCode(pinCode);
            setPinCodeWindowOpened(false);
            setConfirmWindowOpened(true);
          }}
        />
      )}
      {isConfirmWindowOpened && (
        <Pincode
          title={lngT('withdrawal:confirm_PINCODE')}
          closePinCode={() => {
            setPinCodeWindowOpened(false);
            setPinCode(undefined);
          }}
          handleEnterCompleted={confirmedPinCode => {
            if (confirmedPinCode !== pinCode) {
              return toast(lngT('withdrawal:PINCODE_DOES_NOT_MATCH'), { theme: 'dark', type: 'error' });
            }
            setPinCodeConfirmed(true);
          }}
        />
      )}
      {isLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black/80 z-[99]"></div>
      )}
      <div className="pl-4 font-semibold border-l-4 border-[#860099] text-white text-[16px]">
        {lngT('withdrawal:amountInfo:4')}
      </div>
      <div className="flex flex-col space-y-[6px]">
        <div className="text-white/60 font-semibold">{lngT('withdrawal:title:4')}</div>
        <div className="flex flex-row space-x-[8px]">
          <div className="flex items-center rounded-[8px] bg-white/20 flex-1 h-[40px] px-[16px]">
            <input
              className="w-full bg-transparent placeholder:text-white/30 text-white"
              value={firstName}
              placeholder={lngT('withdrawal:title:5')}
              onChange={e => setFirstName(e.target.value)}
            />
          </div>
          <div className="flex items-center rounded-[8px] bg-white/20 flex-1 h-[40px] px-[16px]">
            <input
              className="w-full bg-transparent placeholder:text-white/30 text-white"
              value={lastName}
              placeholder={lngT('withdrawal:title:6')}
              onChange={e => setLastName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-[6px]">
        <div className="text-white/60 font-semibold">{lngT('withdrawal:title:7')}</div>
        <div className="flex flex-row items-center rounded-[8px] bg-white/20 h-[40px] px-[16px]">
          <input
            className="w-full bg-transparent placeholder:text-white/30 text-white"
            value={CPF}
            placeholder="12345678900"
            onChange={handleChangeCPFNumber}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-[6px]">
        <div className="text-white/60 font-semibold">{lngT('withdrawal:title:8')}</div>
        <div className="flex flex-row items-center rounded-[8px] bg-white/20 h-[40px] px-[16px]">
          <input
            className="w-full bg-transparent placeholder:text-white/30 text-white"
            value={PIX_ACCOUNT}
            placeholder="12345678900"
            onChange={e => setPIX_ACCOUNT(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <div
          className="w-fit cursor-pointer text-white px-5 py-3 rounded-md bg-[#8E00CB] hover:opacity-50"
          onClick={handleClickRegister}
        >
          {lngT('withdrawal:title:9')}
        </div>
      </div>
    </div>
  );
}
