import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import tw from 'tailwind-styled-components';
import { WEBSERVERPORT } from '../../config';
import useLang from '../../lib/hook/useLang';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PostBtn = tw.button`
  flex justify-center items-center
  h-[48px]
  bg-[#860099]
  text-white
  cursor-pointer
  rounded-md
`;

//제목 description 언어

export default function FreeboardWrite() {
  const { lngT } = useLang('community:freeboard');
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState();

  const onSubmit = async ({ title, description }) => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/freeboard`,
        {
          title,
          description,
          lang: i18next.language,
        },
        {
          withCredentials: true,
        }
      );

      if (data.ok) {
        toast(lngT('uploadSuccess'), { type: 'success', theme: 'dark' });
        navigate('/community/freeboard');
      }
    } catch (error) {
      toast(lngT('uploadFail'), { type: 'error', theme: 'dark' });
      console.log(error);
    }
  };

  return (
    <form className="flex flex-col D:mx-[64px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="hidden D:flex flex-row D:justify-between items-center text-[24px] font-medium text-white px-[6px] T:pb-[24px] border-b-2 border-white/20">
        <div className="flex text-[20px] h-[38px]">{lngT('freeBoard')}</div>
      </div>
      <div className="p-[15px] space-y-[12px] border-b border-white/20">
        <div className="text-white text-[14px] font-semibold">{lngT('title')}</div>
        <div className="border border-white/40 rounded-lg">
          <input
            className="w-full bg-transparent px-[20px] py-[8px] caret-white/40 placeholder:text-[14px] text-white font-medium"
            placeholder={lngT('typeTitle')}
            {...register('title')}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1 p-[15px] space-y-[12px]">
        <div className="text-white text-[14px] font-semibold">{lngT('contents')}</div>
        <textarea
          className="w-full h-[300px] bg-transparent px-[20px] py-[8px] caret-white/40 placeholder:text-[14px] text-white border border-white/40 rounded-lg resize-none font-normal"
          placeholder={lngT('typeQuestion')}
          {...register('description')}
        />
        <PostBtn type="submit" disabled={isLoading}>
          {lngT('makePost')}
        </PostBtn>
      </div>
    </form>
  );
}
