const DeviceType = {
    // is Moabile
    pc: {
        win: "win",
        mac: "mac",
        linux: "linux",
    },
    mobile: {
        lg: "lg",
        samsung: "samsung",
        iphone: "iphone",
        ipad: "ipad",
        blackberry: "blackberry",
        mot: "mot",
        android: "android",
    },
    default : {
        k : "lg",
    }
}

const isMobile = /Android|iPhone/i.test(navigator.userAgent);

export function getBrand() {

    const type = isMobile ? "mobile"  : "pc";
    let brand = Object.keys(DeviceType[type]).find(key => navigator.userAgent.toLowerCase().includes(key));

    let brandType = DeviceType[type][brand];
    brandType = brandType ? brandType : getBrandByException();

    return brandType;
}

function getBrandByException() {
    const accessInfo = navigator.userAgent.toLowerCase();
    const deviceInfo = accessInfo.substring(accessInfo.indexOf("(")+1, accessInfo.indexOf(")"));
    const splitDeviceInfo = deviceInfo.split(";");

    const baseOS = splitDeviceInfo[0];
    const os = splitDeviceInfo[1];
    const deviceName = splitDeviceInfo[2];

    const brand = DeviceType.default[deviceName];

    return brand ? brand : "default";
};
