import tw from 'tailwind-styled-components';

const Layer = tw.p`
  text-[10px] font-light text-white/60
`;

type Props = {
  text: string;
};

export default function InfoText({ text }: Props) {
  return <Layer>{text}</Layer>;
}
