import React, { useState, useEffect } from 'react';
import tw from 'tailwind-styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../img/ArrowLineRightIcon.svg';
import HistoryBg from '../../img/powerball/lottohistory_bg.png';
import RewardBg from '../../img/powerball/reward_bg.png';
import RewardBgL from '../../img/powerball/reward_bg_L.png';
import RewardBgM from '../../img/powerball/reward_bg_M.png';
import RewardBtn from '../../img/powerball/reward_button.png';
import RewardClose from '../../img/powerball/reward_close.png';
import RewardCloseL from '../../img/powerball/reward_close_L.png';
import RewardCloseM from '../../img/powerball/reward_close_M.png';

import { useLang } from '../../lib/hook';

const InfoContainer = tw.div`
  relative w-full h-full
  max-[984px]:w-full
  transition-all ease-in-out duration-500
  `;
const InfoHeader = tw.div`
 relative
  w-full h-[50px]
 flex flex-row justify-center items-center
`;

const InfoTitleText = tw.div`
text-white text-base font-medium tracking-wider
uppercase
max-[360px]:text-sm
`;

const ArrowWrap = tw.div`
w-[90%] absolute flex flex-row justify-between z-10
`;
export default function NumberRewardBoard({ resOddsCondition, formattedToday, round }) {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isRewardOpen, setIsRewardOpen] = useState(false);
  const [numberPadWidth, setNumberPadWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { lngT } = useLang('powerball');

  const handlePrevSlide = () => setCurrentIndex(currentIndex === 0 ? currentIndex : currentIndex - 1);
  const handleNextSlide = () => setCurrentIndex(currentIndex === 1 ? currentIndex : currentIndex + 1);

  useEffect(() => {
    const interval = setTimeout(() => {
      setCurrentIndex(prevIndex => (prevIndex === 1 ? 0 : prevIndex + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = document.body.offsetWidth;
      setNumberPadWidth(newWidth);
      console.log('newWidth', newWidth);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [numberPadWidth]);

  const handleClickShowReward = () => {
    if (numberPadWidth < 970) {
      setIsRewardOpen(!isRewardOpen);
    } else if (numberPadWidth > 970 && numberPadWidth < 1426) {
      setIsHistoryOpen(!isHistoryOpen);
      setIsRewardOpen(!isRewardOpen);
    }
  };

  useEffect(() => {
    if (numberPadWidth > 1426) {
      setIsHistoryOpen(true);
    } else {
      setIsHistoryOpen(false);
    }

    if (numberPadWidth > 1426) {
      setIsRewardOpen(true);
    } else {
      setIsRewardOpen(false);
    }
  }, [numberPadWidth]);

  return (
    <InfoContainer className={isRewardOpen ? 'rounded-tl-2xl rounded-tr-2xl' : 'rounded-2xl'}>
      <img alt="historybg" src={`${isRewardOpen ? RewardBg : RewardClose}`} className="w-full h-full absolute z-1" />
      <div
        className="absolute z-[99] w-[250px] h-[50px] left-1/2 -translate-x-1/2 max-[320px]:w-[200px]"
        onClick={handleClickShowReward}
      ></div>
      <InfoHeader>
        <ArrowWrap>
          <div className="w-6 h-6 bg-[#2C386B] rounded-lg relative shadow-lg">
            <ArrowLeftIcon
              className={`cursor-pointer absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
                currentIndex === 0 ? 'opacity-10' : ''
              }`}
              onClick={handlePrevSlide}
            />
          </div>
          <div className="w-6 h-6 bg-[#2C386B] rounded-lg relative shadow-lg">
            <ArrowRightIcon
              className={`cursor-pointer absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
                currentIndex === 1 ? 'opacity-10' : ''
              }`}
              onClick={handleNextSlide}
            />
          </div>
        </ArrowWrap>
        <InfoTitleText>{/*Reward Now*/ lngT('lottery:reward:title')}</InfoTitleText>
      </InfoHeader>
      {isRewardOpen && currentIndex === 0 ? (
        <PrizeCard resOddsCondition={resOddsCondition} formattedToday={formattedToday} round={round} />
      ) : isRewardOpen && currentIndex === 1 ? (
        <PrizeTable resOddsCondition={resOddsCondition} />
      ) : (
        ''
      )}
    </InfoContainer>
  );
}

const PrizeCard = ({ resOddsCondition, formattedToday, round }) => {
  const oddsCondition = Object.entries(resOddsCondition);
  const oddsConditionPowerball = oddsCondition[0];
  console.log('oddsCondition', oddsCondition);
  const point = 100;

  const { lngT } = useLang('powerball');

  console.log('oddsCondition', oddsCondition);
  console.log('oddsConditionPowerball', oddsConditionPowerball);
  return (
    <div className="w-full h-fit flex flex-col items-center justify-center space-y-6 py-[60px] z-10">
      <h2 className="text-white text-center z-10 max-[486px]:text-sm max-[360px]:w-[240px]">
        {/*Now, you have chance to get this money!*/ lngT('lottery:reward:phrase')}
      </h2>
      <div className="relative rounded-2xl w-[367px] h-[160px] overflow-hidden text-white">
        <div className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[355px] h-[147px]"></div>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 flex flex-col justify-center items-center">
          <h4 className="font-semibold text-base uppercase">
            {/*Round*/ lngT('lottery:history:round')} <span>{round}</span>
          </h4>
          <p className="text-sm">{formattedToday}</p>
          <p
            className="text-4xl font-bold leading-[48px] drop-shadow-lg"
            style={{
              background: 'linear-gradient(180deg, #FFC700 0%, #FFF 100%)',
              backgroundClip: 'text',
              webkitBackgroundClip: 'text',
              webkitTextFillColor: 'transparent',
            }}
          >
            {oddsCondition.length > 0 && (oddsConditionPowerball[1][0]?.odds * point).toLocaleString()}
          </p>
          <p className="text-sm font-semibold text-center">
            {lngT('lottery:reward:phrase1')}
          </p>
        </div>
        <div className="w-full h-full rounded-2xl absolute z-1"></div>
        <img
          className="w-full h-full rounded-2xl absolute z-2 max-[420px]:w-[270px] max-[360px]:w-[240px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          alt="rewardbtn"
          src={RewardBtn}
        ></img>
      </div>
    </div>
  );
};

const PrizeTable = ({ resOddsCondition }) => {
  const oddsCondition = Object.entries(resOddsCondition);
  const oddsConditionPowerball = oddsCondition[0];
  const point = 100;

  const { lngT } = useLang('powerball');

  console.log('oddsConditionPowerball', oddsConditionPowerball);
  return (
    <div class="absoulte top-0 z-10 overflow-x-auto max-[420px]:overflow-x-hidden w-full h-fit flex items-center justify-center my-2 mx-1">
      <table class="w-[95%] max-[420px]:w-[92%] text-sm text-left rtl:text-right text-gray-500 font-medium z-10">
        <thead class="text-xs uppercase text-white " style={{ fontWeight: 'lighter' }}>
          <tr className="max-[420px]:text-xs">
            <th
              scope="col"
              class="pl-6 py-2 max-[420px]:px-2 max-[420px]:w-[50px] max-[420px]:text-xs max-[310px]:hidden"
            >
              {/*place*/ lngT('lottery:reward:place')}
            </th>
            <th
              scope="col"
              class="text-start pl-8 pr-2 py-2 max-[420px]:pr-0 max-[420px]:pl-0 max-[420px]:w-[80px] max-[420px]:text-xs"
            >
              {/*Match*/ lngT('lottery:reward:match')}
            </th>
            <th scope="col" class="text-left pr-6 py-2 max-[420px]:px=r-0 max-[420px]:w-[70px] max-[420px]:text-xs">
              {/*prize*/ lngT('lottery:reward:prize')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px] max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][0]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 5 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              <div>+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-red-500 text-red-500 roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][0]?.odds * point).toLocaleString()}
            </td>
          </tr>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][1]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 5 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              <div className="text-transparent">+</div>
              <div className="w-5 h-5 rounded-full flex flex-col items-center justify-center  bg-transparent roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][1]?.odds * point).toLocaleString()}
            </td>
          </tr>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][2]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 4 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              <div>+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-red-500  text-red-500 roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][2]?.odds * point).toLocaleString()}
            </td>
          </tr>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][3]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 4 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              <div className="text-transparent">+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-transparent text-red-500 roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][3]?.odds * point).toLocaleString()}
            </td>
          </tr>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][4]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 3 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              <div>+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-red-500 text-red-500 roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][4]?.odds * point).toLocaleString()}
            </td>
          </tr>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][5]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 2 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              <div>+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-red-500 text-red-500 roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][5]?.odds * point).toLocaleString()}
            </td>
          </tr>

          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][6]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 3 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              {/* <div>+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-red-500 text-red-500 roudned-full"></div> */}
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][6]?.odds * point).toLocaleString()}
            </td>
          </tr>

          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][7]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 2 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white text-transparent">
                    {item}
                  </div>
                );
              })}
              {/* <div>+</div>
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-red-500 text-red-500 roudned-full"></div> */}
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && (oddsConditionPowerball[1][7]?.odds * point).toLocaleString()}
            </td>
          </tr>
          <tr class=" ">
            <td class="pl-6 py-[5.5px] max-[420px]:px-2 text-white text-xs max-[420px]:text-center max-[420px]:w-[50px]  max-[310px]:hidden">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][8]?.condition}
            </td>
            <td class="pl-8 pr-2 py-[5.5px] max-[420px]:px-2 max-[420px]:pl-0 flex flex-row justify-start items-start gap-3 max-[420px]:gap-1">
              {Array.from({ length: 1 }, (_, index) => index + 1).map(item => {
                return (
                  <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center roudned-full bg-red-500 text-transparent">
                    {item}
                  </div>
                );
              })}
              {/* <div className="text-transparent">+</div> */}
              <div className="w-5 h-5 max-[420px]:w-4 max-[420px]:h-4 rounded-full flex flex-col items-center justify-center  bg-transparent text-red-500 roudned-full"></div>
            </td>
            <td class="pr-6 py-[5.5px] max-[360px]:pr-4 max-[360px]:pl-0 max-[360px]:text-xs  text-right text-white max-[420px]:w-[70px]">
              {oddsCondition.length > 0 && oddsConditionPowerball[1][8]?.odds * point}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
