import RandomBetting from './RandomBetting';
import GameResult from '../../pages/games/GameResult-v1';

import { BetGame } from './BetGame';
import { useEffect, useState } from 'react';
import { getCookie } from '../../lib/cookie';
import LobbyGame from './LobbyGame';
import * as config from '../../config';
import * as define from '../../define';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { SetCookie } from './jsUtil_combine';
import { GetCoinTypeToServer } from '../../components/gameui/goods'

var { webSocketIO } = require('../../socketClient.js');

var curURLParam = null;
var showRandomBetting = true;
// var clientData = {};

// const UseSelect=(useSelect)=>
// {
//   clientData.user = useSelect((state) => state.user);
//   clientData.progress = useSelect((state) => state.progress);
//   clientData.lan = useSelect((state) => state.lan);
//   clientData.game = useSelect((state) => state.game);
// }
var g_clientData = {};
var currentgame;
//var showresult = false;
const GameOnepage = () => {
  var dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const [rerender, setrerender] = useState(0);
  const [betProgress, setBetProgress] = useState(0);
  const currentgameSelector = useSelector(state => state.game);
  const [showresult, setshowresult] = useState(false);

  if (g_clientData.user === undefined) {
    const urlparam = getCookie('urlparam');
    var crashed = false;

    if (urlparam === undefined || urlparam.game === undefined) {
      navigate('/LobbyGame');
      crashed = true;
    } else {
      g_clientData.game = parseInt(urlparam.game);
      g_clientData.user = urlparam.user;
      g_clientData.progress = parseInt(urlparam.progress);
      g_clientData.lan = urlparam.lan;
      g_clientData.isHistory = JSON.parse(urlparam.isHistory);
      g_clientData.goodsType = Number(urlparam.goodsType);
    }

    dispatch({
      type: 'CLIENTDATA',
      game: g_clientData.game,
      user: g_clientData.user,
      progress: g_clientData.progress,
      lan: g_clientData.lan,
      isHistory: g_clientData.isHistory,      
    });

    dispatch({
      type: 'GOODSTYPE',
      goodsType: g_clientData.goodsType,
    });
  }

  const [clientData, setclientData] = useState({
    user: useSelector(state => state.user),
    progress: useSelector(state => state.progress),
    lan: useSelector(state => state.lan),
    game: useSelector(state => state.game),
    goodsType: useSelector(state => state.goodsType),
  });

  if (curURLParam == null) {
    curURLParam = clientData;
  }

  useEffect(() => {
    currentgame = currentgameSelector;
  }, [currentgameSelector]);

  const CallBackChangeProgress = (game, progress) => {
    console.log(`progresscombine CallBackChangeProgress game : ${game} progress : ${progress}`);

    if (currentgame === game) {
      g_clientData.progress = progress;
      dispatch({
        type: 'CLIENTDATA',
        progress: g_clientData.progress,
      });
    }
  };

  const CallBackBetToGame = () => {
    g_clientData.progress = define.PROGRESS_LIVE;
    g_clientData.oldprogress = -1;

    SetCookie(g_clientData.game, g_clientData.user, g_clientData.progress, g_clientData.lan, g_clientData.isHistory, g_clientData.goodsType);

    dispatch({
      type: 'CLIENTDATA',
      progress: g_clientData.progress,
      oldprogress: g_clientData.oldprogress,
      goodsType: g_clientData.goodsType,
    });

    setclientData(g_clientData);

    console.log(`progresscombine CallBackBetToGame game : ${curURLParam.game} progressNum : ${define.PROGRESS_LIVE}`);
    setrerender(1);
    curURLParam = g_clientData;
    showRandomBetting = curURLParam.progress === 0 || curURLParam.progress === 1 || curURLParam.progress === 2;
  };

  const CallBackResultToBet = (progressNum, idgame) => {

    console.log('CallBackResultToBet : ' + idgame);

    setshowresult(false);
    g_clientData.progress = define.PROGRESS_INTRO1;
    g_clientData.game = idgame;

    SetCookie(g_clientData.game, g_clientData.user, g_clientData.progress, g_clientData.lan, g_clientData.isHistory, g_clientData.goodsType);

    dispatch({
      type: 'CLIENTDATA',
      progress: g_clientData.progress,
      game: g_clientData.game,
      goodsType: g_clientData.goodsType,
    });
    setclientData(g_clientData);
    setrerender(0);
    curURLParam = g_clientData;
    showRandomBetting = curURLParam.progress === 0 || curURLParam.progress === 1 || curURLParam.progress === 2;

    webSocketIO.send(
      JSON.stringify({
        protocol: 'reqUserInit',
        coin: define.GoodsTypeServer[g_clientData.goodsType],
      })
    );
  };

  const CallBackGameToResult = () => {
    // g_clientData.progress = define.PROGRESS_RESULT;
    // dispatch({
    //   type: 'CLIENTDATA',
    //   showresult: true,
    // });
    // setclientData(g_clientData);
    // curURLParam = g_clientData;
    setshowresult(true);
    setBetProgress(g_clientData.progress);

    console.log('CallBackGameToResult : ' + g_clientData.game);
  };

  useEffect(() => {
    console.log('GameOnePage version - 10/12 11:19');

    showRandomBetting = g_clientData.progress === 0 || g_clientData.progress === 1 || g_clientData.progress === 2;

    setrerender(showRandomBetting ? 0 : 1);
  }, []);

  var token = getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
  if (token === undefined || token === 'deleted') {
    navigate('/LobbyGame');
    return;
  }
  
  return (
    <div className="relative">
      <BetGame
        // setunityloaded={setunityloaded}
        rerender={rerender}
        setrerender={setrerender}
        CallBackResultToBet={CallBackResultToBet}
        CallBackGameToResult={CallBackGameToResult}
        betProgress={betProgress}
        clientData={g_clientData}
      />
      <RandomBetting
        CallBackResultToBet={CallBackResultToBet}
        CallBackBetToGame={CallBackBetToGame}
        CallBackChangeProgress={CallBackChangeProgress}
        showRandomBetting={showRandomBetting}
        goodsType={g_clientData.goodsType}        
        // showRandomBetting={true}
      />
      {showresult ? <GameResult CallBackResultToBet={CallBackResultToBet} /> : ''}
    </div>
  );
};

export default GameOnepage;
