import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import useUser from '../../lib/hook/useUser';

import PointCharge from '../../components/PointCharge';
import Withdrawal from '../../components/Withdrawal';
import AccountHistory from '../../components/AccountHistory';
import useLang from '../../lib/hook/useLang';

const SubMenu = tw.div`
  flex flex-row  items-center justify-center
  flex-1
  border border-white/20
  px-[20px] py-[10px]
  space-x-2
  cursor-pointer
  text-white text-[10px] T:text-[14px]
  ${({ $isCurrentMenu }) => ($isCurrentMenu ? 'border-t-[#860099] border-t-2 border-b-0' : '')}
`;

export default function Account() {
  const { lngT } = useLang('mypage:account');
  const navigate = useNavigate();
  const [user] = useUser();
  const [currentSubMenu, setSubMenu] = useState('charge');

  const subMenus = [
    {
      title: lngT('submenus:1'),
      value: 'charge',
      onClick: () => setSubMenu('charge'),
    },
    {
      title: lngT('submenus:2'),
      value: 'withdrawal',
      onClick: () => setSubMenu('withdrawal'),
    },
    {
      title: lngT('submenus:3'),
      value: 'hitory',
      onClick: () => setSubMenu('hitory'),
    },
  ];

  useEffect(() => {
    if (!user) navigate('/login');
  }, [user, navigate]);

  return (
    <div className="flex flex-col rounded-xl D:mx-[64px]">
      <div className="hidden D:flex flex-row T:justify-between items-center text-[24px] font-medium text-white T:pb-[24px] ">
        <div className="text-[20px]">{lngT('title')}</div>
      </div>
      <div className="flex flex-row w-full mt-[12px]">
        {subMenus?.map(menu => (
          <SubMenu key={menu.value} $isCurrentMenu={menu.value === currentSubMenu} onClick={() => menu?.onClick()}>
            {menu.title}
          </SubMenu>
        ))}
      </div>
      <div className="my-12">
        {currentSubMenu === 'charge' ? (
          <PointCharge />
        ) : currentSubMenu === 'withdrawal' ? (
          <Withdrawal />
        ) : (
          <AccountHistory />
        )}
      </div>
    </div>
  );
}
