import {
  Html,
  ContactShadows,
  Environment,
  OrbitControls,
  useProgress,
  Lightformer,
  useHelper,
  SoftShadows,
  Sky,
} from '@react-three/drei';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import * as define from '../../../define'
import { BrightnessContrast, 
  ToneMapping, 
  SSAO, SMAA, SSR, 
  EffectComposer, 
  HueSaturation,
  DepthOfField, 
  LUT, 
  Bloom, 
  Noise, 
  Vignette } from '@react-three/postprocessing'
import { BlendFunction,
  ToneMappingMode } from 'postprocessing'
import { useControls } from 'leva'
// import { Direction } from "react-toastify/dist/utils";

export function CustomLight(props) {
  const pointLightSize = 0.4;
  const refSpotlight = useRef();
  const refpointlight = useRef();
  // let spotLightHelper = useHelper(refSpotlight, THREE.SpotLightHelper, 'red');
  // let PointLightHelper = useHelper(refpointlight, THREE.PointLightHelper, pointLightSize, 'white');

  const targetObject = new THREE.Object3D();
  let x = -0.1;
  let y = 0;
  let z = 0;
  targetObject.position.set(x, y, z-5);
  
  return (
    <group>                 
      <CustomDirectionLight intensity = {1.0} target={[0,0,0]} position = {[0, 10, 0]} ></CustomDirectionLight>
      <spotLight 
        position={[0, 1.6, 0]} 
        angle={1.0} 
        penumbra={0.0} 
        intensity={4.0} 
        // decay={0.0}
        distance={2.0}/>
      <pointLight position={[0.6, 0.8, 0]} intensity={pointLightSize} color={'#ffffff'}/>
      <pointLight position={[-0.6, 0.8, 0]} intensity={pointLightSize} color={'#ffffff'} />      
      {/* <pointLight position={[-5.0, -1.0, 0.0]} intensity={pointLightSize} color={'#ffffff'} ref={refpointlight}/> */}
      
      <CustomAmbientLight intensity={props.ambientIntensity}></CustomAmbientLight>      

      { props.curState > 5 && props.curState < 8 && < spotLight 
        position={[x, y, z+5]}
        angle={0.3}
        penumbra={0.0}
        intensity={2.0}
        decay={0.0}
        distance={5.0}
        target={targetObject}
        ref = {refSpotlight}/>}
    </group>
  );
}

export function CustomEnv( props ) {
  var fileName = `/horsestall/bg/2d/${'inside_01'}.png`;   
  var loader = new THREE.TextureLoader();
  // var texture = loader.load(fileName);
  // var revision = (1190 / 550) * 2.9;
  // var w = 2103;
  // var h = 1190;

  return ( 
    <group  
      >      
      <Environment resolution={512}
        preset='apartment'
        background={props.background}       
      >
      </Environment>
    </group>   
  );
}


// export function CustomEnv( props ) {
//   var fileName = `/horsestall/bg/2d/${'inside_01'}.png`;   
//   var loader = new THREE.TextureLoader();
//   // var texture = loader.load(fileName);
//   // var revision = (1190 / 550) * 2.9;
//   // var w = 2103;
//   // var h = 1190;

//   return ( 
//     <group  
//       // position={[100,-5,-100]}
//       // scale={[753/2.5, 426/2.5,1]}
//       >
//       {/* <sprite>
//         <spriteMaterial 
//         map={texture} />
//       </sprite> */}

//       <Environment 
//         // preset='apartment'
//         // background = {props.background}
//       >
//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, -9]}
//           scale={[10, 1, 1]}
//         />

//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, -6]}
//           scale={[10, 1, 1]}
//         />
//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, -3]}
//           scale={[10, 1, 1]}
//         />
//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, 0]}
//           scale={[10, 1, 1]}
//         />
//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, 3]}
//           scale={[10, 1, 1]}
//         />
//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, 6]}
//           scale={[10, 1, 1]}
//         />
//         <Lightformer
//           intensity={2}
//           rotation-x={Math.PI / 2}
//           position={[0, 4, 9]}
//           scale={[10, 1, 1]}
//         />


//         <Lightformer
//           intensity={2}
//           rotation-y={Math.PI / 2}
//           position={[-50, 2, 0]}
//           scale={[100, 2, 1]}
//         />
//         <Lightformer
//           intensity={2}
//           rotation-y={-Math.PI / 2}
//           position={[50, 2, 0]}
//           scale={[100, 2, 1]}
//         />

//         <Lightformer
//           form="ring"
//           color="white"
//           intensity={20}
//           scale={5}
//           position={[10, 5, 10]}
//           onUpdate={(self) => self.lookAt(0, 0, 0)}
//         />
//       </Environment>
      
//     </group>   
//   );
// }

// export function CustomEnv({ perfSucks }) {  
  
//   return (
//     <Environment      
//       // position={[10,0,0]}
//       SoftShadows={true}
//       receiveShadow={true}
//       files={'/horsestall/bg/bg2.hdr'}
//       ground={{ height: 25, radius: 110, scale: 1300 }}
      
//       // ground={{ height: 32, radius: 130 }}
//       // ground={{ height: 12, radius: 90 }}
//     >     
//     </Environment>
//   );
// }

export function CustomPointLight() {
  const positions = [
    [-0.1, 0.1, 0],
    [0.2, -0.0, 0],
  ];
  const size = 0.3;
  const intensity = 50;
  const color = 'white';

  const pointLightRefs = [useRef(null), useRef(null)];

  // useHelper(pointLightRefs[0], PointLightHelper, size, color);
  // useHelper(pointLightRefs[1], PointLightHelper, size, color);

  return (
    <group>
      <pointLight ref={pointLightRefs[0]} intensity={intensity} distance={size} position={positions[0]} color={color} />
      <pointLight ref={pointLightRefs[1]} intensity={intensity} distance={size} position={positions[1]} color={color} />
    </group>
  );
}

// export function CustomShadowLight(){
//   return (
//     <spotLight angle={0.5} castShadow position={[-80, 200, -100]} intensity={0} shadow-mapSize={[515, 512]} />
//   )
// }

export function CustomShadowLight(){
  //-24, 34, -12
  // const shadowResolution = 0.5;
  return (
    <spotLight 
      angle={0.5} 
      castShadow 
      position={[1,1,0]}
      //position={[-120*shadowResolution, 170*shadowResolution, -60*shadowResolution]} 
      intensity={0} 
      shadow-mapSize={[515, 512]} />
  )
}

export function CustomSpotLight() {
  const targetObject = new THREE.Object3D();
  targetObject.position.set(0, 0, 0);

  const positions = [
    [0.0, 0.0, 0.7],
    [0.0, 0.0, -0.7],
  ];
  const size = 1.0;
  const intensity = 2.5;
  const color = 'white';
  const angle = 1.7;
  //   penumbra?: number,
  // decay?: number,

  const spotLightRefs = [useRef(null), useRef(null)];

  // useHelper(spotLightRefs[0], SpotLightHelper, size, color);
  // useHelper(spotLightRefs[1], SpotLightHelper, size, color);

  return (
    <group>
      <spotLight
        ref={spotLightRefs[0]}
        intensity={intensity}
        distance={size}
        position={positions[0]}
        color={color}
        angle={angle}
        target={targetObject}
      />
      <spotLight
        ref={spotLightRefs[1]}
        intensity={intensity}
        distance={size}
        position={positions[1]}
        color={color}
        angle={angle}
        target={targetObject}
      />
    </group>
  );
}

function CustomAmbientLight(props) {
  return (<group>
    <ambientLight 
    intensity={props.intensity} 
    color={'#ffffff'} 
    />
  </group>)
}

function CustomDirectionLight(props) {
  // const targetObject = new THREE.Object3D();
  // targetObject.position.set(0,0,0);

  // const positions = [[0.0,0.0,0.7], [0.0,0.0,-0.7]];
  // const size = 1.0;
  // const intensity = 2.5;
  // const color = 'white';
  // const angle = 1.7;
  const targetObject = new THREE.Object3D();
  targetObject.position.set(props.target[0], props.target[1], props.target[2]);

  return (
    <group>
      {/* <directionalLight position={[0,10,0]} intensity={1.0}/>  */}
      {/* <fog attach="fog" args={["white", 1, 1]} /> */}
      <directionalLight
        name="Directional_Light"
        intensity={props.intensity}
        decay={2}
        color="#ddd8ca"
        position={props.position}
        target={targetObject}

        castShadow={true}
        shadow-mapSize-height={128}
        shadow-mapSize-width={128}                
        specular
        
      >
        {/* <group position={[0, 0, 0]} /> */}
      </directionalLight>
    </group>
  );
}

export function CustomPostEffect(props) {
  return (
    // <group></group>

    <group>
      <EffectComposer disableNormalPass>
        <Bloom mipmapBlur luminanceThreshold={1} intensity={1} />
        <HueSaturation hue={0} saturation={-0.25} />
        <Vignette
          offset={0.5} // vignette offset
          darkness={0.3} // vignette darkness
          eskil={false} // Eskil's vignette technique
          blendFunction={BlendFunction.NORMAL} // blend mode
        />
        <BrightnessContrast
          brightness={-0.2} // brightness. min: -1, max: 1
          contrast={0.4} // contrast: min -1, max: 1
        />              

      </EffectComposer> 

        {/* <LUT lut={texture} /> */}
        {/* <BrightnessContrast brightness={-0.2} contrast={0.3} /> */}
        {/* <ToneMapping mode={ToneMappingMode.ACES_FILMIC} /> */}

    </group>
  );
}

// export function CustomPostEffectPowerBall(props) {
//   return (
//     // <group></group>

//     <group>
//       <EffectComposer disableNormalPass>
//         <Bloom mipmapBlur luminanceThreshold={1} intensity={1} />
//         <HueSaturation hue={0} saturation={-0.25} />
//         <Vignette
//           offset={0.5} // vignette offset
//           darkness={0.3} // vignette darkness
//           eskil={false} // Eskil's vignette technique
//           blendFunction={BlendFunction.NORMAL} // blend mode
//         />
//         <BrightnessContrast
//           brightness={-0.2} // brightness. min: -1, max: 1
//           contrast={0.4} // contrast: min -1, max: 1
//         />              
//       </EffectComposer> 
//     </group>
//   );
// }

// export function CustomPostEffect_Old(props) {
//   return (
//     // <group></group>

//     <group>
//     <EffectComposer disableNormalPass>
//       {/* <CustomSSR></CustomSSR> */}

//       <Bloom
//           luminanceThreshold={0.2}
//           mipmapBlur
//           luminanceSmoothing={0}
//           intensity={1}
//           //intensity={1.75}
//         />
//       <Vignette
//         offset={0.5} // vignette offset
//         darkness={0.5} // vignette darkness
//         eskil={false} // Eskil's vignette technique
//         blendFunction={BlendFunction.NORMAL} // blend mode
//       />
//       <BrightnessContrast
//         brightness={-0.3} // brightness. min: -1, max: 1
//         contrast={0.4} // contrast: min -1, max: 1
//       />      
//     </EffectComposer>

//     </group>
//   );
// }

// export function CustomPostEffect2(props) {
//   return (
//     // <group></group>

//     <group>
//     <EffectComposer disableNormalPass>
//       {/* <CustomSSR></CustomSSR> */}
//       <Bloom
//           luminanceThreshold={0.2}
//           mipmapBlur
//           luminanceSmoothing={0}
//           intensity={1}
//           //intensity={1.75}
//         />
//       <Vignette
//         offset={0.5} // vignette offset
//         darkness={0.5} // vignette darkness
//         eskil={false} // Eskil's vignette technique
//         blendFunction={BlendFunction.NORMAL} // blend mode
//       />
//       <BrightnessContrast
//         brightness={-0.3} // brightness. min: -1, max: 1
//         contrast={0.4} // contrast: min -1, max: 1
//       />
      
//     </EffectComposer>

//     </group>
//   );
// }

function CustomSSR()
{
  const { enabled, ...props } = useControls({
    enabled: true,
    temporalResolve: true,
    STRETCH_MISSED_RAYS: true,
    USE_MRT: true,
    USE_NORMALMAP: true,
    USE_ROUGHNESSMAP: true,
    ENABLE_JITTERING: true,
    ENABLE_BLUR: true,
    DITHERING: false,
    temporalResolveMix: { value: 0.9, min: 0, max: 1 },
    temporalResolveCorrectionMix: { value: 0.4, min: 0, max: 1 },
    maxSamples: { value: 0, min: 0, max: 1 },
    resolutionScale: { value: 1, min: 0, max: 1 },
    blurMix: { value: 0.2, min: 0, max: 1 },
    blurKernelSize: { value: 8, min: 0, max: 8 },
    BLUR_EXPONENT: { value: 10, min: 0, max: 20 },
    rayStep: { value: 0.5, min: 0, max: 1 },
    intensity: { value: 2.5, min: 0, max: 5 },
    maxRoughness: { value: 1, min: 0, max: 1 },
    jitter: { value: 0.3, min: 0, max: 5 },
    jitterSpread: { value: 0.25, min: 0, max: 1 },
    jitterRough: { value: 0.1, min: 0, max: 1 },
    roughnessFadeOut: { value: 1, min: 0, max: 1 },
    rayFadeOut: { value: 0, min: 0, max: 1 },
    MAX_STEPS: { value: 20, min: 0, max: 20 },
    NUM_BINARY_SEARCH_STEPS: { value: 6, min: 0, max: 10 },
    maxDepthDifference: { value: 5, min: 0, max: 10 },
    maxDepth: { value: 1, min: 0, max: 1 },
    thickness: { value: 3, min: 0, max: 10 },
    ior: { value: 1.45, min: 0, max: 2 },
  });
  
  return(<SSR {...props} />)
}

export function CustomOrbit() {
  var PolarAngle = Math.PI / 2.25-0.1;
  var AzimuthAngle = -0.87;
  var minPolarAngle;
  var maxPolarAngle;
  var minAzimuthAngle;
  var maxAzimuthAngle;

  minPolarAngle = PolarAngle;
  maxPolarAngle = PolarAngle;
  minAzimuthAngle = AzimuthAngle;
  maxAzimuthAngle = AzimuthAngle;

  return (
    <OrbitControls     
    target={[0,14,0]}
    enableZoom={false} 
    enablePan={false} 
    minPolarAngle={minPolarAngle} 
    maxPolarAngle={maxPolarAngle} 
    minAzimuthAngle={minAzimuthAngle}
    maxAzimuthAngle={maxAzimuthAngle}
    makeDefault />
  );
}