import { createContext } from 'react';

export type Context<T = any> = {
  data: T[];
  setData: (data: T[]) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
};

const initial: Context = {
  data: [],
  setData: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
};

export default createContext<Context>(initial);
