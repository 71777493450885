import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useNavigate, useSearchParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import { env } from '@/env';
import { useLang, useUser } from '@/lib/hook';
import Pincode from '@/components/Pincode';
import { toast } from 'react-toastify';
import { genRestfulContainer } from '@/lib/RestfulContainer';
import SpinnerLoader from '@/components/SpinnerLoader';

const Container = tw.section`
  flex flex-col justify-center
  w-[400px]
`;

const Title = tw.div`
  flex justify-center
  font-semibold text-[30px]
  mt-[20px] mb-[20px]
  text-white
`;

export default function ResetPincodeView() {
  const navigate = useNavigate();
  const { lngT } = useLang('mypage:userInfo');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [pinCode, setPinCode] = useState<string | null>(null);
  const [openAgainPopup, setOpenAgainPopup] = useState(false);

  const { error } = useSWR(token ? `${env.api.host}/auth/validate?token=${token}` : null);

  const UpdatePincodeContainer = genRestfulContainer({
    url: `${env.api.host}/withdrawal/pincode/reset`,
    method: 'PATCH',
  });

  useEffect(() => {
    if (!token) {
      alert(lngT('reset:invalid'));
      navigate('/');
    }
    if (error) {
      alert(lngT('reset:expired'));
      navigate('/');
    }
  }, [token, error]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container>
        <Title>{lngT('withdrawal:reset:title')}</Title>
        {!openAgainPopup && (
          <Pincode
            title={lngT('withdrawal:reset:info')}
            handleEnterCompleted={pinCode => {
              setPinCode(pinCode);
              setOpenAgainPopup(true);
            }}
          />
        )}
        {openAgainPopup && (
          <UpdatePincodeContainer
            callback={{
              onSuccess: () => {
                toast(lngT('withdrawal:reset:success'), { type: 'success', theme: 'dark' });
                navigate('/mypage/userinfo');
              },
              onFail: () => {
                toast(lngT('withdrawal:reset:fail'), { type: 'error', theme: 'dark' });
              },
            }}
          >
            {({ call, isLoading }) => (
              <div>
                {isLoading ? (
                  <div className="absolute left-0 top-0 flex justify-center items-center w-full h-full bg-black z-[99]">
                    <SpinnerLoader className="w-[50px] h-[50px] fill-white" />
                  </div>
                ) : (
                  <Pincode
                    title={lngT('withdrawal:reset:again')}
                    handleEnterCompleted={againPincode => {
                      if (againPincode === pinCode) {
                        call({ pinCode, token });
                      } else {
                        toast(lngT('withdrawal:reset:matchFail'), { type: 'error', theme: 'dark' });
                      }
                    }}
                  />
                )}
              </div>
            )}
          </UpdatePincodeContainer>
        )}
      </Container>
    </div>
  );
}
