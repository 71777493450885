import React, { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';

import useLang from '../../lib/hook/useLang';

const InputContainer = tw.div`
flex flex-col space-y-3
`;

const Label = tw.label`
text-white font-xs`;

const Input = tw.input`
h-10 w-full rounded-md
border border-white/20
bg-white/10
text-white text-sm font-light
pl-2
placeholder:text-left
placeholder:text-white/60
focus:outline-none focus:border-[#860099] focus:ring-[#860099] focus:ring-1 sm:text-sm" 
`;

const Button = tw.button`
flex justify-center items-center
w-[100px]
h-10
bg-white/10
rounded-lg
text-white/80 text-sm
hover:bg-white/20
active:bg-white/10  active:border-2 active:border-white/80
transition duration-200
cursor-pointer
`;

const ErrorText = tw.p`
text-sm text-[#EB4335]
`;

const SuccessText = tw.p`
text-sm text-[#24A148]
`;

export default function UserNickname({ name }) {
  const { lngT } = useLang('mypage:userInfo');

  const [isClick, setIsClick] = useState(false);
  const [nickName, setNickName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clickEditBtn = () => {
    setIsClick(!isClick);
  };

  const handleNickChange = e => {
    setNickName(e.target.value);
  };

  const handleSaveClick = () => {
    const regex = /^[^0-9]*$/;

    if (regex.test(nickName) && nickName.length <= 6) {
      setError('Must be over 6 characters and include number');
      console.log('error', error);
    } else {
      setIsLoading(true);

      setTimeout(() => {
        setIsClick(false);
        setSuccess('You have successfully changed your name.');
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    let timer;
    if (error || success) {
      timer = setTimeout(() => {
        setError('');
        setSuccess('');
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [error, success]);

  return (
    <div>
      <InputContainer className="flex flex-col">
        <Label>{lngT('nickname')}</Label>
        <div className="flex flex-row gap-2">
          <Input type="text" value={name} disabled />
          {/* {isClick ? (
            <Input type="text" placeholder={lngT('enterName')} value={name} onChange={handleNickChange} />
          ) : (
            <Input type="text" value={name} disabled />
          )} */}
          {/* <Button onClick={isClick ? handleSaveClick : clickEditBtn}>
            {isLoading ? 'Loading..' : isClick ? 'Save' : 'Edit'}
          </Button> */}
        </div>
        {error && <ErrorText>{error}</ErrorText>}
        {success && <SuccessText>{success}</SuccessText>}
      </InputContainer>
    </div>
  );
}
