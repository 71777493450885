import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      background: 'transparent',
      width: 600,
    },
  },
  gutter: {
    display: 'flex',
    height: 1,
    background: 'rgba(255, 255, 255, 0.2)',
    margin: '16px 0',
  },
  gutterDisable: {
    '@media all and (max-width:668px)': {
      display: 'none',
    },
  },
  buttonClose: {
    '&.MuiButtonBase-root': {
      justifyContent: 'flex-end',
    },
  },
  dialogStyle: {
    background: '#121839',
    color: '#fff',
    borderRadius: 8,
    '& .MuiDialogTitle-root': {
      margin: '24px 32px 8px',
      paddingBottom: 8,
      fontSize: '1.25rem',
      fontWeight: 500,
      textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
      padding: '0 32px',
      fontSize: '1.5rem',
      lineHeight: '1.75',
    },
    '& .MuiDialogActions-root': {
      padding: '8px 8px 32px 8px',
      justifyContent: 'space-around',
    },
    '@media (min-width:280px) and (max-width:667px)': {
      '& .MuiDialogTitle-root': {
        margin: '8px 0 0',
        fontSize: '1rem',
      },
      '& .MuiDialogContent-root': {
        padding: '0 24px',
      },
    },
  },
  titleStyle: {
    '&.MuiTypography-root': {
      fontSize: '0.875rem',
      fontWeight: 400,
      marginTop: 16,
      marginBottom: 8,
      textTransform: 'uppercase',
    },
    '@media (min-width:280px) and (max-width:667px)': {
      '&.MuiTypography-root': {
        fontSize: '0.75rem',
      },
    },
  },
  textBoxStyle2: {
    '&.MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      padding: '4px 16px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      border: '1px solid rgba(255, 255, 255, 0.4)',
      borderRadius: 8,
      fontSize: '0.975rem',
      color: '#fff',
      letterSpacing: 9,
    },
  },
  textBoxStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 40,
    marginBottom: 16,
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '&.MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px 16px',
      fontSize: '0.875rem',
      color: 'rgba(255, 255, 255, 0.6)',
      marginBottom: 16,
    },
    '@media (min-width:280px) and (max-width:667px)': {
      '&.MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        justifyContent: 'flex-start',
        lineClamp: 3,
      },
    },
  },
  boxStyle: {
    borderBottom: '1px solid #71648A',
    padding: '4px 0',
    '& .MuiTypography-root': {
      paddingRight: 10,
      fontSize: '0.935rem',
      fontWeight: 'bold',
      wordBreak: 'break-all',
      color: '#EDC244',
      textDecoration: 'none',
      lineHeight: 1.125,
    },
    '& .MuiButton-root': {
      fontSize: '0.813rem',
      backgroundColor: '#998ab6',
      color: '#2c1a4d',
      borderColor: '#2c1a4d',
      textTransform: 'none',
      minWidth: 54,
      padding: '5px 10px',
      '&:hover': {
        borderColor: '#2c1a4d',
        backgroundColor: '#a28fc6',
        color: '#2c1a4d',
      },
    },
  },
  numberStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    fontSize: '0.873rem',
    borderRadius: 50,
    color: '#fff',
    backgroundColor: '#546739',
    '&:nth-child(1)': {
      backgroundColor: '#DFA100',
    },
    '&:nth-child(2)': {
      backgroundColor: '#7E7E7E',
    },
    '&:nth-child(3)': {
      backgroundColor: '#734A39',
    },
    '@media (min-width:280px) and (max-width:667px)': {
      width: 20,
      height: 20,
      fontSize: '0.75rem',
    },
  },
  headerStyle: {
    paddingTop: 16,
    textAlign: 'center',
    '& .MuiTypography-h3': {
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1,
      textTransform: 'uppercase',
    },
    '& .MuiTypography-subtitle': {
      fontSize: '0.99rem',
      color: '#9B896C',
      letterSpacing: '-0.4px',
    },
    '@media (min-width:280px) and (max-width:512px)': {
      '& .MuiTypography-h3': {
        fontSize: '0.875rem',
      },
      '& .MuiTypography-subtitle': {
        display: 'flex',
        lineHeight: 1.25,
        marginTop: 8,
      },
    },
  },
  ftFieldStyle: {
    border: '1px solid rgba(255, 255, 255, 0.5)',
    borderRadius: 8,
    padding: '0px 16px 16px',
    marginTop: 16,
    marginBottom: 32,
    '@media (min-width:280px) and (max-width:667px)': {
      padding: '0px 16px',
      marginBottom: 16,
    },
  },
  numberStyle2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    fontSize: '0.873rem',
    borderRadius: 50,
    color: '#fff',
    backgroundColor: '#9A9A9A',
    '&:last-child': {
      backgroundColor: '#ef4444',
    },
    // '&:nth-child(2)': {
    //   backgroundColor: '#7E7E7E',
    // },
    // '&:nth-child(3)': {
    //   backgroundColor: '#734A39',
    // },
    '@media (min-width:280px) and (max-width:667px)': {
      width: 20,
      height: 20,
      fontSize: '0.75rem',
    },
  },
  inputStyle: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent:'space-around',
    width: 'calc(100% - 104px)',
    height: 40,
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    '&.MuiTextField-root': {
      marginBottom: 8,
      '& .MuiInputBase-root': {
        width: '100%',
      },
      '& input': {
        color: '#fff',
        fontSize: '0.875rem',
        padding: '11px 8px',
      },
      '& fieldset': {
        border: '0 none',
        color: '#fff',
      },
    },
    '@media (min-width:280px) and (max-width:512px)': {
      width: 'calc(100% - 74px)',
      fontSize: '0.75rem',
    },
  },
  buttonStyle: {
    '&.MuiButton-root': {
      minWidth: 94,
      height: 40,
      marginBottom: 8,
      backgroundColor: '#9B896C',
      color: '#fff',
      border: '0 none',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: '#77674D',
      },
      '@media (min-width:280px) and (max-width:512px)': {
        minWidth: 64,
        fontSize: '0.75rem',
      },
    },
  },
  btnConfirmStyle: {
    '&.MuiButton-root': {
      // width:'100%',
      margin: '0 20px',
      padding: '8px 40px',
      color: '#fff',
      fontSize: '1rem',
      fontWeight: 400,
      textTransform: 'none',
      border: '0 none',
      borderRadius: 8,
      background: '#7C9B3F',
      '&:hover': {
        backgroundColor: '#7C9B3F',
        opacity: '0.8',
        border: '0 none',
      },
    },
  },
}));
