import SpinnerLoader from '@/components/SpinnerLoader';

const HorseStakeLoadingUI = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <SpinnerLoader className="w-[100px] h-[100px]" />
    </div>
  );
};

export default HorseStakeLoadingUI;
