import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLang from '../../lib/hook/useLang';
import PowerBallBg from '../../img/powerball/select_bg.jpg';
import BettingBG from '../../img/powerball/select_betting_bg.png';
import BettingBtn from '../../img/powerball/select_betting_button.png';
import LottoBG from '../../img/powerball/select_lotto_bg.png';
import LottoBtn from '../../img/powerball/select_lotto_button.png';
import PowerballBar from '../../img/powerball/select_bar.png';
import { SpineRenderCommon } from '../../util/SpineRenderCommon';
import { SetCoinType, ToggleCoinType } from '../../components/gameui/goods';
import { GoodsType } from '../../define';
import * as define from '../../define';
import { Bonus } from '@/components/gameui/bonus';
import { Point } from '@/components/gameui/point';
import { ChangePagePowerBall } from '../../horsestall/progresscombine/jsUtil_combine';
import { toast } from 'react-toastify';
import { CheckMaintenence } from '../../util/maintenenceUtil'


let intervalID;
export default function LobbyPowerBall() {
  const { lngT } = useLang('powerball');  
  const { lngT : lngTetc } = useLang('etcs');
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [goodsType, setgoodsType] = useState();
  

  const handleToggleChange = () => {
    let tooglegoodsType = ToggleCoinType(dispatch, goodsType);
    setgoodsType(tooglegoodsType);
  };
  
  useEffect(() => {
    SetCoinType(dispatch, setgoodsType);
    CheckMaintenence(lngTetc);
    intervalID = setInterval(() => {
      CheckMaintenence(lngTetc);  
    }, 60 * 1000);
    
    return ()=>{
      clearInterval(intervalID);
    }
  }, []);
  
  useEffect(() => {
    if (!define.USE_FREECOIN) return;
    if(goodsType === undefined) return;

    console.log('goodsType : ' + goodsType);

    const goodsMsg = goodsType === GoodsType.CASHCOIN ? 
    lngTetc('common:pointgamemode') : lngTetc('common:freegamemode');
    
    toast.dismiss();
    toast(goodsMsg, {      
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "dark",
      type:"info",
      onOpen: (()=>{
        console.log('open');
      })
      });

    setMessage(
      
      // <div className="absolute left-1/2 -translate-x-1/2 top-1 translate-y-[10px] w-[400px] h-12 z-10 -bottom-10 right-7 flex flex-row items-center justify-between">       
      //   <img 
      //     className="absolute w-[800px] h-full" src={PowerballBar} alt="PowerballBar" />
      //   <p
      //   style={{
      //     color:'#ffffff',
      //     fontWeight:'bold'
      //   }}
        
      //   className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[30px] text-xl text-white-0 bg-pink capitalize -top-[20px] text-base  w-full text-center h-full">
      //     {goodsMsg}
      //     <br/>            
      //   </p>
      // </div>
    );

    // const timer = setTimeout(() => {
    //   setMessage('');
    // }, 1500);

    // return () => clearTimeout(timer);
  }, [goodsType]);

  return (
    <div className="max-T:portrait:h-[900px] max-T:landscape:h-[400px] h-[1280px] w-full relative flex flex-row MD:items-start MD:justify-center T:pt-[100px] pt-[48px] items-start justify-center max-[768px]:h-full max-T:gap-0">      
      <img
        src={PowerBallBg}
        alt="bg"
        className="absolute w-full h-full top-0 left-0 z-1 object-cover max-[768px]:h-[1550px]"
      />      
      <div className="absolute flex flex-col justify-center items-center px-6 gap-3 max-[786px]:gap-2 max-[420px]:gap-10">

        {/* <div className="max-sm:w-[300px] w-[550px]">
        <SpineRenderCommon path="powerball/lobby" file="powerball_logo_ani" aniName="logo_ani"></SpineRenderCommon>
        </div> */}

        <div className="max-sm:w-[400px] w-[550px]">        
          <SpineRenderCommon
            path="powerball/landing/logo"
            file="upowerball_logo_ani"
            aniName="logo_ani"
          ></SpineRenderCommon>
        </div>
        <div className="grid T:grid-cols-2 max-T:grid-rows-1 max-D:landscape:grid-cols-2 gap-10 max-[786px]:gap-0 max-[486px]:gap-3">
          {/* lotto */}
          <div
            className="relative cursor-pointer hover:brightness-90 mx-auto max-[400px]:w-[280px]"
            onClick={() => {
              CheckMaintenence(lngTetc, ()=>{})
              ChangePagePowerBall('/powerball/lottery', goodsType)
            }}
          >
            <img src={LottoBG} alt="LottoBG" />
            <button
              className="absolute top-1/2 translate-y-1/2 left-1/2 -translate-x-1/2 hover:scale-[1.1]"
              onClick={() => {
                CheckMaintenence(lngTetc, ()=>{})
                ChangePagePowerBall('/powerball/lottery', goodsType)
              }}
            >
              <img className="" src={LottoBtn} alt="LottoBtn" />
              <p className="text-3xl font-bold absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white max-[486px]:text-xl">
                {/*START*/ lngT('lobby:start')}
              </p>
            </button>
          </div>

          {/* betting */}
          <div className="relative max-sm:top-10 sm:top-10 md:top-0  h-full flex flex-col space-y-2">
            <div
              className="relative cursor-pointer hover:brightness-90 mx-auto max-[400px]:w-[280px]"
              onClick={() => {
                CheckMaintenence(lngTetc, ()=>{})
                ChangePagePowerBall('/powerball/betting', goodsType)
              }}
            >
              <img
                className={`rounded-2xl ${
                  goodsType === GoodsType.CASHCOIN
                    ? 'sepia saturate-200'
                    : goodsType === GoodsType.FREECOIN
                    ? 'brightness-125'
                    : 'brightness-100'
                }`}
                src={BettingBG}
                alt="LottoBG"
              />
              <div>
                <button
                  className="absolute top-1/2 translate-y-1/2 left-1/2 -translate-x-1/2 hover:scale-[1.1]"
                  onClick={() => {
                    CheckMaintenence(lngTetc, ()=>{})
                    ChangePagePowerBall('/powerball/betting', goodsType)
                  }}
                >
                  <img className="" src={BettingBtn} alt="LottoBtn" />
                  <p className="text-3xl font-bold absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white max-[486px]:text-xl">
                    {lngT('lobby:start')}
                  </p>
                </button>
              </div>
            </div>
            {message}
            <CoinToggle
              goodsType={goodsType}
              handleToggleChange={()=>{handleToggleChange()}}
            ></CoinToggle>
          </div>
        </div>
      </div>
    </div>
  );
}

function CoinToggle(props){
  const { lngT : lngTGameHistory } = useLang('mypage:gameHistory');
  return (<div>
  {define.USE_FREECOIN && (              
    <div className="absolute left-1/2 -translate-x-1/2 top-1 -translate-y-[50px] w-[180px] h-12 z-10 -bottom-10 right-7 flex flex-row items-center justify-between">      
      <p className='absolute -left-[55px] text-white text-xl'>
        {lngTGameHistory('table:points')} 
      </p>                      
      <Point size={10} toggleType={true}/>
      <label className="relative inline-flex items-center cursor-pointer">
        <input 
          type="checkbox" 
          checked={props.goodsType===GoodsType.FREECOIN} 
          className="sr-only peer" 
          onChange={props.handleToggleChange} />
        <div className="w-[90px] h-10 ring-2 ring-purple-500 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-purple-500 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-[160%] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-purple-500 after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-8 after:w-8 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-600"></div>
      </label>      
      <Bonus size={10} toggleType={true}/>
      <p className='absolute translate-x-48 text-white text-xl'> 
        {lngTGameHistory('table:freePoints')}
      </p>      
    </div>    
  )}</div>)
}