import React, { ReactNode, useCallback, useState } from 'react';

type QueryMeta = {
  paginate?: {
    currentPage: number;
    perPage: number;
    totalCount: number;
  };
  sortBy?: 'ASC' | 'DESC';
  filterBy?: {
    [key: string]: string;
  };
  includes?: string;
  date?: {
    startDate: Date;
    endDate: Date;
  };
};

type ResWithError = {
  message: string;
  error: string;
  statusCode: number;
};

type ResWithQueryMeta<T> = {
  meta?: QueryMeta;
  data: T;
};

type Props = {
  url: string;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  withCredential?: boolean;
  headers?: HeadersInit;
};

interface ContainerProps<T> {
  children: ({
    data,
    isLoading,
    error,
    call,
    reset,
  }: {
    data?: ResWithQueryMeta<T>;
    isLoading: boolean;
    error?: ResWithError;
    call: (body?: any) => void;
    reset: () => void;
  }) => ReactNode;
  callback?: {
    onSuccess?: (data: T) => void;
    onFail?: (error: ResWithError) => void;
  };
}

export default function genRestfulContainer<T>({ url, method, withCredential = true, headers }: Props) {
  const Container = React.memo(function Container({ children, callback }: ContainerProps<T>) {
    const [data, setData] = useState<ResWithQueryMeta<T>>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<ResWithError>();

    const call = async (body?: any) => {
      setLoading(true);

      try {
        const response = await fetch(url, {
          method,
          ...((method === 'POST' || method === 'PATCH') && body ? { body: JSON.stringify(body) } : {}),
          headers: {
            'Content-Type': 'application/json',
            ...(headers ? headers : {}),
          },
          credentials: withCredential ? 'include' : 'omit',
        });

        const res = await response.json();

        if (res.hasOwnProperty('message')) {
          const error = res as ResWithError;
          setError(error);
        } else {
          const data = res as ResWithQueryMeta<T>;
          setData(data);
          callback?.onSuccess?.(data.data);
        }
      } catch (error) {
        console.log(error);
        const errorObj = {
          error: 'Request Timeout',
          message: 'Request Failed',
          statusCode: 408,
        };
        setError(errorObj);
        callback?.onFail?.(errorObj);
      } finally {
        setLoading(false);
      }
    };

    const reset = useCallback(() => {
      setData(undefined);
      setLoading(false);
      setError(undefined);
    }, []);

    return (
      <>
        {children({
          data,
          isLoading,
          error,
          call,
          reset,
        })}
      </>
    );
  });

  return Container;
}
