import useLang from '@/lib/hook/useLang';
import { SpineRenderCommonByID } from '../../util/SpineRenderCommon';
import * as define from '../../define';

type Props = {
    onClick: () => void;
};

export default function CoinDropButtonMobile({ onClick }: Props) {
    const { isExists, currentLang } = useLang();
    let uselang = currentLang;
    console.log(currentLang);

    if(!isExists)
        uselang = 'en';

    if(!define.SHOW_METACROSS_ONLY)
        return;

    return (
        <div className="flex flex-row justify-end flex-1" onClick={onClick}>
            <SpineRenderCommonByID
                path="powerball/landing/free_button_m"
                file="free_button"
                aniName={`bp_${uselang}_ani`}
                id="player-container4"
                className="cursor-pointer rounded-full text-purple-600 w-[70px] T:w-[70px] h-16 flex flex-row items-center justify-end mt-2"
            ></SpineRenderCommonByID>
        </div>
    );
}
