"use client"
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/new/slot_machine_loop.glb
*/
import { useState } from 'react';
import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useControls } from "leva"
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { SpinState, CanSpin } from '../../util/reeldefine.js'
import { AudioPlay, IsPlaying } from '@/util/audiomanager.js';
import {useReelGameStore} from "@/store/ReelGameStore";

const BLENDER_FPS = 30;
let elapsedTime = 0;
let totalFrame = 0;
const loopEndFrameIndex = 5;
const eventFrameRates = [0, 2.0, 4.0, 6.0, 8.0, 10.0,
  12.0, 14.0, 16.0, 18.0, 20.0];
const soundeventFrameRates = [21,41,61];
const timeScale = 1.0;
let beforefindval = 0;
let wildsoundindex = 0;

export function Slotmachine3x3(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/assets/slotmachine3x3.glb')
  const { actions } = useAnimations(animations, group)
  const {isIphone, isIOSMute} = useReelGameStore();

  // const configs = useControls('SETANI',
  // {
  //   pauseAni: {value:false}
  // })

  useEffect(()=>{
    let action = actions['Animation'];
    action.timeScale = timeScale;
    action._mixer.addEventListener('finished', OnFinishedAction)
    action.clampWhenFinished = true;
    // action.play();
  },[])

  function OnFinishedAction(param, actFinish)
  {
    if(props.spinState === SpinState.REWARD)
      return;

    // console.log('OnFinishedAction ' + param);
    // console.log('OnFinishedAction');

    console.log(`TESTSPIN  : OnFinishedAction SPIN-${props.spinState}`);

    props.SetReelGameAniStep(0);
    props.setspinState(SpinState.REWARD);


    let action = actions['Animation'];
    if(actFinish)
      actFinish();
  }

  function ResetAction(action)
  {
    action.time = 0;
    action.reset();
  }

  const IsFrameStopIndex=(curFrame)=>
  {
    const findval = soundeventFrameRates.find((item)=>{return (item<=Number(curFrame.toFixed(0)) && (Number(item) > Number(beforefindval)))});
    let soundname = '';
    if(findval)
    {
      const findidx = soundeventFrameRates.findIndex((item)=>item===findval);
      const isWild = (props.serverResult[1][findidx] === 0 ||
        props.serverResult[2][findidx] === 0 ||
        props.serverResult[3][findidx] === 0)
      if(findval > beforefindval)
      {
        console.log(`SOUNDFRAME : ${curFrame} / findval:${findval} / b-findval${beforefindval} / W:${isWild} /soundidx:${wildsoundindex} /findidx:${findidx}`);

        beforefindval = findval;

        if(isWild)
        {
          ++wildsoundindex;
          soundname =  `wild${wildsoundindex}`;
        } else {
          soundname =  'itemstop';
        }
      }
      // beforefindval = findval;


      // const findidx = soundeventFrameRates.findIndex((item)=>item===findval);
      // console.log('findidx : ' + findidx);



      // if(props.serverResult[1][findidx] === 0 ||
      //   props.serverResult[2][findidx] === 0 ||
      //   props.serverResult[3][findidx] === 0)
      // {
      //   ++wildsoundindex;

      //   // console.log('wildsoundindex : ' + wildsoundindex + ',' + 'findidx : ' + findidx);
      //   return `wild${wildsoundindex}`;
      // }
      // else
      // {
      //   return 'itemstop';
      // }
    }

    return soundname;
  }

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max)
  const curIndex = props.reelGameAniStep;
  useFrame((state,delta,frame)=>{
    if(CanSpin(props.spinState))
      return;

    let action = actions['Animation'];
    totalFrame = action._clip.duration * BLENDER_FPS;
    let curFrameRate = action.time / action._clip.duration;
    let curFrame = curFrameRate * totalFrame;

    // console.log(`TESTSPIN ${curFrame} = ${action.time} / ${action._clip.duration} * ${totalFrame} SPIN:${props.spinState}`);

    // console.log(`curFrame/curFrameRate : ' + ${curFrame}/${curFrameRate}/`);

    // FORCE SET FRAME
    // let loopEndframeRate = (eventFrameRates[0] / totalFrame); // 0, 2.5, 4.5, 6.5, 8.5, 10.5
    // let loopEndframeRate = (13 - 0.5 / totalFrame); // 0, 2.5, 4.5, 6.5, 8.5, 10.5 //  - 0.5
    // action.time = action._clip.duration * loopEndframeRate;

    let eventFrameRate = eventFrameRates[curIndex+1];
    if(curFrameRate * totalFrame > eventFrameRate)
    {
      // dispatch
      let nextIndex = curIndex + 1;
      if((nextIndex === loopEndFrameIndex) && SpinState.SPINNING === props.spinState)
      {
        console.log('TESTSPIN : ResetAction');
        ResetAction(action);
        nextIndex = 0;
      }

      props.SetReelGameAniStep(nextIndex);
      // console.log('ttest : ' + curIndex + '/' + nextIndex);
      // console.log('nextIndex : ' + nextIndex);
      // console.log(`spinstate/nextIndex : ${props.spinState}${nextIndex}`);
    }

    if(SpinState.STOPPING === props.spinState)
    {
      action.loop = THREE.LoopOnce;
      action._loopCount = action.loop;
      action._interpolantSettings.endingEnd = action._loopCount;
      action._interpolantSettings.endingStart = action._loopCount;

      // FORCE STOP PROCESS
      if(props.isforcestop)
      {
        if(curFrame < eventFrameRates[loopEndFrameIndex+1])
        {
          elapsedTime += 0.1;
          const x = clamp(elapsedTime, 0, 1.0);//Math.min( 0.5, elapsedTime );
          action.timeScale = Math.sqrt(2 - Math.pow(x - 1, 2)) * (1.5+x);
        }
        else if(curFrame < eventFrameRates[loopEndFrameIndex+4])
        {
          action.timeScale = Math.max(1, Math.sqrt( action.timeScale ));
        }
        else
        {
          action.timeScale = timeScale;
          elapsedTime = 0;
        }
      }

      const itemframestopaudioname = IsFrameStopIndex(curFrame);
      if(itemframestopaudioname !== '')
      {
        // console.log(`TESTSPIN ${curFrame} = ${action.time} / ${action._clip.duration} * ${totalFrame} SPIN:${props.spinState}`);
        console.log('itemstop play ' + curFrame);
        // props.serverResult[1].find((element)=>{return element === 0})
        console.log('itemstop play : ' + itemframestopaudioname);

        if(!IsPlaying(itemframestopaudioname))
          AudioPlay(itemframestopaudioname, false, isIphone, isIOSMute);
      }
    }
    // console.log(`curFrame/curFrameRate[totalFrame] : ' + ${curFrame}/${curFrameRate}[${totalFrame}]`);
  })

  const meshRef = useRef(null);
  useEffect(()=>{
    if(SpinState.NONE === props.spinState)
      return;

    const action = actions['Animation'];
    if(SpinState.STOPPING === props.spinState)
    {
      console.log('TESTSPIN ACTIONPLAY action.loop: ' + action._loopCount);
      action._loopCount = action.loop;
      wildsoundindex = 0;
    }

    // if(SpinState.SPINNING === props.spinState)
    // {
    //   wildsoundindex = 0;
    // }

    if(SpinState.SPINNING === props.spinState)
    {
      console.log('SOUNDFRAME TESTSPIN ACTIONPLAY: ' + props.spinState);

      action.time = 0;
      wildsoundindex = 0;
      beforefindval = 0;

      action.reset();
      action.play();
    }
    // console.log('ttest : ' + props.spinState + '/' + curIndex);
    // wildsoundindex = 0;
  },[props.spinState])

  useFrame(()=>{
    console.log();
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="back_empty" position={[0, 0, -0.17]}/>
        <mesh ref = {meshRef}
          name="Background"
          castShadow
          receiveShadow
          geometry={nodes.Background.geometry}
          material={materials.Background}
          rotation={[Math.PI / 2, 0, 0]}
          visible={true}
        />
        <group name="w01h02" position={[-0.05, 0.05, 0]} />
        <group name="w01h01" position={[-0.05, 0.1, 0]} />
        <group name="w01h03" position={[-0.05, 0, 0]} />
        <group name="w01h04" position={[-0.05, -0.05, 0]} />
        <group name="w01h05" position={[-0.05, -0.1, 0]} />
        <group name="w02h05" position={[0, -0.1, 0]} />
        <group name="w02h04" position={[0, -0.05, 0]} />
        <group name="w02h03" />
        <group name="w02h02" position={[0, 0.05, 0]} />
        <group name="w02h01" position={[0, 0.1, 0]} />
        <group name="w03h05" position={[0.05, -0.1, 0]} />
        <group name="w03h04" position={[0.05, -0.05, 0]} />
        <group name="w03h03" position={[0.05, 0, 0]} />
        <group name="w03h02" position={[0.05, 0.05, 0]} />
        <group name="w03h01" position={[0.05, 0.1, 0]} />
      </group>
    </group>
  )
}

// useGLTF.preload('/assets/slotmachineloop.glb')
