import { toast } from 'react-toastify';
import useSWR from 'swr';
import useLang from './useLang';

import { setCookie } from '../cookie';
import { ACCESS_TOKEN_NAME, NEED_REFRESH_STORE_NAME } from '../../config';
import { env } from '@/env';

const useUser = () => {
  const { lngT } = useLang('auth:login');
  const { data, mutate, error } = useSWR(`${process.env.REACT_APP_API_URL}/user/me`, {
    fallbackData: { user: true },
  });

  return [
    !error ? data?.user : null,
    {
      isLoading: true,
      error,
      login: accessToken => {
        setCookie(ACCESS_TOKEN_NAME, accessToken, {
          path: '/', // 모든 경로에 적용
          maxAge: 30 * 24 * 60 * 60, // 30일 유지
          ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
        });
        localStorage.removeItem(NEED_REFRESH_STORE_NAME);
        mutate();
      },
      logout: () => {
        setCookie(ACCESS_TOKEN_NAME, 'deleted', {
          path: '/', // 모든 경로에 적용
          maxAge: 30 * 24 * 60 * 60, // 30일 유지
          ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
        });
        localStorage.removeItem(NEED_REFRESH_STORE_NAME);
        toast(lngT('login:2:toast:2'), { theme: 'dark', type: 'success' });
        mutate();
      },
      updateUser: mutate,
    },
  ];
};

export default useUser;
