import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function EventListLayout({ children }: Props) {
  return (
    <div className="flex flex-row justify-center w-full h-full">
      <div className="w-full max-w-[1200px] h-[800px]">{children}</div>
    </div>
  );
}
