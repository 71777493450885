import styles from './apostouGanhou.module.css';
import Arrow from '@/img/landingPage/arrowButton.svg';
import Plataform from '@/img/plataforma.png';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

import useLang from '@/lib/hook/useLang';

export default function ApostouGanhou() {
  const { lngT } = useLang('landingPage:faq');
  const navigate = useCustomNavigate();

  return (
    <div className={styles.container}>
      <img src={Plataform} alt="Apostou ganhou" className={styles.image} />
      <div className={styles.textContainer}>
        <h1 className={styles.h1}>
          {lngT('betWin:title:1')} <span className={styles.spanH1}>{lngT('betWin:title:2')}</span>
        </h1>
        <p className={styles.p}>{lngT('betWin:phrase')}</p>
        <button onClick={() => navigate('/signup')} className={styles.styledButton}>
          <img src={Arrow} alt="Arrow" /> {lngT('betWin:button')}
        </button>
        <p className={styles.buttonRodape}>{lngT('betWin:buttonP')}</p>
      </div>
    </div>
  );
}
