import * as config from '../config';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

export default function LogoButton({ signup, mobile, className }) {
  const navigate = useCustomNavigate();

  return (
    <div className="cursor-pointer" onClick={() => navigate('/')}>
      {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? (
        <img
          className={className}
          // src={`/images/${!mobile ? 'logo.png' : 'logo-gray.png'}`}
          // src={`/images/${!signup ? 'newLogo_purple.png' : 'newLogo_green.png'}`}
          src={`/images/${!signup ? 'logo_xmas.png' : 'newLogo_green.png'}`}
          // width={130}
          // height={24}
          width={90}
          height={24}
          alt=""
        />
      ) : (
        <img
          className="w-[120px] h-[60px]"
          src={`/images/upowerball/${!signup ? 'up7bet_logo.png' : 'up7bet_logo_1.png'}`}
          alt=""
        />
      )}
    </div>
  );
}
