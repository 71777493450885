import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import {
  Html,
  ContactShadows,
  Environment,
  useGLTF,
  OrbitControls,
  useProgress,
  Lightformer,
  useHelper,
  SoftShadows,
  PerspectiveCamera,
  AccumulativeShadows,
  RandomizedLight,
  Plane,
} from '@react-three/drei';
import {
  CustomOrbit,
  CustomPostEffect,
  CustomPointLight,
  CustomSpotLight,
  CustomEnv,
  CustomLight,
} from './CustomEnvironment';
import { CustomGeometrys } from './CustomGeometrys';
import { PresetHeadComponent } from './PresetHeadComponent';
import * as CustomUtil from './CustomUtil';
import SpinnerLoader from '@/components/SpinnerLoader';
import * as THREE from 'three';
import { useSelector } from "react-redux";

function Loader() {
  // console.log('@@@@loader runnubg/');
  // const { progress } = useProgress()
  // return <Html center>{progress} % loaded</Html>
  return <Html center></Html>;
}
var _anitime = 0;
var _beforeanitime = 0;
var conopacity = 1.0;
var getImg;
var opacity;

function CustomCanvas(props) {
  const [perfSucks, degrade] = useState(false);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const [shadowDirection, setShadowDirection] = useState(new THREE.Vector3(-2, 0, 0));

  const inout = props.item.horse.nextRaceDate === undefined ? 'out' : 'in';
  const backid = props.item.horse.id % 2 + 1;
  const envpage = `${inout}side_0${backid}`;
  const envhorsetexture = props.item.model.texture
  
  const horsetexture = useSelector((state)=>state.horsetexture);
  const horsepage = useSelector((state)=>state.horsepage);

  const [render,setrender] = useState(0);
  useEffect(()=>{
    setShadowDirection(new THREE.Vector3(200, 0, 0));
    
  },[])

  const HandleChangeImg=(DataURL)=>{    
    // zindex = 2;
    opacity = 1.0;
    conopacity = 1.0;
    _beforeanitime = _anitime+200;    
    getImg = DataURL;
    // opacityanimate = true;
  }

  console.log("CustomCanvas refresh");


  const HandleChangeImgComp=()=>{
    // setrender(render+1);    
  }

  return (
    <div
      id="canvas-container"
      style={{
        position: isDesktop ? 'absolute' : 'relative',
        width: '100%',
        maxHeight: '550px',
        height: '550px',
        zIndex: '1',
      }}
      className="flex object-cover"
    >
      {/* <ScreenImg
        HandleChangeImgComp = {HandleChangeImgComp}
      ></ScreenImg> */}
      <Canvas       
        shadows
        gl={{
          preserveDrawingBuffer: true
        }}>
        {/* <ChangeScreenImg></ChangeScreenImg> */}
        <CustomEnv 
          page={envpage} 
        />
        <CustomLight 
          perfSucks={perfSucks}
          page={horsepage}>
        </CustomLight>

        <Suspense fallback={<Loader />}>
          <CustomGeometrys
            page={horsepage}
            texture={envhorsetexture}
            pintexture={horsetexture}
            horseShape={props.horseShape}
            sethorseShape={props.sethorseShape}
            defaulthorseShape={props.defaulthorseShape}
            HandleChangeImg={HandleChangeImg}
          />                    
          <Plane receiveShadow position={[-15.5, 0.5, -100]} rotation-x={-Math.PI / 2} args={[1500, 1500]}>
            <shadowMaterial opacity={0.65} />
          </Plane>          
        </Suspense>        
        <CustomOrbit />
        <PerspectiveCamera makeDefault position={[-30, 100, 20]} fov={35}  />
      </Canvas>
    </div>
  );
}

// function ChangeScreenImg()
// {
//   const { gl } = useThree();

//   useFrame(()=>{
//     if(needchangeScreenImage)
//     {
//       console.log('ChangeScreenImg');
//       getImg = gl.domElement.toDataURL();
//       needchangeScreenImage = false;
//     }
//   })

//   console.log('ChangeScreenImg');
//   return (<group></group>)
// }

// var needchangeScreenImage = false;
// var opacityanimate = false;
// var zindex = '2';
// function ScreenImg(props)
// {
//   const [render, setrender] = useState(0);
//   const requestRef = useRef(0);

//   useEffect(() => {
//     console.log('requestAnimationFrame Start: ' + opacity);
//     requestRef.current = requestAnimationFrame(animate);
//     return () => cancelAnimationFrame(requestRef.current);
//   }, []);

//   const animate = (anitime) => {
//     _anitime = anitime;

//     var timediff = _anitime - _beforeanitime;
    
//     if(timediff > 1000)
//       timediff = 1000;
    
//     requestRef.current = window.requestAnimationFrame(animate);

//     if(!opacityanimate)
//       return;
    
//     if(opacity <= 0)
//     {
//       console.log('requestAnimationFrame conopacity: ' + opacity);
//       opacityanimate = false;
//       opacity = 1;
//       conopacity = 1;
//       zindex = 0;
//       needchangeScreenImage = true;
//       props.HandleChangeImgComp();
//     }
//     else
//     {
//       conopacity = (1000 - timediff ) / 1000;
//       opacity = conopacity;
      
//     }
//     setrender(conopacity);
//   }

//   if(!getImg)
//   {
//     console.log('getImg null');
//     return;
//   }

//   return ( <img
//       src={getImg}
//       alt=""
//       style={{       
//         position:'absolute',
//         width: `${100}%`,
//         height: `${100} %`,
//         // height: `${550 * conopacity} px`,
//         objectFit: 'contain',
//         zIndex:zindex,            
//         opacity:opacity
//       }}
//     />);
// }

// function CustomContactShadows()
// {
//   const contactShadows = new ContactShadows({
//     shadowDirection: new THREE.Vector3(0, 1, 0),
//   });

//   return (<group>
//     {contactShadows}
//   </group>)
// }

// function CustomCamera() {
//   const cameraRef = useRef();
//   const camera = useThree(state => state.camera);

//   // useHelper(camera, CameraHelper);

//   return <group></group>;
// }

export default CustomCanvas;
