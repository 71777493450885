import CTARentButton from './CTA';
import styles from './rent.module.css';
import RentListCard from './RentListCard';

import useLang from '@/lib/hook/useLang';

const HowToDoRent = () => {
  const { lngT } = useLang('landingPage:guideSection');
  return (
    <div className={styles.container}>
      <div className="max-[320px]:pl-[20px]">
        <h3>{lngT('guideRent:header:subTitle')}</h3>
        <p>{lngT('guideRent:header:subsubTitle')}</p>
      </div>
      <RentListCard />
      <CTARentButton />
    </div>
  );
};

export default HowToDoRent;
