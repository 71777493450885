import { SERVICE_TYPE } from '../config';
import { useEffect } from 'react';

export default function AnjouanLicenseLogo() {
  useEffect(() => {
    if (SERVICE_TYPE) {
      // @ts-ignore
      anj_39bb159b_db3d_4bbc_80d4_45cbe13b713a.init();
    } else {
      // @ts-ignore
      anj_6cbaabf0_db94_4f0f_883a_e8d835dac272.init();
    }
  }, []);

  return (
    <div className="flex flex-row items-center space-x-2 w-full my-2">
      {SERVICE_TYPE ? (
        <div
          className="w-[30px] h-[30px]"
          id="anj-39bb159b-db3d-4bbc-80d4-45cbe13b713a"
          data-anj-seal-id="39bb159b-db3d-4bbc-80d4- 45cbe13b713a"
          data-anj-image-size="128"
          data-anj-image-type="basic-small"
        ></div>
      ) : (
        <div
          className="w-[30px] h-[30px]"
          id="anj-6cbaabf0-db94-4f0f-883a-e8d835dac272"
          data-anj-seal-id="6cbaabf0-db94-4f0f-883a- e8d835dac272"
          data-anj-image-size="128"
          data-anj-image-type="basic-small"
        ></div>
      )}

      <div className="text-white text-xs">Anjouan Licensing Services Inc.</div>
    </div>
  );
}
