import { useEffect, useState } from 'react';
import { ReactComponent as ShuffleIcon } from '../assets/icons/shuffle.svg';
import { ReactComponent as BackIcon } from '../assets/icons/back.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

function shuffle(array: number[]) {
  for (let index = array.length - 1; index > 0; index--) {
    const randomPosition = Math.floor(Math.random() * (index + 1));
    const temporary = array[index];
    array[index] = array[randomPosition];
    array[randomPosition] = temporary;
  }
  return array;
}

const shuffled = shuffle(numbers);

export default function Pincode({
  title,
  closePinCode,
  handleEnterCompleted,
}: {
  title: string;
  closePinCode?: () => void;
  handleEnterCompleted: (pincode: string) => void;
}) {
  const [nums, setNums] = useState(shuffled);
  const [enteredNums, setEnteredNums] = useState<number[]>([]);

  const handleClickShuffle = () => {
    const shuffled = shuffle(numbers);
    setNums([...shuffled]);
  };

  const handleClickBackSpace = () => {
    const copied = Array.from(enteredNums);
    copied.pop();
    setEnteredNums(copied);
  };

  const handleClickNumber = (num: number) => () => {
    setEnteredNums([...enteredNums, num]);
  };

  useEffect(() => {
    if (enteredNums.length === 6) {
      handleEnterCompleted(JSON.stringify(enteredNums));
      setEnteredNums([]);
    }
  }, [enteredNums]);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black/80 z-[99]">
      <div className="flex flex-col max-w-[500px]">
        <div className="flex w-full justify-end">
          {closePinCode && <CloseIcon className="cursor-pointer" onClick={closePinCode} />}
        </div>
        <div className="flex flex-col items-center px-[30px] py-[64px] space-y-[64px] bg-[#1C012E]">
          <div className="flex flex-col space-y-[64px]">
            <div className="flex justify-center text-white text-[20px]">{title}</div>
            <div className="relative flex flex-row justify-center">
              <div className="absolute flex flex-row space-x-[26px]">
                {Array.from({ length: 6 }).map((_, i) => (
                  <PinPoint key={i} isEntered={false} />
                ))}
              </div>
              <div className="absolute flex flex-row space-x-[26px]">
                {Array.from({ length: 6 }).map((_, i) => (
                  <PinPoint key={i} isEntered={i < enteredNums.length} />
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-[44px] pt-[24px] border-t border-white/20">
            {nums.map(
              (num, i) => i < nums.length - 1 && <PinButton key={num} number={num} onClick={handleClickNumber(num)} />
            )}
            <div className="flex justify-center items-center w-[80px] h-[80px] text-[28px] text-white">
              <button
                className="flex justify-center items-center w-full h-full rounded-full hover:bg-[#420864]/80"
                onClick={handleClickShuffle}
              >
                <ShuffleIcon />
              </button>
            </div>
            <PinButton number={nums[nums.length - 1]} onClick={handleClickNumber(nums[nums.length - 1])} />
            <div className="flex justify-center items-center w-[80px] h-[80px] text-[28px] text-white">
              <button
                className="flex justify-center items-center w-full h-full rounded-full hover:bg-[#420864]/80"
                onClick={handleClickBackSpace}
              >
                <BackIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PinPoint = ({ isEntered }: { isEntered: boolean }) => (
  <div className={`w-[14px] h-[14px] rounded-full ${isEntered ? 'bg-[#D470FF]' : 'bg-white/20'}`} />
);

const PinButton = ({ number, onClick }: { number: number; onClick: () => void }) => {
  return (
    <div className="flex justify-center items-center w-[80px] h-[80px] text-[28px] text-white">
      <button
        className="flex justify-center items-center w-full h-full rounded-full hover:bg-[#420864]/80"
        onClick={onClick}
      >
        {number}
      </button>
    </div>
  );
};
