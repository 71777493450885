import tw from 'tailwind-styled-components';

import { useState } from 'react';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModalStore } from '@/lib/custom-lib/modal';
import useLang from '@/lib/hook/useLang';

import { ReactComponent as ChevronRightIcon } from '../../../../assets/icons/chevron-right.svg';

import Custom from '../../../../horsestall/Custom';
import { formatDateToLocal } from './../../../../lib/utils';

const InfoTitle = tw.p`
  text-md text-white font-light uppercase
`;

const InfoText = tw.p`
  text-md text-[#F14EA7] font-medium
`;

const LeaseInfoTitle = tw.p`
 text-sm text-white/40
`;

const LeaseInfoText = tw.p`
  text-xl fornt-medium text-white
`;

const LeaseInfoUnit = tw.p`
  text-sm font-light  text-white/80
`;

const LeaseBtn = tw.button`
  flex justify-center items-center
  w-full h-auto
  py-3 px-1
  text-white
  cursor-pointer
  rounded-lg
  hover:bg-opacity-80
`;

const GraphText = tw.p`
  D:text-[12px] text-[10px] text-white/80 font-light 
  max-[400px]:rotate-[45deg] max-[400px]:text-[8px] 
`;

const HorseInfoRow = tw.div`
  flex flex-row justify-between items-center px-2 bg-[#EEC3FF]/10 py-2 rounded-md
`;
const HorseInfoContent = tw.div`
  flex flex-row items-center space-x-1.5
`;
const HorseInfoImg = tw.img`
w-4 h-4 opacity-70
`;

const HorseImgSection = tw.section`
relative w-full D:w-2/3 T:h-full h-fit D:ml-[48px] D:mt-[48px]
`;

const HorseInfoSection = tw.section`
  w-full D:h-full  D:mt-[48px]
`;

const HorseStakeSection = tw.section`
  w-full h-fit bg-[#554FFF]/10 rounded-bl-lg rounded-br-lg px-8 py-8 space-y-6 D:flex D:flex-row D:space-y-0 
`;

type Props = {
  item: HorseStakeInfoItem;
  modalContent: ReactNode;
};

const HorseStakeInfoItemUI = ({ item, modalContent }: Props) => {
  const { lngT } = useLang('horse:info');
  const { openModal } = useModalStore();
  const { model, horse, stake } = item;
  const stakeOption = stake.title;
  const navigate = useNavigate();

  const rankArray = JSON.parse(horse.latestRank);

  const rankBall = rankArray.map((rank: number, index: number) => {
    return (
      // <span key={index}>{rank}</span>
      <div
        key={index}
        className="bg-black/40 text-center w-7 h-7 rounded-full flex items-center justify-center text-sm"
      >
        <div>{rank}</div>
      </div>
    );
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const [modalOpenAnimation, setModalOpenAnimation] = useState(false);

  console.log('horseItem', horse);
  console.log(typeof horse.accReward);

  return (
    <div className="flex flex-col w-full h-fit mb-6 space-y-2 mt-10 T:mt-2">
      <div className="relative flex flex-col D:flex D:flex-row w-full h-fit D:h-[600px] bg-[#48096D] bg-opacity-30 rounded-tl-lg rounded-tr-lg overflow-hidden">
        <HorseImgSection>
          <Custom texture={model.texture} item={item}></Custom>
        </HorseImgSection>
        <div className="relative D:w-1/3 w-full D:mr-[48px]">
          {/* mobile button */}
          <div className="block D:hidden w-full h-[48px] my-4">
            <button
              className={`relative flex justify-center items-center w-full h-full px-[24px] text-[#222] text-[16px] font-bold bg-gradient-to-r from-white/10 via-white to-white/10 rounded-lg cursor-pointer ${
                isModalOpen ? 'rounded-b-none' : ''
              }`}
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setModalOpenAnimation(!modalOpenAnimation);
              }}
            >
              {/*horse Info*/ lngT('title')}

              <ChevronRightIcon
                className={`absolute text-white text-bold right-[24px] top-1/2 -translate-y-1/2 w-[16px] h-[16px] ${
                  isModalOpen ? 'rotate-[270deg]' : 'rotate-90'
                }`}
              />
            </button>
          </div>
          <section
            className={`${modalOpenAnimation ? 'T:animate-fadeIn' : 'T:animate-fadeOut'} ${
              isModalOpen ? 'block' : 'hidden'
            } flex flex-col justify-center D:hidden `}
          >
            <HorseInfoSection className="relative z-[10]">
              <div className="bg-[#48096D]/30 D:bg-[#48096D]/50 D:h-[550px] h-fit px-4 D:px-0 MD:px-4 py-10 flex flex-col D:justify-between">
                <h2 className="ml-2 text-3xl text-[#F14EA7] pb-4">{horse.name}</h2>
                <div className="absolute top-0 right-[24px] drop-shadow-lg w-fit rounded-b-md bg-gradient-to-r from-violet-900 from-25% via-fuchsia-700 via-70% to-fuchsia-800 px-3 py-2.5">
                  <p className="text-white/80 flex flex-col items-center text-sm T:text-sm text-center tracking-wide">
                    {stakeOption}
                  </p>
                </div>
                <div className="space-y-2 ml-2 mr-2">
                  <div className="flex flex-row space-x-2 mb-4 flex-wrap">
                    <InfoText className="text-white/80">
                      &#91;{lngT('horseinfo:round')} {horse.nextRaceId}&#93;
                    </InfoText>
                    <InfoText className="font-light text-[#F14EA7]/80">
                      {horse.nextRaceDate
                        ? formatDateToLocal(horse.nextRaceDate)
                        : /*No race date available*/ `${lngT('horseinfo:norace')}`}
                    </InfoText>
                  </div>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*COUNT RACING*/ lngT('horseinfo:count')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.countRacing}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*WIN FIRST*/ lngT('horseinfo:win')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.winFisrt}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*INNER THIRD*/ lngT('horseinfo:inner')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.innerThird}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*BEST RECORD*/ lngT('horseinfo:best')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.bestRecord}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*ACCUMULATE REWARD*/ lngT('horseinfo:accumulate')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>
                      {horse.accReward === null ? '0' : horse.accReward < 1000 ? '-' : horse.accReward.toLocaleString()}
                    </InfoText>
                  </HorseInfoRow>
                  <HorseInfoRow className="flex-wrap space-y-2">
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*LATEST RANK*/ lngT('horseinfo:latest')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText className="flex flex-row items-center justify-center max-[450px]:flex-wrap space-x-1">
                      {rankBall}
                    </InfoText>
                  </HorseInfoRow>
                </div>
              </div>
            </HorseInfoSection>
          </section>
          {/* PC */}
          <section className={`D:blcok hidden D:flex D:flex-col D:justify-center`}>
            <HorseInfoSection className="relative z-[10]">
              <div className="bg-[#48096D]/30 D:bg-[#48096D]/50 D:h-[550px] h-fit px-4 D:px-0 MD:px-4 py-10 flex flex-col D:justify-between ">
                <h2 className="ml-2 text-3xl text-[#F14EA7] pb-4">{horse.name}</h2>
                <div className="absolute top-0 right-[24px] drop-shadow-lg w-fit rounded-b-md bg-gradient-to-r from-violet-900 from-25% via-fuchsia-700 via-70% to-fuchsia-800 px-3 py-2.5">
                  <p className="text-white/80 flex flex-col items-center text-sm T:text-sm text-center tracking-wide">
                    {stakeOption}
                  </p>
                </div>
                <div className="space-y-2 ml-2 mr-2">
                  <div className="flex flex-row space-x-2 mb-4 flex-wrap">
                    <InfoText className="text-white/80">
                      &#91;{lngT('horseinfo:round')} {horse.nextRaceId}&#93;
                    </InfoText>
                    <InfoText className="font-light text-[#F14EA7]/80">
                      {horse.nextRaceDate
                        ? formatDateToLocal(horse.nextRaceDate)
                        : /*No race date available*/ `${lngT('horseinfo:norace')}`}
                    </InfoText>
                  </div>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*COUNT RACING*/ lngT('horseinfo:count')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.countRacing}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*WIN FIRST*/ lngT('horseinfo:win')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.winFisrt}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*INNER THIRD*/ lngT('horseinfo:inner')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.innerThird}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*BEST RECORD*/ lngT('horseinfo:best')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>{horse.bestRecord}</InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow>
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*ACCUMULATE REWARD*/ lngT('horseinfo:accumulate')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText>
                      {' '}
                      {horse.accReward === null ? '0' : horse.accReward < 1000 ? '-' : horse.accReward.toLocaleString()}
                    </InfoText>
                  </HorseInfoRow>

                  <HorseInfoRow className="flex-wrap space-y-2">
                    <HorseInfoContent>
                      <HorseInfoImg src="/images/icons/diamond.svg" />
                      <InfoTitle>{/*LATEST RANK*/ lngT('horseinfo:latest')}</InfoTitle>
                    </HorseInfoContent>
                    <InfoText className="flex flex-row items-center justify-center max-[450px]:flex-wrap D:space-x-1 space-x-4 ">
                      {rankBall}
                    </InfoText>
                  </HorseInfoRow>
                </div>
              </div>
            </HorseInfoSection>
          </section>
        </div>
      </div>
      <HorseStakeSection className="z-[90]">
        <div className="D:w-2/3 flex flex-col space-y-6">
          <div className="w-full h-[240px] T:h-[100px] bg-white bg-opacity-5 rounded-lg flex flex-col T:flex-row items-start T:justify-between T:items-center space-y-3 T:space-y-0 MD:px-10 px-5 py-8">
            <div className="space-y-1">
              <LeaseInfoTitle className="">{/*Lease Start Date*/ lngT('stake:start')}</LeaseInfoTitle>
              <LeaseInfoText className="">{formatDateToLocal(stake.createdAt).split(' ')[0]}</LeaseInfoText>
            </div>
            <div className="space-y-1">
              <LeaseInfoTitle className="">{/*Lease Amount*/ lngT('stake:amount')}</LeaseInfoTitle>
              <div className="flex flex-row items-center space-x-1">
                <LeaseInfoText className="">{stake.stakePoint}</LeaseInfoText>
                <LeaseInfoUnit className="">{/*points*/ lngT('stake:points')}</LeaseInfoUnit>
              </div>
            </div>
            <div className="space-y-1">
              <LeaseInfoTitle className="">{/*Lease Return Amount*/ lngT('stake:accReward')}</LeaseInfoTitle>
              <div className="flex flex-row items-center space-x-1">
                <LeaseInfoText className="">{stake.returnPoint}</LeaseInfoText>
                <LeaseInfoUnit className="">{/*points*/ lngT('stake:points')}</LeaseInfoUnit>
              </div>
            </div>
          </div>

          {/* graph */}
          <div className="w-full h-fit bg-white bg-opacity-5 rounded-lg flex flex-col T:flex-row items-start T:justify-between T:items-center space-y-3 T:space-y-0 px-5 py-6 D:px-5 MD:px-10 MD:py-10">
            <div className="flex flex-col D:flex-col D:items-start D:justify-start MD:flex-row MD:items-center MD:space-x-10 space-y-8 w-full">
              <div className="space-y-1">
                <LeaseInfoTitle className="">{/*Accumulated Reward Points*/ lngT('stake:accReward')}</LeaseInfoTitle>
                <div className="flex flex-row items-center space-x-1">
                  <LeaseInfoText className="">{stake.currentReturnPoint.toFixed(1)}</LeaseInfoText>
                  <LeaseInfoUnit className="">{/*points*/ lngT('stake:points')}</LeaseInfoUnit>
                </div>
              </div>
              <div className="w-full h-[30px] relative">
                <div className="bg-[#EEC3FF]/10 border border-[#EEC3FF]/50 relative h-[10px] w-full rounded-2xl">
                  <div
                    style={{
                      background: `linear-gradient(90deg, #4504A8 0%, #8E00CB ${
                        (stake.currentReturnPoint / stake.returnPoint) * 100
                      }%)`,
                      width: `${(stake.currentReturnPoint / stake.returnPoint) * 100}%`, // 조절된 길이
                    }}
                    className="z-[10] absolute top-0 left-0 h-full rounded-2xl transition "
                  >
                    <span
                      className={`w-fit bg-[#8E00CB] absolute -right-4 bottom-full mb-2 rounded-sm rounded-bl-sm py-1 px-[9px] text-xs font-semibold text-white ${
                        stake.currentReturnPoint > stake.returnPoint / 2 ? 'left-auto right-0' : 'left-auto -right-6'
                      }`}
                    >
                      <span
                        className={`bg-[#8E00CB] absolute bottom-[-2px] -z-10 h-2 w-2 rotate-[-45deg] rounded-sm  ${
                          stake.currentReturnPoint > stake.returnPoint / 2
                            ? 'right-0 -translate-x-[4px]'
                            : 'left-1/2 -translate-x-1/2'
                        }`}
                      ></span>
                      {stake.currentReturnPoint.toFixed(1)}
                    </span>
                  </div>
                </div>
                <div className="absolute w-full top-4 left-0">
                  <div className="flex flex-row justify-between">
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(index => (
                      <GraphText
                        key={index}
                        className={index % 2 === 0 && index > 0 && index < 7 ? 'max-[400px]:hidden' : ''}
                      >
                        {(stake.returnPoint / 8) * index}
                      </GraphText>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* button */}
        <div className="D:ml-2 MD:ml-8 w-full D:w-1/3 D:flex-col h-auto bg-white bg-opacity-5 rounded-lg flex flex-col T:flex-row items-start T:justify-start T:items-center T:space-x-8 space-y-3 T:space-y-0 D:py-12 D:justify-center D:space-y-10 D:space-x-0 MD:px-10 py-10 px-5 MD:py-9">
          <div className="w-full space-y-2">
            {/* <LeaseInfoTitle className="">{lngT('stake:winning')}</LeaseInfoTitle> */}
            <LeaseBtn onClick={() => openModal(modalContent, true)} className="bg-[#860099]">
              {/*Winning Records*/ lngT('stake:winning')}
            </LeaseBtn>
          </div>
          <div className="w-full space-y-2">
            {/* <LeaseInfoTitle className="">{lngT('stake:withdrawal')}</LeaseInfoTitle> */}
            <LeaseBtn onClick={() => navigate('/mypage/account')} className="bg-[#641DBE]">
              {/*Point Withdrawal*/ lngT('stake:pointWithdrawal')}
            </LeaseBtn>
          </div>
        </div>
      </HorseStakeSection>
    </div>
  );
};

export default HorseStakeInfoItemUI;
