import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';

import { env } from '@/env';
import { fetcher } from '@/lib/util';

import { ContentLayout } from '@/page/common/layout';
import { EventListLayout } from '@/page/event/layout';
import { EventListItem } from '@/page/event/components';

export default function EventListView() {
  const { data } = useSWR<ResWithQueryMeta<EventModel[]>>(`${env.api.host}/event`, { fetcher });
  const navigate = useNavigate();

  return (
    <ContentLayout title="">
      <EventListLayout>
        {data?.data.map(event => (
          <EventListItem key={event.id} item={event} onClick={() => navigate(`/event/${event.id}`)} />
        ))}
      </EventListLayout>
    </ContentLayout>
  );
}
