import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';
import { useLocation } from 'react-router-dom';

import useSWR from 'swr';

import LogoButton from './LogoButton';
import UserAvatar from './UserAvatar';
import MobileNavigation from './MobileNavigation';
import LanguageManage from './LanguageManage';
import { useCoinDrop, useUser } from '@/lib/hook';
import { CoinDropResetButton } from './coindrop';

import { useCoindropStore } from '@/store/coindrop';
import * as config from '../config';
import CoinDropButton from './coindrop/CoinDropButton';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

const Container = tw.nav`
  flex flex-row items-center justify-between
  w-full ${({ $isScrolled }) => ($isScrolled ? 'h-[100px]' : 'h-[100px]')}
  max-[480px]:px-[20px] px-[40px] D:px-[100px]
  ${({ $isScrolled }) => ($isScrolled ? 'bg-[#483F6C] bg-opacity-80 shadow-sm' : 'bg-[#483F6C]')}
  transform transition-[height] duration-500
  ${({ $isColor }) => ($isColor ? 'bg-[#483F6C] bg-opacity-80' : 'bg-[#483F6C]')}
`;

const NavContainer = tw.ul`
  flex flex-row items-center justify-center
  flex-1 h-full
  space-x-[43px]
`;

const AuthBoxContainer = tw.div`
  flex flex-row
  space-x-3 T:space-x-[16px]
  mt-2
`;

const noHeaderUrlList = [
  '/login',
  '/signup',
  '/password',
  '/game',
  '/introbet',
  '/7CkmEx76EWp',
  '/DIEHB7O3WI5II',
  'GameOnePage',
  '/video',
  // '/powerball/betting',
  // '/powerball/lottery',
];

const purpleBgList = ['/powerball'];

export default function Gnb() {
  const { pathname } = useLocation();
  const [isScrolled, setScrolled] = useState(false);
  const { openGamePopup } = useCoindropStore();
  const [user, { isLoading, error, logout }] = useUser();
  const navigate = useCustomNavigate();
  const [isChangeColor, setIsChangeColor] = useState(false);

  const handleUpdateUserBalance = newBalance => {
    console.log('Updating user balance:', newBalance);
  };

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY !== 0);
    window.addEventListener('scroll', handleScroll);

    if (purpleBgList.find(url => pathname?.includes(url))) {
      setIsChangeColor(true);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // if (pathname?.includes('/powerball/lottery') || pathname?.includes('/powerball/betting')) {
  //   return <div className="w-full h-[10px] bg-black"></div>;
  // }

  if (noHeaderUrlList.find(url => pathname?.includes(url))) {
    return null;
  }

  return (
    <Container $isScrolled={isScrolled} $isColor={isChangeColor}>
      <LogoButton />
      {<CoinDropButton onClick={() => (user ? openGamePopup() : navigate('/login'))} />}
      <div className="flex flex-row items-center space-x-3 T:space-x-8">
        <AuthBoxContainer>
          <LanguageManage />
          <UserAvatar />
          <MobileNavigation isScrolled={isScrolled} />
        </AuthBoxContainer>
      </div>
    </Container>
  );
}
