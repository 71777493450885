import React, {useRef} from "react";
import {PerspectiveCamera} from "@react-three/drei";

function CustomLight()
{
    const refSpotlight = useRef();
    // let spotLightHelper = useHelper(refSpotlight, THREE.SpotLightHelper, 'red');

    return (
        <group>

            <PerspectiveCamera makeDefault position={[0,0,15]} fov={15}  />
            {/* <spotLight
                position={[0, 0, 3]}
                angle={0.1}
                penumbra={0.0}
                intensity={0.0}
                distance={3.0}
                // decay={0.0}
                ref = {refSpotlight}/> */}
        </group>)

}
export default CustomLight;