import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCookie, setCookie } from '../../lib/cookie'

let powerballinfo = null;
export default function BettingResultDisplay({ isResultPopupOpen, canBet }) {
  const isFirstRun = useRef(true);
  const powerBallState = useSelector(state => state.Powerball);
  const framState = useSelector(state => state.curState);
  const [ballTotal, setBallTotal] = useState(0);
  const [powerball, setPowerball] = useState(0);
  const [showallball, setshowallball] = useState();
  const [resultPopupState, setresultPopupState] = useState(false);
  
  const ballRef = useRef(null);
  const powerBallRef = useRef(null);

  // console.log('CURFRAME ', framState);

  useEffect(() => {
    if (powerballinfo === undefined || powerballinfo === null || framState === undefined) {
      return;
    }

    if (!isFirstRun.current) return;

    if (framState === 8) {
      isFirstRun.current = false;
    }

    if (1 <= framState && framState <= 6) {
      isFirstRun.current = false;
      let ballTotal = 0;
      let powerBall = 0;

      for (let i = 0; i < framState; i++) {
        ballTotal += powerballinfo.ball[i];
      }

      setBallTotal(ballTotal);

      if (framState === 6) {
        powerBall = powerballinfo.powerBall;
        setPowerball(powerBall);
      }
    }
  }, [framState, powerBallState]);

  useEffect(() => {
    if (powerballinfo === undefined || powerballinfo === null || framState === undefined) return;

    let ballTimerId;
    let powerBallTimerId;

    if (1 <= framState && framState < 6) {
      ballTimerId = setTimeout(
        () => setBallTotal(state => (state += powerballinfo.ball[framState - 1])),
        // ballRef.current.classList.add('animate-pingOnce'),
        1200
      );
    }

    if (framState === 6) {
      powerBallTimerId = setTimeout(() => setPowerball(powerballinfo.powerBall), 500);
    }

    // if (framState === 8) {
    //   setBallTotal(0);
    //   setPowerball(0);
    // }

    return () => {
      clearTimeout(ballTimerId);
      clearTimeout(powerBallTimerId);
    };
  }, [powerBallState, framState]);

  useEffect(() => {
    // if(Powerball)
    //   console.log('isResultPopupOpen : ' + isResultPopupOpen, '/', getState);

    if (framState < 8) setresultPopupState(false);

    let _showball = !resultPopupState && !canBet;
    setshowallball(_showball);
    setBallTotal(_showball ? ballTotal : 0);
    setPowerball(_showball ? powerball : 0);
  }, [canBet, framState]);

  useEffect(() => {
    if (isResultPopupOpen) setresultPopupState(true);
  }, [isResultPopupOpen]);

  useEffect(() => {
    if(powerBallState)
    {
      powerballinfo = getCookie('prevgameinfo');
      powerballinfo = powerballinfo === "null" ? null : powerballinfo;
      if(powerballinfo)
        console.log('gameframe : ' + powerballinfo.gameframe + ' ' + powerBallState.gameframe);
    }
  }, [powerBallState]);  

  if (framState === undefined) {
    return null;
  }
  // {showallball && getState >= 8 && Powerball !== null && Powerball.ball !== null && Powerball.powerBall !== null && (
  return (
    <div className="w-full h-full  flex justify-center items-center absolute z-10 top-1/2 -translate-y-1/2 gap-3 max-[480px]:gap-0">
      <div className="w-full h-[140px] max-[768px]:h-[100px] max-[480px]:h-[60px] flex justify-center items-center absolute z-10 top-1/2 -translate-y-1/2 gap-3 max-[480px]:gap-0">
        {ballTotal !== 0 && (
          <div className="w-[200px] h-full flex flex-row items-center max-[1440px]:text-4xl max-[768px]:text-xl max-[768px]:w-[120px] max-[480px]:w-[100px] max-[400px]:w-[60px] max-[400px]:text-xs ">
            <div
              ref={ballRef}
              className="w-[200px] h-[200px] min-[1440px]:text-[48px] font-bold origin-top-left bg-gradient-to-b rounded-full text-black flex justify-center items-center max-[768px]:w-[120px] max-[768px]:h-[120px] max-[480px]:w-[80px] max-[480px]:h-[80px] max-[400px]:w-[50px] max-[400px]:h-[50px] relative"
              style={{ background: 'linear-gradient(180deg, black 55%, #ADADAD 100%)' }}
            >
              <div className="absolute w-[120px] h-[120px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full flex justify-center items-center max-[768px]:w-[70px] max-[768px]:h-[70px] max-[480px]:w-[50px] max-[480px]:h-[50px] max-[400px]:w-[30px] max-[400px]:h-[30px] ">
                {ballTotal}
              </div>
            </div>
          </div>
        )}
        {powerball !== 0 && framState >= 6 && (
          <div
            ref={powerBallRef}
            className="text-black w-[200px] h-[200px] min-[1440px]:text-[48px] font-bold rounded-full flex items-center justify-center max-[768px]:w-[120px] max-[768px]:h-[120px] max-[480px]:w-[80px] max-[480px]:h-[80px] max-[400px]:w-[50px] max-[400px]:h-[50px] relative max-[1440px]:text-4xl max-[768px]:text-xl max-[400px]:text-xs "
            style={{ background: 'radial-gradient(50% 50% at 50% 50%, #FFB1B1 8%, #FFBEBE 14%, #FF2727 95%)' }}
          >
            <div className="absolute w-[120px] h-[120px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full flex justify-center items-center max-[768px]:w-[70px] max-[768px]:h-[70px] max-[480px]:w-[50px] max-[480px]:h-[50px] max-[400px]:w-[30px] max-[400px]:h-[30px]">
              {powerball}
            </div>
          </div>
        )}
      </div>
      {showallball && framState < 8 && (
        <div className="relative w-[600px] h-full rounded-3xl z-10 font-semibold text-4xl max-[768px]:w-full">
          {/* <div className="absolute bottom-4 w-[585px] h-[100px] rounded-3xl mix-blend-overlay transitaion-all border-[14px] max-[480px]:border-[8px] border-white z-10 font-semibold text-4xl max-[1024px]:w-[480px] max-[1024px]:h-[86px] max-[768px]:h-[80px] max-[768px]:w-[420px] mx-auto max-[768px]:rounded-xl max-[480px]:w-[300px]  max-[480px]:h-[45px] max-[400px]:w-[230px]  max-[480px]:rounded-xl max-[768px]:bottom-1 left-1/2 -translate-x-1/2"></div> */}
          <div className="absolute bottom-4 w-[585px] h-[100px] rounded-3xl mix-blend-overlay transitaion-all border-[14px]  border-white z-10 font-semibold text-4xl max-[1024px]:w-[480px] max-[1024px]:h-[86px] max-[768px]:h-[80px] max-[768px]:w-[420px] mx-auto max-[768px]:rounded-xl max-[640px]:w-[380px] max-[580px]:w-[300px]  max-[580px]:h-[60px] [580px]:border-[8px] max-[480px]:w-[270px] max-[480px]:h-[47px] max-[480px]:border-[6px] max-[420px]:w-[225px] max-[420px]:h-[43px]  max-[380px]:w-[200px] max-[380px]:h-[38px] max-[360px]:w-[180px] max-[360px]:h-[32px] max-[340px]:w-[180px] max-[320px]:w-[160px] max-[320px]:h-[28px] max-[280px]:w-[140px] max-[280px]:h-[25px] max-[580px]:rounded-lg max-[768px]:bottom-2 max-[280px]:bottom-1 left-1/2 -translate-x-1/2"></div>
        </div>
      )}
    </div>
  );
}
