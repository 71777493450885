import tw from 'tailwind-styled-components';

import SpinnerLoader from '@/components/SpinnerLoader';

const Container = tw.button`
  flex justify-center items-center
  min-w-[70px] h-[36px]
  px-[2px] D:px-[8px]
  bg-metacross-main disabled:opacity-40 hover:opacity-80
  text-white text-[10px] T:text-xs font-normal
  rounded-lg
`;

type Props = {
  content: string;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
};

export default function MainButton({ content, onClick, className, isLoading = false, disabled = false }: Props) {
  return (
    <Container onClick={onClick} disabled={isLoading || disabled} className={className ?? ''}>
      {isLoading ? <SpinnerLoader className="w-[24px] h-[24px] fill-white" /> : content}
    </Container>
  );
}
