import React from 'react';
import tw from 'tailwind-styled-components';

const TextContainer = tw.div`
  flex flex-row pl-6 space-x-6
`;

const Title = tw.h2`
   text-white text-[48px]
`;
const SmallTitle = tw.h4`
   text-white text-[20px]
`;
const Text = tw.p`
    text-[14px] text-[#B9B8BB]
`;
const ColorText = tw.span`
    text-[#8E00CB]
`;
export default function TermOfUse() {
  return (
    <div className="mx-8 T:mx-[100px] D:mx-[267px] flex flex-col justify-center items-center space-y-[88px] py-[88px]">
      <Title>TERMOS E CONDIÇÕES</Title>

      {/* 1. INTRODUÇÃO */}
      <div className="space-y-6">
        <SmallTitle>1. INTRODUÇÃO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Ao utilizar e/ou visitar qualquer seção do projeto <ColorText>U.Podium</ColorText> dirigido pela
            <ColorText>Goldenholic Group Limited</ColorText>, abrindo uma conta, o Usuário aceita os seguintes Termos e
            Condições, Política de Privacidade e as regras de todos os jogos, promoções especiais e bônus, condições da
            política do marketing que periodicamente poderá ser observada neste site. Todos os termos e condições
            descritos abaixo serão denominados com “Regras”. Leia atentamente todas as condições antes de aceitá-las. Se
            o Usuário não concordar com as mesmas ou não quer segui-las, não abra a conta do jogador e pare de usar os
            recursos do site. As condições entram em vigor a partir de 22.05.2023.
          </Text>
        </TextContainer>
        <SmallTitle>TERMOS GERAIS</SmallTitle>
      </div>

      {/* 2. PARTES */}
      <div className="space-y-6">
        <SmallTitle>2. PARTES</SmallTitle>
        <TextContainer>
          <Text>1</Text>
          <Text>
            As informações no site são reguladas sob a licença do Registro Comercial da Câmara de Comércio e Indústria
            de Curaçao (Heelsumstraat 51, Willemstad, Curaçao, PO Box 3075, Willemstad, Curaçao), fundada no Curaçao. O
            trabalho do site e dos seus recursos é totalmente licenciado e se encontra em conformidade com as
            jurisdições do país. O e-mail para todas as dúvidas é <ColorText>support@u-podium.com.</ColorText>
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2</Text>
          <Text>
            O website u-podium.com é operado pela <ColorText>Goldenholic N.V.</ColorText> registrada sob o nº
            <ColorText>HE407590</ColorText> com sede registrada em
            <ColorText>Chytron 3, Flat/Office 301, 1075, Nicosia, Cyprus. Goldenholic N.V.</ColorText> registrada sob o
            nº <ColorText></ColorText>152435 e localizada em Heelsumstraat 51, E-Commerce Park, Curaco. A
            <ColorText>Goldenholic N.V.</ColorText> é propriedade da
            <ColorText>Goldenholic Group Limited</ColorText> e pertence ao mesmo grupo de empresas. A
            <ColorText>Goldenholic Group Limited</ColorText> é regulamentada e possui uma licença válida de Curaçao
            Gambling número <ColorText>8048/JAZ2020-014</ColorText>.
          </Text>
        </TextContainer>
      </div>

      {/* 3. ALTERAÇÕES DO ACORDO */}
      <div className="space-y-6">
        <SmallTitle>3. ALTERAÇÕES DO ACORDO</SmallTitle>
        <TextContainer>
          <Text>1</Text>
          <Text>
            A <ColorText>Goldenholic Group Limited</ColorText> reserva o direito de alterar, editar, atualizar quaisquer
            Regras,por razões legais, comerciais ou outras, bem como aquelas destinadas a melhoria do serviço e dos
            usuários sem qualquer aviso prévio aos mesmos. Todas as novas regras e a data em que entram em vigor serão
            publicadas no site da empresa. O Jogador compromete-se a familiarizar-se com atuais governados. O Usuário
            deverá verificar a seção Regras regularmente.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2</Text>
          <Text>
            Se o Usuário não concordar com as alterações, poderá recusar dos recursos do site e fechar sua conta de
            jogador. O uso do Website após a entrada em vigor das alterações será considerada como o seu consentimento
            para todas as Regras, incluindo as alterações, independentemente se o Usuário informar-se das atualizações.
          </Text>
        </TextContainer>
      </div>

      {/* 4. TERMOS GERAIS */}
      <div className="space-y-6">
        <SmallTitle>4. TERMOS GERAIS</SmallTitle>
        <TextContainer>
          <Text>1</Text>
          <Text>
            Sob nenhuma circunstância, é permitido entrar no site para os usuários menores de idade. O acesso aos jogos
            de azar é proibido aos menores de 18 anos de acordo com a lei da jurisdição. O uso do site por todos os
            usuários menores de 18 anos é considerado uma violação das regras. A Empresa reserva o direito de solicitar
            que o Usuário forneça as cópias dos documentos para garantir que tem idade legal. A Administração reserva o
            direito de suspender a conta do Usuário e recusar o serviço, a menos que a documentação não seja fornecida e
            a idade não seja verificada.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2</Text>
          <Text>
            Em alguns países, o jogo é ilegal. O Usuário aceita o fato de que a Administração do Site não poderá
            fornecer nenhuma garantia ou assessoria legal no que diz respeito à sua legalidade no uso dos recursos do
            site. Os serviços do site são usados pelos usuários a seu próprio critério, aceitando os riscos e o fato de
            que o uso de nosso serviço na sua jurisdição pode ser ilegal.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3</Text>
          <Text>
            A Administração não presta serviços que possam violar a lei em uma determinada jurisdição. Usuário reconhece
            o uso dos serviços legais sob a lei da jurisdição que se aplica ao Usuário. A empresa não é responsável por
            uso do site de forma ilegal ou não autorizada.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4</Text>
          <Text>
            A administração do site não permite abrir contas e/ou fazer depósitos para os usuários que residem no
            território da Itália, França, EUA, Turquia, Ucrânia, Bulgária, Reino Unido e República Federal da Alemanha,
            Portugal, Holanda, Índias Ocidentais Holandesas, Curaçao, Irlanda. A lista das jurisdições pode ser alterada
            sem aviso prévio aos usuários. Ao aceitar as Regras, o Usuário confirma que não abrirá e/ou usará a conta de
            jogos de azar se residir em uma dessas jurisdições.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5</Text>
          <Text>
            Os Usuários são totalmente responsáveis por todas as taxas e impostos que acompanham e aumentam dos ganhos
            ao usar o Website. Se os ganhos são tributáveis sob a jurisdição da Empresa, o Usuário é totalmente
            responsável por acompanhar e relatar os ganhos/perdas para as autoridades apropriadas.
          </Text>
        </TextContainer>
      </div>

      {/* 5. TERMOS PARA ABRIR A CONTA DE JOGADOR*/}
      <div className="space-y-6">
        <SmallTitle>5. TERMOS PARA ABRIR A CONTA DE JOGADOR</SmallTitle>
        <TextContainer>
          <Text>1</Text>
          <Text>
            Para abrir a conta do jogador, basta indicar seu e-mail ou número de telefone e configurar uma senha que
            será usada para entrar no site. No site também é necessário indicar seu nome e a data de nascimento.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2</Text>
          <Text>
            O nome fornecido durante o processo de registro deve corresponder ao nome real do jogador, pois, a Empresa
            se reserva o direito de solicitar que o Usuário forneça a documentação para verificar sua identidade. Se o
            Usuário se recusar a fornecer tal documentação, a Administração tem o direito de suspender a conta do
            jogador até o momento em que a documentação apropriada não seja fornecida.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3</Text>
          <Text>
            O registro via e-mail é considerado simplificado. O jogador tem acesso às seguintes opções do site: a
            capacidade de jogar, fazer depósitos e participar nas promoções do site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4</Text>
          <Text>
            Após o registro, o Usuário reconhece a validade e a precisão das informações pessoais e, em caso de
            alterações, atualizará o perfil. Caso o jogador não cumpra esta seção, a conta dele pode ser suspensa,
            quaisquer reclamações serão negadas.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5</Text>
          <Text>
            Caso tenha alguma dúvida durante o processo de inscrição, não hesite em contactar-nos através do chat online
            ou via email: <ColorText>support@u-podium.com</ColorText>.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6</Text>
          <Text>
            O Usuário pode registrar apenas uma conta por computador, endereço IP ou telefone. As outras contas
            registradas pelo mesmo usuário serão consideradas "Contas Duplicadas", cujas podem ser fechados a qualquer
            momento; a conta pode ser bloqueada de acordo com a violação grave das Regras.
          </Text>
        </TextContainer>
      </div>

      {/* 6. VERIFICAÇÃO DE IDENTIDADE*/}
      <div className="space-y-6">
        <SmallTitle>6. VERIFICAÇÃO DE IDENTIDADE</SmallTitle>
        <div className="space-y-4">
          <TextContainer>
            <Text>1</Text>
            <Text>Ao utilizar o site, os jogadores reconhecem os seguintes termos e condições:</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1</Text>
              <Text>
                A idade do jogador no momento de registro é de 18 anos ou mais conforme com a lei da jurisdição do seu
                país.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>2</Text>
              <Text>
                O Usuário é o único proprietário dos fundos que estão na conta. Todas as informações pessoais fornecidas
                no perfil devem corresponder completamente ao nome no cartão de crédito/débito ou outras contas que
                serão fornecidas para depósito ou retirada de ganhos.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>3</Text>
              <Text>
                O Usuário reconhece plenamente que existe um risco de perda de dinheiro depositado na conta através da
                utilização dos recursos de jogo do Casino. Todos os usuários reconhecem que jogam os jogos por sua conta
                e risco. Em caso de perdas e danos, nenhuma reivindicação será feita contra a Administração.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>4</Text>
              <Text>
                Os usuários aceitam todas as regras gerais do uso do site e conduzem o jogo online. Os jogadores são
                responsáveis pelas apostas que fazem e pelo jogo que jogam. Os usuários se comprometem a não realizar
                atividades ilegais que possam prejudicar a credibilidade do site.
              </Text>
            </TextContainer>
          </div>
        </div>

        <TextContainer>
          <Text>2</Text>
          <Text>
            Ao aceitar as Regras, a Empresa tem o direito de fazer inspeções de tempos em tempos, caso outros terceiros
            exigem a validação das informações de contato e da identidade do Jogador.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3</Text>
          <Text>
            Durante essas inspeções, a conta será congelada e a possibilidade de retirada de dinheiro será limitada.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4</Text>
          <Text>
            Se as informações pessoais fornecidas não forem verdadeiras ou precisas, o Jogador será automaticamente
            considerado violador das Regras e a Administração se reserva o direito de negar o uso dos recursos do
            Website e outras ações a seu próprio critério.
          </Text>
        </TextContainer>
      </div>

      {/* 7. SEGURANÇA DA CONTA*/}
      <div className="space-y-6">
        <SmallTitle>7. SEGURANÇA DA CONTA</SmallTitle>
        <TextContainer>
          <Text>1</Text>
          <Text>
            Depois de abrir a conta, os Usuários não deverão revelar suas informações de login e senha. Caso o usuário
            esqueça os dados de login da conta, pode recuperá-los clicando no botão “Recuperar senha”.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2</Text>
          <Text>
            Os usuários são responsáveis por todas as atividades que são realizadas na sua conta e para garantir a
            senha, bem como por todos os danos e perdas que podem ser causados por terceiros.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3</Text>
          <Text>
            Os Jogadores assumem a responsabilidade de informar a Administração sobre o acesso dos terceiros não
            autorizado à sua conta pessoal e sobre a violação de segurança. A Empresa não é responsável pelos danos
            ilegais causados pelas pessoas que acedem a sua conta.
          </Text>
        </TextContainer>
      </div>

      {/* 8. POLÍTICA DE CANCELAMENTO*/}
      <div className="space-y-6">
        <SmallTitle>8. POLÍTICA DE CANCELAMENTO</SmallTitle>
        <TextContainer>
          <Text>1</Text>
          <Text>
            Se quiser começar a jogar no site do cassino, o montante específico de fundos deve estar disponível na sua
            conta.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2</Text>
          <Text>O Usuário se compromete a aceitar e passar as seguintes seções:</Text>
        </TextContainer>
        <div className="pl-10 space-y-2">
          <TextContainer>
            <Text>1</Text>
            <Text>Todos os fundos em dinheiro transferidos para sua conta não devem ser de origem ilegal;</Text>
          </TextContainer>
          <TextContainer>
            <Text>2</Text>
            <Text>
              O Usuário se compromete a não rejeitar a transação previamente realizada e não cancelar o pagamento já
              efetuado.
            </Text>
          </TextContainer>
        </div>
        <TextContainer>
          <Text>3</Text>
          <Text>
            A Empresa não deve aceitar quaisquer fundos de terceiros, incluindo, mas não se limitando, aos parentes,
            amigos, cônjuges e parceiros do Usuário. É permitido fazer depósitos apenas com a ajuda dos cartões e
            sistemas de pagamento que requerem identificação e que estão registrados no nome do usuário. Quando, durante
            o período de verificação, as violações destes termos forem observadas, a Empresa reserva o direito de cobrar
            de volta todos os ganhos obtidos pelos fundos.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4</Text>
          <Text>
            Se o usuário receber a notificação para a transferência bancária dos fundos, todas as taxas ou comissões
            serão pagas pelo destinatário.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5</Text>
          <Text>
            Deve ser utilizado somente um único número de telefone para depósitos na conta do jogador por SMS. Este
            número de telefone deve ser definido no perfil do usuário. Deve-se ter em mente que apenas metade da quantia
            de depósito será recebida na conta. A empresa não permite que os usuários recebam empréstimos das operadoras
            de telefonia celular selecionadas. Os depósitos pelo SMS não podem ser feitos quando o saldo for negativo.
            Se o usuário violar estes termos, a empresa reserva o direito de adicionar o jogador na lista negra e
            suspender sua conta.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6</Text>
          <Text>
            O cassino não aceita fundos em dinheiro. Para processar os pagamentos eletrônicos, a Companhia coopera com
            terceiros e instituições financeiras. O Usuário reconhece o seu consentimento para trabalhar com eles se às
            regras e regulamentos das tais instituições não estiverem em contradição com os Termos atuais da Empresa.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>7</Text>
          <Text>
            O Utilizador compromete-se a não cancelar as transações lançadas ou outras transações de pagamento e, em
            qualquer caso, compensará a Empresa pelos fundos que foram retirados, bem como todas as despesas
            relacionadas com a retirada do depósito.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>8</Text>
          <Text>
            Se qualquer depósito de fundos criminosos ou suspeitos, bem como o uso do cartão de crédito roubado
            (incluindo, sem limitação, a anulação e qualquer reembolso) estiver registrado no Site, a Empresa se reserva
            o direito de suspender a conta do jogador e reembolsar os fundos ganhos pelas atividades ilegais. A Empresa
            deve informar as instituições apropriadas ou autoridades de ações ilegais, bem como cooperar com agências de
            cobrança para receber de volta os pagamentos. A Empresa não é responsável pelo uso fraudulento do cartão de
            crédito, independentemente de o roubo ter sido declarado.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>9</Text>
          <Text>
            Os Usuários reconhecem que a Conta do Jogador não é uma conta bancária, portanto, nenhuma das políticas do
            seguro bancário ou do seguro de doação poderá ser aplicável.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>10</Text>
          <Text>
            Instruções de trabalho com o sistema 1-Click. O Usuário automaticamente concorda em pagar por quaisquer bens
            ou serviços que tenham sido encomendados por ele no Site, bem como pagar por qualquer despesa adicional:
            taxas, impostos, etc. O Usuário é totalmente responsável por tais pagamentos. O prestador de serviços de
            pagamento só deve garantir que a transação seja concluída com sucesso e não é responsável pelo pagamento de
            quaisquer outros serviços adicionais. Depois que o botão Pagamento for clicado, o usuário reconhece que o
            pagamento não pode ser revogado. Ao fazer o pedido no site, o usuário confirma que suas ações são legais e
            se compromete a não violar as leis de qualquer país. Sendo um proprietário do cartão bancário, o usuário
            aceita todos os termos e condições e confirma seu direito de usar quaisquer serviços ou mercadorias neste
            site. Ao dar o seu consentimento, o usuário confirma que tem a maioridade legal e a legalidade da jurisdição
            do Estado em relação ao serviço de jogos de azar no território do país onde o usuário reside. O jogador
            também é totalmente responsável por cumprir as leis locais. O prestador de serviços de pagamento não será
            responsável por quaisquer violações de tais leis. Se o usuário desejar recusar o serviço para a próxima
            compra de produtos ou serviços, ele deverá cancelar o mesmo usando seu perfil pessoal no site. A Empresa não
            é responsável pelos seguintes casos: processamento e verificação de cartão de crédito não podem ser
            concluídos ou recusados, ou o banco emissor se recusa a cumprir a transação com o cartão. A Empresa não é
            responsável pela quantidade, qualidade ou preço de qualquer serviço ou bens adquiridos pelo seu cartão de
            pagamento. Quando o usuário paga pelos bens ou serviços do Site, ele é obrigado a seguir as regras. Somente
            o usuário, sendo um proprietário do cartão de pagamento, é responsável pelo pagamento de taxas e impostos no
            devido tempo. Quando surge a situação que o usuário não concorda com qualquer uma das seções acima
            mencionadas, o usuário deve se recusar a realizar o pagamento e se é necessário, entrar em contato com a
            Equipe de Suporte do Site ou com a Administração.
          </Text>
        </TextContainer>
      </div>

      {/* 9. TERMOS DA OFERTA*/}
      <div className="space-y-6">
        <SmallTitle>9. TERMOS DA OFERTA</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Ao registrar a conta, o usuário concorda em cumprir as regras do cassino com relação aos métodos de
            pagamento.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>2.</Text>
            <Text>
              O usuário pode arquivar pedido de levantamento de dinheiro da conta do jogador a qualquer momento sob as
              seguintes condições:
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text className="text-right">1.</Text>
              <Text>
                todos os pagamentos transferidos para a conta do jogador foram verificados e nenhum deles foi recusado
                ou anulado;
              </Text>
            </TextContainer>
            <TextContainer>
              <Text className="text-right">2.</Text>
              <Text>
                a transação passou por todas as etapas do processo de verificação que comprovaram sua legalidade e
                integridade.
              </Text>
            </TextContainer>
          </div>
        </div>
        <div className="space-y-4">
          <TextContainer>
            <Text className="text-right">3.</Text>
            <Text>Ao preencher o pedido para sacar os fundos, o usuário deve prestar atenção às seguintes seções:</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text className="text-right">1.</Text>
              <Text>seu perfil deve conter informações pessoais completas;</Text>
            </TextContainer>
            <TextContainer>
              <Text className="text-right">2.</Text>
              <Text>os fundos devem ser sacados pelo mesmo método de pagamento usado para fazer o depósito;</Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>
                O procedimento de identificação ocorre no momento da retirada. O Usuário deverá enviar a cópia ou foto
                digital de um documento que comprove a identidade. Caso o Usuário tenha feito depósito com o cartão
                bancário ele deve enviar as cópias de ambos os lados do cartão. Os primeiros 6 dígitos e os últimos 4
                dígitos do número do cartão devem ser claramente visíveis, o CVV2 pode ser embaçado.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>4.</Text>
              <Text>
                Caso o valor das apostas seja três vezes menor que a soma de todos os depósitos do Usuário, a Companhia
                poderá reter 10% do valor do saque. Caso o depósito tenha sido feito com o cartão do banco, 20% do valor
                saco será deduzido.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>5.</Text>
              <Text>
                Os Usuários confirmam sua concordância com a programação atual do saque dos fundos de jogo. Segunda a
                sexta-feira das 9h às 20h. (GMT). A Empresa não é responsável por quaisquer atrasos que apareçam após os
                pedidos terem sido processados pelos gerentes do Casino.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>6.</Text>
              <Text>
                Se o depósito na conta do jogador for feito através das operadoras de telefonia celular, o pagamento não
                será feito antes de 2 semanas após o último depósito ter sido recebido. Esse atraso é causado pela
                investigação de possíveis atividades ilegais.
              </Text>
            </TextContainer>
          </div>
        </div>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            A Companhia reserva o direito de deduzir as taxas para o saque dos fundos que não tenham sido utilizados
            para os jogos de azar.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            Ao efetuar o pagamento no site, o usuário paga taxas pela realização do depósito na conta do jogador.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6.</Text>
          <Text>
            Caso o serviço (o depósito) não seja processado, o Casino reembolsará o usuário por completo. Para que isso
            aconteça, o usuário precisa entrar em contato com nossa equipe de suporte e requisitar uma solicitação de
            reembolso, informando o motivo, a soma e o modo de depósito. A consulta será processada dentro de 72 horas.
            Quando a informação é confirmada, o reembolso será feito através do mesmo método de pagamento que foi
            utilizado para depósito (a única exceção é o pagamento pela rede de terminais, caso em que o usuário terá
            opções para os métodos de pagamento de reembolso). Após a conclusão da transação e aprovação do mesmo, os
            fundos serão disponibilizados na conta indicada no prazo de 14 dias úteis.
          </Text>
        </TextContainer>
      </div>

      {/* 10. ATIVIDADES FRAUDULENTAS*/}
      <div className="space-y-6">
        <SmallTitle>10. ATIVIDADES FRAUDULENTAS</SmallTitle>
        <div className="space-y-4">
          <TextContainer>
            <Text>1.</Text>
            <Text>Os tipos de atividades abaixo são proibidos e violam as Regras:</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>passar informações pessoais para terceiros;</Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>
                conduzir a atividade fraudulenta, incluindo mas não limitado a erros de software, uso de malware, robôs
                (bots);
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>
                conduzir atividades ilegais para ganho pessoal, incluindo o uso de dados clonados, roubados ou obtidos
                ilegalmente de cartões de débito ou crédito para fazer depósito na conta do jogador;
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>4.</Text>
              <Text>
                participar em qualquer atividade criminal que inclua branqueamento de capitais e outras atividades que
                causem consequências no âmbito do direito penal;
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>5.</Text>
              <Text>
                tentativa de conluio ou intenção em relação à participação indireta ou direta em parcelas com outros
                jogadores ao jogar neste site.
              </Text>
            </TextContainer>
          </div>
        </div>

        <TextContainer>
          <Text>2.</Text>
          <Text>
            Quando a suspeita de atividades ilegais surgir, a Administração se reserva o direito de cancelar ou adiar
            todos os ganhos e bônus que foram obtidos no Site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            A Administração tem o direito de tomar medidas destinadas a expor e prevenir o enredo entre os Usuários e
            tem o direito de tomar ações legais contra indivíduos expostos.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            Se o usuário suspeitar que outro usuário está fazendo uma trama ou realizar atividades fraudulentas, o
            usuário deve informar a Administração mais rápido possível.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            A Administração reserva o direito de, a qualquer momento, sem aviso prévio, suspender a conta do jogador
            apenas nos casos em que o Usuário é suspeito da atividade fraudulenta. Nesse caso, a Empresa não é
            responsável pelo reembolso de uma compensação dos fundos que estão na Conta do Jogador. Os funcionários do
            Casino têm o direito de informar as autoridades competentes e podem cooperar com eles durante a investigação
            da situação.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6.</Text>
          <Text>
            Os Jogadores estão proibidos de usar software para atividades fraudulentas ou ilegais ou outras transações
            proibidas de acordo com a lei do seu país. A Administração do Site pode a qualquer momento bloquear ou
            suspender tais contas no sistema ou reter fundos. Sob nenhuma circunstância os usuários deverão levantar a
            reclamação contra o site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>7.</Text>
          <Text>
            O Jogador apenas pode usar uma conta para o registro. Se o Jogador registra uma segunda conta e/ou usa os
            dados de outra pessoa para o registro, tais ações serão reconhecidas como fraudulentos. A Administração terá
            o direito de bloquear a conta do Jogador. Todos os fundos na conta serão bloqueados sem a possibilidade de
            saque e serão coletados pela Administração como uma multa.
          </Text>
        </TextContainer>
      </div>

      {/* 11. ATIVIDADES PROIBIDAS NO SITE*/}
      <div className="space-y-6">
        <SmallTitle>11. ATIVIDADES PROIBIDAS NO SITE</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            É proibido usar formas agressivas ou ofensivas de interação ou fotos, intimidação, palavrões, ações
            violentas contra outros jogadores ou funcionários do site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            É proibido fazer upload de informações cujo tamanho possa causar o mau funcionamento do site, bem como
            conduzir quaisquer outras atividades que possam prejudicar o processo de operação do site. É proibido usar
            malware ou vírus. As mensagens "spam" e envio em massa são estritamente proibidas. É proibido distorcer e
            excluir qualquer informação disponível no site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            Os Usuários comprometem-se a usar os recursos do Site somente para fins de entretenimento: os Jogadores não
            têm o direito de copiar o Site ou qualquer de suas partes sem o consentimento prévio por escrito da
            Administração.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            Os Usuários não tentarão accessar o sistema de segurança ou tentar contornar suas regras. No caso de a
            Administração do Site ficar suspeita de que os Jogadores tentam hackear o software ou sistema de segurança
            do Site, o acesso para tal jogador será negado e a conta do jogador será temporariamente bloqueada. A
            Administração reserva o direito de informar as autoridades competentes das atividades fraudulentas.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            A Administração não é responsável por quaisquer perdas causadas por terceiros ou pelo mau funcionamento da
            tecnologia informática causada pelos vírus ou malware.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6.</Text>
          <Text>
            É proibido passar as contas dos jogadores, vendê-las ou perder as fichas com o objetivo de passá-las para
            outro jogador. Tal perda de fichas pode acontecer naqueles casos em que o Jogador perde propositalmente o
            jogo para que o outro jogador ganhe o dinheiro.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>7.</Text>
          <Text>
            É proibido de criar solicitações de saque de método diferente daquele com o qual o depósito foi feito.
          </Text>
        </TextContainer>
      </div>

      {/* 12. TERMOS DO ACORDO*/}
      <div className="space-y-6">
        <SmallTitle>12. TERMOS DO ACORDO</SmallTitle>

        <TextContainer>
          <Text>1.</Text>
          <Text>
            A Administração do Site se reserva o direito de retirar comissões ou o montante da dívida antes que a conta
            do jogador seja fechada. Se a conta do jogador já foi bloqueada ou excluída, será impossível sacar o retorno
            ou o reembolso de fundos adicionais e bônus.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Quando a suspeita de atividades ilegais surgir, a Administração se reserva o direito de cancelar ou adiar
            todos os ganhos e bônus que foram obtidos no Site.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>3.</Text>
            <Text>
              A Empresa reserva o direito de anular a conta do jogador sem aviso prévio ao Jogador nos seguintes casos:
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>se devido a alguns motivos, especificamente, a conta foi suspensa;</Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>se for descoberto que o jogador está relacionado à conta que foi excluída anteriormente;</Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>se for descoberto que o jogador está relacionado à conta que foi excluída anteriormente;</Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>
                se a conta do jogador estiver relacionada com contas já congeladas, a Administração reserva o direito de
                bloquear a conta do jogador.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>4.</Text>
              <Text>se a tentativa de invasão for descoberta ou o usuário for pego em atividades fraudulentas;</Text>
            </TextContainer>
            <TextContainer>
              <Text>5.</Text>
              <Text>se o usuário usa sistemas diferentes para manipular o software;</Text>
            </TextContainer>
            <TextContainer>
              <Text>6.</Text>
              <Text>
                se o usuário usa o site para ações ilegais, por exemplo, accessar aos jogos de azar dentro do território
                do país onde eles são proibidos;
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>7.</Text>
              <Text>se o jogador deixar informações ofensivas ou depreciativas sobre o site.</Text>
            </TextContainer>
          </div>
        </div>

        <TextContainer>
          <Text>4.</Text>
          <Text>
            Caso a conta do jogador estiver inativa durante 6 meses, ela pode ser temporariamente suspendida ou fechada
            sem aviso prévio.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            A Empresa reserva o direito de excluir a conta do jogador e enviar uma notificação prévia no endereço
            (número de telefone) indicado em seu perfil. Se o motivo para o fechamento da conta não for atividades
            fraudulentas ou violação de regras, a Administração descongela o saldo na conta do jogador. Em outras
            circunstâncias, se o Jogador não entrar em contato, os fundos do jogo serão transferidos para a autoridade
            ou agência de supervisão.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6.</Text>
          <Text>
            O Jogador entende e concorda que a Administração se reserva ao direito de suspender a Conta do Jogador e/ou
            de bloquear a Conta do Jogador e/ou cancelar a Conta do Jogador a qualquer momento, sem aviso prévio.
          </Text>
        </TextContainer>
      </div>

      {/* 13. MUDANÇAS DAS REGRAS */}
      <div className="space-y-6">
        <SmallTitle>13. MUDANÇAS DAS REGRAS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Para o propósito de estabelecer o funcionamento adequado do site e atualizações de serviços, a
            Administração, ao seu exclusivo critério, poderá fazer alterações ou acréscimos a qualquer momento sem aviso
            prévio.
          </Text>
        </TextContainer>
      </div>

      {/* 14. ERROS DO SISTEMA */}
      <div className="space-y-6">
        <SmallTitle>14. ERROS DO SISTEMA</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            No caso de haver erros de jogo ou falha na operação do sistema do Website, a Administração tentará corrigir
            essa situação mais rápido possível. A Empresa não é responsável por avarias ou mau funcionamento do software
            e aparelhos usados pelo usuário para obter acesso ao recurso, bem como por quaisquer problemas no trabalho
            do provedor de Internet do jogador.
          </Text>
        </TextContainer>
      </div>

      {/* 15. FALHAS NO TRABALHO*/}
      <div className="space-y-6">
        <SmallTitle>15. FALHAS NO TRABALHO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Ao trabalhar com o site, alguns problemas com pagamentos ou apostas podem ocorrer por parte do site. Entre
            tais erros podem estar os seguintes exemplos: erro de cálculo dos ganhos, cálculo errado dos retornos,
            ajuste errado das apostas de jogo causado pela entrada incorreta da informação.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>A Companhia tem o direito de cancelar ou restringir qualquer aposta.</Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            Se o jogador já tiver usado os fundos avaliados por engano, a empresa tem o direito de cancelar quaisquer
            ganhos ou apostas que o jogador tenha ou possa ter obtido com esses fundos. Se os fundos de tais apostas ou
            jogos já tiverem sido pagos, eles terão o status "Transferido" e o Jogador se compromete a pagá-los à
            Administração em sua primeira solicitação.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            A Empresa, seus funcionários, parceiros, provedores não são responsáveis ​​por quaisquer danos (incluindo,
            mas não se limitando a perda do vencedor) causados​​como resultado do erro cometido pela empresa ou pelo
            jogador.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            A Empresa, seus titulares de licenças, empresas subsidiárias, distribuidores, filiais afiliadas, seus
            gerentes ou funcionários não são responsáveis ​​pelas perdas, que podem ser causadas pelo uso indevido das
            informações transferidas via Internet.
          </Text>
        </TextContainer>
      </div>

      {/* 16. LIMITAÇÕES DE RESPONSABILIDADE DA EMPRESA*/}
      <div className="space-y-6">
        <SmallTitle>16. LIMITAÇÕES DE RESPONSABILIDADE DA EMPRESA</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            O jogador concorda que ele/ela é totalmente responsável por fazer a escolha de usar os recursos do site e
            ele/ela gere os seus fundos a seu exclusivo critério.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            O site funciona de acordo com as regras indicadas no recurso. A Companhia não oferece garantia adicional em
            relação aos serviços da empresa e, por lei, exclui sua responsabilidade em relação a qualquer garantia
            adicional.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            A Companhia não é responsável por contravenção, contratos quebrados, perdas e danos, incluindo a perda de
            reputação, lucro, dados e outros tipos de perdas, que são impossíveis de prever. A Empresa não é responsável
            pelo conteúdo dos recursos que podem ser encontrados com a ajuda do site.
          </Text>
        </TextContainer>
      </div>

      {/* 17.VIOLAÇÃO DO REGULAMENTO*/}
      <div className="space-y-6">
        <SmallTitle>17. VIOLAÇÃO DO REGULAMENTO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            O Jogador é responsável por compensar todas as despesas, reclamações, cobranças, custas judiciais e todas as
            despesas similares que possam ser causadas pela violação das regras por parte do Jogador.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>2.</Text>
            <Text>
              O Jogador se compromete a compensar as perdas, defender os interesses da Companhia, bem como os interesses
              de funcionários públicos, funcionários e gestores de quaisquer demandas, reclamações, perdas, despesas,
              cobranças e danos, causados​​pelos seguintes motivos:
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>violação das regras do site;</Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>violação dos direitos ou das leis de terceiros;</Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>
                passar informações pessoais de login para os recursos do site para outros indivíduos com ou sem o seu
                consentimento;
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>4.</Text>
              <Text>receber ganhos de uma conta de jogador autorizada.</Text>
            </TextContainer>
          </div>
        </div>
        <div className="space-y-4">
          <TextContainer>
            <Text>3.</Text>
            <Text>
              Quando o jogador violar estas regras, a empresa reserva o direito de (mas não compromete-se a cumprir):
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>receber ganhos de uma conta de jogador autorizada.</Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>congelar temporariamente a conta do jogador para restringir o acesso aos jogos no site;</Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>bloquear a conta do jogador sem aviso prévio;</Text>
            </TextContainer>
            <TextContainer>
              <Text>4.</Text>
              <Text>
                retirar da conta do jogador a quantidade de ganhos, bônus ou pagamentos que o jogador ganhou através de
                atividades fraudulentas ou graves violações.
              </Text>
            </TextContainer>
          </div>
        </div>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            A Administração reserva o direito de excluir a conta do jogador nos casos em que o jogador não seguir as
            regras.
          </Text>
        </TextContainer>
      </div>

      {/* 18.COPYRIGHT*/}
      <div className="space-y-6">
        <SmallTitle>18. COPYRIGHT</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Todos os recursos do site estão sujeitos a direitos de propriedade e direitos autorais, que pertencem ao
            site do jogo ou ao titular da licença de terceiros. Todos os materiais impressos ou baixados devem ser
            baixados somente em um dispositivo e impressos para uso pessoal não comercial.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            A Administração do Site não concede o direito de usar propriedade intelectual de sua própria Empresa ou de
            terceiros.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            É proibida a reprodução ou o uso de marcas registradas, nomes comerciais, materiais criativos ou outros
            logotipos representados no site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            Os Jogadores são responsáveis ​​por quaisquer despesas, cobranças ou danos causados​​por suas atividades
            ilegais. O Jogador informará a Administração da empresa de quaisquer atividades proibidas de outros
            jogadores e colaborara na investigação do assunto em questão.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            As informações de pagamento, incluindo o nome, endereço, conta corrente devem ser representadas para
            fornecer alguns dos serviços do site. Esta informação deve ser usada exclusivamente para faturamento e
            pagamentos. Nós não armazenamos informações dos cartões de crédito.
          </Text>
        </TextContainer>
      </div>

      {/* 19.POLÍTICA DE PRIVACIDADE*/}
      <div className="space-y-6">
        <SmallTitle>19. POLÍTICA DE PRIVACIDADE</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            A Empresa deve seguir as regras para proteger as informações pessoais dos Jogadores. Essas informações
            incluem os dados coletados quando o Usuário visita o site. O Casino trata as informações de cada jogador
            cuidadosamente; portanto, o uso de tais informações é estritamente restrito pelas regras da Empresa. O
            Casino processa todos os dados pessoais de acordo com a seção da Política de Privacidade.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Ao fornecer informações pessoais para o site, o Usuário automaticamente dá o consentimento para que seja
            processado para os fins indicados pela Administração, a fim de cumprir com as responsabilidades legais e
            regulamentares.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            O principal objetivo da empresa é armazenar os dados pessoais dos usuários, que devem ser revelados apenas a
            alguns funcionários, a fim de fornecer ao jogador o pacote completo de serviços.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            Para salvar suas informações exatas, as cópias das cartas recebidas dos Usuários (incluindo e-mails) devem
            ser armazenadas no Site.
          </Text>
        </TextContainer>
      </div>

      {/* 20.OS "COOKIES"*/}
      <div className="space-y-6">
        <SmallTitle>20. OS "COOKIES"</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            A Empresa usa “cookie” para garantir a melhor funcionalidade do site. Cookie é um pequeno arquivo de texto
            que é salvo automaticamente no seu dispositivo que o Usuário usa para entrar no site. Ele permite processar
            seus dados de login mais rapidamente quando o Usuário visitar o site posteriormente. Esses arquivos podem
            ser excluídos, mas o Usuário deve ter em mente que a exclusão ou negação para salvar arquivos de cookie pode
            causar a limitação de acesso a algumas opções e seções do site.
          </Text>
        </TextContainer>
      </div>

      {/* 21.SUGESTÕES E RECLAMAÇÕES
       */}
      <div className="space-y-6">
        <SmallTitle>21. SUGESTÕES E RECLAMAÇÕES</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Se houver algum problema na operação do site, o Usuário deve entrar em contato com a administração do site
            ou com a equipe de suporte em curto prazo.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Caso haja alguns desentendimentos, todos os e-mails e comunicações no site serão apresentados como prova
            final para resolver o problema.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            Os jogadores reconhecem que o resultado de cada jogo é determinado pelo gerador de números aleatórios. Se
            houver qualquer discrepância entre o resultado do jogo no servidor de apostas e seu computador, os dados do
            servidor serão essenciais e finais. Se houver alguma discrepância entre o saldo da conta no servidor e a
            tela do seu computador, a balança no servidor será considerada a correta. O valor da conta do jogo pode ser
            perdido devido a erro técnico ou fator humano.
          </Text>
        </TextContainer>
      </div>

      {/* 22.ATRIBUIÇÃO DOS DIREITOS*/}
      <div className="space-y-6">
        <SmallTitle>22. ATRIBUIÇÃO DOS DIREITOS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            A Empresa reserva o direito de sublocar, nomear, ceder ou prometer regras, parcial ou totalmente, sem o
            consentimento dos Jogadores, nos casos em que tais alterações não afetem os Termos e Condições.
          </Text>
        </TextContainer>
      </div>

      {/* 23.FORÇA MAIOR*/}
      <div className="space-y-6">
        <SmallTitle>23. FORÇA MAIOR</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            A Administração não é responsável se o serviço não for fornecido ou atrasado devido a alguns imprevistos,
            incluindo guerras, calamidades naturais, ataques à Internet, disputas industriais, interrupções de sistemas
            de comunicação, distúrbios civis, que podem ter efeitos perniciosos.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Durante o período da força maior, o acesso ao site pode ser limitado e a conclusão das obrigações pode ser
            adiada. A Administração tentará usar todos os recursos para resolver os problemas, não obstante as
            circunstâncias em vigor.
          </Text>
        </TextContainer>
      </div>

      {/* 24.ABANDONO DE COMPROMISSOS*/}
      <div className="space-y-6">
        <SmallTitle>24. ABANDONO DE COMPROMISSOS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Se a Administração não for capaz de garantir o cumprimento das suas obrigações, A Empresa não deverá usar
            nenhum meio de defesa legal ao qual tenha direito. Este fato não isenta o jogador de seguir os termos e
            condições.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            A recusa do Website em cumprir qualquer das regras não tem qualquer eficácia legal se não for registrada
            oficialmente e aprovada por escrito de acordo com as normas descritas.
          </Text>
        </TextContainer>
      </div>

      {/* 25.FORÇA MAIOR*/}
      <div className="space-y-6">
        <SmallTitle>25. FORÇA MAIOR</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Todas as regras do Site estão de acordo com a legislação das Ilhas Antilhas Holandesas e todos os Jogadores
            que usarem os recursos do Site deverão cumprir as leis da jurisdição das Ilhas Antilhas Holandesas na
            solução dos litígios, que podem ser resultantes pela ação, legitimidade ou relação jurídica estabelecida nos
            Termos e Condições.
          </Text>
        </TextContainer>
      </div>

      {/* 26.TERMOS DOS LINKS*/}
      <div className="space-y-6">
        <SmallTitle>26. TERMOS DOS LINKS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            O recurso pode conter links para sites externos que não são mantidos pela Empresa e não são mencionados nas
            Regras. A Companhia não é responsável pelo conteúdo desses sites, pelo patrocínio ou pelo conteúdo do
            anúncio de terceiros. Todos os links para sites externos são fornecidos apenas para conscientização e
            informações. Os usuários seguem os links e usam os recursos a seu próprio critério.
          </Text>
        </TextContainer>
      </div>

      {/* 27.TERMOS DO BÔNUS*/}
      <div className="space-y-6">
        <SmallTitle>27. TERMOS DO BÔNUS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            O jogador pode receber bônus após o registro bem-sucedido ou depois, quando ele já tiver o status de jogador
            da empresa. A solicitação para recusar os bônus pode ser registrada a qualquer momento entrando em contato
            com a equipe de suporte por e-mail <ColorText>support@u-podium.com</ColorText>. Preste muita atenção aos
            termos de cada bônus que o Usuário aceita. Esta seção contém regras gerais de como trabalhar com bônus neste
            site. Alguns dos bônus podem ter condições adicionais, que são apresentadas na descrição do bônus
            selecionado.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Se o jogador violar as regras da empresa e tentar fazer transações suspeitas, a administração da empresa tem
            o direito de anular os bônus. Esta regra funciona para fornecer um jogo justo para todos os jogadores e
            proteger o trabalho seguro do site.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            Os fundos de bônus são acumulados apenas para os jogadores que forneceram seu número de telefone durante o
            processo de registro.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>4.</Text>
          <Text>
            O valor do depósito mínimo pode variar de $1.00 a $1000.00, dependendo do sistema de pagamento selecionado.
            Para sacar dinheiro que foi ganho com a ajuda do bônus de registro, o Usuário deve fazer um depósito. Se o
            Jogador tentar retirar fundos de bônus sem fazer um depósito mínimo, o bônus será apagado pela
            Administração.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>5.</Text>
          <Text>
            Reservamo-nos o direito de revogar e/ou cancelar quaisquer bônus e ganhos que consideramos que possam ter
            sido resgatados por uso impróprio do sistema. A conta de Jogador abusivo pode ser encerrada imediatamente.
            Reservamo-nos o direito de alterar os termos e condições de qualquer oferta promocional de Bônus a qualquer
            momento e o verificar periodicamente pelas mudanças e atualizações fica na responsabilidade do Jogador.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>6.</Text>
          <Text>
            A Administração se reserva o direito de reter e cancelar quaisquer bônus dados aos Jogador sem aviso se
            esses bônus não forem usados durante 3 meses.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>7.</Text>
          <Text>
            A Administração se reserva ao direito de negar quaisquer dos bônus aos jogadores que receberam um número
            desproporcional de bônus relativo ao número de depósitos feito ou de outra forma abusaram do sistema de
            bônus do website.
          </Text>
        </TextContainer>
      </div>
    </div>
  );
}
