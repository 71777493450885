import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import { useLang, useUser } from '@/lib/hook';
import { maskString } from '@/lib/util';
import { genFetchContainer } from '@/lib/RestfulContainer';
import { useModalStore } from '@/page/common/custom-lib/modal';
import { env } from '@/env';
import { fixBalance } from '@/page/common/lib/utils';

import { ContentBoxLayout, ContentLayout } from '@/page/common/layout';
import { InfoText, Line, MainButton, PlainText, UserAvatar } from '@/page/common/component';
import { CoinBoxLayout } from '@/page/mypage/layout';

import ConversionPointPopup from './ConversionPointPopup';
import ChangePinPopup from './ChangePinPopup';

const Layer = tw.div`
  flex flex-col
  w-full
`;

const UserLayer = tw.div`
  flex flex-col T:flex-row T:justify-between
  w-full
  gap-8
`;

const UserInfoLayer = tw.div`
  flex flex-row items-center
  gap-4
`;

const UserInfoTextLayer = tw.div`
  flex flex-col
`;

const UserBalanceLayer = tw.div`
  flex flex-col
  gap-2
`;

const BalanceLayer = tw.div`
  flex flex-row items-center
  w-[100px] T:w-[150px]
`;

const BalanceTextLayer = tw.div`
  flex-1
`;

const BalanceCoinTypeLayer = tw.div`
  w-[30px] T:w-[60px]
`;

type WithdrawalInfoType = {
  CPF: string;
  PIX_ACCOUNT: string;
  firstName: string;
  lastName: string;
};

export default function UserInfoView() {
  const [user] = useUser();
  const { lngT } = useLang('mypage:userInfo');
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalStore();

  const FetchUserBalancContainer = genFetchContainer<{ coin: number; free: number }>({
    url: `${env.api.host}/balance`,
  });

  const UserAccountContainer = genFetchContainer<WithdrawalInfoType>({ url: `${env.api.host}/withdrawal/info` });

  const handleClickNavigateCharge = () => navigate('/mypage/account');

  const handleClickOpenExchangeModal = () =>
    openModal(modalId => <ConversionPointPopup closeModal={() => closeModal(modalId)} />);

  const handleClickOpenChangePinPopup = () =>
    openModal(modalId => <ChangePinPopup closeModal={() => closeModal(modalId)} />);

  return (
    <ContentLayout title={lngT('title')}>
      <Layer>
        <ContentBoxLayout>
          <UserLayer>
            <UserInfoLayer>
              <UserAvatar />
              <UserInfoTextLayer>
                <PlainText text={user?.email ?? ''} />
                <PlainText text={user?.name ?? ''} />
              </UserInfoTextLayer>
            </UserInfoLayer>

            <FetchUserBalancContainer>
              {({ data }) => {
                return (
                  <UserBalanceLayer>
                    <CoinBoxLayout type="point" tooltipAlways={true}>
                      <BalanceLayer>
                        <BalanceTextLayer>
                          <PlainText text={fixBalance(data?.data.coin)} />
                        </BalanceTextLayer>
                        <BalanceCoinTypeLayer>
                          <InfoText text={lngT('conversion:history:table:point')} />
                        </BalanceCoinTypeLayer>
                      </BalanceLayer>
                      <MainButton content={lngT('charge')} onClick={handleClickNavigateCharge} />
                    </CoinBoxLayout>
                    <CoinBoxLayout type="free" tooltipAlways={true}>
                      <BalanceLayer>
                        <BalanceTextLayer>
                          <PlainText text={fixBalance(data?.data.free)} />
                        </BalanceTextLayer>
                        <BalanceCoinTypeLayer>
                          <InfoText text={lngT('conversion:history:table:bpoint')} />
                        </BalanceCoinTypeLayer>
                      </BalanceLayer>
                      <MainButton content={lngT('conversion')} onClick={handleClickOpenExchangeModal} />
                    </CoinBoxLayout>
                  </UserBalanceLayer>
                );
              }}
            </FetchUserBalancContainer>
          </UserLayer>
        </ContentBoxLayout>
        <Line />
        <ContentBoxLayout title={lngT('email')}>{user?.email}</ContentBoxLayout>
        <Line />
        <ContentBoxLayout title={lngT('nickname')}>{user?.name}</ContentBoxLayout>
        <Line />
        <UserAccountContainer>
          {({ data }) => {
            if (!data?.data) return null;

            return (
              <div className="flex flex-col space-y-4">
                <ContentBoxLayout title={lngT('withdrawal:info')}>TYPE: PIX PAY</ContentBoxLayout>
                <ContentBoxLayout>{`CPF: ${maskString(data.data?.CPF, 4, 'right')}`}</ContentBoxLayout>
                <ContentBoxLayout>{`PIX ACCOUNT: ${maskString(data.data?.PIX_ACCOUNT, 4, 'left')}`}</ContentBoxLayout>
                <Line />
                <div>
                  <MainButton content={lngT('withdrawal:resetPincode')} onClick={handleClickOpenChangePinPopup} />
                </div>
              </div>
            );
          }}
        </UserAccountContainer>
      </Layer>
    </ContentLayout>
  );
}
