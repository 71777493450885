import styles from './divider.module.css';
import BlackStar from '@/img/landingPage/blackstar.svg';
import BlackKnight from '@/img/landingPage/blackknight.svg';
import { useLang } from '@/lib/hook';

const DividerBanner = () => {
  const { lngT } = useLang('landingPage:banner');
  return (
    <div className={styles.bannerContainer}>
      <img src={BlackKnight} width={25} alt="horse" />
      <p>{lngT('title:banner:2')}</p>
      <img src={BlackStar} width={15} alt="star" />
      <p>{lngT('title:banner:1')}</p>
      <img src={BlackKnight} width={25} alt="horse" />
      <p>{lngT('title:banner:3')}</p>
    </div>
  );
};

export default DividerBanner;
