import { create } from 'zustand';

import type { ReactNode } from 'react';

export type ModalInstance = {
  id: number;
  content: ReactNode;
  useCloseDim: boolean;
};

type ModalStore = {
  modals: ModalInstance[];
  openModal: (render: (id: number) => ReactNode, useCloseDim?: boolean) => number;
  closeModal: (id: number | 'all') => void;
  closeAll: () => void;
};

const useModalStore = create<ModalStore>()((set, get) => ({
  modals: [],
  openModal: (render, useCloseDim = false) => {
    const newModalId = get().modals.length > 0 ? Math.max(...get().modals.map(modal => modal.id)) + 1 : 1;

    const content = render(newModalId);

    set(state => {
      return {
        modals: [
          ...state.modals,
          {
            id: newModalId,
            content,
            useCloseDim,
          },
        ],
      };
    });

    const body = document.querySelector('body');
    if (body) body.style.overflowY = 'hidden';

    return newModalId;
  },
  closeModal: id => {
    set(state => ({
      modals: id === 'all' ? [] : state.modals.filter(modal => modal.id !== id),
    }));

    const body = document.querySelector('body');
    if (body) body.style.overflowY = 'overlay';
  },
  closeAll: () => set({ modals: [] }),
}));

export default useModalStore;
