import tw from 'tailwind-styled-components';
import CountBG from '../../img/powerball/b_count_bg.png';
import RoundBG from '../../img/powerball/b_round_bg.png';
import Logo from '../../img/powerball/logo_betting.png';

import { useLang } from '../../lib/hook';

const RoundBox = tw.div`
  h-full w-full relative rounded-2xl relative
`;

const CountDownBox = tw.div`
  relative flex flex-col items-center justify-center
  space-y-1 w-[160px] h-full px-1 py-1
`;

export default function NumberHeader({ count, round, formattedToday }) {
  let minute = Math.trunc(count / 60);
  let second = count - minute * 60;
  let firsrtSec = Math.floor(second / 10);
  let secondSec = second % 10;

  const { lngT } = useLang('powerball');

  return (
    <div className="h-[84px] max-[786px]:h-full w-full flex flex-row max-[768px]:flex-col justify-between space-x-4 items-center pl-3 max-[786px]:pl-0 max-[786px]:space-y-4 max-[1440px]:pr-0 max-[1440px]:space-x-2 max-[480px]:pl-0 ">
      <div className="w-[400px] max-[768px]:w-[250px]">
        <img alt="Logo" src={Logo} className="text-white" />
      </div>
      <div className="w-full h-full flex flex-row justify-between space-x-4 max-[786px]:space-x-2 max-[786px]:pr-2">
        <RoundBox>
          <div className="h-full flex flex-col items-center justify-center py-2 max-[786px]:py-4 relative z-10 ">
            <div className="flex flex-row text-3xl font-extrabold gap-2 max-[480px]:text-2xl max-[360px]:text-lg">
              <h2 className="text-white">{/*ROUND*/ lngT('lottery:history:round')}</h2>
              <h2 className="text-[#FFC801]">{round}</h2>
            </div>
            <div className=" text-white max-[480px]:text-sm max-[480px]:tracking-tighter">{formattedToday}</div>
          </div>
          <img alt="Round BG" src={RoundBG} className="w-full h-[86px] absolute z-1 top-0" />
        </RoundBox>
        <CountDownBox>
          <div className="rounded-lg w-[160px] max-[786px]:w-[100px] text-white text-center relative z-10">
            {/*count*/ lngT('lottery:header:count')}
          </div>
          <div className="flex flex-row justify-center items-center space-x-2 relative z-10">
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-row justify-center items-center space-x-[2px]">
                <div className="bg-[#232E4A] rounded-md px-[5px] py-[2px] text-white text-[20px]">0</div>
                <div className="bg-[#232E4A] rounded-md px-[5px] py-[2px] text-white text-[20px]">{minute}</div>
              </div>
              <div className="text-xs">{/*mins*/ lngT('lottery:header:minutes')}</div>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-row justify-center items-center space-x-[2px]">
                <div className="bg-[#232E4A] rounded-md px-[5px] py-[2px] text-white text-[20px]">{firsrtSec}</div>
                <div className="bg-[#232E4A] rounded-md px-[5px] py-[2px] text-white text-[20px]">{secondSec}</div>
              </div>
              <div className="text-xs">{/*secs*/ lngT('lottery:header:seconds')}</div>
            </div>
          </div>
          <img alt="bg" className="w-full h-full absolute -z-1" src={CountBG} />
        </CountDownBox>
      </div>
    </div>
  );
}
