import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Gnb from '../Gnb_W';
import GnbPowerball from '../Gnb_P';
// import Gnb from '../Gnb';
import Background from '../Background';
import Footer from '../Footer_N';
import FooterPowerball from '../Footer_P';
// import Footer from '../Footer';
import RevalidateToken from '../RevalidateToken';
import NewModal from '@/page/common/custom-lib/modal/Modal';

import { useModalStore, Modal } from '@/lib/custom-lib/modal';
import { useCoindropStore } from '@/store/coindrop';
import { CoinDropPopup } from '@/components/coindrop';
import * as config from '../../config';
import FaviconTitle from '../FaviconTitle';
import IPChecker from '../IPChecker';

export default function RootLayout() {
  const location = useLocation();
  const { isModalOpen } = useModalStore();
  const { i18n } = useTranslation();
  const { isGamePopupOpen } = useCoindropStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const lng = localStorage.getItem('lng');

    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, []);

  return (
    <div className="relative font-popins min-h-screen">
      <IPChecker />
      <ToastContainer />
      <FaviconTitle />
      <RevalidateToken />
      <Background />
      <NewModal />
      {isModalOpen && <Modal />}
      {isGamePopupOpen && <CoinDropPopup />}
      <header id="u-podium-header" className="z-[98] sticky top-0">
        {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? <Gnb /> : <GnbPowerball />}
      </header>
      {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? (
        <main className="relative scrollbar-thin scrollbar-thumb-gray-500/80 scrollbar-thumb-rounded-lg min-h-screen max-[360px]:min-h-[100vh] max-T:min-h-[120vh] max-[924px]:min-h-[110vh]">
          <Outlet />
        </main>
      ) : (
        <main className="relative scrollbar-thin scrollbar-thumb-gray-500/80 scrollbar-thumb-rounded-lg min-h-[800px] h-fit">
          <Outlet />
        </main>
      )}

      <footer className="relative w-full">
        {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? <Footer /> : <FooterPowerball />}
      </footer>
    </div>
  );
}
