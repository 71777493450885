import { type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

// import Point from '@/assets/images/coin_point.png';
// import Free from '@/assets/images/coin_free.png';
import { Point } from '@/components/gameui/point';
import { Bonus } from '@/components/gameui/bonus';
import * as define from '../../../define';

type Props = {
  type: 'point' | 'free';
  children: ReactNode;
  tooltipAlways: boolean;
};

const Layer = tw.div`
  flex flex-row items-center
  w-full
  gap-x-4
`;

const CoinImage = tw.img`
  w-[24px]
`;

const ContentLayer = tw.div`
  flex flex-row items-center justify-between
  flex-1
`;

export default function CoinBoxLayout({ type, children, tooltipAlways }: Props) {
  if(!define.SHOW_METACROSS_ONLY)
    return;

  return (
    <Layer>
      {/* <CoinImage src={type === 'point' ? Point : Free} alt={type === 'point' ? 'point' : 'free point'} /> */}
      {type === 'point' ? (
        <Point size={7} toggleType={false} />
      ) : (
        <Bonus size={7} tooltipAlways={tooltipAlways} toggleType={false} />
      )}
      <ContentLayer>{children}</ContentLayer>
    </Layer>
  );
}
