import { makeStyles } from "@material-ui/styles";

var Red = '#EC6964';
var Yellow = '#F0A43C';
var Blue = '#5B9CFB';
var Purple = '#C46AF5';
var Sky ='#739DEA';
var Green ='#3cd2a5';

export const useOddEvenStyles = makeStyles(theme => ({
    root: {
        color:'#fff',
        textTransform:'uppercase',
        overflow: 'hidden'
    },
    tableTitleYellow:{
        textAlign: 'center',
        fontSize:'1.375rem!important',
        paddingBottom:8,
        paddingTop:20,
        '&:first-of-type':{
            paddingTop:0,
        },
        borderColor:Yellow, 
        color:Yellow+'!important',
        '& .MuiSelect-select, & svg':{
            color:Yellow+'!important',
        },
    },
    tableTitleBlue:{
        fontSize:'1.375rem!important',
        paddingBottom:8,
        paddingTop:20,
        '&:first-of-type':{
            paddingTop:0,
        },
        borderColor:Blue, 
        color:Blue+'!important',
        '& .MuiSelect-select, & svg':{
            color:Blue+'!important',
        },
    },


    tableTitle:{
        fontSize:'1.375rem!important',
        paddingBottom:8,
        paddingTop:20,
        '&:first-of-type':{
            paddingTop:0,
        }
    },
    thStyle:{
        '& th':{
            color:'#111520',
            fontSize:'1.063rem',
            fontWeight:'bold',
            border:'0 none',
            padding:6,
            textShadow:'1px 1px #45454599',
            '@media all and (max-width:399px)':{
                fontSize:'0.938rem',
            }
        }
    },
    tbStyle:{
        '& td':{
            minHeight:40,
            height:40,
            padding:0,
            border:'0 none',
            lineHeight:1,
            '& > div':{
                minHeight:40,
                height:40,
                display:'flex',
                alignItems:'center',
                justifyContent: 'center',
                margin:1,
                fontSize:'1rem',
                borderWidth:1,
                borderStyle:'solid',
                '@media all and (max-width:399px)':{
                    fontSize:'0.875rem',
                },
                '@media all and (max-width:339px)':{
                    fontSize:'0.75rem',
                    letterSpacing:'-0.5px',
                }
            }
        },
        '& > tr > td:first-child':{
            width:'15%',
        }
    },
    trStyle:{
        '& td, th':{
            minHeight:40,
            height:40,
            width:100,
            '@media all and (max-width:375px)':{
                minHeight:'auto',
                height:'auto',
            }
        },
      
    },
    topLienStyle:{
        borderTop:'12px solid transparent'
    },
    bottomLienStyle:{
        borderBottom:'12px solid transparent'
    },
    bottomLienStyle2:{
        borderBottom:'12px solid transparent '
    },
    btnStyle:{
        width:'6rem',
        color:'#44494c!important',
        backgroundColor:'#53595e!important',
        '@media all and (max-width:360px)':{
            width:'auto',
        }
    },
    bgRed:{backgroundColor:Red},
    tdRed:{borderColor:Red,color:Red+'!important'},
    gradiRed:{
        background:'linear-gradient(to right, #d93a88, #ec84b7)',
        color:'#fff!important',
    },
    bgYellow: {backgroundColor:Yellow},
    tdYellow:{
        borderColor:Yellow, 
        color:Yellow+'!important',
        '& .MuiSelect-select, & svg':{
            color:Yellow+'!important',
        },},
    gradiYellow:{
        background:'linear-gradient(to right, #b99212, #F2D074)',
        color:'#fff!important',
    },
    bgBlue: {backgroundColor:Blue,},
    tdBlue:{
        borderColor:Blue,
         color:Blue+'!important',
        '& .MuiSelect-select, & svg':{
            color:Blue+'!important',
        },
    },
    gradiBlue:{
        background:'linear-gradient(to right, #145afa, #145afa)',
        color:'#fff!important',
    },
    bgPurple: {backgroundColor:Purple},
    tdPurple:{borderColor:Purple, color:Purple+'!important'},
    gradiSky:{
        background:'linear-gradient(to right, #2ea9ca, #83daf1)',
        color:'#fff!important',
    },
    skyStyle:{
        borderColor:Sky,
        color:Sky+'!important',
    },
    bgGreen:{backgroundColor:Green},
    tdGreen:{
        borderColor:Green,
        color:Green+'!important',
        '& .MuiSelect-select, & svg':{
            color:Green+'!important',
        },
    },
    formControl:{
        width:'100%',
        minHeight:'auto',
    },
    selectBox:{
        '& .MuiSelect-select':{
            padding:'0 8px',
            textAlign:'left',
            '@media all and (max-width:399px)':{
                fontSize:'0.875rem',
                letterSpacing:'-0.5px',
                padding:'0 4px',
                paddingRight:'22px!important',
            },
        },
        '& > svg':{
            '@media all and (max-width:399px)':{
                fontSize:'1.063rem',
                right:3,
            }
        },
        '& fieldset':{
            border:'0 none',
        },
    },
    menuItem:{
        borderTop:'1px solid #2b2b2b!important',
        backgroundColor:'#111520!important',
        color:'#fff!important',
        // fontFamily: 'Chakra Petch, Montserrat, Passion One, sans-serif!important',
        '@media all and (max-width:399px)':{
            fontSize:'0.875rem!important',
        }
    },
    
    //thStyleYellow:{
    //    '& th':{
    //        color:'#111520',
    //        fontSize:'1.063rem',
    //        fontWeight:'bold',
    //        border:'0 none',
    //        padding:6,
    //        textShadow:'1px 1px #45454599',
    //        '@media all and (max-width:399px)':{
    //            fontSize:'0.938rem',
    //        }
    //    }
    //},
    //thStyleYellow:{
    //    '& th':{
    //        //color:'#111520',
    //        fontSize:'1.063rem',
    //        fontWeight:'bold',
    //        border:'0 none',
    //        padding:6,
    //        textShadow:'1px 1px #45454599',
    //        '@media all and (max-width:399px)':{
    //            fontSize:'0.938rem',
    //        },
    //        //borderColor:Yellow, 
    //        //color:Yellow+'!important',
    //        //'& .MuiSelect-select, & svg':{
    //        //    color:Yellow+'!important',
    //        //},
    //    }
    //},

}));

