import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useUser } from '@/lib/hook';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

export default function GameLayout() {
  const [user] = useUser();
  const navigate = useCustomNavigate();

  useEffect(() => {
    if (!user) navigate('/login');
  }, [user]);

  return (
    <>
      <Outlet />
    </>
  );
}
