import {REEL_ZINDEX} from "../../../util/reeldefine";

const uitop = 45;
export const styles = (theme) => ({
    base: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 180px)',
        backgroundImage: 'url("/assets/img/bg_color.jpg")', // 이미지 경로를 여기에 입력
        backgroundSize: 'cover', // 이미지를 div에 맞게 확대/축소
        backgroundPosition: 'center', // 이미지를 가운데 정렬
        backgroundRepeat: 'no-repeat', // 이미지 반복 없음
    },

    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        // height: 'calc(100vh - 185px)',
        aspectRatio: '2000/1000',
        backgroundImage: 'url("/assets/img/background.webp")', // 이미지 경로를 여기에 입력
        backgroundSize: 'cover', // 이미지를 div에 맞게 확대/축소
        backgroundPosition: 'center', // 이미지를 가운데 정렬
        backgroundRepeat: 'no-repeat', // 이미지 반복 없음

        maxWidth: '2000px',
        minWidth: '280px',
    },

    main: {
        position: 'relative',
        width: '100%',
        aspectRatio: '744/1043',
        maxWidth: '744px',
        maxHeight: '1043px',
        minWidth: '280px',
        minHeight: '400px',
    },
    main_sub: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },

    control_root: {
        position: 'relative',
        zIndex:  REEL_ZINDEX.UI_TOP,
        width: '100%',
        height: '100%',
    },

    bg_image: {
        position: 'absolute',
        zIndex:  REEL_ZINDEX.UI_CANVAS_OVER,
        width: '100%',
        height: '100%',
    },

    effect : {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },

    control_main: {
        position: 'relative',
        zIndex: REEL_ZINDEX.UI_TOP,
        width: '100%',
        height: '100%',

        display : 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    control_area: {
        display : 'flex',
        flexDirection:'column',
        alignItems: 'center',
        width: '80%',
        height: '100%',
    },

    control_header: {
        width: '100%',
        height: '25%',
    },
    control_header2: {
        width: '100%',
        height: '50%',
    },
    control_header3: {
        width: '100%',
        height: '60%',
    },
    control_header4: {
        width: '25%',
        height: '100%',
        float : 'right',
        display : 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    control_mute: {
        width: "35%",
        transition: 'transform 0.5s ease',

        '&:active': {
            transform: 'scale(1.5)',
        },
        '&:hover': {
            filter: 'saturate(150%)',
        },
        '&:disabled': {
            opacity: 0.5, // 투명도를 줄여서 비활성화된 상태를 나타냄
            transform: 'none', // 변환 효과를 없애서 작동하지 않음을 나타냄
            filter: 'none'
        },
        '&:focus': {
            outline: 'none', // 포커스 시 아웃라인 제거
        },
    },

    control_body: {
        width: '100%',
        height: '53%',
        display: 'flex',
        flexDirection: 'column', // 수직으로 정렬
        justifyContent: 'end',
        alignItems: 'center',
    },
    control_text_div: {
        display: 'flex',
        flexDirection: 'column', // 수직으로 정렬
        alignItems: 'center',

        width: '85%',
        aspectRatio: '599/102',

        backgroundImage: 'url("/assets/img/bet_text.png")',
        backgroundSize: 'contain', // 이미지를 div에 맞게 확대/축소
        backgroundPosition: 'center', // 이미지를 가운데 정렬
        backgroundRepeat: 'no-repeat', // 이미지 반복 없음
    },

    control_text_div2: {
        display: 'flex',
        flexDirection: 'column', // 수직으로 정렬
        justifyContent: 'center',
        alignItems: 'center',

        width: '100%',
        height : '87%',

        fontSize: 'clamp(8px, 3vw, 20px)',
    },

    control_text_img: {
        // width: '45px',
        // height: '45px',
        //
        // '@media all and (max-width: 600px)': {
        //     width: '32px',
        //     height: '32px',
        // },
        // '@media all and (max-width: 550px)': {
        //     width: '30px',
        //     height: '30px',
        // },
        // '@media all and (max-width: 500px)': {
        //     width: '28px',
        //     height: '28px',
        // },
        // '@media all and (max-width: 450px)': {
        //     width: '26px',
        //     height: '26px',
        // },
        // '@media all and (max-width: 400px)': {
        //     width: '25px',
        //     height: '25px',
        // },
        // '@media all and (max-width: 350px)': {
        //     width: '24px',
        //     height: '24px',
        // },

        width: 'clamp(23px, 4.5vw, 45px)',
        height: 'clamp(23px, 4.5vw, 45px)',
        marginRight: '4px',
    },

    control_text_with_img:{
        marginRight: '15px',
        '@media all and (max-width: 600px)': {
            marginRight: '10px',
        },
        '@media all and (max-width: 550px)': {
            marginRight: '10px',
        },
        '@media all and (max-width: 500px)': {
            marginRight: '10px',
        },
        '@media all and (max-width: 450px)': {
            marginRight: '10px',
        },
        '@media all and (max-width: 400px)': {
            marginRight: '10px',
        },
        '@media all and (max-width: 350px)': {
            marginRight: '10px',
        },
    },

    control_text_sub: {
        display: 'flex',
        flexDirection: 'column', // 수직으로 정렬
        justifyContent: 'center',
        overflow: 'hidden',
        width: '95%',
    },

    control_text: {
        fontFamily: 'Komikax',
        textShadow: '-1px 1px 5px #000',
        color: '#FED354',

        transition: 'transform 0.5s ease',
        whiteSpace: 'nowrap',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width : '100%',
        textAlign: "center",
        textJustify: "center",
    },

    control_text_cn: {
        fontFamily: 'Microsoft Yahei',
        textShadow: '-1px 1px 5px #000',
        color: '#FED354',

        transition: 'transform 0.5s ease',
        whiteSpace: 'nowrap',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width : '100%',
        textAlign: "center",
        textJustify: "center",
    },

    control_text_wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width:'100%',
        alignContent: 'center',
        marginLeft: '60px',
        '@media all and (max-width: 600px)': {
            marginLeft: '50px',
        },
        '@media all and (max-width: 550px)': {
            marginLeft: '40px',
        },
        '@media all and (max-width: 500px)': {
            marginLeft: '30px',
        },
        '@media all and (max-width: 450px)': {
            marginLeft: '30px',
        },
        '@media all and (max-width: 400px)': {
            marginLeft: '15px',
        },
        '@media all and (max-width: 350px)': {
            marginLeft: '15px',
        },
    },

    control_text_wrapper_jackpot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width:'100%',
        alignContent: 'center'
    },

    control_text_animation: {
        animation: `$slideIn 6s linear infinite`, // 애니메이션 지속 시간 및 반복
    },
    '@keyframes slideIn': {
        '0%': {
            transform: 'translateX(0)',
        },
        '20%': {
            transform: 'translateX(0)', // 왼쪽으로 20px 이동 시작
        },
        '100%': {
            transform: 'translateX(-170%)', // 왼쪽으로 전체 텍스트 길이만큼 이동
        },
    },

    control_bottom: {
        display: 'flex',
        alignItems : "center",
        width: '95%',
        height: '13%',
    },
    control_bottom2: {
        display: 'flex',
        width: '55%',
        height: '70%',
    },
    control_label: {
        width: '35%',
        height: '100%',
    },

    control_label_item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        height: '50%',
        float: "right"
    },

    control_label_d: {
        height: '55%',
        paddingRight: '3%'
    },

    control_coin: {
        width: '70%',
        height: '95%',
    },

    control_coin_item: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems:'flex-end',

        backgroundImage: 'url("/assets/img/has_coin.png")',
        backgroundSize: 'contain', // 이미지를 div에 맞게 확대/축소
        backgroundRepeat: 'no-repeat', // 이미지 반복 없음

        width: '100%',
        height: '50%',
    },

    control_coin_item2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems:'flex-end',

        backgroundImage: 'url("/assets/img/bet_coin.png")',
        backgroundSize: 'contain', // 이미지를 div에 맞게 확대/축소
        backgroundRepeat: 'no-repeat', // 이미지 반복 없음

        width: '100%',
        height: '50%',
    },

    control_coin_d: {
        textAlign: "end",
        textJustify: "center",
        width: '70%',
        paddingRight: '10%',

        textShadow: '-1px 1px 5px #000',
        fontFamily: 'Comic',
        color: '#FAD70A',

        transition: 'transform 0.5s ease',
        fontWeight: 'bold',
        fontSize: 'clamp(8px, 3vw, 20px)',
    },

    control_spin_div: {
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        width: '45%',
        height: '70%',
    },

    control_button: {
        width: '25%',
        height: '70%',
    },
    control_plus_item: {
        transition: 'transform 0.5s ease',

        '&:active': {
            transform: 'scale(1.5)',
        },
        '&:hover': {
            filter: 'saturate(150%)',
        },
        '&:disabled': {
            opacity: 0.5, // 투명도를 줄여서 비활성화된 상태를 나타냄
            transform: 'none', // 변환 효과를 없애서 작동하지 않음을 나타냄
            filter: 'none'
        },
        '&:focus': {
            outline: 'none', // 포커스 시 아웃라인 제거
        },
    },
    control_spin_button: {
        display: 'flex',
        width: '45%',
        height: '130%',
        '&:focus': {
            outline: 'none', // 포커스 시 아웃라인 제거
        },
    },

    canvas_root: {
        position:'absolute',
        zIndex:REEL_ZINDEX.CANVAS,
        left:'0px',
        top:'0px',
        height:'100%',
        width:'100%',

        // height: 'calc(100vh - 50px)',
        // aspectRatio: '744/1043',
        maxWidth: '744px',
        maxHeight: '1043px',
        minWidth: '280px',
        minHeight: '400px',
    },
    canvas_base_line: {
        width: '100%',
        height: '22%',
    },
    canvas_main: {
        // width: '100%',
        height: '50.5%',
        maxHeight: '531px',
        minWidth: '204px',
    },



    betcoin_keyframe: {
        animation: '$scaleAnimation 0.3s ease forwards'
    },
    '@keyframes scaleAnimation': {
        '0%': {
            transform: 'scale(1)'
        },
        '100%': {
            transform: 'scale(1.5)'
        },
    },
    hascoin_keyframe: {
        animation: '$scaleAnimationHas 0.5s ease forwards'
    },
    '@keyframes scaleAnimationHas': {
        '0%': {
            transform: 'scale(1)'
        },
        '60%': {
            transform: 'scale(1.5)'
        },
        '100%': {
            transform: 'scale(1)'
        },
    },
    reward_keyframe: {
        animation: '$scaleAnimationReward 0.6s ease forwards'
    },
    '@keyframes scaleAnimationReward': {
        '0%': {
            transform: 'scale(1)'
        },
        '60%': {
            transform: 'scale(1.5)'
        },
        '100%': {
            transform: 'scale(1)'
        },
    },

    debug_root: {
        position: 'absolute',
        left: '100px',
        top: `${710+300}px`,

        '@media all and (max-height: 1050px)': {
            left: '70px',
            top: `${500+300}px`,
        },
        '@media all and (max-height: 850px)': {
            left: '35px',
            top: `${200+300}px`,
        },
        '@media all and (max-height: 540px)': {
            left: '20px',
            top: `350px`,
        },
    },

    canvas_bottom: {
        position: 'absolute',
        zIndex:  REEL_ZINDEX.UI_CANVAS_BOTTOM,
        height:'2160px',
        width:'3840px',

        '@media all and (max-width: 1080px)': {
            height:'1080px',
            width:'1920px',
        },
    },
});
