/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/lottomachine_new_07.glb 
*/

import React, { useEffect, useRef, useState, useContext } from 'react'
import { useGLTF, useAnimations, MeshTransmissionMaterial } from '@react-three/drei'
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import * as define from '../../../define'
import * as THREE from 'three'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

let uvs = [];
const startball = 0;
const ballCount = 10;
let copyMaterials = [];
let totalFrame = 0;
let elapsedTime = 0;
let accumelapsedTime = 0;
let serverballs = [1,1,1,1,1];
let isMobile = true;

export function LottomachineRed(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/powerball/lottomachine_red.glb')
  const { actions } = useAnimations(animations, group)

  const options = {
    enableSwoopingCamera: false,
    enableRotation: true,
    transmission: 1.0,
    opacity: 1.0,
    metalness: 0,
    roughness: 0,
    ior: 1.5,
    thickness: 0.01,
    specularIntensity: 1.0,
    specularColor: '#ffffff',      
    envMapIntensity: 1.0,
    lightIntensity: 1.0,
    exposure: 1.0
  };
  const GetENV=()=>
  {
    const hdrEquirect = useLoader(RGBELoader, "/powerball/empty_warehouse_01_2k.hdr");
    hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
    return hdrEquirect;
  }
  const pmaterial = 
  isMobile ? materials.glass_001 :
  new THREE.MeshPhysicalMaterial({
    // enableSwoopingCamera:options.enableSwoopingCamera,
    // enableRotation:options.enableRotation,
    // transmissionSampler: false,
    transmission: options.transmission,
    opacity: options.opacity,
    metalness: options.metalness,
    roughness: options.roughness,
    ior: options.ior,
    thickness: options.thickness,
    specularIntensity: options.specularIntensity,
    specularColor: options.specularColor,
    envMapIntensity: options.envMapIntensity,
    // lightIntensity: options.lightIntensity,
    // exposure: options.exposure,
    // distortion:1.0,
    
    // anisotropy:10,
    // distortion:10,
    // distortionScale:10,
    // temporalDistortion:10,
    // chromaticAberration:10,
    // backside:true,
    // backsideThickness:10,
    
    // attenuationDistance:1.0,
    // attenuationColor: '#444444',


    // clear coat : 외각 두께 컬러
    clearcoat:100,
    clearcoatRoughness:1,
    clearcoatNormalScale:10,
    
    // sheen : 외각 두께 컬러
    sheen:1.0,
    sheenColor:'#ffffff',
    sheenRoughness:0.15,
    
    envMap: GetENV()
  })


  // let config = 
  // useControls({
  //   meshPhysicalMaterial: false,
  //   transmissionSampler: false,
  //   backside: true,
  //   backsideThickness: { value: 1, min: -10, max: 10 },
  //   samples: { value: 2, min: 0, max: 32, step: 1 },
  //   resolution: { value: 32, min: 32, max: 2048, step: 256 },
  //   backsideResolution: { value: 256, min: 32, max: 2048, step: 256 },
  //   transmission: { value: 1, min: 0, max: 1 },
  //   roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   ior: { value: 1.0, min: 1, max: 5, step: 0.01 },
  //   thickness: { value: 0.4, min: 0, max: 10, step: 0.01 },
  //   chromaticAberration: { value: 0.0, min: 0, max: 1 },
  //   anisotropy: { value: 0.3, min: 0, max: 1, step: 0.01 },
  //   distortion: { value: 0.1, min: 0, max: 1, step: 0.01 },
  //   distortionScale: { value: 0.3, min: 0.01, max: 1, step: 0.01 },
  //   temporalDistortion: { value: 0.65, min: 0, max: 1, step: 0.01 },
  //   attenuationDistance: { value: 0.5, min: 0, max: 2.5, step: 0.01 },
  //   clearcoat: { value: 0.5, min: 0, max: 1 },
  //   attenuationColor: '#eeeeee',
  //   // color: props.color,
  //   specularIntensity : {value:2,min:-2,max:5},
  //   envMapIntensity:{value:1.0,min:-2,max:2},
  // })



  let config = 
  {
    meshPhysicalMaterial: false,
    transmissionSampler: false,
    backside: false,
    backsideThickness: 1,
    samples: 10,
    resolution: 2048,
    backsideResolution: 1024,
    transmission: 1.00,
    roughness: 0.0,
    ior: 1.00,
    thickness: 0.10,
    chromaticAberration: 0.0,
    anisotropy: 0.3,
    distortion: 0.0,
    distortionScale: 0.0,
    temporalDistortion: 0.65,
    attenuationDistance: 0.5,
    clearcoat: 0.9,
    attenuationColor: '#eeeeee',
    // color: props.color,    
    // specularIntensity : 2,
    envMapIntensity:1.1,
    
  }  

  const BLENDER_FPS = 30;
  const eventFrames = [0,40,120,200,280,360,440,580];
  
  const ChangeMaterial_origin=(targetNum)=>{
    let arridx = String(targetNum).padStart(3, "0");
    let arridxStr = `${arridx}`
    // nodes[arridxStr].geometry.attributes.uv = uvs[4];
    materials[arridxStr] = copyMaterials[29];
  }

  const ChangeMaterial_new=(targetNum)=>{
    let arridx = String(targetNum).padStart(3, "0");
    let arridxStr = `new_${arridx}`
    nodes[arridxStr].geometry.attributes.uv = uvs[2];
  }

  const ChangeNewBallMaterial=()=>{
    let matindex = serverballs-1;
    if(matindex < copyMaterials.length)
      materials['new_001'] = copyMaterials[matindex];
  }

  var dispatch = props.callUseDispatch;
  //const getState = props.curState;//props.useSelector((state)=>state.curState);
  //const getState = useSelector((state)=>state.curState);
  const getState = props.curState;//stock.curState;

  useFrame((state,delta,frame)=>{
    elapsedTime += delta;
    // console.log("elapsedTime : " + elapsedTime);
    let action = actions["Animation"];    
    action.time = (elapsedTime)%action._clip.duration;
  })
  
  useEffect(()=>{
    if(props.Powerball !== undefined && props.Powerball !== null)
    {
      // console.log('useEffect Red Powerball.gameframe Machine : ' +  props.Powerball.gameframe);      
      serverballs = props.Powerball === null || props.Powerball === undefined ? 1 : props.Powerball.powerBall;//[5,2,15,22,27];
      elapsedTime = props.Powerball === null || props.Powerball === undefined ? 0 : props.Powerball.gameframe;
      ChangeNewBallMaterial();
    }
  },[props.Powerball])
  
  useEffect(()=>{    
    isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    uvs = [];
    copyMaterials = [];

    let action = actions["Animation"];
    if(action !== undefined)
    {
      action.play();
      totalFrame = (action._clip.duration * BLENDER_FPS).toFixed();
    }
    
    for(let i = startball; i < ballCount; ++i)
    {
      let arridx = String(i+1).padStart(3, "0");
      let arridxStr = `${arridx}`
      // let uv = nodes[arridxStr].geometry.attributes.uv;
      // uvs.push(uv);
      copyMaterials.push(materials[arridxStr]);
    }

    if(props.Powerball === null || props.Powerball === undefined)
    {
      elapsedTime = 40;
    }
    else
      elapsedTime = props.Powerball.gameframe;
      // elapsedTime = 10;

    serverballs = props.Powerball === null || props.Powerball === undefined ? 1 : props.Powerball.powerBall;//[5,2,15,22,27];
    ChangeNewBallMaterial();
  },[])

  useFrame((state, delta)=>{    
    if(isMobile)
      return;

    const t = (3 + Math.sin(elapsedTime * 4)) / 4;    
    for(let i = 0 ; i < refCylinder006Mat.length; ++i)
    {        
        refCylinder006Mat[i].current.color.r = constColor[0] * t;
        refCylinder006Mat[i].current.color.g = constColor[1] * t;
        refCylinder006Mat[i].current.color.b = constColor[2] * t;      
    }    
    refCylinder006Mat[0].current.side = THREE.DoubleSide;
  })

  // glass_002 는 기둥, 바닥 바깥 원 유리
  const constColor = [16,1,1];
  let brightColor = [16,1,1];
  const refCylinder006Mat = [useRef(null),useRef(null)];  
  // materials.glass_002.side = THREE.DoubleSide;

  return (
<group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={pmaterial} position={[0, 1.382, -0.325]}/>
        <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
        <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
        <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
        <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
        <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879+0.2, -0.169]} scale={0.685}>
        <meshBasicMaterial ref = {refCylinder006Mat[0]} 
          toneMapped={false} 
          color={brightColor}
        />
        </mesh>
        
        <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} position={[0, 0.578, -0.325]}>
        <meshBasicMaterial ref = {refCylinder006Mat[1]} 
          toneMapped={false} 
          color={brightColor}
        />
        </mesh>        
        <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
        <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials.new_001} position={[0, 0.9, -0.22]} />
        <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.174, 1.04, -0.428]} rotation={[Math.PI, 1.092, 2.88]} />
        <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[0.114, 1.04, -0.237]} rotation={[3.088, -0.03, 0.071]} />
        <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.104, 1.04, -0.498]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.178, 1.04, -0.356]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[-0.033, 1.04, -0.509]} rotation={[1.794, -0.88, -0.687]} />
        <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.037, 1.04, -0.17]} rotation={[0.709, -0.577, -1.352]} />
        <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.079, 1.04, -0.395]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[-0.071, 1.04, -0.403]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.096, 1.04, -0.18]} rotation={[-2.033, -0.277, 0.201]} />
        <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[-0.116, 1.04, -0.286]} rotation={[-2.011, -0.665, 0.574]} />
      </group>
    </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">        
    //     <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={pmaterial} position={[0, 1.382, -0.325]}/>
    //     <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
    //     <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
    //     <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
    //     <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
    //     <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
        
        
    //     <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879+0.2, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[0]} 
    //       toneMapped={false} 
    //       color={brightColor}
    //     />
    //     </mesh>
        
    //     <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} position={[0, 0.578, -0.325]}>
    //     <meshBasicMaterial ref = {refCylinder006Mat[1]} 
    //       toneMapped={false} 
    //       color={brightColor}
    //     />
    //     </mesh>        
        
    //     <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
    //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials.new_001} position={[0, 0.9, -0.22]} />
    //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.174, 1.04, -0.428]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[0.114, 1.04, -0.237]} rotation={[3.088, -0.03, 0.071]} />
    //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.104, 1.04, -0.498]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.178, 1.04, -0.356]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[-0.033, 1.04, -0.509]} rotation={[1.794, -0.88, -0.687]} />
    //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.037, 1.04, -0.17]} rotation={[0.709, -0.577, -1.352]} />
    //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.079, 1.04, -0.395]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[-0.071, 1.04, -0.403]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.096, 1.04, -0.18]} rotation={[-2.033, -0.277, 0.201]} />
    //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[-0.116, 1.04, -0.286]} rotation={[-2.011, -0.665, 0.574]} />
    //   </group>
    // </group>

  //   <group ref={group} {...props} dispose={null}>
  //   <group name="Scene">
  //     {isMobile ? <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} /> :
  //     <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} position={[0, 1.382, -0.325]}>
  //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
  //     </mesh>          
  //     }
  //     <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
  //     <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
  //     <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
  //     <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
  //     <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
  //     {/* <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} material={materials.light_001} position={[0, 0.883, -0.169]} scale={0.685} /> */}
  //     <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} position={[0, 0.883, -0.169]} scale={0.685}>
  //       <meshBasicMaterial ref = {refCylinder006Mat[0]} 
  //       toneMapped={false} 
  //       color={brightColor}
  //     />
  //     </mesh>
  //     {/* <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} material={materials.light_001} position={[0, 0.879, -0.169]} scale={0.685} /> */}
  //     <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879, -0.169]} scale={0.685}>
  //       <meshBasicMaterial ref = {refCylinder006Mat[1]} 
  //       toneMapped={false} 
  //       color={brightColor}
  //     />
  //     </mesh>
  //     {/* <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} /> */}
  //     <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} position={[0, 0.578, -0.325]}>
  //     <meshBasicMaterial ref = {refCylinder006Mat[2]} 
  //       toneMapped={false} 
  //       color={brightColor}
  //     />
  //     </mesh>
  //     <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
  //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials.new_001} position={[0, 0.9, -0.22]} />
  //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.174, 1.04, -0.428]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[0.114, 1.04, -0.237]} rotation={[3.088, -0.03, 0.071]} />
  //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.104, 1.04, -0.498]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.178, 1.04, -0.356]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[-0.033, 1.04, -0.509]} rotation={[1.794, -0.88, -0.687]} />
  //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.037, 1.04, -0.17]} rotation={[0.709, -0.577, -1.352]} />
  //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.079, 1.04, -0.395]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[-0.071, 1.04, -0.403]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.096, 1.04, -0.18]} rotation={[-2.033, -0.277, 0.201]} />
  //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[-0.116, 1.04, -0.286]} rotation={[-2.011, -0.665, 0.574]} />
  //   </group>
  // </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} />
    //     <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
    //     <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
    //     <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
    //     <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
    //     <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
    //     {/* <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} material={materials.light_001} position={[0, 0.883, -0.169]} scale={0.685} /> */}
    //     <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} position={[0, 0.883, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[0]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
    //     {/* <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} material={materials.light_001} position={[0, 0.879, -0.169]} scale={0.685} /> */}
    //     <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[1]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
    //     {/* <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} /> */}
    //     <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} position={[0, 0.578, -0.325]}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[2]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
    //     <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.779, -0.329]} />
    //     <mesh name="powerball_001" geometry={nodes.powerball_001.geometry} material={materials['002.001']} position={[0, 0.9, -0.22]} />
    //     <mesh name="powerbal_001" geometry={nodes.powerbal_001.geometry} material={materials['001']} position={[-0.089, 1.031, -0.289]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="powerbal_002" geometry={nodes.powerbal_002.geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[Math.PI, 1.092, 2.618]} />
    //     <mesh name="powerbal_003" geometry={nodes.powerbal_003.geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="powerbal_004" geometry={nodes.powerbal_004.geometry} material={materials['004']} position={[0.091, 1.03, -0.362]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="powerbal_005" geometry={nodes.powerbal_005.geometry} material={materials['005']} position={[0.18, 1.03, -0.168]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="powerbal_006" geometry={nodes.powerbal_006.geometry} material={materials['006']} position={[-0.081, 1.03, -0.21]} rotation={[0.272, 0.222, 0.668]} />
    //     <mesh name="powerbal_007" geometry={nodes.powerbal_007.geometry} material={materials['007']} position={[0.067, 1.03, -0.26]} rotation={[0, 0, 0.873]} />
    //     <mesh name="powerbal_008" geometry={nodes.powerbal_008.geometry} material={materials['008']} position={[0.153, 1.03, -0.238]} rotation={[Math.PI / 6, 0, 0]} />
    //     <mesh name="powerbal_009" geometry={nodes.powerbal_009.geometry} material={materials['009']} position={[-0.032, 1.03, -0.466]} rotation={[0.873, 0, 0]} />
    //     <mesh name="powerbal_010" geometry={nodes.powerbal_010.geometry} material={materials['010']} position={[0.087, 1.03, -0.446]} rotation={[0, 0, -Math.PI / 9]} />
    //   </group>
    // </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} />
    //     <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
    //     <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
    //     <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
    //     <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
    //     <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
    //     {/* <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} material={materials.light_002} position={[0, 0.883, -0.169]} scale={0.685} /> */}
    //     <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} position={[0, 0.883, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[0]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
    //     {/* <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} material={materials.light_002} position={[0, 0.879, -0.169]} scale={0.685} /> */}
    //     <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[1]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
    //     {/* <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_002} position={[0, 0.578, -0.325]} /> */}
    //     <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} position={[0, 0.578, -0.325]}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[2]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
    //     <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.779, -0.329]} />
    //     <mesh name="powerball_new_001" geometry={nodes.powerball_new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]}/>
    //     <mesh name="powerbal_001" geometry={nodes.powerbal_001.geometry} material={materials['001']} position={[-0.089, 1.03, -0.289]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="powerbal_002" geometry={nodes.powerbal_002.geometry} material={materials['002']} position={[-0.07, 1.03, -0.392]} rotation={[Math.PI, 1.092, 2.618]} />
    //     <mesh name="powerbal_003" geometry={nodes.powerbal_003.geometry} material={materials['003']} position={[0.026, 1.03, -0.189]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="powerbal_004" geometry={nodes.powerbal_004.geometry} material={materials['004']} position={[0.091, 1.03, -0.362]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="powerbal_005" geometry={nodes.powerbal_005.geometry} material={materials['005']} position={[-0.161, 1.03, -0.244]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="powerbal_006" geometry={nodes.powerbal_006.geometry} material={materials['006']} position={[-0.081, 1.03, -0.21]} rotation={[0.272, 0.222, 0.668]} />
    //     <mesh name="powerbal_007" geometry={nodes.powerbal_007.geometry} material={materials['007']} position={[0.067, 1.03, -0.26]} rotation={[0, 0, 0.873]} />
    //     <mesh name="powerbal_008" geometry={nodes.powerbal_008.geometry} material={materials['008']} position={[0.153, 1.03, -0.238]} rotation={[Math.PI / 6, 0, 0]} />
    //     <mesh name="powerbal_009" geometry={nodes.powerbal_009.geometry} material={materials['009']} position={[-0.032, 1.03, -0.466]} rotation={[0.873, 0, 0]} />
    //     <mesh name="powerbal_010" geometry={nodes.powerbal_010.geometry} material={materials['010']} position={[0.087, 1.03, -0.446]} rotation={[0, 0, -Math.PI / 9]} />
    //   </group>
    // </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     {/* <mesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} material={materials.light_001} position={[0, 0.869, -0.22]} /> */}
    //     <mesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} position={[0, 0.869, -0.22]}>
    //       <meshBasicMaterial ref = {refCylinder006Mat} 
    //         // backside= {true}            
    //         // backsideThickness= {2}
    //         // backsideResolution= {1024}
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
        
        
    //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} rotation={[-0.001, 0.002, -0.001]} />
    //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
    //     <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.Material} position={[0, 0.945, -0.325]} />
    //     {/* <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} /> */}
    //     <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh>          
    //     <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.Material} position={[0, 0.99, -0.325]} rotation={[0, -0.214, 0]} />
    //     <mesh name="Cylinder010" geometry={nodes.Cylinder010.geometry} material={materials.glass_002} position={[0, 0.578, -0.325]} />        
    //     {/* <mesh name="Cylinder010" geometry={nodes.Cylinder010.geometry} position={[0, 0.578, -0.325]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh>           */}
    //     <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.glass_002} position={[0, 0.873, -0.22]} />        
    //     {/* <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} position={[0, 0.873, -0.22]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh>           */}

    //     <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.Material} position={[0, 0.873, -0.22]} />
    //     {/* <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.light_001} position={[0, 0.873, -0.22]} /> */}
    //     <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} position={[0, 0.873, -0.22]}>
    //       <meshBasicMaterial             
    //           toneMapped={false} 
    //           color={testbrightColor}
    //         />
    //     </mesh>
    //     <mesh name="Cylinder007" geometry={nodes.Cylinder007.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} />
    //     {/* <mesh name="Cylinder007" geometry={nodes.Cylinder007.geometry} position={[0, 0.578, -0.325]}>
    //       <meshBasicMaterial
    //           toneMapped={false} 
    //           color={brightColor}
    //         />
    //     </mesh> */}

        
    //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, 2.618]} />
    //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[2.757, 1.108, -3.059]} />
    //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[1.692, 0.975, -2.363]} />
    //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.004, 1.036, -0.111]} rotation={[2.276, -0.138, -1.935]} />
    //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[2.762, 1.153, 2.961]} />
    //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.081, 1.03, -0.174]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.155, 1.028, -0.416]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-2.4, 0.188, 1.938]} />
    //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[2.762, 1.153, 2.961]} />
    //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.037, -0.539]} rotation={[-1.831, 0.46, 2.111]} />
    //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-2.249, 0.647, 2.163]} />
    //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.015, 1.08, -0.407]} rotation={[2.92, 0.818, 2.775]} />
    //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-0.783, 0.91, 1.956]} />
    //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.054, 1.074, -0.506]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.074, 1.093, -0.441]} rotation={[-2.249, 0.647, 2.163]} />
    //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[2.762, 1.153, 2.961]} />
    //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.103, 1.088, -0.233]} rotation={[1.831, 0.46, -2.111]} />
    //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[3.012, 0.312, -1.655]} />
    //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.054, -0.172]} rotation={[2.276, -0.138, -1.935]} />
    //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-2.387, 0.788, 2.254]} />
    //     <mesh name="Shape001002" geometry={nodes.Shape001002.geometry} material={materials.color_stall} position={[-0.096, 1.779, -0.329]} />
    //     <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials.color_stall} position={[0.459, 1.33, -0.076]} rotation={[-0.526, -0.062, -0.104]} />
    //     <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials.color_stall} position={[0.226, 1.053, 0.086]} rotation={[0.721, 0.476, -1.429]} />
    //     <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials.color_stall} position={[0.263, 1.704, -0.285]} rotation={[0.067, -0.261, 1.124]} />
    //     <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials.color_stall} position={[-0.221, 0.962, 0.118]} rotation={[0.691, 0.132, -1.431]} />
    //     <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials.color_stall} position={[-0.096, 1.778, -0.328]} rotation={[0.7, -0.057, -1.656]} />
    //   </group>
    // </group>

// <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <mesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} material={materials.light_001} position={[0, 0.869, -0.22]} />
//         <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} rotation={[-0.001, 0.002, -0.001]} />
//         <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
//         <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.Material} position={[0, 0.945, -0.325]} />
//         {/* <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} /> */}
//         <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
//           {<MeshTransmissionMaterial {...config} toneMapped={true} />}
//         </mesh>  
//         <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.Material} position={[0, 0.99, -0.325]} rotation={[0, -0.214, 0]} />
//         <group name="Cylinder010" position={[0, 0.578, -0.325]}>
//           <mesh name="Cylinder009" geometry={nodes.Cylinder009.geometry} material={materials.glass_002}/>          
//           <mesh name="Cylinder009_1" geometry={nodes.Cylinder009_1.geometry} material={materials.light_001} />
//         </group>
//         <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.glass_002} position={[0, 0.873, -0.22]} />
//         <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.Material} position={[0, 0.873, -0.22]} />
//         <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.light_001} position={[0, 0.873, -0.22]} />        
//         <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, 2.618]} />
//         <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[2.757, 1.108, -3.059]} />
//         <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[1.692, 0.975, -2.363]} />
//         <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.004, 1.036, -0.111]} rotation={[2.276, -0.138, -1.935]} />
//         <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[2.762, 1.153, 2.961]} />
//         <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.081, 1.03, -0.174]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.155, 1.028, -0.416]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-2.4, 0.188, 1.938]} />
//         <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[2.762, 1.153, 2.961]} />
//         <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.037, -0.539]} rotation={[-1.831, 0.46, 2.111]} />
//         <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-2.249, 0.647, 2.163]} />
//         <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.015, 1.08, -0.407]} rotation={[2.92, 0.818, 2.775]} />
//         <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-0.783, 0.91, 1.956]} />
//         <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.054, 1.074, -0.506]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.074, 1.093, -0.441]} rotation={[-2.249, 0.647, 2.163]} />
//         <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[2.762, 1.153, 2.961]} />
//         <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.103, 1.088, -0.233]} rotation={[1.831, 0.46, -2.111]} />
//         <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[3.012, 0.312, -1.655]} />
//         <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.054, -0.172]} rotation={[2.276, -0.138, -1.935]} />
//         <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-2.387, 0.788, 2.254]} />
//         <mesh name="Shape001002" geometry={nodes.Shape001002.geometry} material={materials.color_stall} position={[-0.096, 1.779, -0.329]} />
//         <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials.color_stall} position={[0.459, 1.33, -0.076]} rotation={[-0.526, -0.062, -0.104]} />
//         <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials.color_stall} position={[0.226, 1.053, 0.086]} rotation={[0.721, 0.476, -1.429]} />
//         <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials.color_stall} position={[0.263, 1.704, -0.285]} rotation={[0.067, -0.261, 1.124]} />
//         <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials.color_stall} position={[-0.221, 0.962, 0.118]} rotation={[0.691, 0.132, -1.431]} />
//         <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials.color_stall} position={[-0.096, 1.778, -0.328]} rotation={[0.7, -0.057, -1.656]} />
//       </group>
//     </group>

  // <group ref={group} {...props} dispose={null}>
  //   <group name="Scene">
  //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
  //     <mesh name="powerball_001" geometry={nodes.powerball_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
  //     <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.number} position={[0, 0.945, -0.325]} />      
  //     <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
  //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
  //     </mesh>       
  //     <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.number} position={[0, 0.99, -0.325]} />
  //     <group name="Cylinder010" position={[0, 0.578, -0.325]}>
        
  //       {/* <mesh name="Cylinder009" geometry={nodes.Cylinder009.geometry} material={materials.glass} /> */}
  //       <mesh name="Cylinder009" castShadow receiveShadow geometry={nodes.Cylinder009.geometry}>
  //         <meshBasicMaterial             
  //           toneMapped={false} 
  //           color={[2,1,1]}
  //         />
  //       </mesh>

  //       {/* <mesh name="Cylinder009_1" geometry={nodes.Cylinder009_1.geometry} material={materials.light_001} /> */}
  //       <mesh name="Cylinder009_1" castShadow receiveShadow geometry={nodes.Cylinder009_1.geometry} >
  //       <meshBasicMaterial             
  //           toneMapped={false} 
  //           color={[2,1,1]}
  //         />          
  //       </mesh>
  //     </group>
  //     <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.glass} position={[0, 0.879, -0.22]} scale={1.3} />
  //     <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.number} position={[0, 0.879, -0.22]} scale={1.3} />
  //     <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.light} position={[0, 0.879, -0.22]} scale={1.3} />
  //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, 2.618]} />
  //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[2.757, 1.108, -3.059]} />
  //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[1.692, 0.975, -2.363]} />
  //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.004, 1.036, -0.111]} rotation={[2.276, -0.138, -1.935]} />
  //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[2.762, 1.153, 2.961]} />
  //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.081, 1.03, -0.174]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.155, 1.028, -0.416]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-2.4, 0.188, 1.938]} />
  //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[2.762, 1.153, 2.961]} />
  //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.037, -0.539]} rotation={[-1.831, 0.46, 2.111]} />
  //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-2.249, 0.647, 2.163]} />
  //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.015, 1.08, -0.407]} rotation={[2.92, 0.818, 2.775]} />
  //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-0.783, 0.91, 1.956]} />
  //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.054, 1.074, -0.506]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.074, 1.093, -0.441]} rotation={[-2.249, 0.647, 2.163]} />
  //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[2.762, 1.153, 2.961]} />
  //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.103, 1.088, -0.233]} rotation={[1.831, 0.46, -2.111]} />
  //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[3.012, 0.312, -1.655]} />
  //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.054, -0.172]} rotation={[2.276, -0.138, -1.935]} />
  //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-2.387, 0.788, 2.254]} />
  //     <mesh name="Shape001002" geometry={nodes.Shape001002.geometry} material={materials.color} position={[-0.096, 1.779, -0.329]} />
  //     <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials.color} position={[0.459, 1.33, -0.076]} rotation={[-0.526, -0.062, -0.104]} />
  //     <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials.color} position={[0.226, 1.053, 0.086]} rotation={[0.721, 0.476, -1.429]} />
  //     <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials.color} position={[0.263, 1.694, -0.285]} rotation={[0.067, -0.261, 1.124]} />
  //     <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials.color} position={[-0.221, 0.962, 0.118]} rotation={[0.691, 0.132, -1.431]} />
  //     <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials.color} position={[-0.096, 1.778, -0.328]} rotation={[0.7, -0.057, -1.656]} />
  //   </group>
  // </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
    //     <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.number} position={[0, 0.945, -0.325]} />        
    //     <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh> 

    //     <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.number} position={[0, 0.99, -0.325]} rotation={[0, -0.185, 0]} />
    //     <mesh name="Cube" geometry={nodes.Cube.geometry} material={materials.number} position={[0, 0.45, -0.22]} />
    //     <mesh name="Cylinder010" geometry={nodes.Cylinder010.geometry} material={materials.number} position={[0, 0.578, -0.325]} />
    //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.011, 1.043, -0.11]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.084, 1.049, -0.174]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.15, 1.054, -0.409]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.043, -0.539]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.018, 1.08, -0.419]} rotation={[-Math.PI, 0.743, -Math.PI]} />
    //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.06, 1.064, -0.523]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.071, 1.095, -0.437]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.116, 1.091, -0.25]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.061, -0.172]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="Torus001" geometry={nodes.Torus001.geometry} material={materials['glass.001']} position={[-0.099, 1.766, -0.329]} rotation={[Math.PI / 3, 0, 0]} scale={1.094} />
    //   </group>
    // </group>
  )
}


// useGLTF.preload('/powerball/lottomachine_red.glb')
