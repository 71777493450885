import styles from './cta.module.css';
import MakeABet from '@/img/landingPage/mobile/horseBet.png';
import MakeRent from '@/img/landingPage/mobile/horseRent.png';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import useLang from '@/lib/hook/useLang';
import { useLocation } from 'react-router-dom';
import { PlayGame, GetDefaultGoods } from '../../../../horsestall/progresscombine/jsUtil_combine';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';
import * as define from '../../../../define'
const CtaSection = () => {
  const { lngT } = useLang('landingPage:guideSection');
  const { search } = useLocation();
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  return (
    <section className={styles.ctaContainer}>
      <button onClick={() => 
        PlayGame(navigate, user?.name, GetDefaultGoods(balance?.data))
        } className={styles.buttonBet}>
        <div className="text-start">
          <h3 className={styles.title}>{lngT('guideBet:guide:betTitle')}</h3>
          <p className={styles.subtitle}>{lngT('guideBet:guide:betSubTitle')}</p>
        </div>
        <img src={MakeABet} alt="Make a bet horse" width={100} className={styles.horseButtonMakeABet} />
      </button>
      <a href={define.SHOW_METACROSS_ONLY ? `/horse/stake${search}` : ''} className={styles.buttonRent}>
        <div className="text-start">
          <h3 className={styles.title}>{lngT('guideRent:betTitle')}</h3>
          <p className={styles.subtitle}>{lngT('guideRent:betSubTitle')}</p>
        </div>
        <img src={MakeRent} alt="Make a lease horse" width={160} className={styles.horseButtonMakeARent} />
      </a>
    </section>
  );
};

export default CtaSection;
