import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import styles from './betListCard.module.css';

import useLang from '@/lib/hook/useLang';
import { PlayGame, GetDefaultGoods } from '../../../../../../horsestall/progresscombine/jsUtil_combine';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';

const BetListCard = () => {
  const { lngT } = useLang('landingPage:guideSection');
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);
  
  return (
    <div className={styles.boxDescription}>
      <span className={styles.line} />
      <div className={styles.boxContainer}>
        <div className={styles.box}>
          <span className={styles.indicator}>01</span>
          <h6>{lngT('guideBet:guide:1:title')}</h6>
          <p>
            {lngT('guideBet:guide:1:description')}
            <a className="cursor-pointer" onClick={() => 
              PlayGame(navigate, user?.name, GetDefaultGoods(balance?.data))
              }>
              {lngT('guideBet:guide:1:a')}
            </a>
          </p>
        </div>
        <div className={styles.box}>
          <span className={styles.indicator}>02</span>
          <h6> {lngT('guideBet:guide:2:title')}</h6>
          <p>{lngT('guideBet:guide:2:description')}</p>
        </div>
        <div className={styles.box}>
          <span className={styles.indicator}>03</span>
          <strong>
            {lngT('guideBet:guide:3:mobile1')} <h6> {lngT('guideBet:guide:3:mobile2')} </h6>
          </strong>
          <p>
            <b>{lngT('guideBet:guide:3:strong')}</b> {lngT('guideBet:guide:3:description')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BetListCard;
