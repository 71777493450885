import React, { useState } from 'react';
import { HistoryBallItem } from './BettingHistoryTable';

type Props = HistoryBallItem & {
  round: number;
};

export default function BettingHistoryBall({ color, content, round }: Props) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className="box px-1 py-[5px] bg-[#3F2254] cell border border-gray-200">
      <div
        onMouseEnter={() => round !== 0 && setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        className={`relative rounded-full w-[40px] aspect-square flex justify-center items-center ${color} `}
      >
        {content}
        {!content && <div className="empty-cell w-[40px] h-[40px] aspect-square"> </div>}
        {isTooltipVisible && (
          <div className="absolute z-10 -top-1 left-1/2 -translate-x-1/2 bg-white/70 text-gray-800 px-2 rounded-md whitespace-nowrap text-xs flex flex-col justify-center items-center">
            <p>Round</p>
            <p>{round}</p>
          </div>
        )}
      </div>
    </div>
  );
}
