import { Link } from "react-router-dom";
import tw from "tailwind-styled-components";

const Container = tw.div`
  space-x-[5px]
  text-[12px]
`;

const Text = tw.span`
  text-[#4D4D4D] font-light
`;

const HrefText = tw.span`
  font-semibold text-[#0C21C1]
  hover:opacity-80
  underline
`;

export default function TextNavLinker({ text, href, hrefText, className }) {
  return (
    <Container className={className}>
      <Text>{text}</Text>
      <Link to={href}>
        <HrefText>{hrefText}</HrefText>
      </Link>
    </Container>
  );
}
