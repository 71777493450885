import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SWRConfig } from 'swr';
import { Provider } from 'react-redux';

import { fetcher } from './lib/utils';
import { sideNavMenus } from './lib/menus';
import Pixel from './lib/pixel/Pixel';
import { store } from './redux/store';

import { RandomBetting, BettingTabsWithStyle, Game, GameResult } from './pages/games';
import { Login, Signup, ForgotPassword, ResetPassword } from './pages/auth';
import { Account } from './pages/mypage';
import { FAQ, Question, QuestionDetail, QuestionWrite } from './pages/contact';
import { FreeBoard, FreeBoardDetail, FreeBoardWrite, NoticeDetail } from './pages/community';
import { Stake, Info } from './pages/horse';
import { Privacy, TermOfUse, Video } from './pages/common';
import RacingLanding from './pages/landing/LandingPage';
import PowerballLanding from './pages/powerball/landing/LandingPage';

import { SideNavLayout, AuthLayout, RootLayout, GameLayout } from '@/components/layout';

import LobbyGame from './horsestall/progresscombine/LobbyGame';
import GameOnepage from './horsestall/progresscombine/GameOnepage';
import History from './horsestall/progresscombine/History';
import { SetSafeLog } from './horsestall/progresscombine/jsUtil_combine';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import PowerBallLottery from './pages/powerball/PowerballLottotery/PowerBallLottery';
import LobbyPowerBall from './pages/powerball/LobbyPowerBall';
import PowerBallBetting from './pages/powerball/PowerballBetting/PowerBallBetting';
import { ResetPincodeView } from './page/pincode/view';
import { EventDetailView, EventListView } from './page/event/view';

import { PdfViewer } from './util/pdfviewer';

import { PointHistoryView, BettingHistoryView, UserInfoView } from '@/page/mypage/view';
import { ListPagerProvider } from '@/lib/custom-lib/ListPager';
import * as config from './config';
import ReelGame from './pages/reelgame/ReelGame';

const root = ReactDOM.createRoot(document.getElementById('root')!);

const isRacingService = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING);

export default function App() {
  InitExtendMethod();
  return (
    <Pixel>
      <Provider store={store}>
        <BrowserRouter>
          <SWRConfig
            value={{
              refreshInterval: 3000,
              fetcher,
            }}
          >
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID!}>
              <Routes>
                <Route path="/" element={<RootLayout />}>
                  <Route index element={isRacingService ? <RacingLanding /> : <PowerballLanding />} />
                  <Route
                    path="/privacy"
                    element={<PdfViewer pdfPath={`./pdfs/${isRacingService ? 'upodium' : 'up7bet'}/privacy.pdf`} />}
                  />
                  <Route
                    path="/terms"
                    element={<PdfViewer pdfPath={`./pdfs/${isRacingService ? 'upodium' : 'up7bet'}/liscence.pdf`} />}
                  />
                  <Route path="/video" element={<Video />} />

                  <Route path="/" element={<AuthLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="password">
                      <Route path="forgot" element={<ForgotPassword />} />
                      <Route path="reset" element={<ResetPassword />} />
                    </Route>
                  </Route>

                  <Route path="/pincode">
                    <Route path="reset" element={<ResetPincodeView />} />
                  </Route>

                  <Route path="/mypage" element={<SideNavLayout title="myPage" menus={sideNavMenus.mypage} />}>
                    <Route path="userinfo" element={<UserInfoView />} />
                    <Route
                      path="pointhistory"
                      element={
                        <ListPagerProvider>
                          <PointHistoryView />
                        </ListPagerProvider>
                      }
                    />
                    <Route
                      path="bettinghistory"
                      element={
                        <ListPagerProvider>
                          <BettingHistoryView />
                        </ListPagerProvider>
                      }
                    />
                    <Route path="account" element={<Account />} />
                  </Route>

                  <Route path="/horse" element={<SideNavLayout title="horse" menus={sideNavMenus.horse} />}>
                    <Route path="info" element={<Info />} />
                    <Route path="stake" element={<Stake />} />
                  </Route>

                  <Route path="/contact" element={<SideNavLayout title="contact" menus={sideNavMenus.contact} />}>
                    <Route path="faq" element={<FAQ />} />
                    <Route path="question">
                      <Route index element={<Question />} />
                      <Route path="write" element={<QuestionWrite />} />
                      <Route path=":questionId" element={<QuestionDetail />} />
                    </Route>
                  </Route>

                  <Route path="/community" element={<SideNavLayout title="community" menus={sideNavMenus.community} />}>
                    <Route path="freeboard">
                      <Route index element={<FreeBoard />} />
                      {/* <Route path="write" element={<FreeBoardWrite />} /> */}
                      <Route path=":freeboardId" element={<FreeBoardDetail />} />
                      <Route path="notice">
                        <Route path=":noticeId" element={<NoticeDetail />} />
                      </Route>
                    </Route>
                  </Route>

                  <Route path="/event" element={<SideNavLayout title="event" menus={sideNavMenus.event} />}>
                    <Route index element={<EventListView />} />
                    <Route path=":eventId" element={<EventDetailView />} />
                  </Route>

                  <Route element={<GameLayout />}>
                    {/* <Route path="/timetablewithstyle" element={<TimetableWithStyle />} />
                    <Route path="/introbet" element={<IntroBet />} /> */}
                    <Route path="/randombetting" element={<RandomBetting />} />
                    <Route path="/BettingTabsWithStyle" element={<BettingTabsWithStyle />} />
                    <Route path="/game" element={<Game />} />
                    {/* <Route path="/gameresultwithstyle" element={<GameResultWithStyle />} /> */}

                    <Route path="/gameresult" element={<GameResult />} />
                    {/* <Route path="/timetable" element={<TimeTable />} /> */}
                    <Route path="/history" element={<History />} />
                    <Route path="/gameonepage" element={<GameOnepage />} />
                    {/* <Route path="/BetGameResult" element={<BetGameResult />} /> */}
                    <Route path="/lobbygame" element={<LobbyGame />} />
                    {/*<Route path="/reelgame" element={<ReelGame02 />} />*/}
                    <Route path="/reelgame" element={<ReelGame isRacingService={isRacingService} />} />
                  </Route>

                  <Route path="/powerball" element={<GameLayout />}>
                    <Route path="lottery" element={<PowerBallLottery />} />
                    <Route path="betting" element={<PowerBallBetting />} />
                    <Route path="lobbyy" element={<LobbyPowerBall />} />
                  </Route>
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </GoogleOAuthProvider>
          </SWRConfig>
        </BrowserRouter>
      </Provider>
    </Pixel>
  );
}

const InitExtendMethod = () => {
  console.log('code version - 02/02-16:45');
  SetSafeLog();
};

root.render(<App />);
