import { useEffect, useState } from 'react';

type FilterItem = {
  value: string;
  isSelected: boolean;
};

const useSelector = (filterList: string[]) => {
  const [selectorItems, setSelectorItems] = useState<FilterItem[]>(
    filterList.map((value, i) => ({
      value,
      isSelected: !i,
    }))
  );

  const selectItem = (value: string) => {
    setSelectorItems(filterItems => filterItems.map(item => ({ ...item, isSelected: item.value === value })));
  };

  useEffect(() => {
    setSelectorItems(
      filterList.map((value, i) => ({
        value,
        isSelected: !i,
      }))
    );
  }, []);

  return {
    selectorItems,
    selectedValue: selectorItems.find(item => item.isSelected)?.value ?? '',
    selectItem,
  };
};

export default useSelector;
