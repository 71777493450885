import { useEffect, useState } from 'react';
// import { getCookie } from '../../lib/cookie';
import { useNavigate } from 'react-router-dom';
// import { GetAddHttpURL, SafeLog, ChangeProgress } from '../../util/jsUtil';

import { ChangeProgress_combine } from '../../horsestall/progresscombine/jsUtil_combine';
import * as config from '../../config';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang'; //'../lib/hook/useLang';
import { useDispatch, useSelector } from 'react-redux';
// import { useSelector } from '@/lib/hook';
import i18n from '../../lang/i18n';
import * as define from '../../define';
import { GoodsType } from '../../define';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { Goods, SetCoinType, GetCoinType, ToggleCoinType } from '../../components/gameui/goods';
import { Point } from '@/components/gameui/point';
import { Bonus } from '@/components/gameui/bonus';
import PowerballBar from '../../img/powerball/select_bar.png';
import { toast } from 'react-toastify';
// import { cssTransition } from 'react-toastify';
import { CheckMaintenence } from '../../util/maintenenceUtil';

let intervalID;
export default function LobbyGame() {
  const { lngT } = useLang('racing:intro');
  const { lngT: lngTetc } = useLang('etcs');
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const [rows, setRows] = useState([]);
  const [dataid, setdataid] = useState();
  const [nowProgress, setNowProgress] = useState();
  const [message, setMessage] = useState('');
  const [goodsType, setgoodsType] = useState();

  const lobbyMenus = [
    {
      index: 0,
      title: `${/*'Game History'*/ lngT('lobby:history')}`,
      status: '',
      button: `${/*'REPLAY'*/ lngT('lobby:button:replay')}`,
      color: '[#1a0234]',
      textColor: 'white',
      img: '../../../images/GameOnePage/history.png',
    },
    {
      index: 1,
      title: `${/*'Game Start'*/ lngT('lobby:start')}`,
      status: 'Betting now',
      button: `${/*'Game Start'*/ lngT('lobby:button:start')}`,
      color: '[#7622d3]',
      textColor: '[#3326B5]',
      img: '../../../images/GameOnePage/gameStart.png',
    },
  ];

  useEffect(() => {
    SetCoinType(dispatch, setgoodsType);
    CheckMaintenence(lngTetc);
    intervalID = setInterval(() => {
      CheckMaintenence(lngTetc);
    }, 60 * 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    if (!user) navigate('/login');
  }, [user]);

  const handleToggleChange = () => {
    let tooglegoodsType = ToggleCoinType(dispatch, goodsType);
    setgoodsType(tooglegoodsType);
  };

  useEffect(() => {
    if (!define.USE_FREECOIN) return;
    if (goodsType === undefined) return;

    console.log('goodsType : ' + goodsType);

    const goodsMsg =
      goodsType === GoodsType.CASHCOIN ? lngTetc('common:pointgamemode') : lngTetc('common:freegamemode');
    toast.dismiss();
    // toast.clearWaitingQueue();
    // const toastTransition = cssTransition({
    //   enter: 'zoomIn',
    //   exit: 'zoomOut',
    //   appendPosition: true,
    //   collapse: false,
    //   collapseDuration: 0
    // });

    toast(goodsMsg, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: 'dark',
      type: 'info',
      // transition:toastTransition,
      onOpen: (a, b, c) => {},
    });

    // const timer = setTimeout(() => {
    //   setMessage('');
    // }, 1500);
    // return () => clearTimeout(timer);
  }, [goodsType]);

  const handleClickHistory = () => {
    navigate('/history');
  };
  const GetProgress = async useChangeProgressCombine => {
    try {
      const url = `${config.GetPrefixURL()}${config.GetBetServerURL()}/api/v1/raceprogress`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log('raceProgress data' + data, config.LOG_DEVELOPER_1);
      setdataid(data.id);
      setNowProgress(data.Progress);

      if (useChangeProgressCombine) {
        CheckMaintenence(lngTetc, () => {});
        ChangeProgress_combine(data.ID, user?.name, data.Progress, i18n.language, false, goodsType);
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const clickAction = () => {
    GetProgress(true);
  };

  useEffect(() => {
    GetProgress(false);
  }, []);

  return (
    <div className="max-T:portrait:h-[900px] max-T:landscape:h-[400px] h-full w-full relative flex flex-row MD:items-center MD:justify-center items-start justify-center ">
      <div className="max-h-screen absolute flex flex-col justify-center items-center D:space-y-[120px] space-y-10 landscape:top-0 T:mt-[120px] max-T:landscape:mt-0 max-T:portrait:mt-[100px] max-[360px]:portrait:mt-[40px] max-T:portrait:scale-[0.8]">
        <div className="flex items-center justify-center w-96 max-[360px]:w-60 h-16 max-[360px]:h-13 bg-gradient-to-r from-white/0 via-white to-white/0 px-10 py-1 relative">
          <h2 className="text-4xl max-[360px]:text-2xl text-center font-orbitron font-bold">
            {/*'racing'*/ lngT('lobby:title')}
          </h2>
        </div>
        {/* {message} */}
        <div className="grid T:grid-cols-2 max-T:grid-rows-1 max-D:landscape:grid-cols-2 gap-10  D:space-x-10 ">
          {lobbyMenus.map(menu => {
            return (
              <GameLobbyButton
                key={menu.index}
                menu={menu}
                handleClickHistory={() => handleClickHistory()}
                clickAction={() => clickAction()}
                id={dataid}
                progress={nowProgress}
                goodsType={goodsType}
                ToggleCoinType={ToggleCoinType}
                dispatch={dispatch}
                handleToggleChange={handleToggleChange}
                message={message}
              />
            );
          })}
        </div>
        {/* {message} */}
      </div>
    </div>
  );
}

const GameLobbyButton = ({
  menu,
  clickAction,
  handleClickHistory,
  id,
  progress,
  goodsType,
  dispatch,
  ToggleCoinType,
  handleToggleChange,
  message,
}) => {
  const { lngT } = useLang('racing:intro');
  const { lngT: lngTEtc } = useLang('mypage:gameHistory');
  const onClickHandler =
    menu.index === 0
      ? () => handleClickHistory()
      : progress === 0
      ? () => {
          const isConfirmed = window.confirm(
            'The game is preparing and will restart in a few minutes. Please wait momentarily. '
          );
        }
      : () => clickAction(id);
  const progressNow =
    progress === define.PROGRESS_MAINLOBBY
      ? `${/*'Preparing Now..'*/ lngT('lobby:preparing')}`
      : progress === define.PROGRESS_INTRO1
      ? `${/*'Betting now ...'*/ lngT('lobby:betting')}`
      : progress === define.PROGRESS_INTRO2
      ? `${/*'Ready now ...'*/ lngT('lobby:ready')}`
      : progress === define.PROGRESS_LIVE
      ? `${/*'Game now ...'*/ lngT('lobby:game')}`
      : progress === define.PROGRESS_REPLAY
      ? `${/*'No Play now ...'*/ lngT('lobby:noplay')}`
      : '';
  const statusNow = menu.index === 0 ? '' : menu.index === 1 ? progressNow : null;

  return (
    <div className="relative">
      <div
        onClick={onClickHandler}
        className={`peer relative flex flex-col items-center max-[380px]:scale-80 max-[380px]:after:hover:scale-105 scale-100 w-[337px] h-[440px] max-[360px]:w-[280px] max-[360px]:h-[367px] rounded-2xl space-y-[60px] cursor-pointer  ${
          menu.index === 1 && goodsType === GoodsType.CASHCOIN
            ? 'bg-yellow-800/50'
            : menu.index === 1 && goodsType === GoodsType.FREECOIN
            ? 'bg-violet-900/80'
            : 'bg-[#1a0234]'
        }
 a after:w-[337px] after:h-[440px] max-[360px]:after:w-[280px] max-[360px]:after:h-[367px] after:absolute after:top-0 after:-left-[1.5px] after:border-[2px] after:hover:border-[3px] after:rounded-2xl after:hover:scale-105 after:duration-300 after:ease-in-out hover:scale-105 ${
   menu.index === 0
     ? 'after:border-[#a991e6] after:hover:border-[#a991e6] hover:bg-[#a991e6]/20 '
     : menu.index === 1
     ? 'after:border-[#7622d3] after:hover:border-[#7622d3] hover:bg-[#241d51] max-T:portrait:order-first'
     : ''
 } `}
      >
        <div className="h-[320px] max-[360px]:h-[240px] flex flex-col items-center justify-between mt-[80px] rounded-2xl">
          <div className="pb-6 space-y-10 flex flex-col items-center">
            <h2
              style={{ 'font-family': 'Microsoft Yahei' }}
              className="text-white text-5xl w-[260px] text-center font-Microsoft Yahei font-bold max-[360px]:text-3xl"
            >
              {menu.title}
            </h2>

            <p className="text-white text-2xl italic opacity-90 font-light max-[360px]:text-lg">{statusNow}</p>
          </div>
          <button
            className={`'cursor-pointer w-[298px] h-[48px] max-[360px]:w-[200px] max-[360px]:h-[40px] rounded-xl max-[360px]:rounded-lg  text-xl tracking-wider font-orbitron font-medium'${
              menu.index === 0 ? ' bg-[#a991e6] text-white' : menu.index === 1 ? ' bg-[#7622d3] text-white ' : ''
            } `}
            onClick={onClickHandler}
          >
            {menu.button}
          </button>
          <img
            alt=""
            className="absolute top-0 -z-30 w-full object-cover opacity-40 grayscale overflow-hidden rounded-2xl"
            src={`${
              menu.index === 0
                ? '../../../images/gameOnePage/history.png'
                : menu.index === 1
                ? '../../../images/gameOnePage/gameStart.png'
                : ''
            }`}
          ></img>
        </div>
      </div>

      {menu.index === 1 && message}
      {menu.index === 1 && define.SHOW_METACROSS_ONLY && (
        <CoinToggle
          goodsType={goodsType}
          handleToggleChange={() => {
            handleToggleChange();
          }}
        ></CoinToggle>
      )}
    </div>
  );
};

function CoinToggle(props) {
  const { lngT: lngTGameHistory } = useLang('mypage:gameHistory');
  return (
    <div>
      {define.USE_FREECOIN && (
        <div className="absolute left-1/2 -translate-x-1/2 top-1 translate-y-[10px] w-[180px] h-12 z-10 -bottom-10 right-7 flex flex-row items-center justify-between">
          {/* <div className="w-[180px] left-[75px] h-12 absolute z-[30] top-3 right-4  flex flex-row items-center justify-end space-x-2"> */}
          <p className="absolute -left-[40px] text-white text-base">{lngTGameHistory('table:points')}</p>
          <Point size={10} toggleType={true} />
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={props.goodsType === GoodsType.FREECOIN}
              className="sr-only peer"
              onChange={props.handleToggleChange}
            />
            <div className="w-[90px] h-10  ring-2 ring-purple-500 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-purple-500 dark:peer-focus:ring-purple-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-[160%] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-purple-500 after:content-[''] after:absolute after:top-[4px] after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-8 after:w-8 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-600"></div>
          </label>
          <Bonus size={10} />
          <p className="absolute translate-x-48 text-white text-xl">{lngTGameHistory('table:freePoints')}</p>
        </div>
      )}
    </div>
  );
}
