import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
//import Modal from 'react-modal';

//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
//import MenuItem from '@mui/material/MenuItem';
//import FormControl from '@mui/material/FormControl';
//import Select from '@mui/material/Select';
//import {Box, Typography } from '@mui/material';

import useLang from '../../lib/hook/useLang';

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  Button,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

import { useTableCaptionComponentStyles } from '../styles/TableCaptionComponentStyles';
import { useOddEvenStyles } from '../styles/OddEvenStyles';
import { useResultBoxComponentStyles } from '../styles/ResultBoxComponentStyles';
import { useStylesVersusFigure } from '../styles/VersusFigureComponentStyles';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useStylesNotify } from '../styles/NotifyComponentStyles';

var { webSocketIO } = require('../../socketClient.js');

const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 8,
    },
  },
  MenuListProps: {
    disablePadding: true,
  },
};

function VersusFigureComponent(props) {
  const classes = useStylesVersusFigure();

  return (
    <Box className={classes.figureBox} mt={5}>
      <span className={classes.oddsText}></span>
      <Box className={classes.oddTextStyle}>
        <Box className={clsx(classes.barWrap, classes.oddBarWrap)}>
          <span
            className={clsx(classes.bar, classes.oddBar)}
            style={{
              width: `${props.oddrate}%`,
            }}
          ></span>
        </Box>
        <span>ODD</span>
        <Typography className={clsx(classes.persentText, classes.oddPersentText)}>{props.oddrate}%</Typography>
      </Box>
      <Box className={classes.vsCenter}>VS</Box>
      <Box className={classes.evenTextStyle}>
        <Box className={clsx(classes.barWrap, classes.evenBarWrap)}>
          <span
            className={clsx(classes.bar, classes.evenBar)}
            style={{
              width: `${props.evenrate}%`,
            }}
          ></span>
        </Box>
        <span>EVEN</span>
        <Typography className={clsx(classes.persentText, classes.evenPersentText)}>{props.evenrate}%</Typography>
      </Box>
    </Box>
  );
}

function BettingSelect(props) {
  const { lngT } = useLang('racing:intro');
  const classesOddEvenStyles = useOddEvenStyles();
  //const [betting, setBetting] = React.useState('');

  const handleChange = event => {
    //webSocketIO.send(JSON.stringify({
    //  protocol: 'reqBetting',
    //  type: props.type,
    //  no: props.index,
    //  value: event.target.value
    //}));

    props.handleSetModal(
      { showModal: true, comfirmModal: false },
      {
        type: props.type,
        index: props.index,
        value: event.target.value,
        currency: props.currency,
      }
    );
  };

  return (
    <FormControl className={classesOddEvenStyles.formControl}>
      <InputLabel
        variant="standard"
        className={props.classInputLabel}
        style={{
          padding: '0 8px',
        }}
        // className={classes.inputLabel}
      >
        {/*'betting'*/ lngT('contents:betting')}
      </InputLabel>
      <Select
        //value={age}
        //onChange={handleChange}
        displayEmpty
        MenuProps={MenuProps}
        className={classesOddEvenStyles.selectBox}
        //labelId="demo-simple-select-label"
        //id="demo-simple-select"
        //value={props.betting[props.index]}
        value={webSocketIO.data.listRow[props.type][props.index].betting}
        label="Betting"
        onChange={handleChange}
        //disabled={webSocketIO.data.isCounterOpen === true ? false : true} //test
        //{webSocketIO.data.isCounterOpen === true : '' : disabled}
      >
        {webSocketIO.data.listBettingValue.map((value, index) => (
          <MenuItem value={value} className={classesOddEvenStyles.menuItem}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function NotifyComponent(props) {
  const classes = useStylesNotify();

  return (
    <Box
      className={classes.notify}
      style={{
        marginTop: 10,
      }}
    >
      <Typography variant={'body2'}>
        <ErrorOutlineRoundedIcon />
        {props.title}
      </Typography>
      <Typography variant={'caption'} display={'block'}>
        {props.content}
      </Typography>
    </Box>
  );
}

const BettingWithStyle = forwardRef((props, ref) => {
  const { lngT } = useLang('racing:intro');

  const classesTableCaptionComponent = useTableCaptionComponentStyles();
  const classesResultBoxComponent = useResultBoxComponentStyles();

  const classesOddEvenStyles = useOddEvenStyles();

  const [, updateState] = React.useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);

  useImperativeHandle(ref, () => ({
    forceUpdate,
  }));

  const oddEvenTitle = lngT('footer:guide:oddEven:title'); //`Odd and even betting guide`;
  const oddEvenContent = lngT('footer:guide:oddEven:description'); //`It is a way to hit even odd numbers of 1 horse that will arrive as 1st place.`;
  const underOverTitle = lngT('footer:guide:underOver:title'); //`Under and Over betting guide`;
  const underOverContent = lngT('footer:guide:underOver:description'); //`Under is a bet on a number of 6 or less, and Over is a bet on a number of 7 or greater.`;
  const oddUnderTitle = lngT('footer:guide:oddUnder:title'); //`Odd even and Even over betting guide`;
  const oddUnderContent = lngT('footer:guide:oddUnder:description'); //`Odd under bets on 1, 3, 5, odd over on 7, 9, 11, even under on 2, 4, 6, even over on 8, 10, 12.`;
  const oddLowTitle = lngT('footer:guide:oddSmall:title'); //`Odd small and Even large betting guide`;
  const oddHighContent = lngT('footer:guide:oddSmall:description'); //`Odd small bets on 1, 3, odd medium on 5, 7, odd large on 9, 11, even small on 2, 4, even medium on 6, 8, even large on 10, 12.`;

  //const oddEvenTitle = `Odd and even betting guide`;
  //const oddEvenContent =
  //`It is a way to hit even odd numbers of 1 horse that will arrive as 1st place.\n Under is a bet on a number of 6 or less, and Over is a bet on a number of 7 or greater.\n Odd under bets on 1, 3, 5, odd over on 7, 9, 11, even under on 2, 4, 6, even over on 8, 10, 12.\n Odd low bets on 1 and 3, odd middle on 5 and 7, odd high on 9 and 11, even low on 2 and 4, even middle on 6 and 8, even high on 10 and 12.`;
  const singleTitle = lngT('footer:guide:single:title'); //`Single win betting guide`;
  const singleContent = lngT('footer:guide:single:description'); //`It's a way to hit 1 horse that will arrive in 1st place.`;
  const showTitle = lngT('footer:guide:show:title'); //`Show win betting guide`;
  const showContent = lngT('footer:guide:show:description'); //`It is a way to hit 1 horse that will come in 1st to 3rd place.`;

  const listLine = [
    lngT('contents:odd'), //'odd',
    lngT('contents:even'), //'even',
    lngT('contents:under'), //'under',
    lngT('contents:over'), //'over',
    lngT('contents:oddUnder'), //'odd under',
    lngT('contents:oddOver'), //'odd over',
    lngT('contents:evenUnder'), //'even under',
    lngT('contents:evenOver'), //'even over',
    lngT('contents:oddSmall'), //'odd small',
    lngT('contents:oddMedium'), //'odd medium',
    lngT('contents:oddLarge'), //'odd large',
    lngT('contents:evenSmall'), //'even small',
    lngT('contents:evenMedium'), //'even medium',
    lngT('contents:evenLarge'), //'even large',
  ]; //['odd', 'even'];//

  webSocketIO.remaintime = webSocketIO.data.counter;
  // console.log('Set IntroRemainSeconds : ' + webSocketIO.data.counter);

  if (props.type !== props.value) {
    return <></>;
  } else {
    return (
      <Box className={classesOddEvenStyles.root}>
        <Box className={classesTableCaptionComponent.root}>
          {webSocketIO.data.isCounterOpen && webSocketIO.data.counter === 0 ? (
            ''
          ) : (
            <Typography variant="h3" color={'success'} className={classesTableCaptionComponent.time}>
              {/*'time'*/ lngT('contents:time')}
              {/*<img src="/images/icons/clock_1.svg" />*/}:{webSocketIO.data.counter /*00:01*/}
            </Typography>
          )}
          {/* <Typography className={classesTableCaptionComponent.round}><span>{webSocketIO.data.game}</span> Round</Typography> */}
        </Box>

        {props.type === 0 ? (
          <>
            <Table className={classesOddEvenStyles.table}>
              <TableHead className={classesOddEvenStyles.thStyle}>
                <TableRow className={classesOddEvenStyles.trStyle}>
                  <TableCell align="center" className={classesOddEvenStyles.bgRed}>
                    {/*'Line'*/ lngT('header:line')}
                  </TableCell>
                  <TableCell align="center" className={classesOddEvenStyles.bgYellow}>
                    {/*'Point'*/ lngT('header:coin')}
                  </TableCell>
                  {/*<TableCell align="center" className={classesOddEvenStyles.bgBlue}>Meta.X</TableCell>*/}
                  <TableCell align="center" className={classesOddEvenStyles.bgPurple}>
                    {/*'Odds'*/ lngT('header:odds')}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classesOddEvenStyles.tbStyle}>
                {
                  //rows.map((row, index) => (
                  webSocketIO.data.listRow[props.type].map((row, index) =>
                    index < 2 ? (
                      <TableRow
                        key={index}
                        className={
                          //props.type === 0 ? (
                          //  //row.line === 'EVEN' ?
                          //  index+1 === 2 ?
                          //  clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.bottomLienStyle) :
                          //  classesOddEvenStyles.trStyle
                          //) : (
                          //  //row.line === 'EVEN' ?
                          //  //index+1 === 2 ?
                          //  //clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.bottomLienStyle) :
                          //  classesOddEvenStyles.trStyle
                          //)
                          classesOddEvenStyles.trStyle
                          //props.type === 0 && (index === 1 || index === 3 || index === 7)
                          //  ? clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.bottomLienStyle)
                          //  : classesOddEvenStyles.trStyle
                        }
                      >
                        <TableCell align="center" className={classesOddEvenStyles.tdRed}>
                          <Box>
                            {props.type === 0
                              ? //index+1 === 1 ? 'odd' : 'even'
                                listLine[index]
                              : index + 1}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdYellow}>
                            {
                              row.betting === 0 ? (
                                webSocketIO.data.isCounterOpen ? (
                                  <BettingSelect
                                    type={props.type}
                                    index={index}
                                    classInputLabel={classesOddEvenStyles.tableTitleYellow}
                                    handleSetModal={props.handleSetModal}
                                    currency={'point'}
                                  />
                                ) : (
                                  'closed'
                                )
                              ) : (
                                row.betting
                              ) //(row.currency === 'coin'? row.betting : '-')
                            }
                          </Box>
                        </TableCell>
                        {/*<TableCell align="center">
                                  <Box className={classesOddEvenStyles.tdBlue}>
                                  {
                                      row.betting === 0 ? 
                                      (
                                        webSocketIO.data.isCounterOpen ?
                                        <BettingSelect type={props.type} index={index} classInputLabel={classesOddEvenStyles.tableTitleBlue} handleSetModal={props.handleSetModal} currency={'x'} /> :
                                        'closed'
                                      ) 
                                      : (row.currency === 'x'? row.betting : '-')//row.betting
                                  }
                                  </Box>
                                </TableCell>*/}
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdPurple}>x{Math.round(row.odds * 1000) / 1000}</Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )
                }
              </TableBody>
            </Table>
            <NotifyComponent title={oddEvenTitle} content={oddEvenContent} />

            <Table className={classesOddEvenStyles.table}>
              <TableBody className={classesOddEvenStyles.tbStyle}>
                {
                  //rows.map((row, index) => (
                  webSocketIO.data.listRow[props.type].map((row, index) =>
                    index >= 2 && index < 4 ? (
                      <TableRow
                        key={index}
                        className={
                          //classesOddEvenStyles.trStyle
                          props.type === 0 && (index === 2 || index === 4 || index === 8)
                            ? clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.topLienStyle)
                            : classesOddEvenStyles.trStyle
                        }
                      >
                        <TableCell align="center" className={classesOddEvenStyles.tdRed}>
                          <Box>
                            {props.type === 0
                              ? //index+1 === 1 ? 'odd' : 'even'
                                listLine[index]
                              : index + 1}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdYellow}>
                            {
                              row.betting === 0 ? (
                                webSocketIO.data.isCounterOpen ? (
                                  <BettingSelect
                                    type={props.type}
                                    index={index}
                                    classInputLabel={classesOddEvenStyles.tableTitleYellow}
                                    handleSetModal={props.handleSetModal}
                                    currency={'point'}
                                  />
                                ) : (
                                  'closed'
                                )
                              ) : (
                                row.betting
                              ) //(row.currency === 'coin'? row.betting : '-')
                            }
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdPurple}>x{Math.round(row.odds * 1000) / 1000}</Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )
                }
              </TableBody>
            </Table>
            <NotifyComponent title={underOverTitle} content={underOverContent} />

            <Table className={classesOddEvenStyles.table}>
              <TableBody className={classesOddEvenStyles.tbStyle}>
                {
                  //rows.map((row, index) => (
                  webSocketIO.data.listRow[props.type].map((row, index) =>
                    index >= 4 && index < 8 ? (
                      <TableRow
                        key={index}
                        className={
                          //classesOddEvenStyles.trStyle
                          props.type === 0 && (index === 2 || index === 4 || index === 8)
                            ? clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.topLienStyle)
                            : classesOddEvenStyles.trStyle
                        }
                      >
                        <TableCell align="center" className={classesOddEvenStyles.tdRed}>
                          <Box>
                            {props.type === 0
                              ? //index+1 === 1 ? 'odd' : 'even'
                                listLine[index]
                              : index + 1}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdYellow}>
                            {
                              row.betting === 0 ? (
                                webSocketIO.data.isCounterOpen ? (
                                  <BettingSelect
                                    type={props.type}
                                    index={index}
                                    classInputLabel={classesOddEvenStyles.tableTitleYellow}
                                    handleSetModal={props.handleSetModal}
                                    currency={'point'}
                                  />
                                ) : (
                                  'closed'
                                )
                              ) : (
                                row.betting
                              ) //(row.currency === 'coin'? row.betting : '-')
                            }
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdPurple}>x{Math.round(row.odds * 1000) / 1000}</Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )
                }
              </TableBody>
            </Table>
            <NotifyComponent title={oddUnderTitle} content={oddUnderContent} />

            <Table className={classesOddEvenStyles.table}>
              <TableBody className={classesOddEvenStyles.tbStyle}>
                {
                  //rows.map((row, index) => (
                  webSocketIO.data.listRow[props.type].map((row, index) =>
                    index >= 8 ? (
                      <TableRow
                        key={index}
                        className={
                          //classesOddEvenStyles.trStyle
                          props.type === 0 && (index === 2 || index === 4 || index === 8)
                            ? clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.topLienStyle)
                            : classesOddEvenStyles.trStyle
                        }
                      >
                        <TableCell align="center" className={classesOddEvenStyles.tdRed}>
                          <Box>
                            {props.type === 0
                              ? //index+1 === 1 ? 'odd' : 'even'
                                listLine[index]
                              : index + 1}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdYellow}>
                            {
                              row.betting === 0 ? (
                                webSocketIO.data.isCounterOpen ? (
                                  <BettingSelect
                                    type={props.type}
                                    index={index}
                                    classInputLabel={classesOddEvenStyles.tableTitleYellow}
                                    handleSetModal={props.handleSetModal}
                                    currency={'point'}
                                  />
                                ) : (
                                  'closed'
                                )
                              ) : (
                                row.betting
                              ) //(row.currency === 'coin'? row.betting : '-')
                            }
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box className={classesOddEvenStyles.tdPurple}>x{Math.round(row.odds * 1000) / 1000}</Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )
                  )
                }
              </TableBody>
            </Table>
            <NotifyComponent title={oddLowTitle} content={oddHighContent} />
          </>
        ) : (
          <>
            <Table className={classesOddEvenStyles.table}>
              <TableHead className={classesOddEvenStyles.thStyle}>
                <TableRow className={classesOddEvenStyles.trStyle}>
                  <TableCell align="center" className={classesOddEvenStyles.bgRed}>
                    {/*'Line'*/ lngT('header:line')}
                  </TableCell>
                  <TableCell align="center" className={classesOddEvenStyles.bgYellow}>
                    {/*'Point'*/ lngT('header:coin')}
                  </TableCell>
                  {/*<TableCell align="center" className={classesOddEvenStyles.bgBlue}>Meta.X</TableCell>*/}
                  <TableCell align="center" className={classesOddEvenStyles.bgPurple}>
                    {/*'Odds'*/ lngT('header:odds')}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classesOddEvenStyles.tbStyle}>
                {
                  //rows.map((row, index) => (
                  webSocketIO.data.listRow[props.type].map((row, index) => (
                    //index < 2 ? (
                    <TableRow
                      key={index}
                      className={
                        //props.type === 0 ? (
                        //  //row.line === 'EVEN' ?
                        //  index+1 === 2 ?
                        //  clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.bottomLienStyle) :
                        //  classesOddEvenStyles.trStyle
                        //) : (
                        //  //row.line === 'EVEN' ?
                        //  //index+1 === 2 ?
                        //  //clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.bottomLienStyle) :
                        //  classesOddEvenStyles.trStyle
                        //)
                        classesOddEvenStyles.trStyle
                        //props.type === 0 && (index === 1 || index === 3 || index === 7)
                        //  ? clsx(classesOddEvenStyles.trStyle, classesOddEvenStyles.bottomLienStyle)
                        //  : classesOddEvenStyles.trStyle
                      }
                    >
                      <TableCell align="center" className={classesOddEvenStyles.tdRed}>
                        <Box>
                          {props.type === 0
                            ? //index+1 === 1 ? 'odd' : 'even'
                              listLine[index]
                            : index + 1}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box className={classesOddEvenStyles.tdYellow}>
                          {
                            row.betting === 0 ? (
                              webSocketIO.data.isCounterOpen ? (
                                <BettingSelect
                                  type={props.type}
                                  index={index}
                                  classInputLabel={classesOddEvenStyles.tableTitleYellow}
                                  handleSetModal={props.handleSetModal}
                                  currency={'point'}
                                />
                              ) : (
                                'closed'
                              )
                            ) : (
                              row.betting
                            ) //(row.currency === 'coin'? row.betting : '-')
                          }
                        </Box>
                      </TableCell>
                      {/*<TableCell align="center">
                                  <Box className={classesOddEvenStyles.tdBlue}>
                                  {
                                      row.betting === 0 ? 
                                      (
                                        webSocketIO.data.isCounterOpen ?
                                        <BettingSelect type={props.type} index={index} classInputLabel={classesOddEvenStyles.tableTitleBlue} handleSetModal={props.handleSetModal} currency={'x'} /> :
                                        'closed'
                                      ) 
                                      : (row.currency === 'x'? row.betting : '-')//row.betting
                                  }
                                  </Box>
                                </TableCell>*/}
                      <TableCell align="center">
                        <Box className={classesOddEvenStyles.tdPurple}>x{Math.round(row.odds * 1000) / 1000}</Box>
                      </TableCell>
                    </TableRow> //)
                    //: <></>
                  ))
                }
              </TableBody>
            </Table>
          </>
        )}

        <Box className={classesResultBoxComponent.resultBox}>
          <Typography>
            {/*'TOTAL BET VALUE'*/ lngT('footer:totalBetValue')} : <b>{webSocketIO.data.total[props.type]}</b>{' '}
            {/*'COIN'*/ lngT('footer:coin')}
          </Typography>
          <Typography>
            {/*'MAX BET PRICE'*/ lngT('footer:maxBetPrice')} (10,000 {/*'COIN'*/ lngT('footer:coin')})
          </Typography>
        </Box>

        {/* 
                props.type === 0 ? <VersusFigureComponent oddrate={Math.round(webSocketIO.data.bettingOddEven[0]*1000)/1000} evenrate={Math.round(webSocketIO.data.bettingOddEven[1]*1000)/1000}/> : ''
              */}

        {/*props.type === 0 && (
          <>
            <NotifyComponent title={oddEvenTitle} content={oddEvenContent} />
            <NotifyComponent title={underOverTitle} content={underOverContent} />
            <NotifyComponent title={oddUnderTitle} content={oddUnderContent} />
            <NotifyComponent title={oddLowTitle} content={oddHighContent} />
          </>
        )*/}
        {props.type === 1 && (
          <>
            <NotifyComponent title={singleTitle} content={singleContent} />
          </>
        )}
        {props.type === 2 && (
          <>
            <NotifyComponent title={showTitle} content={showContent} />
          </>
        )}
      </Box>
    );
    /*return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>line</TableCell>
                <TableCell align="right">betting</TableCell>
                <TableCell align="right">odds</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              //rows.map((row, index) => (
              webSocketIO.data.listRow[props.type].map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index+1}
                  </TableCell>
                  <TableCell align="right">{
                  row.betting === 0 ? 
                  //<BettingSelect type={props.type} index={index} rows={rows} setRows={setRows} total={total} setTotal={setTotal} betting={betting} setBetting={setBetting}/> 
                  <BettingSelect type={props.type} index={index} /> 
                  : row.betting
                  }</TableCell>
                  <TableCell align="right">{row.odds}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );*/
  }
});

export default BettingWithStyle;
