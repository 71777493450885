import { useLang } from '@/lib/hook';

import { ListPagerProvider } from '@/lib/custom-lib/ListPager';

import { PopupLayout } from '@/page/common/layout';
import { TabManager, TitleText } from '@/page/common/component';

import HistoryTab from './HistoryTab';
import ConversionTab from './ConversionTab';
import Style from './style';

type Props = {
  closeModal: () => void;
};

export default function ConversionPointPopup({ closeModal }: Props) {
  const { lngT } = useLang('mypage:userInfo');

  return (
    <PopupLayout closeModal={closeModal} closeDisabled={false}>
      <Style.PopupLayer>
        <TitleText text={`Point ${lngT('conversion')}`} />
        <TabManager
          tabs={[
            {
              title: lngT('conversion'),
              value: 'conversion',
            },
            {
              title: lngT('conversion:history'),
              value: 'history',
            },
          ]}
        >
          {({ selectedTab }) => (
            <Style.PopupContentLayer>
              {selectedTab === 'conversion' ? (
                <ConversionTab />
              ) : (
                <ListPagerProvider>
                  <HistoryTab />
                </ListPagerProvider>
              )}
            </Style.PopupContentLayer>
          )}
        </TabManager>
      </Style.PopupLayer>
    </PopupLayout>
  );
}
