import React, { Component, useState } from 'react';
import {Box, Select,Button, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useOddEvenStyles } from './styles/OddEvenStyles';
import clsx from "clsx";
import Rate from './Rate';
//import React, { useLayoutEffect, useState, useEffect, useCallback, useRef } from 'react';


function createData(name, number, amount) {
  return { name, number, amount};
}

const rows = [
  createData('홀수', '1,3,5,7,9', 10000),
  createData('홀수하', '9,11', 20000),
  createData('홀짝하', '7,9', 30000),
  createData('홀짝상', '1,3,', 40000),
  createData('짝수상', '12 ', 50000),
];


function isOnButton(selectOddEven, indexButton) {
  const bettingButton = [
    [],
    [1], [2], [3], [4],
    [1, 3], [1, 4], [2, 3], [2, 4],
    [1, 5], [1, 6], [1, 7], [2, 5], [2, 6], [2, 7]
  ]

  for (var n = 0; n < bettingButton[selectOddEven].length; n++) {
    if (bettingButton[selectOddEven][n] === indexButton) {
      return true;
    }
  }
  return false;

  /*if (selectOddEven === 1 && (indexButton === 0)) {
    return true;
  } else if (selectOddEven === 2 && (indexButton === 1)) {
    return true;
  } else if (selectOddEven === 3 && (indexButton === 2)) {
    return true;
  } else if (selectOddEven === 4 && (indexButton === 3)) {
    return true;
  } else if (selectOddEven === 5 && (indexButton === 0 || indexButton === 2)) {
    return true;
  } else if (selectOddEven === 6 && (indexButton === 0 || indexButton === 3)) {
    return true;
  } else if (selectOddEven === 7 && (indexButton === 1 || indexButton === 2)) {
    return true;
  } else if (selectOddEven === 8 && (indexButton === 1 || indexButton === 3)) {
    return true;
  } else if (selectOddEven === 9 && (indexButton === 0 || indexButton === 4)) {
    return true;
  } else if (selectOddEven === 10 && (indexButton === 0 || indexButton === 5)) {
    return true;
  } else if (selectOddEven === 11 && (indexButton === 0 || indexButton === 6)) {
    return true;
  } else if (selectOddEven === 12 && (indexButton === 1 || indexButton === 4)) {
    return true;
  } else if (selectOddEven === 13 && (indexButton === 1 || indexButton === 5)) {
    return true;
  } else if (selectOddEven === 14 && (indexButton === 1 || indexButton === 6)) {
    return true;
  }
  return false;*/
}

function isOnDigit(selectOddEven, digit) {
  const bettingButton = [
    [],
    [1, 3, 5, 7, 9, 11],
    [2, 4, 6, 8, 10, 12], 
    [1, 2, 3, 4, 5, 6], 
    [7, 8, 9, 10, 11, 12], 
    [1, 3, 5],
    [7, 9, 11], 
    [2, 4, 6], 
    [8, 10, 12], 
    [1, 3], 
    [5, 7], 
    [9, 11], 
    [2, 4], 
    [6, 8], 
    [10, 12]
  ]

  for (var n = 0; n < bettingButton[selectOddEven].length; n++) {
    if (bettingButton[selectOddEven][n] === digit) {
      return true;
    }
  }
  return false;

}

function toggleButton(selectOddEven, indexButton) {
  var indexOddEvenUnder = [0, 0, 0];
  const index = [
    [0, 0, 0],
    [1, 0, 0],
    [2, 0, 0],
    [0, 1, 0],
    [0, 2, 0],
    [1, 1, 0],
    [1, 2, 0],
    [2, 1, 0],
    [2, 2, 0],
    [1, 0, 1],
    [1, 0, 2],
    [1, 0, 3],
    [2, 0, 1],
    [2, 0, 2],
    [2, 0, 3]
  ]
  
  for (var n = 0; n < index[selectOddEven].length; n++) {
    indexOddEvenUnder[n] = index[selectOddEven][n];
  }

  if (indexButton === 1) {
    if (indexOddEvenUnder[0] === 0) {
      indexOddEvenUnder[0] = 1;
    } else if (indexOddEvenUnder[0] === 1) {
      indexOddEvenUnder[0] = 0;
    } else {
      indexOddEvenUnder[0] = 1;
    }
  } else if (indexButton === 2) {
    if (indexOddEvenUnder[0] === 0) {
      indexOddEvenUnder[0] = 2;
    } else if (indexOddEvenUnder[0] === 2) {
      indexOddEvenUnder[0] = 0;
    } else {
      indexOddEvenUnder[0] = 2;
    }
  } else if (indexButton === 3) {
    if (indexOddEvenUnder[1] === 0) {
      indexOddEvenUnder[1] = 1;
    } else if (indexOddEvenUnder[1] === 1) {
      indexOddEvenUnder[1] = 0;
    } else {
      indexOddEvenUnder[1] = 1;
    }
    indexOddEvenUnder[2] = 0;
  } else if (indexButton === 4) {
    if (indexOddEvenUnder[1] === 0) {
      indexOddEvenUnder[1] = 2;
    } else if (indexOddEvenUnder[1] === 2) {
      indexOddEvenUnder[1] = 0;
    } else {
      indexOddEvenUnder[1] = 2;
    }
    indexOddEvenUnder[2] = 0;
  } else if (indexButton === 5) {
    if (indexOddEvenUnder[2] === 0) {
      indexOddEvenUnder[2] = 1;
    } else if (indexOddEvenUnder[2] === 1) {
      indexOddEvenUnder[2] = 0;
    } else {
      indexOddEvenUnder[2] = 1;
    }
    indexOddEvenUnder[1] = 0;
  } else if (indexButton === 6) {
    if (indexOddEvenUnder[2] === 0) {
      indexOddEvenUnder[2] = 2;
    } else if (indexOddEvenUnder[2] === 2) {
      indexOddEvenUnder[2] = 0;
    } else {
      indexOddEvenUnder[2] = 2;
    }
    indexOddEvenUnder[1] = 0;
  } else if (indexButton === 7) {
    if (indexOddEvenUnder[2] === 0) {
      indexOddEvenUnder[2] = 3;
    } else if (indexOddEvenUnder[2] === 3) {
      indexOddEvenUnder[2] = 0;
    } else {
      indexOddEvenUnder[2] = 3;
    }
    indexOddEvenUnder[1] = 0;
  }

  for (var n = 0; n < index.length; n++) {
    if (index[n][0] === indexOddEvenUnder[0] && index[n][1] === indexOddEvenUnder[1] && index[n][2] === indexOddEvenUnder[2]) {
      return n;
    }
  }

  return 0;
}


export default function OddEven(props){
    const classes  = useOddEvenStyles();
    const [age, setAge] = React.useState('');

    const [selectOddEven, setSelectOddEven] = useState(14);

    const selectBetting = (selectOddEven, indexButton) => {
      var newSelect = toggleButton(selectOddEven, indexButton);
      setSelectOddEven(newSelect);
    }

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    return (
        <Box className={classes.root}>
            <Rate/>
            <Box className={classes.bettingStyle}>
                <Box className={clsx(classes.item, classes.empty)}></Box>
                <Button className={
                  isOnButton(selectOddEven, 1)?
                  clsx(classes.item, classes.itemActive):classes.item
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 1)}>홀수</Button>
                <Button className={
                  isOnButton(selectOddEven, 2)?
                  clsx(classes.item, classes.itemActive):classes.item
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 2)}>짝수</Button>
                <Box className={clsx(classes.item, classes.empty)}></Box>

                <Button className={
                  isOnButton(selectOddEven, 3)?
                  clsx(classes.item, classes.itemRowUnder, classes.itemActive):clsx(classes.item, classes.itemRowUnder)
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 3)}>언더</Button>
                <Button className={
                  isOnDigit(selectOddEven, 1)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>1</Button>
                {/*<Button className={classes.itemNum} disableRipple>2</Button>*/}
                <Button className={
                  isOnDigit(selectOddEven, 2)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>2</Button>
                <Button className={
                  isOnButton(selectOddEven, 5)?
                  clsx(classes.item,classes.itemRowBottom, classes.itemActive):clsx(classes.item,classes.itemRowBottom)
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 5)}>하</Button>
                
                <Button className={
                  isOnDigit(selectOddEven, 3)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>3</Button>
                <Button className={
                  isOnDigit(selectOddEven, 4)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>4</Button>
                <Button className={
                  isOnDigit(selectOddEven, 5)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                } disableRipple>5</Button>
                <Button className={
                  isOnDigit(selectOddEven, 6)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>6</Button>
                <Button className={
                  isOnButton(selectOddEven, 6)?
                  clsx(classes.item,classes.itemRowMiddle, classes.itemActive):clsx(classes.item,classes.itemRowMiddle)
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 6)}>중</Button>
                <Button className={
                  isOnButton(selectOddEven, 4)?
                  clsx(classes.item,classes.itemRowOver, classes.itemActive):clsx(classes.item,classes.itemRowOver)
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 4)}>오버</Button>
                <Button className={
                  isOnDigit(selectOddEven, 7)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum}
                   disableRipple>7</Button>
                <Button className={
                  isOnDigit(selectOddEven, 8)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>8</Button>
                <Button className={
                  isOnDigit(selectOddEven, 9)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>9</Button>
                <Button className={
                  isOnDigit(selectOddEven, 10)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>10</Button>
                <Button className={
                  isOnButton(selectOddEven, 7)?
                  clsx(classes.item,classes.itemRowTop, classes.itemActive):clsx(classes.item,classes.itemRowTop)
                  } disableRipple onClick={()=>selectBetting(selectOddEven, 7)}>상</Button>
                <Button className={
                  isOnDigit(selectOddEven, 11)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>11</Button>
                <Button className={
                  isOnDigit(selectOddEven, 12)?
                  clsx(classes.itemNum, classes.itemNumActive):classes.itemNum
                  } disableRipple>12</Button>
            </Box>

            <Select className={classes.selectStyle} value={age} onChange={handleChange} displayEmpty >
                <MenuItem value="">금액</MenuItem>
                <MenuItem value={10}>1000</MenuItem>
                <MenuItem value={20}>2000</MenuItem>
                <MenuItem value={30}>3000</MenuItem>
            </Select>

            <Table className={classes.tableStyle}>
                <TableHead>
                <TableRow>
                    <TableCell align="center">경기</TableCell>
                    <TableCell align="center">번호</TableCell>
                    <TableCell align="center">금액</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.name}
                    >
                    <TableCell component="th" scope="row" align="center">
                        {row.name}
                    </TableCell>
                    <TableCell align="center">{row.number}</TableCell>
                    <TableCell align="center">{row.amount}</TableCell>
                    </TableRow>
                ))}
        </TableBody>
      </Table>

        </Box>
    );
}
