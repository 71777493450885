//import React from 'react';
import React, { useRef, useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, IconButton, TextField, Avatar } from '@mui/material';
import { useStyles } from '../styles/ChattingComponentStyles';
import SendIcon from '@mui/icons-material/Send';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

var { webSocketIO } = require('../../socketClient.js');

//export default function ChattingComponent(props, ref){
const ChattingComponent = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [openChat, setChat] = React.useState(true);

  const [, updateState] = React.useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);

  useImperativeHandle(ref, () => ({
    forceUpdate,
  }));

  //useImperativeHandle(ref, () => ({
  //  scrollToBottom,
  //}), [webSocketIO.data.listChat]);

  const messageBoxRef = useRef();
  const [messages, setMessages] = useState();
  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
    //props.newMsg = ''
  }, [props.newMsg]);
  useEffect(() => {
    scrollToBottom();
    //props.newMsg = ''
  }, [props.height]);

  const toggleChat = () => {
    setChat(openChat => !openChat);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //const onInput = (event) => {
  //    console.log("input");
  //};
  const onChange = event => {
    setValue(event.target.value);
    //console.log("change");
  };
  const onKeyDown = event => {
    if (event.keyCode == 13) {
      if (event.target.value.length > 0) {
        webSocketIO.send(
          JSON.stringify({
            protocol: 'reqChatting',
            type: 0,
            msg: event.target.value,
          })
        );
      }

      console.log('value', event.target.value);
      setValue('');
    }
  };

  const onClick = () => {
    webSocketIO.send(
      JSON.stringify({
        protocol: 'reqChatting',
        type: 0,
        msg: value,
      })
    );
    console.log('value', value);
    setValue('');
  };

  const chatHeight = props.height - 40 - 54;

  return (
    <Box
      className={`${classes.chatBox} px-[20px] T:px-[100px]`}
      // maxHeight={props.height}
      // height={openChat ? props.height : 46}
    >
      <Box className={classes.chatTitle}>
        <Typography variant={'h4'}>CHAT</Typography>
        <IconButton disableRipple className={classes.btnActive} onClick={() => toggleChat()}>
          {openChat ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
      </Box>
      {/*<Box className={openChat ? classes.chatContentWrap : classes.none }>*/}
      <Box className={openChat ? classes.chatContent : classes.none} height={openChat ? chatHeight : 0}>
        <Box className={classes.wrapContent} ref={messageBoxRef}>
          {webSocketIO.data.listChat.map((row, index) =>
            row.idUser !== webSocketIO.data.idUser ? (
              <Box className={classes.anotherUser}>
                <Box>
                  <span className={classes.nickName}>{row.name} </span>
                  <p>{row.msg}</p>
                </Box>
              </Box>
            ) : (
              <Box className={classes.myText}>
                <p>{row.msg}</p>
              </Box>
            )
          )}
        </Box>

        {/*<Box className={classes.anotherUser}>
                  <Box>abc12345 <p>남이 쓴 글 남이 쓴 글남이 쓴 글</p></Box>
                </Box>

                <Box className={classes.manager}>[ 관리자가 쓴 글 ]</Box>

                <Box className={classes.anotherUser}>
                  <Box>User <p>남이 쓴 글</p></Box>
                </Box>

                <Box className={classes.myText}><p>내내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글 내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글내가 쓴 글</p>
                </Box>

                <Box className={classes.anotherUser}>
                  <Box>
                    User12 
                    <p>남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글</p>
                    </Box>
                </Box> 

                <Box className={classes.anotherUser}>
                  <Box>
                    User12 
                    <p>남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글</p>
                    </Box>
                </Box> 

                <Box className={classes.anotherUser}>
                  <Box>Another User <p>남이 쓴 글</p></Box>
                </Box>

                <Box className={classes.anotherUser}>
                  <Box>Another User1234 <p>남이 쓴 글 남이 쓴 글 남이 쓴 글 남이 쓴 글 남이쓴 글 남이 쓴 </p></Box>
                </Box>

                <Box className={classes.myText}>
              <p>내가 쓴 글</p></Box>*/}

        <Box className={classes.chatInput}>
          <TextField
            fullWidth
            placeholder="input message"
            value={value}
            onKeyDown={onKeyDown}
            //onInput={onInput}
            onChange={onChange}
          ></TextField>
          <IconButton disableRipple>
            <SendIcon onClick={onClick} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
});

export default ChattingComponent;
