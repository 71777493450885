import React, { useEffect, useState } from 'react';
import { MdPreview } from 'react-icons/md';
import * as define from '../../define';

const ResultProgressBar = progress => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (width <= 100) {
        setWidth(prevWidth => prevWidth + 1);
      } else {
        clearInterval(timer);
      }
    }, define.RESULTPAGE_WAITING_DELAY / 125);
    return () => clearInterval(timer);
  }, [progress]);

  return (
    <div className="">
      {/* <div className="flex mb-2 items-center justify-between">
        <div>
          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
            진행 중
          </span>
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-teal-600">{width}%</span>
        </div>
      </div> */}
      <div className="flex h-3 w-full mb-4 overflow-hidden text-xs bg-[#546739]/60 rounded">
        <div
          style={{ width: `${width}%` }}
          className="flex flex-col text-center whitespace-nowrap rounded-full text-white justify-center bg-gradient-to-l from-[#7C9B3F] via-[#7C9B3F]/50 to-[#cee998]"
        ></div>
      </div>
    </div>
  );
};

export default ResultProgressBar;
