import axios from 'axios';
// import { useLang } from '../lib/hook';
import {GetBetServerURL, GetPrefixURL, SERVICE_TYPE, SERVICE_RACING, SERVICE_POWERBALL} from '../config'

  const GetTick=(hour, minutes, seconds)=>{
    return hour * 60 * 60 + 
      minutes * 60 + 
      seconds    
  }

  const ServerUTCStrToHMS=(utcStr)=>{
    const eh = Number(utcStr.slice(0,2))
    const em = Number(utcStr.slice(3,5))
    const es = Number(utcStr.slice(7,8))    
    return [eh, em, es];
  }

  const IsBetweenCurTime=(strTimeStart, strTimeEnd, numDay)=>
  {
    const datetime = new Date();
    const curH = datetime.getUTCHours(); // 4
    const curM = datetime.getUTCMinutes(); // 30
    const curS = datetime.getUTCSeconds();
    const curTick = GetTick(curH, curM, curS);

    // MaintenenceTime, BreakTime Test
    // const curH = 21;
    // const curM = 56;
    // const curS = 0;
    // const curTick = GetTick(curH, curM, curS);
    // numDay = 1;

    const sHMS  = ServerUTCStrToHMS(strTimeStart);
    const sTick = GetTick(sHMS[0], sHMS[1], sHMS[2]);

    const eHMS  = ServerUTCStrToHMS(strTimeEnd);
    const eTick = GetTick(eHMS[0], eHMS[1], eHMS[2]);

    const isBreakTime = curTick >= sTick && curTick <= eTick;    

    let isMaintenanceDay = true;
    if(numDay !== undefined)
    {
      const curDay = datetime.getUTCDay();
      isMaintenanceDay = curDay === numDay;
    }

    return isBreakTime && isMaintenanceDay;
  }

  export const CheckMaintenence = (lngT, Callback) => {    
    // const { lngT } = useLang('etcs');
    const serverurl = GetPrefixURL() + GetBetServerURL();
    const detailURL = SERVICE_TYPE === SERVICE_RACING ? 'maintenancetime' : 'powerballmaintenancetime';
    const maintenanceURL = `${serverurl}/api/v1/${detailURL}`;
    console.log('maintenanceURL : ' + maintenanceURL);
    axios.get(maintenanceURL)
    .then(res=>{
      const data = res.data;
      console.log('maintenanceURL info: ' + JSON.stringify(res));
      
      let isBreakTime = IsBetweenCurTime(data.BreakTimeStart, data.BreakTimeEnd);
      let isMaintenanceTime = IsBetweenCurTime(data.MaintenanceTimeStart, data.MaintenanceTimeEnd, data.MaintenanceTimeDay);
      const maintenanceStr = lngT('warning:maintenance');
      const maintenanceTimeStr = lngT('warning:maintenanceTime');
      const breaktimeStr = lngT('warning:breaktime');
      
      if(data.ErrorCode === 0)
      {
        if(isMaintenanceTime)
        {
          alert( `${maintenanceStr} \n${maintenanceTimeStr} : ${data.MaintenanceTimeStart}~${data.MaintenanceTimeEnd} (UTC)`);
        }
        else if(isBreakTime)
        {
          alert( `${maintenanceStr} \n${breaktimeStr} : ${data.BreakTimeStart}~${data.BreakTimeEnd} (UTC)`);
        }
      }
      else
      {
        console.log(`CheckMaintenence error ${data.ErrorCode}`)
      }

      if(Callback !== undefined)
        Callback();

    }).catch (error=> {
      console.log(error);
    });
  }
