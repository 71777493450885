import React, {useEffect, useRef} from 'react';
import {SpinePlayer} from '@esotericsoftware/spine-player';

import {withStyles} from "@mui/styles";
import {getStyleByBrand} from "../styles/StyleManager";
const styles = getStyleByBrand();

var spinePlayer;
let spinePlayers = {}

function IsKeyInObject(obj, key) {
    return Object.keys(obj).some(v => v == key);
}

function SpineRenderCommonForReel(props) {
    const { classes } = props;
    const refSpine = useRef(null);
    const path = props.path; //'horsespine';
    const file = props.file; //'result'
    const ani = props.aniName;
    const loop = props.loop !== undefined ? props.loop : true;
    const ext = props.ext === undefined ? 'json' : props.ext;

    const IsNullLine=()=>{
        let isNull = true;
        props.visibles.forEach(element => {
            if(element !== -1)    
            {
                isNull = false;
                return;
            }
        });

        if(props.enterfreespinanimationplaying)
            isNull = true;

        return isNull;
    }

    useEffect(() => {
        if (!IsKeyInObject(props.id)) {

            // if (file === 'freespin') {
            //     console.log(`ccc freespin init - ani:${ani} loop:${loop}`);
            // } else if (file.includes('fx_line')) {
            //     console.log(`ccc fx_line init - ani:${ani} loop:${loop}`);
            // }

            console.log('EXT TEST' + `/${path}/${file}.${ext}`);
            spinePlayer = new SpinePlayer(props.id, {
                jsonUrl: `/${path}/${file}.${ext}`,
                atlasUrl: `/${path}/${file}.atlas`,
                animation: ani,
                backgroundColor: '#00000000',
                alpha: true,
                showControls: false,
                showLoading: false,
                premultipliedAlpha :false
            });

            spinePlayers[props.id] = spinePlayer;
        }

        const initinterval = setInterval(() => {
            console.log('interval ' + file);
            if(file !== 'fx_jackpot_common') {
                clearInterval(initinterval);
                return;
            }

            if(IsNullLine()) {
                let spinePlayerByID = spinePlayers[props.id];
                if(spinePlayerByID.skeleton) {
                    clearInterval(initinterval);
                    InitLineAllSpine();
                    // spinePlayerByID.skeleton.slots.map((element, index)=>{
                    //     if(index !== 0)
                    //         element.color.a = 0.0;
                    // })
                }
            }
            
        }, 10);
    }, []);

    useEffect(() => {
        let spinePlayerByID = spinePlayers[props.id]
        if (spinePlayerByID.animationState) {
            const curani = spinePlayerByID.animationState.getCurrent(0)?.animation;
            if (curani !== undefined && curani.name === ani || ani === '') {
                return;
            }

            // if (file === 'freespin') {
            //     console.log(`ccc freespin curani:${curani.name} ani:${ani} loop:${loop}`);
            // }
            spinePlayerByID.animationState.setAnimation(0, ani, loop)


            
        }
        
    }, [ani]);


    useEffect(()=>{
        if(file === 'fx_jackpot_common')
        {
            console.log(`enterfreespinanimationplaying : ${props.visibles}`);
            let spinePlayerByID = spinePlayers[props.id]
            console.log('printline : ' + spinePlayerByID.skeleton + ' ' + props.visibles);

            if(IsNullLine()) {
                if(spinePlayerByID.skeleton) {
                    InitLineAllSpine();
                    // spinePlayerByID.skeleton.slots.map((element, index)=>{
                    //     if(index !== 0)
                    //         element.color.a = 0.0;
                    // })
                }
            } else {
                props.visibles.map((element, index) => {
                    const itemcountbyline = 4;
                    const dindex = index * itemcountbyline + 1;
                    
                    for(let i = 0; i < itemcountbyline; ++i)
                    {
                        spinePlayerByID.skeleton.slots[dindex+i].color.a = element === -1 ? 0.0 : 1.0;
                    }                
                })
            }            
        }
    },[props.visibles, props.enterfreespinanimationplaying])

    const InitLineAllSpine=()=>{
        let spinePlayerByID = spinePlayers[props.id]
        spinePlayerByID.skeleton.slots.map((element, index)=>{
            if(index > 0 && index <= 20)
                element.color.a = 0.0;

            // element.color.a = 0.2; DEBUGTEST
        })
    }


    return (
        <div
            className={classes.effect}
            style={props.className? props.className:{}}
            id={props.id}
            ref={refSpine}
        />
    );
};

export default withStyles(styles) (SpineRenderCommonForReel);
