import useSWR from 'swr';
import axios from 'axios';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import tw from 'tailwind-styled-components';

import { WEBSERVERPORT } from '../../config';
import useLang from '../../lib/hook/useLang';
import { useUser } from '@/lib/hook';

const QuestionWriteBtn = tw.div`
  flex justify-center items-center
  h-[48px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

export default function Question() {
  const { lngT } = useLang('contact:question');
  const navigate = useNavigate();
  const params = useParams();
  const [user, { logout }] = useUser();
  const [questionId, setQuestionId] = useState(null);
  const { data: data, isLoading: isLoading, error: error } = useSWR(`${process.env.REACT_APP_API_URL}/question`);

  return (
    <div className="flex flex-col rounded-xl mt-4 T:mt-0 D:mx-[64px]">
      <div className="hidden D:flex flex-row D:justify-between items-center text-[24px] font-medium text-white px-[6px] T:pb-[24px]">
        <div className="text-[20px]">{lngT('title')}</div>
        <QuestionWriteBtn
          className="text-[14px] px-[20px] rounded-xl"
          onClick={() => navigate('/contact/question/write')}
        >
          {lngT('header:askQuestion')}
        </QuestionWriteBtn>
      </div>
      <div className="hidden T:flex flex-row border-y-2 border-white/20 py-[8px] text-white/90 text-[14px] font-medium uppercase T:space-x-9">
        <div className="flex flex-1 pl-[15px] items-center">{lngT('header:title')}</div>
        <div className="flex justify-center items-center w-[150px]">{lngT('header:status')}</div>
        <div className="flex justify-center items-center w-[120px]">{lngT('header:date')}</div>
      </div>
      <span className="border-b border-white/20 T:hidden"></span>
      {console.log('question', user?.id)}

      {data?.questions
        .filter(item => user?.id && user.id == item.user_id)
        .map((item, i) => (
          <QuestionItem key={i} item={item} />
        ))}
      <QuestionWriteBtn className="fixed bottom-0 w-full D:hidden" onClick={() => navigate('/contact/question/write')}>
        {lngT('write:section:askQuestion')}
      </QuestionWriteBtn>
    </div>
  );
}

const QuestionItem = ({ item }) => {
  const { lngT } = useLang('contact:question');
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col T:flex-row opacity-90 border-b border-white/20 p-[15px] T:p-[16px] T:pr-0 space-y-[6px] cursor-pointer hover:bg-[#62218d]/10  bg-[#1C012E]"
      onClick={() => navigate(`/contact/question/${item.id}`)}
    >
      <div className="flex items-center text-white text-[15px] T:flex-1 capitalize">{item.title}</div>
      <div className="flex flex-row justify-end items-center T:space-x-9">
        <div
          className={`flex justify-center text-[14px]  T:w-[150px] uppercase ${
            !item.answer_id ? 'text-[#E44E54]' : 'text-[#74D1C8]'
          }`}
        >
          {!item.answer_id ? lngT('status:inProgress') : lngT('status:complete')}
        </div>
        <span className="px-[8px] text-[#CCCCCC]/80 text-[12px] T:hidden">|</span>
        <div className="flex justify-center text-white/90 text-[14px] T:w-[120px]">
          {new Date(item.date).toISOString().substring(0, 10)}
        </div>
      </div>
    </div>
  );
};
