import axios from 'axios';
import { env } from '@/env';

import { t } from 'i18next';
import * as config from '../../config';
import * as define from '../../define';
import { useSelector } from 'react-redux';
import { setCookie, getCookie } from '../../lib/cookie';
// import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import i18n from '../../lang/i18n';
import useLang from '@/lib/hook/useLang';

var { webSocketIO } = require('../../socketClient.js');
// var { clientData } = require('../../clientData.js');
// const vscode = require('vscode');
const navigate = useNavigate;
export const ContractName = name => {
  if (name === undefined || name.length === 0) return '';

  var contractname = '';
  var contractString = '...';
  if (name.length > define.contractLength) {
    contractname = name.substr(0, define.contractLength);
    contractname = contractname.concat(contractString);
  } else contractname = name;

  return contractname;
};

const listLine = [
  'ODD',
  'EVEN',
  'UNDER',
  'OVER',
  'ODD UNDER',
  'ODD OVER',
  'EVEN UNDER',
  'EVEN OVER',
  'ODD SMALL',
  'ODD MEDIUM',
  'ODD LARGE',
  'EVEN SMALL',
  'EVEN MEDIUM',
  'EVEN LARGE',
]; //['odd', 'even'];//

export const GetNumberName = (type, no, idDiffShowAndSingle) => {
  var numberName;
  if (type === 0) {
    //numberName = no === 1 ? 'ODD' : 'EVEN';
    numberName = 'ODD';
    if (no >= 1 && no <= 14) {
      numberName = listLine[no - 1];
    }
  } else {
    if (idDiffShowAndSingle) {
      var typename = type === 1 ? 'SINGLE' : 'SHOW';
      numberName = `${no} - ${typename}`;
    } else {
      numberName = no;
    }
  }
  return numberName;
};

export const Round = (number, count) => {
  return number.toFixed(count);
};

// [U] 유니티, [R] 리액트
// ex) console.log('', config.LOG_RELEASE) : 라이브 모드에서도 출력

export const SafeLog = (log, logLV = config.LOG_DEVELOPER_1) => {
  if (log === undefined) return;

  if (logLV > config.DEBUG_LV) return;

  // const previousStackTrace = stackTrace.slice(1);
  const stackTrace = getStackTrace();
  const linebreakLength = 100;
  let headlog = '';
  let resultLog = '';
  let prefix = '';

  if (log.length > linebreakLength) {
    headlog = log.substr(0, linebreakLength) + '...';
  }
  resultLog = log;

  if (stackTrace.length > 1 && stackTrace[1].includes('_JS_Log_Dump')) {
    prefix = '[U] ';
  } else prefix = '[R] ';

  if (headlog !== '') {
    console.groupCollapsed(prefix + headlog);
    console.trace(resultLog);
  } else {
    console.groupCollapsed(prefix + resultLog);
    console.trace();
  }
  console.groupEnd();

  function getStackTrace() {
    let stack = new Error().stack || '';
    stack = stack.split('\n').map(function (line) {
      return line.trim();
    });
    return stack.splice(stack[0] === 'Error' ? 2 : 1);
  }

  // debugger;
  // console.debug(log);
  // console.time();
  // console.timeEnd();

  // window.addEventListener('consolemessage', (event) => {
  //   event.data.stack = previousStackTrace;
  // });

  // // 콜스택을 클릭했을 때 최종 코드가 아닌, 최종 직전의 코드로 이동하도록 하기
  // window.addEventListener('click', (event) => {
  //   const target = event.target;

  //   console.log('hello');
  //   if (target.classList.contains('monaco-editor')) {
  //     const editor = target.querySelector('.monaco-editor-container');
  //     const lineNumber = target.querySelector('.monaco-editor-line-number').textContent;
  //     const textRange = getRange(lineNumber - 1, 0, lineNumber - 1, 0);
  //     editor.selection = textRange;
  //   }
  // });
};

function getRange(startLineNumber, startColumn, endLineNumber, endColumn) {
  return {
    startLineNumber,
    startColumn,
    endLineNumber,
    endColumn,
  };
}

export const SetSafeLog = () => {
  // if(config.USE_SAFELOG === undefined)
  //   return;

  if (!define.USE_SAFELOG) return;

  console.log = SafeLog;
};
export const ChangeProgress_combine = (game, user, toprogress, language, isHistory, goodsType) => {
  console.log('test game ChangeProgress_combin ' + game);
  ChangePage(
    `/GameOnePage?game=${game}&user=${user}&progress=${toprogress}&lan=${language}&isHistory=${isHistory}&goodsType=${goodsType}`
  );
};

function ChangePage(url) {
  var urls = url.split('?');
  var urlhead = urls[0];
  var urlparam = urls[1];

  var urlparams = urlparam.split('&');
  var game = urlparams[0].split('=')[1];
  var user = urlparams[1].split('=')[1];
  var progress = urlparams[2].split('=')[1];
  var lan = urlparams[3].split('=')[1];
  var isHistory = urlparams[4].split('=')[1];
  var goodsType = urlparams[5].split('=')[1];

  // var urlparam = {
  //   game: game,
  //   user: user,
  //   progress: progress,
  //   lan: lan,
  //   isHistory: isHistory,
  // };
  SetCookie(game, user, progress, lan, isHistory, goodsType);

  window.location.href = urlhead;
}

export function SetCookie(game, user, progress, lan, isHistory, goodsType) {
  var urlparam = {
    game: game,
    user: user,
    progress: progress,
    lan: lan,
    isHistory: isHistory,
    goodsType: goodsType,
  };
  setCookie('urlparam', urlparam, {
    path: '/', // 모든 경로에 적용
    maxAge: 10 * 60, // 10m 유지
    ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
  });
}

// /powerball/betting
export function ChangePagePowerBall(urlhead, goodsType) {
  if (goodsType !== null) {
    var urlparam = getCookie('urlparam');

    if (urlparam === undefined) {
      urlparam = {
        goodsType: goodsType,
      };
    }

    urlparam.goodsType = goodsType;
    setCookie('urlparam', urlparam, {
      path: '/', // 모든 경로에 적용
      maxAge: 10 * 60, // 10m 유지
      ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
    });
  }
  window.location.href = urlhead;
}

export const GetAddHttpURL = () => {
  var fullurl = config.GetPrefixURL() + config.GetBetServerURL();
  return fullurl;
};

export function GoMainLobby() {
  const navigate = useNavigate();
  navigate('/LobbyGame');
}

export function GoReelGame(lngTetc){  
  // alert(lngTetc('warning:reelmaintenance'));
  // return;
  
  window.location.href = '/reelgame';
}

export function PlayGame(navigate, userName, goodsType) {
  // const [user] = useUser();
  // user?.name
  //const navigate = useNavigate();
  
  
  const GetProgress = async useChangeProgressCombine => {
    try {
      const url = `${config.GetPrefixURL()}${config.GetBetServerURL()}/api/v1/raceprogress`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log('raceProgress data' + data, config.LOG_DEVELOPER_1);
      // setdataid(data.id);
      // setNowProgress(data.Progress);

      if (userName === undefined) {
        navigate('/login');
      } else if (data.Progress === define.PROGRESS_MAINLOBBY) {
        //GoMainLobby();
        navigate('/LobbyGame');
      } else if (useChangeProgressCombine) {
        ChangeProgress_combine(data.ID, userName, data.Progress, i18n.language, false, goodsType);
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (e) {
      console.log(e);
    }
  };

  GetProgress(true);
}

export function SetBalance(CallSetBalance) {
  (async function () {
    try {
      const { data } = await axios.get(`${env.api.host}/balance`, {
        withCredentials: true,
      });

      CallSetBalance(data.data);
    } catch (error) {
      console.log(error);
    }
  })();
}

export function GetDefaultGoods(balance)
{
  if(balance === undefined)
  {
    console.log('error GetDefaultGoods balance');
    return define.GoodsType.CASHCOIN;
  }

  const minBetbalance = 50;  
  return balance.coin >= minBetbalance || ( balance.coin < minBetbalance && balance.free < minBetbalance ) ? define.GoodsType.CASHCOIN : define.GoodsType.FREECOIN;
}

export const TEST_VALUE = 100;
