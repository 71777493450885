import { useEffect, useState } from 'react';

const useScaleWidth = () => {
  const [scaleWidth, setScaleWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setScaleWidth(document.body.offsetWidth);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [scaleWidth]);

  return {
    scaleWidth,
  };
};

export default useScaleWidth;
