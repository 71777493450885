//import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import * as config from '../config';
import * as define from '../define';
import { useSelector } from 'react-redux';
import { setCookie, getCookie } from '../lib/cookie';
import { env } from '@/env';

// var { webSocketIO } = require('../socketClient.js');

export const ContractName = name => {
  if (name == undefined || name.length == 0) return '';

  var contractname = '';
  var contractString = '...';
  if (name.length > define.contractLength) {
    contractname = name.substr(0, define.contractLength);
    contractname = contractname.concat(contractString);
  } else contractname = name;

  return contractname;
};

const listLine = [
  'ODD',
  'EVEN',
  'UNDER',
  'OVER',
  'ODD UNDER',
  'ODD OVER',
  'EVEN UNDER',
  'EVEN OVER',
  'ODD SMALL',
  'ODD MEDIUM',
  'ODD LARGE',
  'EVEN SMALL',
  'EVEN MEDIUM',
  'EVEN LARGE',
]; //['odd', 'even'];//

export const GetNumberName = (type, no, idDiffShowAndSingle) => {
  var numberName;
  if (type === 0) {
    //numberName = no === 1 ? 'ODD' : 'EVEN';
    numberName = 'ODD';
    if (no >= 1 && no <= 14) {
      numberName = listLine[no - 1];
    }
  } else {
    if (idDiffShowAndSingle) {
      var typename = type === 1 ? 'SINGLE' : 'SHOW';
      numberName = `${no} - ${typename}`;
    } else {
      numberName = no;
    }
  }
  return numberName;
};

export const Round = (number, count) => {
  return number.toFixed(count);
};

// export const SafeLog = (log, logLV = 0) => {
//   if (logLV > config.DEBUG_LV) return;

//   console.trace('METAF(R) LOG : ' + log);
// };

export const ChangeProgress = (game, user, toprogress, language) => {
  //const navigate = useNavigate();

  // SafeLog(`game : ${game}, user : ${user}, toprogress : ${toprogress}`);

  // const state = {
  //     game: game,
  //     user: user,
  //     progress: toprogress,
  //   };

  if (toprogress === 1 || toprogress === 2) {
    ChangePage(`/introbet?game=${game}&user=${user}&progress=${toprogress}&lan=${language}`);
    return;
  }

  // client self process
  if (toprogress === 0 || toprogress === 5) {
    if (toprogress === 0) {
      //ChangePage(`/TimeTableWithStyle?game=${game}&user=${user}&progress=${toprogress}&lan=${language}`);
      ChangePage(`/timetable?game=${game}&user=${user}&progress=${toprogress}&lan=${language}`);
    } else if (toprogress === 5) {
      // betting to game
      console.log('href1 : ' + window.location.href);
      //window.location.href = `/gameresultwithstyle?game=${game}&user=${user}`;//`/result?game=${game}&user=${user}`;
      //navigate(`/gameresultwithstyle`, { state: state });
      //ChangePage(`/gameresultwithstyle?game=${game}&user=${user}&progress=${toprogress}&lan=${language}`);
      ChangePage(`/gameresult?game=${game}&user=${user}&progress=${toprogress}&lan=${language}`);
      console.log('href2 : ' + window.location.href);
    }
    return;
  }

  var url = GetAddHttpURL(); //config.GetBetServerURL();
  fetch(`${url}/api/v1/raceprogress?id=${game}`, {
    method: 'GET', // 또는 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    //body: JSON.stringify(params),
  })
    .then(response => {
      return response.json();
    })
    .then(data => {
      //console.log(data);

      if (data.Progress >= toprogress) {
        if (toprogress === 3 || toprogress === 4)
          // betting to game
          //window.location.href = `/game?game=${game}&user=${user}&progress=${toprogress}`;
          //navigate(`/game`, { state: state });
          ChangePage(`/game?game=${game}&user=${user}&progress=${toprogress}&lan=${language}`);
      }
    })
    .catch(e => {
      console.log(e);
    });
};

function ChangePage(url) {
  var urls = url.split('?');
  var urlhead = urls[0];
  var urlparam = urls[1];

  var urlparams = urlparam.split('&');
  var game = urlparams[0].split('=')[1];
  var user = urlparams[1].split('=')[1];
  var progress = urlparams[2].split('=')[1];
  var lan = urlparams[3].split('=')[1];

  var urlparam = {
    game: { game },
    user: { user },
    progress: { progress },
    lan: lan,
  };
  setCookie('urlparam', urlparam, {
    path: '/', // 모든 경로에 적용
    maxAge: 10 * 60, // 10m 유지
    ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
  });

  window.location.href = urlhead;
}

export const GetAddHttpURL = () => {
  var fullurl = config.GetPrefixURL() + config.GetBetServerURL();
  return fullurl;
};
