import axios from 'axios';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { GetBetServerURL, GetPrefixURL } from '../../../../config';
import BettingHistoryBall from './BettingHistoryBall';
import { usePowerBallResultTrigger } from '@/store/powerball';

type Props = {
  historyTypeIndex: number;
};

type BettingHistoryItem = {
  ball: number[];
  idGame: number;
  index: number;
  powerBall: number;
};

export type HistoryBallItem = {
  color: string;
  content: string;
};

const protocol = GetPrefixURL();
const serverUrl = GetBetServerURL();

export default function BettingHistoryTable({ historyTypeIndex }: Props) {
  const [historyData, setHistoryData] = useState<BettingHistoryItem[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { trigger } = usePowerBallResultTrigger();

  const SetEmptyToWhite=(historyLists:any)=>{
    // let historyLists = data.list;

    const oneLineCount = 6;
    let modCount = historyLists.length % oneLineCount;
    let addEmptyCount = oneLineCount - historyLists.length % oneLineCount;
    
    if (modCount === 0)
      return historyLists;
    
    for(let i = 0; i < addEmptyCount; ++i)
    {
      let history : BettingHistoryItem = {
        ball : [0,0,0,0,0],
        index : 0,//historyLists.length+1,
        powerBall : 0,
        idGame : -1,
      };                        
      historyLists.push(history);
    }
 
    return historyLists;
  }

  useEffect(() => {
    setLoading(true);

    (async function () {
      try {
        const { data } = await axios.get<{ list: BettingHistoryItem[] }>(
          `${protocol}${serverUrl}/api/v1/powerballhistory`
        );
        
        let historyLists = SetEmptyToWhite(data.list);        
        setHistoryData(historyLists);
      } catch (error) {
        console.log(error);
        setError(!!error);
      } finally {
        setLoading(false);
      }
    })();
  }, [trigger]);

  useLayoutEffect(() => {
    if (!scrollRef.current) return;
    const containerScrollWidth = scrollRef.current.scrollWidth;

    if (scrollRef.current.scrollLeft !== containerScrollWidth) {
      scrollRef.current.scrollTo(containerScrollWidth, 0);
    }
  }, [historyTypeIndex, historyData]);

  return (
    <div className="">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>Error</div>
      ) : historyData.length > 0 ? (
        <div className="w-[1000px] px-5 py-2 mb-3 max-[1024px]:max-w-3xl max-[768px]:max-w-xl max-[576px]:max-w-lg max-[512px]:max-w-sm max-[384px]:max-w-xs ">
          <div ref={scrollRef} className="flex overflow-x-hidden overflow-y-hidden  rounded-md">
            <div className="wrapper grid grid-rows-6 grid-flow-col bg-#3F2254">
              {historyData.map((historyItem, i) => (
                <BettingHistoryBall
                  key={i}
                  round={historyItem.index}
                  {...convertBettingHistoryItem(historyItem, historyTypeIndex)}
                />
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const convertBettingHistoryItem = (
  bettingHistoryItem: BettingHistoryItem,
  historyTypeIndex: number
): HistoryBallItem => {
  const ballsSum = bettingHistoryItem.ball.reduce((acc, curr) => acc + curr, 0);
  if(bettingHistoryItem.idGame === -1)
  {
    return {
      content: '',
      color: 'bg-white-500',
    };
  }

  if (historyTypeIndex === 1) {
    return {
      content: ballsSum % 2 === 1 ? 'O' : 'E',
      color: ballsSum % 2 === 1 ? 'bg-red-500' : 'bg-blue-500',
    };
  } else if (historyTypeIndex === 2) {
    return {
      content: ballsSum > 77 ? 'O' : 'U',
      color: ballsSum > 77 ? 'bg-blue-500' : 'bg-red-500',
    };
  } else if (historyTypeIndex === 3) {
    return {
      content: ballsSum < 70 ? 'S' : ballsSum < 86 ? 'M' : 'L',
      color: ballsSum < 70 ? 'bg-red-500' : ballsSum < 86 ? 'bg-blue-500' : 'bg-yellow-500',
    };
  } else {
    return {
      content: bettingHistoryItem.powerBall % 2 === 1 ? 'O' : 'E',
      color: bettingHistoryItem.powerBall % 2 === 1 ? 'bg-red-500' : 'bg-blue-500',
    };
  }
};
