import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';

import { LotteryTicket } from '@/store/powerball';
import { usePowerBallLotteryStore } from '@/store/powerball';
import useLang from '../../../../lib/hook/useLang';

import TicketBg from '../../../../img/powerball/draws_ticket.png';
import { useSelector } from 'react-redux';

const ButtonBox = tw.div`
  space-y-3 h-fit
  w-[380px] max-[1440px]:w-full
`;

type Props = {
  round: number;
  ticket: undefined | LotteryTicket;
};

export default function LotteryTicketCard() {
  const { selectedTicketList } = usePowerBallLotteryStore();
  const [lottoLists, setLottoLists] = useState<
    {
      balls: number[];
      powerball: number;
    }[]
  >([]);
  const { lngT } = useLang('powerball');
  const curState = useSelector((state: any) => state.curState);
  const powerBallState = useSelector((state: any) => state.Powerball);
  const [whiteBall, setWhiteBall] = useState<number[]>([]);
  const [powerBall, setPowerBall] = useState<number>();

  useEffect(() => {
    if (selectedTicketList === undefined) return;

    const lottoList = selectedTicketList.map((item, index) => ({
      balls: item?.balls || [],
      powerball: item?.powerBall || 0,
    }));

    setLottoLists(lottoList);

    setWhiteBall([]);
    setPowerBall(0);
  }, [selectedTicketList]);

  useEffect(() => {
    if (powerBallState !== undefined) {
      if (curState <= 8) {
        const maxcurState = Math.min(curState,4)
        setWhiteBall(powerBallState.ball.slice(0,maxcurState));
      }
      if (curState >= 6) {
        setPowerBall(powerBallState.powerBall);
      }
    }    
  }, [curState]);

  console.log('powerBallState', powerBallState);
  console.log('curState', curState);

  return (
    <ButtonBox>
      <div className="mx-auto w-[90%] h-[300px] relative ">
        <img className="absolute top-0 left-1/2 -translate-x-1/2 w-fit h-[300px] z-1" alt="TicketBg" src={TicketBg} />
        <div className="absolute w-[300px] mx-auto  bottom-6 left-1/2 -translate-x-1/2 z-2 h-[210px] space-y-2 custom-scrollbar overflow-y-auto overflow-x-hidden max-[420px]:bottom-5">          
          <div className="w-full py-2 h-[210px] flex flex-col justify-start items-center gap-0 max-[480px]:w-[230px] mx-auto max-[386px]:py-1">
            {selectedTicketList.length >= 1 ? (
              lottoLists
                .map((items: any, i) => {
                  const label = String.fromCharCode(65 + i);
                  return (
                    <div className="px-8 py-1 max-[680px]:px-4 max-[555px]:px-2 flex flex-row justify-evenly items-center gap-2 font-medium">
                      <div className="text-white max-[360px]:text-xs">{label}</div>
                      {items.balls &&
                        items.balls.map((item: number) => {
                          return (
                            <div
                              className="w-8 h-8 rounded-full flex flex-col items-center justify-center max-[386px]:h-6 max-[386px]:w-6 "
                              style={{
                                background: 'radial-gradient(70.71% 70.71% at -0.00% 120.71%, white 0%, #D7D7D7 100%)',
                                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12) inset',
                              }}
                            >
                              <div className="z-10 relative bg-white w-5 h-5 text-sm rounded-full flex flex-row justify-center items-center max-[386px]:text-xs max-[386px]:h-4 max-[386px]:w-4">
                                {whiteBall.includes(item) ? (
                                  <img
                                    className="absolute -top-3 left-1/2 -translate-x-1/2"
                                    src="/images/icons/check.png"
                                    alt="check"
                                  />
                                ) : (
                                  ''
                                )}
                                {item}
                              </div>
                            </div>
                          );
                        })}
                      <div
                        className="w-8 h-8 rounded-full flex flex-col items-center justify-center max-[386px]:h-6 max-[386px]:w-6 "
                        style={{
                          background: 'radial-gradient(50% 50% at 50% 50%, #FFB1B1 8%, #FFBEBE 14%, #FF2727 95%)',
                          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
                        }}
                      >
                        <div className="z-10 relative bg-white w-5 h-5 rounded-full text-sm flex flex-row justify-center items-center max-[386px]:text-xs max-[386px]:h-4 max-[386px]:w-4">
                          {Number(powerBall) === Number(items.powerball) ? (
                            <img
                              className="absolute -top-3 left-1/2 -translate-x-1/2"
                              src="/images/icons/check.png"
                              alt="check"
                            />
                          ) : (
                            ''
                          )}
                          {
                            items.powerball
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
                .reverse()
            ) : (
              <div className="flex flex-col items-center justify-center mt-8 gap-2 w-[90%]">
                <p className="text-white text-sm text-center">
                  {
                    /* Select 5 WhiteBalls and 1 PowerBall and Click the BuyTicket button to purchase tickets. */ lngT(
                      'lottery:ticket:phrase1'
                    )
                  }
                </p>
                <p className="text-white text-sm text-center">
                  {/* If you press the random button, you can save selection time.*/ lngT('lottery:ticket:phrase2')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </ButtonBox>
  );
}
