import tw from 'tailwind-styled-components';

import useModalStore from './store';

const Container = tw.div`
  fixed top-0 right-0 bottom-0 left-0
  z-[99]
  bg-black/80
  flex justify-center items-center
`;

const Modal = () => {
  const { useCloseDim, closeModal, content } = useModalStore();

  const handleClickDim = () => {
    if (!useCloseDim) return;
    closeModal();
  };

  return <Container onClick={handleClickDim}>{content}</Container>;
};

export default Modal;
