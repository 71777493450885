import React from 'react';
import tw from 'tailwind-styled-components';
import useLang from '../../lib/hook/useLang';

const InputContainer = tw.div`
  flex flex-col space-y-3
`;

const Label = tw.label`
text-white/60 font-sm
`;

const Input = tw.input`
  h-10 w-full rounded-md
  bg-white/10
  text-white/30 text-sm font-light
  pl-2
  placeholder:text-left
  placeholder:text-white/60
  focus:outline-none focus:border-[#860099] focus:ring-[#860099] focus:ring-1 sm:text-sm"
  disabled:text-white
`;

export default function UserEmail({ email }) {
  const { lngT } = useLang('mypage:userInfo');
  return (
    <div>
      <InputContainer className="flex flex-col">
        <Label>{lngT('email')}</Label>
        <div>
          <Input type="text" disabled value={email} />
        </div>
      </InputContainer>
    </div>
  );
}
