import React, { Component } from 'react';
import {Box, Select,Button, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useStyles } from './styles/ShowSingleStyles';
import clsx from "clsx";
import Rate from './Rate';


function createData(name, number, amount) {
  return { name, number, amount};
}

const rows = [
  createData('홀수', '1,3,5,7,9', 10000),
  createData('홀수하', '9,11', 20000),
  createData('홀짝하', '7,9', 30000),
  createData('홀짝상', '1,3,', 40000),
  createData('짝수상', '12 ', 50000),
];


export default function Single(props){
    const classes  = useStyles();
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    return (
        <Box className={classes.root}>
            <Rate/>
           
            <Typography className={classes.labelStyle}>숫자</Typography>
            <Select className={classes.selectStyle} value={age} onChange={handleChange} displayEmpty >
                <MenuItem value="">숫자</MenuItem>
                <MenuItem value={10}>1</MenuItem>
                <MenuItem value={20}>2</MenuItem>
                <MenuItem value={30}>3</MenuItem>
            </Select>

            <Typography className={classes.labelStyle}>금액</Typography>
            <Select className={classes.selectStyle} value={age} onChange={handleChange} displayEmpty >
                <MenuItem value="">금액</MenuItem>
                <MenuItem value={10}>1000</MenuItem>
                <MenuItem value={20}>2000</MenuItem>
                <MenuItem value={30}>3000</MenuItem>
            </Select>

            <Box className={classes.textBoxStyle}>
              <p className={classes.pStyle}>Total bet value : <b>40</b> coin</p>
              <p>Max bet price (1,000 coin)</p>
            </Box>
            
        </Box>
    );
}
