import { useMemo } from 'react';

import useUser from '../lib/hook/useUser';
import useLang from '../lib/hook/useLang';

import * as config from '../config';
import * as define from '../define';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import {GoReelGame} from '../horsestall/progresscombine/jsUtil_combine'

export default function HoverMenu() {
  const { lngT, currentLang } = useLang('menu');
  const navigate = useCustomNavigate();
  const [_, { logout }] = useUser();
  const { lngT : lngTetc } = useLang('etcs');
  const gameRoute = config.TEST_PROGRESS_COMBINE ? '/LobbyGame' : '/timetable';

  const menus = useMemo(
    () =>
      [
        {
          title: lngT('myPage'),
          image: '/images/icons/mypage.svg',
          onClick: () => navigate('/mypage/userinfo'),
        },
        config.TEST_PROGRESS_COMBINE && define.SHOW_METACROSS_ONLY
          ? {
              title: lngT('horse'),
              image: '/images/icons/myhorse.svg',
              onClick: () => navigate('/horse/info'),
            }
          : null,
        {
          title: lngT('racing'),
          image: '/images/icons/racing.png',
          onClick: () => navigate(gameRoute),
        },
        config.ValidContents(define.CONTENTS_REELGAME) && define.SHOW_METACROSS_ONLY
          ? {
              title: 'Slot',
              image: '/images/icons/icon_slot.png',
              // onClick: () => navigate('/reelgame'),
              onClick: () => GoReelGame(lngTetc),
          }
          : null,
        {
          title: lngT('faq'),
          image: '/images/icons/faq.svg',
          onClick: () => navigate('/contact/faq'),
        },
        {
          title: lngT('question'),
          image: '/images/icons/question.svg',
          onClick: () => navigate('/contact/question'),
        },
        {
          title: lngT('freeboard'),
          image: '/images/icons/freeboard.png',
          onClick: () => navigate('/community/freeboard'),
        },
        {
          title: lngT('logout'),
          image: '/images/icons/logout.svg',
          onClick: () => {
            navigate('/');
            logout();
          },
        },
      ].filter(item => item !== null), // null 값 제거
    [currentLang, config.TEST_PROGRESS_COMBINE]
  );

  const { lngT: etclngT } = useLang('landing:footer');
  const powerballMenus = useMemo(
    () =>
      [
        {
          title: lngT('myPage'),
          image: '/images/icons/mypage.svg',
          onClick: () => navigate('/mypage/userinfo'),
        },
        {
          title: lngT('powerball'),
          image: '/images/icons/gamble.png',
          onClick: () => navigate('/powerball/lobbyy'),
        },
        config.ValidContents(define.CONTENTS_REELGAME) && define.SHOW_METACROSS_ONLY
          ? {
              title: 'Slot',
              image: '/images/icons/icon_slot.png',
              // onClick: () => navigate('/reelgame'),
              onClick: () => GoReelGame(lngTetc),
          }
          : null,
        {
          title: etclngT('nav:5:menu:4'),
          image: '/images/icons/freeboard.png',
          onClick: () => navigate('/community/freeboard'),
        },
        {
          title: etclngT('nav:5:menu:5'),
          image: '/images/icons/event.png',
          onClick: () => navigate('/event'),
        },
        {
          title: lngT('faq'),
          image: '/images/icons/faq.svg',
          onClick: () => navigate('/contact/faq'),
        },
        {
          title: lngT('question'),
          image: '/images/icons/question.svg',
          onClick: () => navigate('/contact/question'),
        },
        {
          title: lngT('logout'),
          image: '/images/icons/logout.svg',
          onClick: () => {
            navigate('/');
            logout();
          },
        },
      ].filter(item => item !== null), // null 값 제거
    [currentLang, config.TEST_PROGRESS_COMBINE]
  );

  return (
    <div
      className="w-[150px] pt-[8px] pb-[16px] px-[8px] backdrop-blur-[2px] rounded-md mt-3"
      style={{
        background:
          'linear-gradient(156.67deg, rgba(112, 112, 114, 0.6) 0.07%, rgba(112, 112, 114, 0.6) 0.08%, rgba(100, 97, 113, 0.222) 99.07%)',
      }}
    >
      {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? (
        <ul className="flex flex-col justify-center space-y-[10px] list-none">
          {menus.map(({ title, image, onClick }, i) => (
            <li
              key={i}
              className="flex flex-row items-center p-[4px] space-x-[8px] rounded-md cursor-pointer hover:bg-white/20"
              onClick={onClick}
            >
              <img className="w-[24px]" src={image} alt={title} />
              <div className="text-white text-[14px]">{title}</div>
            </li>
          ))}
        </ul>
      ) : (
        <ul className="flex flex-col justify-center space-y-[10px] list-none">
          {powerballMenus.map(({ title, image, onClick }, i) => (
            <li
              key={i}
              className="flex flex-row items-center p-[4px] space-x-[8px] rounded-md cursor-pointer hover:bg-white/20"
              onClick={onClick}
            >
              <img className="w-[24px]" src={image} alt={title} />
              <div className="text-white text-[14px]">{title}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
