import React, {useEffect, useRef, useState} from 'react';

let audioRefs = {}
let accFileMap = {};
let audioContext = undefined;
let audioMap = {};
let audioPlayMap = {}; // 재생 상태 관리
export function AudioManager()
{

    useEffect( () => {

        async function loadAudios() {
            audioContext = new AudioContext();

            accFileMap = {
                'minusBt': '/assets/sounds/+-button.aac',
                'plusBt': '/assets/sounds/+-button.aac',
                'spinBt': '/assets/sounds/spin_button.aac',
                'stopBt': '/assets/sounds/stop_button.aac',
                'muteBt': '/assets/sounds/sound_on_off_button.aac',
                'wild1': '/assets/sounds/wild1.aac',
                'wild2': '/assets/sounds/wild2.aac',
                'wild3': '/assets/sounds/wild3.aac',
                'itemstop': '/assets/sounds/itemstop.aac',
                'linenormal': '/assets/sounds/linenormal.aac',
                'linefree': '/assets/sounds/linefree.aac',
                'linejackpot': '/assets/sounds/linejackpot.aac',
                'freespinbgm': '/assets/sounds/free_spin_bgm.aac',
                'jackpotonbgm': '/assets/sounds/jackpot_on_bgm.aac',
                'jackpotbgm': '/assets/sounds/jackpot_bgm.aac',
                'normalbgm': '/assets/sounds/normal_bgm.aac'
            }

            const keys = Object.keys(accFileMap);

            for (const key of keys) {
                const source = accFileMap[key];
                const response = await fetch(source);
                const arrayBuffer = await response.arrayBuffer();

                audioPlayMap[key] = false;

                audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
                    // 오디오 소스 노드 생성
                    const sourceNode = audioContext.createBufferSource();
                    sourceNode.buffer = audioBuffer;

                    const isBGM = key.includes('bgm');
                    if(isBGM){
                        sourceNode.loop = true;
                    }

                    // 오디오 컨텍스트의 출력과 연결
                    sourceNode.connect(audioContext.destination);

                    audioMap[key] = sourceNode;

                });
            }

            console.log("audioMap : ", audioMap);
        }

        loadAudios();


        // return () => {
        //     if(audioContext){
        //         audioContext.close();
        //         audioContext = undefined;
        //     }
        // };

    }, [])



    audioRefs = {
        'minusBt' : useRef(null),
        'plusBt' : useRef(null),
        'spinBt' : useRef(null),
        'stopBt' : useRef(null),
        'muteBt' : useRef(null),
        'wild1' : useRef(null),
        'wild2' : useRef(null),
        'wild3' : useRef(null),
        'itemstop' : useRef(null),
        'linenormal' : useRef(null),
        'linefree' : useRef(null),
        'linejackpot' : useRef(null),
        'freespinbgm' : useRef(null),
        'jackpotonbgm' : useRef(null),
        'jackpotbgm' : useRef(null),
        'normalbgm' : useRef(null),
    }

    return (<div>
        <audio ref={audioRefs.minusBt} src={'/assets/sounds/+-button.aac'}/>
        <audio ref={audioRefs.plusBt} src={'/assets/sounds/+-button.aac'}/>
        <audio ref={audioRefs.spinBt} src={'/assets/sounds/spin_button.aac'}/>
        <audio ref={audioRefs.stopBt} src={'/assets/sounds/stop_button.aac'}/>
        <audio ref={audioRefs.muteBt} src={'/assets/sounds/sound_on_off_button.aac'}/>

        <audio ref={audioRefs.wild1} src={'/assets/sounds/wild1.aac'}/>
        <audio ref={audioRefs.wild2} src={'/assets/sounds/wild2.aac'}/>
        <audio ref={audioRefs.wild3} src={'/assets/sounds/wild3.aac'}/>
        <audio ref={audioRefs.itemstop} src={'/assets/sounds/itemstop.aac'}/>

        <audio ref={audioRefs.linenormal} src={'/assets/sounds/linenormal.aac'}/>
        <audio ref={audioRefs.linefree} src={'/assets/sounds/linefree.aac'}/>
        <audio ref={audioRefs.linejackpot} src={'/assets/sounds/linejackpot.aac'}/>

        <audio ref={audioRefs.normalbgm} src={'/assets/sounds/normal_bgm.aac'} loop/>
        <audio ref={audioRefs.freespinbgm} src={'/assets/sounds/free_spin_bgm.aac'} loop/>
        <audio ref={audioRefs.jackpotonbgm} src={'/assets/sounds/jackpot_on_bgm.aac'} loop/>
        <audio ref={audioRefs.jackpotbgm} src={'/assets/sounds/jackpot_bgm.aac'} loop/>

    </div>)
}

// export const audioPlayOnly = (audioRef) => {
//     // 재생 중 인데 같은걸 또 재생 요청 오면 -> 항상 처음 부터 다시 재생.
//     if(audioRef.current !== null){
//         audioRef.current.currentTime = 0;
//         audioRef.current.play().catch(e => console.log("재생 오류 : ", e));
//     } else {
//         console.warn("오디오 ref 없음");
//     }
// }

export const AudioPlay = (audioname, forcereplay, isIphone, isIOSMute) => {
    // 재생 중 인데 같은걸 또 재생 요청 오면 -> 항상 처음 부터 다시 재생.
    const audioRef = audioRefs[audioname];
    if(audioRef.current !== null){
        if(forcereplay || audioRef.current.paused)
        {
            if(isIphone){
                if(!isIOSMute){
                    // audioRef.current.currentTime = 0;
                    // audioRef.current.play().catch(e => console.log("playerror : ", audioname, ' - ', e));
                    // console.log('play audio : ' + audioname);
                    const node = audioMap[audioname];
                    console.log("play 노드 : ", audioname);
                    node.onended = () => onEnded(audioname);
                    const tempBuffer = node.buffer;
                    const newAudioSourceTemp = audioContext.createBufferSource();
                    newAudioSourceTemp.buffer = tempBuffer;
                    newAudioSourceTemp.connect(audioContext.destination);

                    audioPlayMap[audioname] = true;

                    newAudioSourceTemp.start();

                    audioMap[audioname] = newAudioSourceTemp;
                } else {
                    console.log('play audio : IOS Mute');
                }
            } else {
                audioRef.current.currentTime = 0;
                audioRef.current.play().catch(e => console.log("playerror : ", e));
                console.log('play audio : ' + audioname);
            }
        }
    } else {
        console.warn(`has no ${audioname} audio`);
    }
}

export const AudioPlayForBGM = (audioname) => {
    const audioBGMRef = audioRefs[audioname];
    if(audioBGMRef.current !== null){
        if(audioBGMRef.current.paused)
        {
            audioBGMRef.current.currentTime = 0;
            audioBGMRef.current.play().catch(e => console.log("playerror : ", audioname, ' - ', e));
            console.log('play audio bgm : ' + audioname);
        }
    } else {
        console.warn(`has no ${audioname} audio`);
    }
}

export const AudioPlayForBGMIOS = (audioname) => {
    const node = audioMap[audioname];

    if(node){
        console.log("play BGM 노드 : ", audioname);
        // node.onended = () => onEnded(audioname); // 루프니까 할필요 없음
        const tempBuffer = node.buffer;
        const newAudioSourceTemp = audioContext.createBufferSource();
        newAudioSourceTemp.buffer = tempBuffer;
        newAudioSourceTemp.connect(audioContext.destination);
        newAudioSourceTemp.loop = true;

        audioPlayMap[audioname] = true;

        newAudioSourceTemp.start();

        audioMap[audioname] = newAudioSourceTemp;
    } else {
        console.warn(`has no ${audioname} audio node`);
    }
}

export const AudioStopForBGM = (audioname) => {
    const audioBGMRef = audioRefs[audioname];
    if(audioBGMRef.current !== null){
        audioBGMRef.current.currentTime = 0;
        audioBGMRef.current.pause();
        console.log('pause audio bgm : ' + audioname);
    } else {
        console.warn(`has no ${audioname} audio`);
    }
}

export const AudioStopForBGMIOS = (audioname) => {
    const node = audioMap[audioname];
    if(node){
        console.log("stop BGM 노드 : ", audioname);
        audioPlayMap[audioname] = false;

        node.stop();
    } else {
        console.warn(`has no ${audioname} audio node`);
    }
}

export const AudioAllStop = () => {
    const audioKeys = Object.keys(audioRefs);
    audioKeys.forEach(key => {
        const audioRef = audioRefs[key];
        if(audioRef.current !== null){
            audioRef.current.pause();
            audioRef.src = "";
        }
    })
}

export const MuteAll = () => {
    const audioKeys = Object.keys(audioRefs);
    audioKeys.forEach(key => {
        const audioRef = audioRefs[key];
        if(audioRef.current !== null){
            audioRef.current.volume = 0;
        }
    })
};

export const unMuteAll = () => {
    const audioKeys = Object.keys(audioRefs);
    audioKeys.forEach(key => {
        const audioRef = audioRefs[key];
        if(audioRef.current !== null){
            audioRef.current.volume = 1;
        }
    })
}

export const MuteForIOS = () => {
    const keys = Object.keys(audioMap);
    keys.forEach(key => {
        const node = audioMap[key];
        audioPlayMap[key] = false;
        if(node){
            // console.log("노드 mute : ", audioPlayMap[key], key);
            // audioPlayMap[key] = false;
            try {
                node.stop();
            } catch (e){

            }
        }

    })
}

export const audioContextClear = () => {
    Object.values(audioMap).forEach(sourceNode => {
        try {
            if (sourceNode){
                sourceNode.stop();
                sourceNode.disconnect();
            }
        } catch (e){}
    });

    if(audioContext){
        audioContext.close();
        audioContext = undefined;
    }
}

const onEnded = (keyValue) => {
    audioPlayMap[keyValue] = false;
}

export const IsPlaying=(audioname)=>{
    const audioRef = audioRefs[audioname];
    return (audioRef.current !== null && !audioRef.current.paused)
}

// 사실상 IOS - BGM 관련
export const IsPlayingIOS=(audioname)=>{
    return audioPlayMap[audioname];
}
