import styles from './videoOverlay.module.css';
import Screen from '@/img/landingPage/screen.png';

function VideoOverlay() {
  return (
    <div className={styles.container}>
      <img src={Screen} alt="Background" className="relative h-[100%] w-[100%] " />
      <div className="absolute h-[350px] w-[500px] ">
        <iframe
          src="https://www.youtube.com/embed/BZIssQ_1iV4?autoplay=1"
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={styles.video}
        ></iframe>
      </div>
    </div>
  );
}

export default VideoOverlay;
