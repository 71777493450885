import HowToDoBet from "./Bet";
import styles from "./BoxHowToDo.module.css"
import HowToDoRent from "./Rent";

const BoxHowToDoSection = () => {
    return (
        <section className={styles.container}>
            <HowToDoBet />
            <HowToDoRent />
        </section >
    )
}

export default BoxHowToDoSection;