import { useState, useEffect } from 'react';
import Modal from 'react-modal';

import { usePowerBallLotteryStore } from '@/store/powerball';
import ResultBg from '../../img/powerball/result.png';
import { useSelector } from 'react-redux';
import { getCookie, setCookie } from '../../lib/cookie';

// let powerballinfo = null;
export default function NumberResultModal({ isOpen, round, lotteryResult }) {
  const { selectedTicketList, setSelectedTicketList } = usePowerBallLotteryStore();
  const [lottoResults, setLottoResults] = useState([]);
  const [copiedSelectedTicketList, setCopiedSelectedTicketList] = useState([]);
  const powerBallState = useSelector(state => state.Powerball);
  const [whiteBall, setWhiteBall] = useState([]);
  const [powerBall, setPowerBall] = useState();

  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      zIndex: 1000,
    },
    content: {
      zIndex: 1001,
    },
  };

  useEffect(() => {
    if (selectedTicketList === undefined) return;

    // const copiedSelectedTicketList = Array.from(selectedTicketList);
    // setCopiedSelectedTicketList(copiedSelectedTicketList);

    const lottoResult = selectedTicketList.map((item, index) => ({
      place: lotteryResult[index]?.place,
      reward: lotteryResult[index]?.reward,
      balls: item?.balls,
      powerball: item?.powerBall,
    }));
    setLottoResults(lottoResult);
    setSelectedTicketList([]);

    setWhiteBall(powerBallState.ball);
    setPowerBall(powerBallState.powerBall);
  }, [lotteryResult]);

  // useEffect(() => {
  //   if(powerBallState)
  //   {
  //     powerballinfo = getCookie('prevgameinfo');
  //     powerballinfo = powerballinfo === "null" ? null : powerballinfo;
  //     if(powerballinfo)
  //       console.log('gameframe : ' + powerballinfo.gameframe + ' ' + powerBallState.gameframe);
  //   }
  // }, [powerBallState]);

  return (
    <Modal
      isOpen={isOpen}
      className="absolute w-[800px] h-[600px] max-[900px]:w-[90%] max-[900px]:h-[400px] max-[600px]:h-[320px] max-[450px]:h-[220px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl"
      style={modalStyle}
    >
      <img alt="bg" src={ResultBg} className="w-full h-full absolute top-0 left-0 object-fit" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex flex-col justify-start py-2 max-[900px]:py-1 max-[600px]:py-0 items-center w-full h-[100%] space-y-9 max-[450px]:space-y-1 pb-[80px] max-[450px]:w-[90%]">
        <h2 className="text-white text-3xl font-medium max-[900px]:text-xl max-[786px]:text-lg max-[500px]:text-base max-[450px]:text-sm pb-1">
          Result
        </h2>
        <div className="flex flex-col justify-start items-center space-y-2 max-[450px]:space-y-1 max-[320px]:space-y-0 text-white h-[460px] max-[900px]:h-[360px] max-[600px]:h-[280px] max-[450px]:h-[190px] max-[450px]:w-[320px] max-[320px]:items-center max-[320px]:w-[240px] overflow-y-auto custom-scrollbar  overflow-x-hidden ">
          <div className="flex flex-row justify-normal items-center gap-2">
            <div className="flex flex-row gap-2">
              {whiteBall &&
                whiteBall.map(ball => {
                  return (
                    <div className="w-7 h-7 flex items-center justify-center rounded-full bg-white text-black border border-[#eee] max-[600px]:text-sm max-[600px]:w-4 max-[600px]:h-4 max-[360px]:text-xs">
                      {ball}
                    </div>
                  );
                })}
            </div>
            <div className="w-7 h-7 flex items-center justify-center rounded-full bg-red-500 border border-red-500 max-[600px]:text-sm max-[600px]:w-4 max-[600px]:h-4 max-[360px]:text-xs">
              {powerBall}
            </div>
          </div>
          <div class="absoulte top-0 z-10 w-full max-[360px]:w-[230px] h-fit flex items-start justify-center max-[360px]:justify-start my-2 max-[360px]:my-0">
            <table class="w-full text-sm max-[555px]:text-xs text-left rtl:text-right text-white font-normal space-y-2 ">
              <thead class="uppercase text-white/80 pb-4">
                <tr>
                  <th
                    scope="col"
                    class="px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:w-2  max-[450px]:hidden"
                  >
                    Round
                  </th>
                  <th scope="col" class="text-center px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:text-xs">
                    Lotto Number
                  </th>
                  <th
                    scope="col"
                    class="text-center px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:px-0 max-[450px]:text-xs"
                  >
                    Place
                  </th>
                  <th
                    scope="col"
                    class="text-center px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:px-0 max-[450px]:text-xs"
                  >
                    Reward
                  </th>
                </tr>
              </thead>
              {lottoResults?.map(items => {
                return (
                  <tbody>
                    <tr class="border-b border-white/20 w-full max-[555px]:text-xs">
                      <td class="px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:w-2 text-white max-[450px]:hidden">
                        {round}
                      </td>
                      <td class="px-8 py-2 max-[680px]:px-4 max-[555px]:px-1 flex flex-row justify-evenly items-center gap-2 font-medium max-[486px]:gap-1 max-[486px]:px-0">
                        {items.balls &&
                          items.balls.map((item, j) => {
                            return (
                              <div
                                key={j}
                                className="relative w-8 h-8 max-[680px]:w-6 max-[680px]:h-6 max-[555px]:w-4 max-[555px]:h-4  rounded-full flex flex-col items-center justify-center roudned-full bg-gray-400 text-white"
                              >
                                {whiteBall.includes(item) ? (
                                  <img
                                    className="absolute -top-4 left-1/2 -translate-x-1/2 "
                                    src="/images/icons/check.png"
                                    alt="check"
                                  />
                                ) : (
                                  ''
                                )}
                                {item}
                              </div>
                            );
                          })}
                        <div className="w-8 h-8 max-[680px]:w-6 max-[680px]:h-6 max-[555px]:w-4 max-[555px]:h-4 rounded-full flex flex-col items-center justify-center text-white roudned-full bg-red-500 border border-red-500 relative">
                          {powerBall === items.powerball ? (
                            <img
                              className="absolute -top-4 left-1/2 -translate-x-1/2"
                              src="/images/icons/check.png"
                              alt="check"
                            />
                          ) : (
                            ''
                          )}
                          {items.powerball}
                        </div>
                      </td>
                      <td class="px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:px-0 text-center">
                        {items.place ?? '-'}
                      </td>
                      <td class="px-8 py-2 max-[680px]:px-4 max-[555px]:px-2 max-[450px]:px-0 text-center">
                        {items.reward ?? '-'}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
}
