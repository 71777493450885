export function formatDateToLocal(date: Date) {
  const localDate = new Date(date);

  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0');
  const day = String(localDate.getDate()).padStart(2, '0');
  const hours = String(localDate.getHours()).padStart(2, '0');
  const minutes = String(localDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export const getTime = () => {
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const optionDate: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
  const formattedDate = today.toLocaleDateString('en-Us', optionDate);
  const optionDay: Intl.DateTimeFormatOptions = { weekday: 'short' };
  const formattedDay = today.toLocaleDateString('en-US', optionDay);
  const formattedToday = formattedDate.replace(/ /g, ' / ').replace(',', '') + ' / ' + formattedDay;

  return formattedToday;
};

export function maskString(str: string, num: number, direction: 'left' | 'right'): string {
  if (str.length <= num) {
    return '*'.repeat(str.length);
  }

  if (direction === 'left') {
    return '*'.repeat(num) + str.substring(num);
  }

  if (direction === 'right') {
    return str.substring(0, str.length - num) + '*'.repeat(num);
  }

  return str.substring(0, str.length - num) + '*'.repeat(num);
}

export function formatToBrazilTime(utcDate: Date) {
  const brazilTime = new Date(utcDate.toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }));
  const day = brazilTime.getDate().toString().padStart(2, '0');
  const month = (brazilTime.getMonth() + 1).toString().padStart(2, '0');
  const year = brazilTime.getFullYear().toString().slice(-2);

  return `${day}. ${month}. ${year}`;
}

export const fetcher = (url: string) => fetch(url, { credentials: 'include' }).then(r => r.json());
