import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& *, & .MuiTypography-root': {
      fontFamily: '"Poppins", sans-serif!important',
      margin: 'auto',
    },
    '& .MuiPaper-root': {
      background: 'transparent',
      margin: '0px 0',
    },
  },
  buttonClose: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      top: 8,
      right: 8,
    },
  },
  dialogStyle: {
    width: '550px',
    padding: '32px 16px',
    background: 'rgba(0, 0, 0, 0.9)',
    color: '#fff',
    border: '2px solid rgba(255, 255, 255, 0.5)',
    borderRadius: 16,
    '& .MuiDialogContent-root': {
      padding: '0 16px',
      margin: '0px 0',
      fontSize: '1.25rem',
    },
    '& .MuiDialogActions-root': {
      padding: '32px 16px 0 16px',
      justifyContent: 'center',
      gap: '22px',
      margin: 'auto',
      display: 'flex',
    },
    '@media (min-width:280px) and (max-width:667px)': {
      width: '280px',
      '& .MuiDialogContent-root': {
        padding: '8px 16px 0 16px',
        margin: '0px 0',
      },
      '& .MuiDialogActions-root': {
        padding: '8px 16px 0 16px',
        gap: '12px',
      },
    },
  },
  objectImgStyle: {
    display: 'flex',
    alignItems: 'center',
    color: '#C4F322',
    justifyContent: 'center',
    '@media (min-width:280px) and (max-width:667px)': {
      '& svg': {
        color: '#C4F322',
        width: 48,
        height: 48,
      },
    },
  },
  titleStyle: {
    '&.MuiTypography-root': {
      color: '#C4F322',
      fontSize: '2.0rem',
      fontWeight: 600,
      paddingTop: '10px',
      textAlign: 'center',
      '@media (min-width:280px) and (max-width:667px)': {
        fontSize: '2rem',
      },
    },
  },
  childrenStyle: {
    fontSize: '1.25rem',
    textAlign: 'center',
    marginTop: 16,
    margine: 'auto',
    padding: '0 8px',
    '& .MuiTypography-root': {
      fontSize: '1.25rem',
      width: '400px',
    },
    '@media (min-width:280px) and (max-width:667px)': {
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
        width: '200px',
        paddingBottom: '10px',
      },
    },
  },
  cancelButton: {
    '&.MuiButton-root': {
      minWidth: 174,
      padding: 12,
      fontSize: '0.875rem',
      textTransform: 'none',
      color: '#000',
      background: '#fff',
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
      border: '2px solid transparent',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: '#ccc',
      },
      '@media (min-width:280px) and (max-width:667px)': {
        minWidth: 'auto',
        width: 132,
        height: 40,
      },
    },
  },
  submitButton: {
    '&.MuiButton-root': {
      minWidth: 174,
      padding: 12,
      fontSize: '0.875rem',
      textTransform: 'none',
      background: '#7C9B3F',
      color: '#fff',
      borderRadius: 8,
      '&:hover': {
        backgroundColor: '#546739',
      },
      '@media (min-width:280px) and (max-width:667px)': {
        minWidth: 'auto',
        width: 132,
        height: 40,
      },
    },
  },
}));
