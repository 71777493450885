import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useNavigate, useSearchParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import useLang from '../../lib/hook/useLang';

import TextInputWrapper from '../../components/TextInputWrapper';
import PrimaryButton from '../../components/PrimaryButton';
import { ReactComponent as BackIcon } from '../../assets/icons/arrow-back.svg';
import axios from 'axios';
import { toast } from 'react-toastify';

const Container = tw.section`
  flex flex-col justify-center
  w-[400px]
`;

const Title = tw.div`
  font-semibold text-[30px]
  mt-[20px] mb-[20px]
`;

export default function ResetPassword() {
  const navigate = useNavigate();
  const { lngT } = useLang('auth:password');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { data } = useSWR(`${process.env.REACT_APP_API_URL}/auth/validate?token=${token}`);

  const [userPassword, setUserPassword] = useState('');
  const [userPasswordConfirm, setUserPasswordConfirm] = useState('');

  const [passwordError, setPasswordError] = useState(' ');
  const [passwordConfirmError, setPasswordConfirmError] = useState(' ');
  const [isLoading, setLoading] = useState(false);

  const handleChangePassword = e => {
    setUserPassword(e.target.value);
    var reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

    if (!reg.test(e.target.value)) {
      return setPasswordError(lngT('error:1'));
    }
    setPasswordError(undefined);
  };

  const handleChangePasswordConfirm = e => {
    setUserPasswordConfirm(e.target.value);

    if (userPassword !== e.target.value) {
      return setPasswordConfirmError(lngT('error:2'));
    }
    setPasswordConfirmError(undefined);
  };

  const handleClickResetPassword = async () => {
    if (!data || !data.ok) return;
    const email = data.email;
    setLoading(true);

    try {
      const payload = {
        email,
        userPassword,
        token,
      };
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/resetPassword`, payload);

      if (data.ok) {
        toast(lngT('reset:message:success'), { theme: 'dark', type: 'success' });
        navigate('/login');
      }
    } catch (error) {
      toast(lngT('reset:message:fail'), { theme: 'dark', type: 'error' });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.error) {
      alert(lngT('reset:expired'));
      navigate('/password/forgot');
    }
  }, [data, lngT]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container>
        <BackIcon className="cursor-pointer" onClick={() => navigate('/login')} />
        <Title>{lngT('reset:title')}</Title>
        <form className="flex flex-col space-y-10">
          <TextInputWrapper title={lngT('reset:newPassword')} errorText={passwordError}>
            <input
              type="password"
              value={userPassword}
              maxLength={15}
              placeholder={lngT('type:1')}
              autoComplete="off"
              onChange={handleChangePassword}
            />
          </TextInputWrapper>
          <TextInputWrapper title={lngT('reset:passwordConfirm')} errorText={passwordConfirmError}>
            <input
              type="password"
              value={userPasswordConfirm}
              maxLength={15}
              placeholder={lngT('type:2')}
              autoComplete="off"
              onChange={handleChangePasswordConfirm}
            />
          </TextInputWrapper>
          <PrimaryButton
            type="button"
            title={lngT('reset:title')}
            isLoading={isLoading}
            error={passwordError || passwordConfirmError}
            className="mt-[40px]"
            onClick={handleClickResetPassword}
          />
        </form>
      </Container>
    </div>
  );
}
