import React, { useState, useEffect } from 'react';
import {GoReelGame} from "../horsestall/progresscombine/jsUtil_combine";
import useLang from '../lib/hook/useLang';
const frameWidth = 293; // 프레임의 가로 크기
const frameHeight = 113; // 프레임의 세로 크기
const framesPerRow = 6; // 한 줄에 있는 프레임 수 (가로)
const framesPerCol = 11; // 세로 있는 프레임 수 (세로)
const animationSpeed = 80; // ms 단위, 100이면 100/1000 초에 한번 애니메이션 교체

// function getFramePosition(frameIndex) {
//     const x = -(frameIndex % framesPerRow) * frameWidth * scale;
//     const y = -Math.floor(frameIndex / framesPerRow) * frameHeight * scale;
//     return { x, y };
// }

function SlotSpriteButton(props) {
    const {isMobile, isLogin, navigate} = props;
    const [frameIndex, setFrameIndex] = useState(0);
    const scale = isMobile ? 0.3 : 0.5;
    const { lngT : lngTetc } = useLang('etcs');

    function getFramePosition(frameIndex) {
        const x = -(frameIndex % framesPerRow) * (frameWidth * scale);
        const y = -Math.floor(frameIndex / framesPerRow) * (frameHeight * scale);
        return { x, y };
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setFrameIndex((currentFrame) => (currentFrame + 1) % (framesPerRow * framesPerCol - 5)); // 총 프레임 수 : 가로 * 세로 - 이미지 맨마지막 열 이빨빠진 갯수
        }, animationSpeed); // 애니메이션 속도 조절

        return () => clearInterval(interval);
    }, []);

    const { x, y } = getFramePosition(frameIndex);

    const totalWidth = frameWidth * framesPerRow; // 전체 스프라이트 시트의 가로 크기
    const totalHeight = frameHeight * framesPerCol;

    return (
        <div
            onClick={isLogin ? () => GoReelGame(lngTetc) : () => navigate('login')}
            style={{
                marginTop: '8px',
                cursor: 'pointer',
                width: `${frameWidth * scale}px`,
                height: `${frameHeight * scale}px`,
                backgroundImage: 'url(/images/landing/reel/slot_button_sprite.webp)',
                backgroundPosition: `${x}px ${y}px`,
                backgroundSize: `${totalWidth * scale}px ${totalHeight * scale}px`
            }}
        />
    );
}

export default SlotSpriteButton;
