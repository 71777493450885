import { makeStyles } from "@material-ui/styles";


export const useStylesNotify = makeStyles(theme => ({
    notify:{
        marginTop:20,
        padding:8,
        backgroundColor:'#00000036',
        color:'#ffffffd9',
        border:'2px solid transparent',
        borderImage:'linear-gradient(to right, #ffe53b 0%, #00ffff 100%)',
        borderImageSlice:1,
        '& .MuiTypography-subtitle1':{
            display:'flex',
            alignItems:'center',
            marginBottom:6,
            fontSize:'0.875rem',
            '& > svg':{
                marginRight:6,
                width:20,
                height:20,
            }
        },
        '&  .MuiTypography-body2':{
            color:'#ffffffb3',
            fontSize:'0.813rem',
        }
    },
}));