import React, { Component, useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import { Box, Container, Typography, LinearProgress } from '@mui/material';
import {
  Button,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import clsx from 'clsx';
import Notify from '../../components/betting/Notify';

import { useBettingStyles } from '../styles/BettingStyles';
import RandomBoard from '../../components/betting/RandomBoard'; //'./OddEven';
import OddEven from '../../components/betting/OddEven'; //'./OddEven';
import Single from '../../components/betting/Single'; //'./Single';
import Show from '../../components/betting/Show'; //'./Show';
import { ReactComponent as StopwatchIcon } from '../../img/StopwatchIcon.svg';

import { useLocation } from 'react-router';
import { ChangeProgress } from '../../util/jsUtil';

import { getCookie } from '../../lib/cookie';
import useLang from '../../lib/hook/useLang'; //'../lib/hook/useLang';
import { ChangeLanguage, Translation } from '../../components/Translation';

import i18n from '../../lang/i18n';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useStylesNotify } from '../styles/NotifyComponentStyles';

import { ReactComponent as BettingLine } from '../../assets/bettingLine.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as HorseListTitleGradient } from '../../assets/horseListTitleGradient.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';
import { ReactComponent as WarningIcon } from '../../img/WarningIcon.svg';
import { ReactComponent as SuccessFilledIcon } from '../../img/SuccessFilledIcon.svg';
// import { ReactComponent as BettingIcon } from '../../img/BettingIcon.svg';

import CommonDialog from '../../components/betting/CommonDialog-v1';
import * as config from '../../config';

var { webSocketIO } = require('../../socketClient.js');

var _resUserInitCallback = { isconfirm: false, endcallback: undefined };

var customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function NotifyComponent(props) {
  const classes = useStylesNotify();

  return (
    <Box
      className={classes.notify}
      style={{
        marginTop: 10,
      }}
    >
      <Typography variant={'body2'}>
        <ErrorOutlineRoundedIcon />
        {props.title}
      </Typography>
      <Typography variant={'caption'} display={'block'}>
        {props.content}
      </Typography>
    </Box>
  );
}

export default function RandomBetting(props) {
  const { lngT } = useLang('racing:intro');

  const classes = useBettingStyles();
  const classesBetting = useBettingStyles();

  const [newMsg, setNewMsg] = React.useState(0);
  const [, updateState] = React.useState([]);
  const { state } = useLocation();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [counter, setCounter] = React.useState(60);

  const [connected, setConnected] = useState(false);

  // const [selectOddEven, setSelectOddEven] = useState(0);
  // const [selectDigit, setSelectDigit] = useState(0);
  // const [selectShowDigit, setSelectShowDigit] = useState(0);

  const [selectedinfo, setselectedinfo] = useState({
    type: 0,
    index: 0,
    value: 0,
    currency: 'coin',
  });

  const [bettingValue, setBettingValue] = React.useState('');

  const showResultPopup = memo => {
    setModal({ comfirmModal: true, confirmMemo: memo });

    setTimeout(() => {
      setModal({ comfirmModal: false });
    }, 1000);
  };

  var count = 0;
  const addCount = () => {
    setNewMsg(++count);
  };

  const [modal, setModal] = useState({
    showModal: false,
    comfirmModal: true,
    confirmMemo: 'connecting...',
  });

  // betting popup cancel
  const handleCloseModal = () => {
    setModal({ showModal: false, comfirmModal: false });
  };
  const handleComfirmModal = () => {
    setModal({ comfirmModal: true, confirmMemo: 'betting...' });
    callbetting(
      selectedinfo.type,
      selectedinfo.index,
      selectedinfo.value,
      selectedinfo.currency,
      true,
      showResultPopup
    );
  };
  const handleConfirmCloseModal = () => {
    setModal({ comfirmModal: false });
  };

  function handleSetModal(state, selinfo) {
    setModal(state);
    setselectedinfo(selinfo);
  }

  const callbetting = (type, index, value, currency, isconfirm, endcallback) => {
    webSocketIO.send(
      JSON.stringify({
        protocol: 'reqBetting',
        type: type,
        no: index,
        value: value,
        currency: currency,
      })
    );

    _resUserInitCallback.isconfirm = isconfirm;
    _resUserInitCallback.endcallback = endcallback;
  };

  const [openBetDialog, setOpenBetDialog] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleClose = () => {
    //setOpenHashDialog(false);
    setOpenBetDialog(false);
    setOpenSuccess(false);
  };

  const oddEvenTitle = lngT('footer:guide:oddEven:title');
  const oddEvenContent = lngT('footer:guide:oddEven:description');
  const singleTitle = lngT('footer:guide:single:title');
  const singleContent = lngT('footer:guide:single:description');
  const showTitle = lngT('footer:guide:show:title');
  const showContent = lngT('footer:guide:show:description');

  function onMessage(e) {
    console.log(e.data, 'Message received');

    var data = JSON.parse(e.data);

    if (data.protocol === 'resBetting') {
      //test
      setBettingValue('');
      //(data.betting !== undefined) {
      var total = webSocketIO.data.total[data.type];
      //total += data.value;
      webSocketIO.data.total[data.type] = data.total;
      var index = data.no;
      if (data.idUser === webSocketIO.data.idUser) {
        webSocketIO.data.listRow[data.type][index].betting = data.value;
        //webSocketIO.data.listRow[data.type][index].currency = data.currency;
      }
      if (_resUserInitCallback.endcallback != undefined && data.error != undefined) {
        var resMsg = data.error;
        if (resMsg == 'notEnoughCoin') {
          resMsg = lngT(`contents:notEnoughCoin`);
        }
        _resUserInitCallback.endcallback(resMsg);
        return;
      }

      for (var n = 0; n < webSocketIO.data.listRow[data.type].length; n++) {
        //if (webSocketIO.data.listRow[data.type][n].betting > 0) {
        //  webSocketIO.data.listRow[data.type][n].odds = total/webSocketIO.data.listRow[data.type][n].betting;
        //}
        webSocketIO.data.listRow[data.type][n].odds = data.odds[n];
      }
      if (data.type === 0) {
        //webSocketIO.data.bettingOddEven = data.bettingOddEven;
        if (webSocketIO.data.total[0] > 0) {
          webSocketIO.data.bettingOddEven[0] = (data.bettingOddEven[0] / webSocketIO.data.total[0]) * 100;
          webSocketIO.data.bettingOddEven[1] = (data.bettingOddEven[1] / webSocketIO.data.total[0]) * 100;
        }
      }

      if (_resUserInitCallback.endcallback != undefined && data.idUser === webSocketIO.data.idUser) {
        var resMsg = data.error === undefined || data.error === '' ? 'Success' : 'Error : ' + data.error;
        _resUserInitCallback.endcallback(resMsg);
      }
    } else if (data.protocol === 'resUserInit') {
      //(data.userBetting !== undefined) {
      for (var n = 0; n < 3; n++) {
        for (var m = 0; m < webSocketIO.data.listRow[n].length; m++) {
          if (data.userBetting.betting == null) {
            webSocketIO.data.listRow[n][m].betting = 0;
          } else {
            webSocketIO.data.listRow[n][m].betting = data.userBetting.betting[n][m];
          }
          //webSocketIO.data.listRow[n][m].currency = data.userBetting.currency[n][m];
          webSocketIO.data.listRow[n][m].odds = data.odds[n][m];
        }
        webSocketIO.data.total[n] = data.total[n];
      }
      for (var n = 0; n < data.listBettingValue.length; n++) {
        webSocketIO.data.listBettingValue.push(data.listBettingValue[n]);
      }
      if (webSocketIO.data.total[0] > 0) {
        if (webSocketIO.data.total[0] <= 0) {
          webSocketIO.data.bettingOddEven[0] = 0;
          webSocketIO.data.bettingOddEven[1] = 0;
        } else {
          webSocketIO.data.bettingOddEven[0] = (data.bettingOddEven[0] / webSocketIO.data.total[0]) * 100;
          webSocketIO.data.bettingOddEven[1] = (data.bettingOddEven[1] / webSocketIO.data.total[0]) * 100;
        }
      }
      webSocketIO.data.isCounterOpen = data.isCounterOpen;
      webSocketIO.data.counter = 0;
      webSocketIO.data.idUser = data.idUser;

      webSocketIO.data.listChat = [];

      setConnected(true);
      setModal({ comfirmModal: false });
    } else if (data.protocol === 'resCounter') {
      console.log('counter:', data.counter);
      webSocketIO.data.counter = data.counter;
      if (data.counter <= 0) {
        webSocketIO.data.isCounterOpen = false; //data.isCounterOpen;
        //webSocketIO.data.block = '0xff'; //임시
      }
      setCounter(webSocketIO.data.counter);
      return;
    } else if (data.protocol === 'resBlock') {
      console.log('block:', data.block);
      webSocketIO.data.block = data.block;
      //return;
    } else if (data.protocol === 'reqChatting') {
      webSocketIO.data.listChat.push({
        idUser: data.idUser,
        name: data.nameUser,
        type: data.type,
        msg: data.msg,
      });
      addCount();
    }

    forceUpdate();
  }

  function connectSocket() {
    webSocketIO.close();
    webSocketIO.connect();

    webSocketIO.websocket.onmessage = onMessage;

    const requestTerm = 3000;

    Interval();
    function Interval() {
      setTimeout(() => {
        if (webSocketIO.data.block > 0) {
          //(!webSocketIO.data.isCounterOpen) {
          // state가 항상 0이어서 전역변수 사용 함
          ChangeProgress(webSocketIO.data.game, webSocketIO.data.tokenUser, 3, i18n.language);
          return;
        }
        if (!webSocketIO.data.isCounterOpen) {
          setModal({ comfirmModal: true, confirmMemo: 'waitting hash...' });
        }

        Interval();

        return () => {
          clearTimeout();
        };
      }, requestTerm);
    }

    return () => {
      webSocketIO.close();
      console.log('컴포넌트가 화면에서 사라짐');
    };
  }
  useEffect(() => {
    var urlparam = getCookie('urlparam');
    if (urlparam !== undefined) {
      webSocketIO.data.game = urlparam.game.game; //0;//20550;//
      webSocketIO.data.tokenUser = urlparam.user.user; //'seatetra1';//

      webSocketIO.clientData.game = urlparam.game.game;
      webSocketIO.clientData.user = urlparam.user.user;
      webSocketIO.clientData.lan = urlparam.lan.lan;
    } else {
      webSocketIO.data.game = 0; //20550;//
      webSocketIO.data.tokenUser = 'seatetra1'; //
    }

    // ChangeLanguage(urlparam.lan); //

    if (webSocketIO.data.tokenUser == undefined || webSocketIO.data.tokenUser == 'undefined') {
      ChangeProgress(0, 0, 0, i18n.language);
      return;
    }

    var url = config.GetPrefixURL() + config.GetBetServerURL();
    fetch(`${url}/api/v1/racedetail?id=${webSocketIO.data.game}`, {
      method: 'GET', // 또는 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(params),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        //SafeLog("racedetail data : " + JSON.stringfy(data), config.LOG_DEVELOPER_1);
        //var newList = [];
        var resHorseList = [];
        for (var n = 0; n < data.Race.HorseList.length; n++) {
          //var strLatest = JSON.stringify(data.Race.HorseList[n].latestGames);
          data.Race.HorseList[n].latestGames = data.Race.HorseList[n].latestGames.slice(0, 3);
          if (data.Race.HorseList[n].name.length > 5) {
            data.Race.HorseList[n].name = data.Race.HorseList[n].name.substr(0, 4) + '...';
          }
          resHorseList.push({
            no: n + 1,
            name: data.Race.HorseList[n].name,
            game: data.Race.HorseList[n].countRacing,
            wins: data.Race.HorseList[n].countWin,
            top3s: data.Race.HorseList[n].countTop3,
            latest: data.Race.HorseList[n].latestGames,
          });
        }
        setHorseList(resHorseList);

        connectSocket();
      });
  }, [state]);

  // 말 리스트 ui 상태
  const [isHorseListOpen, setHorseListOpen] = useState(true);
  const [horseListAnimation, setHorseListAnimation] = useState(true);

  const isAnimating = useRef(false);

  const [tab, setTab] = useState('1');

  const [horseList, setHorseList] = useState([]);

  const handleClickShowHorsList = () => {
    if (isAnimating.current) return;
    isAnimating.current = true;
    setHorseListAnimation(!horseListAnimation);
    setTimeout(() => {
      isAnimating.current = false;
      setHorseListOpen(!isHorseListOpen);
    }, 300);
  };

  // Betting Board Component
  const BettingBoard = () => {
    const [selectedValue, setSelectedValue] = useState();

    return (
      <section className="w-full h-full">
        <Container maxWidth="md" className={classes.container}>
          {connected === false || (webSocketIO.data.isCounterOpen && webSocketIO.data.counter === 0) ? (
            ''
          ) : (
            <div className="flex flex-col w-full items-center py-[16px] T:pt-0 T:pb-[24px]">
              <div className="flex flex-row items-center space-x-[8px] h-[30px]">
                <ClockIcon className="w-[24px] h-[24px] fill-[#EFFF8E] stroke-[#EFFF8E]" />
                <b className="text-[20px] T:text-[24px] text-[#EFFF8E] animate-blink">
                  {`00:${String(webSocketIO.data.counter).padStart(2, '0')}`}
                </b>
              </div>
              <div className="tracking-widest text-[12px] text-white font-semibold">
                {/*Remaining Time*/ lngT('list:remainingTime')}
              </div>
            </div>
          )}
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row w-full h-[40px] rounded-t-md bg-lime-50 overflow-hidden">
              <button
                className={`flex justify-center items-center flex-1 text-[14px] text-white ${
                  tab === '1'
                    ? 'bg-[#D470FF] border-b border-white text-opacity-100'
                    : 'bg-[#860099] text-opacity-60 border border-white/20'
                }`}
                onClick={() => setTab('1')}
              >
                {lngT('buttons:oddeven')}
              </button>
              <button
                className={`flex justify-center items-center flex-1 text-[14px text-white ${
                  tab === '2'
                    ? 'bg-[#D470FF] border-b border-white text-opacity-100'
                    : 'bg-[#860099] text-opacity-60 border border-white/20'
                }`}
                onClick={() => setTab('2')}
              >
                {lngT('buttons:single')}
              </button>
              <button
                className={`flex justify-center items-center flex-1 text-[14px text-white ${
                  tab === '3'
                    ? 'bg-[#D470FF] border-b border-white text-opacity-100'
                    : 'bg-[#860099] text-opacity-60 border border-white/20'
                }`}
                onClick={() => setTab('3')}
              >
                {lngT('buttons:show')}
              </button>
            </div>
            {tab === '2' ? (
              <Notify title={singleTitle} content={singleContent} />
            ) : tab === '3' ? (
              <Notify title={showTitle} content={showContent} />
            ) : (
              <Notify title={oddEvenTitle} content={oddEvenContent} />
            )}
            <RandomBoard
              type={tab}
              handleSetModal={handleSetModal}
              bettingValue={bettingValue}
              setBettingValue={setBettingValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              connected={connected}
              //selectOddEven={selectOddEven}
              //setSelectOddEven={setSelectOddEven}
            />
          </div>
        </Container>

        {
          <Modal isOpen={modal.showModal} style={customStyles} className="modal" overlayClassName="overlay">
            <div className="contents">
              <p>
                {lngT('contents:bettingText')}
                <br />
                {lngT('contents:askBetting')}
              </p>
            </div>
            <div className="actions">
              <button className="button" onClick={handleComfirmModal} onClose={handleCloseModal}>
                {lngT('contents:confirm')}
              </button>
              <button className="button" onClick={handleCloseModal}>
                {lngT('contents:cancel')}
              </button>
            </div>
          </Modal>
        }
        <Modal isOpen={modal.comfirmModal} style={customStyles} className="modal" overlayClassName="overlay">
          <button onClick={handleConfirmCloseModal} className="btn-close"></button>
          <div className="contents onlyText">
            <p>{modal.confirmMemo}</p>
          </div>
        </Modal>

        {/* Betting */}
        <CommonDialog
          open={false}
          onClose={handleCloseModal}
          objectImg={<WarningIcon />}
          children={
            <Typography>
              Cancellation is not possible after placing a bet. <br /> Do you want to bet?
            </Typography>
          }
          cancel={'Cancel'}
          submit={'Confirm'}
          //onConfirm={handleComfirmModal}
        />

        {/* Success */}
        <CommonDialog
          open={false}
          onClose={handleClose}
          objectImg={<SuccessFilledIcon />}
          closeButton={true}
          title={'Success'}
          children={<Typography>Congraturation, Successfully betting! </Typography>}
          submit={'Confirm'}
        />

        {/* betting 중 */}
        <CommonDialog
          open={openSuccess}
          onClose={handleClose}
          // objectImg={<BettingIcon />}
          closeButton={true}
          title={'Betting'}
          children={<LinearProgress className={classes.progressBar} />}
        />
      </section>
    );
  };

  return (
    <div className="relative flex flex-col T:flex-row justify-center w-full min-h-screen py-[64px]">
      <div className="block T:hidden w-full h-[48px] px-[16px]">
        <button
          className={`relative flex justify-center items-center w-full h-full bg-red-100 px-[24px] text-white text-[16px] font-bold bg-gradient-to-r from-[#4504A8] via-[#8804B9] to-[#860099] rounded-lg cursor-pointer ${
            isHorseListOpen ? '' : 'border border-white rounded-b-none'
          }`}
          onClick={() => {
            setHorseListOpen(!isHorseListOpen);
            setHorseListAnimation(!horseListAnimation);
          }}
        >
          {/*Horse List*/ lngT('list:title')}
          <ChevronRightIcon
            className={`absolute right-[24px] top-1/2 -translate-y-1/2 w-[16px] h-[16px] ${
              isHorseListOpen ? 'rotate-90' : 'rotate-[270deg]'
            }`}
          />
        </button>
      </div>
      <section
        className={`${horseListAnimation ? 'T:animate-fadeIn' : 'T:animate-fadeOut'} ${
          isHorseListOpen ? 'block' : 'hidden'
        } flex justify-center flex-1 h-full`}
      >
        <div className="flex flex-col w-full px-[16px] T:px-[32px]">
          <div className="hidden T:flex justify-center items-center w-full">
            <div className="relative w-full h-[54px]">
              <HorseListTitleGradient className="absolute w-full h-full" />
              <div className="relative flex justify-center items-center w-full h-full text-[20px] font-[500] text-white">
                {/*Horse List*/ lngT('list:title')}
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-[2px] T:pt-[25px] px-0 T:px-[12px]">
            <div className="relative flex flex-row w-full h-[35px] T:h-[55px] border-t border-l border-r border-white T:rounded-t-md overflow-hidden">
              <div className="absolute w-full h-full bg-gradient-to-r from-[#4504A8] via-[#8804B9] to-[#860099]" />
              <div className="relative flex flex-row w-full h-full text-white text-[12px] D:text-[16px] font-medium divide-x divide-white text-center text-ellipsis">
                <div className="flex justify-center items-center px-[5px] w-[40px] D:w-[70px]">NO</div>
                <div className="flex justify-center items-center px-[5px] w-[60px] D:flex-1">
                  {/*Name*/ lngT('list:name')}
                </div>
                <div className="flex justify-center items-center px-[5px] flex-1">{/*Front*/ lngT('list:game')}</div>
                <div className="flex justify-center items-center px-[5px] flex-1">{/*Stalker*/ lngT('list:wins')}</div>
                <div className="flex justify-center items-center px-[5px] flex-1">{/*Closer*/ lngT('list:top3s')}</div>
                <div className="flex justify-center items-center px-[5px] w-[80px] D:flex-1">
                  <span className="truncate">{/*Latest*/ lngT('list:latest')}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full border border-[#FFD84D] divide-y divide-[#FFD84D]">
              {console.log(horseList)}
              {horseList.map((horseItem, index) => (
                <div
                  key={horseItem.no}
                  className={`flex flex-row w-full h-[35px] T:h-[55px] divide-x divide-[#FFD84D] text-[#FFD84D] text-[10px] max-[992px]:text-[12px] D:text-[16px] ${
                    index % 2 === 0 ? 'bg-[#271158]' : 'bg-[#321B57]'
                  }`}
                >
                  <div className="flex justify-center items-center px-[5px] w-[40px] D:w-[70px]">{horseItem.no}</div>
                  <div className="flex justify-center items-center px-[5px] w-[60px] D:flex-1">{horseItem.name}</div>
                  <div className="flex justify-center items-center px-[5px] flex-1">{horseItem.game}</div>
                  <div className="flex justify-center items-center px-[5px] flex-1">{horseItem.wins}</div>
                  <div className="flex justify-center items-center px-[5px] flex-1">{horseItem.top3s}</div>
                  <div className="flex justify-center items-center px-[5px] w-[80px] D:flex-1">
                    {JSON.stringify(horseItem.latest)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="relative w-full T:w-1/2 mt-1">
        <div
          className={`${
            isHorseListOpen ? '' : 'T:left-1/2 T:-translate-x-1/2'
          } relative w-full T:transition-all T:duration-500`}
        >
          <div className="absolute hidden T:block">
            <div className="absolute right-[30px] flex flex-row items-center space-x-[6px]">
              <div className="flex justify-end w-[60px] text-[14px] text-fuchsia-400">
                {isHorseListOpen ? /*'Hide'*/ lngT('list:Hide') : /*'Horse List'*/ lngT('list:title')}
              </div>
              <button
                className="flex justify-center items-center w-[40px] h-[40px] bg-[#27004A] rounded-lg border border-[#D470FF]"
                onClick={handleClickShowHorsList}
              >
                {isHorseListOpen ? (
                  <ChevronLeftIcon className="w-[20px] h-[20px] text-white" />
                ) : (
                  <ChevronRightIcon className="w-[20px] h-[20px] text-white" />
                )}
              </button>
            </div>
            <BettingLine />
          </div>
          <BettingBoard />
        </div>
      </div>
    </div>
  );
}
