/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/lottomachine_new_07.glb 
*/

import React, { useEffect, useRef, useState, useContext } from 'react'
import { useGLTF, useAnimations, MeshTransmissionMaterial } from '@react-three/drei'
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import * as define from '../../../define'
import * as THREE from 'three'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import {
  usePowerBallBettingStore,
  usePowerBallLotteryStore,
} from '@/store/powerball';
import {UseHasFocus} from '../../../util/detectfocus'
// import useCustomNavigate from '@/lib/hook/useCustomNavigate';

const startball = 0;
const ballCount = 30;
let copyMaterials = [];
let totalFrame = 0;
let elapsedTime = 0;
let serverballs = [1,1,1,1,1];
let isMobile = true;
let gfocusTime = 0;
let focused = true;
let gfocusoffTime = 0;

let jsHeapSizeLimit = 0;
let totalJSHeapSize = 0;
let clock = null;
export const frame7Time = 588;
export function LottomachineWhite(props) {
  // const navigate = useCustomNavigate();
  const { setSelectedTicketList } = usePowerBallLotteryStore();
  const { setTicketStore } = usePowerBallBettingStore();

  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/powerball/lottomachine_white.glb')
  const { actions } = useAnimations(animations, group)

  const options = {
    enableSwoopingCamera: false,
    enableRotation: true,
    transmission: 1.0,
    opacity: 1.0,
    metalness: 0,
    roughness: 0,
    ior: 1.5,
    thickness: 0.01,
    specularIntensity: 1.0,
    specularColor: '#ffffff',      
    envMapIntensity: 1.0,
    lightIntensity: 1.0,
    exposure: 1.0
  };
  const GetENV=()=>
  {
    const hdrEquirect = useLoader(RGBELoader, "/powerball/empty_warehouse_01_2k.hdr");
    hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
    return hdrEquirect;
  }
  const pmaterial = 
  isMobile ? materials.glass_001 :
  new THREE.MeshPhysicalMaterial({
    transmission: options.transmission,
    opacity: options.opacity,
    metalness: options.metalness,
    roughness: options.roughness,
    ior: options.ior,
    thickness: options.thickness,
    specularIntensity: options.specularIntensity,
    specularColor: options.specularColor,
    envMapIntensity: options.envMapIntensity,

    // clear coat : 외각 두께 컬러
    clearcoat:100,
    clearcoatRoughness:1,
    clearcoatNormalScale:10,
    
    // sheen : 외각 두께 컬러
    sheen:1.0,
    sheenColor:'#ffffff',
    sheenRoughness:0.15,
    
    envMap: GetENV()
  })

  useThree()

  let config = {    
    meshPhysicalMaterial: false,
    transmissionSampler: false,
    transmission: 1.00,
    roughness: 0.0,
    ior: 1.00,
    thickness: 0.4,
    chromaticAberration: 0.0,
    anisotropy: 0.3,
    distortion: 0.1,
    distortionScale: 200.5,
    temporalDistortion: 2,
    attenuationDistance: 1.5,
    clearcoat: 4.5,
    attenuationColor: '#eeeeee',
    // color: '#ffeeee',    
    specularIntensity : 2,
    envMapIntensity:1.4,
  }

  // origin fog
  // let config = {
    
  //   meshPhysicalMaterial: false,
  //   transmissionSampler: false,
  //   backside: true,
  //   backsideThickness: 0.1,
  //   samples: 2,
  //   resolution: 512,
  //   backsideResolution: 512,
  //   transmission: 1.00,
  //   roughness: 0.0,
  //   ior: 1.00,
  //   thickness: 0.4,
  //   chromaticAberration: 0.0,
  //   anisotropy: 0.3,
  //   distortion: 0.1,
  //   distortionScale: 0.3,
  //   temporalDistortion: 0.65,
  //   attenuationDistance: 0.5,
  //   clearcoat: 0.5,
  //   attenuationColor: '#eeeeee',
  //   // color: '#ffeeee',    
  //   specularIntensity : 2,
  //   envMapIntensity:1.4,
  // }

  // let config = 
  // useControls({
  //   meshPhysicalMaterial: false,
  //   transmissionSampler: false,
  //   backside: true,
  //   backsideThickness: { value: 1, min: -10, max: 10 },
  //   samples: { value: 2, min: 0, max: 32, step: 1 },
  //   resolution: { value: 32, min: 32, max: 2048, step: 256 },
  //   backsideResolution: { value: 256, min: 32, max: 2048, step: 256 },
  //   transmission: { value: 1, min: 0, max: 1 },
  //   roughness: { value: 0.0, min: 0, max: 1, step: 0.01 },
  //   ior: { value: 1.0, min: 1, max: 5, step: 0.01 },
  //   thickness: { value: 0.4, min: 0, max: 10, step: 0.01 },
  //   chromaticAberration: { value: 0.0, min: 0, max: 1 },
  //   anisotropy: { value: 0.3, min: 0, max: 1, step: 0.01 },
  //   distortion: { value: 0.1, min: 0, max: 1, step: 0.01 },
  //   distortionScale: { value: 0.3, min: 0.01, max: 1, step: 0.01 },
  //   temporalDistortion: { value: 0.65, min: 0, max: 1, step: 0.01 },
  //   attenuationDistance: { value: 0.5, min: 0, max: 2.5, step: 0.01 },
  //   clearcoat: { value: 0.5, min: 0, max: 1 },
  //   attenuationColor: '#eeeeee',
  //   // color: props.color,
  //   specularIntensity : {value:2,min:-2,max:5},
  //   envMapIntensity:{value:1.0,min:-2,max:2},
  // })
  

  const BLENDER_FPS = 30;
  const eventFrames = [0,40,120,200,280,360,480,frame7Time,589];

  const sfxframeGab = 10;
  const eventFramesSFX = [];
  for(let i = 0; i < 6; ++i)
  {
    eventFramesSFX.push({frame:eventFrames[1]+80*i-sfxframeGab, sfx:define.SFX_LASER})
    eventFramesSFX.push({frame:eventFrames[1]+80*i+sfxframeGab, sfx:define.SFX_BALLMOVE})
  }
 
  const ChangeNewBallMaterial=()=>{
    materials['new_001'] = copyMaterials[serverballs[0]-1];
    materials['new_002'] = copyMaterials[serverballs[1]-1];
    materials['new_003'] = copyMaterials[serverballs[2]-1];
    materials['new_004'] = copyMaterials[serverballs[3]-1];
    materials['new_005'] = copyMaterials[serverballs[4]-1];
  }

  const getState = props.curState;  
  const getSFX = props.curSFX;

  const Init=()=>{
    gfocusTime = new Date().getTime();
    isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);    
    copyMaterials = [];
    let action = actions["Animation"];    

    if(action !== undefined)
    {
      action.play();
      totalFrame = Number((action._clip.duration * BLENDER_FPS).toFixed());
    }

    for(let i = startball; i < ballCount; ++i)
    {
      let arridx = String(i+1).padStart(3, "0");
      let arridxStr = `${arridx}`
      copyMaterials.push(materials[arridxStr]);
    }
    // SERVER
    if(props.Powerball === null || props.Powerball === undefined)
    {
      elapsedTime = 40;
      // console.log('set elapsedTime : ' + elapsedTime);
    }
    else
    {
      elapsedTime = props.Powerball.gameframe;      

      if(props.dispatch)
      {
        console.log(`CallDispatch : ${GetCurState()}`);
        props.CallDispatch(GetCurState());      
      }
      // console.log('set elapsedTime : ' + elapsedTime);

      let actiontime = (elapsedTime)%action._clip.duration;
      let curFrameRate = actiontime / action._clip.duration;
      let curFrame = curFrameRate * totalFrame;

      if(curFrame >= frame7Time)
      {
        setSelectedTicketList([]);
        setTicketStore({});
      }
    }    

    serverballs = props.Powerball === null || props.Powerball === undefined ? [1,1,1,1,1] : props.Powerball.ball;
    ChangeNewBallMaterial();

    if(props.dispatch)
      DetectPerformance();    
  }

  useEffect(()=>{
    Init();
  },[])

  
  
  useEffect(()=>{
    if(props.Powerball !== undefined && props.Powerball !== null)
    {
      console.log('useEffect Powerball.gameframe Machine : ' +  props.Powerball.gameframe);      
      serverballs = props.Powerball.ball;
      elapsedTime = props.Powerball.gameframe;
      // console.log('set elapsedTime : ' + elapsedTime);

      if(props.dispatch)
      {
        console.log(`CallDispatch : ${GetCurState()}`);
        props.CallDispatch(GetCurState());      
      }
      ChangeNewBallMaterial();
    }
    // else
    // {
    //   elapsedTime = 40;
    //   console.log('set elapsedTime : ' + elapsedTime);
    // }
  },[props.Powerball])


  function GetCurFrame()
  {
    let action = actions["Animation"];    
    action.time = (elapsedTime)%action._clip.duration;
    let curFrameRate = action.time / action._clip.duration;
    let curFrame = curFrameRate * totalFrame;
    curFrame = curFrame.toFixed();    
    return curFrame;
  }

  function GetCurState()
  {
    const curFrame = GetCurFrame();
    for(let i = 0; i < eventFrames.length; ++i)
    {
      const destFrame = eventFrames[i];
      if(curFrame < destFrame)
        return i;
    }
  }
  
  useFrame((state,delta,frame)=>{    
    clock = state.clock;        
    elapsedTime += (delta*0.5);    

    if(!focused)
    { 
      gfocusoffTime += 0.1  
    }
    const curFrame = GetCurFrame();
    
    // DISPATCH EVENT 
    if(curFrame !== undefined && props.dispatch)
    {           
      for(let i = 0; i < eventFrames.length; ++i)
      {
        let compareState = i;
        let minFrame =eventFrames[i]; 
        let maxFrame = i+1 >= eventFrames.length ? totalFrame : eventFrames[i+1];
        
        if(curFrame >= minFrame && curFrame <= maxFrame)
        { 
          // console.log(`telapsedTime : ${elapsedTime} compareState : ${compareState} getState:${getState}`);
          // console.log(`curFrame:${curFrame} totalFrame:${totalFrame}`);
          if(compareState > getState || (compareState === 0 && getState === eventFrames.length-1 ))
          {
            // console.log(`CallDispatch : ${compareState} ${getState}`);
            props.CallDispatch(compareState);            
            // console.log('set CURFRAME : ' + compareState + 'count : ' + curFrame);
            break;
          }
        }
      }

      // DISPATCH SFX EVENT 
      for(let i = 0; i < eventFramesSFX.length; ++i)
      {
        let info = eventFramesSFX[i];
        let compareState = i;
        let minFrame = info.frame;
        let maxFrame = i+1 >= eventFramesSFX.length ? totalFrame : eventFramesSFX[i+1].frame;
        
        if(curFrame >= minFrame && curFrame <= maxFrame)
        { 
          // console.log(`curFrame:${curFrame} totalFrame:${totalFrame}`);
          if(compareState > getSFX.curState || (compareState === 0 &&  getSFX.curState === eventFramesSFX.length-1 ))
          {
            // console.log(`dispatch : ${compareState} ${getSFX.curState}`);
            // console.log('SET SFX :' + info.sfx);
            props.CallDispatchSFX({curState: compareState, sfx: info.sfx});
            break;
          }
        }
      }
    }
  })

  const CallRefreshBrowser=(reconnect)=>{
    console.log('REFRESH POWERBALL');
    gfocusTime = new Date().getTime();    

    // window.location.reload();

    // if(reconnect)
    //   window.location.reload();
    // else
    //   Init();

    const isBetting =  window.location.pathname.includes('betting')
    if(isBetting)
    {
      window.location.replace('/powerball/betting');
      // props.navigate('/powerball/betting');
    }
    else
    {
      window.location.replace('/powerball/lottery');
      // props.navigate('/powerball/lottery');
    }
  }

  const DetectPerformance=()=>
  {
    const detectDelay = 5000;
    setTimeout(() => {
      if(!performance.memory)
        return;

      if(jsHeapSizeLimit === 0)
        jsHeapSizeLimit = performance.memory.jsHeapSizeLimit;
      if(totalJSHeapSize === 0 ) 
      {
        totalJSHeapSize = performance.memory.totalJSHeapSize;
        console.log('refresh totalheapsize');
      }

      let refreshMinute = 60 * 10; // refresh 10 min 
      let refreshMinuteMax = 60 * 30; // refresh 30 min 
      let usedJSHeapSize = performance.memory.usedJSHeapSize;

      if(clock)
      {
        let refresh = (usedJSHeapSize > totalJSHeapSize && clock.elapsedTime > refreshMinute) || clock.elapsedTime > refreshMinuteMax;
        if(refresh)
        {
          CallRefreshBrowser(true);
          return;
        }

        // console.log('elapsedTime : ' + clock.elapsedTime);
        // console.log(`jsHeapSizeLimit:\t${jsHeapSizeLimit}\n totalJSHeapSize:\t${totalJSHeapSize}\n usedJSHeapSize:\t${usedJSHeapSize}`);      
      }
      DetectPerformance();
    }, detectDelay);
  }

  const focus = props.dispatch && UseHasFocus();
  useEffect(()=>{
    if(focused === focus)
      return;

    focused = focus;    

    if(!focus)
    {
      gfocusoffTime = 0;
      console.log(`focus:${focus}`);
      return;
    }

    let curTime = new Date().getTime();    
    let timeDiff = curTime - gfocusTime;
    const maxRefreshTime = 1000;
    const maxFocusOffTime = 3;
    gfocusTime = curTime;

    let refresh = timeDiff > maxRefreshTime && focus&& gfocusoffTime < maxFocusOffTime && !isMobile;

    const logTxt = `refresh:${refresh}\nfocus:${focus}\ntimedif:${timeDiff}>${maxRefreshTime}\ngfocusoffTime:${gfocusoffTime}<${maxFocusOffTime}`;
    console.log(logTxt);
    // alert(logTxt);

    let refreshMinute = 2; // refresh 10 seconds : 켜지자마자 메모리 수거하지 않게 처리
    if(refresh && clock)
    {
      if(clock.elapsedTime > refreshMinute)
        CallRefreshBrowser(false);
    }
    
    gfocusoffTime = 0;
  },[focus])

  useFrame((state, delta)=>{

    // LEAK TEST START
    // let data = [];
    // for(var i = 0; i < 1000000; i++) {
    //   data.push(Math.random());
    // }
    // LEAK TEST END

    if(isMobile)
      return;

    const t = (3 + Math.sin(elapsedTime * 4)) / 4;    
    for(let i = 0 ; i < refCylinder006Mat.length; ++i)
    {        
        refCylinder006Mat[i].current.color.r = constColor[0] * t;
        refCylinder006Mat[i].current.color.g = constColor[1] * t;
        refCylinder006Mat[i].current.color.b = constColor[2] * t;      
    }    
    refCylinder006Mat[0].current.side = THREE.DoubleSide;
  })

  // glass_002 는 기둥, 바닥 바깥 원 유리
  const constColor = [1,1,16];
  let brightColor = [1,1,16];
  const refCylinder006Mat = [useRef(null),useRef(null)];  
  // materials.glass_002.side = THREE.DoubleSide;

  return (
<group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={pmaterial} position={[0, 1.382, -0.325]}/>
        <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
        <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
        <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
        <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
        {/* <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} material={materials.light_001} position={[0, 0.879, -0.169]} scale={0.685} />
        <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} /> */}
         <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.8790, -0.169]} scale={[0.685,0.685,0.685]} rotation={[0,0,0]} >
            <meshBasicMaterial ref = {refCylinder006Mat[0]} 
              toneMapped={false} 
              color={brightColor}
              // side={THREE.DoubleSide}
          />
        </mesh>        
        <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} >
          <meshBasicMaterial ref = {refCylinder006Mat[1]} 
              toneMapped={false} 
              color={brightColor}
          />
        </mesh>        
        <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
        <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials.new_001} position={[0, 0.9, -0.22]} />
        <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials.new_002} position={[0, 0.9, -0.22]} />
        <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials.new_003} position={[0, 0.9, -0.22]} />
        <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials.new_004} position={[0, 0.9, -0.22]} />
        <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials.new_005} position={[0, 0.9, -0.22]} />
        <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.086, 1.04, -0.248]} rotation={[Math.PI, 1.092, 2.88]} />
        <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.04, -0.404]} rotation={[Math.PI, 1.092, 2.618]} />
        <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.032, 1.04, -0.152]} rotation={[2.003, 0.678, -2.204]} />
        <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.1, 1.04, -0.371]} rotation={[1.652, 0.155, -2.056]} />
        <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.214, 1.127, -0.168]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.07, 1.04, -0.246]} rotation={[3.088, -0.03, 0.071]} />
        <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.25, 1.098, -0.281]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.124, 1.125, -0.412]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.069, 1.125, -0.461]} rotation={[1.794, -0.88, -0.687]} />
        <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.104, 1.132, -0.204]} rotation={[0.709, -0.577, -1.352]} />
        <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.021, 1.088, -0.418]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.009, 1.104, -0.23]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[0.13, 1.063, -0.139]} rotation={[-2.033, -0.277, 0.201]} />
        <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.147, 1.143, -0.23]} rotation={[-2.011, -0.665, 0.574]} />
        <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.171, 1.04, -0.424]} rotation={[0.677, -0.383, -1.151]} />
        <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[-0.029, 1.04, -0.499]} rotation={[2.441, -0.587, -0.188]} />
        <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[0.098, 1.04, -0.476]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.173, 1.04, -0.225]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[0.2, 1.04, -0.347]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.096, 1.04, -0.151]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[-0.131, 1.067, -0.513]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[-0.034, 1.079, -0.082]} rotation={[2.525, -1.234, -0.311]} />
        <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[-0.258, 1.1, -0.26]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[-0.228, 1.069, -0.36]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[0.045, 1.064, -0.548]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.129, 1.111, -0.302]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.1, 1.104, -0.329]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.19, 1.059, -0.198]} rotation={[3.075, 0.022, -0.643]} />
        <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.188, 1.061, -0.446]} rotation={[-2.897, -1.333, 2.497]} />
        <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.179, 1.04, -0.296]} rotation={[2.176, -0.401, -0.188]} />
      </group>
    </group>

  //   <group ref={group} {...props} dispose={null}>
  //   <group name="Scene">
  //     <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={pmaterial} position={[0, 1.382, -0.325]}/>
  //     <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
  //     <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
  //     <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
  //     <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={[0.0,0.0,0.0]} />
  //     <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685}/>

  //     {/* <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} material={materials.light_001} position={[0, 0.879, -0.169]} scale={0.685} />
  //     <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} /> */}
  //       <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.8790, -0.169]} scale={[0.685,0.685,0.685]} rotation={[0,0,0]} >
  //          <meshBasicMaterial ref = {refCylinder006Mat[0]} 
  //             toneMapped={false} 
  //             color={brightColor}
  //             // side={THREE.DoubleSide}
  //         />
  //       </mesh>        
  //       <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} >
  //         <meshBasicMaterial ref = {refCylinder006Mat[1]} 
  //             toneMapped={false} 
  //             color={brightColor}
  //         />
  //       </mesh>

  //     <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
  //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials.new_001} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials.new_002} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials.new_003} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials.new_004} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials.new_005} position={[0, 0.9, -0.22]} />
  //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.086, 1.04, -0.248]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.04, -0.404]} rotation={[Math.PI, 1.092, 2.618]} />
  //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.032, 1.04, -0.152]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.1, 1.04, -0.371]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.214, 1.127, -0.168]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.07, 1.04, -0.246]} rotation={[3.088, -0.03, 0.071]} />
  //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.25, 1.098, -0.281]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.124, 1.125, -0.412]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.069, 1.125, -0.461]} rotation={[1.794, -0.88, -0.687]} />
  //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.104, 1.132, -0.204]} rotation={[0.709, -0.577, -1.352]} />
  //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.021, 1.088, -0.418]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.009, 1.104, -0.23]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[0.13, 1.063, -0.139]} rotation={[-2.033, -0.277, 0.201]} />
  //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.147, 1.143, -0.23]} rotation={[-2.011, -0.665, 0.574]} />
  //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.171, 1.04, -0.424]} rotation={[0.677, -0.383, -1.151]} />
  //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[-0.029, 1.04, -0.499]} rotation={[2.441, -0.587, -0.188]} />
  //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[0.098, 1.04, -0.476]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.173, 1.04, -0.225]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[0.2, 1.04, -0.347]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.096, 1.04, -0.151]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[-0.131, 1.067, -0.513]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[-0.034, 1.079, -0.082]} rotation={[2.525, -1.234, -0.311]} />
  //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[-0.258, 1.1, -0.26]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[-0.228, 1.069, -0.36]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[0.045, 1.064, -0.548]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.129, 1.111, -0.302]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.1, 1.104, -0.329]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.19, 1.059, -0.198]} rotation={[3.075, 0.022, -0.643]} />
  //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.188, 1.061, -0.446]} rotation={[-2.897, -1.333, 2.497]} />
  //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.179, 1.04, -0.296]} rotation={[2.176, -0.401, -0.188]} />
  //   </group>
  // </group>
    
  // <group ref={group} {...props} dispose={null}>
  //     <group name="Scene">
  //       { isMobile ? <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]}/> :
  //       <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} position={[0, 1.382, -0.325]}>
  //         {<MeshTransmissionMaterial {...config} toneMapped={true} />}
  //       </mesh>          
  //       }       

  //       <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
  //       <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
  //       <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
  //       <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
  //       <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
        
        
  //       <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} position={[0, 0.883, -0.169]} scale={0.685} >
  //         <meshBasicMaterial ref = {refCylinder006Mat[0]} 
  //             toneMapped={false} 
  //             color={brightColor}
  //         />
  //       </mesh>
        
  //       <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879, -0.169]} scale={0.685} >
  //         <meshBasicMaterial ref = {refCylinder006Mat[1]} 
  //             toneMapped={false} 
  //             color={brightColor}
  //         />
  //       </mesh>
        
  //       <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} >
  //         <meshBasicMaterial ref = {refCylinder006Mat[2]} 
  //             toneMapped={false} 
  //             color={brightColor}
  //         />
  //       </mesh>
  //       <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
  //       <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials.new_001} position={[0, 0.9, -0.22]} />
  //       <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials.new_002} position={[0, 0.9, -0.22]} />
  //       <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials.new_003} position={[0, 0.9, -0.22]} />
  //       <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials.new_004} position={[0, 0.9, -0.22]} />
  //       <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials.new_005} position={[0, 0.9, -0.22]} />
  //       <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.086, 1.04, -0.248]} rotation={[Math.PI, 1.092, 2.88]} />
  //       <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.04, -0.404]} rotation={[Math.PI, 1.092, 2.618]} />
  //       <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.032, 1.04, -0.152]} rotation={[2.003, 0.678, -2.204]} />
  //       <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.1, 1.04, -0.371]} rotation={[1.652, 0.155, -2.056]} />
  //       <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.214, 1.127, -0.168]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.07, 1.04, -0.246]} rotation={[3.088, -0.03, 0.071]} />
  //       <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.25, 1.098, -0.281]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.124, 1.125, -0.412]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.069, 1.125, -0.461]} rotation={[1.794, -0.88, -0.687]} />
  //       <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.104, 1.132, -0.204]} rotation={[0.709, -0.577, -1.352]} />
  //       <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.021, 1.088, -0.418]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.009, 1.104, -0.23]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[0.13, 1.063, -0.139]} rotation={[-2.033, -0.277, 0.201]} />
  //       <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.147, 1.143, -0.23]} rotation={[-2.011, -0.665, 0.574]} />
  //       <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.171, 1.04, -0.424]} rotation={[0.677, -0.383, -1.151]} />
  //       <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[-0.029, 1.04, -0.499]} rotation={[2.441, -0.587, -0.188]} />
  //       <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[0.098, 1.04, -0.476]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.173, 1.04, -0.225]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[0.204, 1.04, -0.351]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.104, 1.04, -0.145]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[-0.131, 1.067, -0.513]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[-0.034, 1.079, -0.082]} rotation={[2.525, -1.234, -0.311]} />
  //       <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[-0.258, 1.1, -0.26]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[-0.228, 1.069, -0.36]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[0.045, 1.064, -0.548]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.129, 1.111, -0.302]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.1, 1.104, -0.329]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.19, 1.059, -0.198]} rotation={[3.075, 0.022, -0.643]} />
  //       <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.197, 1.061, -0.449]} rotation={[-2.897, -1.333, 2.497]} />
  //       <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.179, 1.04, -0.296]} rotation={[2.176, -0.401, -0.188]} />
  //     </group>
  //   </group>

// <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} />
//         <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
//         <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
//         <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
//         <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
//         <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
//         {/* <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} material={materials.light_001} position={[0, 0.883, -0.169]} scale={0.685} /> */}
//         <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} position={[0, 0.883, -0.169]} scale={0.685} >
//           <meshBasicMaterial ref = {refCylinder006Mat[0]} 
//               toneMapped={false} 
//               color={brightColor}
//           />
//         </mesh>

//         {/* <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} material={materials.light_001} position={[0, 0.879, -0.169]} scale={0.685} /> */}
//         <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879, -0.169]} scale={0.685} >
//           <meshBasicMaterial ref = {refCylinder006Mat[1]} 
//               toneMapped={false} 
//               color={brightColor}
//           />
//         </mesh>
//         {/* <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} /> */}
//         <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} >
//           <meshBasicMaterial ref = {refCylinder006Mat[2]} 
//               toneMapped={false} 
//               color={brightColor}
//           />
//         </mesh>
//         <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.798, -0.329]} />
//         <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
//         <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.086, 1.04, -0.248]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.04, -0.404]} rotation={[Math.PI, 1.092, 2.618]} />
//         <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.032, 1.04, -0.152]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.1, 1.04, -0.371]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.214, 1.127, -0.168]} rotation={[-2.897, -1.333, 2.497]} />
//         <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[0.12, 1.131, -0.419]} rotation={[0.272, 0.222, 0.668]} />
//         <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.014, 1.094, -0.411]} rotation={[0, 0, 0.873]} />
//         <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.062, 1.099, -0.068]} rotation={[Math.PI / 6, 0, 0]} />
//         <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[-0.028, 1.101, -0.585]} rotation={[0.873, 0, 0]} />
//         <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.061, 1.116, -0.534]} rotation={[0, 0, -Math.PI / 9]} />
//         <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[-0.171, 1.04, -0.425]} rotation={[0, 0, -Math.PI / 6]} />
//         <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.175, 1.04, -0.301]} rotation={[Math.PI / 3, 0, 0]} />
//         <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[0.247, 1.106, -0.259]} rotation={[Math.PI / 9, 0, 0]} />
//         <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.086, 1.132, -0.446]} rotation={[0.355, -0.393, -0.801]} />
//         <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[0.133, 1.067, -0.134]} rotation={[0, 0, -1.396]} />
//         <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.137, 1.103, -0.301]} rotation={[0.873, 0, 0]} />
//         <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.027, 1.042, -0.514]} rotation={[1.396, 0, 0]} />
//         <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.189, 1.057, -0.443]} rotation={[0, 0, -Math.PI / 9]} />
//         <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.252, 1.105, -0.263]} rotation={[0, 0, -Math.PI / 6]} />
//         <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.112, 1.127, -0.2]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.074, 1.04, -0.246]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[-0.037, 1.083, -0.09]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[-0.1, 1.04, -0.151]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.057, 1.129, -0.166]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[0.176, 1.04, -0.226]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.218, 1.056, -0.346]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.132, 1.074, -0.52]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[0.1, 1.04, -0.5]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[-0.192, 1.07, -0.191]} rotation={[0.865, 0.133, 0.113]} />
//         <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.095, 1.116, -0.34]} rotation={[0.865, 0.133, 0.113]} />
//       </group>
//     </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
        
    //     <mesh name="sphere_glass" geometry={nodes.sphere_glass.geometry} position={[0, 1.382, -0.325]}>
    //        {<MeshTransmissionMaterial {...config} toneMapped={true} ref={refSphere}/>}        
    //     </mesh>          
    //     <mesh name="sphere_in" geometry={nodes.sphere_in.geometry} material={materials.rubber_001} position={[0, 0.99, -0.325]} />
    //     <mesh name="cylinder_glass_001" geometry={nodes.cylinder_glass_001.geometry} material={materials.glass_001} position={[0, 0.578, -0.325]} />
    //     <mesh name="cylinder_001" geometry={nodes.cylinder_001.geometry} material={materials.rubber_001} position={[0, 0.945, -0.325]} />
    //     <mesh name="glass_001" geometry={nodes.glass_001.geometry} material={materials.glass_002} position={[0, 0.883, -0.169]} scale={0.685} />
    //     <mesh name="cylinder_002" geometry={nodes.cylinder_002.geometry} material={materials.rubber_001} position={[0, 0.883, -0.169]} scale={0.685} />
        
    //     <mesh name="glass_light_002" geometry={nodes.glass_light_002.geometry} position={[0, 0.883, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[0]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>

    //     <mesh name="glass_light_001" geometry={nodes.glass_light_001.geometry} position={[0, 0.879, -0.169]} scale={0.685}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[1]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>


    //     <mesh name="cylinder_light_001" geometry={nodes.cylinder_light_001.geometry} position={[0, 0.578, -0.325]}>
    //       <meshBasicMaterial ref = {refCylinder006Mat[2]} 
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>

    //     <mesh name="rail" geometry={nodes.rail.geometry} material={materials.metal_001} position={[-0.096, 1.779, -0.329]} />
    //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]}/>
    //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]}/>
    //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]}/>
    //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]}/>
    //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]}/>
    //     <mesh name="powerball_001" geometry={nodes.powerball_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
    //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.089, 1.031, -0.289]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[Math.PI, 1.092, 2.618]} />
    //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.18, 1.057, -0.168]} rotation={[-2.897, -1.333, 2.497]} />
    //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.162, 1.059, -0.15]} rotation={[0.272, 0.222, 0.668]} />
    //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.126, 1.079, -0.304]} rotation={[0, 0, 0.873]} />
    //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[-0.015, 1.052, -0.255]} rotation={[Math.PI / 6, 0, 0]} />
    //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.224, 1.06, -0.234]} rotation={[0.873, 0, 0]} />
    //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.033, 1.074, -0.487]} rotation={[0, 0, -Math.PI / 9]} />
    //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[-0.112, 1.08, -0.351]} rotation={[0, 0, -Math.PI / 6]} />
    //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.223, 1.059, -0.254]} rotation={[Math.PI / 3, 0, 0]} />
    //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.011, 1.04, -0.539]} rotation={[Math.PI / 9, 0, 0]} />
    //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.164, 1.03, -0.411]} rotation={[0.355, -0.393, -0.801]} />
    //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[0.104, 1.03, -0.175]} rotation={[0, 0, -1.396]} />
    //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.076, 1.03, -0.44]} rotation={[0.873, 0, 0]} />
    //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.025, 1.03, -0.458]} rotation={[1.396, 0, 0]} />
    //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.153, 1.03, -0.423]} rotation={[0, 0, -Math.PI / 9]} />
    //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[0.088, 1.057, -0.107]} rotation={[0, 0, -Math.PI / 6]} />
    //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.098, 1.098, -0.177]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.069, 1.03, -0.256]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.012, 1.042, -0.112]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[-0.09, 1.033, -0.135]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[-0.076, 1.03, -0.21]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[0.146, 1.03, -0.242]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.172, 1.03, -0.344]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.106, 1.03, -0.463]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[0.014, 1.03, -0.396]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[-0.151, 1.03, -0.227]} rotation={[0.865, 0.133, 0.113]} />
    //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.168, 1.03, -0.304]} rotation={[0.865, 0.133, 0.113]} />
    //   </group>
    // </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     {/* <mesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} material={materials.light_001} position={[0, 0.869, -0.22]} /> */}
    //     <mesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} position={[0, 0.869, -0.22]}>
    //       <meshBasicMaterial ref = {refCylinder006Mat} 
    //         // backside= {true}            
    //         // backsideThickness= {2}
    //         // backsideResolution= {1024}
    //         toneMapped={false} 
    //         color={brightColor}
    //       />
    //     </mesh>
        
        
    //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} rotation={[-0.001, 0.002, -0.001]} />
    //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
    //     <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.Material} position={[0, 0.945, -0.325]} />
    //     {/* <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} /> */}
    //     <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh>          
    //     <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.Material} position={[0, 0.99, -0.325]} rotation={[0, -0.214, 0]} />
    //     <mesh name="Cylinder010" geometry={nodes.Cylinder010.geometry} material={materials.glass_002} position={[0, 0.578, -0.325]} />        
    //     {/* <mesh name="Cylinder010" geometry={nodes.Cylinder010.geometry} position={[0, 0.578, -0.325]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh>           */}
    //     <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.glass_002} position={[0, 0.873, -0.22]} />        
    //     {/* <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} position={[0, 0.873, -0.22]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh>           */}

    //     <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.Material} position={[0, 0.873, -0.22]} />
    //     {/* <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.light_001} position={[0, 0.873, -0.22]} /> */}
    //     <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} position={[0, 0.873, -0.22]}>
    //       <meshBasicMaterial             
    //           toneMapped={false} 
    //           color={testbrightColor}
    //         />
    //     </mesh>
    //     <mesh name="Cylinder007" geometry={nodes.Cylinder007.geometry} material={materials.light_001} position={[0, 0.578, -0.325]} />
    //     {/* <mesh name="Cylinder007" geometry={nodes.Cylinder007.geometry} position={[0, 0.578, -0.325]}>
    //       <meshBasicMaterial
    //           toneMapped={false} 
    //           color={brightColor}
    //         />
    //     </mesh> */}

        
    //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, 2.618]} />
    //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[2.757, 1.108, -3.059]} />
    //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[1.692, 0.975, -2.363]} />
    //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.004, 1.036, -0.111]} rotation={[2.276, -0.138, -1.935]} />
    //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[2.762, 1.153, 2.961]} />
    //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.081, 1.03, -0.174]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.155, 1.028, -0.416]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-2.4, 0.188, 1.938]} />
    //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[Math.PI, 1.092, 2.88]} />
    //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[2.762, 1.153, 2.961]} />
    //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.037, -0.539]} rotation={[-1.831, 0.46, 2.111]} />
    //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-2.249, 0.647, 2.163]} />
    //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.015, 1.08, -0.407]} rotation={[2.92, 0.818, 2.775]} />
    //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-0.783, 0.91, 1.956]} />
    //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[2.003, 0.678, -2.204]} />
    //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.054, 1.074, -0.506]} rotation={[1.652, 0.155, -2.056]} />
    //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.074, 1.093, -0.441]} rotation={[-2.249, 0.647, 2.163]} />
    //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[2.762, 1.153, 2.961]} />
    //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.103, 1.088, -0.233]} rotation={[1.831, 0.46, -2.111]} />
    //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[3.012, 0.312, -1.655]} />
    //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.054, -0.172]} rotation={[2.276, -0.138, -1.935]} />
    //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-2.387, 0.788, 2.254]} />
    //     <mesh name="Shape001002" geometry={nodes.Shape001002.geometry} material={materials.color_stall} position={[-0.096, 1.779, -0.329]} />
    //     <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials.color_stall} position={[0.459, 1.33, -0.076]} rotation={[-0.526, -0.062, -0.104]} />
    //     <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials.color_stall} position={[0.226, 1.053, 0.086]} rotation={[0.721, 0.476, -1.429]} />
    //     <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials.color_stall} position={[0.263, 1.704, -0.285]} rotation={[0.067, -0.261, 1.124]} />
    //     <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials.color_stall} position={[-0.221, 0.962, 0.118]} rotation={[0.691, 0.132, -1.431]} />
    //     <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials.color_stall} position={[-0.096, 1.778, -0.328]} rotation={[0.7, -0.057, -1.656]} />
    //   </group>
    // </group>

// <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <mesh name="Cylinder006" geometry={nodes.Cylinder006.geometry} material={materials.light_001} position={[0, 0.869, -0.22]} />
//         <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
//         <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} rotation={[-0.001, 0.002, -0.001]} />
//         <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
//         <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.Material} position={[0, 0.945, -0.325]} />
//         {/* <mesh name="Sphere" geometry={nodes.Sphere.geometry} material={materials.glass_001} position={[0, 1.382, -0.325]} /> */}
//         <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
//           {<MeshTransmissionMaterial {...config} toneMapped={true} />}
//         </mesh>  
//         <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.Material} position={[0, 0.99, -0.325]} rotation={[0, -0.214, 0]} />
//         <group name="Cylinder010" position={[0, 0.578, -0.325]}>
//           <mesh name="Cylinder009" geometry={nodes.Cylinder009.geometry} material={materials.glass_002}/>          
//           <mesh name="Cylinder009_1" geometry={nodes.Cylinder009_1.geometry} material={materials.light_001} />
//         </group>
//         <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.glass_002} position={[0, 0.873, -0.22]} />
//         <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.Material} position={[0, 0.873, -0.22]} />
//         <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.light_001} position={[0, 0.873, -0.22]} />        
//         <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, 2.618]} />
//         <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[2.757, 1.108, -3.059]} />
//         <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[1.692, 0.975, -2.363]} />
//         <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.004, 1.036, -0.111]} rotation={[2.276, -0.138, -1.935]} />
//         <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[2.762, 1.153, 2.961]} />
//         <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.081, 1.03, -0.174]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.155, 1.028, -0.416]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-2.4, 0.188, 1.938]} />
//         <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[Math.PI, 1.092, 2.88]} />
//         <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[2.762, 1.153, 2.961]} />
//         <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.037, -0.539]} rotation={[-1.831, 0.46, 2.111]} />
//         <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-2.249, 0.647, 2.163]} />
//         <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.015, 1.08, -0.407]} rotation={[2.92, 0.818, 2.775]} />
//         <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-0.783, 0.91, 1.956]} />
//         <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[2.003, 0.678, -2.204]} />
//         <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.054, 1.074, -0.506]} rotation={[1.652, 0.155, -2.056]} />
//         <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.074, 1.093, -0.441]} rotation={[-2.249, 0.647, 2.163]} />
//         <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[2.762, 1.153, 2.961]} />
//         <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.103, 1.088, -0.233]} rotation={[1.831, 0.46, -2.111]} />
//         <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[3.012, 0.312, -1.655]} />
//         <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.054, -0.172]} rotation={[2.276, -0.138, -1.935]} />
//         <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-2.387, 0.788, 2.254]} />
//         <mesh name="Shape001002" geometry={nodes.Shape001002.geometry} material={materials.color_stall} position={[-0.096, 1.779, -0.329]} />
//         <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials.color_stall} position={[0.459, 1.33, -0.076]} rotation={[-0.526, -0.062, -0.104]} />
//         <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials.color_stall} position={[0.226, 1.053, 0.086]} rotation={[0.721, 0.476, -1.429]} />
//         <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials.color_stall} position={[0.263, 1.704, -0.285]} rotation={[0.067, -0.261, 1.124]} />
//         <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials.color_stall} position={[-0.221, 0.962, 0.118]} rotation={[0.691, 0.132, -1.431]} />
//         <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials.color_stall} position={[-0.096, 1.778, -0.328]} rotation={[0.7, -0.057, -1.656]} />
//       </group>
//     </group>

  // <group ref={group} {...props} dispose={null}>
  //   <group name="Scene">
  //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
  //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
  //     <mesh name="powerball_001" geometry={nodes.powerball_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
  //     <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.number} position={[0, 0.945, -0.325]} />      
  //     <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
  //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
  //     </mesh>       
  //     <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.number} position={[0, 0.99, -0.325]} />
  //     <group name="Cylinder010" position={[0, 0.578, -0.325]}>
        
  //       {/* <mesh name="Cylinder009" geometry={nodes.Cylinder009.geometry} material={materials.glass} /> */}
  //       <mesh name="Cylinder009" castShadow receiveShadow geometry={nodes.Cylinder009.geometry}>
  //         <meshBasicMaterial             
  //           toneMapped={false} 
  //           color={[2,1,1]}
  //         />
  //       </mesh>

  //       {/* <mesh name="Cylinder009_1" geometry={nodes.Cylinder009_1.geometry} material={materials.light_001} /> */}
  //       <mesh name="Cylinder009_1" castShadow receiveShadow geometry={nodes.Cylinder009_1.geometry} >
  //       <meshBasicMaterial             
  //           toneMapped={false} 
  //           color={[2,1,1]}
  //         />          
  //       </mesh>
  //     </group>
  //     <mesh name="Cylinder001" geometry={nodes.Cylinder001.geometry} material={materials.glass} position={[0, 0.879, -0.22]} scale={1.3} />
  //     <mesh name="Cylinder003" geometry={nodes.Cylinder003.geometry} material={materials.number} position={[0, 0.879, -0.22]} scale={1.3} />
  //     <mesh name="Cylinder004" geometry={nodes.Cylinder004.geometry} material={materials.light} position={[0, 0.879, -0.22]} scale={1.3} />
  //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, 2.618]} />
  //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[2.757, 1.108, -3.059]} />
  //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[1.692, 0.975, -2.363]} />
  //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.004, 1.036, -0.111]} rotation={[2.276, -0.138, -1.935]} />
  //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[2.762, 1.153, 2.961]} />
  //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.081, 1.03, -0.174]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.155, 1.028, -0.416]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-2.4, 0.188, 1.938]} />
  //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[Math.PI, 1.092, 2.88]} />
  //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[2.762, 1.153, 2.961]} />
  //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.037, -0.539]} rotation={[-1.831, 0.46, 2.111]} />
  //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-2.249, 0.647, 2.163]} />
  //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.015, 1.08, -0.407]} rotation={[2.92, 0.818, 2.775]} />
  //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-0.783, 0.91, 1.956]} />
  //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[2.003, 0.678, -2.204]} />
  //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.054, 1.074, -0.506]} rotation={[1.652, 0.155, -2.056]} />
  //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.074, 1.093, -0.441]} rotation={[-2.249, 0.647, 2.163]} />
  //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[2.762, 1.153, 2.961]} />
  //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.103, 1.088, -0.233]} rotation={[1.831, 0.46, -2.111]} />
  //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[3.012, 0.312, -1.655]} />
  //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.054, -0.172]} rotation={[2.276, -0.138, -1.935]} />
  //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-2.387, 0.788, 2.254]} />
  //     <mesh name="Shape001002" geometry={nodes.Shape001002.geometry} material={materials.color} position={[-0.096, 1.779, -0.329]} />
  //     <mesh name="Torus002" geometry={nodes.Torus002.geometry} material={materials.color} position={[0.459, 1.33, -0.076]} rotation={[-0.526, -0.062, -0.104]} />
  //     <mesh name="Torus004" geometry={nodes.Torus004.geometry} material={materials.color} position={[0.226, 1.053, 0.086]} rotation={[0.721, 0.476, -1.429]} />
  //     <mesh name="Torus005" geometry={nodes.Torus005.geometry} material={materials.color} position={[0.263, 1.694, -0.285]} rotation={[0.067, -0.261, 1.124]} />
  //     <mesh name="Torus006" geometry={nodes.Torus006.geometry} material={materials.color} position={[-0.221, 0.962, 0.118]} rotation={[0.691, 0.132, -1.431]} />
  //     <mesh name="Torus007" geometry={nodes.Torus007.geometry} material={materials.color} position={[-0.096, 1.778, -0.328]} rotation={[0.7, -0.057, -1.656]} />
  //   </group>
  // </group>

    // <group ref={group} {...props} dispose={null}>
    //   <group name="Scene">
    //     <mesh name="new_003" geometry={nodes.new_003.geometry} material={materials['003']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_004" geometry={nodes.new_004.geometry} material={materials['004']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_005" geometry={nodes.new_005.geometry} material={materials['005']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_001" geometry={nodes.new_001.geometry} material={materials['001']} position={[0, 0.9, -0.22]} />
    //     <mesh name="new_002" geometry={nodes.new_002.geometry} material={materials['002']} position={[0, 0.9, -0.22]} />
    //     <mesh name="Cylinder" geometry={nodes.Cylinder.geometry} material={materials.number} position={[0, 0.945, -0.325]} />        
    //     <mesh name="Sphere" geometry={nodes.Sphere.geometry} position={[0, 1.382, -0.325]}>
    //       {<MeshTransmissionMaterial {...config} toneMapped={true} />}
    //     </mesh> 

    //     <mesh name="Cylinder002" geometry={nodes.Cylinder002.geometry} material={materials.number} position={[0, 0.99, -0.325]} rotation={[0, -0.185, 0]} />
    //     <mesh name="Cube" geometry={nodes.Cube.geometry} material={materials.number} position={[0, 0.45, -0.22]} />
    //     <mesh name="Cylinder010" geometry={nodes.Cylinder010.geometry} material={materials.number} position={[0, 0.578, -0.325]} />
    //     <mesh name="001" geometry={nodes['001'].geometry} material={materials['001']} position={[-0.137, 1.031, -0.298]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="002" geometry={nodes['002'].geometry} material={materials['002']} position={[-0.07, 1.031, -0.392]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="003" geometry={nodes['003'].geometry} material={materials['003']} position={[0.026, 1.031, -0.189]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="004" geometry={nodes['004'].geometry} material={materials['004']} position={[0.091, 1.031, -0.362]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="005" geometry={nodes['005'].geometry} material={materials['005']} position={[0.069, 1.031, -0.258]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="006" geometry={nodes['006'].geometry} material={materials['006']} position={[-0.026, 1.031, -0.461]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="007" geometry={nodes['007'].geometry} material={materials['007']} position={[0.149, 1.031, -0.241]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="008" geometry={nodes['008'].geometry} material={materials['008']} position={[0.106, 1.031, -0.173]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="009" geometry={nodes['009'].geometry} material={materials['009']} position={[0.011, 1.043, -0.11]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="010" geometry={nodes['010'].geometry} material={materials['010']} position={[0.079, 1.031, -0.446]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="011" geometry={nodes['011'].geometry} material={materials['011']} position={[0.172, 1.031, -0.345]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="012" geometry={nodes['012'].geometry} material={materials['012']} position={[-0.084, 1.049, -0.174]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="013" geometry={nodes['013'].geometry} material={materials['013']} position={[-0.15, 1.054, -0.409]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="014" geometry={nodes['014'].geometry} material={materials['014']} position={[-0.157, 1.031, -0.216]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="015" geometry={nodes['015'].geometry} material={materials['015']} position={[-0.108, 1.031, -0.479]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="016" geometry={nodes['016'].geometry} material={materials['016']} position={[0.168, 1.031, -0.427]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="017" geometry={nodes['017'].geometry} material={materials['017']} position={[-0.008, 1.043, -0.539]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="018" geometry={nodes['018'].geometry} material={materials['018']} position={[0.136, 1.043, -0.504]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="019" geometry={nodes['019'].geometry} material={materials['019']} position={[-0.067, 1.031, -0.256]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="020" geometry={nodes['020'].geometry} material={materials['020']} position={[-0.125, 1.08, -0.358]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="021" geometry={nodes['021'].geometry} material={materials['021']} position={[0.018, 1.08, -0.419]} rotation={[-Math.PI, 0.743, -Math.PI]} />
    //     <mesh name="022" geometry={nodes['022'].geometry} material={materials['022']} position={[0.123, 1.069, -0.301]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="023" geometry={nodes['023'].geometry} material={materials['023']} position={[0.108, 1.08, -0.412]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="024" geometry={nodes['024'].geometry} material={materials['024']} position={[0.06, 1.064, -0.523]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="025" geometry={nodes['025'].geometry} material={materials['025']} position={[-0.071, 1.095, -0.437]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="026" geometry={nodes['026'].geometry} material={materials['026']} position={[0.104, 1.091, -0.224]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="027" geometry={nodes['027'].geometry} material={materials['027']} position={[-0.116, 1.091, -0.25]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="028" geometry={nodes['028'].geometry} material={materials['028']} position={[-0.216, 1.037, -0.314]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="029" geometry={nodes['029'].geometry} material={materials['029']} position={[0.176, 1.061, -0.172]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="030" geometry={nodes['030'].geometry} material={materials['030']} position={[-0.024, 1.08, -0.216]} rotation={[-Math.PI, 1.092, -Math.PI]} />
    //     <mesh name="Torus001" geometry={nodes.Torus001.geometry} material={materials['glass.001']} position={[-0.099, 1.766, -0.329]} rotation={[Math.PI / 3, 0, 0]} scale={1.094} />
    //   </group>
    // </group>
  )
}


// useGLTF.preload('/powerball/lottomachine_white.glb')
