import React, { Component, useState, useEffect, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import { Box, Container, Typography, LinearProgress } from '@mui/material';
import useSWR from 'swr';

import clsx from 'clsx';
import Notify from '../../components/betting/Notify';

import { useBettingStyles } from './style/BettingStyles';
import RandomBoard from '../../components/betting/RandomBoard'; //'./OddEven';

import { useLocation } from 'react-router';
import { ChangeProgress, GetAddHttpURL } from '../../util/jsUtil';

import { getCookie } from '../../lib/cookie';
import useLang from '../../lib/hook/useLang'; //'../lib/hook/useLang';
import useUser from '../../lib/hook/useUser';

import i18n from '../../lang/i18n';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useStylesNotify } from './style/NotifyComponentStyles';

import { ReactComponent as BettingLine } from '../../assets/bettingLine.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as HorseListTitleGradient } from '../../assets/horseListTitleGradient.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock.svg';
import { ReactComponent as WarningIcon } from '../../img/WarningIcon.svg';
import { ReactComponent as SuccessFilledIcon } from '../../img/SuccessFilledIcon.svg';
import { ReactComponent as CardGameIcon } from '../../img/cardGame.svg';
// import { ReactComponent as BettingIcon } from '../../img/BettingIcon.svg';
import BlockIcon from '../../img/Block.gif';
import { ReactComponent as FailIcon } from '../../img/FailIcon.svg';

import HashDialog from './HashDialog';
import CommonDialog from '../../components/betting/CommonDialog-v1';
import * as config from '../../config';
import * as define from '../../define';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { use } from 'i18next';
import OddEvenTable from '../../components/OddEvenTable';

import { useCoinDrop } from '@/lib/hook';
import { CoinDropButton, CoinDropResetButton } from '../../components/coindrop';
import { useCoindropStore } from '@/store/coindrop';


var { webSocketIO } = require('../../socketClient.js');
var _resUserInitCallback = { isconfirm: false, endcallback: undefined };
var _accRaceList = [];
function Counter(props) {
  const { lngT } = useLang('racing:intro');

  const [counter, setCounter] = React.useState(60);

  useEffect(() => {
    webSocketIO.data.onCounter = updateCount;
  }, []);

  const updateCount = newCounter => {
    setCounter(newCounter);
    // console.log('updateCount : ' + newCounter);
  };
  //function onMessage(e) {
  //
  //}

  let needBlink = webSocketIO.data.counter < 60;
  let minute = Math.trunc(webSocketIO.data.counter / 60);
  let second = webSocketIO.data.counter - minute * 60;

  let className = needBlink
    ? 'text-[20px] D:text-[24px] text-[#EFFF8E] animate-blink'
    : 'text-[20px] D:text-[24px] text-[#EFFF8E]';
  let remainTime = needBlink
    ? `00:${String(webSocketIO.data.counter).padStart(2, '0')}`
    : `${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;

  return (
    <>
      {props.connected === false || (webSocketIO.data.isCounterOpen && webSocketIO.data.counter === 0) ? (
        ''
      ) : (
        <div className="flex flex-col w-full items-center py-[16px] D:pt-0 D:pb-[24px]">
          <div className="flex flex-row items-center space-x-[8px] h-[30px]">
            <ClockIcon className="w-[24px] h-[24px] fill-[#EFFF8E] stroke-[#EFFF8E]" />
            <b className={className}>{remainTime}</b>
          </div>
          <div className="tracking-widest text-[14px] text-white font-semibold">{lngT('list:remainingTime')}</div>
        </div>
      )}
    </>
  );
}

export default function RandomBetting(props) {
  const { lngT } = useLang('racing:intro');
  const { lngT: etcsLngT } = useLang('etcs');
  // const countRef = useRef(null);
  const classes = useBettingStyles();
  // const classesBetting = useBettingStyles();
  const [newMsg, setNewMsg] = React.useState(0);
  const [, updateState] = React.useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);
  const { state } = useLocation();
  // const [counter, setCounter] = React.useState(60);
  const [connected, setConnected] = useState(false);
  const [selectOddEven, setSelectOddEven] = useState(0);
  const [selectDigit, setSelectDigit] = useState(0);
  const [selectShowDigit, setSelectShowDigit] = useState(0);
  const [activelistBettingValue, setactivelistBettingValue] = useState(false);
  const [bettingValue, setBettingValue] = useState(0);

  const [selectedValue, setSelectedValue] = useState(1);
  const [selectedinfo, setselectedinfo] = useState({
    type: 0,
    index: 0,
    value: 0,
    currency: 'coin',
  });
  const navigate = useCustomNavigate();

  var dispatch = useDispatch();
  //var selector = useSelector();bettingValue
  const isHistory = useSelector(state => state.isHistory);
  const [raceList, setRaceList] = useState([]);

  // Random Board

  const [activeButton, setActiveButton] = useState(true);
  const [activeShowButton, setActiveShowButton] = useState(true);
  const [selectedSingleButton, setSelectedSingleButton] = useState(null);
  const [selectedShowButton, setSelectedShowButton] = useState(null);

  const initialArray1 = [
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
  ];
  const initialArray2 = [
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
    { betting: 0 },
  ];

  const [arraySingleBetting, setArraySingleBetting] = useState(initialArray1);
  const [arrayShowBetting, setArrayShowBetting] = useState(initialArray2);

  //mini game question
  const { openGamePopup, closeGamePopup, isGamePopupOpen } = useCoindropStore();
  // console.log('???', canDropCoin);

  const showResultPopup = memo => {
    setModal({ comfirmModal: true, confirmMemo: memo });
    setOpenComfirm(true);
    setTitleComfirm('Betting');
    setContentComfirm(memo);

    setTimeout(() => {
      setModal({ comfirmModal: false });
      setOpenComfirm(false);
      setselectedinfo({
        type: 0,
        index: 0,
        value: 0,
        currency: 'coin',
      });

      setSelectOddEven(0);
      setSelectDigit(0);
      setSelectShowDigit(0);
      HandleSetactivelistBettingValue(false);
    }, 1000);
  };

  window.onfocus = () => {
    //setIsFocused(true);
    console.log('focus onfocus');
  };

  window.onblur = () => {
    //setIsFocused(false);
    console.log('focus onblur');
  };

  var count = 0;
  const addCount = () => {
    setNewMsg(++count);
  };

  const [modal, setModal] = useState({
    showModal: true /* false */,
    comfirmModal: true,
    confirmMemo: /*'connecting...'*/ lngT('contents:connecting...'),
  });

  const HandleSetactivelistBettingValue = value => {
    setactivelistBettingValue(value);
  };

  const [openCommon, setOpenCommon] = useState(false);
  const handleCloseOpenCommon = () => {
    setOpenCommon(false);
  };
  const [openComfirm, setOpenComfirm] = useState(false);
  const [titleComfirm, setTitleComfirm] = useState(/*'success'*/ lngT('contents:success'));
  const [contentComfirm, setContentComfirm] = useState(/*'betting'*/ lngT('contents:betting'));
  const handleCloseOpenComfirm = () => {
    setOpenComfirm(false);
    setOpenMiniGame(false);
  };
  const [openWaitting, setOpenWaitting] = useState(false);
  const [openMiniGame, setOpenMiniGame] = useState(false);
  const [titleWaitting, setTitleWaitting] = useState(/*'connecting...'*/ lngT('contents:connecting...'));
  const handleCloseOpenWaitting = () => {
    setOpenWaitting(false); /*  !openWaitting*/
  };

  //Hashdialog
  const [user] = useUser();
  const [openHashDialog, setOpenHashDialog] = React.useState({
    open: false,
    block: 0,
    hash: '',
    progress: {},
  });

  const handleClickOpenHashDialog = (block, hash, game, progress) => {
    setOpenHashDialog({
      open: true,
      block: block,
      hash: hash,
      progress: {
        game: game,
        userName: user?.name,
        progress: progress,
        lang: i18n.language,
      },
    });
  };

  const handleHashDialogClose = () => {
    setOpenHashDialog({
      ...openHashDialog,
      open: false,
    });
  };

  const showCardGamePopUp = () => {
    setOpenMiniGame(true);
    setTitleComfirm(etcsLngT(/*'Play Game'*/ 'inBettingCardGame:title'));
    setContentComfirm(etcsLngT(/*'Ready to Play?'*/ 'inBettingCardGame:content'));
  };

  const handleComfirmGameModal = () => {
    setOpenMiniGame(false);
    openGamePopup();
  };

  const [rank, setRank] = useState([0, 0, 0]);

  // betting popup cancel
  const handleCloseModal = () => {
    setModal({ showModal: false, comfirmModal: false });
  };
  const handleComfirmModal = () => {
    setModal({ comfirmModal: true, confirmMemo: /*'betting...'*/ lngT('contents:betting...') });
    handleCloseOpenCommon();
    handleCloseOpenComfirm();
    setOpenWaitting(true);
    setTitleWaitting(/*'betting...'*/ lngT('contents:betting...'));

    callbetting(
      selectedinfo.type,
      selectedinfo.index,
      selectedinfo.value,
      selectedinfo.currency,
      true,
      showResultPopup,      
    );
  };

  function handleSetModal(state, selinfo) {
    setModal(state);
    setselectedinfo(selinfo);
    setOpenCommon(state.showModal);
  }
  const requestTerm = 1000;
  const [openBetDialog, setOpenBetDialog] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleClose = () => {
    //setOpenHashDialog(false);
    setOpenBetDialog(false);
    setOpenSuccess(false);
  };

  const oddEvenTitle = lngT('footer:guide:oddEven:title');
  const oddEvenContent = lngT('footer:guide:oddEven:description');
  const singleTitle = lngT('footer:guide:single:title');
  const singleContent = lngT('footer:guide:single:description');
  const showTitle = lngT('footer:guide:show:title');
  const showContent = lngT('footer:guide:show:description');
  var clientData = {};
  // let progressNum;
  const callbetting = (type, index, value, currency, isconfirm, endcallback) => {
    // goodsTypeServer = 'free';
    let reqSendParams = define.USE_FREECOIN ? 
    JSON.stringify({
      protocol: 'reqBetting',
      type: type,
      no: index,
      value: value,
      currency: currency,
      coin: define.GoodsTypeServer[props.goodsType]
    }) :
    JSON.stringify({
      protocol: 'reqBetting',
      type: type,
      no: index,
      value: value,
      currency: currency,
    }); 

    webSocketIO.send(
      reqSendParams
    );

    _resUserInitCallback.isconfirm = isconfirm;
    _resUserInitCallback.endcallback = endcallback;
    setInBettingPage(false);
  };

  const [iconComponent, setIconComponent] = useState(<SuccessFilledIcon />);

  // const { canDropCoin } = useCoinDrop({ requestable: true });
  const [isInBettingPage, setInBettingPage] = useState(false);
  console.log('@#$', isInBettingPage);

  // useEffect(() => {
  //   if (!isInBettingPage) {
  //     if (isGamePopupOpen) closeGamePopup();
  //     return;
  //   }

  //   console.log('#$#$$$', canDropCoin, isInBettingPage);
  //   if (canDropCoin) {
  //     showCardGamePopUp();
  //   }
  // }, [canDropCoin, isInBettingPage]);

  function onMessage(e) {
    // console.log(e.data, 'Message received');

    var data = JSON.parse(e.data);
    // progressNum = data.progress;

    if (data.protocol === 'changeProgress') {
      props.CallBackChangeProgress(data.idGame, data.progress);

      if (data.progress === define.PROGRESS_LIVE) webSocketIO.data.block = 1;

      // props.onProgress(changeProgress);
      //reqUserInit();
      console.log('changeProgress ' + data.progress + ' ' + data.idGame);
    } else if (data.protocol === 'resProgress') {
      console.log(`progressTest resProgress progress:${data.progress} error:${data.error} game:${data.idGame}`);
      if (data.error !== undefined) {
        navigate('/LobbyGame');
      } else {
        if (data.progress === define.PROGRESS_INTRO1) {
          props.CallBackResultToBet(data.progress, data.idGame);
          setBettingValue(0);
          setSelectedValue(undefined);
        } else if (data.progress === define.PROGRESS_MAINLOBBY) {
          navigate('/LobbyGame');
        }
      }
    } else if (data.protocol === 'resBetting') {
      handleCloseOpenWaitting();
      setBettingValue(0);
      setIconComponent(<SuccessFilledIcon />);
      setTitleComfirm('Betting');

      if (_resUserInitCallback.endcallback !== undefined && data.error !== undefined) {
        var resMsg = data.error;
        if (resMsg === 'notEnoughCoin') {
          resMsg = lngT(`contents:notEnoughCoin`);
        }

        setIconComponent(<FailIcon />);
        // if (resMsg === 'do not betting this') {
        //   setIconComponent(<FailIcon />);
        // } else {
        //   setIconComponent(<SuccessFilledIcon />);
        // }
        _resUserInitCallback.endcallback(resMsg);
        return;
      }
      //(data.betting !== undefined) {
      // var total = webSocketIO.data.total[data.type];
      //total += data.value;
      webSocketIO.data.total[data.type] = data.total;
      var index = data.no;
      if (data.idUser === webSocketIO.data.idUser) {
        webSocketIO.data.listRow[data.type][index].betting = data.value;
        //webSocketIO.data.listRow[data.type][index].currency = data.currency;
      }

      for (var n = 0; n < webSocketIO.data.listRow[data.type].length; n++) {
        webSocketIO.data.listRow[data.type][n].odds = data.odds[n];
      }
      if (data.type === 0) {
        if (webSocketIO.data.total[0] > 0) {
          webSocketIO.data.bettingOddEven[0] = (data.bettingOddEven[0] / webSocketIO.data.total[0]) * 100;
          webSocketIO.data.bettingOddEven[1] = (data.bettingOddEven[1] / webSocketIO.data.total[0]) * 100;
        }
      }

      SetSingleShowBetting();

      if (_resUserInitCallback.endcallback !== undefined && data.idUser === webSocketIO.data.idUser) {
        resMsg = data.error === undefined || data.error === '' ? 'Success' : 'Error : ' + data.error;
        _resUserInitCallback.endcallback(resMsg);
      }
    } else if (data.protocol === 'resUserInit') {
      webSocketIO.InitData();

      // 베팅페이지일 경우에만
      if (data.isCounterOpen) {
        setInBettingPage(true);
      }

      // odd even 버튼 초기화
      setSelectOddEven(0);
      setSelectDigit(0);
      setSelectShowDigit(0);

      getRaceListInfo();
      getHorseListInfo();
      setTab(define.BETTYPE_ODDEVEN);
      setTabOdd(OVERUNDERTAB);
      HandleSetactivelistBettingValue(false);
      // ;

      //(data.userBetting !== undefined) {
      for (var n = 0; n < 3; n++) {
        for (var m = 0; m < webSocketIO.data.listRow[n].length; m++) {
          if (data.userBetting.betting == null) {
            webSocketIO.data.listRow[n][m].betting = 0;
          } else {
            webSocketIO.data.listRow[n][m].betting = data.userBetting.betting[n][m];
          }
          //webSocketIO.data.listRow[n][m].currency = data.userBetting.currency[n][m];
          webSocketIO.data.listRow[n][m].odds = data.odds[n][m];
        }
        webSocketIO.data.total[n] = data.total[n];
      }
      if (webSocketIO.data.listBettingValue <= 0) {
        for (var n = 0; n < data.listBettingValue.length; n++) {
          webSocketIO.data.listBettingValue.push(data.listBettingValue[n]);
        }
      }
      if (webSocketIO.data.total[0] > 0) {
        if (webSocketIO.data.total[0] <= 0) {
          webSocketIO.data.bettingOddEven[0] = 0;
          webSocketIO.data.bettingOddEven[1] = 0;
        } else {
          webSocketIO.data.bettingOddEven[0] = (data.bettingOddEven[0] / webSocketIO.data.total[0]) * 100;
          webSocketIO.data.bettingOddEven[1] = (data.bettingOddEven[1] / webSocketIO.data.total[0]) * 100;
        }
      }

      console.log(`userinit test in: ${webSocketIO.data.isCounterOpen}`);
      if (webSocketIO.data.userIninited) {
        webSocketIO.data.isCounterOpen = true; //data.isCounterOpen;
        //console.log(`userinit test out 1: ${webSocketIO.data.isCounterOpen}`);
      } else {
        webSocketIO.data.isCounterOpen = true; //data.isCounterOpen;
        webSocketIO.data.userIninited = true;
        //console.log(`userinit test out 2: ${webSocketIO.data.isCounterOpen}`);
      }

      webSocketIO.data.counter = 0;
      webSocketIO.data.idUser = data.idUser;
      webSocketIO.data.listChat = [];

      SetSingleShowBetting();
      setConnected(true);
      setModal({ comfirmModal: false });
      setOpenWaitting(false);
    } else if (data.protocol === 'resCounter') {
      console.log('userinit test counter:', data.counter);

      webSocketIO.data.counter = data.counter;
      webSocketIO.data.isCounterOpen = data.counter > 0;
      webSocketIO.data.block = 0;

      if (webSocketIO.data.onCounter) {
        webSocketIO.data.onCounter(webSocketIO.data.counter);
      }
      //return;
    }
    // else if (data.protocol === 'resBlock') {
    //   console.log('block:', data.block);
    //   webSocketIO.data.block = data.block;
    //   //return;
    // }
    else if (data.protocol === 'reqChatting') {
      webSocketIO.data.listChat.push({
        idUser: data.idUser,
        name: data.nameUser,
        type: data.type,
        msg: data.msg,
      });
      addCount();
    }
  }

  function SetSingleShowBetting() {
    // 걸어둔 금액 가져오기
    const arraySingleBet = webSocketIO.data.listRow[define.BETTYPE_SINGLE];
    const arraySingleBetLst = [];
    for (var i = 0; i < arraySingleBet.length; ++i) {
      arraySingleBetLst.push({ betting: arraySingleBet[i].betting });
    }
    setArraySingleBetting(arraySingleBetLst);

    const arrayShowBet = webSocketIO.data.listRow[define.BETTYPE_SHOW];
    const arrayShowBetLst = [];
    for (var i = 0; i < arrayShowBet.length; ++i) {
      arrayShowBetLst.push({ betting: arrayShowBet[i].betting });
    }
    setArrayShowBetting(arrayShowBetLst);
  }
  function oncloseCallback() {
    console.log('call: oncloseCallback');
    webSocketIO.oncloseCallback = undefined;

    navigate('/LobbyGame');
  }

  function connectSocket() {
    if (webSocketIO.websocket === null) {
      if(define.USE_FREECOIN)      
        webSocketIO.connect('','',define.GoodsTypeServer[props.goodsType]);
      else
        webSocketIO.connect();

      console.log('progresscombine connectSocket');
    }
    //webSocketIO.close(); /**/

    webSocketIO.websocket.onmessage = onMessage;
    webSocketIO.oncloseCallback = oncloseCallback;

    Interval();
    function Interval() {
      setTimeout(() => {
        // console.log('progresscombine Interval1');
        if (webSocketIO.data.block > 0) {
          console.log('RandomBetting CallBackBetToGame');
          webSocketIO.data.block = 0;
          webSocketIO.data.isCounterOpen = true;
          props.CallBackBetToGame();
          handleCloseOpenWaitting();
          setArraySingleBetting(initialArray1);
          setArrayShowBetting(initialArray2);

          //return;
        }
        if (!webSocketIO.data.isCounterOpen) {
          // waiting hash oepn.
          handleCloseOpenCommon();
          handleCloseOpenComfirm();

          setModal({ comfirmModal: true, confirmMemo: /*'waitting hash...'*/ lngT('contents:waitingHash') });
          setOpenWaitting(true); /*true*/
          setTitleWaitting(/*'waitting hash...'*/ lngT('contents:waitingHash'));

          setSelectOddEven(0);
          setSelectDigit(0);
          setSelectShowDigit(0);
          setInBettingPage(false);
        }

        Interval();

        return () => {
          clearTimeout();
        };
      }, requestTerm);
    }

    return () => {
      webSocketIO.close();
      console.log('컴포넌트가 화면에서 사라짐');
    };
  }

  useEffect(() => {
    console.log('start gamestate ' + clientData.game);
    if (clientData !== undefined) {
      webSocketIO.data.game = clientData.game;
    } else {
      webSocketIO.data.game = 0;
    }
    var token = getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);
    if (token !== undefined) {
      webSocketIO.data.tokenUser = token;
    }

    // token !== undefined || token!=="deleted"
    //if (!isHistory) {
    if (!isHistory && token !== undefined && token !== 'deleted') {
      connectSocket();
      setTitleWaitting(lngT('contents:connecting...'));
    }

    if (webSocketIO.data.tokenUser === undefined || webSocketIO.data.tokenUser === 'undefined') {
      ChangeProgress(0, 0, 0, i18n.language);
      return;
    }
  }, [state]);

  // 말 리스트 ui 상태
  const [isHorseListOpen, setHorseListOpen] = useState(false);
  const [horseListAnimation, setHorseListAnimation] = useState(false);

  const isAnimating = useRef(false);

  const HORSEPAGE = 0;
  const HISTORYPAGE = 1;
  const OVERUNDERTAB = 0;
  const ODDEVENTAB = 1;
  const [tab, setTab] = useState(define.BETTYPE_ODDEVEN);
  const [tabRace, setTabRace] = useState(HISTORYPAGE);
  const [tabOdd, setTabOdd] = useState(OVERUNDERTAB);
  const [someKey, setSomeKey] = useState();

  const [horseList, setHorseList] = useState([]);

  //OddEven OverUnder
  const [newOddEven, setNewOddEven] = useState([]);
  const [newOverUnder, setNewOverUnder] = useState([]);
  const [raceListInfo, setRaceListInfo] = useState({});

  const handleClickShowHorsList = () => {
    if (isAnimating.current) return;
    isAnimating.current = true;
    setHorseListAnimation(!horseListAnimation);

    setTimeout(() => {
      isAnimating.current = false;
      setHorseListOpen(!isHorseListOpen);
    }, 300);
  };

  const getHorseListInfo = async () => {
    var url = config.GetPrefixURL() + config.GetBetServerURL();
    //console.log('progresscombine 0' + webSocketIO.data.game);
    fetch(`${url}/api/v1/racedetail`, {
      method: 'GET', // 또는 'PUT's
      headers: {
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(params),
    })
      .then(response => {
        return response.json();
      })

      .then(data => {
        //SafeLog("racedetail data : " + JSON.stringfy(data), config.LOG_DEVELOPER_1);
        //var newList = [];
        var resHorseList = [];

        if (data.ErrorCode === 0) {
          for (var n = 0; n < data.Race.HorseList.length; n++) {
            //var strLatest = JSON.stringify(data.Race.HorseList[n].latestGames);
            data.Race.HorseList[n].latestGames = data.Race.HorseList[n].latestGames.slice(0, 5);
            // if (data.Race.HorseList[n].name.length > 7) {
            //   data.Race.HorseList[n].name = data.Race.HorseList[n].name.substr(0, 6) + '...';
            // }
            resHorseList.push({
              no: n + 1,
              name: data.Race.HorseList[n].name,
              game: data.Race.HorseList[n].countRacing,
              wins: data.Race.HorseList[n].countWin,
              top3s: data.Race.HorseList[n].countTop3,
              latest: data.Race.HorseList[n].latestGames,
            });
          }

          if (data.Race.Progress === define.PROGRESS_MAINLOBBY) {
            navigate('/LobbyGame');
          }
        }

        console.log('progresscombine racedetail Progress : ' + JSON.stringify(data.Race));

        setHorseList(resHorseList);
        //console.log('test game2' + webSocketIO.data.game);
        // connectSocket();
        // connectSocket();
      });
  };

  //경기 리스트 갖고 오기 racelist
  const getRaceListInfo = async () => {
    var url = config.GetPrefixURL() + config.GetBetServerURL();

    console.log('url' + url);

    try {
      const responseInfo = await fetch(`${url}/api/v1/racelistinfo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!responseInfo.ok) {
        throw new Error('Network response was not ok');
      }
      const dataInfo = await responseInfo.json();
      console.log('dataInfo', dataInfo);

      if (!isHistory) {
        dispatch({
          type: 'CLIENTDATA',
          game: dataInfo.Game,
        });
      }
      // ChangePageOnlySetCookie(
      //   `/gameresult?game=${dataInfo.Game}&user=${clientData.user}&progress=${clientData.progress}&lan=${clientData.lan}`
      // );
      //const response = await fetch(`${url}/api/v1/racelist?start=61&end=71&user=undefined&option=`, {

      // const response = await fetch(
      //   `${url}/api/v1/racelist?start=${dataInfo.Now - 11}&end=${dataInfo.Now - 1}&user=undefined&option=`,
      //   {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );

      let startIndex = _accRaceList.length === 0 ? 0 : _accRaceList[_accRaceList.length - 1].index;
      const response = await fetch(
        `${url}/api/v1/racelist?todayStart=${startIndex}&nowEnd=${-1}&user=undefined&option=`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      //console.log('RaceList :', data.RaceList);
      // console.log('dataInfo :' + JSON.stringify(dataInfo));

      // if(startIndex===0)
      //   g_RaceList = data.RaceList;
      // else
      // {
      //   data.RaceList.forEach(element => {
      //     // if(g_RaceList.find(obj=>obj.index==element.index))
      //     g_RaceList.push(element);
      //   });
      // }

      data.RaceList.forEach(element => {
        if (element.rank !== null) _accRaceList.push(element);
      });

      console.log(`RaceList (${_accRaceList.length}):`, _accRaceList);

      //const tempraceList = data.RaceList;
      setRaceList(_accRaceList);
      setRaceListInfo(dataInfo);
    } catch (e) {
      console.error(e);
      throw e; // You can choose to rethrow the error if needed
    }
  };

  if (!props.showRandomBetting) return;

  console.log('selectOddEven : ' + selectOddEven);

  // Betting Board Component
  const BettingBoard = () => {
    return (
      <section className="w-full h-full space-y-8">
        <div className="hidden D:flex justify-center items-center w-full">
          <div className="relative w-full h-[54px]">
            <div className="bg-gradient-to-r from-white/0 via-white to-white/0 absolute w-full T:w-2/3 h-full left-1/2 -translate-x-1/2">
              <div className="relative flex justify-center items-center w-full h-full text-[20px] font-bold font-orbitron text-[#222]">
                {/*Betting */ lngT('title')}
              </div>
            </div>
          </div>
        </div>
        <Container maxWidth="md" className={classes.container}>
          <Counter connected={connected} />

          <div className="flex flex-col space-y-4 ">
            <div className="flex flex-row w-full h-[48px] D:h-[60px] rounded-t-2xl D:text-xl text-lg overflow-hidden">
              <button
                style={{
                  boxShadow: '4px 10px 4px rgba(0, 0, 0, 0.25)',
                }}
                className={`flex justify-center items-center flex-1 text-white hover:brightness-110 transition-all ${
                  tab === define.BETTYPE_ODDEVEN
                    ? 'bg-[#7C9B3F] brightness-125 text-opacity-100'
                    : 'bg-[#546739] brightness-100 text-opacity-80 '
                }`}
                onClick={() => setTab(define.BETTYPE_ODDEVEN)}
              >
                {lngT('buttons:oddeven')}
              </button>
              <button
                style={{
                  boxShadow: '-4px -10px -4px rgba(0, 0, 0, 0.25)',
                }}
                className={`flex justify-center items-center flex-1 text-xl text-white hover:brightness-110 transition-all ${
                  tab === define.BETTYPE_SINGLE
                    ? 'bg-[#7C9B3F] brightness-125  text-opacity-100'
                    : 'bg-[#546739] brightness-100 text-opacity-80 '
                }`}
                onClick={() => setTab(define.BETTYPE_SINGLE)}
              >
                {lngT('buttons:single')}
              </button>
              <button
                style={{
                  boxShadow: '4px 10px 4px rgba(0, 0, 0, 0.25)',
                }}
                className={`flex justify-center items-center flex-1 text-xl text-white hover:brightness-110 transition-all ${
                  tab === define.BETTYPE_SHOW
                    ? 'bg-[#7C9B3F] brightness-125 text-opacity-100'
                    : 'bg-[#546739] brightness-100 text-opacity-80 border border-black/20'
                }`}
                onClick={() => setTab(define.BETTYPE_SHOW)}
              >
                {lngT('buttons:show')}
              </button>
            </div>
            <div>
              {tab === define.BETTYPE_ODDEVEN ? (
                <Notify title={oddEvenTitle} content={oddEvenContent} />
              ) : tab === define.BETTYPE_SINGLE ? (
                <Notify title={singleTitle} content={singleContent} />
              ) : tab === define.BETTYPE_SHOW ? (
                <Notify title={showTitle} content={showContent} />
              ) : (
                ''
              )}
            </div>
            <RandomBoard
              bettype={tab}
              handleSetModal={handleSetModal}
              bettingValue={bettingValue}
              setBettingValue={setBettingValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              connected={connected}
              selectDigit={selectDigit}
              setSelectDigit={setSelectDigit}
              selectShowDigit={selectShowDigit}
              setSelectShowDigit={setSelectShowDigit}
              selectOddEven={selectOddEven}
              setSelectOddEven={setSelectOddEven}
              setActiveButton={setActiveButton}
              activeButton={activeButton}
              setActiveShowButton={setActiveShowButton}
              activeShowButton={activeShowButton}
              setSelectedSingleButton={setSelectedSingleButton}
              selectedSingleButton={selectedSingleButton}
              setSelectedShowButton={setSelectedShowButton}
              selectedShowButton={selectedShowButton}
              arraySingleBetting={arraySingleBetting}
              setArraySingleBetting={setArraySingleBetting}
              arrayShowBetting={arrayShowBetting}
              setArrayShowBetting={setArrayShowBetting}
              HandleSetactivelistBettingValue={HandleSetactivelistBettingValue}
              activelistBettingValue={activelistBettingValue}
              goodsType={props.goodsType}
            />
          </div>
        </Container>
      </section>
    );
  };

  return (
    <div
      className={`${
        isHorseListOpen ? 'D:px-6 T:px-10' : ''
      } relative flex flex-col D:flex-row justify-center w-full min-h-screen py-[64px] bg-[#222] overflow-x-auto`}
    >
      {/* mobile button */}
      <div className="block D:hidden w-full h-[48px] px-[16px] mb-4">
        <button
          className={`relative flex justify-center items-center w-full h-full px-[24px] text-[#222] text-[16px] font-bold bg-gradient-to-r from-white/10 via-white to-white/10 rounded-lg cursor-pointer ${
            isHorseListOpen ? 'rounded-b-none' : ''
          }`}
          onClick={() => {
            setHorseListOpen(!isHorseListOpen);
            setHorseListAnimation(!horseListAnimation);
          }}
        >
          {/*Betting Info*/ lngT('information:title')}
          <ChevronRightIcon
            className={`absolute right-[24px] top-1/2 -translate-y-1/2 w-[16px] h-[16px] ${
              isHorseListOpen ? 'rotate-90' : 'rotate-[270deg]'
            }`}
          />
        </button>
      </div>
      <section
        className={`${horseListAnimation ? 'D:animate-fadeIn' : 'D:animate-fadeOut'} ${
          isHorseListOpen ? 'block ' : 'hidden'
        } flex-1 justify-center items-center D:w-1/2 h-full w-full`}
      >
        <div className="w-full flex flex-col items-center D:px-[20px] LD:px-[100px]">
          <div className="hidden D:flex justify-center items-center w-2/3 mb-10">
            <div className="relative w-full h-[54px]">
              <div className="bg-gradient-to-r from-white/0 via-white to-white/0 absolute w-full T:w-2/3 h-full left-1/2 -translate-x-1/2">
                <div className="relative flex justify-center items-center w-full h-full text-[20px] font-bold font-orbitron text-center text-[#222]">
                  {/*Betting Info*/ lngT('information:title')}
                </div>
              </div>
            </div>
          </div>
          {/* tab */}
          <div className="flex flex-col space-y-4 w-full px-10">
            <div className="flex flex-row w-full h-[48px] D:h-[60px] rounded-t-2xl D:text-xl text-lg overflow-hidden">
              <button
                style={{
                  boxShadow: '-4px -10px -4px rgba(0, 0, 0, 0.25)',
                }}
                className={`flex justify-center items-center flex-1 text-xl text-white hover:brightness-110 transition-all border-l border-black/20 ${
                  tabRace === HISTORYPAGE
                    ? 'bg-[#4680b6] brightness-125  text-opacity-100'
                    : 'bg-[#4174a4] brightness-100 text-opacity-80'
                }`}
                onClick={() => setTabRace(HISTORYPAGE)}
              >
                {/* History */ `${lngT('information:history')}`}
              </button>
              <button
                style={{
                  boxShadow: '4px 10px 4px rgba(0, 0, 0, 0.25)',
                }}
                className={`flex justify-center items-center flex-1 text-xl text-white hover:brightness-110 transition-all border-r border-black/20  ' ${
                  tabRace === HORSEPAGE
                    ? 'bg-[#4680b6] brightness-125 text-opacity-100'
                    : 'bg-[#4174a4] brightness-100 text-opacity-80'
                }`}
                onClick={() => setTabRace(HORSEPAGE)}
              >
                {/*Horse List*/ lngT('list:title')}
              </button>
            </div>
            <div>
              {tabRace === HISTORYPAGE ? (
                <div key={someKey}>
                  <OddEvenTable
                    setTabOdd={setTabOdd}
                    tabOdd={tabOdd}
                    raceList={raceList}
                    ODDEVENTAB={ODDEVENTAB}
                    OVERUNDERTAB={OVERUNDERTAB}
                  />
                </div>
              ) : (
                // <div className="flex flex-col w-full">
                //   <div className="flex flex-col pt-[2px] D:pt-2">
                //     <div className="relative flex flex-row w-full h-[35px] T:h-[55px] D:rounded-t-md overflow-hidden">
                //       <div className="absolute w-full h-full bg-[#3C6D9B] border-b border-[#222]" />
                //       <div className="relative flex flex-row w-full h-full text-white text-[12px] D:text-[16px] font-medium divide-x divide-[#222] text-center text-ellipsis">
                //         <div className="flex justify-center items-center px-[5px] w-[60px] min-[768px]:w-[80px] D:w-[120px]">
                //           ID
                //         </div>
                //         <div className="flex justify-center items-center px-[5px] w-full D:flex-1 truncate">
                //           <span className=""> {/* Rank */ `${lngT('information:rank')}`}</span>
                //         </div>
                //         <div className="flex justify-center items-center px-[5px] w-[80px] D:w-[140px]">
                //           {/*Hash*/ 'Hash'}
                //         </div>
                //       </div>
                //     </div>
                //     <div className="flex flex-col w-full divide-y divide-[#222]">
                //       {/* {console.log(raceList)} */}
                //       {/* {raceList.reverse().map( */}
                //       {raceList.map(
                //         (race, index) =>
                //           race.rank !== null && (
                //             <div
                //               key={index}
                //               className={`flex flex-row w-full h-[35px] T:h-[80px] divide-x divide-[#222] text-white text-[10px] max-[992px]:text-[12px] D:text-[16px] ${
                //                 index % 2 === 0 ? 'bg-[#4E4E4E]' : 'bg-[#4E4E4E]/80'
                //               }`}
                //             >
                //               <div className="flex justify-center items-center px-[5px] w-[60px] min-[768px]:w-[80px] D:w-[120px]">
                //                 {race.index}
                //               </div>
                //               <div className="flex justify-evenly T:justify-center items-center px-0 T:px-4 w-full D:flex-1 T:space-x-2 ">
                //                 {/* {console.log('rank' + rank)} */}
                //                 {race.rank?.map(rank => (
                //                   <>
                //                     {rank === race.rank[0] ? (
                //                       <div className={clsx(classes.numberStyleRank, classes.numOneRank)}>{rank}</div>
                //                     ) : rank === race.rank[1] ? (
                //                       <div className={clsx(classes.numberStyleRank, classes.numTwoRank)}>{rank}</div>
                //                     ) : rank === race.rank[2] ? (
                //                       <div className={clsx(classes.numberStyleRank, classes.numThreeRank)}>{rank}</div>
                //                     ) : rank === race.rank[3] ? (
                //                       <div className={clsx(classes.numberStyleRank)}>{rank}</div>
                //                     ) : rank === race.rank[4] ? (
                //                       <div className={clsx(classes.numberStyleRank)}>{rank}</div>
                //                     ) : (
                //                       <div className={clsx(classes.numberStyleRank, classes.numHide)}>{rank}</div>
                //                     )}
                //                   </>
                //                 ))}

                //                 {/*JSON.stringify(horseItem.latest)*/}
                //                 {/*<div className={clsx(classes.numberStyle, classes.numOne)}>1</div>
                //      <div className={clsx(classes.numberStyle)}>12</div>
                //  <div className={clsx(classes.numberStyle)}>12</div>*/}
                //               </div>
                //               <div className="flex justify-center items-center px-[5px] w-[80px] D:w-[140px]">
                //                 <button
                //                   onClick={() => {
                //                     handleClickOpenHashDialog(race.block, race.hash, race.ID, 4);
                //                   }}
                //                   className="bg-[#927E60] w-[50px] h-[24px] T:w-24 T:h-10 rounded-lg shadow-black/10 shadow-sm hover:bg-opacity-80"
                //                 >
                //                   {/*row.hash*/ 'Hash'}
                //                 </button>
                //               </div>
                //             </div>
                //           )
                //       )}
                //     </div>
                //   </div>
                // </div>
                <div className="flex flex-col w-full">
                  <div className="flex flex-col pt-[2px] D:pt-2 px-0">
                    <div className="relative flex flex-row w-full h-[35px] T:h-[55px] D:rounded-t-md overflow-hidden">
                      <div className="absolute w-full h-full bg-[#3689d7] border-b border-gray-300" />
                      <div className="relative flex flex-row w-full h-full text-white text-[14px] D:text-[16px] font-medium divide-x divide-gray-300 text-center text-ellipsis">
                        <div className="flex justify-center items-center px-[5px] w-[40px] D:w-[70px]">NO</div>
                        <div className="flex justify-center items-center px-[5px] w-[100px] D:w-[140px]">
                          {/*Name*/ lngT('list:name')}
                        </div>
                        <div className="flex justify-center items-center px-[5px] w-full D:flex-1 ">
                          <span className="truncate">{/*Latest*/ lngT('list:latest')}</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full divide-y divide-gray-300">
                      {/* {console.log(horseList)} */}
                      {horseList.map((horseItem, index) => (
                        <div
                          key={horseItem.no}
                          className={`flex flex-row w-full h-[35px] T:h-[55px] divide-x divide-gray-300 text-gray-500 text-[10px] max-[992px]:text-[12px] D:text-[16px] ${
                            index % 2 === 0 ? 'bg-white' : 'bg-white/90'
                          }`}
                        >
                          <div className="flex justify-center items-center px-[5px] w-[40px] D:w-[70px]">
                            {horseItem.no}
                          </div>
                          <div className="flex justify-center items-center px-[5px] w-[100px] text-xs T:text-sm  D:w-[140px] truncate pl-1">
                            {horseItem.name}
                          </div>
                          <div className="flex justify-center items-center px-[5px] w-full D:flex-1 space-x-4">
                            {/* {console.log('horseItem' + horseItem.latest.rank)} */}
                            {horseItem.latest.map((rank, index) => (
                              <div key={index}>
                                {rank === 1 ? (
                                  <div className={clsx(classes.numberStyle, classes.numOne)}>{rank}</div>
                                ) : rank === 2 ? (
                                  <div className={clsx(classes.numberStyle, classes.numTwo)}>{rank}</div>
                                ) : rank === 3 ? (
                                  <div className={clsx(classes.numberStyle, classes.numThree)}>{rank}</div>
                                ) : (
                                  <div className={clsx(classes.numberStyle)}>{rank}</div>
                                )}
                              </div>
                            ))}
                            {/*JSON.stringify(horseItem.latest)*/}
                            {/*<div className={clsx(classes.numberStyle, classes.numOne)}>1</div>
                    <div className={clsx(classes.numberStyle)}>12</div>
                <div className={clsx(classes.numberStyle)}>12</div>*/}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* Race info open or hide */}
      <div className="relative h-full w-full D:w-1/2 mt-1">
        <div
          className={`${
            isHorseListOpen ? '' : 'D:left-1/2 D:-translate-x-1/2'
          } relative w-full D:transition-all D:duration-500`}
        >
          <div className="absolute hidden D:block">
            <div className="absolute right-[30px] flex flex-row items-center space-x-[6px]">
              <div className="flex justify-end w-[120px] text-center text-lg text-white/80">
                {isHorseListOpen ? /*'Hide'*/ lngT('list:Hide') : /*'Horse List'*/ lngT('information:title')}
              </div>
              <button
                className="flex justify-center items-center w-[40px] h-[40px] bg-white/20 hover:bg-opacity-80 rounded-lg  "
                onClick={handleClickShowHorsList}
              >
                {isHorseListOpen ? (
                  <ChevronRightIcon className="w-[20px] h-[20px] text-white" />
                ) : (
                  <ChevronLeftIcon className="w-[20px] h-[20px] text-white" />
                )}
              </button>
            </div>
            <div className="bg bg-gradient-to-t from-white/0 via-white to-white/0 w-[0.5px] h-[100vh] pt-10 "></div>
          </div>
          <BettingBoard />
        </div>
      </div>

      {/* 해시 다얼로그 */}
      <HashDialog open={openHashDialog} onClose={handleHashDialogClose} />
      {/* 해시 다얼로그 */}

      {/* Betting */}
      {/*<Modal isOpen={modal.showModal} style={customStyles} className="modal" overlayClassName="overlay">
            <CommonDialog
                open={true}
                onClose={handleCloseModal}
                objectImg={<WarningIcon/>}
                children={<Typography>Cancellation is not possible after placing a bet. <br/> Do you want to bet?</Typography>}
                cancel={'Cancel'}
                submit={'Confirm'}
                //onConfirm={handleComfirmModal}
                />

            </Modal>*/}
      {
        <CommonDialog
          open={openCommon}
          onClose={handleCloseOpenCommon}
          objectImg={<WarningIcon />}
          children={
            <Typography>
              {/*Cancellation is not possible after placing a bet.*/ lngT('contents:bettingText')} <br />{' '}
              {/*Do you want to bet?*/ lngT('contents:askBetting')}
            </Typography>
          }
          cancel={'Cancel'}
          submit={'Confirm'}
          onConfirm={handleComfirmModal}
        />
      }

      {/* Success */}
      <CommonDialog
        open={openComfirm}
        onClose={handleCloseOpenComfirm}
        objectImg={iconComponent}
        closeButton={true}
        title={/*"Success"*/ titleComfirm}
        children={<Typography>{/*Congraturation, Successfully betting!  */ contentComfirm}</Typography>}
        submit={'Confirm'}
      />

      {/* betting 중 */}
      <CommonDialog
        open={openWaitting}
        onClose={handleCloseOpenWaitting}
        objectImg={<img src={BlockIcon} alt="tron" />}
        // objectImg={<BettingIcon />}
        closeButton={true}
        title={/*"Betting"*/ titleWaitting}
        children={
          <LinearProgress
            color="success"
            sx={{
              '--LinearProgress-progressThickness': '8px',
              '--LinearProgress-progressRadius': '8px',
              '--LinearProgress-thickness': '9px',
              '--LinearProgress-radius': '9px',
            }}
          />
        }
      />

      {/* mini game modal */}
      <CommonDialog
        open={openMiniGame}
        onClose={handleCloseOpenComfirm}
        objectImg={<CardGameIcon />}
        closeButton={true}
        title={/*"Success"*/ titleComfirm}
        children={<Typography>{/*Do you want to play mini game?*/ contentComfirm}</Typography>}
        submit={'Confirm'}
        cancel={'Cancel'}
        onConfirm={handleComfirmGameModal}
      />
    </div>
  );
}
