import tw from 'tailwind-styled-components';

const PopupLayer = tw.div`
  flex flex-col
  w-full T:w-fit
  gap-y-4
`;

const PopupContentLayer = tw.div`
  flex flex-col
  space-y-7
`;

const ExchangeLayer = tw.div`
  flex w-full flex-col
  gap-y-5
`;

const ExchangeCoinLayer = tw.div`
  flex flex-col T:flex-row justify-between items-center
  w-full
  gap-y-4 T:gap-y-0 T:gap-x-4
`;

const ExchangeCoinCounterLayer = tw.div`
  flex flex-col
  px-2
`;

const AlertLayer = tw.div`
  flex flex-col
  gap-y-3
`;

const AlertTitleLayer = tw.div`
  flex flex-row
  gap-x-2
`;

const Style = {
  PopupLayer,
  PopupContentLayer,
  ExchangeLayer,
  ExchangeCoinLayer,
  ExchangeCoinCounterLayer,
  AlertLayer,
  AlertTitleLayer,
};

export default Style;
