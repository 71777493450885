import React, { useState } from 'react';
import FreeCoin from '../../img/coin_free.png';
import CashCoin from '../../img/coin_point.png';
import { useSelector } from 'react-redux';
import { GoodsType } from '../../define';
import { SetBalance } from '../../horsestall/progresscombine/jsUtil_combine';
import { useLang } from '../../lib/hook';
import * as config from '../../config';

type Props = {
  size: number;
  tooltipAlways: boolean;
  toggleType : boolean;
};

export function Bonus({ size, tooltipAlways, toggleType }: Props) {
  const { lngT } = useLang('etcs');
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const lngtypeStr = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'bonusactive2line' : 'bonusactive3line';

  let goodsInfo = { 
    tip : tooltipAlways ? 
    lngT(`goods:tooltip:${lngtypeStr}`) : 
    lngT('goods:tooltip:bonus'), img: FreeCoin };

  let goodsType = GetCoinType();
  //const classNameTip = `absolute z-10 top-1/2 left-10 -translate-y-1/2 px-2 py-1 bg-white rounded-md whitespace-nowrap text-xs text-gray-800`;
  const classNameTip = 
  tooltipAlways ? `absolute z-10 top-[40px] left-0 px-2 py-1 bg-white rounded-md whitespace-nowrap text-xs text-gray-800 whitespace-pre font-bold` :
  `absolute z-10 top-1/2 left-10 -translate-y-1/2 px-2 py-1 bg-white rounded-md whitespace-nowrap text-xs text-gray-800 font-bold`;
  return (
    <div
      className={`relative`}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      <img src={goodsInfo.img} className={`w-${size} h-${size} ${goodsType === GoodsType.CASHCOIN && !tooltipAlways && toggleType ? 'opacity-30' : ''}`} alt="goods" />
      {(isTooltipVisible || tooltipAlways) && (
        <div className={classNameTip}>
          {goodsInfo.tip}
        </div>
      )}
    </div>
  );
}

export function GetCoinType() {
  const goodsType = useSelector((state: any) => state.goodsType);
  // console.log('goodsType11 : ' + goodsType);
  return goodsType;
}
