import { useTranslation } from 'react-i18next';

import { ReactComponent as LanguageManageIcon } from '../assets/icons/language-manage.svg';
import { useState } from 'react';
import { useLang } from '@/lib/hook';
import useUser from '../lib/hook/useUser';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

export default function LanguageManage() {
  const { i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const languageImages = {
    pt: '/images/icons/lng/br.svg',
    en: '/images/icons/lng/us.svg',
    cn: '/images/icons/lng/cn.svg',
    es: '/images/icons/lng/es.svg',
    kr: '/images/icons/lng/kr.svg',
  };

  const navigate = useCustomNavigate();
  const [user, { isLoading, error, logout }] = useUser();
  return (
    //  <div className="group relative">
    //     {/* <LanguageManageIcon className="w-[32px] h-[32px] cursor-pointer" onClick={() => setOpen(!isOpen)} /> */}
    //     <img
    //       className="w-[32px] h-[32px] cursor-pointer"
    //       onClick={() => setOpen(!isOpen)}
    //       src={languageImages[i18n.language]}
    //     />
    //     {isOpen && (
    //       <div className="absolute -bottom-[100px] right-0 z-[99]">
    //         <HoverMenu setOpen={setOpen} />
    //       </div>
    //     )}
    //     <div className="absolute -top-2 -right-3 text-white bg-[#1E022F] leading-3 px-[4px] py-[2px] rounded-lg text-xs">
    //       {i18n.language}
    //     </div>
    //   </div>
    <>
      <div className="group relative">
        {/* <LanguageManageIcon className="w-[32px] h-[32px] cursor-pointer" onClick={() => setOpen(!isOpen)} /> */}
        <img
          className="w-[39px] h-[39px] cursor-pointer"
          onClick={() => setOpen(!isOpen)}
          src={languageImages[i18n.language]}
        />
        {isOpen && (
          <div className="absolute -bottom-[130px] right-0 z-[99]">
            <HoverMenu setOpen={setOpen} />
          </div>
        )}
        <div className="absolute -top-2 -right-3 text-[#9C4EFF] bg-white border border-[#9C4EFF] leading-3 px-[4px] py-[2px] rounded-lg text-xs font-medium">
          {i18n.language}
        </div>
      </div>
    </>
  );
}

function HoverMenu({ setOpen }) {
  const { changeLang } = useLang();

  const menus = [
    {
      title: 'Portuguese',
      image: 'br',
      onClick: () => {
        changeLang('pt');
        setOpen(false);
      },
    },
    {
      title: 'English',
      image: 'us',
      onClick: () => {
        changeLang('en');
        setOpen(false);
      },
    },
    {
      title: 'Chinese',
      image: 'cn',
      onClick: () => {
        changeLang('cn');
        setOpen(false);
      },
    },
    // {
    //   title: 'Spain',
    //   image: 'es',
    //   onClick: () => {
    //     changeLang('es');
    //     setOpen(false);
    //   },
    // },
    // {
    //   title: 'Korean',
    //   image: 'kr',
    //   onClick: () => {
    //     changeLang('kr');
    //     setOpen(false);
    //   },
    // },
  ];

  return (
    <div
      className="w-[150px] pt-[8px] pb-[16px] px-[8px] backdrop-blur-[2px] rounded-md mt-3"
      style={{
        background:
          'linear-gradient(156.67deg, rgba(112, 112, 114, 0.6) 0.07%, rgba(112, 112, 114, 0.6) 0.08%, rgba(100, 97, 113, 0.222) 99.07%)',
      }}
    >
      <ul className="flex flex-col justify-center space-y-[10px] list-none">
        {menus.map(({ title, image, onClick }, i) => (
          <li
            key={i}
            className="flex flex-row items-center p-[4px] space-x-[8px] rounded-md cursor-pointer hover:bg-white/20"
            onClick={onClick}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="text-white text-[14px]">{title}</div>
              <img className="w-5" src={`/images/icons/lng/${image}.svg`} alt={`${image}_lng`} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
