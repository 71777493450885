import tw from "tailwind-styled-components";

const Container = tw.button`
  relative
  flex items-center justify-center
  w-full h-[50px]
  space-x-[20px]
  rounded-[32px]
  bg-red-200
`;
const Text = tw.span`
  text-neutral-500
`;

export default function OauthButton({ title, background, image, onClick }) {
  return (
    <Container onClick={onClick} style={{ background }}>
      <img className="w-6 h-6" src={image} alt={title} />
      <Text>{title}</Text>
    </Container>
  );
}
