import { type ReactNode, useEffect, useState } from 'react';

type Props = {
  availablePoint: number;
  children: ({
    requestFree,
    willConvertedPoint,
    onClickPlus,
    onClickMinus,
    reset,
  }: {
    requestFree: number;
    willConvertedPoint: number;
    onClickPlus: () => void;
    onClickMinus: () => void;
    reset: () => void;
  }) => ReactNode;
};

export default function ExchangePointContainer({ availablePoint, children }: Props) {
  const [requestFree, setRequestFree] = useState(0);
  const [willConvertedPoint, setWillConvertedPoint] = useState(0);

  const onClickPlus = () => {
    if (availablePoint >= 5000 && availablePoint >= requestFree + 5000) {
      setRequestFree(requestFree + 5000);
    }
  };

  const onClickMinus = () => {
    const value = requestFree - 5000;
    setRequestFree(value < 0 ? 0 : value);
  };

  const reset = () => {
    setRequestFree(0);
    setWillConvertedPoint(0);
  };

  useEffect(() => {
    setWillConvertedPoint(requestFree / 100);
  }, [requestFree]);

  return (
    <>
      {children({
        requestFree,
        willConvertedPoint,
        onClickPlus,
        onClickMinus,
        reset,
      })}
    </>
  );
}
