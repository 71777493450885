import React, { useState } from 'react';

import TextInputWrapper from '../TextInputWrapper';
import PrimaryButton from '../PrimaryButton';

import useLang from '../../lib/hook/useLang';

export default function EmailVerification({ email, verificationCode, setPassword, setNextPhase }) {
  const { lngT: passwordLngT } = useLang('auth:password');
  const { lngT: signupLngT } = useLang('auth:signup');

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(' ');

  const [userPassword, setUserPassword] = useState('');
  const [userPasswordConfirm, setUserPasswordConfirm] = useState('');

  const [passwordError, setPasswordError] = useState(' ');
  const [passwordConfirmError, setPasswordConfirmError] = useState(' ');

  const handleChangeCodeInput = e => {
    setCode(e.target.value);
    if (e.target.value === verificationCode) {
      setCodeError(undefined);
    } else {
      setCodeError(signupLngT('signup:4:2'));
    }
  };

  const handleChangePassword = e => {
    setUserPassword(e.target.value);
    var reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

    if (!reg.test(e.target.value)) {
      return setPasswordError(passwordLngT('error:1'));
    }
    setPasswordError(undefined);
  };

  const handleChangePasswordConfirm = e => {
    setUserPasswordConfirm(e.target.value);

    if (userPassword !== e.target.value) {
      return setPasswordConfirmError(passwordLngT('error:2'));
    }
    setPasswordConfirmError(undefined);
  };

  const handleClickNextPhase = () => {
    setPassword(userPassword);
    setNextPhase();
  };

  return (
    <div className="flex flex-col space-y-5">
      <TextInputWrapper title={signupLngT('signup:1:email')}>
        <input
          type="email"
          value={email}
          placeholder={signupLngT('signup:type:email')}
          autoComplete="off"
          disabled={true}
        />
      </TextInputWrapper>
      <TextInputWrapper
        title={`${signupLngT('signup:2:verification')} ${!codeError ? signupLngT('signup:3:verified') : ''}`}
        errorText={codeError}
        className={!codeError ? 'opacity-50' : 'opacity-100'}
      >
        <input
          type="text"
          value={code}
          maxLength={6}
          placeholder={signupLngT('signup:2:code')}
          autoComplete="off"
          disabled={!codeError}
          onChange={handleChangeCodeInput}
        />
      </TextInputWrapper>
      {!codeError && (
        <div className="py-[30px] space-y-5">
          <TextInputWrapper title={passwordLngT('type:1')} errorText={passwordError}>
            <input
              type="password"
              value={userPassword}
              maxLength={15}
              placeholder={passwordLngT('type:1')}
              autoComplete="off"
              onChange={handleChangePassword}
            />
          </TextInputWrapper>
          {!passwordError && (
            <TextInputWrapper title={passwordLngT('type:2')} errorText={passwordConfirmError}>
              <input
                type="password"
                value={userPasswordConfirm}
                maxLength={15}
                placeholder={passwordLngT('type:2')}
                autoComplete="off"
                onChange={handleChangePasswordConfirm}
              />
            </TextInputWrapper>
          )}
          <PrimaryButton
            type="button"
            title={signupLngT('button:1')}
            isLoading={false}
            error={codeError || passwordError || passwordConfirmError}
            className="mt-[40px]"
            onClick={handleClickNextPhase}
          />
        </div>
      )}
    </div>
  );
}
