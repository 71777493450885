// import { SpineThreeJS, AssetManager, AtlasAttachmentLoader, SkeletonJson, SkeletonMesh} from "@esotericsoftware/spine-threejs"
import * as spine from "@esotericsoftware/spine-threejs";
import { useEffect } from "react";

const baseUrl =    
'assets/';    
let animation = [];
let skeletonFile = [];
let atlasFile = [];
let skeletonMesh = [];
let skeletonIndex = 0;
let assetManager = [];


export function InitSpine(assetName, aniName)
{
    animation[skeletonIndex] = aniName;
    skeletonFile[skeletonIndex] = `${assetName}.json`;
    atlasFile[skeletonIndex] = `${assetName}.atlas`;

    if(assetManager[skeletonIndex] === undefined)
    {
        assetManager[skeletonIndex] = new spine.AssetManager(baseUrl);
        assetManager[skeletonIndex].loadText(skeletonFile[skeletonIndex]);
        assetManager[skeletonIndex].loadTextureAtlas(atlasFile[skeletonIndex]);    
    }
    
    ++skeletonIndex;
}

export function IsLoadingCompleteSpine()
{
    for(let i = 0; i < assetManager.length; ++i)
    {
        if(!assetManager[i].isLoadingComplete())
            return false;
    }
    return true;
}

export function LoadSpine(index, scale, pos)
{
    if(skeletonMesh[index] !== undefined)
        return skeletonMesh[index];

    // The function passed to TextureAtlas is used to resolve relative paths.
    let atlas = assetManager[index].require(atlasFile[index]);
    // Create a AtlasAttachmentLoader that resolves region, mesh, boundingbox and path attachments
    let atlasLoader = new spine.AtlasAttachmentLoader(atlas);
    // Create a SkeletonJson instance for parsing the .json file.
    let skeletonJson = new spine.SkeletonJson(atlasLoader);
    // Set the scale to apply during parsing, parse the file, and create a new skeleton.
    skeletonJson.scale = 1;
    let skeletonData = skeletonJson.readSkeletonData(
    assetManager[index].require(skeletonFile[index])
    );
    
    skeletonMesh[index] = new spine.SkeletonMesh(
    skeletonData,
    (parameters) => {        
        // parameters.depthWrite = true;        
        parameters.depthTest = false; // false가 되면 background 뒤의 숨겨진 릴 회전이 노출 됨
        parameters.alphaTest = 0.0;  // 0에 가까워지면 투명한영역이 영역내의 다른 오브젝트를 가림   
        parameters.premultipliedAlpha = false;
    }
    );
    // let scale = 0.01;
    skeletonMesh[index].scale.x = scale;
    skeletonMesh[index].scale.y = scale;
    skeletonMesh[index].scale.z = scale;
    skeletonMesh[index].position.x = pos.x;
    // skeletonMesh[index].position.y = pos.y - 0.023;
    skeletonMesh[index].position.y = pos.y;
    skeletonMesh[index].position.z = pos.z;
    // skeletonMesh[index].state.setAnimation(0, animation[index], true);
    Animate(skeletonMesh[index], animation[index], false)

    // skeletonMesh[index].state.data.skeletonData.findSlot("item_clover").visible = false
    // skeletonMesh[index].state.data.skeletonData.findSlot("item_flag").visible = false
    // skeletonMesh[index].state.data.skeletonData.findSlot("item_grape").visible = false
    // skeletonMesh[index].state.data.skeletonData.findSlot("item_trophy").visible = false
    // skeletonMesh[index].state.data.skeletonData.findSlot("item_u").visible = false
    // skeletonMesh[index].state.data.skeletonData.findSlot("item_wild").visible = false

    // let slotName = "item_wild";
    // const attachmentName = skeletonMesh[index].state.data.skeletonData.findSlot(slotName).attachmentName;
    // skeletonMesh[index].state.data.skeletonData.skins[0].attachments = skeletonMesh[index].state.data.skeletonData.skins[0].attachments[0];
    // skeletonMesh[index].state.data.skeletonData.defaultSkin = null;

    return skeletonMesh[index];
}

export function Animate(skelMesh, aniName, isPlay)
{

    let callAniName = isPlay ? `${aniName}_action_ani` : `${aniName}_stop_ani`;
    if(aniName === 'aim' || aniName === 'x0_ani' || aniName === 'x5_ani')
        callAniName = aniName;
        

    skelMesh.state.setAnimation(1, callAniName, isPlay);
    
    if(isPlay)    
        skelMesh.position.z = 0.005;
    else
        skelMesh.position.z = 0.001;

    // console.log(`SetLineModel isPlay:${isPlay}`);
}