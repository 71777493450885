import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useLang from '../../lib/hook/useLang';
import { env } from '@/env';
import { getCookie, setCookie } from '../../lib/cookie';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

import { GoodsTypeServer } from '../../define';
const { webSocketIO } = require('../../socketGameClient.js');

// 클라이언트에서 사용하는 state는 한 프레임이 지나면 사라진다. 프리롤때 서버 size 값이 정상이 아닌 값이 오므로 전역저장.
let freerollsize = 0;

// callback func
// let ResRollSlotGame;
export default function useReelGameSocket() {
  const { lngT:lngTetc } = useLang('etc');  
  const navigate = useCustomNavigate();
  const [resultSlot,setresultSlot] = useState([])
  const [slot33,setslot33] = useState([])
  const [reward,setreward] = useState(0)
  const [balance,setbalance] = useState(0)
  const [freeroll, setfreeroll] = useState({size:0, count:0})
  const dispatch = useDispatch()  
  function connectSocket() {    
    var token = getCookie(process.env.REACT_APP_ACCESS_TOKEN_NAME);

    if (token !== undefined) {
      webSocketIO.data.tokenUser = token;
    }
    webSocketIO.connect(null,null,'slot');
    webSocketIO.websocket.onmessage = onMessage;
    webSocketIO.websocket.onopen = onOpen;
    webSocketIO.oncloseCallback = onClose;

    console.log('connectSocket');

    return () => {
      webSocketIO.onClose();
      console.log('컴포넌트가 화면에서 사라짐');
    };
  }

  const resfreeroll=(size, count)=>{
    // console.log('resfreeroll size/count : ' + size +'/' + count)
    freerollsize = size===undefined?freerollsize:size;
    setfreeroll({size:freerollsize, count:count});

    dispatch(
      {
        type : 'REELGAME_SERVERINFO',
        reelGameServerInfo : {
          freeroll : {size:freerollsize, count:count}
        }
      }
    );
  }

  const resUserInit=()=>{
    dispatch(
      {
        type : 'REELGAME_SERVERINFO',
        reelGameServerInfo : {
          userinit : true
        }
      }
    );
  }

  const resRollSlotGame=(isJackpot, needUpdateSpin)=>{
    dispatch(
      {
        type : 'REELGAME_SERVERINFO',
        reelGameServerInfo : {
          needUpdateSpin : needUpdateSpin,
          isJackpot : isJackpot === undefined ? false : isJackpot
        }
      }
    );
  }

  const resJackpotOn=()=>{
    const nowtime = Date.now();
    console.log('resJackpotOn : ' + nowtime);
    dispatch({
      type : 'REELGAME_SERVERINFO',
      reelGameServerInfo : {
        lastjackpottime : nowtime
      }
    })
  }

  function onMessage(e) {
    
    const data = JSON.parse(e.data);

    if(data.protocol === 'slot-resUserInit') {
      console.log(data);

      setbalance(data.balance);
      resRollSlotGame(data.isJackpot, false);
      resfreeroll(data.sizeFreeRoll, data.countFreeRoll);
      resUserInit();
      
    } else if (data.protocol === 'slot-resRollSlotGame') { 
      console.log(data);

      /////////////////////////////////
      // force change server data 
      // data.reward = 5;
      // data.slot33 = [[0,0,0],[2,2,2],[2,2,2]];
      // data.sizeFreeRoll = 1;
      // data.resultSlot = [0,2,2,2,2];
      // data.balance = 1000;
      /////////////////////////////////


      setresultSlot(data.resultSlot);
      setslot33(data.slot33);
      setreward(data.reward);
      setbalance(data.balance);
      resRollSlotGame(data.isJackpot, true);
      resfreeroll(data.sizeFreeRoll, 0);
    } else if (data.protocol === 'slot-resFreeRollSlotGame') {       
      console.log(data);

      setresultSlot(data.resultSlot);
      setslot33(data.slot33);
      setreward(data.reward);
      setbalance(data.balance);
      resRollSlotGame(data.isJackpot, true);

      // 서버 오류 예외처리
      // size, count 변수명이 타 패킷과 달라서 예외처리. size가 count와 같은 값이 와서 size는 사용하지 않는다
      resfreeroll(data.sizeFreeRoll, data.countFreeRoll);
    } else if (data.protocol === 'slot-resJackpotOn') {
      // console.log('resJackpotOn');
      resJackpotOn();
      // alert(lngTetc('resJackpotOn'));      
    } else if (data.protocol === 'resMultiConnection') {      
      alert('You cannot log in with duplicate credentials.');
      console.log('중복로그인임');
      navigate('/');
    } else if (data.protocol === 'slot-resMaintenanceCloseServer') {      
      alert('MaintenanceCloseServer.');
      navigate('/');
    }
    
  }

  function onClose() {
    console.log('call: oncloseCallback - ' + window?.location?.pathname);

    // 현재 페이지에서 서버 접속이 끊긴경우만 lobby로 강제 이동 시켜준다. 유저가 다른페이지로 이동하는 경우에도 onClose로 들어오기 때문에 예외처리를 안하면 페이지를 두번 이동 하게 된다.
    if(window?.location?.pathname.includes('reelgame'))
    {
      alert('server disconnected');
      navigate('/');
    }     
  }

  function onOpen() {    
    // console.log('webSocketIO.data', webSocketIO.data);
    // console.log('WebSocket Request:', webSocketIO.websocket.protocol);
    console.log('onOpen');
  }

  const ReqRollSlotGame = (bettingvalue, Callback) => {
    // ResRollSlotGame = Callback;
    webSocketIO.send(
        JSON.stringify({
        protocol: 'slot-reqRollSlotGame',
        betting: bettingvalue,
        })
      );    
  };
  
  const GetGoodsTypeStr = () => {
    var urlparam = getCookie('urlparam');
    const goodsType = urlparam !== undefined ? urlparam.goodsType : 0;

    return GoodsTypeServer[goodsType];
  };

  return {
    connectSocket,
    ReqRollSlotGame,
    resultSlot,
    slot33,
    reward,        
    balance,
    freeroll,
  };
}
