import { useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import axios from 'axios';

import OauthButton from './OauthButton';

import useUser from '../lib/hook/useUser';
import useLang from '../lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

const Container = tw.section`
  flex flex-col items-center
`;

const ORBox = tw.div`
  relative
  w-full h-[100px]
  bg-white
`;

const ORText = tw.div`
  absolute
  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
  after:content-["OR"]
  flex justify-center items-center
  h-full aspect-square
  text-sm text-[#B5B5B5]
  bg-white
  z-50
`;

const ORLine = tw.div`
  absolute
  top-1/2 -translate-y-1/2
  w-full h-1
  border-t border-neutral-300
`;

export default function OauthBox() {
  const { lngT } = useLang('auth:login');
  const { pathname } = useLocation();
  const navigate = useCustomNavigate();
  const [_, { login }] = useUser();
  const googleLogin = useGoogleLogin({
    scope: 'email profile',
    onSuccess: async ({ code }) => {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/callback/google`, {
        code,
      });
      if (data.ok) {
        const { accessToken } = data;
        login(accessToken);
        toast(lngT('login:2:toast:1'), { theme: 'dark', type: 'success' });
        navigate('/');
      }
    },
    onError: e => {
      console.log(e);
    },
    flow: 'auth-code',
  });

  const oauthList = [
    {
      title: `${pathname.includes('login') ? 'Login in' : 'Sign up'} with google`,
      background: '#EFEFEF',
      image: '/images/icons/google-oauth.svg',
      onClick: googleLogin,
    },
  ];

  return (
    <Container>
      {oauthList.map(oauth => (
        <OauthButton key={oauth.title} {...oauth} />
      ))}

      <ORBox>
        <ORText />
        <ORLine />
      </ORBox>
    </Container>
  );
}
