import { UnityRender } from '../util/UnityUtil';
import ResultTable from './ResultTable';
import { useEffect, useState } from 'react';
import QueryString from 'qs';
//import { useLocation, useParams } from "react-router";
import { ChangeProgress } from '../util/jsUtil';
import { ChangeLanguage, Translation } from './Translation';
import * as config from '../config';
import { Gettoken } from '../util/userSession';
import { setCookie, getCookie } from '../lib/cookie';
//import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
// var { webSocketIO } = require('../socketClient.js');

export const GameComponent = props => {
  const [urlparam, seturlparam] = useState(getCookie('urlparam'));
  const [gameState, setGameState] = useState('play'); // 1:게임플레이, 2:결과
  const location = useLocation(); //바뀐 부분
  useEffect(() => {
    const queryData = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    // ChangeLanguage('en');

    // 1: 2:intro 3:live 4:replay 5:result
    if (
      urlparam === undefined ||
      urlparam.progress == undefined ||
      urlparam.progress.progress == '0' ||
      urlparam.progress.progress == '5' ||
      (urlparam.progress.progress == '3' && location.pathname.includes('intro'))
    ) {
      var user = urlparam != undefined && urlparam.user.user != undefined ? urlparam.user.user : '';
      var lan = urlparam != undefined && urlparam.lan.lan != undefined ? urlparam.lan.lan : 'pt';
      ChangeProgress(0, user, 0, lan);
    }
  }, []);

  function handleSetGameState(state) {
    console.log('setGameState : ' + state);
    setGameState(state);
    ChangeProgress(urlparam.game.game, urlparam.user.user, 5, urlparam.lan.lan);
  }

  useEffect(() => {
    document.getElementById('u-podium-header').focus();
  }, []);

  return (
    <div className="relative">
      {/* <div className="absolute z-50 top-0 left-0 bg-red-500 w-[200px] h-[200px]">
        <p className="text-white text-lg">hello</p>
      </div> */}
      {urlparam !== undefined && urlparam.progress !== undefined && urlparam.progress.progress > 0 ? (
        <UnityRender setGameState={handleSetGameState} setunityloaded={props.setunityloaded} />
      ) : (
        ''
      )}
    </div>
  );
};

export default GameComponent;
