import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import tw from 'tailwind-styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { WEBSERVERPORT } from '../../config';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang';

import { ReactComponent as CommentIcon } from '../../assets/icons/comment.svg';
import { toast } from 'react-toastify';

const QuestionWriteBtn = tw.button`
  flex justify-center items-center
  h-[38px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

export default function FreeBoardDetail() {
  const { lngT } = useLang('community:freeboard');
  const navigate = useNavigate();
  const params = useParams();
  const [user] = useUser();
  const { data, isLoading, error } = useSWR(`${process.env.REACT_APP_API_URL}/freeboard/${params.freeboardId}`);
  const { register, setValue, handleSubmit } = useForm();
  const [isEditing, setEditing] = useState(false);
  // const [isNotice, setNotice] = useState(true);

  // if (data && !data.freeBoartItem) {
  //   navigate('/freeboard');
  // }

  const onSubmit = async payload => {
    if (data.freeBoartItem.title === payload.title && data.freeBoartItem.description === payload.description) {
      return;
    }

    try {
      const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}/freeboard/${params.freeboardId}`, payload, {
        withCredentials: true,
      });

      if (data.ok) {
        toast(lngT('updateSuccess'), { type: 'success', theme: 'dark' });
        setEditing(false);
        navigate('/community/freeboard');
      }
    } catch (error) {
      toast(lngT('updateFail'), { type: 'error', theme: 'dark' });
      console.log(error);
    }
  };

  const handleClickDeleteFreeboardItem = async () => {
    if (!window.confirm(lngT('checkDelete'))) return;

    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/freeboard/${params.freeboardId}`, {
        withCredentials: true,
      });

      if (data.ok) {
        toast(lngT('deleteSuccess'), { type: 'success', theme: 'dark' });
        setEditing(false);
        navigate('/community/freeboard');
      }
    } catch (error) {
      toast(lngT('deleteFail'), { type: 'error', theme: 'dark' });
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('title', data.freeBoartItem.title);
      setValue('description', data.freeBoartItem.description);
    }
  }, [data, setValue]);

  return (
    <form className="flex flex-col D:mx-[64px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <div className="flex flex-col space-y-4">
          <div className="hidden D:flex flex-row D:justify-between">
            <div className="flex text-[20px] text-white h-[38px]">{lngT('freeBoard')}</div>
            {isEditing && (
              <QuestionWriteBtn type="submit" className="text-[14px] px-[20px] rounded-xl">
                {lngT('save')}
              </QuestionWriteBtn>
            )}
          </div>
          <div className="border-b D:border border-white/40 D:rounded-lg T:px-3 D:py-4">
            <div className="space-y-2 pb-4 D:pt-2">
              <input
                className={`text-white font-medium text-[20px] pb-2 pt-2 D:ml-2 D:pl-2 D:flex rounded-md bg-transparent ${
                  isEditing ? 'bg-white/10' : 'bg-transparent'
                }`}
                disabled={!isEditing}
                {...register('title')}
              />
              {/* {data?.freeBoartItem.title} */}

              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center space-x-[5px]">
                  <img className="w-[24px] h-[24px] mr-2 D:mx-2" src={'/images/icons/avatar-default.svg'} alt="" />
                  {/* src={data?.freeboardId.author.imageUrl ?? '/images/icons/avatar-default.svg'} */}
                  <div className="text-white text-[14px]">{data?.freeBoartItem.author.name}</div>
                  <span className="px-[8px] text-[#CCCCCC]/80 text-[12px]">|</span>
                  <div className="flex justify-end text-[#CCCCCC]/80 text-[14px]">
                    {data?.freeBoartItem.date && new Date(data.freeBoartItem.date).toISOString().substring(0, 10)}
                  </div>
                </div>

                {data?.freeBoartItem.author.id === user?.id && (
                  <div className="flex flex-row items-center text-[#CCCCCC]/80 text-[14px]">
                    {!isEditing && (
                      <div className="cursor-pointer hover:opacity-70}" onClick={() => setEditing(true)}>
                        {lngT('edit')}
                      </div>
                    )}
                    {!isEditing && <span className="px-[8px]">|</span>}
                    {isEditing ? (
                      <div className="cursor-pointer hover:opacity-70 text-[14px]" onClick={() => setEditing(false)}>
                        {lngT('cancelEdit')}
                      </div>
                    ) : (
                      <div
                        className="cursor-pointer hover:opacity-70 text-[14px]"
                        onClick={handleClickDeleteFreeboardItem}
                      >
                        {lngT('delete')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="text-white text-[12px] border-b border-white/20 block" />

            <div className="px-[15px] py-[32px] text-white text-[14px]">
              {isEditing ? (
                <textarea
                  className={`w-full bg-transparent font-normal text-[14px] p-[8px] resize-none rounded-md leading-7 ${
                    isEditing ? 'bg-white/10' : 'bg-transparent'
                  }`}
                  disabled={!isEditing}
                  {...register('description')}
                />
              ) : (
                data?.freeBoartItem.description
              )}
            </div>
          </div>
        </div>
      </div>
      <FreeBoardComment />
      <QuestionWriteBtn type="submit" className="fixed text-[14px] bottom-0 w-full D:hidden">
        {lngT('save')}
      </QuestionWriteBtn>
    </form>
  );
}

const FreeBoardComment = () => {
  const { lngT } = useLang('community:freeboard');
  const textAreaRef = useRef();
  const params = useParams();
  const [user] = useUser();
  const { data, isLoading, error, mutate } = useSWR(
    `${process.env.REACT_APP_API_URL}/freeboard/${params.freeboardId}/comments`
  );

  const [comment, setComment] = useState('');

  const handleChangeTextArea = e => {
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    setComment(e.target.value);
  };

  const handleClickPostComment = async () => {
    try {
      if (!comment) return;

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/freeboard/${params.freeboardId}/comments`,
        { description: comment },
        { withCredentials: true }
      );

      if (data.ok) {
        mutate({});
        setComment('');
        return toast(lngT('uploadSuccess'), { type: 'success', theme: 'dark' });
      }
    } catch (error) {
      console.log(error);
      return toast(lngT('uploadFail'), { type: 'error', theme: 'dark' });
    }
  };

  const handleClickDeleteComment = async commentId => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/freeboard/${params.freeboardId}/comments/${commentId}`,
        { withCredentials: true }
      );

      if (data.ok) {
        mutate({});
        return toast(lngT('deleteSuccess'), { type: 'success', theme: 'dark' });
      }
    } catch (error) {
      console.log(error);
      return toast(lngT('deleteFail'), { type: 'error', theme: 'dark' });
    }
  };

  return (
    <div className="flex flex-col py-[24px] px-2 space-y-4">
      <div className="flex flex-row items-center space-x-[8px]">
        <span className="text-white">{lngT('comment')}</span>
        <CommentIcon />
        <span className="text-[12px] text-white/80">{data?.comments?.length ?? 0}</span>
      </div>
      <div className="flex flex-row items-center border border-white/40 rounded-lg px-2 py-3">
        <img className="w-[22px] h-[22px] mr-[10px]" src={user?.image ?? '/images/icons/avatar-default.svg'} alt="" />
        <textarea
          ref={textAreaRef}
          rows={1}
          className="bg-transparent border-none outline-none resize-none flex-1 text-white text-[12px]"
          value={comment}
          placeholder={lngT('addComment')}
          onChange={handleChangeTextArea}
        />
        <button
          className="px-[12px] py-[5px] text-white/70 bg-white/20 rounded-lg text-[10px] cursor-pointer hover:opacity-70"
          onClick={handleClickPostComment}
        >
          {lngT('post')}
        </button>
      </div>
      <div className="flex flex-col">
        {data?.comments &&
          data.comments.map(comment => (
            <div key={comment.id} className="space-y-4 pb-[20px] mb-[20px] border-b border-white/10 px-2">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center text-white/70">
                  <img
                    className="w-[22px] h-[22px] mr-[10px]"
                    src={comment.author.imageUrl ?? '/images/icons/avatar-default.svg'}
                    alt=""
                  />
                  <div className="text-[14px]">{comment.author.name}</div>
                  <span className="px-[8px] text-[12px]">|</span>
                  <div className=" text-[14px]">{new Date(comment.createdAt).toISOString().substring(0, 10)}</div>
                </div>
                {comment.author.id === user?.id && (
                  <div
                    className="text-[#CCCCCC]/80 text-[14px] cursor-pointer hover:opacity-70"
                    onClick={() => handleClickDeleteComment(comment.id)}
                  >
                    {lngT('delete')}
                  </div>
                )}
              </div>
              <div className="text-white text-[14px] leading-5 pl-5">{comment.description}</div>
            </div>
          ))}
      </div>
    </div>
  );
};
