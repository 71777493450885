import React, { Component, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { useStyles } from './style/HashStyles';
import { ReactComponent as CloseIcon } from '../../img/CloseIcon.svg';
import * as config from '../../config';
import useLang from '../../lib/hook/useLang';
import { usePowerModalStore } from '@/store/powerball';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as define from '../../define'
const { webSocketIO } = require('../../socketGameClient.js');

export default function NumberHash({ blockHash, gameType }) {
  const { lngT } = useLang('racing:timetable');
  const classes = useStyles();

  const { closeHashModalPopup, isHashModalOpen } = usePowerModalStore();
  const [rowsBlock, setRowsBlock] = React.useState([]);
  const [rowsball, setRowsBall] = React.useState(null);  
  const [rowsPowerball, setRowsPowerball] = React.useState();
  const [strHash, setStrHash] = React.useState('');
  const hashUrl = define.BLOCKCHAIN_LINK();
  const hashValue = blockHash.block;
  const [isCopied, setCopied] = useState(false);
  const [blockHashinit, setblockHashinit] = React.useState();
  const clickHashProof = () => {        
    // setRowsBall([...blockHash.checkHash.balls, blockHash.checkHash.powerBall]);
    webSocketIO.send(
      JSON.stringify({
        protocol: 'powerball-reqPowerballFromHash',
        hash: strHash,
      })
    );
    // console.log('req hash : ' + strHash);
  };

  useEffect(()=>{
    // console.log('hash set 0:' + JSON.stringify(blockHash.ball))
    setblockHashinit(blockHash);
  },[])

  useEffect(()=>{
    // console.log('hash set 1:' + JSON.stringify(blockHash.ball))

    if(rowsball === null)
    {
      setRowsBall([]);
    }
    else
    {
      setRowsBall([blockHash.ball[0], blockHash.ball[1], blockHash.ball[2], blockHash.powerBall]);      
    }
  },[blockHash])
  

  const onChange = event => {
    setStrHash(event.target.value);
    //console.log("change");
  };

  return (
    <Dialog open={isHashModalOpen} onClose={closeHashModalPopup} className={classes.root}>
      <IconButton disableRipple className={classes.buttonClose} onClick={closeHashModalPopup}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.dialogStyle}>
        <div className="relative bg-gradient-to-r from-white/0 via-white to-white/0 w-96 mx-auto max-M:mx-auto max-[460px]:w-[90%]">
          <DialogTitle className="text-[#222] max-[460px]:text-center text-sm">
            {/*HASH VALUE*/ lngT('hashDialog:hashValue')}
          </DialogTitle>
        </div>
        <DialogContent>
          <span className={classes.gutter}></span>
          <Box mb={2}>
            <div className="flex flex-row space-x-2 justify-center items-center ">
              {blockHashinit?.ball.map((row, i) => (
                <span key={i} className={classes.numberStyle2}>

                  {row}
                </span>
              ))}
              <span className={classes.numberStyle2}>{blockHashinit?.powerBall}</span>
            </div>
          </Box>
          <Box mb={2}>
            <Typography variant="h5" className={classes.titleStyle}>
              {define.format(lngT('hashDialog:ethereumBlock'), define.BLOCKCHAIN_NAME())}
            </Typography>
            <Typography className={classes.textBoxStyle2}>
              <a href={hashUrl + hashValue} target="_blank">
                {blockHash.block}
              </a>
            </Typography>
          </Box>
          <Typography variant="h5" className={classes.titleStyle}>
            {/*Hash Code*/ lngT('hashDialog:hashCoed')}
          </Typography>
          <Box mb={4}>
            <Typography className={classes.textBoxStyle}>
              <CopyToClipboard
                className="hover:opacity-70 cursor-pointer"
                text={blockHash.hash}
                onCopy={() => {
                  setTimeout(() => setCopied(false), 2000);
                  setCopied(true);
                }}
              >
                <div className="hover:opacity-70">{isCopied ? 'Copied!!' : blockHash.hash}</div>
              </CopyToClipboard>
            </Typography>
            <div className="flex flex-row space-x-2 justify-center">
              {rowsBlock.map(row => (
                <span key={row} className={classes.numberStyle}>
                  {row}
                </span>
              ))}
            </div>
          </Box>
          <span className={classes.gutter}></span>
          <Box className={classes.headerStyle}>
            <Typography variant="h3">{/*Verify Hash Code*/ lngT('hashDialog:verifyHashCoed')}</Typography>
            <Typography variant="subtitle">
              {
                /*If you went to prove the hash code,
                  You can perform a hash verification*/ lngT('hashDialog:verifySubtitle')
              }
            </Typography>
          </Box>
          <Box className={classes.ftFieldStyle}>
            <Typography variant="h5" className={classes.titleStyle} mb={1}>
              {/*Hash code*/ lngT('hashDialog:hashCoed')}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <TextField
                className={classes.inputStyle}
                onChange={onChange}
                placeholder={
                  /*"oxdkjfl3f93j3948f93w402jfe9r309f39fjsklfjw39fdkjf9fdkfjslkjfslkdf"*/ /*'input hash'*/ lngT(
                    'hashDialog:inputHash'
                  )
                }
                variant="outlined"
              />
              <Button className={classes.buttonStyle} onClick={clickHashProof} disableRipple>
                {/*Check*/ lngT('hashDialog:check')}
              </Button>
            </Box>
            <div className="flex flex-row space-x-2 justify-center">
              {rowsball?.map((row,index) => (
                <span key={index} className={classes.numberStyle2}>
                  {row}
                </span>
              ))}
            </div>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
