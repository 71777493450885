import SpineRenderCommonForReel from "../util/SpineRenderCommonForReel";

function BackSpine(props) {
    const filePath = `assets/2d`;

    return (
        <SpineRenderCommonForReel
            isBg={true}
            path={filePath}
            file={props.filename}
            aniName="bg_ani"
            id="player-containerslotbg"/>
    )
}

export default BackSpine;