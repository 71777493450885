import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    landing: {
      home: require('./pages/landing/home/en.json'),
      about: require('./pages/landing/about/en.json'),
      introduction: require('./pages/landing/introduction/en.json'),
      ourteam: require('./pages/landing/ourteam/en.json'),
      roadmap: require('./pages/landing/roadmap/en.json'),
      creating: require('./pages/landing/creating/en.json'),
      footer: require('./pages/landing/footer/en.json'),
    },
    landingPage: {
      firstPage: require('./pages/landingPage/firstPage/en.json'),
      banner: require('./pages/landingPage/banner/en.json'),
      guideSection: require('./pages/landingPage/guideSection/en.json'),
      faq: require('./pages/landingPage/faq/en.json'),
      footer: require('./pages/landingPage/footer/en.json'),
    },
    contact: {
      common: require('./pages/contact/common/en.json'),
      faq: require('./pages/contact/faq/en.json'),
      question: require('./pages/contact/question/en.json'),
    },
    racing: {
      timetable: require('./pages/racing/timetable/en.json'),
      intro: require('./pages/racing/intro/en.json'),
      result: require('./pages/racing/result/en.json'),
    },
    powerball: require('./pages/powerball/en.json'),
    auth: {
      login: require('./pages/auth/login/en.json'),
      password: require('./pages/auth/password/en.json'),
      signup: require('./pages/auth/signup/en.json'),
    },
    community: {
      freeboard: require('./pages/community/freeboard/en.json'),
    },
    mypage: {
      userInfo: require('./pages/mypage/userInfo/en.json'),
      account: require('./pages/mypage/account/en.json'),
      pointHistory: require('./pages/mypage/pointHistory/en.json'),
      gameHistory: require('./pages/mypage/gameHistory/en.json'),
    },
    horse: {
      info: require('./pages/horse/info/en.json'),
      stake: require('./pages/horse/stake/en.json'),
    },
    titles: require('./pages/titles/en.json'),
    menu: require('./pages/menu/en.json'),
    notice: {
      maintenance: require('./pages/notice/maintenance/en.json'),
    },
    reelGame: require('./pages/reelGame/en.json'),
    etcs: require('./pages/etcs/en.json'),
  },
  pt: {
    landing: {
      home: require('./pages/landing/home/pt.json'),
      about: require('./pages/landing/about/pt.json'),
      introduction: require('./pages/landing/introduction/pt.json'),
      ourteam: require('./pages/landing/ourteam/pt.json'),
      roadmap: require('./pages/landing/roadmap/pt.json'),
      creating: require('./pages/landing/creating/pt.json'),
      footer: require('./pages/landing/footer/pt.json'),
    },
    landingPage: {
      firstPage: require('./pages/landingPage/firstPage/pt.json'),
      banner: require('./pages/landingPage/banner/pt.json'),
      guideSection: require('./pages/landingPage/guideSection/pt.json'),
      faq: require('./pages/landingPage/faq/pt.json'),
      footer: require('./pages/landingPage/footer/pt.json'),
    },
    contact: {
      common: require('./pages/contact/common/pt.json'),
      faq: require('./pages/contact/faq/pt.json'),
      question: require('./pages/contact/question/pt.json'),
    },
    racing: {
      timetable: require('./pages/racing/timetable/pt.json'),
      intro: require('./pages/racing/intro/pt.json'),
      result: require('./pages/racing/result/pt.json'),
    },
    powerball: require('./pages/powerball/pt.json'),
    auth: {
      login: require('./pages/auth/login/pt.json'),
      password: require('./pages/auth/password/pt.json'),
      signup: require('./pages/auth/signup/pt.json'),
    },
    community: {
      freeboard: require('./pages/community/freeboard/pt.json'),
    },
    mypage: {
      userInfo: require('./pages/mypage/userInfo/pt.json'),
      account: require('./pages/mypage/account/pt.json'),
      pointHistory: require('./pages/mypage/pointHistory/pt.json'),
      gameHistory: require('./pages/mypage/gameHistory/pt.json'),
    },
    horse: {
      info: require('./pages/horse/info/pt.json'),
      stake: require('./pages/horse/stake/pt.json'),
    },
    titles: require('./pages/titles/pt.json'),
    menu: require('./pages/menu/pt.json'),
    notice: {
      maintenance: require('./pages/notice/maintenance/pt.json'),
    },
    reelGame :   require('./pages/reelGame/pt.json'),
    etcs: require('./pages/etcs/pt.json'),
  },
  cn: {
    landing: {
      home: require('./pages/landing/home/cn.json'),
      about: require('./pages/landing/about/cn.json'),
      introduction: require('./pages/landing/introduction/cn.json'),
      ourteam: require('./pages/landing/ourteam/cn.json'),
      roadmap: require('./pages/landing/roadmap/cn.json'),
      creating: require('./pages/landing/creating/cn.json'),
      footer: require('./pages/landing/footer/cn.json'),
    },
    landingPage: {
      firstPage: require('./pages/landingPage/firstPage/cn.json'),
      banner: require('./pages/landingPage/banner/cn.json'),
      guideSection: require('./pages/landingPage/guideSection/cn.json'),
      faq: require('./pages/landingPage/faq/cn.json'),
      footer: require('./pages/landingPage/footer/cn.json'),
    },
    contact: {
      common: require('./pages/contact/common/cn.json'),
      faq: require('./pages/contact/faq/cn.json'),
      question: require('./pages/contact/question/cn.json'),
    },
    racing: {
      timetable: require('./pages/racing/timetable/cn.json'),
      intro: require('./pages/racing/intro/cn.json'),
      result: require('./pages/racing/result/cn.json'),
    },
    powerball: require('./pages/powerball/cn.json'),
    auth: {
      login: require('./pages/auth/login/cn.json'),
      password: require('./pages/auth/password/cn.json'),
      signup: require('./pages/auth/signup/cn.json'),
    },
    community: {
      freeboard: require('./pages/community/freeboard/cn.json'),
    },
    mypage: {
      userInfo: require('./pages/mypage/userInfo/cn.json'),
      account: require('./pages/mypage/account/cn.json'),
      pointHistory: require('./pages/mypage/pointHistory/cn.json'),
      gameHistory: require('./pages/mypage/gameHistory/cn.json'),
    },
    horse: {
      info: require('./pages/horse/info/cn.json'),
      stake: require('./pages/horse/stake/cn.json'),
    },
    titles: require('./pages/titles/cn.json'),
    menu: require('./pages/menu/cn.json'),
    notice: {
      maintenance: require('./pages/notice/maintenance/cn.json'),
    },
    reelGame :   require('./pages/reelGame/cn.json'),
    etcs: require('./pages/etcs/cn.json'),
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    whitelist: ['pt', 'en', 'cn', 'es', 'kr'],
    fallbackLng: 'pt',
    debug: false,
    defaultNS: 'translations',
    ns: [
      'landing',
      'landingPage',
      'contact',
      'racing',
      'auth',
      'community',
      'mypage',
      'menu',
      'titles',
      'notice',
      'etcs',
      'powerball',
      'reelGame',
    ],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

const simplifiedLanguageCode = code => {
  if (code.includes('-')) {
    code = code.split('-')[0];
  }

  if (code === 'pt') return 'pt';
  if (code === 'cn') return 'cn';
  if (code === 'es') return 'es';
  if (code === 'kr') return 'kr';
  return 'en';
};

// i18n의 언어 코드 설정 변경
const detectedLanguage = simplifiedLanguageCode(i18n.language);

i18n.changeLanguage(detectedLanguage);

export default i18n;
