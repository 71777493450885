import CTABetButton from './CTA';
import styles from './bet.module.css';
import BetListCard from './betListCard';

import useLang from '@/lib/hook/useLang';

const HowToDoBet = () => {
  const { lngT } = useLang('landingPage:guideSection');

  return (
    <div className={styles.container}>
      <div className="max-[320px]:pl-[20px]">
        <h3>{lngT('guideBet:header:subTitle')}</h3>
        <p>{lngT('guideBet:header:subsubTitle')}</p>
      </div>
      <BetListCard />
      <CTABetButton />
    </div>
  );
};

export default HowToDoBet;
