import SpinnerLoader from '@/components/SpinnerLoader';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useRef, useEffect, useState } from 'react';
import { HiSpeakerXMark, HiSpeakerWave } from 'react-icons/hi2';

export default function Video() {
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isEndVideo, setEndVideo] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const navigate = useCustomNavigate();

  useEffect(() => {
    const handleResize = () => setMobile(window.innerHeight > window.innerWidth);

    const handleCanPlay = () => {
      setIsLoading(false);
      videoRef.current.play();
    };

    const handleVideoEnd = () => {
      setEndVideo(true);
      setTimeout(() => navigate('/'), 2000);
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener('canplay', handleCanPlay);
    videoElement.addEventListener('ended', handleVideoEnd);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      videoElement.removeEventListener('ended', handleVideoEnd);
      videoElement.removeEventListener('canplay', handleCanPlay);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={`fixed inset-0 z-[99] overflow-hidden`}
      style={
        isMobile
          ? {
              transformOrigin: 'top left',
              transform: 'rotate(90deg)',
              width: window.innerHeight,
              height: window.innerWidth,
              left: `${window.innerWidth}px`,
            }
          : {
              width: window.innerWidth,
              height: window.innerHeight,
            }
      }
    >
      {isLoading && (
        <div className="absolute flex w-full h-full items-center justify-center text-white font-semibold text-2xl">
          Getting Video...
        </div>
      )}
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-contain"
        playsInline
        autoPlay
        muted={muted}
      >
        <source
          src="https://metacross-brazil-s3-bucket.s3.sa-east-1.amazonaws.com/video/promotion.mp4"
          type="video/mp4"
        />
      </video>
      {isEndVideo && (
        <div className="absolute top-0 left-0 bg-black/80 flex justify-center items-center w-full h-full">
          <SpinnerLoader className="fill-white w-[50px]" />
        </div>
      )}
      {!isLoading && (
        <div
          className={`absolute top-5 right-5 z-10 flex justify-center items-center w-[50px] h-[50px] bg-white/30 rounded-full ${
            muted ? 'animate-pulse' : ''
          }`}
        >
          <button onClick={() => setMuted(!muted)} className="text-white text-2xl">
            {muted ? <HiSpeakerXMark /> : <HiSpeakerWave />}
          </button>
        </div>
      )}
    </div>
  );
}
