import {useThree} from "@react-three/fiber";
import {useEffect, useState} from "react";
import * as THREE from "three";
import {SpinState} from "../../../util/reeldefine";

let material2;
function BackgroundWallPlane(props)
{
    const state = useThree();
    const scene = state.scene;
    const [opacity, setopacity] = useState(0);

    useEffect(()=>{
        setTimeout(()=>{
            material2 = new THREE.MeshStandardMaterial( { opacity:1.0, color: 0xBBA480, map: null } ); // assignSRGB
            material2.transparent = true;
            material2.blending = THREE.NormalBlending;
            const geometry = new THREE.PlaneGeometry(3.5,3.8);
            const meshorign = new THREE.Mesh( geometry, material2 );
            meshorign.position.x = props.position[0];
            meshorign.position.y = props.position[1];
            meshorign.position.z = props.position[2];
            scene.add( meshorign );
        },10)
    },[])

    useEffect(()=>{
        // If Spin Reel then Do Update Front UI. Spin 패킷 응답 받은 후에 처리 해주세요.
        // if(props.spinState === SpinState.SPINNING)
        // {
        //     sethascoin(hascoin-betcoin);
        // }

        // If Stop Reel then Do Update Front UI
        // if(props.spinState === SpinState.REWARD && props.reward > 0)
        // {
        //     material2.opacity = 0.6;
        //     // setopacity(material2.opacity);
        // }
        // else if(props.spinState === SpinState.SPINNING)
        // {
        //     material2.opacity = 0.0;
        // }

        // if(props.spinState !== SpinState.NONE)
        // {
        //     material2.opacity = 1.0;
        //     material2.color = 0xff0000;
        // }
            
    },[props.spinState])
}

export default BackgroundWallPlane;
