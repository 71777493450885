import {useEffect, useState} from "react";
import {FreeRollState, GetFreeRollState, REEL_ZINDEX, SpinState} from "../../../util/reeldefine";
import UILineSpine from "./UISpine";
import UILineAllSpine from "./UISpine";
import UIFreeSpin from "./UISpine";
import {useReelGameStore} from "@/store/ReelGameStore";
import {useDispatch, useSelector} from 'react-redux';

function CallFindResult(element) {
    if (element === 0)
        return true;
}

export function ReelGameFrontEffect(props) {
    const {
        jackpotspinentercount, freespinentercount,
        setJackpotSpinEnterCount, setFreeSpinEnterCount
    } = useReelGameStore();
    
    const reelGameSpinState = useSelector((state)=>state.reelGameSpinState);

    // const [resultslot, setresultslot] = useState([-1, -1, -1, -1, -1]);
    const [hitlinecount, sethitlinecount] = useState(0);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    useEffect(() => {
        // If Spin Reel then Do Update Front UI. Spin 패킷 응답 받은 후에 처리 해주세요.
        if (reelGameSpinState === SpinState.SPINNING) {
            // setresultslot([-1, -1, -1, -1, -1]);

            sethitlinecount(0);
            console.log('ReelGameFrontEffect1');
        }

        // If Stop Reel then Do Update Front UI
        if (reelGameSpinState === SpinState.REWARD) {
            // setresultslot(props.resultSlot);
            const findresult = props.resultSlot?.filter(CallFindResult);
            if (findresult && findresult.length > 0 && GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START) {
                sethitlinecount(findresult.length);

                if(props.reelGameServerInfo.isJackpot)
                    setJackpotSpinEnterCount(jackpotspinentercount+1);
                else
                    setFreeSpinEnterCount(freespinentercount+1);
            }
        }

        if (reelGameSpinState === SpinState.NONE) {
        }

        console.log('ReelGameFrontEffect2');
    }, [reelGameSpinState])

    console.log('ReelGameFrontEffect3');
    return (
        <div
            style={{
                position: 'relative',
                zIndex: REEL_ZINDEX.UI_TOP
            }}>

            <UIFreeSpin
                path={''}
                filename={'freespin'}
                aniName={hitlinecount > 0 ? `x${hitlinecount * 5}_ani` : `x${0}_ani`}
                visible={true}
                loop={false}
                ext={'json'}
                />
        </div>
    )
}
// dg =over;
// effect =top;
// main=top

export default ReelGameFrontEffect;