import { makeStyles } from "@material-ui/styles";
import pattern from '../../img/pattern.png';

var Red = '#EC6964';
var Yellow = '#F0A43C';
var Blue = '#5B9CFB';
var Purple = '#C46AF5';
var Sky ='#739DEA';
var Green ='#3cd2a5';//#98C73D

export const useStyles = makeStyles(theme => ({
    root: {
        // backgroundImage: `url(${pattern})`,
        // backgroundRepeat:'repeat',
        // backgroundPosition:'0 0',
        // backgroundSize:'4.6875rem',
        // backgroundColor:'#161C24',
        //display:'flex',
        //flexDirection:'row-reverse',
        '@media all and (max-width:640px)':{
            display:'flex',
            flexDirection:'column',
        },
        marginTop:20,
        // '& > *':{
        // fontFamily: 'Chakra Petch, Montserrat, Passion One, sans-serif!important',
        // }
    },
    container:{
        height:'100%',
        width:'calc(100%)',//'calc(100% - 340px)',
        '@media all and (max-width:640px)':{
            width:'100%',
        }
    },
    titleStyle:{
        color:'#fff',
    },
    tabs:{
        marginTop:20,
        marginBottom:20,
        '& .MuiTabs-flexContainer':{
            justifyContent:'center',
        },
        '& .MuiTab-root':{
            minHeight:40,
            margin:'0 4px',
            padding:'8px 16px',
            borderWidth:'1px',
            borderStyle:'solid',
            '&:first-child':{
                color:Red,
                borderColor:Red,
            },
            '&:nth-child(2)':{
                color:Yellow,
                borderColor:Yellow,
            },
            '&:nth-child(3)':{
                color:Green,
                borderColor:Green,
            },
            '&:nth-child(4)':{
                color:Blue,
                borderColor:Blue,
            },
        },
        '& .MuiTab-root.Mui-selected':{
            color:'#000!important',
            borderColor:'transparent!important',
            '&:first-child':{
                background:'linear-gradient(#EC6964, #b50b45)', //2번째 #e84741
            },
            '&:nth-child(2)':{
                background:'linear-gradient(#F0A43C, #c94213)', //2번째 #e33475
            },
            '&:nth-child(3)':{
                background:'linear-gradient(#3cd2a5, #195291)', //2번째 #193891
            },
            '&:nth-child(4)':{
                background:'linear-gradient(#5B9CFB, #316abd)', //2번째 #316abd
            },
        },
        '& .MuiTabs-indicator':{
            display:'none',
        }
    },
}));

