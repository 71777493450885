import styles from './Header.module.css';
import Logo from '@/img/landingPage/upodiumwhite.png';
import BrazilianLogo from '../../../../../images/icon_ptbr.png';
import AmericanLogo from '../../../../../images/icon_usd.png';

const Header = () => {
  return (
    <header className={styles.header}>
      {/* <a>
        <img width={60} height={60} src="../../../img/landingPage/upodiumwhite.png" alt={'Logo'} />
      </a>
      <div className={styles.buttonContainer}>
        <a href="https://upodium-qlivibf0a-rodrigo-matos-projects.vercel.app/">
          <img width={20} height={20} src="../../../../../images/icon_ptbr.png" alt={'Logo'} />
        </a>
        <a href="#">
          <img width={20} height={20} src="../../../../../images/icon_usd.png" alt={'Logo'} />
        </a>
        <a href={'https://www.u-podium.com/login'} className={styles.button}>
          Login
        </a>
        <a href={'https://www.u-podium.com/signup'} className={styles.button}>
          Cadastre-se
        </a>
      </div> */}
    </header>
  );
};

export default Header;
