import { UnityRender } from './UnityUtil_combine';
import { useEffect, useState } from 'react';
import QueryString from 'qs';
import { ChangeProgress_combine } from '../progresscombine/jsUtil_combine';
// import { getCookie } from '../../lib/cookie';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import GameResult from '../../pages/games/GameResult-v1';
import * as config from '../../config';
import * as define from '../../define';
import { useSelector, useDispatch } from "react-redux";
//var { clientData } =  require('../../clientData.js'); //require('../../clientData.js');
var { webSocketIO } = require('../../socketClient.js');

var clientGame;
var clientProgress;
export const BetGame = props => {

  var clientData = props.clientData;  
  const tempclientProgress = useSelector((state)=>state.progress);
  clientGame = useSelector((state) => state.game);
  
  //const [clientProgress, setclientProgress] = useState();
  const [urlparam, seturlparam] = useState(clientData); //useState(getCookie('urlparam'));
  const [gameState, setGameState] = useState('play'); // 1:게임플레이, 2:결과
  var resultPagedelay = define.RESULTPAGE_WAITING_DELAY;
  const navigate = useNavigate();

  useEffect(()=>{
    clientProgress = tempclientProgress;
    console.log('tempclientProgress : ' + tempclientProgress);
  },[tempclientProgress]);
  
  useEffect(() => {
    //console.log('progressTest BetGame : ' + props.rerender);
  }, [props.rerender]);

  function handleSetGameState(state) {
    if(state==='break')
    {
      console.log(`0927 break ${props.betProgress}`)
      navigate('/LobbyGame');
    }
    else  
    {
      console.log(`0927 result ${props.betProgress}`)
      
      const tempUrlParm = clientData;
      seturlparam ( tempUrlParm );    
      setGameState(state);    
      console.log('progresscombine setGameState : ' + state);
      props.CallBackGameToResult();      
      
      setTimeout(() => {  

        console.log('1004 handleSetGameState setTimeout clientProgress : ' + clientProgress);

        if (clientData.isHistory) {
          navigate('/history');
        } 
        else 
        {
  
          if(clientProgress >  define.PROGRESS_LIVE)
          {
            var sendGameID = clientGame+1;
            console.log('progressTest reqProgress setGameState : game : ' + sendGameID);
            webSocketIO.send
            (
              JSON.stringify({
                protocol: 'reqProgress',
                idGame:sendGameID
              })
            );
          }
          else
          {
            navigate('/LobbyGame');
          }
        }
  
        // ReCallFunc();
        return () => {
          clearTimeout();
        };    
      }, resultPagedelay);
    }

    
  }

  useEffect(() => {
    document.getElementById('u-podium-header').focus();
  }, []);

  return (
    <div className="relative z-0 w-100">
      {/* <div className="absolute z-50 top-0 left-0 bg-red-500 w-[200px] h-[200px]">
        <p className="text-white text-lg">hello</p>
      </div>
      */}
      {urlparam !== undefined && urlparam.progress !== undefined  ? (
        <UnityRender
          setGameState={handleSetGameState}
          // setunityloaded={props.setunityloaded}
          rerender={props.rerender}
          setrerender={props.setrerender}
          clientData={clientData}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default BetGame;
