import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    boxSizing: 'border-box',
    zIndex: 1,
    '& *, & .MuiTypography-root': {
      fontFamily: '"Poppins", sans-serif!important',
    },
    '& .MuiTabPanel-root': {
      padding: 10,
    },
  },
  container: {
    '&.MuiContainer-root': {
      width: 'calc(100vw - 32px)',
      '@media (min-width:279px) and (max-width:380px)': {
        padding: 0,
      },
    },
  },
  gutter: {
    width: '100%',
    height: 1,
    background:
      'linear-gradient(90deg, rgba(69,4,168,0) 0%, rgba(69,4,168,1) 20%, rgba(136,4,185,1) 50%, rgba(134,0,153,1) 80%, rgba(134,0,153,0) 100%)',
    margin: '48px 0',
    '@media (min-width:280px) and (max-width:768px)': {
      margin: '24px 0',
    },
  },
  titleStyle: {
    '&.MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      width: 800,
      height: 63,
      marginBottom: 30,
      fontSize: '1.5rem',
      fontFamily: '"Orbitron", sans-serif!important',
      fontWeight: 800,
      color: '#222',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 6.61%, rgba(255, 255, 255, 0.82) 49.95%, rgba(255, 255, 255, 0.00) 91.51%)',
      '@media (min-width:768px) and (max-width:1024px)': {
        width: 500,
        height: 40,
        fontSize: '1.1rem',
      },
      '@media (min-width:280px) and (max-width:768px)': {
        width: 300,
        height: 40,
        fontSize: '0.9rem',
      },
    },
  },
  titleBg: {
    '&.MuiTypography-root': {
      background: '#3C6D9B',
    },
  },
  numberBoxStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    '& .MuiTypography-h5': {
      padding: '8px 14px',
      fontSize: '1.3rem',
      backgroundColor: '#EDA717',
      opacity: 0.8,
      color: '#fff',
      borderRadius: 8,
      marginRight: 8,
      fontFamily: '"Orbitron", sans-serif!important',
      fontWeight: 800,
    },
    '& p': {
      color: '#fff',
      paddingRight: 8,
    },
    '@media (min-width:280px) and (max-width:768px)': {
      flexDirection: 'column',
      flexWrap: 'wrap',
      fontSize: '0.9rem',
      fontWeight: 600,
      gap: 10,
      '& .MuiTypography-h5': {
        marginBottom: 8,
      },
      '& p': {
        display: 'none',
      },
    },
  },
  numberStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      width: 40,
      height: 40,
      marginRight: 10,
      fontSize: '0.873rem',
      borderRadius: 50,
      color: '#fff',
      backgroundColor: '#546739',
      '&:nth-child(1)': {
        backgroundColor: '#DFA100',
      },
      '&:nth-child(2)': {
        backgroundColor: '#7E7E7E',
      },
      '&:nth-child(3)': {
        backgroundColor: '#734A39',
      },
    },
    '@media (min-width:280px) and (max-width:768px)': {
      flexWrap: 'wrap',
      '& > span': {
        marginTop: 8,
        width: 24,
        height: 24,
      },
    },
  },
}));
