import { makeStyles } from "@material-ui/styles";


export const useStyles = makeStyles(theme => ({
    chatBox:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        width:340,
        // maxHeight:'586px',
        // height:'calc(100% - 40px - 56px)',
        backgroundColor:'#1e252d7a',
        color:'#fff',
        marginLeft:24,
        '@media all and (max-width:640px)':{
            width:'100%',
            margin:'0 0 24px 0',
        }
    },
    chatTitle:{
        // position:'absolute',
        // top:0,
        // left:0,
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        width:'100%',
        minHeight:40,
        padding:'8px 16px',
        borderBottom:'1px solid #ffffff0a',
        boxSizing:'border-box',
        '& h4.MuiTypography-root':{
            fontSize:'1.063rem',
        },
        '& > button':{
            color:'#fff',
            paddingRight:0,
            '& > svg':{
                width:18,
            },
            display:'none',
            '@media all and (max-width:640px)':{
                display:'block',
            }
        },
        '@media all and (max-width:640px)':{
            padding:'0px 16px',
        }
    },
    none:{
        display:'none',
    },
    chatContentWrap:{
        height:'100%',
        '@media all and (max-width:640px)':{
            height:'auto',
        }
    },
    chatContent:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'flex-end',
        height:'100%',
        overflowY:'auto',
        marginBottom:54,
        // margin:'58px 0 66px',
        padding:'0 4px',
        fontSize:'0.813rem',
        '@media all and (max-width:640px)':{
            maxHeight:'50vh',
            minHeight:'30vh',
            display:'block',
        }
    },
    wrapContent:{
        flex:1,
        overflowY:'auto',
        height:'100%',
        margin:0,
        padding:'0 12px',
         '& > div':{
            margin:'12px 0',
            flex:1,
            '& p':{
                marginBlock:0,
            }
        },
        '&::-webkit-scrollbar':{
            width:6,
        },
        '&::-webkit-scrollbar-thumb':{
            backgroundColor: '#8b949f7d',
            borderRadius:25,
        },
        '&::-webkit-scrollbar-track':{
            background:'transparent',
            borderRadius:25,
        },
    },
    manager:{ 
        textAlign:'center',
        fontWeight:'bold',
        color:'#b50b45',
        margin:'12px 0',
    },
    anotherUser:{
        display:'flex',
        '& .MuiBox-root':{
            display:'flex',
            alignItems:'flex-start',
            '& > p':{
                marginLeft:12,
                color:'#8a949e',
                // backgroundColor:'#3e4753',
                // padding:8,
                // borderRadius:8,
            }
        },
        '& .MuiAvatar-root':{
            width:32,
            height:32,
        },
        '& > span':{
            marginLeft:6,
        }
    },
    nickName:{
        wordBreak:'no-break',
    },
    time:{
        display:'flex',
        alignItems:'flex-end',
        fontSize:'0.625rem',
        color:'#6d7276',
    },
    myText:{
        width:'100%',
        display:'flex',
        flexDirection:'row-reverse',
        justifyContent:'flex-start',
        '& > p':{
            // color:'#5B9CFB',
            backgroundColor:'#4bb066',
            padding:8,
            borderRadius:8,
        },
        '& > span':{
            marginRight:6,
        }
    },
    chatInput:{
        position:'absolute',
        bottom:0,
        left:0,
        width:'100%',
        '& .MuiInputBase-root':{
            backgroundColor:'rgb(145 158 171 / 8%)',
            color:'#fff',
            borderRadius:0,
            '& input':{
                paddingRight:60,
                fontSize:'0.875rem',
            },
            '& fieldset':{
                border:'0 none',
            },
        },
        '& button.MuiIconButton-root':{
            position:'absolute',
            right:8,
            top:6,
            color:'#fff',
            transform:'rotate(-40deg)'
        },
    },
    
}));

