import styles from './boxIncome.module.css';
import BoxIncome from '@/img/landingPage/mobile/boxIncome.png';
import useLang from '@/lib/hook/useLang';

const BoxIncomeSection = () => {
  const { lngT } = useLang('landingPage:guideSection');
  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <img src={BoxIncome} alt={''} />
        <div className={styles.textContainer}>
          <h3>{lngT('point:title')}</h3>
          <p>{lngT('point:phrase')}</p>
        </div>
      </div>
    </section>
  );
};

export default BoxIncomeSection;
