import { useState, useMemo } from 'react';
import tw from 'tailwind-styled-components';

import useUser from '../lib/hook/useUser';
import useLang from '../lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import * as config from '../config';
import * as define from '../define';
import Logout from '../img/Logout.webp';
// import Login from '../img/Login.svg';
import {GoReelGame} from '../horsestall/progresscombine/jsUtil_combine'

const Container = tw.div`
  flex T:hidden flex-row items-center
  space-x-2
`;

const AvatarDefaultIcon = tw.img`
  w-[40px] h-[40px]
  cursor-pointer
  rounded-full
  overflow-hidden
`;

const AvatarLogoutIcon = tw.img`
  w-[40px] h-[40px]
  cursor-pointer
  rounded-full
  overflow-hidden
`;

export default function MobileNavigation({ isScrolled }) {
  const { currentLang, lngT } = useLang('menu');
  const { lngT : lngTetc } = useLang('etcs');
  const navigate = useCustomNavigate();
  const [user, { logout }] = useUser();
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const body = document.querySelector('body');

  const menus = useMemo(
    () => [
      {
        title: lngT('myPage'),
        image: '/images/icons/mypage.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/mypage/userinfo');
          setOpenMobileMenu(false);
        },
      },
      define.SHOW_METACROSS_ONLY ? {
        title: lngT('horse'),
        image: '/images/icons/myhorse.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/horse/info');
          setOpenMobileMenu(false);
        },
      } : {title:null},
      {
        title: lngT('racing'),
        image: '/images/icons/racing.png',
        onClick: () => {
          body.style.overflowY = 'scroll';
          //navigate('/timetablewithstyle');
          navigate('/LobbyGame');
          setOpenMobileMenu(false);
        },
      },
      config.ValidContents(define.CONTENTS_REELGAME) && define.SHOW_METACROSS_ONLY
        ? {
            title: 'Slot',
            image: '/images/icons/icon_slot.png',
            // onClick: () => navigate('/reelgame'),
            onClick: () => {
                body.style.overflowY = 'scroll';
                GoReelGame(lngTetc);
                setOpenMobileMenu(false);
            }
        }
        : {title:null},
      {
        title: 'FAQ',
        image: '/images/icons/faq.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/contact/faq');
          setOpenMobileMenu(false);
        },
      },
      {
        title: 'Q&A',
        image: '/images/icons/question.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/contact/question');
          setOpenMobileMenu(false);
        },
      },
      {
        title: 'Discussion Board',
        image: '/images/icons/freeboard.png',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/community/freeboard');
          setOpenMobileMenu(false);
        },
      },
      {
        title: lngT('logout'),
        image: '/images/icons/logout.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/');
          setOpenMobileMenu(false);
          logout();
        },
      },
    ],
    [currentLang]
  );

  const { lngT: etclngT } = useLang('landing:footer');
  const powerballMenus = useMemo(
    () => [
      {
        title: lngT('myPage'),
        image: '/images/icons/mypage.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/mypage/userinfo');
          setOpenMobileMenu(false);
        },
      },
      {
        title: lngT('powerball'),
        image: '/images/icons/gamble.png',
        onClick: () => {
          body.style.overflowY = 'scroll';
          //navigate('/timetablewithstyle');
          navigate('/powerball/lobbyy');
          setOpenMobileMenu(false);
        },
      },
        config.ValidContents(define.CONTENTS_REELGAME) && define.SHOW_METACROSS_ONLY
            ? {
                title: 'Slot',
                image: '/images/icons/icon_slot.png',
                // onClick: () => navigate('/reelgame'),
                onClick: () => {
                    body.style.overflowY = 'scroll';
                    GoReelGame(lngTetc);
                    setOpenMobileMenu(false);
                }
            }
            : {title:null},
      {
        title: etclngT('nav:5:menu:4'),
        image: '/images/icons/freeboard.png',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/community/freeboard');
          setOpenMobileMenu(false);
        },
      },
      {
        title: etclngT('nav:5:menu:5'),
        image: '/images/icons/event.png',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/event');
          setOpenMobileMenu(false);
        },
      },
      {
        title: 'FAQ',
        image: '/images/icons/faq.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/contact/faq');
          setOpenMobileMenu(false);
        },
      },
      {
        title: 'Q&A',
        image: '/images/icons/question.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/contact/question');
          setOpenMobileMenu(false);
        },
      },
      {
        title: lngT('logout'),
        image: '/images/icons/logout.svg',
        onClick: () => {
          body.style.overflowY = 'scroll';
          navigate('/');
          setOpenMobileMenu(false);
          logout();
        },
      },
    ],
    [currentLang]
  );

  return (
    <Container>
      <>
        {user ? (
          <>
            <AvatarDefaultIcon
              src={openMobileMenu ? '/images/icons/close.svg' : user?.image || '/images/icons/avatar-default.svg'}
              // src={openMobileMenu ? '/images/icons/close.svg' : user?.image || Login}
              className="text-[#9C4EFF]"
              alt="close"
              onClick={() => {
                if (!openMobileMenu) {
                  body.style.overflow = 'hidden';
                } else {
                  body.style.overflowY = 'scroll';
                }
                setOpenMobileMenu(!openMobileMenu);
              }}
            />
            {openMobileMenu && Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? (
              <div
                style={{ marginLeft: 0 }}
                className={`absolute left-0 right-0 transform transition-all duration-500 ${
                  isScrolled ? 'top-[100px] h-[calc(100vh-100px)]' : 'top-[88px] h-[calc(100vh-88px)]'
                  // isScrolled ? 'top-[64px] h-[calc(100vh-64px)]' : 'top-[88px] h-[calc(100vh-88px)]'
                } bg-[#380E53]`}
              >
                {menus.map(({ title, image, onClick }) => (
                  title !== null &&
                  <div
                    className="flex flex-row items-center cursor-pointer w-full h-[44px] px-[16px] space-x-2 hover:bg-white/10"
                    key={title}
                    onClick={onClick}
                  >
                    <img className="w-[16px] h-[16px]" src={image} alt="" />
                    <div className="text-[16px] text-white">{title}</div>
                  </div>
                ))}
              </div>
            ) : (
              openMobileMenu && (
                <div
                  style={{ marginLeft: 0 }}
                  className={`absolute left-0 right-0 transform transition-all duration-500 ${
                    isScrolled ? 'top-[100px] h-[calc(100vh-100px)]' : 'top-[88px] h-[calc(100vh-88px)]'
                    // isScrolled ? 'top-[64px] h-[calc(100vh-64px)]' : 'top-[88px] h-[calc(100vh-88px)]'
                  } bg-[#380E53]`}
                >
                  {powerballMenus.map(({ title, image, onClick }) => (
                    <div
                      className="flex flex-row items-center cursor-pointer w-full h-[44px] px-[16px] space-x-2 hover:bg-white/10"
                      key={title}
                      onClick={onClick}
                    >
                      <img className="w-[16px] h-[16px]" src={image} alt="" />
                      <div className="text-[16px] text-white">{title}</div>
                    </div>
                  ))}
                </div>
              )
            )}
          </>
        ) : (
          // <AvatarLogoutIcon src="/images/icons/avatar-logout.svg" alt="" onClick={() => navigate('/login')} />
          <AvatarLogoutIcon src={Logout} alt="Logout" onClick={() => navigate('/login')} />
        )}
      </>
    </Container>
  );
}
