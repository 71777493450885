import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import tw from 'tailwind-styled-components';

import NumberResultDisplay from '../../../components/powerball/NumberResultDisplay';
import PowerBallCanvas from '../canvas/PowerBallCanvas';
import NumberHistoryBoard from '../../../components/powerball/NumberHistoryBoard';
import NumberHeader from '../../../components/powerball/NumberHeader';

import Line from '../../../img/powerball/line.png';

import { PowerBallZoomCanvas } from '../canvas/PowerBallCanvas';

import { useResultStore } from '@/store/powerball';
import usePowerBallSocket from '../usePowerBallSocket';
import PowerBallModal from '../../../components/powerball/PowerBallModal';
import { usePowerModalStore } from '@/store/powerball';
import NumberResultModal from '../../../components/powerball/NumberResultModal';
import NumberRewardBoard from '../../../components/powerball/NumberRewardBoard';
import SoundManager from '../sound/soundManager';
import { getTime } from '@/lib/util';
import LotteryPadBoard from '@/components/powerball/component/lottery/LotteryPadBoard';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
var curState = 0;

const GameBoard = tw.div`
  relative max-[1024px]:w-full max-w-[1000px] w-full h-[960px]
  rounded-2xl
  py-2 space-y-6
  max-[1440px]:h-fit
  max-[768px]:py-0  max-[768px]:space-y-3
  max-[480px]:px-0 max-[480px]:rounded-none
`;

const PadBoard = tw.div`
  w-[430px] h-[1058px]
  rounded-2xl
  max-[1440px]:w-[1000px] max-[1440px]:h-fit
  max-[1024px]:w-[98%]
  flex flex-col justify-start items-center
  overflow-hidden
  max-[768px]:w-[100%]
`;

const HistoryBoard = tw.div`
  relative w-full h-fit py-6
  max-[1440px]:h-fit
  max-[1440px]:py-0
`;

const Canvas = tw.div`
  w-full h-[500px] bg-white
  rounded-2xl border-4 border-[#FFC801]
  overflow-hidden
  flex justify-end items-end
  max-[1024px]:h-[420px]
  max-[768px]:h-[360px]
  max-[640px]:h-[320px]
  max-[580px]:h-[260px]
  max-[480px]:h-[240px]
  max-[420px]:h-[200px]
  max-[380px]:h-[180px]
  max-[360px]:h-[160px]
  max-[320px]:h-[140px]
  max-[280px]:h-[120px]
`;

const formattedToday = getTime();

export default function PowerBallNumber() {
  const navigate = useCustomNavigate();
  const { isPowerballModalOpen } = usePowerModalStore();
  const { isResultPopupOpen } = useResultStore();
  let callUseDispatch = useDispatch();
  const {
    count,
    round,
    openSuccess,
    lotteryResult,
    resPowerballHistory,
    resOddsCondition,
    resPowerballFromHash,
    connectSocket,
    ReqOddsCondition,
    setRewardOddEven,
    canBet,
  } = usePowerBallSocket('lottery');

  const CallDispatch = val => {
    callUseDispatch({
      type: 'CURFRAME',
      curState: val,
    });
  };

  const CallDispatchSFX = val => {
    callUseDispatch({
      type: 'CURFRAMESFX',
      curSFX: val,
    });
  };

  useEffect(() => {
    const closeSocket = connectSocket();

    return () => closeSocket();
  }, []);

  return (
    <div className="max-[1024px]:w-[98%] max-[1024px]:mx-2 max-[1440px]:max-w-[1000px] h-fit mx-auto LD:mx-[128px] max-[768px]:my-4 my-[100px] overflow-x-hidden flex flex-row justify-center items-center space-x-4 max-[1440px]:flex-col max-[1440px]:space-y-4 max-[1440px]:space-x-0 overflow-y-hidden">
      <div className="w-[1000px] max-[1024px]:w-[98%] space-y-4">
        <NumberHeader count={count} round={round} formattedToday={formattedToday} />

        <GameBoard>
          <img src={Line} alt="Line" className="w-full object-fit absolute z-1 max-[768px]:hidden " />
          <Canvas className="relative">
            <SoundManager bgm={0}></SoundManager>
            <PowerBallZoomCanvas
              callUseDispatch={callUseDispatch}
              curState={curState}
              CallDispatch={CallDispatch}
              CallDispatchSFX={CallDispatchSFX}
              whiteballShowState={8}
            ></PowerBallZoomCanvas>
            <PowerBallCanvas
              callUseDispatch={callUseDispatch}
              curState={curState}
              CallDispatch={CallDispatch}
              CallDispatchSFX={CallDispatchSFX}
              ReqOddsCondition={ReqOddsCondition}
              setRewardOddEven={setRewardOddEven}
              gameType={0}
              navigate={navigate}
            ></PowerBallCanvas>
            <NumberResultDisplay isResultPopupOpen={isResultPopupOpen} canBet={canBet} />
          </Canvas>
          {isResultPopupOpen && (
            <NumberResultModal isOpen={isResultPopupOpen} round={round} lotteryResult={lotteryResult} />
          )}
          <img src={Line} alt="Line" className="w-full object-fit absolute z-1 max-[1440px]:hidden" />
          <HistoryBoard>
            <div className="relative flex flex-row max-[984px]:flex-col justify-between space-x-5 max-[984px]:space-x-0 max-[984px]:space-y-2 items-center h-full">
              <NumberHistoryBoard
                resPowerballHistory={resPowerballHistory}
                resOddsCondition={resOddsCondition}
                resPowerballFromHash={resPowerballFromHash}
              />
              <NumberRewardBoard
                resPowerballHistory={resPowerballHistory}
                resOddsCondition={resOddsCondition}
                formattedToday={formattedToday}
                round={round}
              />
            </div>
          </HistoryBoard>
        </GameBoard>
      </div>
      <PadBoard>
        <LotteryPadBoard round={round} canBet={canBet} />
      </PadBoard>

      {/* success */}
      {isPowerballModalOpen && <PowerBallModal isOpen={openSuccess} submit={true} />}
      {/* block */}
      {isPowerballModalOpen && <PowerBallModal isOpen={isPowerballModalOpen} />}
    </div>
  );
}
