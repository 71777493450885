import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles(theme => ({
    root:{
    },
    labelStyle:{
        color:'#8753CF',
        fontSize:'0.813rem',
        fontWeight:'bold'
    },
    buttonBoxStyle:{
        display:'grid',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridTemplateColumns: 'repeat(6, 1fr)',
        gridGap:14,
        justifyItems:'center',
        alignItems:'center',
        marginBottom:40,
        '@media all and (max-width: 510px)': {
            gridTemplateRows: 'repeat(3, 1fr)',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap:10,
        },
    },
    buttonStyle:{
        // width:64,
        minHeight:64,
        '&.MuiButton-root':{
            backgroundColor:'#2D1A3C',
            color:'#fff',
            fontSize:'1.375rem',
            borderRadius:0,
            '&:hover':{
                border:'1px solid #EAD40C',
                color:'#EAD40C',
                fontWeight:'bold',
            },
        }
    },
    buttonActiveStyle:{
        '&.MuiButton-root':{
            border:'1px solid #EAD40C',
            color:'#EAD40C',
            fontWeight:'bold',
        },
    },
    selectStyle:{
        marginTop:12,
        marginBottom:30,
        width:'100%',
        backgroundColor:'#140523',
        '& .MuiSelect-select, svg':{
            color:'#fff',
        },
        '& fieldset':{
            border:'0 none',
        },
    },
    textBoxStyle:{
        textAlign:'center',
        color:'#fff',
        fontSize:'1.125rem'
    },
    pStyle:{
        fontSize:'1.25rem',  
        '& b':{
            fontSize:'1.5rem',
            color:'#EAD40C',
            marginLeft:8,
        }
    },
}));

