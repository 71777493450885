import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100vw',
    background: '#222',
    boxSizing: 'border-box',
    '& *, & .MuiTypography-root': {
      fontFamily: '"Poppins", sans-serif!important',
    },
    '& .MuiTabPanel-root': {
      padding: 10,
    },
  },
  container: {
    '&.MuiContainer-root': {
      width: 'calc(100vw - 48px)',
      '@media (min-width:279px) and (max-width:380px)': {
        padding: 0,
      },
    },
  },
  titleStyle: {
    '&.MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      width: 600,
      height: 63,
      marginBottom: 30,
      fontSize: '1.5rem',
      fontWeight: 800,
      color: '#222',
      fontFamily: '"Orbitron", sans-serif!important',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 6.61%, rgba(255, 255, 255, 0.82) 49.95%, rgba(255, 255, 255, 0.00) 91.51%)',
      '@media (min-width:280px) and (max-width:667px)': {
        height: 40,
        fontSize: '1.25rem',
        width: 360,
      },
    },
  },
  tableStyle: {
    '&.MuiTable-root': {
      // width:'calc(100% - 48px)',
      margin: '0 auto',
      borderRadius: 8,
      overflow: 'hidden',
      '& .MuiTableRow-head': {
        background: '#3C6D9B',
        '& .MuiTableCell-head': {
          color: '#fff',
          fontSize: '1.125rem',
          fontWeight: '500',
          border: '0 none',
          borderLeft: '1px solid #222',
          '&:first-child': {
            borderLeft: '0 none',
          },
          '@media (min-width:480px) and (max-width:667px)': {
            fontSize: '0.875rem',
          },
          '@media (min-width:280px) and (max-width:479.99px)': {
            fontSize: '0.75rem',
            padding: '4px',
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          background: '#4E4E4E',
          '&:nth-child(even)': {
            background: '#4E4E4E',
            opacity: '0.9',
          },
          '& .MuiTableCell-body': {
            padding: '8px 16px',
            color: '#fff',
            fontSize: '1rem',
            border: '0 none',
            borderLeft: '1px solid #222',
            '&:first-child': {
              borderLeft: '0 none',
            },
            '@media (min-width:480px) and (max-width:667px)': {
              fontSize: '0.875rem',
              letterSpacing: '-0.5px',
              padding: '6px 8px',
            },
            '@media (min-width:281px) and (max-width:479.99px)': {
              fontSize: '0.75rem',
              letterSpacing: '-0.5px',
              padding: '6px',
            },
            '@media (min-width:1px) and (max-width:280px)': {
              padding: '2px',
              fontSize: '0.75rem',
              letterSpacing: '-0.5px',
            },
          },
        },
      },
    },
  },
  buttonStyle: {
    '&.MuiButton-root': {
      width: 180,
      height: 48,
      fontSize: '0.875rem',
      color: '#fff',
      borderRadius: 8,
      '&:hover': {
        background: '#7C9B3F',
        opacity: 0.6,
      },
      '@media (min-width:480px) and (max-width:667px)': {
        minWidth: 'auto',
        width: 104,
        height: 32,
        fontSize: '0.875rem',
      },
      '@media (min-width:280px) and (max-width:479.99px)': {
        minWidth: 'auto',
        width: 70,
        height: 24,
        letterSpacing: '-0.8px',
        fontSize: '0.75rem',
      },
    },
  },
  outlineGradientStyle: {
    '&.MuiButton-root': {
      background: '#7C9B3F',
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
      border: '2px solid transparent',
      '&:hover': {
        background: '#7C9B3F',
        opacity: 0.6,
      },
    },
  },
  containedStyle: {
    '&.MuiButton-root': {
      background: '#6439D1',
      '&.Mui-disabled': {
        color: '#fff',
        opacity: 0.3,
      },
      '&:hover': {
        background: '#7C9B3F',
        opacity: 0.6,
      },
    },
  },
  outlineStyle: {
    '&.MuiButton-root': {
      color: '#fff',
      background: '#EEAB1D',
    },
    '&hover': {
      opacity: 0.8,
    },
  },
  buttonStyle2: {
    '&.MuiButton-root': {
      width: 120,
      height: 48,
      color: '#fff',
      backgroundColor: '#9B896C',
      borderRadius: 8,
      '&:hover': {
        background: '#9B896C',
        opacity: 0.6,
      },
      '&.Mui-disabled, &:disabled': {
        color: '#FFD84D',
        opacity: 0.2,
      },
      '@media (min-width:480px) and (max-width:667px)': {
        minWidth: 'auto',
        width: 44,
        height: 32,
        fontSize: '0.875rem',
      },
      '@media (min-width:280px) and (max-width:479.99px)': {
        minWidth: 'auto',
        width: 42,
        height: 24,
        letterSpacing: '-0.8px',
        fontSize: '0.75rem',
      },
    },
  },
  paginationStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px 0',
    '& button, & .MuiPaginationItem-root': {
      minWidth: 28,
      height: 28,
      fontSize: '0.75rem',
      color: '#fff',
      borderRadius: 8,
    },
    '& li:first-child > button, & li:last-child > button': {},
    '& .MuiPaginationItem-root:hover': {
      backgroundColor: 'rgba(212, 112, 255, 0.1)!important',
    },
    '& button.Mui-selected': {
      backgroundColor: '#7C9B3F',
      borderRadius: 8,
    },
    '@media (min-width:370px) and (max-width:440px)': {
      '& button, & .MuiPaginationItem-root': {
        padding: 0,
        margin: 0,
      },
    },
    '@media (min-width:279px) and (max-width:369px)': {
      '& button, & .MuiPaginationItem-root': {
        padding: 0,
        margin: -2,
      },
    },
  },
  progressBar: {
    '&.MuiLinearProgress-root': {
      minWidth: 338,
      height: 13,
      borderRadius: 13,
      background: 'rgba(69,4,168,1)',
      '& > span': {
        background: 'linear-gradient(90deg, rgba(69,4,168,1) 10%, rgba(136,4,185,1) 65%, rgba(134,0,153,1) 100%)',
      },
      '@media (min-width:279px) and (max-width:475px)': {
        minWidth: 210,
      },
    },
  },
}));
