import React, { useState, useEffect, Component, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  PaginationItem,
  Stack,
  LinearProgress,
} from '@mui/material';
import clsx from 'clsx';
import { useStyles } from '../styles/ResultStyles';
import WinnersTable from '../../components/betting/WinnersTable';
import MyResultsTable from '../../components/betting/MyResultsTable';
import { ReactComponent as ExitIcon } from '../../img/ExitIcon.svg';
import { HiChevronDown } from 'react-icons/hi';
import { GetAddHttpURL } from '../../horsestall/progresscombine/jsUtil_combine';

import i18n from '../../lang/i18n';
import useLang from '../../lib/hook/useLang';
import ResultProgressBar from '../../horsestall/progresscombine/ResultProgressBar';
import { useSelector, useDispatch } from 'react-redux';
import * as config from '../../config';
import * as define from '../../define';
import { useNavigate } from 'react-router-dom';

const rows = [3, 1, 4, 7, 8, 9, 11, 12, 2, 10, 5, 6];

export default function GameResult(props) {
  const { lngT } = useLang('racing:result');
  const classes = useStyles();

  const [user, setUser] = useState('');

  // const [bettingresult, setbettingresult] = useState([]);
  const [userbettingresult, setuserbettingresult] = useState([]);

  const [rank, setRank] = useState([]);

  // const [isWinnerListOpen, setWinnerListOpen] = useState(false);
  // const [winnerListAnimation, setWinnerListAnimation] = useState(false);

  const isAnimating = useRef(false);
  const [betProgress, setBetProgress] = useState(0);
  const navigate = useNavigate();

  var urlparam = {
    user: useSelector(state => state.user),
    progress: useSelector(state => state.progress),
    lan: useSelector(state => state.lan),
    game: useSelector(state => state.game),
    goodsType: useSelector(state => state.goodsType),
  };
  urlparam.game = useSelector(state => state.game);
  console.log('GameResult game : ' + urlparam.game);

  function progressState() {
    setBetProgress(1); // 초기값 설정
    console.log('프로그레스 바 시작');
    setTimeout(() => {
      setBetProgress(100); // 프로그레스를 완료 상태로 업데이트
      console.log('프로그레스 바 완료');
    }, define.RESULTPAGE_WAITING_DELAY);
  }

  const CallbackErrorException = () => {
    // console.log('ranks : ' + JSON.stringify(data) );
    console.log('CallbackErrorException');
    setRank([]);
    //navigate('/LobbyGame');
    // props.CallBackResultToBet(0);
  };

  useEffect(() => {
    setUser(urlparam.user);
    var url = GetAddHttpURL();
    var fullUrl = `${url}/api/v1/bettingresult?game=${urlparam.game}&user=${urlparam.user}&coin=${define.GoodsTypeServer[Number(urlparam.goodsType)]}`;
    // var fullUrl = `${url}/api/v1/bettingresult?game=${urlparam.game}&user=${urlparam.user}&coin=${'coin'}`;
    //var fullUrl = `${url}/api/v1/bettingresult?game=${27782}&user=${'seatetra1'}`;
    console.log('GameResult URL : ' + fullUrl);
    fetch(fullUrl, {
      method: 'GET', // 또는 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.log('ranks : ' + JSON.stringify(data));
        if (data.ErrorCode === 0) {
          // setbettingresult(data.list);
          setuserbettingresult(data.user);
          if (data.rank !== undefined) {
            setRank(data.rank);
          } else {
            CallbackErrorException();
          }
        } else {
          CallbackErrorException();
        }
      })
      .catch(e => {
        console.log(e);
      });
    progressState();
  }, [urlparam.game]);

  return (
    <div className="absolute top-0 left-0 flex items-center min-h-[100vh] w-[100%] bg-black/70 z-50">
      <div className="relative w-full min-h-[100vh]">
        <ResultProgressBar className="absolute top-0 left-0 z-[99] w-full" progress={betProgress} />
        <div className="px-[24px] D:px-[120px] space-y-14 max-T:py-[0px] max-D:py-[30px] py-[120px] max-D:landscape:scale-90 ">
          <Box className={classes.numberBoxStyle}>
            <Typography variant={'h5'}>{/*Ranking Grade*/ lngT('rankNumber')}</Typography>
            <Box className={classes.numberStyle}>
              {rank.map((row, index) => (
                <span key={index} className={classes.numberStyle}>
                  {row}
                </span>
              ))}
            </Box>
          </Box>
          <div>
            <Typography variant="h5" className={clsx(classes.titleStyle)}>
              {lngT('title:myResult')}
            </Typography>
            <MyResultsTable userbettingresult={userbettingresult} rank={rank} />
          </div>

          {/* <div className="relative">
            <div className="absolute z-50 -top-2 right-0 cursor-pointer">
              <button
                className="text-white flex flex-row items-center "
                onClick={() => {
                  setWinnerListOpen(!isWinnerListOpen);
                  setWinnerListAnimation(!winnerListAnimation);
                }}
              >
                <span className="w-[120px] text-lg font-medium pr-2">{ lngT('header:winnerList')}</span>
                <HiChevronDown
                  className={`h-10 w-10 rounded-lg bg-[#3C6D9B]/60 hover:bg-[#3C6D9B]/30 ${
                    isWinnerListOpen ? 'rotate-180' : 'rotate-360'
                  }`}
                />
              </button>
            </div>
            <section
              className={`${winnerListAnimation ? 'T:animate-fadeIn' : 'T:animate-fadeOut'} ${
                isWinnerListOpen ? 'block' : 'hidden'
              } flex flex-col justify-center`}
            >
              <Typography variant="h3" className={classes.titleStyle}>
                { lngT('title:winner')}
              </Typography>
              <WinnersTable bettingresult={bettingresult} rank={rank} />
            </section>
          </div> */}
        </div>
      </div>
    </div>
  );
}
