import {
  MdKeyboardArrowLeft as ArrowLeftIcon,
  MdKeyboardArrowRight as ArrowRightIcon,
  MdOutlineKeyboardDoubleArrowLeft as DoubleArrowLeftIcon,
  MdOutlineKeyboardDoubleArrowRight as DoubleArrowRightIcon,
} from 'react-icons/md';

export default function Paginator({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}: {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (number: number) => void;
}) {
  // 페이저의 번호를 계산합니다.
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  let startPage: number, endPage: number;

  if (totalPages <= 5) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  // 페이지 번호를 생성합니다.
  const pages = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

  return (
    <div className="flex justify-center items-center h-fit py-5">
      <div className="flex justify-center items-center w-[30px] h-[30px]">
        <DoubleArrowLeftIcon
          onClick={() => currentPage > 3 && onPageChange(1)}
          className={`text-white ${
            currentPage > 3 ? 'opacity-100 cursor-pointer hover:text-white/50' : 'opacity-30 cursor-default'
          }`}
        />
      </div>
      <div className="flex justify-center items-center w-[30px] h-[30px]">
        <ArrowLeftIcon
          onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
          className={`text-white ${
            currentPage !== 1 ? 'opacity-100 cursor-pointer hover:text-white/50' : 'opacity-30 cursor-default'
          }`}
        />
      </div>
      <div className="flex flex-row space-x-1">
        {pages.map(pageNumber => (
          <div
            key={pageNumber}
            className={`flex justify-center items-center w-[24px] h-[24px] text-sm ${
              pageNumber === currentPage ? 'bg-white text-admin-bg' : 'hover:bg-white/20 text-white'
            } cursor-pointer rounded-lg`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center w-[30px] h-[30px]">
        <ArrowRightIcon
          onClick={() => currentPage !== totalPages && onPageChange(currentPage + 1)}
          className={`text-white ${
            currentPage !== totalPages ? 'opacity-100 cursor-pointer hover:text-white/50' : 'opacity-30 cursor-default'
          }`}
        />
      </div>
      <div className="flex justify-center items-center w-[30px] h-[30px]">
        <DoubleArrowRightIcon
          onClick={() => endPage < totalPages && onPageChange(totalPages)}
          className={`text-white ${
            endPage < totalPages ? 'opacity-100 cursor-pointer hover:text-white/50' : 'opacity-30 cursor-default'
          }`}
        />
      </div>
    </div>
  );
}
