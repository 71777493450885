import { makeStyles } from "@material-ui/styles";


export const useTableCaptionComponentStyles = makeStyles(theme => ({
    root: {
        '& .MuiTypography-root':{
            display:'flex',
            justifyContent:'flex-end',
            color:'#E09B59',//'#fff',
            fontSize:'2.125rem!important',//'1.125rem!important',
            
            '& span':{
                marginRight:4,
            }
        }
    },
    time:{
      
    },
    round:{
        paddingBottom:10,
    },
}));