import {useEffect, useState} from "react";
import {SpinState} from "../../../util/reeldefine";

import {useReelGameStore} from "@/store/ReelGameStore";
import {withStyles} from "@mui/styles";
import {getStyleByBrand} from "../styles/StyleManager";
import {getBrand} from "../styles/DeviceManager";

const styles = getStyleByBrand();



let gamestarttime;
let passedtime;
function DebugUI(props) {
    const { classes } = props;
    const userAgent = navigator.userAgent;
    const userAgentData = navigator.userAgentData;

    console.log("userAgent - origin : ", userAgent);
    console.log("userAgentData - new : ", userAgentData);

    if(userAgentData)
    {
    navigator.userAgentData
        .getHighEntropyValues([
            "architecture",
            "bitness",
            "formFactor",
            "fullVersionList",
            "model",
            "platformVersion",
            "uaFullVersion",
            "wow64"])
        .then((values) => console.log(values));
    }

    const {
        isAutoSpin,
        jackpotspinCount,
        freespinCount,
        normalspinCount,
        useCoin,
        rewardCoin,
        freespinentercount,
        jackpotspinentercount,

        setAutoSpin,
        setRewardCoin,
    } = useReelGameStore();

    const [auto, setauto] = useState(isAutoSpin);
    const [timeupdate,settimeupdate] = useState(0);

    useEffect(()=>{
        gamestarttime = Date.now();

        const timecheckdelay = 3000;
        const timecheckinterval = setInterval(() => {
            passedtime = (Date.now() - gamestarttime) / 1000;
            settimeupdate(passedtime);
        }, timecheckdelay);

        return()=>clearInterval(timecheckinterval);
    },[])

    useEffect(() => {
        // If Stop Reel then Do Update Front UI
        if (props.spinState === SpinState.REWARD) {
            setRewardCoin(rewardCoin + props.reward);

            if (isAutoSpin) {
                setTimeout(() => {
                    props.CallSpin();
                }, 1000);
            }
        }
    }, [props.spinState])


    const accessInfo = navigator.userAgent.toLowerCase();
    const deviceInfo = accessInfo.substring(accessInfo.indexOf("(")+1, accessInfo.indexOf(")"));
    const splitDeviceInfo = deviceInfo.split(";");

    const baseOS = splitDeviceInfo[0];
    const os = splitDeviceInfo[1];
    const deviceName = splitDeviceInfo[2];

    const brand = getBrand();

    return (
        <div className={classes.debug_root}>

            <button style={{ left: '300px', color:'red' }}
                    onClick={() => {
                        console.log('AUTO');
                        setAutoSpin(!isAutoSpin);
                        setauto(isAutoSpin);
                    }}>
                {`AUTO - ${isAutoSpin}`}
            </button>
            <br/>
            <label style={{ color: 'white' }}>
                {`[userAgent] : ${navigator.userAgent}`}<br/>
                {`[baseOS] : ${baseOS}`}<br/>
                {`[os] : ${os}`}<br/>
                {`[deviceName] : ${deviceName}`}<br/>
                {`[brand] : ${brand}`}<br/>
            </label>
            <br/>
            <label style={{ color: 'white' }}>
                {`[OS] : ${navigator.platform}`}
            </label>
            <br/>

            <label style={{ color: 'white' }}>
                {`[COIN]`}
            </label>
            <br/>
            <label style={{color: 'green'}}>
                {`use : ${useCoin.toFixed(2)} reward : ${rewardCoin.toFixed(2)} earn : ${(rewardCoin - useCoin).toFixed(2)} (${(rewardCoin / (useCoin) * 100).toFixed(2)})%`}
            </label>
            <br/>
            <label style={{ color: 'white' }}>
                {`[SPIN COUNT]`}
            </label>
            <br/>
            <label style={{ color: 'green' }}>
                {`Jackpot:${jackpotspinCount} Free:${freespinCount} Normal:${normalspinCount} Total:${jackpotspinCount + freespinCount + normalspinCount}`}
            </label>
            <br/>
            <label style={{ color: 'white' }} >
                {`[SPIN ENTER COUNT]`}
            </label>
            <br/>
            <label style={{ color: 'green' }}>
                {`Jackpot:${jackpotspinentercount} Free:${freespinentercount} Total:${jackpotspinentercount + freespinentercount}`}
            </label>
            <br/>

            <label style={{ color: 'white'}}>
                {props.reelGameServerInfo.freeroll && `[FREE SPIN]`}
            </label>
            <br/>

            <label style={{ color: 'green'}}>
                {props.reelGameServerInfo.freeroll && `freespin : ${props.reelGameServerInfo.freeroll.count}/${props.reelGameServerInfo.freeroll.size}`}
            </label>
            <br/>

            <label style={{ color: 'white' }}>
                {`[PASSED TIME]`}
            </label>
            <br/>

            <label style={{ color: 'green' }}>
                {`${ (passedtime/60).toFixed(0) } min ${(passedtime%60).toFixed(0)} sec`}
            </label>
        </div>
    )
}

export default withStyles(styles) (DebugUI);

