import { ListPager, HeaderItem, RowItem, useListPager } from '@/lib/custom-lib/ListPager';
import { formatDateToLocal } from '@/lib/util';
import { useLang } from '@/lib/hook';
import useToggle from '@/lib/hook/useToggle';
import { env } from '@/env';

import { ContentLayout } from '@/page/common/layout';
import { generateContainer } from '@/page/common/lib';
import { coinHistoryInfoParser } from '@/page/mypage/lib/utils';

import Point from '@/assets/images/coin_point.png';
import Free from '@/assets/images/coin_free.png';

type ListHeaderProps = {
  width: number;
  title: string;
};

const headers: ListHeaderProps[] = [
  {
    width: 100,
    title: /*Index*/ 'table:title:index',
  },
  {
    width: 250,
    title: /*Date*/ 'table:title:date',
  },
  {
    width: 300,
    title: /*Info*/ 'table:title:info',
  },
  {
    width: 180,
    title: /*Amount*/ 'table:title:amount',
  },
  {
    width: 180,
    title: /*Balance*/ 'table:title:points',
  },
];

export default function PointHistory() {
  const { lngT } = useLang('mypage:pointHistory');
  const { currentPage, setCurrentPage } = useListPager();
  const { Toggle, isToggled } = useToggle({
    init: false,
  });

  const listHeaderProps: ListHeaderProps[] = headers.map(header => ({ ...header, title: lngT(header.title) }));

  const PaginateUserCoinHistoryContainer = generateContainer<CoinHistoryData[]>(
    `${env.api.host}/user/history/point?currentPage=${currentPage}&perPage=${20}&sortBy=DESC&coinType=${
      isToggled ? 'free' : 'coin'
    }`
  );

  return (
    <ContentLayout title={lngT('title')}>
      <div className="flex justify-end w-full pb-8 text-white gap-x-4">
        <div className={`flex flex-row items-center gap-x-2 ${isToggled ? 'opacity-30' : ''}`}>
          <img src={Point} alt="point" className="w-[28px] h-[28px]" />
          <span className="text-lg">{lngT('table:points')}</span>
        </div>
        <Toggle />
        <div className={`flex flex-row items-center gap-x-2 ${!isToggled ? 'opacity-30' : ''}`}>
          <img src={Free} alt="point" className="w-[28px] h-[28px]" />
          <span className="text-lg">{lngT('table:freePoints')}</span>
        </div>
      </div>
      <PaginateUserCoinHistoryContainer>
        {({ data, isLoading, error }) => {
          const totalPageCount = data?.meta?.paginate?.totalCount ?? 0;
          const perPage = data?.meta?.paginate?.perPage ?? 0;

          return (
            <ListPager
              header={
                <HeaderItem.Container>
                  {listHeaderProps.map((header, i) => (
                    <HeaderItem.Box key={i} $width={header.width}>
                      {header.title}
                    </HeaderItem.Box>
                  ))}
                </HeaderItem.Container>
              }
              dataLength={data?.data?.length ?? 0}
              rows={data?.data?.map((item, index) => {
                const isEven = !(index % 2);
                const indexNumber = totalPageCount - (index + (currentPage - 1) * perPage);
                const time = formatDateToLocal(item.time_transaction);
                const amount = item.amount;
                const info = coinHistoryInfoParser(item, lngT);
                const coinAfter = item.coin_after.toLocaleString();

                return (
                  <RowItem.Container key={index} $isEven={isEven} className={isToggled ? 'bg-opacity-50' : ''}>
                    <RowItem.Box $width={listHeaderProps[0].width}>{indexNumber}</RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[1].width}>{time}</RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[2].width}>{info}</RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[3].width}>
                      <span className={amount > 0 ? 'text-[#84FFE9]' : 'text-[#FF5656]'}>{amount > 0 ? '+' : '-'}</span>
                      <span>{Math.abs(amount).toLocaleString()}</span>
                    </RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[4].width}>{coinAfter}</RowItem.Box>
                  </RowItem.Container>
                );
              })}
              pager={{
                totalPageCount,
                perPage,
                currentPage,
                setCurrentPage,
              }}
              isLoading={isLoading}
              error={error}
            />
          );
        }}
      </PaginateUserCoinHistoryContainer>
    </ContentLayout>
  );
}
