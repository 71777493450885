import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        margin:'12px 0',
        borderRadius:'12px 0 12px 0',
        padding:20,
        color:'#fff',
        //background:'radial-gradient(circle, rgba(247,150,192,1) 0%, rgb(42 5 68)  100%)',
        background:'radial-gradient(circle, rgba(247,150,192,1) 0%, rgb(42 5 68)  100%)',
        '& p':{
            fontSize:'1.5rem',
            fontWeight:'bold',
        },
    },
    titleStyle:{
        // color:'#fff',/
    },
    pStyle:{
        // color:'rgba(255,255,255, 0.4)',
        padding:'0 20px',
    },
    rateStyle:{
        // color:'#EAD40C',
        color:'#faff0b',
        fontSize:'2rem!important',
        textDecoration:'underline',
    },
    
}));


/*import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        margin:'12px 0',
        '& p':{
            fontSize:'1.5rem',
            fontWeight:'bold',
        },
    },
    titleStyle:{
        color:'#fff',
    },
    pStyle:{
        color:'rgba(255,255,255, 0.4)',
        padding:'0 20px',
    },
    rateStyle:{
        color:'#EAD40C',
    },
}));*/

