import { create } from 'zustand';

import type { ReactNode } from 'react';

type ModalStore = {
  isModalOpen: boolean;
  useCloseDim: boolean;
  content: null | ReactNode;
  openModal: (content: null | ReactNode, useCloseDim?: boolean) => void;
  closeModal: () => void;
};

const useModalStore = create<ModalStore>()(set => ({
  isModalOpen: false,
  useCloseDim: false,
  content: null,
  openModal: (content, useCloseDim = false) => {
    set({
      isModalOpen: true,
      content,
      useCloseDim,
    });
    const body = document.querySelector('body');

    if (body) {
      body.style.overflowY = 'hidden';
    }
  },
  closeModal: () => {
    const body = document.querySelector('body');

    if (body) {
      body.style.overflowY = 'overlay';
    }

    set({
      isModalOpen: false,
      content: null,
      useCloseDim: false,
    });
  },
}));

export default useModalStore;
