import styles from './firstpage.module.css';
import Arrow from '@/img/landingPage/arrowButton.svg';
import MakeABet from '@/img/landingPage/makeABet.png';
import RantAHorse from '@/img/landingPage/rentHorse.png';
import useLang from '@/lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useLocation } from 'react-router-dom';
import { PlayGame, GetDefaultGoods } from '../../../horsestall/progresscombine/jsUtil_combine';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';
import * as define from '../../../define';

export default function FirstPage() {
  const { lngT: LngT } = useLang('landingPage:firstPage');
  const { lngT: lobbyLngT } = useLang('powerball');
  const { search } = useLocation();
  const [user] = useUser();
  const navigate = useCustomNavigate();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  return (
    <div className="h-[1000px] max-[1280px]:h-[800px] relative">
      <div className={styles.background}>
        <header className={styles.header}></header>
        <div className={styles.conteinerText}>
          <h2 className={styles.title}>{LngT('title')}</h2>
          <h3 className={styles.subtitle}>
            {LngT('subTitle:1')}
            <p>
              {LngT('subTitle:2')} <span className={styles.bonus}> {LngT('subTitle:3')}</span>
              <span className="text-3xl text-[#a565f9] font-semibold tracking-tighter">
                {LngT('subTitle:4')}
                <br />
              </span>
              {LngT('subTitle:5')}
            </p>
          </h3>
          <div className="w-[600px]">
            <p className={styles.description}>{LngT('phrase')}</p>
          </div>
        </div>        
        <div className={styles.alignButtons}>
          <button
            onClick={() => {
              // navigate('/LobbyGame');              
              PlayGame(navigate, user?.name, GetDefaultGoods(balance?.data))
            }}
            className={styles.buttonApostar}
          >
            <img src={Arrow} alt="Arrow" width={50} className={styles.arrow} />
            <div className={styles.textButtonBet}>
              <p className={styles.pMakeABetTitle}>{LngT('buttonApostar:title')}</p>
              <p className={styles.pMakeABetSubTitle}>{LngT('buttonApostar:subTitle')}</p>
            </div>
            <img src={MakeABet} alt="Make a bet horse" className={styles.horseButtonMakeABet} />
          </button>

          <a href={ define.SHOW_METACROSS_ONLY ? `/horse/stake${search}` : '/'} className={styles.buttonAlugar}>
            <img src={Arrow} alt="Arrow" width={50} />
            <div className={styles.textButtonBet}>
              <p className={styles.pMakeABetTitle}>{LngT('buttonAlugar:title')}</p>
              <p className={styles.pMakeABetSubTitle}>{LngT('buttonAlugar:subTitle')}</p>
            </div>
            <img src={RantAHorse} alt="lease a horse" className={styles.horseButtonRantAHorse} />
          </a>
        </div>
      </div>
    </div>
  );
}
