import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    SpinState,
    CanSpin,
    AUTOSTOP_DELAY,
    REEL_ZINDEX,
    GetFreeRollState,
    FreeRollState,
} from '../../../util/reeldefine.js'
import { useNavigate } from 'react-router-dom';
import DebugUI from "./DebugUI";
import UISpine from "../effect/UISpine";
import BackSpine from "../effect/BackSpine";
import * as PropTypes from "prop-types";
import useLang from '@/lib/hook/useLang';

import {withStyles} from "@mui/styles";

import {useReelGameStore} from "@/store/ReelGameStore";
import CommonDialog from '../../../components/betting/CommonDialog-v1';
import { ReactComponent as WarningIcon } from '../../../img/WarningIcon.svg';
import {Typography} from "@mui/material";
import {getStyleByBrand} from "../styles/StyleManager";
import {
    AudioAllStop,
    AudioPlay,
    AudioPlayForBGM,
    AudioStopForBGM,
    MuteAll,
    unMuteAll,
    IsPlaying,
    MuteForIOS, IsPlayingIOS, AudioStopForBGMIOS, AudioPlayForBGMIOS, audioContextClear
} from '@/util/audiomanager.js';
import {ReelGameFrontEffect} from '../effect/ReelGameFrontEffect'
import {getBrand} from "../../reelgame/styles/DeviceManager";
const styles = getStyleByBrand();

BackSpine.propTypes = {filename: PropTypes.string};
let hascoinInterval = undefined;
let rewardInterval = undefined;
let stopSoundAlreadyPlayed = false;
let betCoinAniTimeOut = undefined;
let hasCoinAniTimeOut = undefined;
let canforcestop = false;
function ReelGameFront(props) {
    const { classes, isRacingService } = props;
    const { lngT, currentLang, isCurrentEn, isExists } = useLang('reelGame');
    const navigate = useNavigate();

    const {
        isAutoSpin, jackpotspinCount, freespinCount, normalspinCount, useCoin, rewardCoin,
        setJackpotSpinCount, setFreeSpinCount, setNormalSpinCount, setUserCoin,
        isIphone, isIOSMute, setIsIphone, setIsIOSMute
    } = useReelGameStore();

    const reelGameAniStep = useSelector((state)=>state.reelGameAniStep);
    const reelGameSpinState = useSelector((state)=>state.reelGameSpinState);
    const reelGameResourceReady = useSelector((state)=>state.reelGameResourceReady);
    const enterfreespinanimationplaying = useSelector((state)=>state.enterfreespinanimationplaying);
    const SetReelGameSpinState=(spinstate)=>{
        // console.log('storespinstate reelgamefront : ' + spinstate);
        props.dispatch({
            type:'REELGAME_SPINSTATE',
            reelGameSpinState:spinstate,
        });
    }

    const betTextType1 =
        (<div className={classes.control_text_wrapper}>
            <img className={classes.control_text_img} src={'/images/slotItems/carrot.webp'} alt={"carrot"}/>
            <label className={classes.control_text_with_img}>X 0.15</label>
            <img className={classes.control_text_img} src={'/images/slotItems/grape.webp'} alt={"grape"}/>
            <label className={classes.control_text_with_img}>X 0.30</label>
            <img className={classes.control_text_img} src={'/images/slotItems/flag.webp'} alt={"flag"}/>
            <label className={classes.control_text_with_img}>X 0.45</label>
            <img className={classes.control_text_img} src={'/images/slotItems/clover.webp'} alt={"clover"}/>
            <label className={classes.control_text_with_img}>X 0.50</label>
            <img className={classes.control_text_img} src={'/images/slotItems/u.webp'} alt={"u"}/>
            <label className={classes.control_text_with_img}>X 0.75</label>
            <img className={classes.control_text_img} src={'/images/slotItems/trophy.webp'} alt={"trophy"}/>
            <label className={classes.control_text_with_img}>X 0.90</label>
            <img className={classes.control_text_img} src={'/images/slotItems/wild.webp'} alt={"wild"}/>
            <label className={classes.control_text_with_img}>X 1.50</label>
        </div>)

    const betTextType2 =
        (<div className={classes.control_text_wrapper}>
            <img className={classes.control_text_img} src={'/images/slotItems/wild.webp'} alt={"wild"}/>
            <label className={classes.control_text_with_img}>X 1.50</label>
            <img className={classes.control_text_img} src={'/images/slotItems/trophy.webp'} alt={"trophy"}/>
            <label className={classes.control_text_with_img}>X 0.90</label>
            <img className={classes.control_text_img} src={'/images/slotItems/u.webp'} alt={"u"}/>
            <label className={classes.control_text_with_img}>X 0.75</label>
            <img className={classes.control_text_img} src={'/images/slotItems/clover.webp'} alt={"clover"}/>
            <label className={classes.control_text_with_img}>X 0.50</label>
            <img className={classes.control_text_img} src={'/images/slotItems/flag.webp'} alt={"flag"}/>
            <label className={classes.control_text_with_img}>X 0.45</label>
            <img className={classes.control_text_img} src={'/images/slotItems/grape.webp'} alt={"grape"}/>
            <label className={classes.control_text_with_img}>X 0.30</label>
            <img className={classes.control_text_img} src={'/images/slotItems/carrot.webp'} alt={"carrot"}/>
            <label className={classes.control_text_with_img}>X 0.15</label>
        </div>)

    const betTextType3 =
        (<div className={classes.control_text_wrapper}>
            <label>{lngT('bet_text:type3')}</label>
        </div>)

    const betTextType4 =
        (<div className={classes.control_text_wrapper_jackpot}>
            <img className={classes.control_text_img} style={{marginRight: 4}} src={'/images/slotItems/h_r.webp'} alt={"h_r"}/>
            <img className={classes.control_text_img} style={{marginRight: 4}} src={'/images/slotItems/h_b.webp'} alt={"h_b"}/>
            <img className={classes.control_text_img} style={{marginRight: 4}} src={'/images/slotItems/h_v.webp'} alt={"h_v"}/>
            <img className={classes.control_text_img} style={{marginRight: 4}} src={'/images/slotItems/h_w.webp'} alt={"h_w"}/>
            <label>X 1.50</label>
        </div>)

    const betTextList = [
        betTextType1,
        betTextType2,
        betTextType3
    ]

    const onebetcoin = 1;
    const betmin = 1 * onebetcoin;
    const betmax = 10 * onebetcoin;

    const [isDialogOpen, setDialogOpen] = useState(false);
    const [totalFreeRewored, setTotalFreeRewored] = useState(0);
    const [betText, setBetText] = useState(lngT('bet_text:good_luck'));
    const [betcoin, setbetcoin] = useState(betmin);
    const [hascoin, sethascoin] = useState(0);
    const [rewardcoin, setrewardcoin] = useState(0);
    const [fx_roll_ani, setfx_roll_ani] = useState('roll_stop_ani');
    const [fx_jackpot_ani, setfx_jackpot_ani] = useState('uslot_stop_ani');
    // const [firstSpin, setFirstSpin] = useState(true);

    // 베팅 숫자 에니메이션 관련
    const [betCoinAni, setBetCoinAni] = useState(false);
    // const betCoinAniTimeOutRef = useRef(null);

    // Credit 표시 관련
    const [newHascoin, setNewHascoin] = useState(0);
    const [hascoinAni, setHascoinAni] = useState(false);

    // reward 관련
    const [newReward, setNewReward] = useState(0);
    const [rewardAni, setRewardAni] = useState(false);

    // sound 관련
    const [isMute, setIsMute] = useState(true); // 기본 = 정지

    // 연결 문구 관련
    const [isTarget, setIsTarget] = useState(false);
    const [isBridgeDialogOpen, setIsBridgeDialogOpen] = useState(false);

    // freeroll 여부 체크
    const IsFreeRoll = GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START || GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_PLAYING
    const [resultslot, setresultslot] = useState([-1, -1, -1, -1, -1]);

    // 베팅 텍스트 관리
    const [betTextIndex, setBetTextIndex] = useState(0);
    const [showText, setShowText] = useState(false); // 최초 진입시 Good Luck 은 고정이므로 false
    const betTextParentRef = useRef(null);
    const betTextChildRef = useRef(null);

    useEffect(() => {
        const brand = getBrand();
        const checkIphone = brand === 'iphone' || brand === 'ipad';
        // const checkIphone = true;
        setIsIphone(checkIphone);

        const checkjackpotDelay = 500.0;
        const checkjackpotInterval = setInterval(() => {
            //  const lastjackpottime =  props.reelGameServerInfo.lastjackpottime;
            //  const nowtime = Date.now();
            //  const diffedTime = nowtime - lastjackpottime;

            //  // 서버에서 1초마다 jackpot 상태를 줍니다. "현재시간 - 이전 메시지를 받은 시간의 차가" 실제로는 1초가 좀 넘어 갈 수 있으니 넉넉히 2초를 넘어가면 상태를 false로 만듭니다.
            //  const jackpotCancelTerm = 2000;
            //  if((jackpotCancelTerm < diffedTime) && jackpot)

            if(GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.NORMAL)
                setfx_jackpot_ani(GetStopAni())
        }, checkjackpotDelay);

        isMute ? mute() : unMute();

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                mute();
                setIsIOSMute(true);
                MuteForIOS();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // window.addEventListener('pagehide', () => mute());
        // window.addEventListener('blur', () => mute());

        return ()=>{
            AudioAllStop();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            // window.removeEventListener('pagehide', () => mute());
            // window.removeEventListener('blur', () => mute());
            clearInterval(checkjackpotInterval);
            audioContextClear();
        }
    },[])

    //////// 초기값 설정 ////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (hascoin === 0 && props.balance !== hascoin) {
            sethascoin(props.balance);
            if(props.balance < 50){
                setIsTarget(true);
            }
        }
    }, [props.balance])

    useEffect(() => {
        // 부모와 자식 요소의 너비 비교
        const parentWidth = betTextParentRef.current.offsetWidth;
        const childWidth = betTextChildRef.current.scrollWidth;

        // 자식 요소의 너비가 부모 요소의 너비보다 크면 true 설정
        if (childWidth > parentWidth) {
            setShowText(true);
        } else {
            setShowText(false);
        }
    }, [betText]);

    useEffect(() => {
        if (reelGameSpinState === SpinState.NONE) {
            if (props.reelGameServerInfo.freeroll !== undefined) {
                setfx_jackpot_ani(IsFreeRoll ? GetFreeSpinAni('a') : GetStopAni());
                setfx_roll_ani('roll_stop_ani');
                // TODO - 임시 주석
                if(IsFreeRoll){
                    setBetText(betTextType4)
                }
            }
        }
    }, [props.reelGameServerInfo.freeroll])


    useEffect(() => {
        if (props.reelGameServerInfo.needUpdateSpin) {
            if(!IsFreeRoll){
                handleChangeHasCoin(hascoin - betcoin);
            }
            console.log('reel freerollstate : ' + GetFreeRollState(props.reelGameServerInfo.freeroll));
            setfx_jackpot_ani(
                GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_PLAYING ||
                GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_END ? GetFreeSpinAni('a') : GetStopAni());

            props.dispatch(
                {
                    type: 'REELGAME_SERVERINFO',
                    reelGameServerInfo: {
                        needUpdateSpin: false
                    }
                }
            )

            canforcestop = true;
        }
    }, [props.reelGameServerInfo.needUpdateSpin])

    useEffect(()=>{
        if(fx_roll_ani === 'roll_start2_ani')
        {
            if(reelGameSpinState === SpinState.STOPPING)
            {
                setfx_roll_ani('roll_stop_ani');
            }
        }
    },[fx_roll_ani])

    useEffect(()=>{
        if(reelGameResourceReady===false)
            return;
        setfx_roll_ani('roll_idle_ani');
    },[reelGameResourceReady])

    const changeBetText = () => {
        // 리워드가 0이면 문구 변경
        setBetTextIndex((prevState => {
            if(prevState >= 2){
                return 0;
            } else {
                return prevState + 1;
            }
        }));
    }

    useEffect(() => {
        // If Spin Reel then Do Update Front UI. Spin 패킷 응답 받은 후에 처리 해주세요.
        if (reelGameSpinState === SpinState.SPINNING) {
            setresultslot([-1, -1, -1, -1, -1]);
            setfx_roll_ani('roll_start_ani');
            // console.log('aniplay roll_start_ani');
            setTimeout(() => {
                setfx_roll_ani('roll_start2_ani');

                // console.log('aniplay roll_start2_ani' + reelGameSpinState);
            }, 500);
        }

        if(reelGameSpinState === SpinState.STOPPING)
        {
            setfx_roll_ani('roll_stop_ani');
            // console.log('aniplay roll_stop_ani');
        }

        // If Stop Reel then Do Update Front UI
        if (reelGameSpinState === SpinState.REWARD) {
            // console.log('resultSlot : ' + props.resultSlot);
            setresultslot(props.resultSlot);
            const resetspinbuttondelay = GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START ? 1500 : props.reward > 0 ? 500 : 100;
            setTimeout(() => {
                setfx_roll_ani('roll_idle_ani');
            }, resetspinbuttondelay);

            if(props.reward !== 0){
                // 0부터 시작 하도록
                setrewardcoin(0);
                updateReward(props.reward);
                // TODO - 임시 주석
                changeBetText(); // 문구 변경
            } else {
                setrewardcoin(props.reward);
            }

            if(isTarget){
                if(props.balance >= 50 && isRacingService){
                    setIsBridgeDialogOpen(true);
                    setIsTarget(false); // 초기화
                }
            }

            if (GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_PLAYING || GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_END) {
                if(props.reward > 0)
                {
                    setfx_jackpot_ani(GetFreeSpinAni('b'));
                }
                else
                {
                    setfx_jackpot_ani(GetFreeSpinAni('a'));
                }
            } else if (GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START) {
                setfx_jackpot_ani(GetFreeSpinAni('a'));
            } else
                setfx_jackpot_ani(GetStopAni());

                // console.log('isfreeroll : ' + IsFreeRoll + ' rollstate : ' + reelGameSpinState);

            // 게임 중
            // IsFreeRoll 은 사용자가 spin 을 누르자마자 결정되므로, BGM 이 조금더 빨리 재생되고 빨리 정지된다.
            // 따라서, REWARD 가 갱신되는 시점(게임이 마무리 되었다고 보여지는 시점) 에 맞춰서 BGM 이 따라가도록.
            if(isIphone){
                if(!isIOSMute){
                    PlayJackPotBGMIOS();
                }
            } else {
                PlayJackPotBGM();
            }
        }

    }, [reelGameSpinState])

    const PlayJackPotBGM=()=>
    {
        if(IsFreeRoll){
            const bgm = props.reelGameServerInfo.isJackpot ? 'jackpotbgm' : 'freespinbgm';
            console.log(`${bgm} BGM START`);

            if(IsPlaying('jackpotonbgm'))
                AudioStopForBGM('jackpotonbgm');

            if(IsPlaying('normalbgm'))
                AudioStopForBGM('normalbgm');

            if(!IsPlaying(bgm))
                AudioPlayForBGM(bgm);
        } else {
            const bgm = props.reelGameServerInfo.isJackpot ? 'jackpotbgm' : 'freespinbgm';
            console.log(`${bgm} BGM STOP`);
            if(IsPlaying(bgm))
                AudioStopForBGM(bgm);

            const normalbgm = 'normalbgm';
            if(!IsPlaying(normalbgm))
                AudioPlayForBGM(normalbgm);
        }
    }

    const PlayJackPotBGMIOS=()=>
    {
        if(IsFreeRoll){
            const bgm = props.reelGameServerInfo.isJackpot ? 'jackpotbgm' : 'freespinbgm';
            console.log(`${bgm} BGM START`);

            if(IsPlayingIOS('jackpotonbgm'))
                AudioStopForBGMIOS('jackpotonbgm');

            if(IsPlayingIOS('normalbgm'))
                AudioStopForBGMIOS('normalbgm');

            if(!IsPlayingIOS(bgm))
                AudioPlayForBGMIOS(bgm);
        } else {
            const bgm = props.reelGameServerInfo.isJackpot ? 'jackpotbgm' : 'freespinbgm';
            console.log(`${bgm} BGM STOP`);
            if(IsPlayingIOS(bgm))
                AudioStopForBGMIOS(bgm);

            const normalbgm = 'normalbgm';
            if(!IsPlayingIOS(normalbgm))
                AudioPlayForBGMIOS(normalbgm);
        }
    }

    const updateHascoin = (newCoin) => {
        // 아직 인터벌이 진행중인데 업데이트 요청이 온 경우.
        // 이전 newCoin 값에 도달 못했으면 도달하게 해줌.
        // 이후 로직 진행
        if(hascoinInterval){
            if(hascoin !== newHascoin){
                sethascoin(newHascoin);
            }
            clearInterval(hascoinInterval);
            hascoinInterval = undefined;
        }

        setNewHascoin(newCoin);

        const update = () => {
            const difference = newCoin - hascoin;

            // 한 번에 변경할 양 계산 (예: 차이의 10%, 최소 단위 1)
            // 차이가 양수일 경우 증가, 음수일 경우 감소
            const changeStep = difference > 0 ? Math.max(1, Math.floor(difference / 10)) : Math.min(-1, Math.floor(difference / 10));

            // 크레딧 업데이트
            sethascoin((currentCoin) => {
                const updatedCredit = currentCoin + changeStep;
                // 새로운 값이 목표치를 초과하거나 미달하지 않도록 함
                if ((difference > 0 && updatedCredit >= newCoin) || (difference < 0 && updatedCredit <= newCoin)) {
                    return newCoin;
                }
                return updatedCredit;
            });
        };
        hascoinInterval = setInterval(update, 100);

    }

    const updateReward = (reward) => {
        // 진행중이면 초기화.
        if(rewardInterval){
            setRewardAni(false);
            clearInterval(rewardInterval);
            rewardInterval = undefined;
        }

        setRewardAni(true);

        setNewReward(reward);

        const update = () => {
            const difference = reward;

            // 한 번에 변경할 양 계산 (리워드의 10%, 최소 단위 1)
            // 항상 양수.
            // const changeStep = Math.max(1, Math.floor((difference / 10).toFixed(1)));
            const changeStep = Number(Math.floor(difference * 100) / 1000);

            // 크레딧 업데이트
            setrewardcoin((currentCoin) => {
                const updatedReward = currentCoin + changeStep;
                // 새로운 값이 목표치를 초과하거나 미달하지 않도록 함
                if ((difference > 0 && updatedReward >= reward) || (difference < 0 && updatedReward <= reward)) {
                    return reward;
                }
                return updatedReward;
            });
        };
        rewardInterval = setInterval(update, 50);

    }

    useEffect(() => {
        if(hascoin === newHascoin){
            if(hascoinInterval){
                clearInterval(hascoinInterval);
                hascoinInterval = undefined;
            }
        }
    }, [hascoin])

    useEffect(() => {
        if(rewardcoin === newReward){
            if(rewardInterval){
                if(props.balance !== hascoin){
                    // updateHascoin(props.balance);
                    handleChangeHasCoin(props.balance);
                }
                setRewardAni(false);
                clearInterval(rewardInterval);
                rewardInterval = undefined;
            }
        }
    }, [rewardcoin])

    const GetFreeSpinAni = (spintype) => {
        return props.reelGameServerInfo.isJackpot ? `jackpot_${spintype}_ani` : `freespin_${spintype}_ani`;
    }

    const IsJacopotOn=()=>{
        if(props.reelGameServerInfo.lastjackpottime === undefined)
            return false;

        const lastjackpottime =  props.reelGameServerInfo.lastjackpottime;
        const nowtime = Date.now();
        const diffedTime = nowtime - lastjackpottime;

        // 서버에서 1초마다 jackpot 상태를 줍니다. "현재시간 - 이전 메시지를 받은 시간의 차가" 실제로는 1초가 좀 넘어 갈 수 있으니 넉넉히 2초를 넘어가면 상태를 false로 만듭니다.
        const jackpotCancelTerm = 2000;
        return (jackpotCancelTerm > diffedTime);
    }

    const GetStopAni = () => {
        if(IsJacopotOn())
        {
            if(isIphone){
                if(!isIOSMute){
                    AudioPlayForBGM('jackpotonbgm');
                }
            } else {
                AudioPlayForBGM('jackpotonbgm');
            }
            return 'jackpot_c_ani';
        }
        else
        {
            if(IsPlaying('jackpotonbgm'))
                AudioStopForBGM('jackpotonbgm');
            return 'uslot_stop_ani';
        }
    }

    const CallStop = (isforcestop) => {
        console.log('rlot-stopping!');
        if(isforcestop){
            if(!canforcestop)
            {
                console.log('rlot-잘못된스탑요청');
                return;
            }

            // 손으로 stop 눌렀을 때 -> 무조건 재생
            AudioPlay('stopBt', true, isIphone, isIOSMute);
            stopSoundAlreadyPlayed = true;
        } else {
            // 자연 스톱일 때 -> 진짜 자연스톱 일수도 손으로 누른 다음일수도 있음.
            if(!stopSoundAlreadyPlayed){
                // 진짜 자연 스톱일 때만 재생
                // AudioPlay('spinBt');
            } else {
                stopSoundAlreadyPlayed = false; // 초기화
            }
        }

        const changeSpinState = SpinState.STOPPING;
        props.setisforcestop(isforcestop)
        SetReelGameSpinState(changeSpinState);

    }

    const CallSpin = () => {
        console.log('spinning!');
        // if(firstSpin){
        //     if(IsFreeRoll) {
        //         console.log("free spin BGM START");
        //         AudioPlayForBGM('freespinbgm');
        //     }
        //     setFirstSpin(false);
        // }
        canforcestop = false;
        setNewReward(0);
        SetReelGameSpinState(SpinState.SPINNING);
        props.ReqRollSlotGame(betcoin);

        console.log('Callback ResRollSlotGame');

        AudioPlay('spinBt', false, isIphone, isIOSMute);


        // 리워드 관견 애니메이션이 끝나기 전에 다음 스핀이 도는 경우.
        // 관련 값을 바로 셋팅하고 리워드 관련 동작을 취소 해준다
        if(rewardInterval){
            setrewardcoin(props.reward)
            if(props.balance !== hascoin){
                // updateHascoin(props.balance);
                handleChangeHasCoin(props.balance);
            }
            setRewardAni(false);
            clearInterval(rewardInterval);
            rewardInterval = undefined;
        }

        // debug
        // const IsFreeRoll = GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START || GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_PLAYING
        if (IsFreeRoll)
        {
            if(props.reelGameServerInfo.isJackpot)
                setJackpotSpinCount(jackpotspinCount+1);
            else
                setFreeSpinCount(freespinCount+1);
        }
        else
            setNormalSpinCount(normalspinCount+1);

        if(!IsFreeRoll)
            setUserCoin(useCoin + betcoin);

        const debugteststopdelay = 500;
        //////////////////////


        let stopDelay = isAutoSpin ? debugteststopdelay : AUTOSTOP_DELAY;
        setTimeout(() => {
            CallStop(false);
        }, stopDelay);
    }

    const CallCancled = () => {
        console.log('cant input');
    }

    const handleChangeBetCoin = (coin, audioname) => {

        AudioPlay(audioname, true, isIphone, isIOSMute);

        if(betCoinAniTimeOut){
            setBetCoinAni(false);
            clearTimeout(betCoinAniTimeOut);
        }

        setBetCoinAni(true);

        setbetcoin(coin);
        betCoinAniTimeOut = setTimeout(() => {
            setBetCoinAni(false);
            betCoinAniTimeOut = null;
        }, 100);
    }

    const handleChangeHasCoin = (coin) => {
        if(hasCoinAniTimeOut){
            clearTimeout(hasCoinAniTimeOut);
        }

        setHascoinAni(true);
        sethascoin(coin);
        hasCoinAniTimeOut = setTimeout(() => setHascoinAni(false), 400);
    }

    useEffect(() => {
        if (reelGameSpinState === SpinState.SPINNING
            || reelGameSpinState === SpinState.REWARD) {
            handleChangeText();
        }
    }, [reelGameSpinState, rewardcoin, currentLang]);

    useEffect(() => {
        if (reelGameSpinState === SpinState.REWARD) {
            handleChangeFreeRollReword();
        }
    }, [newReward]);

    const handleChangeFreeRollReword = () => {
        const isFreeRoll = props.freeroll.size > 0;
        if (isFreeRoll) {
            if (props.freeroll.size > 0 && props.freeroll.count === 0) {
                setTotalFreeRewored(0);
            } else {
                setTotalFreeRewored(totalFreeRewored+newReward);
            }
        }
    }

    const getBetText = (index) => {
        return betTextList[index];
    }

    const handleChangeText = () => {
            const isFreeRoll = props.freeroll.size - props.freeroll.count > 0;
            const before = reelGameSpinState === SpinState.SPINNING; // "spin count total"
            const lose = newReward === 0; // "spin count total"

            let text;
            if (isFreeRoll) {
                // TODO - 임시 주석
                if(props.freeroll.count === 0){
                    // text = betTextType4;
                    if(reelGameSpinState === SpinState.SPINNING){
                        text = betTextType4;
                    } else {
                        setShowText(false);
                        text = (
                            <div>
                                <label style={{ color:'#139FEC' }}>{lngT('bet_text:win')} : </label>
                                <label>{convertNumberToDPSec(rewardcoin)}</label>
                            </div>
                        );
                    }
                } else if (before || lose) { // lose
                    text = (
                        <div>
                            <label style={{ color:'#139FEC' }}>{lngT('bet_text:total_win')} : </label>
                            <label>{convertNumberToDPSec(totalFreeRewored)}</label>
                            {" / "}
                            <label style={{ color:'#139FEC' }}>{lngT('bet_text:free_spin')} : </label>
                            <label>{props.freeroll.size - props.freeroll.count}</label>
                        </div>
                    );
                } else { // win
                    text = (
                        <div>
                            <label style={{ color:'#139FEC' }}>{lngT('bet_text:win')} : </label>
                            <label>{convertNumberToDPSec(rewardcoin)}</label>
                            {" / "}
                            <label style={{ color:'#139FEC' }}> {lngT('bet_text:free_spin')} : </label>
                            <label>{props.freeroll.size - props.freeroll.count}</label>
                        </div>
                    );
                }
            } else {
                if (before || lose) { // lose
                    // TODO - 임시 주석 해제 -> 주석 또는 제거 해야함.
                    // text = <label >{lngT('bet_text:good_luck')}</label>;

                    // TODO - 임시 주석
                    setShowText(true);
                    text = getBetText(betTextIndex);
                } else { // win
                    // TODO - 임시 주석
                    setShowText(false);
                    text = (
                        <div>
                            <label style={{ color:'#139FEC' }}>{lngT('bet_text:win')} : </label>
                            <label>{convertNumberToDPSec(rewardcoin)}</label>
                        </div>
                    );
                }
            }
            setBetText(text);
    }

    const convertNumberToDPSec = (number) => {
        return  (Math.floor(number * 100) / 100).toFixed(2);
    }

    const handleClickSpin = () => {
        if (CanSpin(reelGameSpinState) && fx_roll_ani === 'roll_idle_ani') {
            console.log(`TESTSPIN  : CallSpin - ${reelGameSpinState} - ${reelGameAniStep}`);
            if (hascoin < betcoin) setDialogOpen(true);
            else CallSpin();
        } else if (reelGameSpinState === SpinState.SPINNING) {
            console.log(`TESTSPIN  : CallStop - ${reelGameSpinState} - aniStep ${reelGameAniStep}`);
            CallStop(true);
        } else {
            console.log(`TESTSPIN  : CallCancled - ${reelGameSpinState}`);
            CallCancled();
        }
    }

    const handleChangeDialogOpen = (value) => {
        navigate("/mypage/account");
    }

    const handleClickBridgeConfirm = () => {
        if(isRacingService){
            navigate("/LobbyGame");
        } else {
            navigate("/powerball/lobbyy");
        }
    }

    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits:2}).format(convertNumberToDPSec(number));
    };

    // const audioPlayOnly = (audioRef) => {
    //     // 재생 중 인데 같은걸 또 재생 요청 오면 -> 항상 처음 부터 다시 재생.
    //     if(audioRef.current !== null){
    //         audioRef.current.currentTime = 0;
    //         audioRef.current.play().catch(e => console.log("재생 오류 : ", e));
    //     } else {
    //         console.warn("오디오 ref 없음");
    //     }
    // }

    const mute = () => {
        setIsMute(true);
        AudioPlay('muteBt', true, isIphone, isIOSMute);
        if(isIphone){
            setIsIOSMute(true);
            MuteForIOS();
        } else {
            MuteAll();
        }
        console.log('mute');
    }

    const unMute = () => {
        setIsMute(false);
        AudioPlay('muteBt', true, isIphone, isIOSMute);
        unMuteAll();
        console.log('unMute');

        if (reelGameSpinState === SpinState.NONE) {
            if (props.reelGameServerInfo.freeroll !== undefined) {
                if(isIphone){
                    PlayJackPotBGMIOS();
                } else {
                    PlayJackPotBGM();
                }
            }
        }

        if(isIphone){
            setIsIOSMute(false);
            if(reelGameSpinState !== SpinState.NONE){
                PlayJackPotBGMIOS();
            }
        }
    }


    return (

        <div className={classes.control_root}>

            <div className={classes.bg_image}>
                <UISpine
                    path=''
                    filename='fx_jackpot_common'
                    aniName={fx_jackpot_ani}
                    visible={true}
                    lineaudioname={GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_PLAYING ||
                    GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_END ? 'linefree' : 'linenormal'}
                    // visibles={resultslot.find(element=>element!==-1) ? resultslot : null}
                    visibles={resultslot}
                    enterfreespinanimationplaying={enterfreespinanimationplaying}
                />

                <UISpine
                    path=''
                    filename='fx_roll'
                    aniName={fx_roll_ani}
                    loop={fx_roll_ani === 'roll_idle_ani' || fx_roll_ani === 'roll_start2_ani'}
                    visible={true}/>
            </div>

            <div className={classes.control_main}>
                <div className={classes.control_area}>
                    <div className={classes.control_header}>
                        <div className={classes.control_header2}/>
                        <div className={classes.control_header2}>
                            <div className={classes.control_header3}>
                                <div className={classes.control_header4}>
                                    <button className={classes.control_mute}
                                            onClick={isMute ? () => unMute() : () => mute()}>
                                        <img alt="Button" src={isMute ? `/assets/img/sound_off.png` : `/assets/img/sound_on.png`}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.control_body}>
                        <div className={classes.control_text_div}>
                            <div className={classes.control_text_div2}>
                                <div ref={betTextParentRef} className={classes.control_text_sub}>
                                    {/*TODO - 임시 변경
                                        rewardcoin === 0 -> showText 추후 변경 필요
                                    */}
                                    <div ref={betTextChildRef} className={`${isExists ? classes.control_text : classes.control_text_cn} ${rewardAni ? classes.reward_keyframe : ''} ${showText && classes.control_text_animation}`}>
                                        {betText}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.control_bottom}>
                        <div className={classes.control_bottom2}>
                            <div className={classes.control_label}>
                                <div className={classes.control_label_item}>
                                    <img className={classes.control_label_d}
                                         src={isCurrentEn ? `/assets/img/credet.png` : `/assets/img/credet.png`} alt=''/>
                                </div>
                                <div className={classes.control_label_item}>
                                    <img className={classes.control_label_d}
                                         src={isCurrentEn ? `/assets/img/bet.png` : `/assets/img/bet.png`} alt=''/>
                                </div>
                            </div>
                            <div className={classes.control_coin}>
                                <div className={classes.control_coin_item}>
                                    <div className={`${classes.control_coin_d} ${hascoinAni && classes.hascoin_keyframe}`}>
                                        {formatNumber(hascoin)}
                                    </div>

                                </div>
                                <div className={classes.control_coin_item2}>
                                    <div className={`${classes.control_coin_d} ${betCoinAni && classes.betcoin_keyframe}`}
                                         style={hascoin < betcoin ? {color : 'red'} : {}}>
                                        {convertNumberToDPSec(betcoin)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.control_spin_div}>
                            <div className={classes.control_button}>
                                <button className={classes.control_plus_item}
                                        disabled={
                                            ((props.freeroll.size - props.freeroll.count) > 0)
                                            || (reelGameSpinState === SpinState.SPINNING
                                                || reelGameSpinState === SpinState.STOPPING)
                                            || ((betcoin-1) <= 0)
                                        }
                                        onClick={() => handleChangeBetCoin(Math.max(betmin, betcoin - onebetcoin), 'minusBt')}>

                                    <img alt="Button"
                                         src={`/assets/img/minus.png`}/>
                                </button>
                            </div>

                                <button className={classes.control_spin_button}
                                        onClick={handleClickSpin}/>

                            <div className={classes.control_button}>
                                <button className={classes.control_plus_item}
                                        disabled={
                                            ((props.freeroll.size - props.freeroll.count) > 0)
                                            || (reelGameSpinState === SpinState.SPINNING
                                                || reelGameSpinState === SpinState.STOPPING)
                                            || (hascoin < (betcoin+1))
                                        }
                                        onClick={() => handleChangeBetCoin(Math.min(betmax, betcoin + onebetcoin), 'plusBt')}>
                                    <img alt="Button" src={`/assets/img/plus.png`}/>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
                {/* <DebugUI reelGameServerInfo={props.reelGameServerInfo}
                         CallSpin={CallSpin}
                         spinState={reelGameSpinState}
                         reward={props.reward}/> */}

                <CommonDialog
                    open={isDialogOpen}
                    onClose={() => setDialogOpen(false)}
                    objectImg={<WarningIcon />}
                    children={
                        <Typography>
                            Insufficient balance
                        </Typography>
                    }
                    cancel={'Cancel'}
                    submit={'Confirm'}
                    onConfirm={handleChangeDialogOpen}
                />
                <CommonDialog
                    open={isBridgeDialogOpen}
                    onClose={() => setIsBridgeDialogOpen(false)}
                    children={
                        <Typography>
                            {lngT('dialog_text:racing')}
                        </Typography>
                    }
                    cancel={lngT('dialog_text:cancel')}
                    submit={lngT('dialog_text:confirm')}
                    onConfirm={handleClickBridgeConfirm}
                />

        </div>
    )
}

export default withStyles(styles)(ReelGameFront);
