import styles from './banner.module.css';
import BlackStar from '@/img/landingPage/blackstar.svg';
import BlackKnight from '@/img/landingPage/blackknight.svg';

import { useLang } from '@/lib/hook';

const Banner = () => {
  const { lngT } = useLang('landingPage:banner');
  return (
    <div className={styles.bannerContainer}>
      <img src={BlackStar} alt="Estrela" className={styles.icon} />
      <p className={styles.p}>{lngT('title:banner:1')}</p>
      <img src={BlackKnight} alt="Cavalo" className={styles.icon} />
      <p className={styles.p}>{lngT('title:banner:2')}</p>
      <img src={BlackStar} alt="Estrela" className={styles.icon} />
      <p className={styles.p}>{lngT('title:banner:1')}</p>
      <img src={BlackKnight} alt="Cavalo" className={styles.icon} />
      <p className={styles.p}>{lngT('title:banner:2')}</p>
      <img src={BlackStar} alt="Estrela" className={styles.icon} />
    </div>
  );
};

export default Banner;
