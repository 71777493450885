import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import useUser from '../lib/hook/useUser';

import HoverMenu from './HoverMenu';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useLang } from '@/lib/hook';
import * as config from '../config';
// import ResendEmailMessage from "@/components/ResendEmailMessage";
// import Login from '../img/Login.svg';

const Container = tw.div`
  hidden T:flex flex-row items-center
  space-x-2
`;

const AvatarDefaultIcon = tw.img`
  w-[40px] h-[40px]
  cursor-pointer
  rounded-full
  overflow-hidden 
`;

const AvatarLogoutIcon = tw.img`
  w-[40px] h-[40px]
  cursor-pointer
  rounded-full
  overflow-hidden
`;

export default function UserAvatar() {
  const [user, { isLoading, error, logout }] = useUser();
  const navigate = useCustomNavigate();
  const { lngT } = useLang('landingPage:firstPage');

  return (
    <Container>
      <>
        {user ? (
          <div className="group relative">
            <AvatarDefaultIcon
              src={user?.image || '/images/icons/avatar-default.svg'}
              // src={user?.image || Login}
              alt=""
              onClick={() => navigate('/mypage/userinfo')}
            />
            <div className="absolute right-0 hidden group-hover:block">
              <HoverMenu />
            </div>
          </div>
        ) : (
          <div className="flex gap-4">
            <button
              className={`px-3 py-2 border-[2px]  cursor-pointer rounded-[20px] w-[157px] h-[42px] font-semibold  
              ${
                Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING)
                  ? 'text-[#9c4eff] border-[#9c4eff]'
                  : 'text-white border-white'
              } text-[16px]`}
              alt=""
              onClick={() => navigate('login')}
            >
              {/*LOGIN*/ lngT('login')}
            </button>
            <button
              className="px-3 py-2 bg-[#9c4eff] border-[1px] border-black cursor-pointer rounded-[20px] w-[157px] h-[42px] font-semibold text-white"
              alt=""
              onClick={() => navigate('signup')}
            >
              {/*SIGN UP*/ lngT('signUp')}
            </button>
          </div>
          // <AvatarLogoutIcon src="/images/icons/avatar-logout.svg" alt="" onClick={() => navigate('login')} />
        )}
      </>
      {/* <ResendEmailMessage userEmail={'jpdud@naver.com'} /> */}
    </Container>
  );
}
