import * as define from './define';

const SERVERTYPE_DEV = 'dev';
const SERVERTYPE_LIVE = 'live';
const REACT_APP_SERVER_URL = [
  // SERVICE_RACING
  {
    'dev' : '13.124.61.71:8081',
    'live': 'game.u-podium.com'
  },

  // SERVICE_POWERBALL
  {
    'dev' : '13.124.61.71:8081',
    'live': 'game.up7bet.com'
  }
];

const PREFIX_URL = {};
PREFIX_URL[SERVERTYPE_DEV] = 'http://';
PREFIX_URL[SERVERTYPE_LIVE] = 'https://';

export const GetBetServerURL = () => {
  let serverurl = REACT_APP_SERVER_URL[SERVICE_TYPE][SERVERTYPE];
  // console.log('serverurl : ' + serverurl);
  return REACT_APP_SERVER_URL[SERVICE_TYPE][SERVERTYPE];
};

export const GetPrefixURL = () => {
  return PREFIX_URL[SERVERTYPE];
};

export const SERVERTYPE = SERVERTYPE_LIVE; // dev, test, live, powerball
export const WEBSERVERPORT = 3333; // dev, test, live

export const BETTING_PROGRESS_CLOSED = 0;
export const BETTING_PROGRESS_HORSE_INTRODUCE_UNITY = 1; // 2번이 안 오면 막아준다
export const BETTING_PROGRESS_BETTING = 2;
export const BETTING_PROGRESS_BETTING_CLOSING = 3; // progress 3번인데 remaintime 0이하면 막아버린다
export const BETTING_PROGRESS_GAME_PLAYING_UNITY = 4; // 3초동안 요청 패킷 보내는것 하지 말 것 시상 끝나면 풀 모드 끄고 미리 받아둔 베팅 결과 보여준다. 그 때부터는 3초패킷 보낸다
export const BETTING_PROGRESS_GAME_CLOSING = 5;

export const REQ_TERM_DELAY = 3;
export const NOTICE_REMAINTIME_DELAY = 1;

export const GAME_MOVIE_PLAY = false;

export const GAMEPLAY_EVNETINDEX = 14;
export const horseCount = 12;
export const oddevencount = 2;
export const contractLength = 10;
export const betendwaitdelay = 60;
export const GAMEPLAY_TIME = 150;

export const TIMETABLE_ROW_COUNT = 25;

// test, live 는 아래 변수들 모두 false 로 해주세요
export const DEV_LOG = true;
export const DEV_SESSION = false;
export const TEST_HISTORY_LEGACY = false;
export const TEST_OFFLINE = false;
export const TEST_CSS = true;

// 테스트 후 삭제 할 변수
export const gameIndex = 3386;
export const eventIndex = GAMEPLAY_EVNETINDEX;

// req/betting type: 0 홀짝, 1: 단승식. ODD 1 EVEN 2
// public enum DEBUG_LV
// {
export const LOG_NONE = -1; // 아무것도 표시 하지 않는다 ( 사용 X )
export const LOG_RELEASE = 0; // 기본적인 프로그레스만 표시 ( 배포 모드 )
export const LOG_DEVELOPER_1 = 1; // 개발자 모드 1단계 ( 개발자 로그 표시한다 )
export const LOG_DEVELOPER_2 = 2; // 개발자 모드 2단계 ( 치트를 위한 빨리감기 UI 등을 표시 한다 )
// }
export const DEBUG_LV = LOG_DEVELOPER_2; // 배포할때는 반드시 0이하로 변경 해주세요
export const NATION = 'PT'; //"PT", "KR"
export default contractLength;

//cross-env HTTPS=true

//.env files
export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_OAUTH_ID = process.env.REACT_APP_GOOGLE_OAUTH_ID;

export const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
export const NOTICE_TOKEN_NAME = process.env.REACT_APP_NOTICE_TOKEN_NAME;
export const PIXEL_ACCESS_TOKEN = process.env.REACT_APP_PIXEL_ACCESS_TOKEN;

export const USER_STORE_NAME = process.env.REACT_APP_USER_STORE_NAME;
export const NEED_REFRESH_STORE_NAME = process.env.REACT_APP_USER_STORE_NAME;
export const TEST_PROGRESS_COMBINE = true;

export const RESULTPAGE_WAITING_DELAY = 5000;

// PROGRESS
export const PROGRESS_MAINLOBBY = 0;
export const PROGRESS_INTRO1 = 1;
export const PROGRESS_INTRO2 = 2;
export const PROGRESS_LIVE = 3;
export const PROGRESS_REPLAY = 4;
export const PROGRESS_RESULT = 5;
export const PROGRESS_LOBBYGAME = 10; // 문제가 있을 때 로비로 보낸다
export const PROGRESS_SIGN = 20; // 문제가 있을 때 로그인 창으로 보낸다

// BET TYPE
export const BETTYPE_ODDEVEN = 0;
export const BETTYPE_SINGLE = 1;
export const BETTYPE_SHOW = 2;

export const SERVICE_RACING = 0;
export const SERVICE_POWERBALL = 1;
export const SERVICE_ALL = 100;
export const SERVICE_TYPE = SERVICE_POWERBALL; // SERVICE_RACING : 레이싱 랜딩 페이지 사용, SERVICE_POWERBALL : 파워볼 랜딩 페이지 사용
//
export function ValidContents(contentsType) {
  switch (contentsType) {
    case define.CONTENTS_REELGAME:
      return true;

    default:
      return false;
  }
}

