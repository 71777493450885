import styles from './guideSectionRent.module.css';
import Horse from '@/img/landingPage/Mask_group.png';
import Arrow from '@/img/landingPage/arrowButton.svg';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import useLang from '@/lib/hook/useLang';
import { useLocation } from 'react-router-dom';
import * as define from '../../../define';

export default function GuideSectionRent() {
  const { lngT } = useLang('landingPage:guideSection');
  const navigate = useCustomNavigate();
  const { search } = useLocation();

  return (
    <div className="flex flex-col items-start py-4 space-y-4 max-[922px]:pt-[100px]">
      <header className="relative flex items-center gap-4 -left-[60px]">
        <img
          src={Horse}
          alt="Cavalo"
          className="z-10 absolute -left-[30px] rounded-full max-[922px]:left-[50px] max-[922px]:-top-[150px]"
          width={130}
          height={129}
        />
        <div className="text-[32px] font-bold pl-[120px] max-[922px]:-left-4 max-[922px]:text-[28px] max-[922px]:pl-[60px] uppercase h-[80px] max-[1280px]:w-[320px]">
          {lngT('guideRent:header:title')}
        </div>
      </header>
      <div className="pl-[40px] pt-[50px] space-y-2 max-[922px]:py-4 max-[922px]:pl-0">
        <p className="text-[25px] font-bold uppercase max-[1280px]:text-[20px]">{lngT('guideRent:header:subTitle')}</p>
        <p className="text-[16px]">{lngT('guideRent:header:subsubTitle')}</p>
      </div>

      <div className="relative space-y-6">
        <div className="absolute w-[3.5px] bg-[#9c4eff] h-[660px] -left-6 -top-[130px] z-1  max-[922px]:hidden"></div>
        <div className="relative flex flex-col w-[440px] bg-[#6b1ec8] rounded-[16px] pt-[20px] px-[40px] h-[180px] max-[1280px]:w-[340px] max-[1280px]:h-[200px] max-[1280px]:px-[28px]">
          <div className="absolute text-[36px] text-white bg-[#7823d6] border-[3px] border-[#9c4eff] flex justify-center items-center font-bold rounded-full w-[75px] h-[75px] -left-[60px] top-[50px] max-[1280px]:text-[30px] max-[1280px]:h-[60px] max-[1280px]:w-[60px] max-[1280px]:-left-[53px] max-[922px]:-left-4  max-[922px]:-top-8">
            01
          </div>
          <div className="space-y-2">
            <div className="text-white text-[24px] font-bold uppercase max-[1280px]:text-[20px]">
              {lngT('guideRent:guide:1:title')}
            </div>
            <div className="">
              {lngT('guideRent:guide:1:description')}

              <a className="text-[#2ff3ff]" href={define.SHOW_METACROSS_ONLY ? `/horse/stake${search}` : ''}>
                {lngT('guideRent:guide:1:a')}
              </a>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col w-[440px] bg-[#6b1ec8] rounded-[16px] pt-[20px] px-[40px] h-[180px] max-[1280px]:w-[340px] max-[1280px]:h-[200px] max-[1280px]:px-[28px]">
          <div className="absolute text-[36px] text-white bg-[#7823d6] border-[3px] border-[#9c4eff] flex justify-center items-center font-bold rounded-full w-[75px] h-[75px] -left-[60px] top-[50px] max-[1280px]:text-[30px] max-[1280px]:h-[60px] max-[1280px]:w-[60px] max-[1280px]:-left-[53px] max-[922px]:-left-4  max-[922px]:-top-8">
            02
          </div>
          <div className="space-y-2">
            <div className="text-white text-[24px] font-bold uppercase max-[1280px]:text-[20px]">
              {lngT('guideRent:guide:2:title')}
            </div>
            <div className="">{lngT('guideRent:guide:2:description')}</div>
          </div>
        </div>

        <div className="relative flex flex-col w-[440px] bg-[#6b1ec8] rounded-[16px] py-[25px] px-[40px] h-[220px] max-[1280px]:w-[340px] max-[1280px]:h-[240px] max-[1280px]:px-[28px]">
          <div className="absolute text-[36px] text-white bg-[#7823d6] border-[3px] border-[#9c4eff] flex justify-center items-center font-bold rounded-full w-[75px] h-[75px] -left-[60px] top-[50px] max-[1280px]:text-[30px] max-[1280px]:h-[60px] max-[1280px]:w-[60px] max-[1280px]:-left-[53px] max-[922px]:-left-4  max-[922px]:-top-8">
            03
          </div>
          <div className="space-y-2">
            <div className=" text-[24px] font-bold max-[1280px]:text-[20px]">{lngT('guideRent:guide:3:title')}</div>
            <div className="">
              <strong>{lngT('guideRent:guide:3:strong')}</strong> {lngT('guideRent:guide:3:description')}
            </div>
          </div>
        </div>
      </div>

      {/* <img src="../landingPage/rentAHorseWithoutAHorse.svg" alt="Cavalo" className={styles.horseImage} /> */}
      <a href={define.SHOW_METACROSS_ONLY ? `/horse/stake${search}` : ''} className="flex flex-start pt-[50px] ">
        <div
          className="w-[446px] h-[100px] rounded-2xl cursor-pointer text-[18px] relative font-bold flex pl-8 gap-5 items-center max-[1280px]:w-[340px]"
          style={{
            boxShadow: '-15px 2px 85px 19px rgb(136, 11, 235) inset',
            background: 'linear-gradient(101.61deg, #2ff3ff -14.72%, #ff008a 66.97%)',
          }}
        >
          <img src={Arrow} alt="Arrow" width={50} />
          <div className="">
            <p className="uppercase text-[28px] max-[1280px]:text-[24px]">{lngT('guideRent:betTitle')}</p>
            <p className="text-[1rem] font-medium">{lngT('guideRent:betSubTitle')}</p>
          </div>
          {/* <Image src={RantAHorse} alt="Rant a horse" width={210} className={styles.horseButtonRantAHorse} /> */}
        </div>
      </a>
    </div>
  );
}
