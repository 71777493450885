import { useState, useRef, useEffect } from 'react';
import tw from 'tailwind-styled-components';
import useSWR from 'swr';

import { ReactComponent as ArrowBackIcon } from '../img/ArrowBackIcon.svg';
import { ReactComponent as ArrowForwardIcon } from '../img/ArrowForwardIcon.svg';
import { ReactComponent as ArrowLineLeftIcon } from '../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowLineRightIcon } from '../img/ArrowLineRightIcon.svg';

import GameHistoryItem from './GameHistoryItem';

import * as config from '../config';
import useLang from '../lib/hook/useLang'; //'../../lib/hook/useLang';
import { formatDateToLocal } from '../lib/utils';

const Container = tw.div`
  space-y-[4px]
  w-full
  pb-10
  select-none
`;

const ListHeader = tw.div`
  flex flex-row justify-between
  w-full bg-black/30
  max-[640px]:w-fit
`;

const ListHeaderItem = tw.div`
  flex flex-row justify-center items-center 
  min-[1800px]:w-full
  max-[640px]:!w-[74px]
  py-[10px] px-[16px] max-[1180px]:px-2 max-[640px]:px-[4px]
  text-white text-xs font-medium
  bg-black/30
  max-[640px]:first:!w-[40px]  max-[1180px]:first:!pr-2
`;

const CoinHistoryListContainer = tw.div`
  flex flex-col
  space-y-[4px]
`;

const fetcher = url =>
  fetch(url, {
    method: 'GET',
    credentials: 'include', // 모든 요청에서 쿠키 포함
  })
    .then(r => r.json())
    .catch(e => console.log(e));

export default function GameHistoryList() {
  const { lngT } = useLang('mypage:gameHistory');
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  const {
    data: rawGameHistories,
    isLoading,
    error,
  } = useSWR(`${process.env.REACT_APP_API_URL}/user/bettings?page=${currentPage}&perPage=${perPage}`);
  console.log('rawGameHistories', rawGameHistories);

  const totalItems = rawGameHistories?.data.totalCount;
  console.log(totalItems);
  const totalPages = Math.ceil(totalItems / rawGameHistories?.data.perPage);

  //현재 페이지에 표시할 데이터 계산
  // const indexOfLastItem = totalItems;
  // const indexOfFirstItem = 0;
  // const currentItems = rawGameHistories?.data.slice(indexOfFirstItem, indexOfLastItem);

  const handleChangePage = page => {
    setCurrentPage(page);
  };

  // 페이지 변경 함수
  const handlePageChange = page => {
    setCurrentPage(page);
    handleChangePage(page);
    // console.log(`handlePageChange : ${page}`);
  };

  // const sortedData = rawGameHistories?.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // // 각 항목에 index를 추가
  // const dataWithIndex = sortedData?.map((item, index) => ({ ...item, index: index + 1 }));
  //index
  const calcIndex = (currentPage - 1) * perPage;

  const scrollerContainerRef = useRef(null);
  const [scrollerWidth, setScrollerWidth] = useState(0);

  useEffect(() => {
    if (!scrollerContainerRef.current) return;
    setScrollerWidth(scrollerContainerRef.current?.clientWidth);
  }, [scrollerContainerRef.current]);

  // text-[#84FFE9]' : 'text-[#FF5656]
  return (
    <Container ref={scrollerContainerRef}>
      <div className={`max-[640px]:w-[${scrollerWidth}px] max-[640px]:overflow-x-scroll`}>
        <ListHeader className="">
          <ListHeaderItem className="w-[100px]">{lngT('table:title:no')}</ListHeaderItem>
          <ListHeaderItem className="w-[400px]">{lngT('table:title:date')}</ListHeaderItem>
          <ListHeaderItem className="w-[100px]">{lngT('table:title:round')}</ListHeaderItem>
          <ListHeaderItem className="w-[300px]">{lngT('table:title:bet')}</ListHeaderItem>
          <ListHeaderItem className="w-[300px]">{lngT('table:title:winner')}</ListHeaderItem>
          <ListHeaderItem className="w-[300px]">{lngT('table:title:result')}</ListHeaderItem>
          <ListHeaderItem className="w-[250px]">{lngT('table:title:betting')}</ListHeaderItem>
          <ListHeaderItem className="w-[250px]">{lngT('table:title:odds')}</ListHeaderItem>
          <ListHeaderItem className="w-[250px]">{lngT('table:title:reward')}</ListHeaderItem>
        </ListHeader>
        <CoinHistoryListContainer>
          {isLoading ? (
            'loading'
          ) : Array.isArray(rawGameHistories.data.data) && rawGameHistories.data.data.length ? (
            rawGameHistories.data.data
              ?.map(({ createdAt, raceNumber, selected, rank, type, betting_value, reward_value, odds }, index) => ({
                index: String(totalItems - calcIndex - index),
                date: formatDateToLocal(createdAt),
                raceNumber,
                selected,
                rank,
                type,
                betting_value,
                reward_value,
                odds,
              }))
              // .reverse()
              .map((history, i) => (
                <GameHistoryItem
                  key={i}
                  item={history}
                  currency={lngT('table:points')}
                  rawGameHistories={rawGameHistories}
                />
              ))
          ) : (
            <div className="flex justify-center items-center w-full h-[50px] bg-white/10 text-white text-sm">
              {lngT('table:content')}
            </div>
          )}
        </CoinHistoryListContainer>
      </div>

      {/* 페이지네이션 컴포넌트 */}
      <ul className=" pt-4 flex justify-center items-center space-x-1 T:space-x-2">
        <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer transition-all ease-in-out ${
              currentPage === 1 ? '' : ''
            }`}
          >
            <ArrowForwardIcon />
          </button>
        </div>
        <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer transition-all ease-in-out ${
              currentPage === 1 ? '' : ''
            }`}
          >
            <ArrowLineLeftIcon />
          </button>
        </div>
        {totalPages >= 5
          ? Array.from({ length: totalPages }).map((_, index) => {
              const pageNum =
                currentPage === index + 1
                  ? index + 1
                  : index === 0
                  ? 1
                  : currentPage === 1 && index === 1
                  ? 2
                  : index === totalPages - 1
                  ? totalPages
                  : currentPage <= index + 2 && currentPage > index - 1
                  ? '...'
                  : currentPage === 1 && index === totalPages - 2
                  ? '...'
                  : null;
              return pageNum !== null ? (
                <li key={index} className="flex items-center justify-center">
                  <button
                    onClick={
                      () => handlePageChange(pageNum)
                      // typeof pageNum === 'number'
                      //   ? () => handlePageChange(pageNum)
                      //   : pageNum === '...' && currentPage > 1
                      //   ? () => handlePageChange(currentPage - 1)
                      //   : ''
                    }
                    className={`px-2 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 focus:outline-none transition-all ease-in-out ${
                      currentPage === index + 1 ? 'bg-[#D470FF]/50' : ''
                    } ${pageNum === '...' ? '' : 'cursor-pointer'}`}
                    disabled={pageNum === '...' ? true : false}
                  >
                    {pageNum}
                  </button>
                </li>
              ) : null;
            })
          : Array.from({ length: totalPages }).map((_, index) => (
              <li key={index} className="flex items-center justify-center">
                <button
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-2 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 focus:outline-none cursor-pointer transition-all ease-in-out ${
                    currentPage === index + 1 ? 'bg-[#D470FF]/50' : ''
                  }`}
                >
                  {index + 1}
                </button>
              </li>
            ))}

        <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
          <button
            onClick={() => {
              handlePageChange(currentPage + 1);
            }}
            disabled={currentPage === totalPages}
            className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer transition-all ease-in-out ${
              currentPage === 1 ? '' : ''
            }`}
          >
            <ArrowLineRightIcon />
          </button>
        </div>
        <div className="h-5 w-5 flex items-center justify-center hover:bg-[#D470FF]/20 rounded-md">
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            className={`px-1 py-1 text-[9px] T:text-[10px] font-medium text-white rounded-md hover:bg-opacity-80 hover:rounded-md focus:outline-none cursor-pointer transition-all ease-in-out ${
              currentPage === totalPages ? '' : ''
            }`}
          >
            <ArrowBackIcon />
          </button>
        </div>
      </ul>
    </Container>
  );
}
