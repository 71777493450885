import { Children } from 'react';
import tw from 'tailwind-styled-components';

const HeaderItemContainer = tw.div`
  flex flex-row
  justify-between items-center
  w-fit min-w-[1600px]
  bg-black/30
`;

const Container = ({ children }: { children: React.ReactElement[] | React.ReactElement }) => {
  Children.forEach(children, child => {
    if (child.type !== Box) {
      throw new Error('<HeaderItem.Container>는 <HeaderItem.Box> 컴포넌트만 자식으로 가져야 합니다.');
    }
  });

  return <HeaderItemContainer>{children}</HeaderItemContainer>;
};

const Box = tw.div<{ $width: number }>`
  ${({ $width }) => `w-[${$width}px]`}
  flex flex-row items-center justify-center
  py-[10px] px-[16px]
  text-white text-xs font-medium
  bg-black/30
`;

const HeaderItem = {
  Container,
  Box,
};

export default HeaderItem;
