import styles from './boxIncome.module.css';
import BoxIncome from '@/img/landingPage/mobile/boxIncome.png';
import { useModalStore } from '@/lib/custom-lib/modal';
import useLang from '@/lib/hook/useLang';
import TutorialImage from '@/img/landingPage/tutorialImage.png';
import Arrow from '@/img/landingPage/arrowButton.svg';

const VideoBoxIncomeSection = () => {
  const { lngT } = useLang('landingPage:guideSection');
  const { openModal } = useModalStore();

  const handleClickGameVideo = () => {
    openModal(<VideoModal />, false);
  };

  return (
    <section className={styles.container}>
      <div className={styles.box}>
        <img className="max-[400px]:h-[120px] h-[140px] w-[320px]" src={TutorialImage} alt={''} />
        <div className={styles.textContainer}>
          <h3 className="max-[400px]:text-lg text-xl">{lngT('videoBanner:title')}</h3>
          <p className="max-[400px]:text-base max-[400px]:font-normal text-lg font-normal">
            {lngT('videoBanner:phrase')}
          </p>
        </div>
        <div
          onClick={handleClickGameVideo}
          className="min-[400px]:w-[80%] w-[90%] h-[53px] rounded-2xl text-white flex flex-row items-center justify-center gap-[10px] bg-[#4116AE] uppercase max-[400px]:text-sm  max-[400px]:gap-1 font-semibold cursor-pointer hover:bg-violet-900 transition-all"
        >
          <img className="max-[400px]:w-4" src={Arrow} alt="Arrow" /> {lngT('videoBanner:button')}
        </div>
      </div>
    </section>
  );
};

export default VideoBoxIncomeSection;

const VideoModal = () => {
  const { closeModal } = useModalStore();

  const handleCloseVideoMdoal = (e: any) => {
    const clickedElement = e.target;
    console.log('clickedElement', clickedElement);
    if (!clickedElement.classList.contains('videoModal')) {
      closeModal();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white z-[99]"
      onClick={handleCloseVideoMdoal}
    >
      <div className="relative flex flex-col w-[90%] h-[250px] min-[500px]:h-[300px] bg-black rounded-lg videoModal">
        <button className="absolute -top-8 right-2 text-white" onClick={closeModal}>
          <img className="w-8 h-8" src="../../images/icons/close.svg" />
        </button>
        <iframe
          src="https://www.youtube.com/embed/b5_4Z2ChriA?autoplay=1"
          title="YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="absolute top-0 left-0 w-[100%] h-[100%] "
        ></iframe>
      </div>
    </div>
  );
};
