import { Perf } from 'r3f-perf';
import React, {useRef} from "react";
import {useSelector} from "react-redux";
import {Canvas} from "@react-three/fiber";
import {OrbitControls} from "@react-three/drei";

import {REEL_ZINDEX, SLOT_SCALE} from "../../../util/reeldefine";
import {Slotmachine3x3} from "../slotmachine3x3";
import {PodiumSpine} from "../podiumspine";
import GrayWallPlane from "./GrayWallPlane";
import IronLinePlane from "./IronLinePlane";
import BackgroundWallPlane from "./BackgroundWallPlane";
import CustomLight from "./CustomLight";
import {withStyles} from "@mui/styles";
import {getStyleByBrand} from "../styles/StyleManager";
const styles = getStyleByBrand();

function CanvasManager(props) {
    const { classes } = props;
    const refOrbitControls = useRef(null);

    const reelGameAniStep = useSelector((state)=>state.reelGameAniStep);
    const SetReelGameAniStep=(aniStep)=>{
        // console.log('state : ' + _state);
        props.dispatch({
            type:'REELGAME_ANISTEP',
            reelGameAniStep:aniStep,
        });
    }

    const reelGameSpinState = useSelector((state)=>state.reelGameSpinState);
    const SetReelGameSpinState=(spinstate)=>{        
        // console.log('storespinstate csvmanager : ' + spinstate);
        props.dispatch({
            type:'REELGAME_SPINSTATE',
            reelGameSpinState:spinstate,
        });
    }
    const reelGameResourceReady = useSelector((state)=>state.reelGameResourceReady);
    const SetReelGameResourceReady=(ready)=>{        
        props.dispatch({
            type:'REELGAME_RESOURCEREADY',
            reelGameResourceReady:ready,
        });
    }

    const enterfreespinanimationplaying = useSelector((state)=>state.enterfreespinanimationplaying);
    const Setenterfreespinanimationplaying=(playing)=>{
        props.dispatch({
            type:'ENTERFREESPINANIMATIONPLAYING',
            enterfreespinanimationplaying:playing,
        });
    }

    // useEffect(()=>{
    //   console.log('reelGameServerInfo : ' + JSON.stringify(reelGameServerInfo));
    // },[reelGameServerInfo])

    return (
        <div className={classes.canvas_root}>
            <div className={classes.canvas_base_line}>

            </div>
            <div className={classes.canvas_main}>
                <Canvas>
                    {/* <color alpha='true' attach="background" args={['#00000000']}></color> */}
                    <CustomLight/>
                    <ambientLight/>

                    {/* <OrbitControls ref = {refOrbitControls}/> */}
                    <Slotmachine3x3 position={[0,0,0]}
                                    scale={SLOT_SCALE}
                                    reelGameAniStep={reelGameAniStep}
                                    SetReelGameAniStep={SetReelGameAniStep}
                                    spinState = {reelGameSpinState}
                                    setspinState = {SetReelGameSpinState}
                                    serverResult = {props.serverResult}
                                    isforcestop = {props.isforcestop}
                                    />

                    <PodiumSpine reelGameAniStep={reelGameAniStep}
                                 SetReelGameAniStep={SetReelGameAniStep}
                                 spinState = {reelGameSpinState}
                                 setspinState = {SetReelGameSpinState}
                                 reelGameResourceReady = {reelGameResourceReady}
                                 SetReelGameResourceReady = {SetReelGameResourceReady}
                                 enterfreespinanimationplaying={enterfreespinanimationplaying}
                                 Setenterfreespinanimationplaying = {Setenterfreespinanimationplaying}
                                 serverResult = {props.serverResult}
                                 resultSlot = {props.resultSlot}
                                // freeroll = {props.freeroll}
                                 reelGameServerInfo = {props.reelGameServerInfo}/>

                    {/* <BackgroundWallPlane position={[0.0,0.0,0.0001]} */}
                    <BackgroundWallPlane position={[0.0,0.0,0.0003]}
                                   spinState = {reelGameSpinState}
                                   reward = {props.reward}/>

                    <GrayWallPlane position={[0.0,0.0,0.1]}
                                   spinState = {reelGameSpinState}
                                   reward = {props.reward}/>



                    <IronLinePlane position={[0.0,0.0,1.0]}
                                   spinState = {reelGameSpinState}
                                   reward = {props.reward}/>
                    {/* <Perf position="bottom-left"></Perf> */}
                </Canvas>
            </div>
        </div>

    );
}



export default withStyles(styles) (CanvasManager);

