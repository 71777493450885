import { Children } from 'react';
import tw from 'tailwind-styled-components';

const RowItemContainer = tw.div<{ $isEven: boolean }>`
  ${({ $isEven }) => ($isEven ? 'bg-[#493156]' : 'bg-[#5F4B6C]')}
  flex flex-row items-center justify-between
  w-fit min-w-[1600px]
`;

const Container = ({
  children,
  $isEven,
  className,
}: {
  children: React.ReactElement[] | React.ReactElement;
  $isEven: boolean;
  className?: string;
}) => {
  Children.forEach(children, child => {
    if (child.type !== Box) {
      throw new Error('<RowItem.Container>는 <RowItem.Box> 컴포넌트만 자식으로 가져야 합니다.');
    }
  });

  return (
    <RowItemContainer className={className ?? ''} $isEven={$isEven}>
      {children}
    </RowItemContainer>
  );
};

const Box = tw.div<{ $width: number }>`
  ${({ $width }) => `w-[${$width}px]`}
  flex flex-row items-center justify-center
  py-[10px] px-[16px]
  text-white text-[14px] font-normal
  select-none
`;

const RowItem = {
  Container,
  Box,
};

export default RowItem;
