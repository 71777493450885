import * as config from '../config'
import * as define from '../define'
import { Unity, useUnityContext  } from "react-unity-webgl";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { ChangeProgress, GetAddHttpURL } from './jsUtil'
import { useSelector } from "react-redux";
import { setCookie, getCookie } from '../lib/cookie';
import cn from "classnames";
import { Fragment } from "react";
import '../css/unitystyle.css';
var { webSocketIO } = require("../socketClient.js");
const UNITY_STATE_DEFAULT     = 0;
const UNITY_STATE_INITIALIZE  = 1;
const UNITY_STATE_RENDER      = 2;
// const WINDOW_HEIGHT = 800;

var _unityProvider = undefined;
var _loadingProgression;
var _sendMessage; 
var _addEventListener;
var _removeEventListener;
var soundOnVal = 0;
var _unityState = UNITY_STATE_DEFAULT;
var _isLoaded;
var _isGameOver=false;
var _unload;
var windowsizex = 0; 
var windowsizey = 0;//'0%'; 
function SetXY(progress, isLoadingPage)
{
  // if(_isLandscape)
  // {
  //   windowsizex = _isGameOver ? 0+`%` : 100+`%`;
  //   windowsizey = progress==1 || progress==2 ? window.innerHeight / 2 + 'px' : _isGameOver ? 0 : window.innerHeight + 'px';  
  // }
  // else
  // {
  //   windowsizex = _isGameOver ? 0+`%` : window.innerWidth+`px`;
  //   windowsizey = progress==1 || progress==2 ? window.innerHeight / 2 + 'px' : _isGameOver ? 0 : 100 + '%';  
  // }

  windowsizex = _isGameOver ? 0+`%` : 100+`%`;
  //windowsizey = progress==1 || progress==2 ? window.innerHeight / 2 + 'px' : _isGameOver ? 0 : window.innerHeight + 'px';  
  //windowsizey = progress==1 || progress==2 ? window.innerHeight / 2 + 'px' : _isGameOver ? 0 : window.innerHeight + 'px';  


  var isLandscape = false;
  if (window.matchMedia('(orientation: portrait)').matches) {
    // Portrait 모드일 때 실행할 스크립트
    // 폭과 높이가 같으면 Portrait 모드로 인식돼요
    //console.log("orientation " + 'portrait');
  } else {
    // Landscape 모드일 때 실행할 스크립트
    //console.log("orientation " + 'randscape');
    isLandscape = true;
  }

  // if(isLoadingPage)
  // {
  //   //console.log("SetXY " + isLoadingPage);
  //   windowsizey = window.innerHeight + 'px';  
  //   return;
  // }

  // INTRO BET
  if(progress <= 2)
  {
    //windowsizey = isLandscape ? window.innerWidth / 2 + 'px' : window.innerHeight / 2 + 'px';
    if(isLandscape)
      windowsizey = window.innerHeight * (2/3) + 'px';
    else
      windowsizey = window.innerWidth / 2 + 'px';
  }
  // GAME or REPLAY
  else
  {
    if(_isGameOver)
      windowsizey = 0;
    else 
      windowsizey = isLandscape ? window.innerHeight + 'px' : window.innerWidth - 80 + 'px';
  }
  // console.log("SetXY " + windowsizex + ',' + windowsizey);
  // console.log(`POSS => inX : ${window.innerWidth} inY : ${window.innerHeight} OutX : ${window.outerWidth} OutY : ${window.outerHeight} X : ${window.width} Y : ${window.height}`);
  // console.log(`windowsizex:${windowsizex} windowsizey:${windowsizey}`);
  //isLandscape
}
//var _isLandscape = false;
export function UnityRender(props) 
{
  const [devicePixelRatio, setDevicePixelRatio]=useState(window.devicePixelRatio); 
  const [_isOnPage,set_isOnPage]=useState(false);
  var urlparam = getCookie('urlparam');
  const [game, setGame]=useState(urlparam.game.game);
  const [user, setUser]=useState(urlparam.user.user);
  const [progress, setProgress]=useState(urlparam.progress.progress);  
  //const [needLoading,setneedLoading] = useState(true);  

  function handleClick() {
    _unload();
  }

  
  useEffect(()=>{
    //document.documentElement.requestFullscreen();
    //console.log("document : " + document.body);
    //document.body.requestFullscreen();
    //document.exitFullscreen();
    //Screen.orientation.lock('landscape');

    set_isOnPage(true);
    console.logSafeLog("USEEFFECT LOAD", config.LOG_DEVELOPER_1);
    return()=>{      

      set_isOnPage(false);
      console.log("USEEFFECT UNLOAD", config.LOG_DEVELOPER_1);
      // <LoadingPageTest></LoadingPageTest>
      if(_unload !== undefined)
      {
        handleClick();     
      }
    }
  }, [])

  // useEffect(() => (
  //   onWindowResize(),
  //   window.addEventListener('resize', onWindowResize),
  //   () => window.removeEventListener('resize', onWindowResize)
  // ),[])
  ///test

  
  // const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
  // [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
  // onWindowResize = () => {              
  //   clearTimeout(window.resizeLag)
  //   window.resizeLag = setTimeout(() => {
  //     delete window.resizeLag                       
  //     // setOrientation(isLandscape() ? 'landscape' : 'portrait')
  //     setOrientation('landscape')
  //     console.log('rotation!! : ' + isLandscape());
  //     _isLandscape = isLandscape();
  //   }, 200)
  // }

  // Init Unity wasm & data
  const MOBILE_BUILD_URL = "Build_Mobile";
  const OTHER_BUILD_URL = "Build_Other";
  var buildUrl = OTHER_BUILD_URL;
  // if (/iPhone|iPod|Android/i.test(navigator.userAgent))
  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
  //if (/iPhone|iPod|Android/i.test(navigator.userAgent))
  {
      buildUrl = MOBILE_BUILD_URL;
  }

  var useGZip = false;
  var zipString = useGZip ? ".gz" : "";  
  //var fileserverURL = `http://192.168.0.29:7788/hfsfilefolder`;
  
  var streamfileserverURL = define.FILE_SERVER_URL;//`http://192.168.0.29:5502/files`;
  var fileserverURL = define.FILE_SERVER_URL;//`http://192.168.0.29:5502/files`;
  //var fileserverURL = `http://192.168.0.29:3000/files`;

  const {unload, unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression } =     
  useUnityContext({
      loaderUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.loader.js`,
      dataUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.data${zipString}`,
      frameworkUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.framework.js${zipString}`,
      codeUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.wasm${zipString}`,
      streamingAssetsUrl:`${streamfileserverURL}/Build/${buildUrl}/StreamingAssets`,
      cacheControl : handleCacheControl,
      productName : "upodium",
      productVersion : "1.0",
      companyName : "zeromax",
  });
  
  _unityProvider = unityProvider;
  _sendMessage = sendMessage;
  _addEventListener = addEventListener;
  _removeEventListener = removeEventListener;      
  _loadingProgression = loadingProgression;      
  _isLoaded = isLoaded;
  _unityState = UNITY_STATE_INITIALIZE;
  _unload = unload;
  
  if(_isLoaded && UNITY_STATE_RENDER > _unityState && _isOnPage)
  {      
    const GAME_PROGRESS = 3;
    RUInit(game, progress, progress < GAME_PROGRESS ? 0 : define.GAMEPLAY_EVNETINDEX, urlparam.lan);
    _unityState = UNITY_STATE_RENDER;         
  }  

  if(_isLoaded && props.setunityloaded != null)
    props.setunityloaded(true);

  // const handleChangePixelRatio = useCallback(
  //   function () {
  //     // A function which will update the device pixel ratio of the Unity
  //     // Application to match the device pixel ratio of the browser.
  //     const updateDevicePixelRatio = function () {
  //       setDevicePixelRatio(window.devicePixelRatio);
  //     };
  //     // A media matcher which watches for changes in the device pixel ratio.
  //     const mediaMatcher = window.matchMedia(
  //       `screen and (resolution: ${devicePixelRatio}dppx)`
  //     );
  //     // Adding an event listener to the media matcher which will update the
  //     // device pixel ratio of the Unity Application when the device pixel
  //     // ratio changes.
  //     mediaMatcher.addEventListener("change", updateDevicePixelRatio);
  //     return function () {
  //       // Removing the event listener when the component unmounts.
  //       mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
  //       console.log('devicePixelRatio : ' + devicePixelRatio);
  //     };
      
  //   },
  //   [devicePixelRatio]    
  // );

  

  // var loadingInfo = (progress <= 2 && !needLoading) ?
  // <LoadingReadyPage 
  //   setneedLoading={setneedLoading}
  // >    
  // </LoadingReadyPage>
  // : 
  
  
  // var loadingInfo =
  // _isLoaded || progress <= 2 ? "" :   
  // <LoadingPage
  //   progress = {_loadingProgression}
  // />
  var loadingInfo =
  _isLoaded ? "" :   
  <LoadingPage
    progress = {_loadingProgression}
    gameprogress = {progress}
  />
  var introRemainTime = _isLoaded && progress === 1 ? <IntroRemainSeconds></IntroRemainSeconds> : 
  "";

  SetXY(progress, false);

  var unityx = _isLoaded?windowsizex:0+'px';
  var unityy = _isLoaded?windowsizey:0+'px';
  
  function handleCacheControl(url) {
    if (url.match(/\.data/) || url.match(/\.wasm/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  return (
    <div>            
      {loadingInfo}
      {/* <OnRUTest></OnRUTest> */}
      {/* <OnRUSoudOn></OnRUSoudOn> */}
      {introRemainTime}
      
      <OnURCallback
        setGameState={props.setGameState}
        game={game}
        user={user}
        progress={progress}
      ></OnURCallback>      
    
    <Unity 
      unityProvider={_unityProvider}
      scrolling="no"
        frameBorder={0}
        allowFullScreen
        style={{
          width: unityx,          
          maxHeight: unityy,
          height: unityy,
          border: 0,
          padding: 0,
          margin: 0,                
          left:0,
          top:0,

          // left: 30,
          // top: 30,
        }}
        //devicePixelRatio={devicePixelRatio}        
      />      
    </div>
  );    
}

function LoadingPage(props)
{  
  const [container,setcontainer] = useState(null);
  const [canvas, setcanvas] = useState(null);
  const [loadingBar, setloadingBar] = useState(null);
  const [progressBarFull, setprogressBarFull] = useState(null);
  var loadingbarcss;
  useEffect(()=>{
    loadingbarcss = "unity-loading-bar";
    setcontainer ( document.querySelector("#unity-container") );
    setcanvas ( document.querySelector("#unity-canvas") );
    setloadingBar ( document.querySelector(`#${loadingbarcss}`));
    setprogressBarFull ( document.querySelector("#unity-progress-bar-full"));

    if(progressBarFull != null)
    {
      progressBarFull.style.width = 100 * props.progress + "%";    
      loadingBar.style.display = "block";

      // if(props.gameprogress <= 2)
      //   loadingBar.style.top =  (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) ? 0+`%` : 10+`%`;
      // console.log("progress : " + props.progress);
    }
  }, [props.progress])
  
  var winysizes = windowsizey.split('px');
  var winysize =  winysizes[0]/2;

  var loadinghtml =  
  <div id="unity-loading-bar"
    style={{
      top:winysize
    }}
  >
    <div id="unity-Web_loading_2"
      style={{
        position:'absolute',      
        top:-20,      
        left:-60
      }}
    ></div>
    <div id="unity-Web_loading_1"
      style={{
        position:'absolute',
        top:-20,      
        left:-60
      }}>
    </div>                          
    <div id="unity-progress-bar-empty">
      <div id="unity-progress-bar-full">
      </div>
    </div> 
  </div>



  return <div>    
    {/* <div id="unity-container" className="unity-desktop"> */}
      <canvas id="unity-canvas" 
      style={{
        width: windowsizex,          
        maxHeight: windowsizey,
        height: windowsizey,
        border: 0,
        padding: 0,
        margin: 0,                
        left:0,
        top:0,

        // left: 30,
        // top: 30,
      }}>
      </canvas>
      {loadinghtml}
    </div>
  // </div>  
}

function OnURCallback(props)
{  
  const [userName, setUserName] = useState();
  const [isIntro, setIsIntro] = useState();
  
  const handleGameOver = useCallback((userName, isIntro) => {

    if(!isIntro)
      props.setGameState('close');
      
    _isGameOver = true;
    setUserName(userName);
    setIsIntro(isIntro);

    // if(isIntro)
    //   window.location.href = `/betting?game=${props.game}&user=${props.user}`;

    console.log("LOGTEST : handleGameOver");
  }, []);

  const handleFullScreen = useCallback((isFull) => {    
    if (window.matchMedia('(orientation: portrait)').matches && isFull) {
      
      //enterFullscreen();
      document.documentElement.style.transform = 'rotate(90deg)';
      document.documentElement.style.transformOrigin = 'left top';                      
    } else {
      //exitFullscreen();
      document.documentElement.style.transform = 'none';
      document.documentElement.style.transformOrigin = 'initial';        
    }

    console.log("LOGTEST : handleFullScreen");
  }, []);  

  useEffect(() => {
    _addEventListener("GameOver", handleGameOver);
    // _addEventListener("FullScreen", handleFullScreen);
    return () => {
      _removeEventListener("GameOver", handleGameOver);
      // _removeEventListener("FullScreen", handleFullScreen);
    };
  }, [_addEventListener, _removeEventListener, handleGameOver]);
  //}, [_addEventListener, _removeEventListener, handleGameOver, handleFullScreen]);

  return (
    <Fragment>
      {/* {_isGameOver === true ? 
      props.progress === 1 ? ChangeProgress(props.game, props.user, 2) : "" :
      ""} */}
    </Fragment>
  );
}

var changeValue = 0;
function OnRUTest() 
{
    return (
    <div>  
        <button onClick={()=>{
            console.log('Click!! : ' + changeValue);
            RUExample(changeValue);
            ++changeValue;
        }}>+</button>
    </div>
    )
}

 
function IntroRemainSeconds()
{
  const [needUpdate,setneedUpdate] = useState(false);
  useEffect(()=>{
    const requestTerm = 1000;   
    Interval();
    function Interval()
    {
      setTimeout(() => {
        // if(!savebetinfo.betable)
        // { 
        //   // state가 항상 0이어서 전역변수 사용 함
        //   ChangeProgress(_game, _user, 3);
        // }
    
        setneedUpdate(true);        
        Interval();       

        return ()=>{
          clearTimeout();
        }
      }, requestTerm);
    }
  }, [])

  var remaintime = webSocketIO.data.counter;//webSocketIO.remaintime;
  if(needUpdate)
  {
    console.log("react remaintime : " + remaintime);
    RUMessage("ReactToUnityReceiver", "IntroRemainSeconds", remaintime);
    setneedUpdate(false);
  }

  return (
    <div>                  
    </div>
    )
}

function OnRUSoudOn() 
{
  const myInput = useRef(null);

  const [time, setTime] = React.useState(0);
    function interval() {
      setTimeout(() => {
        setTime((prevTime) => prevTime + 1); // <-- Change this line!
        console.log("Time Call");
        interval();
        ++soundOnVal;
        if(soundOnVal>20)
        {
          myInput.current.click();
          //RUMessage("ReactToUnityReceiver", "SoundOn", "param test");     
          console.log("Time Call 10!! Sound On!" );

        }
      }, 1000);
    }

    useEffect(() => {
      interval();
      console.log('UnityRender Call in React');   
      //RUMessage("ReactToUnityReceiver", "SoundOn", "param test");     
    }, []);    

  return (
  <div>  
      <button onClick={()=>{
          console.log('SoundOn');
          RUMessage("ReactToUnityReceiver", "SoundOn", "param test");
      }}>S</button>
      <input ref={myInput} />
  </div>
  )
}

const ReactToUnityReceiver = "ReactToUnityReceiver";
export function RUExample(param)
{
    RUMessage("ReactToUnityReceiver", "FrameUpdate", param);
}

export function RUInit(racingCount, progress, eventIndex, lang)
{
  //http://192.168.0.33:8080/api/v1/raceprogress?id=1890
  //http://192.168.0.33:8080/api/v1/racedetail?id=1890
  var useTimeSync = true;  
  var fullurl = GetAddHttpURL()+"/";
  var unitygameserverurl = fullurl;
  var parameters=`?racingCount=${racingCount}&eventIndex=${eventIndex}&servertype=${config.SERVERTYPE}&gameserverurl=${unitygameserverurl}&raceprogress=api/v1/raceprogress&racedetail=api/v1/racedetail&gamedata=api/v1/gamedata&debugLV=${config.DEBUG_LV}&progress=${progress}&useTimeSync=${useTimeSync}&nation=${lang}`;
  

  var testHighQualityTimeSync = false;
  var testTimeGap = -45;
  var mobileTimeDiffMulti = 4.0;
  var timeScaleMin = 2;
  var timeScaleMax = 200;
  var parameters_debug = `testHighQualityTimeSync=${testHighQualityTimeSync}&testTimeGap=${testTimeGap}&mobileTimeDiffMulti=${mobileTimeDiffMulti}&timeScaleMin=${timeScaleMin}&timeScaleMax=${timeScaleMax}`;

  var finalParams = `${parameters}&${parameters_debug}`;
  //console.log("LOGTEST : RUInit");

  RUMessage("ReactToUnityReceiver", "RUInit", finalParams);
}

function RUMessage(gameObjectName, funcName, param)
{    
    //console.log("LOGTEST : _sendMessage");
    _sendMessage(gameObjectName, funcName, param);
}

function URMessage(gameObjectName, funcName, param)
{
    _sendMessage(gameObjectName, funcName, param);
}