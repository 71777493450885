import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

type PDFFile = string | File | null;

export function PdfViewer(props: { pdfPath: string }) {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const [numPages, setNumPages] = useState<number>();
  // const [pageNumber, setPageNumber] = useState<number>(1);
  const [file, setFile] = useState<PDFFile>(props.pdfPath);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    console.log('pdf loaded : ' + numPages);

    function RemoveClass(className: string) {
      const allWithClass = Array.from(document.getElementsByClassName(className));
      console.log(allWithClass);

      allWithClass.forEach(element => {
        element.remove();
      });
    }

    // TextLayer, annotationLayer css가 작동하지 않으면 강제로 없애준다.
    setTimeout(() => {
      // RemoveClass('react-pdf__Page__textContent textLayer');
      // RemoveClass('react-pdf__Page__annotations annotationLayer');
    }, 100);
  }

  useEffect(() => {}, []);

  return (
    <div className="flex flex-row justify-center overflow-x-auto">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from({ length: Number(numPages) }).map((_, i) => (
          <div className="mx-auto">
            <Page pageNumber={i + 1} renderForms={false} renderMode={'canvas'} scale={0.8} width={window.innerWidth} />
            <div className="flex justify-center items-center bg-white/80">
              Page {i + 1} of {numPages}
            </div>
          </div>
        ))}

        {/* <Page 
          pageNumber={pageNumber} 
          renderForms={false}
          renderMode={'canvas'}
          scale={1.0}
          width={window.innerWidth }
        /> */}
        {/* <div className='flex justify-center items-center bg-white/80'>
          Page {pageNumber} of {numPages}
        </div> */}
      </Document>
    </div>
  );
}
