/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/lottomachine_background.glb 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/powerball/background_blue.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(()=>{
    let action = actions["Animation"];    

    if(action !== undefined)
    {
      action.play();     
    }
  })
  return (
    <group ref={group} {...props} dispose={null}>
    <group name="Scene">
      <mesh name="blue_01_02" geometry={nodes.blue_01_02.geometry} material={materials.blue_256_01_02} position={[0.039, 0.945, 0.187]} />
      <mesh name="blue_line" geometry={nodes.blue_line.geometry} material={materials.blue_256} position={[0.039, 0.945, 0.187]} />
      <mesh name="blue_main" geometry={nodes.blue_main.geometry} material={materials.blue_256} position={[0.039, 0.945, 0.187]} />
      <mesh name="blue_01_01" geometry={nodes.blue_01_01.geometry} material={materials.blue_256_01_01} position={[0, 0.945, -0.325]} />
      <mesh name="blue_02_01" geometry={nodes.blue_02_01.geometry} material={materials.blue_256_01_01} position={[0.039, 0.945, 0.187]} />
      <mesh name="blue_02_02" geometry={nodes.blue_02_02.geometry} material={materials.blue_256_01_02} position={[0, 0.945, -0.325]} />
      <mesh name="blue_03_02" geometry={nodes.blue_03_02.geometry} material={materials.blue_256_01_02} position={[0, 0.945, -0.325]} />
      <mesh name="blue_03_01" geometry={nodes.blue_03_01.geometry} material={materials.blue_256_01_01} position={[0.039, 0.945, 0.187]} />
      <mesh name="blue_04_01" geometry={nodes.blue_04_01.geometry} material={materials.blue_256_01_01} position={[0.039, 0.945, 0.187]} />
      <mesh name="blue_04_02" geometry={nodes.blue_04_02.geometry} material={materials.blue_256_01_02} position={[0, 0.945, -0.325]} />
    </group>
  </group>
  )
}

// useGLTF.preload('/powerball/background_blue.glb')
