import Banner from '../../components/tempLanding/banner';
import FirstPage from '../../components/tempLanding/firstPage';
import FaqPage from '../../components/tempLanding/faq';
import GuideSectionApostas from '../../components/tempLanding/guideSectionBet/index';
import GuideSectionRent from '../../components/tempLanding/guideSectionRent/index';
import styles from './landing.module.css';
import BoxIncome from '@/img/landingPage/mobile/boxIncome.png';
import TutorialImage from '@/img/landingPage/tutorialImage.png';

import Arrow from '@/img/landingPage/arrowButton.svg';

import useLang from '@/lib/hook/useLang';
import { useModalStore } from '@/lib/custom-lib/modal';
import Notice from '../../components/Notice';

function Landing() {
  const { lngT } = useLang('landingPage:guideSection');
  const { openModal } = useModalStore();

  const handleClickGameVideo = () => {
    openModal(<VideoModal />, false);
  };

  return (
    <div className={styles.container}>
      <Notice />
      <main className={styles.main}>
        <FirstPage />
        <Banner />
        {/* video banner */}
        <div
          className="w-100 flex justify-center items-center px-8 p-[64px]"
          style={{
            background: 'linear-gradient(270.12deg, #3E095F 0.13%, #1E0E3F 37.45%, #310C53 81.63%, #46096A 99.93%)',
          }}
        >
          <div className="flex flex-row justify-center items-center rounded-2xl px-4 py-4 gap-10 w-[1000px] bg-gradient-to-r from-indigo-700 to-violet-600">
            <img className="max-[1024px]:w-[400px] w-[450px]" src={TutorialImage} alt={''} />
            <div className="flex flex-col items-start justify-between gap-3">
              <div className="flex flex-col space-y-2">
                <h3 className="text-2xl font-semibold text-[#F8DB4C]">{lngT('videoBanner:title')}</h3>
                <p className="text-lg">{lngT('videoBanner:phrase')}</p>
              </div>
              <div
                onClick={handleClickGameVideo}
                className="w-[100%] h-[63px] rounded-2xl text-white flex flex-row items-center justify-center gap-[14px] bg-[#4116AE] uppercase min-[1440px]:text-xl font-semibold cursor-pointer hover:bg-violet-900 transition-all"
              >
                <img src={Arrow} alt="Arrow" /> {lngT('videoBanner:button')}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: 'linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%)',
          }}
        >
          <div className="flex justify-evenly mx-[320px] space-x-[120px] py-[100px] pl-[80px] max-[1280px]:pl-0 max-[1280px]:mx-[40px] max-[1280px]:space-x-10 max-[1024px]:mx-2">
            <GuideSectionApostas />
            <GuideSectionRent />
          </div>

          <div
            className="w-100 flex justify-center items-center px-8"
            style={{
              background: 'linear-gradient(270.12deg, #3E095F 0.13%, #1E0E3F 37.45%, #310C53 81.63%, #46096A 99.93%)',
            }}
          >
            <div
              className="flex flex-row items-center rounded-2xl px-10 py-4 gap-10 mb-[80px] w-[1000px]"
              style={{
                background:
                  'linear-gradient(90deg, #5426B1 2.37%, #6D1DB1 36.28%, #5629B3 99.83%), linear-gradient(90deg, #5426B1 2.37%, #6D1DB1 36.28%, #5629B3 99.83%)',
              }}
            >
              <img className="max-[1024px]:w-[250px] w-[300px]" src={BoxIncome} alt={''} />
              <div className="flex flex-col space-y-2">
                <h3 className="text-[18px] font-semibold uppercase text-[#FFD911]">{lngT('point:title')}</h3>
                <p className="text-lg">{lngT('point:phrase')}</p>
              </div>
            </div>
          </div>
        </div>
        <Banner />
        <FaqPage />
        <Banner />
        {/* <Footer /> */}
      </main>
    </div>
  );
}

export default Landing;

const VideoModal = () => {
  const { closeModal } = useModalStore();

  const handleCloseVideoMdoal = e => {
    const clickedElement = e.target;
    console.log('clickedElement', clickedElement);
    if (!clickedElement.classList.contains('videoModal')) {
      closeModal();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white z-[99]"
      onClick={handleCloseVideoMdoal}
    >
      <div className="relative flex flex-col w-[79%] h-[70%] max-[1440px]:w-[768px] max-[1440px]:h-[450px] bg-black rounded-lg videoModal">
        <button className="absolute -top-10 right-2 text-white" onClick={closeModal}>
          <img className="w-11 h-11" src="../../images/icons/close.svg" />
        </button>
        <iframe
          src="https://www.youtube.com/embed/b5_4Z2ChriA?autoplay=1"
          title="YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="absolute top-0 left-0 w-[100%] h-[100%] "
        ></iframe>
      </div>
    </div>
  );
};
