import React, { useEffect, useRef } from 'react';
import { SpinePlayer } from '@esotericsoftware/spine-player';
// import { SpineThreeJS, AssetManager, AtlasAttachmentLoader, SkeletonJson, SkeletonMesh} from "@esotericsoftware/spine-threejs"
// import * as spine from "@esotericsoftware/spine-threejs"

var spinePlayer;
export const SpineRenderCommon = props => {
  const refSpine = useRef(null);
  const path = props.path; //'horsespine';
  const file = props.file; //'result'
  const ani = props.aniName;

  useEffect(() => {
    spinePlayer = new SpinePlayer('player-container', {
      jsonUrl: `/${path}/${file}.json`,
      atlasUrl: `/${path}/${file}.atlas`,
      animation: ani,
      backgroundColor: '#00000000',
      alpha: true,
      showControls: false,
      showLoading: false,
    });
  }, []);

  let percent = '130%';
  return (
    <div
      id="player-container"
      ref={refSpine}
      // style={{
      //   left: '0px',
      //   top: '0px',
      //   width: '100%',
      //   height: 'auto',
      //   zIndex: '999',
      //   position: 'absolute',
      // }}
    ></div>
  );
};

let spinePlayers = {}

function ExistsspinePlayers(element)
{
  // console.log(element);
  return spinePlayers.includes(element)
}

function IsKeyInObject(obj, key) {
  return Object.keys(obj).some(v => v == key);
  
}

export const SpineRenderCommonByID = props => {
  const refSpine = useRef(null);
  const path = props.path; //'horsespine';
  const file = props.file; //'result'
  const ani = props.aniName;
  const loop = props.loop !== undefined ? props.loop : true;
  useEffect(() => {
    if(!IsKeyInObject(props.id))
    {
      // spinePlayers.push(props.id);
      // spinePlayers[props.id] 
      
      if(file === 'freespin')
        console.log(`ccc freespin init - ani:${ani} loop:${loop}`);
      else if(file.includes('fx_line'))
        console.log(`ccc fx_line init - ani:${ani} loop:${loop}`);
      
      spinePlayer = new SpinePlayer(props.id, {
        jsonUrl: `/${path}/${file}.json`,
        atlasUrl: `/${path}/${file}.atlas`,
        animation: ani,
        backgroundColor: '#00000000',
        alpha: true,
        showControls: false,
        showLoading: false,
      });

      spinePlayers[props.id] = spinePlayer;
    }
    // console.log(`spinePlayers length : ${Object.keys(spinePlayers).length}`)
  }, []);

  useEffect(() => {
    const spinePlayerByID = spinePlayers[props.id]
    if(spinePlayerByID.animationState)
    { 
      const curani = spinePlayerByID.animationState.getCurrent(0)?.animation;
      if(curani !== undefined && curani.name === ani || ani === '')
        return;

      if(file === 'freespin')
        console.log(`ccc freespin curani:${curani.name} ani:${ani} loop:${loop}`);
      // else if(file.includes('fx_line'))
      //   console.log(`ccc fx_line curani:${curani.name} ani:${ani} loop:${loop}`);

      spinePlayerByID.animationState.setAnimation(0, ani, loop)
    }
    // console.log(`spinePlayers ani : ${spinePlayerByID.animation}`)
  }, [ani]);

  return (
    <div 
      className={props.className ? props.className : ''}

      id={props.id} 
      ref={refSpine}
    ></div>
  );
};

export const GetSpinePlayer = (spineid)=>
{
  return spinePlayers[spineid];
}