import { makeStyles } from '@mui/styles';

export const useSingleStyles = makeStyles(theme => ({
  numberBoxStyle: {
    width: '80%',
    display: 'grid',
    gridTemplateRows: 'repeat(6, 1fr)',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: 4,
    justifyItems: 'center',
    alignItems: 'center',
    margin: '40px auto',
    '@media all and (max-width: 510px)': {
      gridTemplateRows: 'repeat(4, 1fr)',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: 2,
      width: '100%',
    },
  },
  numberStyle: {
    '&.MuiButton-root': {
      width: '100%',
      minHeight: 80,
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 3%, rgba(255, 255, 255, 0.60) 105.18%), #9B896C',
      backgroundBlendMode: 'soft-light, normal',
      color: 'rgba(0, 0, 0, 0.50)',
      filter: 'brightness(100%)',
      fontSize: '1.75rem',
      borderRadius: 0,
      border: '1px solid #222',
      borderRadius: '8px',
      '&:hover': {
        fontWeight: 'semi-bold',
        background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.40) 3%, rgba(255, 255, 255, 0.60) 105.18%), #9B896C',
        filter: 'brightness(110%)',
      },
    },
  },
  numberActiveStyle: {
    '&.MuiButton-root': {
      background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.20) 0%, rgba(255, 255, 255, 0.20) 105.18%), #EEAA1C',
      borderWidth: 2,
      fontWeight: 'semi-bold',
      color: '#fff',
      boxShadow:
        '5px 5px 10px 0px #000, 4px -3px 10px 0px rgba(3, 3, 3, 0.15) inset, 6px 8px 4px 0px rgba(255, 255, 255, 0.40) inset',
      '&:hover': {
        background: 'linear-gradient(318deg, rgba(0, 0, 0, 0.20) 0%, rgba(255, 255, 255, 0.20) 105.18%), #EEAA1C',
      },
    },
  },
}));
