import tw from 'tailwind-styled-components';

const Layer = tw.p`
  text-sm T:text-base font-normal text-white
`;

type Props = {
  text: string;
};

export default function PlainText({ text }: Props) {
  return <Layer>{text}</Layer>;
}
