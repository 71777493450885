import tw from 'tailwind-styled-components';

import useLang from '../../../../lib/hook/useLang';

import DrawsBtn from '../../../../img/powerball/draws_button.png';
import DrawsBtnL from '../../../../img/powerball/draws_button_L.png';
import DrawsBtnM from '../../../../img/powerball/draws_button_M.png';
import { GetCoinType, Goods, USDPerGoods } from '../../../../components/gameui/goods';

const ButtonBox = tw.div`
  space-y-3 h-fit
  w-[380px] max-[1440px]:w-full
  text-center
`;

const SmallButtonBox = tw.div`
  rounded-2xl
  px-4 pb-3 space-y-6 h-fit
  w-[380px] max-[1440px]:w-full
`;

const BoxText = tw.h2`
  text-white font-bold text-base upppercase
`;

const PointText = tw.h2`
  text-center text-[#FFC801] text-3xl font-bold  max-[594px]:text-xl
`;

const PointUnitText = tw.h2`
  text-center text-white text-xl font-semibold  max-[594px]:text-base
`;

type Props = {
  canBet: boolean;
  onClick: () => void;
};

export default function LotteryTicketIssueButton({ canBet, onClick }: Props) {
  const { lngT } = useLang('powerball');
  return (
    <ButtonBox>
      {/* <BoxText className="pl-6">Betting Points</BoxText> */}
      <SmallButtonBox>
        {/* <div className="flex flex-row items-center justify-center space-x-3">
          <PointText>100</PointText>
          <PointUnitText>Point</PointUnitText>
        </div> */}

        <button
          disabled={!canBet}
          onClick={onClick}
          className="rounded-2xl relative w-[100%] max-[486px]:w-[90%] h-[80px] max-[594px]:h-[70px] overflow-hidden"
        >
          <div className="w-full h-full absolute top-0 left-0 z-10" style={{ background: '#6B13D8' }}></div>
          <img
            className="w-full h-full absolute top-0 left-0 z-[99]"
            src={DrawsBtn}
            srcSet={`${DrawsBtnL} 1440w,${DrawsBtnM} 984w`}
            alt=""
          />
          <div className="w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[99] text-center text-white text-xl font-bold uppercase max-[486px]:text-base">
            <div className="flex flex-row items-center justify-center space-x-2 w-full  max-[594px]:space-x-1">
              <Goods goodsType={GetCoinType()} size={8} />
              <PointText>100</PointText>
              {/* <PointUnitText>{/* Point lngT('lottery:pad:point')}</PointUnitText> */}
              <div className="text-sm font-light flex flex-row">
                <USDPerGoods size={5}/>
              </div>
            </div>
            {/*  Buy Ticket */ lngT('lottery:pad:buyticket')}
          </div>
        </button>
      </SmallButtonBox>
    </ButtonBox>
  );
}
