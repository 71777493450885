import styles from './cta.module.css';
import Arrow from '@/img/landingPage/arrowButton.svg';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

import useLang from '@/lib/hook/useLang';
import { useLocation } from 'react-router-dom';
import * as define from '../../../../../../define'

const CTARentButton = () => {
  const { lngT } = useLang('landingPage:guideSection');
  const { search } = useLocation();
  const navigate = useCustomNavigate();

  return (
    <div className={styles.container}>
      <a href={define.SHOW_METACROSS_ONLY ? `/horse/stake${search}` : ''} className={styles.buttonRent}>
        <img src={Arrow} alt="Make a bet horse" className={styles.arrow} />
        <div>
          <h3 className={styles.title}>{lngT('guideRent:betTitle')}</h3>
          <p className={styles.subtitle}>{lngT('guideRent:betSubTitle')}</p>
        </div>
      </a>
    </div>
  );
};

export default CTARentButton;
