import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 32,
    '& *, & .MuiTypography-root': {
      fontFamily: '"Poppins", sans-serif!important',
    },
  },
  tableStyle: {
    '&.MuiTable-root': {
      // width:'calc(100% - 48px)',
      margin: '0 auto',
      borderRadius: 8,
      overflow: 'hidden',
      '@media (min-width:280px) and (max-width:321px)': {
        minWidth: 'auto',
      },
      '& .MuiTableRow-head': {
        background: '#3C6D9B',
        '& .MuiTableCell-head': {
          color: '#fff',
          fontSize: '1.125rem',
          fontWeight: '500',
          border: '0 none',
          borderLeft: '1px solid rgb(255 255 255 / 20%)',
          '&:first-child': {
            borderLeft: '0 none',
          },
          '@media (min-width:480px) and (max-width:667px)': {
            fontSize: '0.875rem',
          },
          '@media (min-width:280px) and (max-width:479.99px)': {
            fontSize: '0.75rem',
            padding: '4px',
          },
        },
      },
      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          background: '#4E4E4E',
          '&:nth-child(even)': {
            background: '#4E4E4E',
            opacity: '0.8',
          },
          '& .MuiTableCell-body': {
            height: 48,
            padding: '8px 16px',
            color: '#fff',
            fontSize: '1rem',
            border: '0 none',
            borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
            '&:first-child': {
              borderLeft: '0 none',
            },
            '@media (min-width:480px) and (max-width:667px)': {
              fontSize: '0.875rem',
              letterSpacing: '-0.5px',
              padding: '4px 8px',
            },
            '@media (min-width:280px) and (max-width:479.99px)': {
              fontSize: '0.75rem',
              letterSpacing: '-0.5px',
              padding: '2px 6px',
              wordBreak: 'break-word',
            },
          },
          '& th': {
            textTransform: 'uppercase',
          },
        },
      },
    },
  },
  numberStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    fontSize: '0.873rem',
    borderRadius: 50,
    color: '#fff',
    backgroundColor: '#546739',
  },
  buttonStyle: {
    '&.MuiButton-root': {
      width: 180,
      height: 48,
      fontSize: '0.875rem',
      color: '#fff',
      borderRadius: 8,
    },
  },
  outlineGradientStyle: {
    '&.MuiButton-root': {
      backgroundImage:
        'linear-gradient(#271158, #271158), linear-gradient(90deg, rgba(130, 135, 255, 1) 0%, rgba(247, 74, 162, 1) 100%)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'padding-box, border-box',
      border: '2px solid transparent',
    },
  },
  containedStyle: {
    '&.MuiButton-root': {
      background: 'linear-gradient(90deg, rgba(130, 135, 255, 1) 0%, rgba(247, 74, 162, 1) 100%)',
      '&.Mui-disabled': {
        color: '#fff',
        opacity: 0.3,
      },
    },
  },
  outlineStyle: {
    '&.MuiButton-root': {
      color: '#F14EA7',
      border: '2px solid #F14EA7',
    },
  },
  buttonStyle2: {
    '&.MuiButton-root': {
      width: 120,
      height: 48,
      color: '#FFD84D',
      border: '1px solid #FFD84D',
      borderRadius: 8,
      '&.Mui-disabled, &:disabled': {
        color: '#FFD84D',
        opacity: 0.2,
      },
    },
  },
  empty: {
    '&.MuiTypography-root': {
      color: '#fff',
      fontSize: '1.25rem',
      letterSpacing: 7,
      padding: 24,
    },
  },
  paginationStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '30px 0',
    '& button, & .MuiPaginationItem-root': {
      minWidth: 28,
      height: 28,
      fontSize: '0.75rem',
      color: '#fff',
      borderRadius: 8,
    },
    '& li:first-child > button, & li:last-child > button': {},
    '& .MuiPaginationItem-root:hover': {
      backgroundColor: 'rgba(212, 112, 255, 0.1)!important',
    },
    '& button.Mui-selected': {
      backgroundColor: 'rgba(212, 112, 255, 0.5)',
      borderRadius: 8,
    },
    '@media (min-width:370px) and (max-width:440px)': {
      '& button, & .MuiPaginationItem-root': {
        padding: 0,
        margin: 0,
      },
    },
    '@media (min-width:279px) and (max-width:369px)': {
      '& button, & .MuiPaginationItem-root': {
        padding: 0,
        margin: -2,
      },
    },
  },
}));
