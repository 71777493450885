import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import { env } from '@/env';
import { fetcher, formatDateToLocal } from '@/lib/util';
import { useLang } from '@/lib/hook';

import { EventDetailLayout } from '@/page/event/layout';
import { SpinnerLoader } from '@/page/common/component';

export default function EventDetailView() {
  const { isCurrentEn } = useLang();
  const params = useParams();
  const id = params.eventId;
  const { data } = useSWR<ResWithQueryMeta<EventModel>>(`${env.api.host}/event/detail/${id}`, { fetcher });

  return (
    <EventDetailLayout text={`${isCurrentEn ? 'Event' : 'Evento'}. ${id}`}>
      {!data?.data ? (
        <SpinnerLoader className="w-[40px] h-[40px] fill-white" />
      ) : (
        <div className="flex flex-col text-white p-4 gap-y-4">
          <div className="text-sm T:text-2xl p-4 bg-white/10 rounded-md">
            {isCurrentEn ? data.data.title_en : data.data.title_pt}
          </div>
          <div className="text-[10px] T:text-base flex flex-row justify-end">
            {formatDateToLocal(data.data.createdAt)}
          </div>
          <div
            className="bg-white/10 p-4 rounded-md"
            dangerouslySetInnerHTML={{ __html: isCurrentEn ? data.data.content_en : data.data.content_pt }}
          />
        </div>
      )}
    </EventDetailLayout>
  );
}
