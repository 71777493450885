import tw from 'tailwind-styled-components';

import useScaleWidth from '@/page/common/hook/useScaleWidth';
import LotteryBallButton from './LotteryBallButton';

import { ReactComponent as RandomIcon } from '../../../../img/RandomIcon.svg';
import DrawsLine from '../../../../img/powerball/draws_line.png';
import DrawsLineL from '../../../../img/powerball/draws_line_L.png';
import DrawsLineM from '../../../../img/powerball/draws_line_M.png';

const BoxText = tw.h2`
  text-white font-bold text-base upppercase 
  max-[1440px]:text-sm
  max-[400px]:text-xs
`;

const NumberBox = tw.div`
  relative rounded-2xl
  px-4 py-2 space-y-3 w-[380px] h-fit
  max-[1440px]:w-full
`;

const BulletCount = tw.div<{ $isSelected1: boolean }>`
  h-3 w-3 rounded-full text-transparent
  ${({ $isSelected1 }) => ($isSelected1 ? 'bg-yellow-500' : 'bg-gray-200')}
`;

const NumberPadHeader = tw.div`
  flex flex-row justify-between px-2 max-[594px]:px-4 max-[360px]:px-0
`;

const NumberPadHeaderText = tw.div`
  flex flex-row gap-4 items-center w-[200px]
`;

const NumberPadHeaderButton = tw.button`
  flex justify-center items-center
  w-8 h-8
  rounded-2xl
  cursor-pointer disabled:cursor-default
  bg-[#96A9DD] hover:bg-[#96A9DD]/80 disabled:hover:bg-[#96A9DD]
  shadow-[5px_5px_10px_0px_rgba(0,0,255,0.20)]
  max-[594px]:w-7 max-[594px]:h-7
`;

const getBallsNumberArray = (length: number) => Array.from({ length }, (_, index) => index + 1);

const BallSelectorBox = ({
  type,
  title,
  canBet,
  selectedBalls,
  selectBall,
  generateRandomNumber,
}: {
  type: 'ball' | 'powerball';
  title: string;
  canBet: boolean;
  selectedBalls: number[];
  selectBall: (ball: number) => void;
  generateRandomNumber: () => void;
}) => {
  const { scaleWidth } = useScaleWidth();

  return (
    <NumberBox>
      <NumberPadHeader>
        <NumberPadHeaderText>
          <BoxText>{title}</BoxText>
          <div className="space-x-2 flex flex-row">
            {Array.from({ length: type === 'ball' ? 5 : 1 }, (_, index) => index + 1).map((bullet, index) => (
              <BulletCount key={index} $isSelected1={index < selectedBalls.length}>
                {bullet}
              </BulletCount>
            ))}
          </div>
        </NumberPadHeaderText>
        <NumberPadHeaderButton disabled={!canBet} onClick={generateRandomNumber}>
          <RandomIcon />
        </NumberPadHeaderButton>
      </NumberPadHeader>
      <div
        className={`grid ${
          scaleWidth < 580 ? 'grid-cols-5' : scaleWidth < 1426 ? 'grid-cols-10' : scaleWidth > 1425 ? 'grid-cols-5' : ''
        } gap-2 justify-items-center`}
      >
        {getBallsNumberArray(type === 'ball' ? 30 : 10).map((number, index) => (
          <LotteryBallButton
            key={index}
            type={type}
            canBet={canBet}
            isSelected={selectedBalls.includes(number)}
            number={number}
            onClick={() => selectBall(number)}
          />
        ))}
      </div>
      {/* <picture className="w-[90%] max-[1440px]:w-full absolute">
        <source srcSet={DrawsLine} media="(max-width: 500px)" />
        <source srcSet={DrawsLineM} media="(max-width: 984px)" />
        <source srcSet={DrawsLineL} media="(max-width: 1440px)" />
        <source srcSet={DrawsLine} media="(min-width:1441px)" />
        <img className="w-full max-[1440px]:w-full absolute" src={DrawsLine} alt="DrawsLine"></img>
      </picture> */}
    </NumberBox>
  );
};

export default BallSelectorBox;
