import { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';

import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang';
import { debounce } from '../../lib/utils';

import TextInputWrapper from '../TextInputWrapper';
import PrimaryButton from '../PrimaryButton';
import { API_URL } from '@/env';

export default function UserInfoSetting({ email, password, code }) {
  const { lngT } = useLang('auth:signup');
  const navigate = useNavigate();
  const [_, { login, updateUser }] = useUser();
  const [nameUser, setNameUser] = useState('');
  const [userNameError, setUserNameError] = useState(' ');
  const [isLoading, setLoading] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [terms, setTerms] = useState(false);
  const [recaptcha, setRecaptcha] = useState();

  const [recommenderCode, setRecommenderCode] = useState(code);
  const [recommenderId, setRecommenderId] = useState();
  const [isValidCode, setValidCode] = useState();

  const checkUserName = debounce(async nameUser => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/checkUserNameExist`, {
        nameUser,
      });

      if (!data.ok) {
        setUserNameError(data.message);
      }

      if (data.ok) {
        setUserNameError(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const handleChangeUserName = async e => {
    setNameUser(e.target.value);

    checkUserName(e.target.value);
  };

  const handleChangeRecommenderCode = e => {
    setRecommenderCode(e.target.value);
  };

  const handleClickSignup = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, {
        email,
        password,
        nameUser,
        recommenderId,
      });
      if (!data.ok) {
        return toast(data.message, { type: 'error', theme: 'dark' });
      }
      const { accessToken } = data;
      login(accessToken);
      navigate('/');
      toast(lngT('signup:4:toast:4'), { theme: 'dark', type: 'success' });
    } catch (error) {
      toast(lngT('signup:4:toast:5'), { type: 'error', theme: 'dark' });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!recommenderCode) {
      setValidCode();
      setRecommenderId();
      return;
    }

    (async function () {
      const { data } = await axios.post(`${API_URL}/auth/checkValidCode`, { recommenderCode });

      if (data.ok && data.data.code) {
        setValidCode(!!data.data.code);
        setRecommenderId(data.data.code.id);
      } else {
        setValidCode(false);
        setRecommenderId();
      }
    })();
  }, [recommenderCode]);

  return (
    <div>
      <div className="flex flex-col space-y-10">
        <TextInputWrapper
          title={`${lngT('signup:3:user')} ${!userNameError ? lngT('signup:3:verified') : ''}`}
          errorText={userNameError}
        >
          <input
            type="text"
            value={nameUser}
            placeholder={lngT('signup:3:username')}
            autoComplete="off"
            onChange={handleChangeUserName}
          />
        </TextInputWrapper>
        <div className="w-full">
          <div className="text-[13px] text-[#999999] pb-[10px]">{`${lngT('signup:6:title')} ${
            isValidCode === false ? '(invalid code)' : isValidCode === true ? '(valid code)' : ''
          }`}</div>
          <input
            className={`w-full text-[16px] text-[#999999] font-semibold placeholder:font-light placeholder:text-[#999999] bg-white border-b-2 border-[#999999] ${
              isValidCode === false ? 'border-red-400' : isValidCode === true ? 'border-[#8E00CB]' : 'border-[#999999]'
            } pb-2 px-2`}
            type="text"
            value={recommenderCode}
            placeholder={lngT('signup:6:title')}
            autoComplete="off"
            onChange={handleChangeRecommenderCode}
          />
        </div>
      </div>
      <div className="pt-[40px]">
        <div className="flex items-center mb-4">
          <div className="relative flex justify-center items-center">
            <input
              id="privacy"
              type="checkbox"
              className="peer w-4 h-4 text-blue-600 bg-gray-300 border-gray-400 rounded focus:ring-blue-500 focus:ring-2 checked:ring-blue-500 checked:ring-2"
              onChange={e => setPrivacy(e.target.checked)}
            />
            <label
              htmlFor="privacy"
              className="absolute hidden peer-checked:block bg-blue-500 w-[14px] h-[14px] rounded"
            />
          </div>

          <label htmlFor="privacy" className="ml-2 text-sm font-medium text-[#999999]">
            {lngT('signup:5:privacy:agree')}
            <a href="/privacy" className="ml-1 text-blue-600 dark:text-blue-500 hover:underline" target="_blank">
              {lngT('signup:5:privacy:privacy')}
            </a>
          </label>
        </div>
        <div className="flex items-center mb-4">
          <div className="relative flex justify-center items-center">
            <input
              id="terms"
              type="checkbox"
              className="peer w-4 h-4 text-blue-600 bg-gray-300 border-gray-400 rounded focus:ring-blue-500 focus:ring-2 checked:ring-blue-500 checked:ring-2"
              onChange={e => setTerms(e.target.checked)}
            />
            <label
              htmlFor="terms"
              className="absolute hidden peer-checked:block bg-blue-500 w-[14px] h-[14px] rounded"
            />
          </div>
          <label htmlFor="terms" className="ml-2 text-sm font-medium text-[#999999]">
            {lngT('signup:5:terms:agree')}
            <a href="/terms" className="ml-1 text-blue-600 dark:text-blue-500 hover:underline" target="_blank">
              {lngT('signup:5:terms:terms')}
            </a>
          </label>
        </div>
        {/* {!userNameError && privacy && terms && isValidCode !== false && (
          <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={e => setRecaptcha(!!e)} />
        )} */}
      </div>
      <PrimaryButton
        type="button"
        title={lngT('button:3')}
        isLoading={isLoading}
        error={userNameError || !privacy || !terms || isValidCode === false}
        className="mt-[40px]"
        onClick={handleClickSignup}
      />
    </div>
  );
}
