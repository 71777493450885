import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import * as THREE from 'three';
import { InitSpine, IsLoadingCompleteSpine, LoadSpine, Animate } from "./spineManager";
import { 
  SpinState,   
  ITEM_SIZE,   
  GetFreeRollState,
  FreeRollState,
} from '../../util/reeldefine';

// 7 set
// const modelKinds = 7;
// const setCounts = 5;
const normalshapes = [0,1,2,3,4,5,6];
const freeshapes = [7,8,9,10];
let spineinfos = []
let skeletonMesh=[{}];
let scene;
let freespinskeletonMesh;

let gstoped;
let needreload = false;

export function PodiumSpine(props){
    let lastFrameTime = GetAniSpeed();
    const state = useThree();    
    const aniStep = props.reelGameAniStep;
    // const [freeroll, setfreeroll]=useState(props.freeroll);
    const [reelGameServerInfo,setreelGameServerInfo]=useState(props.reelGameServerInfo);
    const [hitlinecount, sethitlinecount] = useState(0);
    
    useEffect(()=>{
      // gl.getContext().getExtension('WEBGL_lose_context').loseContext();
      // gl.getContext().getExtension('WEBGL_lose_context').restoreContext();
      if(props.reelGameResourceReady)
      {
        props.SetReelGameResourceReady(false);
        needreload = true;
        window.location.reload();
        return;
      }

      for(let i = 0; i < 15; ++i)
      {
        spineinfos.push(
          {assetName:'slotmodels/item_wild', aniName:'wild', shape:0},
          {assetName:'slotmodels/item_carrot', aniName:'carrot', shape:1},
          {assetName:'slotmodels/item_grape', aniName:'grape', shape:2},
          {assetName:'slotmodels/item_flag', aniName:'flag', shape:3},           
          {assetName:'slotmodels/item_clover', aniName:'clover', shape:4}, 
          {assetName:'slotmodels/item_u', aniName:'u', shape:5},
          {assetName:'slotmodels/item_trophy', aniName:'trophy', shape:6},

          {assetName:'slotmodels/item_h_b', aniName:'h_b', shape:7},
          {assetName:'slotmodels/item_h_r', aniName:'h_r', shape:8},
          {assetName:'slotmodels/item_h_v', aniName:'h_v', shape:9},
          {assetName:'slotmodels/item_h_w', aniName:'h_w', shape:10},          
        )
      }

      init();
    },[])
    
    useEffect(()=>{
      if(props.spinState === SpinState.NONE){
        // AnimateEffectfreespin(0);
      }
      else if(props.spinState === SpinState.SPINNING){
        AnimateEffect(false);
        AnimateEffectfreespin(0);
        gstoped = false;
      }
      else if(props.spinState === SpinState.REWARD){        

        // reel animation frameindex 0으로 돌린다.
        props.SetReelGameAniStep(0);

        // setfreeroll(props.freeroll);

        // 현재 화면에 노출 된 아이템 중 이펙트 true인 것 체크해서 effect animation 출력한다                
        AnimateEffect(true, props.resultSlot);

        function CallFindResult(element) {
          if (element === 0)
              return true;
        }
        const findresult = props.resultSlot?.filter(CallFindResult);
        if (findresult && findresult.length > 0 && GetFreeRollState(props.reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START) {            
            AnimateEffectfreespin(findresult.length);
        }

        

        gstoped = true;
      }      
    },[props.spinState])

    // useEffect(()=>{
    //   // console.log(`freerolllog :  + ${freeroll.count}/${freeroll.size} freeroll:${IsFreeRoll()}`);
    //   if(props.spinState === SpinState.NONE)
    //   {
    //     setfreeroll(props.freeroll);
    //   }
    // },[props.freeroll])

    useEffect(()=>{
      
      setreelGameServerInfo(props.reelGameServerInfo);
      console.log('reelGameServerInfo : ' + JSON.stringify(reelGameServerInfo));
    },[props.reelGameServerInfo])

    useEffect(()=>{
      // const frameLengths = 6;
      if(needreload)
        return;

      if(skeletonMesh.length < spineinfos.length)
        return;

      const idx_In  = aniStep<=5 ? ((aniStep===0? 1 : 6-aniStep)) : 5+6-aniStep;
      const idx_Out = idx_In;
      // console.log('idx_In : ' + idx_In);
      
      // debugger;
      let serverResults;
      if(aniStep>5)
        serverResults = props.serverResult[idx_In-1];

      console.log('tlot-' + JSON.stringify (serverResults));

      // console.log(`serverResults : ${serverResults}`); 
      
      ReleaseLineModels(idx_Out);
      SetLineModels(idx_In, serverResults);
    },[aniStep])
    
    function init() {
      // setfreeroll(props.freeroll);
      scene = state.scene;

      for(let i = 0; i < spineinfos.length; ++i)
      {
        InitSpine(spineinfos[i].assetName, spineinfos[i].aniName);
      }
      // InitSpine('2d/spineboy/spineboy', 'aim');
      // InitSpine('2d/fx_roll', 'roll');
      InitSpine('2d/freespin', 'x0_ani')

      requestAnimationFrame(load);      
    }

    function load(name, scale) {
      if (IsLoadingCompleteSpine() && reelGameServerInfo.userinit)
      {
        // console.log('freeroll.size : ' + freeroll.size);
        for(let i = 0; i < spineinfos.length; ++i)
        {
          // ghost
          // skeletonMesh[i] = {used : false , data : LoadSpine(i, 0.0001,{x:0,y:0.0,z:0.0001})};

          // friut
          skeletonMesh[i] = {used : false , aniName : spineinfos[i].aniName, data : LoadSpine(i, ITEM_SIZE,{x:0,y:0.0,z:0.0001}), shape : spineinfos[i].shape};
        }        
        freespinskeletonMesh = LoadSpine(spineinfos.length, 0.006,{x:0,y:-0.25,z:0.0002});
        freespinskeletonMesh.state.Complete = ()=>{
          console.log('freespinskeletonMesh ani end');
        }
        //GetSpineRoot().children[5].add(btnskeletonMesh); 
        scene.children[6].add(freespinskeletonMesh);
        
        SetLineModels(1);
        SetLineModels(2);
        SetLineModels(3);
        SetLineModels(4);
        SetLineModels(5);

        requestAnimationFrame(render);

        props.SetReelGameResourceReady(true);
      } 
      else
      { 
        requestAnimationFrame(load);            
      }

      
    }

    function GetSkeltonMesh(serverResult)
    {      
      function FilterShapeByRollType(shape)
      {
        //return reelGameServerInfo.freeroll.size - reelGameServerInfo.freeroll.count > 0?
        // console.log('FREEROLL : ' + GetFreeRollState(reelGameServerInfo.freeroll));
        return (
          GetFreeRollState(reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_PLAYING || 
          GetFreeRollState(reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_END ||
          (GetFreeRollState(reelGameServerInfo.freeroll) === FreeRollState.FREEROLL_START && props.spinState === SpinState.NONE )
          ) ?
        freeshapes.includes(shape) :
        normalshapes.includes(shape);
      }

      const useAbleskeletonMeshs = serverResult === undefined ?
      skeletonMesh.filter((obj)=>obj.used===false && FilterShapeByRollType(obj.shape)) : 
      skeletonMesh.filter((obj)=>obj.used===false && obj.shape===serverResult);

       

      const count = useAbleskeletonMeshs.length;
      const randIndex =  Math.floor (Math.random() * count);
      // console.log('randIndex : ' + randIndex);

      // console.log(`serverResult : serverResult:${serverResult} count:${count}`);
      return useAbleskeletonMeshs[randIndex];
    }

    function SetLineModel(hNumber, wNumber, serverResult)
    {
      let index;      
      let addskeletonMesh;

      index = GetIndex(`w0${wNumber}h0${hNumber}`);
      addskeletonMesh = GetSkeltonMesh(serverResult);
      
      if(addskeletonMesh)
      {        
        addskeletonMesh.used = true;
        GetSpineRoot().children[index].add(addskeletonMesh.data);
      }
    }

    function SetLineModels(hNumber, serverResults)
    {
      // console.log('SetLineModels : ' + hNumber);
      for(let wNumber = 1; wNumber <= 3; ++wNumber)
      {        
        SetLineModel(hNumber, wNumber, serverResults !== undefined ? serverResults[wNumber-1] : undefined);
      }
    }    

    function ReleaseLineModel(hNumber, wNumber)
    {
      let index;      
      let addskeletonMesh;
      let addskeletonMeshPack;

      index = GetIndex(`w0${wNumber}h0${hNumber}`);
      addskeletonMesh = GetSpineRoot().children[index].children[0];
      GetSpineRoot().children[index].remove(addskeletonMesh);
      addskeletonMeshPack = skeletonMesh.find((obj)=>obj.data===addskeletonMesh);

      if(addskeletonMeshPack)
      {
        addskeletonMeshPack.used = false;
        // debugger;
      }      
    }

    function ReleaseLineModels(hNumber)
    {
      for(let wNumber = 1; wNumber <= 3; ++wNumber)
      {        
        ReleaseLineModel(hNumber, wNumber);
      }
    }

    function AnimateEffectfreespin(linecount)
    {
      // let findskeletonMesh = GetSpineRoot().children[index].children[0];  
      console.log('AnimateEffectfreespin : ' + linecount);
      freespinskeletonMesh.state.setAnimation(1, linecount > 0 ? `x${linecount * 5}_ani` : `x${0}_ani`, false);
    }

    function AnimateEffect(isPlay, resultSlot)
    {
      // const useAbleskeletonMeshs = skeletonMesh.filter((obj)=>obj.used===true);
      let showeffectarrs = [
        [false, false, false],  // dosent show area
        [false, false, false],
        [false, false, false],
        [false, false, false],
        [false, false, false],  // dosent show area
      ]
      // resultSlot[0] // middle 3-1, 3-2, 3-3
      // resultSlot[1] // top    2-1, 2-2, 2-3
      // resultSlot[2] // bottom 4-1, 4-2, 4-3
      // resultSlot[3] // ltop to rbottom  2-1, 3-2, 4-3
      // resultSlot[4] // rtop to lbottom  2-3, 3-2, 4-1

      // resultSlot[0] // middle 2-0, 2-1, 2-2
      // resultSlot[1] // top    1-0, 1-1, 1-2
      // resultSlot[2] // bottom 3-0, 3-1, 3-2
      // resultSlot[3] // ltop to rbottom  1-0, 2-1, 3-2
      // resultSlot[4] // rtop to lbottom  1-2, 2-1, 3-0
      
      if(isPlay)
      {
        const wMax = 3;
        if(resultSlot[0] !== -1)
        {
          let showeffectarr = showeffectarrs[2];
          for(let i = 0; i < showeffectarr.length; ++i)
            showeffectarr[i] = true;        
        }
        if(resultSlot[1] !== -1)
        {
          let showeffectarr = showeffectarrs[1];
          for(let i = 0; i < showeffectarr.length; ++i)
            showeffectarr[i] = true;        
        }
        if(resultSlot[2] !== -1)
        {
          let showeffectarr = showeffectarrs[3];
          for(let i = 0; i < showeffectarr.length; ++i)
            showeffectarr[i] = true;        
        }
        if(resultSlot[3] !== -1)
        {
          for(let i = 0; i < wMax; ++i)
            showeffectarrs[i+1][i] = true;
        }
        if(resultSlot[4] !== -1)
        {
          for(let i = 0; i < wMax; ++i)
            showeffectarrs[i+1][wMax-i-1] = true;
        }
      }

      console.log('showeffectarrs : ' + showeffectarrs);

      for(let hNumber = 2; hNumber <= 4; ++hNumber)
      {
        for(let wNumber = 1; wNumber <= 3; ++wNumber)
        {
          const showeffect = isPlay ? showeffectarrs[hNumber-1][wNumber-1] : false;

          const index = GetIndex(`w0${wNumber}h0${hNumber}`);
          let findskeletonMesh = GetSpineRoot().children[index].children[0];  
          findskeletonMesh = skeletonMesh.find((obj)=>obj.data===findskeletonMesh);
          Animate(findskeletonMesh.data, findskeletonMesh.aniName, showeffect);
        }
      }

      if(isPlay)
      {
        // const effectMesh = GetSpinePlayer("player-containerslotbg");
        // effectMesh.animationState.setAnimation(0, 'fx_line2', isPlay);        
      }
    }

    function GetAniSpeed()
    {
      return Date.now() / 1000;
    }

    const GetIndex=(name)=>
    {      
      for(let i = 0; i < GetSpineRoot().children.length; ++i)
      {
        let child = GetSpineRoot().children[i];
        if(child.name === name)
          return i;
      }
    }

    const GetSpineRoot=()=>{
      return scene.children[2].children[0];
    }    

    function render() {            

      // Render full screen quad with generated texture
      let now = GetAniSpeed();
      let delta = now - lastFrameTime;
      lastFrameTime = now;

      for(let i = 0; i < skeletonMesh.length; ++i)
      {
        if(gstoped)
        {
          if(skeletonMesh[i].used)
          {
            skeletonMesh[i].data.update(delta);
          }
        }
        else
        {
          if(skeletonMesh[i])
          {
            skeletonMesh[i].data.update(delta);
          }
        }
      }

      freespinskeletonMesh.update(delta);
      if(freespinskeletonMesh.state.getCurrent(1).animationLast >= freespinskeletonMesh.state.getCurrent(1).animationEnd || 
        (freespinskeletonMesh.state.getCurrent(1).animationEnd === 0)
      )
      {
        // if(props.enterfreespinanimationplaying)
        
          // setenterfreespinanimationplaying(false);
          props.Setenterfreespinanimationplaying(false);
          // console.log(`ANITEST enterfreespinanimationplaying : ${btnskeletonMesh.state.getCurrent(1).animationEnd} / ${btnskeletonMesh.state.getCurrent(1).animationLast}` ) 
        
      }
      else
      {
        // if(!props.enterfreespinanimationplaying)
        
          // setenterfreespinanimationplaying(true);
          props.Setenterfreespinanimationplaying(true);
          // console.log(`ANITEST enterfreespinanimationplaying : ${btnskeletonMesh.state.getCurrent(1).animationEnd} / ${btnskeletonMesh.state.getCurrent(1).animationLast}` ) 
        
      }
      

      // requestAnimationFrame(render);  
      // setTimeout(() => {
      //   requestAnimationFrame(render);
      // }, gtest ? 1000/30 : 1000/30);

      requestAnimationFrame(render);
    }

    return (
        <group>
        </group>
    )
}