import { useEffect, useState } from 'react';
import { useCoindropStore } from '@/store/coindrop';
import axios from 'axios';
import ReactCardFlip from 'react-card-flip';
import i18next from 'i18next';
import { API_URL } from '@/env';

import CloseButton from '@/img/CloseIcon.svg';
import useLang from '@/lib/hook/useLang';
import { SpineRender } from '../../util/SpineRender';
import * as config from '../../config'

export default function CoinDropPopup() {
  const { lngT } = useLang('etcs');
  const { closeGamePopup } = useCoindropStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonOpen, setIsButtonOpen] = useState(false);
  const [isCanDropCoin, setIsCanDropCoin] = useState(false);
  const [cards, setCards] = useState([
    { id: 1, isFlipped: false, isDisabled: false },
    { id: 2, isFlipped: false, isDisabled: false },
    { id: 3, isFlipped: false, isDisabled: false },
    { id: 4, isFlipped: false, isDisabled: false },
    { id: 5, isFlipped: false, isDisabled: false },
  ]);

  const imgfolder = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'racing' : 'powerball';
  const BgImg = `/images/cards/${imgfolder}/card_base.png`;

  const handleCardClick = async (card: any) => {
    try {
      const { data } = await axios.post<Res<{ ok: boolean }>>(
        `${process.env.REACT_APP_API_URL}/coindrop`,
        {},
        {
          withCredentials: true,
        }
      );

      if (data.data?.ok) {
        setIsSuccess(true);
        setTimeout(() => {
          setCards(prevCards =>
            prevCards.map(prevCard =>
              prevCard.id === card.id
                ? { ...prevCard, isFlipped: true, isDisabled: false }
                : { ...prevCard, isFlipped: false, isDisabled: true }
            )
          );
        }, 500);
      }
    } catch (error) {
    } finally {
    }
  };

  const handleClickCloseModal = (e: any) => {
    const clickedElement = e.target;

    if (!clickedElement.classList.contains('modal-coin-drop')) {
      setIsModalOpen(false);
    }
    if (!clickedElement.classList.contains('modal-card-game')) {
      isButtonOpen && closeGamePopup();
    }
  };

  useEffect(() => {
    cards.forEach((card, index) => {
      if (card.isFlipped === true) {
        if (isSuccess === true) {
          //  <CoinDropMessage />;
          setTimeout(() => {
            setShowCongratulations(true);
            setIsModalOpen(true);
          }, 500);
        } else {
          setTimeout(() => {
            setIsModalOpen(true);
          }, 500);
        }
      }
    });
  }, [cards, isSuccess]);

  useEffect(() => {
    if (isModalOpen === true) {
      setTimeout(() => {
        setIsButtonOpen(true);
      }, 2000);
    }
  }, [isModalOpen]);

  useEffect(() => {
    (async function () {
      try {
        const { data } = await axios.get<Res<{ ok: boolean; canCoinDrop: boolean }>>(
          `${API_URL}/coindrop/canCoinDrop`,
          { withCredentials: true }
        );
        if (data) {
          setIsCanDropCoin(data.data?.canCoinDrop ?? false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-black/50 text-white z-[99]"
      onClick={handleClickCloseModal}
    >
      <div
        className="relative flex flex-col items-center justify-center w-full max-[386px]:h-[340px] max-[500px]:h-[400px] T:w-[700px] h-[475px] D:w-[1000px] D:h-[680px] LD:w-[1100px] LD:h-[747px] rounded-xl mx-10 modal-card-game"
        // style={{ background: 'linear-gradient(248deg, #1C012E 0%, #301988 100%)' }}
      >
        <img className="absolute w-full h-full top-0 object-fit" src={BgImg} />
        <div className="absolute -top-10 right-4" onClick={closeGamePopup}>
          <img className="w-12 h-12" src={CloseButton} />
        </div>
        <h2 className="absolute top-11 max-LD:top-8 max-D:top-6 text-2xl max-[386px]:text-lg max-[500px]:top-4 T:text-[24px] D:text-[34px] font-normal font-oswald text-center">
          {lngT('PointCardGame:title')}
        </h2>
        {/* <SpineRender></SpineRender> */}
        {isCanDropCoin ? (
          <div className="absolute top-1/2  max-LD:top-[52%] max-[500px]:top-[48%] max-T:top-[49%] -translate-y-[65%] max-T:space-y-[55px] space-y-[50px] LD:space-y-[88px] flex flex-col items-center justify-center">
            <div className="text-yellow-300 text-center">
              <p className="font-medium D:text-xl max-[406px]:text-base max-[386px]:text-sm">
                {lngT('PointCardGame:warning1')}
              </p>
              <p className="font-light D:text-lg max-[406px]:text-sm max-[386px]:text-sm">
                {lngT('PointCardGame:warning2')}
              </p>
            </div>
            {/* <div className="flex gap-3 flex-wrap max-T:grid-row-3 mx-4 items-center justify-center max-[498px]:mx-[64px] max-[406px]:mx-[12px]"> */}
            <div className="flex gap-2 mx-4 items-center justify-center">
              {cards.map((card, index) => (
                <div key={index} className="relative">
                  <CardItems
                    card={card}
                    handleCardClick={handleCardClick}
                    isFlipped={card.isFlipped}
                    disabled={card.isDisabled}
                    isSuccess={isSuccess}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="absolute top-1/2 max-LD:top-[52%] max-[500px]:top-[48%] max-T:top-[49%] -translate-y-[65%] max-T:space-y-[50px] space-y-[50px] LD:space-y-[88px] flex flex-col items-center justify-center">
            <div className="text-fuchsia-300 text-center w-[300px] T:w-full">
              <p className="font-medium D:text-xl max-[406px]:text-base max-[386px]:text-sm">
                {lngT('PointCardGame:Info:title')}
              </p>
            </div>
            <div className="relative">
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-center text-xs M:text-sm T:text-base w-[260px] M:w-[300px] T:w-[400px] ">
                <p className="">{lngT('PointCardGame:Info:content1')}</p>
                <p>{/*If you meet this condition*/ lngT('PointCardGame:Info:content2')}</p>
              </div>
              <div className="flex gap-2 mx-4 items-center justify-center opacity-20">
                {cards.map((card, index) => (
                  <div key={index} className="relative">
                    <CardItems
                      card={card}
                      handleCardClick={handleCardClick}
                      isFlipped={card.isFlipped}
                      disabled={card.isDisabled}
                      isSuccess={isSuccess}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {isCanDropCoin ? (
          <div className="absolute bottom-[100px] flex flex-row items-center D:gap-8 gap-6 max-[386px]:bottom-[40px] max-D:bottom-[50px] D:bottom-[80px]">
            {isButtonOpen && (
              <button
                className="bg-indigo-800  D:w-[160px] D:h-[48px] T:w-[128px] T:h-[40px] w-[100px] h-[36px] max-T:text-xs  rounded-lg hover:brightness-110 transition-all"
                style={{ background: 'linear-gradient(265deg, #4A1A97 0%, #3F00C5 100%)' }}
                onClick={closeGamePopup}
              >
                {/*Confirmar!*/ lngT('common:confirm')}
              </button>
            )}
          </div>
        ) : (
          <div className="absolute bottom-[100px] flex flex-row items-center D:gap-8 gap-6 max-[386px]:bottom-[40px] max-D:bottom-[50px] D:bottom-[80px]">
            <button
              className="bg-indigo-800  D:w-[160px] D:h-[48px] T:w-[128px] T:h-[40px] w-[100px] h-[36px] max-T:text-xs  rounded-lg hover:brightness-110 transition-all"
              style={{ background: 'linear-gradient(265deg, #4A1A97 0%, #3F00C5 100%)' }}
              onClick={closeGamePopup}
            >
              {/*Confirmar!*/ lngT('common:confirm')}
            </button>
          </div>
        )}
        {isModalOpen && (
          <SpineRender
            filepath={Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'horsespine' : 'powerball'}
            aniName={`result_${showCongratulations ? 50 : 0}_${i18next.language}`}
            success={showCongratulations}
            // aniName = {`result_${0}_${i18next.language}`}
            // success = {false}
          ></SpineRender>
        )}
      </div>
    </div>
  );
}

const CardItems = ({ card, handleCardClick, isFlipped, disabled, isSuccess }: any) => {

  const imgfolder = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'racing' : 'powerball';  
  const cardsImg = [
    { src: `/images/cards/${imgfolder}/card_front_0.png` },
    { src: `/images/cards/${imgfolder}/card_front_50.png` },
    { src: `/images/cards/${imgfolder}/card_back.png` },
  ];

  const handleClick = () => {
    if (!disabled) {
      handleCardClick(card);
    }
  };

  return (
    // <div>
    //   <div className="flex gap-3 flex-wrap max-T:grid-row-3 mx-4 items-center justify-center max-[500px]:mx-[64px] max-[400px]:mx-[12px]">
    //     <div className="relative">

    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      {/* back */}
      <div
        className={`${
          disabled ? 'brightness-50' : 'brightness-100 hover:brightness-125 cursor-pointer'
        } rounded-2xl max-[386px]:w-[50px] max-[386px]:h-[78px] max-[500px]:w-[60px] max-[500px]:h-[94px] max-T:w-[80px] max-T:h-[125px] T:w-[100px] T:h-[156px] D:w-[140px] D:h-[220px]`}
        onClick={handleClick}
      >
        <img className=" w-full h-full object-cover" src={cardsImg[2].src} alt=''/>
      </div>
      {/* front */}
      <div
        className={`${
          disabled ? '' : 'scale-105'
        } rounded-2xl max-[386px]:w-[50px] max-[386px]:h-[78px] max-[500px]:w-[60px] max-[500px]:h-[94px] max-T:w-[80px] max-T:h-[125px] T:w-[100px] T:h-[156px] D:w-[140px] D:h-[220px]`}
        onClick={handleClick}
      >
        {/* <img className=" w-full h-full object-cover" src={`${isSuccess ? CardFront_50 : CardFront_0}`} /> */}
        <img className=" w-full h-full object-cover" src={`${isSuccess && cardsImg[1].src}`} alt=''/>
      </div>
    </ReactCardFlip>
    //     </div>
    //   </div>
    // </div>
  );
};
