import React, { Component, useState, useEffect, useMemo } from 'react';
import {
  Box,
  Select,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useOddEvenStyles } from './styles/OddEvenStyles';
import { useSingleStyles } from './styles/SingleStyles';
import { useShowStyles } from './styles/ShowStyles';

import useLang from '../../lib/hook/useLang'; //'../lib/hook/useLang'

import clsx from 'clsx';
import Rate from './Rate';

import { makeStyles } from '@mui/styles';
import * as define from '../../define';
import useSWR from 'swr';
import { GoodsType } from '../../define';
import { Goods, GetFixedBalanceStr, GetBalance, USDPerGoods } from '../../components/gameui/goods';
import { useDispatch } from 'react-redux';

var { webSocketIO } = require('../../socketClient');

export const useStylesRate = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justify: 'center',
    alignItems: 'center',
    margin: '12px 0',
    '& p': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
  titleStyle: {
    color: '#fff',
  },
  pStyle: {
    color: 'rgba(255,255,255, 0.4)',
    padding: '0 20px',
  },
  rateStyle: {
    color: '#EAD40C',
  },
}));

var activelistBettingValue = false;
var underoverInfo = [];
var isSMGActive = false;
function isOnButton(selectOddEven, indexButton) {
  const bettingButton = [
    [],
    [1],
    [2],
    [3],
    [4],
    [1, 3],
    [1, 4],
    [2, 3],
    [2, 4],
    [1, 5],
    [1, 6],
    [1, 7],
    [2, 5],
    [2, 6],
    [2, 7],
  ];

  for (var n = 0; n < bettingButton[selectOddEven].length; n++) {
    if (bettingButton[selectOddEven][n] === indexButton) {
      return true;
    }
  }
  return false;
}

function isOnDigit(selectOddEven, digit) {
  const bettingButton = [
    [],
    [1, 3, 5, 7, 9, 11],
    [2, 4, 6, 8, 10, 12],
    [1, 2, 3, 4, 5, 6],
    [7, 8, 9, 10, 11, 12],
    [1, 3, 5],
    [7, 9, 11],
    [2, 4, 6],
    [8, 10, 12],
    [1, 3],
    [5, 7],
    [9, 11],
    [2, 4],
    [6, 8],
    [10, 12],
  ];

  for (var n = 0; n < bettingButton[selectOddEven].length; n++) {
    if (bettingButton[selectOddEven][n] === digit) {
      return true;
    }
  }
  return false;
}

function toggleButton(selectOddEven, indexButton) {
  var indexOddEvenUnder = [0, 0, 0];
  const index = [
    [0, 0, 0],
    [1, 0, 0],
    [2, 0, 0],
    [0, 1, 0],
    [0, 2, 0],
    [1, 1, 0],
    [1, 2, 0],
    [2, 1, 0],
    [2, 2, 0],
    [1, 0, 1],
    [1, 0, 2],
    [1, 0, 3],
    [2, 0, 1],
    [2, 0, 2],
    [2, 0, 3],
  ];

  for (var n = 0; n < index[selectOddEven].length; n++) {
    indexOddEvenUnder[n] = index[selectOddEven][n];
  }

  if (indexButton === 1) {
    if (indexOddEvenUnder[0] === 0) {
      indexOddEvenUnder[0] = 1;
    } else if (indexOddEvenUnder[0] === 1) {
      indexOddEvenUnder[0] = 0;
    } else {
      indexOddEvenUnder[0] = 1;
    }
  } else if (indexButton === 2) {
    if (indexOddEvenUnder[0] === 0) {
      indexOddEvenUnder[0] = 2;
    } else if (indexOddEvenUnder[0] === 2) {
      indexOddEvenUnder[0] = 0;
    } else {
      indexOddEvenUnder[0] = 2;
    }
  } else if (indexButton === 3) {
    if (indexOddEvenUnder[1] === 0) {
      indexOddEvenUnder[1] = 1;
    } else if (indexOddEvenUnder[1] === 1) {
      indexOddEvenUnder[1] = 0;
    } else {
      indexOddEvenUnder[1] = 1;
    }
    indexOddEvenUnder[2] = 0;
  } else if (indexButton === 4) {
    if (indexOddEvenUnder[1] === 0) {
      indexOddEvenUnder[1] = 2;
    } else if (indexOddEvenUnder[1] === 2) {
      indexOddEvenUnder[1] = 0;
    } else {
      indexOddEvenUnder[1] = 2;
    }
    indexOddEvenUnder[2] = 0;
  } else if (indexButton === 5) {
    if (indexOddEvenUnder[2] === 0) {
      indexOddEvenUnder[2] = 1;
    } else if (indexOddEvenUnder[2] === 1) {
      indexOddEvenUnder[2] = 0;
    } else {
      indexOddEvenUnder[2] = 1;
    }
    indexOddEvenUnder[1] = 0;
  } else if (indexButton === 6) {
    if (indexOddEvenUnder[2] === 0) {
      indexOddEvenUnder[2] = 2;
    } else if (indexOddEvenUnder[2] === 2) {
      indexOddEvenUnder[2] = 0;
    } else {
      indexOddEvenUnder[2] = 2;
    }
    indexOddEvenUnder[1] = 0;
  } else if (indexButton === 7) {
    if (indexOddEvenUnder[2] === 0) {
      indexOddEvenUnder[2] = 3;
    } else if (indexOddEvenUnder[2] === 3) {
      indexOddEvenUnder[2] = 0;
    } else {
      indexOddEvenUnder[2] = 3;
    }
    indexOddEvenUnder[1] = 0;
  }

  for (var n = 0; n < index.length; n++) {
    if (
      index[n][0] === indexOddEvenUnder[0] &&
      index[n][1] === indexOddEvenUnder[1] &&
      index[n][2] === indexOddEvenUnder[2]
    ) {
      return n;
    }
  }

  return 0;
}

export default function RandomBoard(props) {  
  const { lngT } = useLang('racing:intro');
  

  // const [selectIndex, setSelectIndex] = useState(0);

  //const [disabledBtn, setDisabledBtn] = useState(true);
  const BETLIST_TYPE = 0;
  const BETLIST_NUMBER = 1;
  const BETLIST_TYPE_DETAIL = 2;
  const singleNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const showNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const bettingList = [
    [`${lngT('contents:odd')}/${lngT('contents:even')}`, `${lngT('buttons:single')}`, `${lngT('buttons:show')}`],
    [
      '1,3,5,7,9,11',
      '2,4,6,8,10,12',
      '1,2,3,4,5,6',
      '7,8,9,10,11,12',
      '1,3,5',
      '7,9,11',
      '2,4,6',
      '8,10,12',
      '1,3',
      '5,7',
      '9,11',
      '2,4',
      '6,8',
      '10,12',
    ],
    [
      `${lngT('contents:odd')}`,
      `${lngT('contents:even')}`,
      `${lngT('contents:under')}`,
      `${lngT('contents:over')}`,
      `${lngT('contents:oddUnder')}`,
      `${lngT('contents:oddOver')}`,
      `${lngT('contents:evenUnder')}`,
      `${lngT('contents:evenOver')}`,
      `${lngT('contents:oddSmall')}`,
      `${lngT('contents:oddMedium')}`,
      `${lngT('contents:oddLarge')}`,
      `${lngT('contents:evenSmall')}`,
      `${lngT('contents:evenMedium')}`,
      `${lngT('contents:evenLarge')}`,
    ],
  ];

  if (props.selectOddEven === undefined) isSMGActive = false;
  else
    isSMGActive = !(
      props.selectOddEven === 0 ||
      props.selectOddEven === define.ODDEVEN_BETTYPE_OVER ||
      props.selectOddEven === define.ODDEVEN_BETTYPE_UNDER
    );
  // console.log("isSMGActive : " + isSMGActive);

  const classes = useOddEvenStyles();
  const classesSingle = useSingleStyles();
  const classesShow = useShowStyles();
  const { data: balance, isLoading, error, mutate } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  underoverInfo = [
    { text: 'contents:odd', item: classes.item, itemRow: classes.itemOdd, itemRowActive: classes.itemOddActive },
    { text: 'contents:even', item: classes.item, itemRow: classes.itemEven, itemRowActive: classes.itemEvenActive },
    {
      text: 'contents:under',
      item: classes.item,
      itemRow: classes.itemRowUnder,
      itemRowActive: classes.itemRowUnderActive,
    },
    {
      text: 'contents:over',
      item: classes.item,
      itemRow: classes.itemRowOver,
      itemRowActive: classes.itemRowOverActive,
    },
    {
      text: 'contents:Small',
      item: isSMGActive ? classes.item : `${classes.item} ${classes.itemRowBottomNotActive}`,
      itemRow: classes.itemRowBottom,
      itemRowActive: isSMGActive ? classes.itemRowBottomActive : classes.itemRowBottomNotActive,
    },
    {
      text: 'contents:Medium',
      item: isSMGActive ? classes.item : `${classes.item} ${classes.itemRowMiddleNotActive}`,
      itemRow: classes.itemRowMiddle,
      itemRowActive: isSMGActive ? classes.itemRowMiddleActive : classes.itemRowMiddleNotActive,
    },
    {
      text: 'contents:Large',
      item: isSMGActive ? classes.item : `${classes.item} ${classes.itemRowTopNotActive}`,
      itemRow: classes.itemRowTop,
      itemRowActive: isSMGActive ? classes.itemRowTopActive : classes.itemRowTopNotActive,
    },
  ];

  const selectBettingOddEven = (selectOddEven, indexButton) => {
    if (props.bettype !== define.BETTYPE_ODDEVEN) {
      return;
    }
    webSocketIO.data.selectOddEven = toggleButton(selectOddEven, indexButton);
    props.setSelectOddEven(webSocketIO.data.selectOddEven);
  };

  // console.log('selectBettingOddEven:', webSocketIO.data.selectOddEven);

  const selectBettingDigit = indexButton => {
    if (props.bettype !== define.BETTYPE_SINGLE) {
      return;
    }

    var newDigit = indexButton;
    if (indexButton === props.selectDigit) {
      newDigit = 0;
    }

    webSocketIO.data.selectDigit = newDigit;
    props.setSelectDigit(webSocketIO.data.selectDigit);
  };

  const selectBettingShowDigit = indexButton => {
    if (props.bettype !== define.BETTYPE_SHOW) {
      return;
    }

    var newDigit = indexButton;
    if (indexButton === props.selectShowDigit) {
      newDigit = 0;
    }

    webSocketIO.data.selectShowDigit = newDigit;
    props.setSelectShowDigit(webSocketIO.data.selectShowDigit);
  };

  const dispatch = useDispatch();
  const [goodsType, setgoodsType] = useState(GoodsType.CASHCOIN);

  useEffect(() => {
    setgoodsType(props.goodsType);
  }, []);

  useEffect(() => {
    props.HandleSetactivelistBettingValue(activelistBettingValue);
  }, [activelistBettingValue]);

  var viewSingleNumbers = [];
  const SetviewSingleNumbers = () => {
    activelistBettingValue = false;

    for (var i = 0; i < singleNumber.length; ++i) {
      var row = singleNumber[i];
      var tempclsx;
      if (props.bettype === define.BETTYPE_ODDEVEN) {
        if (isOnDigit(props.selectOddEven, row)) {
          tempclsx = clsx(classes.itemNum, classes.itemNumActive);
          activelistBettingValue = true;
        } else tempclsx = classes.itemNumBoxStyle;
      } else {
        var tempSelectDigit = props.bettype === define.BETTYPE_SINGLE ? props.selectDigit : props.selectShowDigit;
        if (tempSelectDigit === row) {
          tempclsx = clsx(classes.itemNum, classes.itemNumActive);
          activelistBettingValue = true;
        } else tempclsx = classes.itemNum;
      }

      var viewSingleNumber = (
        <Button key={row} className={tempclsx} disableRipple onClick={() => {}}>
          {row}
        </Button>
      );
      viewSingleNumbers.push(viewSingleNumber);
    }
  };
  SetviewSingleNumbers();

  return (
    <div className="relative">
      {props.isNotBetting && (
        <div className="absolute z-50 top-1/4 left-1/2 -translate-x-1/2 text-white text-2xl text-center">
          You can't bet now.
          <br /> Wait a minute please
        </div>
      )}
      <Box className={props.isNotBetting ? 'opacity-10' : ''}>
        {
          <Rate
            odds={
              props.bettype === define.BETTYPE_ODDEVEN
                ? props.selectOddEven > 0
                  ? `x${webSocketIO.data.listRow[define.BETTYPE_ODDEVEN][props.selectOddEven - 1].odds.toFixed(2)}`
                  : ''
                : props.bettype === define.BETTYPE_SINGLE
                ? `x${webSocketIO.data.listRow[define.BETTYPE_SINGLE][0].odds.toFixed(2)}`
                : props.bettype === define.BETTYPE_SHOW
                ? `x${webSocketIO.data.listRow[define.BETTYPE_SHOW][0].odds.toFixed(2)}`
                : ''
            }
          />
        }
        {props.bettype === define.BETTYPE_ODDEVEN ? (
          <Box className={classes.bettingStyle}>
            <Box className={clsx(classes.item, classes.empty)} />

            {underoverInfo.map((item, index) => (
              <Button
                key={index}
                className={
                  isOnButton(props.selectOddEven, index + 1)
                    ? clsx(item.item, item.itemRow, item.itemRowActive)
                    : clsx(item.item, item.itemRow)
                }
                disableRipple
                onClick={() => {
                  selectBettingOddEven(props.selectOddEven, index + 1);
                }}
                disabled={props.bettype === define.BETTYPE_ODDEVEN ? false : true}
              >
                {lngT(item.text)}
              </Button>
            ))}
            <Box className={clsx(classes.item, classes.empty)}></Box>

            {viewSingleNumbers}
          </Box>
        ) : props.bettype === define.BETTYPE_SINGLE ? (
          <Box className={classesSingle.numberBoxStyle}>
            {singleNumber.map(row => (
              //  선택되었을 때,
              //  <Button disableRipple className={clsx(classes.numberStyle, classes.numberActiveStyle)}>{row}</Button>
              <Button
                key={row}
                disableRipple
                className={
                  props.selectDigit === row && props.activeButton
                    ? clsx(classesSingle.numberStyle, classesSingle.numberActiveStyle)
                    : clsx(classesSingle.numberStyle)
                }
                onClick={() => {
                  props.setActiveButton(true);
                  props.setSelectedSingleButton(row - 1);
                  selectBettingDigit(row);
                }}
              >
                {/* {console.log('rrrr' + JSON.stringify(props.arraySingleBetting))} */}
                {row}
                {props.arraySingleBetting.map((item, index) =>
                  item.betting >= 50 && index + 1 === row ? (
                    <div key={index}>
                      <div
                        className="absolute z-[99] top-1/2 -translate-y-1/2 right-6 bg-[#e5ad34a4] rounded-full flex justify-center text-gray-700 text-xl rotate-45 items-center px-[5px] T:w-14 T:h-14 flex-col space-y-1 shadow-sm shadow-[#4c412aa4]"
                        align="center"
                      >
                        <div
                          className={`font-bold font-oswald text-2xl leading-6 ${
                            item.betting < 100
                              ? 'tracking-wider'
                              : item.betting >= 1000
                              ? 'tracking-tighter'
                              : 'tracking-normal'
                          }`}
                        >
                          {item.betting}
                        </div>
                        <p className="text-[4px] leading-[4px] tracking-tight">points</p>
                      </div>
                    </div>
                  ) : null
                )}
              </Button>
            ))}
          </Box>
        ) : props.bettype === define.BETTYPE_SHOW ? (
          <Box className={classesShow.numberBoxStyle}>
            {showNumber.map(row => (
              //  선택되었을 때,
              //  <Button disableRipple className={clsx(classes.numberStyle, classes.numberActiveStyle)}>{row}</Button>
              <Button
                key={row}
                disableRipple
                className={
                  props.selectShowDigit === row && props.activeShowButton
                    ? clsx(classesShow.numberStyle, classesShow.numberActiveStyle)
                    : clsx(classesShow.numberStyle)
                }
                onClick={() => {
                  props.setActiveShowButton(true);
                  props.setSelectedShowButton(row - 1);
                  selectBettingShowDigit(row);
                  // console.log('fff');
                }}
              >
                {row}
                {props.arrayShowBetting.map((item, index) =>
                  item.betting >= 50 && index + 1 === row ? (
                    <div key={index}>
                      <div
                        className="absolute z-[99] top-1/2 -translate-y-1/2 right-6 bg-[#e5ad34a4] rounded-full flex justify-center text-gray-700 text-xl rotate-45 items-center px-[5px] T:w-14 T:h-14 flex-col space-y-1 shadow-sm shadow-[#4c412aa4]"
                        align="center"
                        // style={{ background: '' }}
                      >
                        <div
                          className={`font-bold font-oswald text-2xl leading-6 ${
                            item.betting < 100
                              ? 'tracking-wider'
                              : item.betting >= 1000
                              ? 'tracking-tighter'
                              : 'tracking-normal'
                          }`}
                        >
                          {item.betting}
                        </div>
                        <p className="text-[4px] leading-[4px] tracking-tight">points</p>
                      </div>
                    </div>
                  ) : null
                )}
              </Button>
            ))}
          </Box>
        ) : (
          ''
        )}
        {define.SHOW_BETTING_BALANCE ? (
          <div className="relative w-full h-[50px] flex justify-center items-center mt-4">
            <div className="absolute z-10 flex flex-row items-center justify-center space-x-4">
              <p className="text-white text-lg ">{
              // lngT('footer:myPoints')
              }</p>
              <div className="flex flex-row items-center justify-center gap-2">
                <Goods goodsType={props.goodsType} size={9} />
                <p className="text-[#C4F322] font-semibold text-xl text-center">
                  {GetFixedBalanceStr(props.goodsType, balance)}
                </p>
              </div>
            </div>
            <div className="bg-white/20 absolute w-full h-full rounded-md"></div>
          </div>
        ) : (
          ''
        )}

        <div className="flex justify-center items-center w-full h-[54px] text-2xl bg-[#C4F322] mb-1 mt-5 rounded-t-md text-[#4E4E4E]">
          <div className="flex flex-col justify-center items-center py-3 space-y-0">
            <div className="flex flex-row items-center justify-center space-x-2">
              <div>{lngT('footer:bet')}</div>
              {/* <Goods goodsType={props.goodsType} size={7} /> */}
              <div className="text-[#3B25BB] font-semibold">{props.bettingValue.toLocaleString()}</div>
            </div>
            <div className="text-xs flex flex-row items-center justify-center">
              {' '}
              (<USDPerGoods size={5}/>)
            </div>
          </div>
        </div>
        <div className="grid grid-cols-5 gap-[1px] mb-5 h-[120px]">
          {webSocketIO.data.listBettingValue.map((value, index) =>
            props.activelistBettingValue ? (
              <div
                key={index}
                className={`flex justify-center items-center w-full ${
                  props.selectedValue === value ? 'bg-[#4E4E4E] ' : 'bg-[#4E4E4E]/60'
                } text-white p-2 cursor-pointer hover:bg-[#4E4E4E]/80 divide-x-[1px] divide-y-[1px] divide-[#1c012E]`}
                onClick={() => {
                  // 1.선택을 하는지 안하는지 검사
                  let selectIndex = 0;

                  if (props.bettype === define.BETTYPE_ODDEVEN) {
                    selectIndex = props.selectOddEven;
                  } else if (props.bettype === define.BETTYPE_SINGLE) {
                    selectIndex = props.selectDigit;
                  } else if (props.bettype === define.BETTYPE_SHOW) {
                    selectIndex = props.selectShowDigit;
                  }

                  if (selectIndex <= 0) return;

                  // 2. 남은 금액 산출
                  const MAX = 10000;

                  const prevAccBettingValue = webSocketIO.data.listRow[props.bettype].reduce(
                    (acc, curr) => (acc += curr.betting),
                    0
                  );
                  const inProgressBettingValue = props.bettingValue;
                  const possibleBettingAmount = MAX - (prevAccBettingValue + inProgressBettingValue);
                  console.log('possibleBettingAmount: ', possibleBettingAmount);

                  // 3. 발란스 검사

                  // const fiftyBalance = balance - Math.floor(balance % 50);
                  const fiftyBalance = Math.floor(GetBalance(props.goodsType, balance) / 50) * 50;

                  if (GetBalance(props.goodsType, balance) < inProgressBettingValue + value) {
                    if (GetBalance(props.goodsType, balance) < MAX) {
                      if (value === MAX) {
                        if (possibleBettingAmount === 0) return alert('Only You can bet max 10,000 points');
                        else if (fiftyBalance - prevAccBettingValue < 50) {
                          return alert('You need to charge the points');
                        } else {
                          if (fiftyBalance < prevAccBettingValue) {
                            return props.setBettingValue(prevAccBettingValue - fiftyBalance);
                          }
                          return props.setBettingValue(fiftyBalance - prevAccBettingValue);
                          // return props.setBettingValue(fiftyBalance);
                        }
                      }
                    }
                    return alert('You need to charge the points');
                  }

                  // 4. 금액 검사
                  let currentBettingValue = 0;

                  if (value === 0) {
                    return props.setBettingValue(0);
                  } else if (possibleBettingAmount < value) {
                    if (value === MAX) {
                      if (possibleBettingAmount === 0) return alert('Only You can bet max 10,000 points');
                      return props.setBettingValue(MAX - prevAccBettingValue);
                    } else {
                      return alert('Only You can bet max 10,000 points');
                    }
                  } else {
                    currentBettingValue = value;
                  }

                  props.setBettingValue(inProgressBettingValue + currentBettingValue);

                  props.setSelectedValue(value);

                  // props.setSelectBettingValue(totalValue);
                  console.log('webSocketIO.data.listBettingValue' + webSocketIO.data.listBettingValue);
                }}
              >
                {value === 10000 ? 'MAX' : value === 0 ? 'RESET' : value}
              </div>
            ) : (
              <div
                key={index}
                className={`flex justify-center items-center w-full bg-[#4E4E4E]/90 text-white/30 p-2 cursor-pointer divide-x-[1px] divide-y-[1px] divide-[#1c012E]`}
              >
                {value === 10000 ? 'MAX' : value === 0 ? 'RESET' : value}
              </div>
            )
          )}
          {/* {console.log('arraySingleBetting' + JSON.stringify(props.arraySingleBetting))} */}
        </div>
        <div className="mb-4 ">
          <button
            style={{ boxShadow: '6px 6px 2px rgba(255, 255, 255, 0.1) inset' }}
            className="w-full rounded-md bg-[#3C6D9B] text-white mx-auto h-[50px] text-2xl border-[#3C6D9B] hover:border-[#284a6a] hover:bg-[#3C6D9B]/60 font-semibold"
            onClick={() => {
              let selectIndex = 0;
              if (props.bettype === define.BETTYPE_ODDEVEN) {
                selectIndex = props.selectOddEven;
              } else if (props.bettype === define.BETTYPE_SINGLE) {
                selectIndex = props.selectDigit;
              } else if (props.bettype === define.BETTYPE_SHOW) {
                selectIndex = props.selectShowDigit;
              }
              props.setSelectedValue(1);
              props.handleSetModal(
                { showModal: true, comfirmModal: false },
                {
                  type: Number(props.bettype),
                  index: selectIndex - 1, //props.index,
                  value: props.bettingValue,
                  currency: 'point', //props.currency,
                }
              );
            }}
          >
            {/*BET*/ lngT('footer:bet')}
          </button>
        </div>

        <div className="flex flex-col pt-[2px] w-full space-y-1">
          <div className="relative flex flex-row w-full h-[48px] rounded-t-md">
            <div className="absolute w-full h-full bg-[#546739] rounded-t-md" />
            <div className="relative flex flew-row w-full h-full text-white text-lg">
              <div className="flex justify-center items-center px-[5px] w-[93px] T:w-[160px]" align="center">
                {/*경기*/ lngT('footer:type')}
              </div>
              <div className="flex justify-center items-center px-[5px] flex-1 T:w-[155px]" align="center">
                {/*번호*/ lngT('footer:no')}
              </div>
              <div className="flex justify-center items-center px-[5px] flex-1 T:w-[203px]" align="center">
                {/*금액*/ lngT('footer:coin')}
              </div>
              <div className="flex justify-center items-center px-[5px] flex-1 T:w-[185px]" align="center">
                {/*금액*/ lngT('header:odds')}
              </div>
            </div>
          </div>
          <div className="h-[300px] overflow-y-auto">
            <div className="flex flex-col w-full bg-[hsl(0,0%,31%)] divide-y divide-white/20">
              {webSocketIO.data.listRow.map((list, nRow) =>
                list.map((row, index) =>
                  row.betting !== 0 ? (
                    <div
                      key={100 * nRow + index}
                      className={`flex flex-row w-full h-[35px] T:h-[55px] text-white text-[14px] ${
                        nRow === define.BETTYPE_SINGLE
                          ? 'text-white'
                          : nRow === define.BETTYPE_SHOW
                          ? 'text-white'
                          : 'text-white'
                      }`}
                    >
                      <div
                        className="flex justify-center items-center px-[5px] w-[93px] T:w-[160px]"
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {nRow === define.BETTYPE_ODDEVEN
                          ? bettingList[BETLIST_TYPE_DETAIL][index]
                          : bettingList[BETLIST_TYPE][nRow]}
                      </div>
                      <div className="flex justify-center items-center px-[5px] flex-1 T:w-[155px]" align="center">
                        {nRow === define.BETTYPE_ODDEVEN ? bettingList[BETLIST_NUMBER][index] : index + 1}
                      </div>
                      <div className="flex justify-center items-center px-[5px] flex-1 T:w-[155px]" align="center">
                        {row.betting}
                      </div>
                      <div className="flex justify-center items-center px-[5px] flex-1 T:w-[155px]" align="center">
                        {row.odds.toFixed(2)}
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                )
              )}
              {/* {console.log('webSocketIO.data.listRow' + JSON.stringify(webSocketIO.data.listRow))} */}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}
