import * as config from '../config';

export const SNS = [
  // RACING
  {
    Telegram: 'https://www.t.me/upodium',
    Instagram: 'https://www.instagram.com/upodium777',
    Facebook: 'https://www.facebook.com/u.podium777',
  },

  // POWERBALL
  {
    Telegram: 'https://t.me/up7bet',
    Instagram: 'https://www.instagram.com/upowerball777',
    Facebook: 'https://www.facebook.com/upowerball777',
  },
];

export const GetSNSLink = snsType => {
  const snsInfo = SNS[config.SERVICE_TYPE];
  return snsInfo[snsType];
};
