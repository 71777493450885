import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, setCookie } from '../../lib/cookie'

let powerballinfo = null;
export default function NumberResultDisplay({ isResultPopupOpen, canBet }) {
  const [showPowerballNumber, setShowPowerballNumber] = useState(false);
  const Powerball = useSelector(state => state.Powerball);
  const getState = useSelector(state => state.curState);

  const [eachBall, setEachBall] = useState(0);
  const [powerball, setPowerball] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [showallball, setshowallball] = useState();
  const [resultPopupState, setresultPopupState] = useState(false);
  console.log('getState', getState, '/', canBet);

  useEffect(() => {
    if (powerballinfo !== undefined && powerballinfo !== null && getState > 5) {
      setTimeout(() => {
        setShowPowerballNumber(true);
      }, 2000);
    } else {
      setShowPowerballNumber(false);
    }
  }, [getState]);

  useEffect(() => {
    if (
      powerballinfo === undefined ||
      powerballinfo === null ||
      powerballinfo.ball === undefined ||
      powerballinfo.powerBall === undefined ||
      getState === undefined
    )
      return;

    let ballTimerId;
    let powerBallTimerId;
    let totalResult;

    if (1 <= getState && getState < 6) {
      const delay = 1200;
      for (let i = 1; i <= getState; i++) {
        ballTimerId = setTimeout(() => setEachBall(powerballinfo.ball[i - 1]), delay);
      }
    }

    if (getState === 6) {
      powerBallTimerId = setTimeout(() => setPowerball(powerballinfo.powerBall), 500);
    }

    if (getState === 8) {
      totalResult = setTimeout(() => setShowResult(true), 500);
      setEachBall(0);
      setPowerball(0);
    }

    return () => {
      clearTimeout(ballTimerId);
      clearTimeout(powerBallTimerId);
      clearTimeout(totalResult);
    };
  }, [Powerball, getState]);

  useEffect(() => {
    // if(Powerball)
    //   console.log('isResultPopupOpen : ' + isResultPopupOpen, '/', getState);

    if (getState < 8) setresultPopupState(false);
    setshowallball(!resultPopupState && !canBet);
  }, [canBet, getState]);

  useEffect(() => {
    if (isResultPopupOpen) setresultPopupState(true);
  }, [isResultPopupOpen]);

  useEffect(() => {
    if(Powerball)
    {
      powerballinfo = getCookie('prevgameinfo');
      powerballinfo = powerballinfo === "null" ? null : powerballinfo;
      if(powerballinfo)
      console.log('gameframe : ' + powerballinfo.gameframe + ' ' + Powerball.gameframe);
    }
  }, [Powerball]);  

  // if (getState === undefined || getState === 8) {
  if (getState === undefined) {
    return null;
  }

  // if (getState >= 8) return <div></div>;

  return (
    <div className="w-full h-full  flex justify-center items-center absolute z-10 top-1/2 -translate-y-1/2 gap-3 max-[480px]:gap-0">
      <div className="w-full h-[140px] max-[768px]:h-[100px] max-[480px]:h-[60px] flex justify-center items-center absolute z-10 top-1/2 -translate-y-1/2 gap-1 max-[480px]:gap-0">
        {/* 볼 하나씩 */}
        {eachBall !== 0 && getState < 6 && (
          <div className="w-[200px] h-full flex flex-row items-center max-[1440px]:text-4xl max-[768px]:text-xl max-[768px]:w-[120px] max-[480px]:w-[100px] max-[400px]:w-[55px] max-[400px]:text-xs ">
            <div
              // ref={ballRef}
              className="w-[200px] h-[200px] min-[1440px]:text-[48px] font-bold origin-top-left bg-gradient-to-b rounded-full text-black flex justify-center items-center max-[768px]:w-[120px] max-[768px]:h-[120px] max-[480px]:w-[80px] max-[480px]:h-[80px] max-[400px]:w-[50px] max-[400px]:h-[50px] relative"
              style={{ background: 'linear-gradient(180deg, black 55%, #ADADAD 100%)' }}
            >
              <div className="absolute w-[120px] h-[120px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full flex justify-center items-center max-[768px]:w-[70px] max-[768px]:h-[70px] max-[480px]:w-[50px] max-[480px]:h-[50px] max-[400px]:w-[30px] max-[400px]:h-[30px]">
                {eachBall}
              </div>
            </div>
          </div>
        )}
        {powerball !== 0 && getState >= 6 && (
          <div
            // ref={powerBallRef}
            className="text-black w-[200px] h-[200px] min-[1440px]:text-[48px] font-bold rounded-full flex items-center justify-center max-[768px]:w-[120px] max-[768px]:h-[120px] max-[480px]:w-[80px] max-[480px]:h-[80px] max-[400px]:w-[50px] max-[400px]:h-[50px] relative max-[1440px]:text-4xl max-[768px]:text-xl max-[400px]:text-xs "
            style={{ background: 'radial-gradient(50% 50% at 50% 50%, #FFB1B1 8%, #FFBEBE 14%, #FF2727 95%)' }}
          >
            <div className="absolute w-[120px] h-[120px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-full flex justify-center items-center max-[768px]:w-[70px] max-[768px]:h-[70px] max-[480px]:w-[50px] max-[480px]:h-[50px] max-[400px]:w-[30px] max-[400px]:h-[30px] ">
              {powerball}
            </div>
          </div>
        )}
        {showallball && getState >= 8 && powerballinfo !== null && (
          <div className="w-[680px] h-[110px] rounded-3xl relative z-10 font-semibold text-4xl max-[768px]:w-[90%] max-[768px]:flex max-[768px]:items-center max-[768px]:justify-center max-[768px]:text-lg max-[480px]:h-[50px] ">
            <div className="absolute w-full h-fit left-1/2 -translate-x-1/2 z-20 flex flex-row justify-center items-center gap-1 max-[768px]:top-1/2 max-[768px]:-translate-y-2/3">
              <div className="w-[85%] max-[768px]:w-[400px] max-[480px]:w-[230px] max-[400px]:w-[200px] h-full flex flex-row items-center justify-around gap-1 ">
                {powerballinfo &&
                  powerballinfo.ball.map(regularNumber => {
                    return (
                      <div
                        key={regularNumber}
                        className="w-[90px] h-[90px] origin-top-left bg-gradient-to-b rounded-full text-white flex justify-center items-center max-[768px]:w-[58px] max-[768px]:h-[58px] max-[480px]:w-[36px] max-[480px]:h-[36px]"
                        style={{ background: 'linear-gradient(180deg, black 55%, #ADADAD 100%)' }}
                      >
                        <div className="text-black w-[60px] h-[60px] rounded-full flex items-center justify-center max-[768px]:w-[38px] max-[768px]:h-[38px] max-[480px]:w-[20px] max-[480px]:h-[20px] bg-white text-3xl max-[768px]:text-xl max-[480px]:text-sm">
                          {regularNumber}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {powerballinfo && (
                <div
                  className="text-black w-[90px] h-[90px] rounded-full flex items-center justify-center max-[768px]:w-[58px] max-[768px]:h-[58px] max-[480px]:w-[36px] max-[480px]:h-[36px]"
                  style={{ background: 'radial-gradient(50% 50% at 50% 50%, #FFB1B1 8%, #FFBEBE 14%, #FF2727 95%)' }}
                >
                  <div className="text-black w-[60px] h-[60px] rounded-full flex items-center justify-center max-[768px]:w-[38px] max-[768px]:h-[38px] max-[480px]:w-[20px] max-[480px]:h-[20px] bg-white text-3xl max-[768px]:text-lg max-[480px]:text-sm">
                    {showPowerballNumber && powerballinfo.powerBall}
                  </div>
                </div>
              )}
            </div>
            {/* <div
              className="w-[680px] h-[110px] rounded-3xl mix-blend-overlay transitaion-all relative z-10 font-semibold max-[768px]:h-[80px] max-[768px]:w-[600px] max-[768px]:mx-auto max-[768px]:rounded-xl max-[480px]:w-[320px] max-[480px]:h-[50px] max-[480px]:rounded-xl bottom-2 bg-white/80"
     
            ></div> */}
          </div>
        )}
      </div>
      {showallball && getState < 8 && (
        <div className="relative w-[600px] h-full rounded-3xl z-10 font-semibold text-4xl max-[768px]:w-full">
          <div className="absolute bottom-4 w-[585px] h-[100px] rounded-3xl mix-blend-overlay transitaion-all border-[14px]  border-white z-10 font-semibold text-4xl max-[1024px]:w-[480px] max-[1024px]:h-[86px] max-[768px]:h-[80px] max-[768px]:w-[420px] mx-auto max-[768px]:rounded-xl max-[640px]:w-[380px] max-[580px]:w-[300px]  max-[580px]:h-[60px] [580px]:border-[8px] max-[480px]:w-[270px] max-[480px]:h-[47px] max-[480px]:border-[6px] max-[420px]:w-[225px] max-[420px]:h-[43px]  max-[380px]:w-[200px] max-[380px]:h-[38px] max-[360px]:w-[180px] max-[360px]:h-[32px] max-[340px]:w-[180px] max-[320px]:w-[160px] max-[320px]:h-[28px] max-[280px]:w-[140px] max-[280px]:h-[25px] max-[580px]:rounded-lg max-[768px]:bottom-2 max-[280px]:bottom-1 left-1/2 -translate-x-1/2"></div>
        </div>
      )}
      {/* 보라색 배경 */}
      {/* {showpurpleBack && (
        <div
          className="w-full h-full absolute z-[2] max-[768px]:h-[80px] max-[480px]:h-[60px] "
          style={{
            background: 'linear-gradient(180deg, #D28BDD -13.38%, #5901B1 76.67%, #FF73B6 124.1%)',
          }}
        ></div>
      )} */}
    </div>
  );
}
