import axios from 'axios';
import tw from 'tailwind-styled-components';

import { usePowerBallBettingStore, usePowerBallResultTrigger } from '@/store/powerball';
import { useLang } from '../../../../lib/hook';

import { BettingPriceValue } from './BettingPadBoard';
import BettingPointButton from './BettingPriceButton';

import DrawsLine from '../../../../img/powerball/b_betting_line.png';
import DrawsLineL from '../../../../img/powerball/b_betting_line_L.png';
import DrawsLineM from '../../../../img/powerball/b_betting_line_M.png';
import { useEffect, useState } from 'react';
import { env } from '@/env';

import { GoodsType, format } from '../../../../define';
import { Goods, GetBalance } from '../../../../components/gameui/goods';
import { Point } from '@/components/gameui/point';

const BoxText = tw.h2`
  font-bold text-base text-white upppercase pt-3 max-[1440px]:text-sm
  max-[400px]:text-xs
`;

const ButtonBox = tw.div`
  relative z-10 space-y-6  h-fit
  w-[380px] max-[1440px]:w-full
  mb-2
`;

const SmallButtonBox = tw.div`
  rounded-2xl
  px-4 py-2 space-y-4 h-fit
  w-[380px] max-[1440px]:w-full
`;

type Props = {
  canBet: boolean;
  accumulatedBettingPrice: number;
  handleClickPriceButton: (price: number) => void;
  rewardOddEven: any;
  goodsType: number;
};

const bettingPoints: BettingPriceValue[] = [50, 100, 300, 500, 1000, 3000, 5000, 7000, 'MAX', 'RESET'];

const MAX_PRICE = 10000;

export default function BettingPricePad({
  canBet,
  accumulatedBettingPrice,
  handleClickPriceButton,
  rewardOddEven,
  goodsType,
}: Props) {
  const [balance, setBalance] = useState(0);
  const { ticketStore } = usePowerBallBettingStore();
  const issuedTicketTotalPrice = Object.values(ticketStore).reduce((acc, curr) => acc + curr.price, 0);
  const { trigger } = usePowerBallResultTrigger();
  const { lngT } = useLang('powerball');
  const { lngT : lngTetc } = useLang('etcs');

  const handleClickPointsBtn = (price: BettingPriceValue) => () => {
    const minbetPrice = 50;
    if (price === 'RESET') {
      return handleClickPriceButton(-accumulatedBettingPrice);
    }

    let willAddedPrice: number = 0;

    if (price === 'MAX') {
      if (balance + issuedTicketTotalPrice + accumulatedBettingPrice >= MAX_PRICE) {
        willAddedPrice = MAX_PRICE - issuedTicketTotalPrice - accumulatedBettingPrice;
      } else {
        willAddedPrice = balance;
      }
    } else {
      willAddedPrice = price;
    }

    // 50 원 이상 배팅해야함
    if (willAddedPrice < minbetPrice) {
      const alertStr = 
        goodsType === GoodsType.CASHCOIN ? 
        lngTetc('warning:minimumbettingpoint') : lngTetc('warning:minimumbettingbonus')
      return alert(format(alertStr, minbetPrice));
    }
    
    // 포인트 부족할 때 알림 창 띄우기
    if (balance < willAddedPrice || balance - accumulatedBettingPrice < willAddedPrice) {
      return alert('you need to charge you point');
    }

    // 10000 포인트 이상 배팅 못함
    if (issuedTicketTotalPrice + willAddedPrice + accumulatedBettingPrice > MAX_PRICE) {
      const alertStr = 
      goodsType === GoodsType.CASHCOIN ? 
        lngTetc('warning:betnotenoughpoint') : lngTetc('warning:betnotenoughbonus')

        return alert(format(alertStr, '10,000'));
    }

    handleClickPriceButton(Math.floor(willAddedPrice / 50) * 50);
  };

  useEffect(() => {
    if (rewardOddEven === null) return;

    (async function () {
      try {
        const { data } = await axios.get(`${env.api.host}/balance`, {
          withCredentials: true,
        });

        const totalReward = rewardOddEven?.reduce((total: number, item: any) => total + item.reward, 0) ?? 0;
        setBalance(GetBalance(goodsType, data) - totalReward);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [trigger, rewardOddEven]);

  return (
    <ButtonBox>
      <SmallButtonBox>
        <div className="py-2">
          <div className="w-full h-12 relative bg-indigo-100 rounded-lg border-[2px] border-indigo-400 flex flex-row justify-between px-4 items-center space-x-2">
            <div className="text-center text-sm font-medium uppercase text-black/60 max-[400px]:text-xs">
              {/* {lngT('betting:pad:my')} */}
              <Goods goodsType={goodsType} size={6} />
            </div>
            <div className="flex flex-row items-center justify-end align-bottom space-x-2">
              <div className="text-center text-xl font-semibold uppercase text-black/80">{balance}</div>
              {/* <span className="inline-block uppercase text-sm text-black/60 max-[400px]:text-xs">
                {/*POINT lngT('lottery:pad:point')}
              </span> */}
            </div>
          </div>
        </div>
        <picture className="w-[90%] max-[1440px]:w-full">
          <source srcSet={DrawsLine} media="(max-width: 500px)" />
          <source srcSet={DrawsLineM} media="(max-width: 984px)" />
          <source srcSet={DrawsLineL} media="(max-width: 1440px)" />
          <source srcSet={DrawsLine} media="(min-width:1441px)" />
          <img className="w-full max-[1440px]:w-full pt-5" src={DrawsLine} alt="DrawsLine"></img>
        </picture>
        {/* <BoxText>{/*Betting Points lngT('betting:pad:bettingpoint')}</BoxText> */}
        <div className="w-full h-12 relative flex flex-row justify-center items-center space-x-2">
          <div className="text-center uppercase text-[#FFFFFF] text-3xl font-bold">{lngT('betting:pad:bet')}</div>
          <div className="text-center uppercase text-[#FFC801] text-3xl font-bold">{accumulatedBettingPrice}</div>
          {/* <Goods goodsType={goodsType} size={8} /> */}
          <span className="inline-block uppercase text-sm align-baseline leading-5 text-white">
            {/* {/*POINT lngT('lottery:pad:point')} */}
          </span>
        </div>
        <div className="grid grid-cols-4 gap-2 relative z-10">
          {bettingPoints.map((point, i) => (
            <BettingPointButton
              key={i}
              pointValue={point}
              disabled={!canBet}
              isSelected={false}
              onClick={handleClickPointsBtn(point)}
            />
          ))}
        </div>
      </SmallButtonBox>
    </ButtonBox>
  );
}
