import React, { Component } from 'react';
import { ReactComponent as GuideGradient } from '../../assets/guideGradient.svg';

export default function Notify({ title, content }) {
  return (
    <div className="relative w-full h-[80px] flex justify-center items-center">
      <div className="bg-white/20 absolute w-full h-full rounded-md"></div>
      <div className="flex flex-col items-center justify-center">
        <p className="text-white text-lg LD:text-xl">{title}</p>
        <p className="text-[#C4F322] LD:text-lg text-center">{content}</p>
      </div>
    </div>
  );
}
