import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';

import useLang from '../../lib/hook/useLang';

import TextInputWrapper from '../../components/TextInputWrapper';
import PrimaryButton from '../../components/PrimaryButton';
import { ReactComponent as BackIcon } from '../../assets/icons/arrow-back.svg';

const Container = tw.section`
  flex flex-col justify-center
  w-[400px]
`;

const Title = tw.div`
  font-semibold text-[30px]
  mt-[20px] mb-[20px]
`;

const Description = tw.div`
  mb-[30px]
`;

export default function ForgotPassword() {
  const { lngT } = useLang('auth:password');
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const hasInputError = !!Object.keys(errors).length;

  const onSubmit = async payload => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotPassword`, payload, {
        withCredentials: true,
      });

      if (data.ok) {
        toast(lngT('forgot:message:success'), { theme: 'dark', type: 'success' });
      }
    } catch (error) {
      console.log(error);
      toast(lngT('forgot:message:fail'), { theme: 'dark', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container>
        <BackIcon className="cursor-pointer" onClick={() => navigate('/login')} />
        <Title>{lngT('forgot:title')}</Title>
        <Description>{lngT('forgot:description')}</Description>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInputWrapper
            title={lngT('forgot:email')}
            className="mb-[25px]"
            isEmpty={!watch('email')}
            errorText={errors.email?.message}
          >
            <input
              type="text"
              {...register('email', {
                required: lngT('forgot:email:error:1'),
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                  message: lngT('forgot:email:error:2'),
                },
              })}
              placeholder={lngT('forgot:email:placeholder')}
              autoComplete="off"
            />
          </TextInputWrapper>
          <PrimaryButton
            type="submit"
            title={lngT('forgot:email:placeholder')}
            isLoading={isLoading}
            error={hasInputError}
          />
        </form>
      </Container>
    </div>
  );
}
