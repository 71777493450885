import { useState, useEffect } from 'react';
import Landing from './Landing';
import LandingPageMobile from './Mobile/page';

export default function LandingPage() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 769 ? setIsMobile(true) : setIsMobile(false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <div className="overflow-x-hidden">{isMobile ? <LandingPageMobile /> : <Landing />}</div>;
}
