import tw from 'tailwind-styled-components';

const Container = tw.div`
  relative
  flex justify-center items-center
  w-full h-[54px]
  p-[2px]
  rounded-[30px]
  overflow-hidden
`;

const LoadingBg = tw.div`
  absolute
  w-[120%] aspect-square
  bg-[conic-gradient(#fd004c,#fe9000,#fff020,#3edf4b,#3363ff,#b102b7,#fd004c)]
  rounded-[30px]
  animate-spin
`;

const Buttton = tw.button`
  relative
  flex justify-center items-center
  leading-[24px] text-white
  rounded-[30px]
  w-full py-3
  bg-[#8E00CB]
  ${({ $isLoading }) => ($isLoading ? '' : 'hover:bg-opacity-30 disabled:opacity-60 disabled:hover:bg-opacity-100')}
`;

const PrimaryButton = ({ type, title, isLoading, error, className, onClick }) => {
  return (
    <Container className={className}>
      {isLoading && <LoadingBg />}
      <Buttton type={type} disabled={isLoading || error} $isLoading={isLoading} onClick={onClick}>
        <div className="transform transition duration-500 scale-110">{title}</div>
      </Buttton>
    </Container>
  );
};

export default PrimaryButton;
