import { useTranslation } from 'react-i18next';

const useLang = (prefix?: string) => {
  const { t, i18n } = useTranslation();

  const changeLang = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lng', lng);
  };

  return {
    isCurrentEn: i18n.language === 'en',
    isExists: i18n.language === 'en' || i18n.language === 'pt',
    currentLang: i18n.language,
    changeLang,
    lngT: (name: string) => t(`${prefix}:${name}:value`),
  };
};

export default useLang;