import { env } from '@/env';
import { genFetchContainer } from '@/lib/RestfulContainer';
import { HeaderItem, ListPager, RowItem, useListPager } from '@/lib/custom-lib/ListPager';
import { useDateSelector, useLang } from '@/lib/hook';
import { formatDateToLocal } from '@/lib/util';
import { DateFilter } from '@/page/common/component';
import { ChangeEvent } from 'react';

type ListHeaderProps = {
  width: number;
  title: string;
};

const headers: ListHeaderProps[] = [
  {
    width: 100,
    title: /*Index*/ 'conversion:history:table:index',
  },
  {
    width: 200,
    title: /*Date*/ 'conversion:history:table:date',
  },
  {
    width: 150,
    title: /*B. Point*/ 'conversion:history:table:bpoint',
  },
  {
    width: 150,
    title: /*Point*/ 'conversion:history:table:point',
  },
];

export default function HistoryTab() {
  const { lngT } = useLang('mypage:userInfo');
  const { currentPage, setCurrentPage } = useListPager();
  const { startDate, endDate, setStartDate, setEndDate } = useDateSelector();

  const listHeaderProps: ListHeaderProps[] = headers.map(header => ({ ...header, title: lngT(header.title) }));

  const FetchConversionBalanceHistoryContainer = genFetchContainer<
    {
      date: Date;
      point: number;
      free: number;
    }[]
  >({
    url: `${
      env.api.host
    }/balance/conversion/history?currentPage=${currentPage}&perPage=${20}&sortBy=DESC&startDate=${startDate}&endDate=${endDate}`,
  });

  const handleChangeFilterState =
    (setState: (state: any) => void) => (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      setState(e.target.value);
      setCurrentPage(1);
    };

  return (
    <>
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={handleChangeFilterState(setStartDate)}
        onChnageEndDate={handleChangeFilterState(setEndDate)}
      />
      <FetchConversionBalanceHistoryContainer>
        {({ data, isLoading, error }) => {
          const totalPageCount = data?.meta?.paginate?.totalCount ?? 0;
          const perPage = data?.meta?.paginate?.perPage ?? 0;

          return (
            <ListPager
              options={{
                $minHeight: 500,
              }}
              header={
                <HeaderItem.Container>
                  {listHeaderProps.map((header, i) => (
                    <HeaderItem.Box key={i} $width={header.width}>
                      {header.title}
                    </HeaderItem.Box>
                  ))}
                </HeaderItem.Container>
              }
              dataLength={data?.data.length ?? 0}
              rows={data?.data.map((item, index) => {
                const isEven = !(index % 2);
                const indexNumber = totalPageCount - (index + (currentPage - 1) * perPage);
                const date = formatDateToLocal(item.date);
                const free = item.free;
                const point = item.point;

                return (
                  <RowItem.Container key={index} $isEven={isEven}>
                    <RowItem.Box $width={listHeaderProps[0].width}>{indexNumber}</RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[1].width}>{date}</RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[2].width}>{free}</RowItem.Box>
                    <RowItem.Box $width={listHeaderProps[3].width}>{point}</RowItem.Box>
                  </RowItem.Container>
                );
              })}
              pager={{
                totalPageCount,
                perPage,
                currentPage,
                setCurrentPage,
              }}
              isLoading={isLoading}
              error={!!error}
            />
          );
        }}
      </FetchConversionBalanceHistoryContainer>
    </>
  );
}
