import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation, Autoplay } from 'swiper';
import tw from 'tailwind-styled-components';
import useLang from '@/lib/hook/useLang';
import { useModalStore } from '@/lib/custom-lib/modal';
import { useSpring, animated } from 'react-spring';
import { useEffect, useState } from 'react';

import {ChangePagePowerBall, GetDefaultGoods, GoReelGame} from '../../../horsestall/progresscombine/jsUtil_combine';
import { GoodsType } from '../../../define';
import { useTranslation } from 'react-i18next';
import { SpineRenderCommon, SpineRenderCommonByID } from '../../../util/SpineRenderCommon';
import { Point } from '@/components/gameui/point';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';
import Notice from '../../../components/Notice';

const bannerLinks = ['/reelgame', '/powerball/betting', '/powerball/lottery', '/event' ];

const Container = tw.div`
  relative
  w-full py-4 px-4 md:px-10 grid gap-4
`;

const UpperBanner = tw.div`
  relative bg-red-500 overflow-hidden border border-yellow-300
`;

const UpperBannerBG = tw.img`
  w-full object-contain
`;

const UpperBannerContent = tw.div`
  absolute top-1/2 left-1/2 -translate-x-[46%] -translate-y-1/2
  flex flex-row justify-end items-center
  w-full
  max-[986px]:px-0
`;

const UpperBannerTitle = tw.p`
  text-base md:text-2xl lg:text-5xl font-bold text-white font-outline-2
`;

const PointBox = tw.div`
  relative w-[70%] h-full max-[986px]:w-[65%]
`;

const PointImg = tw.img`
  h-full w-full
`;

const PointText = tw.p`
  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-base min-[486px]:text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-8xl font-medium text-yellow-300
`;

const MainBannerBox = tw.div`
relative
bg-gray-300 h-fit relative overflow-hidden
  border-2 border-yellow-300
`;

const MainBannerBg = tw.img`
  w-full h-full sm:h-fit object-cover
`;

const MainBannerText = tw.p`
  absolute top-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2 text-yellow-400 md:text-xl sm:text-sm text-[9px] text-center w-full lg:w-[50%] mx-auto sm:font-outline-1 sm:font-bold font-outline-0 font-base leading-3
`;

const BannerContainer = tw.div`
  w-full h-full 
  flex flex-col justify-end
  gap-6 cursor-pointer 
  border border-white
`;

const BannerContent = tw.div`
  relative w-full h-full overflow-hidden
`;

const BannerInner = tw.div`
  absolute z-10 w-full h-full 
  flex flex-col justify-end 
  lg:gap-3 sm:gap-20 gap-4
`;

const BannerBg = tw.img`
  w-full h-full object-cover
`;

const BannerImg = tw.img`
  w-fit h-[40px] min-[400px]:h-[80px]
  object-fit px-10
`;

const ButtonTitle = tw.p`
  text-sm sm:text-lg 
  font-medium
  text-white 
`;

// const Button = tw.div`
//   relative w-[100px] h-[32px]
//   hover:brightness-105 transiation-all
// `;

const Button = tw.div`
  relative w-[100px] h-[32px]      
  bg-[#869900]/40 hover:bg-[#869900]/60  
  border border-transparent hover:border-[5px] hover:h-[37px]
  active:ring-[#869900] active:ring-2
`;

const ImgButton = tw.img`
  absolute top-0 right-0 w-full h-fit object-fit
`;

const ButtonText = tw.p`
  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full 
  text-sm font-medium text-white text-center
`;

const ButtonBg = tw.div`
  bg-black/70 
  flex flex-row justify-between items-center
  w-full
  py-3 px-2 max-[400px]:py-1
`;

function Landing() {
  const { openModal } = useModalStore();
  const jackpotStart = 3000;
  const [jackpot, setjackpot] = useState(jackpotStart);
  const props = useSpring({ val: jackpot, from: { val: 0 } });
  const { i18n } = useTranslation();
  const navigate = useCustomNavigate();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);
  const [user] = useUser();
  const { lngT : lngTetc } = useLang('etcs');

  let accumedJackpot = 0;

  useEffect(() => {
    const refreshDelay = 3000.0;

    const RefreshJackPot = () => {
      setTimeout(() => {
        const max = 20;
        const min = 10;
        accumedJackpot += Math.floor(Math.random() * (max - min)) + min; // 10~20
        const randomNum = jackpot + accumedJackpot;
        setjackpot(randomNum);
        RefreshJackPot();
      }, refreshDelay);
    };

    RefreshJackPot();
  }, []);

  return (
    <Container>
      <Notice />
      <UpperBanner>
        <UpperBannerBG src="/images/upowerball/p-jackpot_bg.jpg" />
        <UpperBannerContent>
          <PointBox>
            <SpineRenderCommon
              path="powerball/landing/jackpot_ani"
              file="p-jackpot_ani"
              aniName="jackpot_ani"
            ></SpineRenderCommon>
            <PointText>
              <animated.dd className="powerball font-digital">
                {props.val.to(val => 'P.' + Math.floor(val).toLocaleString())}
              </animated.dd>
            </PointText>
          </PointBox>
        </UpperBannerContent>
      </UpperBanner>
      <MainBanner />
      <div className="relative grid lg:grid-cols-3 gap-4 grid-cols-1 ">
        <BannerContainer
          onClick={() => {
            if (user === undefined) {
              navigate('/login');
              return;
            }

            ChangePagePowerBall('/powerball/betting', GetDefaultGoods(balance?.data));
          }}
        >
          <BannerContent>
            <BannerInner>
              <ButtonBg>
                <ButtonTitle>Powerball Betting</ButtonTitle>
                <Button>
                  <ImgButton src="/images/upowerball/start__button.png" />
                  <ButtonText>Play Now</ButtonText>
                </Button>
              </ButtonBg>
            </BannerInner>
            <BannerBg src="/images/upowerball/start_betting_bg.jpg" />
          </BannerContent>
        </BannerContainer>
        <BannerContainer
          onClick={() => {
            if (user === undefined) {
              navigate('/login');
              return;
            }

            ChangePagePowerBall('/powerball/lottery', GetDefaultGoods(balance?.data));
          }}
        >
          <BannerContent>
            <BannerInner>
              <ButtonBg>
                <ButtonTitle>Powerball Lottery</ButtonTitle>
                <Button>
                  <ImgButton src="/images/upowerball/start__button.png" />
                  <ButtonText>Play Now</ButtonText>
                </Button>
              </ButtonBg>
            </BannerInner>
            <BannerBg src="/images/upowerball/start_lottery_bg.jpg" />
          </BannerContent>
        </BannerContainer>

        <BannerContainer
            onClick={() => {
              if (user === undefined) {
                navigate('/login');
                return;
              }

              GoReelGame(lngTetc);
            }}
        >
          <BannerContent>
            <BannerInner>
              <ButtonBg>
                <ButtonTitle>Slot</ButtonTitle>
                <Button>
                  <ImgButton src="/images/upowerball/start__button.png" />
                  <ButtonText>Play Now</ButtonText>
                </Button>
              </ButtonBg>
            </BannerInner>
            <BannerBg src={`/assets/img/start_uslot.webp`} />
          </BannerContent>
        </BannerContainer>
      </div>
    </Container>
  );
}

export default Landing;

const MainBanner = () => {
  const { lngT } = useLang('landing');
  const { i18n } = useTranslation();

  return (
    <Swiper
      grabCursor={true}
      navigation={true}
      loop={true}
      slidesPerView={1}
      speed={500}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 0,
      }}
      modules={[EffectCoverflow, Navigation, Autoplay]}
      className="swiper_container w-full"
    >
      {Array.from({ length: 4 }).map((_, i) => {
        const { imageUrl, link, titleUrl, index } = getBannerItems(i, i18n);

        return (
          <SwiperSlide key={i} className="relative">
            <BannerItem imageUrl={imageUrl} titleUrl={titleUrl} link={link} index={index} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const BannerItem = ({ imageUrl, titleUrl, link, index }) => {
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const { lngT : lngTetc } = useLang('etcs');
  let spineInfo = '';
  if (index === 2) {
    spineInfo = (
      <div className="absolute z-10 transform left-1/4 -translate-x-1/2 lg:-top-4 lg:w-[30%] sm:-top-2 top-0 w-[25%] h-auto">
        <SpineRenderCommonByID
          path="powerball/landing/logo"
          file="upowerball_logo_ani"
          aniName="logo_ani"
          id="player-container2"
        ></SpineRenderCommonByID>
      </div>
    );
  }
  if (index === 3) {
    spineInfo = (
      <div className="absolute z-10 transform left-1/4 -translate-x-1/2 lg:-top-4 lg:w-[30%] sm:-top-2 top-0 w-[25%] h-auto">
        <SpineRenderCommonByID
          path="powerball/landing/logo"
          file="upowerball_logo_ani"
          aniName="logo_ani"
          id="player-container3"
        ></SpineRenderCommonByID>
      </div>
    );
  }
  return (
    <MainBannerBox
      onClick={() => {
        if (user === undefined) {
          navigate('/login');
          return;
        }

        if(link.includes('reelgame'))
        {
          // alert(lngTetc('warning:reelmaintenance'));
          // return;
        }
          
        ChangePagePowerBall(link, GetDefaultGoods(balance?.data));
      }}
    >
      {/* <img
          alt=""
          src="/images/upowerball/upowerball_logo.png"
          className="absolute z-10 transform left-1/4 -translate-x-1/2 lg:top-7 lg:w-[20%] sm:top-4 top-2 w-[15%] h-auto"
        /> */}
      <MainBannerBg src={imageUrl} />
      {spineInfo}
    </MainBannerBox>
  );
};

const getBannerItems = (index, i18n) => ({
  index: index + 1,
  imageUrl: `/images/upowerball/banner_${index}_${i18n.language === 'pt' ? 'pt' : i18n.language === 'cn' ? 'cn' : 'en'}.jpg`,
  titleUrl: `/images/upowerball/banner_text_${index + 1}.png`,
  link: bannerLinks[index],
});

const VideoModal = () => {
  const { closeModal } = useModalStore();

  const handleCloseVideoMdoal = e => {
    const clickedElement = e.target;
    console.log('clickedElement', clickedElement);
    if (!clickedElement.classList.contains('videoModal')) {
      closeModal();
    }
  };

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white z-[99]"
      onClick={handleCloseVideoMdoal}
    >
      <div className="relative flex flex-col w-[79%] h-[70%] max-[1440px]:w-[768px] max-[1440px]:h-[450px] bg-black rounded-lg videoModal">
        <button className="absolute -top-10 right-2 text-white" onClick={closeModal}>
          <img alt="" className="w-11 h-11" src="../../images/icons/close.svg" />
        </button>
        <iframe
          src="https://www.youtube.com/embed/b5_4Z2ChriA?autoplay=1"
          title="YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="absolute top-0 left-0 w-[100%] h-[100%] "
        ></iframe>
      </div>
    </div>
  );
};
