import tw from 'tailwind-styled-components';

import { type BettingPriceValue } from './BettingPadBoard';

const BettingButton = tw.button<{ $isSelected: boolean }>`
  w-full h-11
  rounded-lg
  flex flex-col
  items-center justify-center
  hover:brightness-105 transiation-all
  
  ${({ $isSelected }) =>
    $isSelected
      ? 'bg-gradient-to-b from-indigo-200 via-violet-100 to-indigo-300'
      : ' bg-gradient-to-b from-[#C69BE0] via-[#C69BE0]/80 to-[#C69BE0]'}
`;

type Props = {
  pointValue: BettingPriceValue;
  disabled: boolean;
  isSelected: boolean;
  onClick: () => void;
};

export default function BettingPointButton({ pointValue, disabled, isSelected, onClick }: Props) {
  return (
    <BettingButton
      className={pointValue === 'MAX' || pointValue === 'RESET' ? 'col-span-2' : ''}
      disabled={disabled}
      $isSelected={isSelected}
      onClick={onClick}
    >
      {pointValue}
    </BettingButton>
  );
}
