import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import DrawerMenu from '../DrawerMenu';

import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang'; //'../ lib/hook/useLang';

const Container = tw.div`
  flex flex-col D:flex-row
  w-full
  D:px-[64px]
  D:pt-[64px]
  min-[1440px]:px-[100px]
`;

const NavigationContainer = tw.nav`
  flex flex-row D:flex-col
  items-center D:items-start
  w-full h-[75px] D:w-auto D:h-auto
  border-b border-white/60 D:border-none
  space-x-[16px] D:space-x-0
  px-[16px] D:px-0
  D:ml-[0px]
`;

const NavTitle = tw.div`
  D:w-full
  text-white font-semibold text-[14px] D:text-[24px]
`;

const SectionContainer = tw.section`
  flex-1
  px-[16px] D:px-0 h-full overflow-x-hidden
`;

export default function SideNavLayout({ title, menus }) {
  const { lngT } = useLang('titles');
  const [user] = useUser();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/contact/faq' || pathname === '/community/freeboard' || pathname === '/horse/stake') return;
    if (!user) navigate(`/login?callbackUrl=${pathname}${search}`);
  }, [user, pathname]);

  return (
    <Container>
      <NavigationContainer>
        <NavTitle>{/*title*/ lngT(`${title}:title`)}</NavTitle>
        <div className="flex D:hidden">
          <ChevronRightIcon fill="white" />
        </div>
        <DrawerMenu
          title={title}
          className="w-[150px] D:hidden"
          menus={menus}
          selectedText={lngT(`${title}:${menus.find(menu => pathname.includes(menu.route))?.title}`)}
        />
        <div className="hidden D:flex flex-col border border-white/60 w-[200px] bg-white/60 space-y-[1px] mt-[32px]">
          {menus?.map((menu, i) => (
            <div
              key={i}
              className={`flex flex-row items-center justify-between px-[20px] py-[10px] w-full space-x-2 cursor-pointer ${
                pathname.includes(menu.route) ? 'bg-[#331A43]' : 'bg-[#1C012E]'
              }`}
              onClick={() => navigate(menu.route)}
            >
              <span className="text-white">
                {/*menu.title*/ /*lngT(`myPage:userInfo`)*/ lngT(`${title}:${menu.title}`)}
              </span>
              <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
            </div>
          ))}
        </div>
      </NavigationContainer>
      <SectionContainer>
        <Outlet />
      </SectionContainer>
    </Container>
  );
}
