import { useEffect, useState } from 'react';

import DrawsBG from '../../../../img/powerball/b_betting_bg.png';
import DrawsBGL from '../../../../img/powerball/b_betting_bg_L.png';
import DrawsBGM from '../../../../img/powerball/b_betting_bg_M.png';

import { ReactComponent as FailIcon } from '../../../../img/FailIcon.svg';

import { useLang } from '../../../../lib/hook';

import {
  useIconStore,
  usePowerBallBettingStore,
  usePowerBallResultTrigger,
  usePowerModalStore,
  useTitleStore,
} from '@/store/powerball';
import BettingValuePad from './BettingValuePad';
import FinalBettingButton from './FinalBettingButton';
import BettingPricePad from './BettingPricePad';
import { GoodsTypeServer } from '../../../../define';
import * as define from '../../../../define';
const { webSocketIO } = require('../../../../socketGameClient');

type Props = {
  canBet: boolean;
  rewardOddEven: any;
  goodsType: number;
};

export type BettingPriceValue = 50 | 100 | 300 | 500 | 1000 | 3000 | 5000 | 7000 | 'MAX' | 'RESET';

export default function BettingPadBoard({ canBet, rewardOddEven, goodsType }: Props) {
  const [selectedBettingId, setSelectedBettingId] = useState<number | null>(null);
  const [accumulatedBettingPrice, setAccumulatedBettingPrice] = useState<number>(0);
  const { addTicket } = usePowerBallBettingStore();

  const { openPowerModalPopup } = usePowerModalStore();
  const { setIconComponent } = useIconStore();
  const { setTitle, setPhrase, onSubmit } = useTitleStore();
  const { callTrigger } = usePowerBallResultTrigger();

  const { lngT } = useLang('powerball');

  const handleClickPriceButton = (price: number) => setAccumulatedBettingPrice(state => (state += price));

  const handleClickFinalBetting = () => {
    if (selectedBettingId && accumulatedBettingPrice !== 0) {
      let reqSendParams = define.USE_FREECOIN
        ? JSON.stringify({
            protocol: 'powerball-reqBuyTicket',
            type: 1,
            value: selectedBettingId,
            balls: '[]',
            powerball: 0,
            price: accumulatedBettingPrice,
            coin: GoodsTypeServer[goodsType],
          })
        : JSON.stringify({
            protocol: 'powerball-reqBuyTicket',
            type: 1,
            value: selectedBettingId,
            balls: '[]',
            powerball: 0,
            price: accumulatedBettingPrice,
          });

      webSocketIO.send(reqSendParams);

      // 전역변수에 저장하고
      addTicket({
        id: selectedBettingId,
        price: accumulatedBettingPrice,
        result: null,
        reward: 0,
      });

      //초기화
      setSelectedBettingId(null);
      setAccumulatedBettingPrice(0);
      callTrigger();

      //5.팝업창 열기
      openPowerModalPopup();
      setPhrase('Your betting has been completed');
    } else {
      openPowerModalPopup();
      setIconComponent(<FailIcon />);
      setTitle(/*'Fail'*/ lngT('fail:title'));
      setPhrase(/*Please choose the betting method and bet amount'*/ lngT('fail:phrase1'));
      onSubmit();
    }
  };

  useEffect(() => {
    if (!canBet) {
      setSelectedBettingId(null);
      setAccumulatedBettingPrice(0);
    }
  }, [canBet]);

  return (
    <div className="relative max-[1440px]:space-y-0 h-full w-[430px] flex flex-col items-center justify-center max-[1440px]:w-full">
      <picture className="w-fit h-full absolute top-0 -z-1 object-fit ">
        <source srcSet={DrawsBG} media="(max-width: 500px)" />
        <source srcSet={DrawsBGM} media="(max-width: 984px)" />
        <source srcSet={DrawsBGL} media="(max-width: 1440px)" />
        <source srcSet={DrawsBG} media="(min-width:1441px)" />
        <img className="h-full w-full max-[1440px]:w-full" src={DrawsBG} alt="DrawsBG"></img>
      </picture>
      <div className="absolute top-0 w-[500px] h-[76px] flex flex-row justify-center items-center max-[1440px]:w-full">
        <p className="text-xl font-semibold uppercase text-white">{/*BETTING*/ lngT('betting:pad:betting')}</p>
      </div>
      <div
        className={`${
          !canBet ? 'opacity-50' : ''
        } flex flex-col justify-center items-center w-full px-10 max-[1440px]:px-10 max-[1440px]:pt-[80px] max-[1440px]:pb-10 max-[450px]:px-2 pt-10 pb-2 space-y-3 relative z-10 h-full`}
      >
        <BettingValuePad
          canBet={canBet}
          selectedBettingId={selectedBettingId}
          setSelectedBettingId={setSelectedBettingId}
        />
        <BettingPricePad
          accumulatedBettingPrice={accumulatedBettingPrice}
          handleClickPriceButton={handleClickPriceButton}
          canBet={canBet}
          rewardOddEven={rewardOddEven}
          goodsType={goodsType}
        />
        <FinalBettingButton onClick={handleClickFinalBetting} disabled={!canBet} goodsType={goodsType} />
      </div>
    </div>
  );
}
