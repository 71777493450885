import { type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

const Container = tw.div`
  flex flex-col
  min-h-[1000px]
  D:mx-[64px]
`;

const Header = tw.div`
  hidden D:flex
  flex-row
  T:justify-between items-center
  text-[24px] font-medium text-white
  px-[6px] T:pb-[24px]
`;

const Title = tw.div`
  text-[20px]
`;

const Content = tw.div`
  flex flex-col mt-[12px]
  w-full
`;

type Props = {
  children: ReactNode;
  title: string;
};

export default function ContentLayout({ children, title }: Props) {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
}
