import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import { API_URL } from '@/env';
import useLang from '@/lib/hook/useLang';

import HorseStakeRewardHistoryContainer from './HorseStakeRewardHistoryContainer';
import HorseStakeLoadingUI from '../ui/HorseStakeLoadingUI';
import HorseStakeInfoItemUI from '../ui/HorseStakeInfoItemUI';
import HorseStakeNoItemUI from '../ui/HorseStakeNoItemUI';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.min.css';

import { useDispatch } from 'react-redux';
import { Color } from 'three';
import useSWRImmutable from 'swr/immutable';
var { webSocketIO } = require('../../../../../src/socketClient.js');
type HorseStakeInfoResponse = Res<{ horseStakeInfoItems: HorseStakeInfoItem[] }>;
//var pageIndex : number = 0;
export default function HorseStakeInfoContainer() {
  const { lngT } = useLang('horse:info');
  const [currentStakeHorseId, setCurrentStakeHorseId] = useState<number>(0);
  const { data, isLoading } = useSWRImmutable<HorseStakeInfoResponse>(`${API_URL}/horse/stake/info`);

  // useEffect(()=>{
  //   setCurrentStakeHorseId(pageIndex);
  //   console.log("pageIndex3 : " + currentStakeHorseId);
  // }, [pageIndex]);

  // console.log('horse');
  const [pageIndex, setpageIndex] = useState(0);

  var dispatch = useDispatch();
  // console.log(data);

  if (isLoading) return <HorseStakeLoadingUI />;

  const hasHorse = data?.data?.horseStakeInfoItems?.length !== 0;
  const texture = hasHorse ? data?.data?.horseStakeInfoItems[pageIndex].model.texture : 0;
  const horseid = hasHorse ? data?.data?.horseStakeInfoItems[pageIndex].horse.id : 0;
  const nextRaceDate = hasHorse ? data?.data?.horseStakeInfoItems[pageIndex].horse.nextRaceDate : 0;
  const backid = (horseid! % 2) + 1;
  const inout = nextRaceDate === undefined ? 'out' : 'in';
  const horseLength = data?.data?.horseStakeInfoItems.length as number;
  const horseListNumber = data?.data?.horseStakeInfoItems;
  const horseStakeInfoImte = data?.data?.horseStakeInfoItems?.slice()?.reverse();

  console.log('horseStakeInfoImte', horseStakeInfoImte);

  dispatch({
    type: 'HORSESTALL',
    horsetexture: texture,
    horsepage: `${inout}side_0${backid}`,
  });

  const OnChangePage = (nextPageIdx: number) => {
    const itemLength = data?.data?.horseStakeInfoItems.length as number;
    if (nextPageIdx >= itemLength || nextPageIdx < 0) return;
    setpageIndex(nextPageIdx);
    //pageIndex = nextPageIdx;
    console.log('pageIndex1 : ' + pageIndex);
  };

  const OnChangeBullet = (nextPageIdx: number) => {
    setpageIndex(nextPageIdx);
    //pageIndex = nextPageIdx;
    console.log('pageIndex2 : ' + pageIndex);
  };

  return (
    <div className="relative flex flex-col h-fit rounded-xl D:ml-[64px]">
      <div className="hidden D:flex D:flex-row T:justify-between items-center text-[24px] font-medium text-white T:pb-[24px]">
        <div className="text-[20px]">{lngT('title')}</div>
      </div>
      {data?.data?.horseStakeInfoItems === undefined || data?.data?.horseStakeInfoItems.length === 0 ? (
        <HorseStakeNoItemUI />
      ) : (
        <div>
          <div className="absolute w-full left-0 h-[600px]">
            <div className="absolute bottom-0 left-1/2 -translate-y-[48px] -translate-x-1/2 D:-translate-y-[24px] LD:bottom-1/2 LD:translate-y-[64px] flex flex-row items-center T:w-fit T:space-x-2 z-[90]">
              <ul className="list-style-none flex flex-row items-center space-x-2 max-[330px]:space-x-1">
                {horseListNumber?.map((item, index) => (
                  <li
                    key={index}
                    className={`relative w-2 h-2 rounded-full ${
                      horseListNumber.length >= 20 ? 'max-[360px]:hidden' : ''
                    }`}
                  >
                    <button
                      className={`absolute top-0 left-0 w-2 h-2 rounded-full cursor-pointer ${
                        index === pageIndex ? 'bg-white w-[10px] h-[10px]' : 'bg-white/30 '
                      }`}
                      onClick={() => OnChangeBullet(index)}
                    ></button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="absolute D:top-[580px] top-1/2 -translate-y-1/2 LD:-translate-y-[20px] z-10 w-full flex justify-between">
              <div
                className={`after:hidden bg-black/30 w-[48px] h-[48px] left-0 ${
                  pageIndex === 0 ? '' : 'cursor-pointer'
                }`}
              >
                <img
                  className={`w-full ${pageIndex === 0 ? 'opacity-20' : ''}`}
                  src="../../images/icons/chevron-double-left.svg"
                  onClick={() => {
                    const nextPageIdx = pageIndex - 1;
                    OnChangePage(nextPageIdx);
                  }}
                  alt=""
                />
              </div>
              <div
                className={`after:hidden bg-black/30 w-[48px] h-[48px] right-0 ${
                  pageIndex === horseLength - 1 ? '' : 'cursor-pointer'
                }`}
              >
                <img
                  className={`w-full ${pageIndex === horseLength - 1 ? 'opacity-20' : ''}`}
                  src="../../images/icons/chevron-double-right.svg"
                  onClick={() => {
                    const nextPageIdx = pageIndex + 1;
                    OnChangePage(nextPageIdx);
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
          <HorseStakeInfoItemUI
            item={data?.data?.horseStakeInfoItems?.slice()?.reverse()[pageIndex]}
            modalContent={
              <HorseStakeRewardHistoryContainer
                horseStakeId={data?.data?.horseStakeInfoItems?.slice()?.reverse()[pageIndex].stake.id}
              />
            }
          />
        </div>
      )}
    </div>
  );
}
