import { useEffect, useState } from 'react';

import { LotteryTicket, usePowerBallLotteryStore } from '@/store/powerball';

import LotterySelectorTicketBox from './LotterySelectorTicketBox';

import { ReactComponent as ArrowBigLeftIcon } from '../../../../img/ArrowLeftBigIcon.svg';
import { ReactComponent as ArrowBigRightIcon } from '../../../../img/ArrowRightBigIcon.svg';
import DrawsBG from '../../../../img/powerball/draws_bg.png';
import DrawsBGL from '../../../../img/powerball/draws_bg_L.png';
import DrawsBGM from '../../../../img/powerball/draws_bg_M.png';

import useLang from '../../../../lib/hook/useLang';

type Props = {
  round: number;
  canBet: boolean;
};

export type LottoBox = {
  data?: LotteryTicket;
  type: string;
};

export default function LotteryPadBoard({ round, canBet }: Props) {
  const { selectedTicketList } = usePowerBallLotteryStore();
  const [lottoBoxes, setLottoBoxes] = useState<LottoBox[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { lngT } = useLang('powerball');

  const handlePrevSlide = () => setCurrentIndex(currentIndex - 1);

  const handleNextSlide = () =>
    setCurrentIndex(lottoBoxes.length - currentIndex === 1 ? currentIndex : currentIndex + 1);

  useEffect(() => {
    if (selectedTicketList.length === 0) {
      setCurrentIndex(0);
    }

    const lottoBoxes: LottoBox[] = selectedTicketList.map(item => ({ data: item, type: 'ticket' }));

    setLottoBoxes([...lottoBoxes, { type: 'selector' }]);
  }, [selectedTicketList]);

  return (
    <div className="relative w-fit max-[1440px]:space-y-0 flex flex-col items-center justify-evenly max-[1440px]:w-full h-full">
      <picture className="w-full h-full absolute z-[1] object-fit ">
        <source srcSet={DrawsBG} media="(max-width: 500px)" />
        <source srcSet={DrawsBGM} media="(max-width: 984px)" />
        <source srcSet={DrawsBGL} media="(max-width: 1440px)" />
        <source srcSet={DrawsBG} media="(min-width:1441px)" />
        <img className="h-full w-full max-[1440px]:w-full" src={DrawsBG} alt="DrawsBG"></img>
      </picture>
      <div className="relative w-[430px] h-[76pxd flex flex-row justify-center items-center max-[1440px]:w-full z-10 pt-2">
        {/* {lottoBoxes.length > 0 ? (
          <div className="px-2 absolute w-[430px] h-fit flex flex-row justify-between z-1 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-[1440px]:w-full max-[1440px]:pt-2 max-[768px]:pt-4">
            <div className="w-10 h-10 bg-[#2C386B] rounded-lg relative shadow-lg">
              <ArrowBigLeftIcon
                onClick={handlePrevSlide}
                className="cursor-pointer w-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              />
            </div>
            <div className="w-10 h-10 bg-[#2C386B] rounded-lg relative">
              <ArrowBigRightIcon
                onClick={handleNextSlide}
                className="cursor-pointer w-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-lg"
              />
            </div>
          </div>
        ) : (
          ''
        )} */}
        <div className="flex flex-row justify-center items-center w-[400px] max-[1440px]:w-[90%] max-[1440px]:pt-2 max-[768px]:pt-4">
          {/* <p
            className={`text-base font-medium uppercase pl-8 max-[450px]:text-sm ${
              currentIndex === 0 ? 'text-white/0 ' : 'text-white/70'
            }`}
          >
            {currentIndex === 0 ? '' : currentIndex}Draws
          </p> */}
          {lottoBoxes.length > 0 && (
            <p className="text-xl font-semibold uppercase text-white max-[450px]:text-base">
              {/* {currentIndex + 1}Draws {lottoBoxes[currentIndex].type === 'selector' ? '' : ''} */}
              {selectedTicketList.length + 1}
              {lngT('lottery:pad:draws')}
            </p>
          )}
          {/* {lottoBoxes.length > 0 && (
            <p
              className={`${
                lottoBoxes[currentIndex].type === 'selector' ? 'text-white/70' : 'text-yellow-50'
              } text-base font-medium uppercase pr-8 max-[450px]:text-sm`}
            >
              {currentIndex + 2}Draws
            </p>
          )} */}
        </div>
      </div>
      {lottoBoxes.length > 0 && (
        <LotterySelectorTicketBox round={round} canBet={canBet} lottoBox={lottoBoxes[currentIndex]} />
      )}
    </div>
  );
}
