import { useState, type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

const Container = tw.div`
  relative
  flex flex-col
  w-full
`;

const TabContainer = tw.div`
  relative top-[1px]
  flex flex-row space-x-1
  z-[90]
`;

const TabButton = tw.button<{ $isSelected: boolean }>`
  flex justify-center items-center
  px-3 py-2
  bg-admin-contentBg
  text-white text-sm
  border border-white
  rounded-t-md
  ${({ $isSelected }) => ($isSelected ? 'opacity-100 border-b-metacross-bg' : 'opacity-20 hover:opacity-60 ')}
`;

const ContentContainer = tw.div`
  relative
  w-full
  bg-admin-contentBg
  border border-white
  p-4
  z-[89]
`;

type Props = {
  tabs: {
    title: string;
    value: string;
  }[];
  children: ({ selectedTab }: { selectedTab: string }) => ReactNode;
};

export default function TabManager<T>({ tabs, children }: Props) {
  const [tabItems, setTabItems] = useState<{ title: string; value: string; isSelected: boolean }[]>(
    tabs.map((tab, i) => ({ ...tab, isSelected: !i }))
  );

  const clickTab = (clickedTab: string) => () =>
    setTabItems(tabs.map(tab => ({ ...tab, isSelected: tab.value === clickedTab })));

  return (
    <Container>
      <TabContainer>
        {tabItems.map((tabItem, i) => (
          <TabButton key={i} $isSelected={tabItem.isSelected} onClick={clickTab(tabItem.value)}>
            {tabItem.title}
          </TabButton>
        ))}
      </TabContainer>
      <ContentContainer>
        {children({ selectedTab: tabItems.find(tabItem => tabItem.isSelected)?.value ?? tabItems[0].value })}
      </ContentContainer>
    </Container>
  );
}
