import tw from 'tailwind-styled-components';
import useUser from '../lib/hook/useUser';
import useLang from '@/lib/hook/useLang';

import { useNavigate } from 'react-router-dom';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useTranslation } from 'react-i18next';
const Container = tw.div`
 h-[520px] rounded-lg
   py-5
  bg-black border border-[#641DBE]/30
  transition duration-500 ease-in-out
  group hover:border-[#8E00CB] hover:border-2 hover:bg-[#380758] hover:scale-105 hover:z-[90] cursor-pointer

`;

const ImgBox = tw.img`
  relative
  w-[180px] h-[220px]
  object-[top-left] object-fill
`;

const TitleText = tw.p`
  text-sm text-white/40 tracking-wider
`;

const ContentText = tw.p`
  text-base text-white/80 font-medium
`;

const UnitText = tw.p`
  pl-1 text-xs T:text-sm text-white/60 font-light
`;

const InnerText = tw.p`
  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
  text-lg text-white
`;

const RentBtn = tw.button`
  flex justify-center items-center
  w-[130px] h-[40px]
  bg-[#641DBE]
  text-white
  cursor-pointer
  rounded-lg
`;

// const SoldOutImg = tw.div`
//   flex justify-center items-center
//   w-[130px] h-[40px]
//   text-red-500
//   bg-[#222222]
// `;

const SoldOutImg = tw.div`
  relative
  w-[180px] h-[220px]
  object-[top-left] object-fill
`;

const SoldOutImgBox = tw.img`
  absolute
  object-fill
  w-[220px] h-[220px]
  
`;

// w-[220px] h-[220px]
// object-[center-left] object-fill

type Props = {
  stakeType: StakeType;
  onClick: () => void;
};

export default function StakeHorseOptionItem({ stakeType, onClick }: Props) {
  const [user] = useUser();
  const { lngT } = useLang('horse:stake');
  const { i18n } = useTranslation();
  const navigate = useCustomNavigate();
  const onClickUserOrNot = user ? onClick : () => navigate('/login');

  return (
    <Container
      className="flex-shrink D:px-3 px-10 mb-10 max-[400px]:w-[300px] scale-100 T:scale-90 D:scale-95 LD:scale-110 LD:hover:scale-[1.15]"
      // onClick={onClickUserOrNot}
    >
      <div className="flex flex-col justify-center items-center space-y-3">
        <div className="relative flex flex-col justify-center items-center gap-5">
          <TitleText
            className={`border rounded-full px-2 py-1 first-line:text-xl text-center text-white/80 font-oswald min-[1880px]:w-[100px] w-[90px] ${
              stakeType.title === 'Regular'
                ? ' text-amber-600/60 border-amber-600/60'
                : stakeType.title === 'Siver'
                ? 'text-gray-400 border-gray-400'
                : stakeType.title === 'Gold'
                ? ' text-yellow-500 border-yellow-500'
                : ''
            }`}
          >
            {stakeType.title}
          </TitleText>
          {/* <ImgBox src={`/images/soldout_${stakeType.id}${i18n.language}.png`}></ImgBox>           */}
          <ImgBox src={`/images/soldout_${stakeType.id}_${i18n.language}.png`}></ImgBox>          
          
          {/* <ImgBox src={`/images/lease_${stakeType.id}.png`}></ImgBox>           */}
          {/* <SoldOutImgBox src={`/images/soldout.png`}></SoldOutImgBox> */}
          {/* <InnerText className="absolute">{stakeType.id}</InnerText> */}
        </div>
        <div className="w-full flex flex-col justify-center items-center space-y-2">
          <div className="w-[280px] flex flex-row items-center justify-center space-x-4 rounded-lg py-1 space-y-2">
            <div className="flex items-center">
              <ContentText className="font-medium min-[1880px]:text-3xl text-2xl text-white">
                {stakeType.stakePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </ContentText>
              <UnitText className="text-lg">{/*points*/ lngT('points')}</UnitText>
            </div>
          </div>

          <div className="text-white text-[10px] leading-[5px] pb-2">{`( 100 ${lngT(
            'pointsWithCapital'
          )} = 1 USD )`}</div>
          <div className="flex flex-col items-center justify-between">
            <div className="flex flex-row items-start space-x-2">
              <TitleText>{/*Performance*/ lngT('performance')}</TitleText>
              <div className="flex items-center">
                <ContentText>
                  {Math.floor(((stakeType.returnPoint - stakeType.stakePoint) * 100) / stakeType.stakePoint)}
                </ContentText>
                <UnitText>%</UnitText>
              </div>
            </div>
            <div className="flex flex-row items-start space-x-2">
              <TitleText>{/*Reward*/ lngT('reward')}</TitleText>
              <div className="flex items-center">
                <ContentText>{stakeType.returnPoint.toLocaleString()}</ContentText>
                {/* <UnitText className="text-xs">points</UnitText> */}
              </div>
            </div>
          </div>
        </div>
        {/* {user ? (
          <RentBtn onClick={onClick}>{ lngT('lease')}</RentBtn>
        ) : (
          <RentBtn onClick={() => navigate('/login')}> { lngT('lease')} </RentBtn>
        )} */}

        {/* <SoldOutImg>{lngT('soldout')}</SoldOutImg> */}
        {/* <SoldOutImg src={`/images/lease_${stakeType.id}.png`}></SoldOutImg> */}
      </div>
    </Container>
  );
}
