import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from '../../img/CloseIcon.svg';
import useLang from '../../lib/hook/useLang';
import ResultBg from '../../img/powerball/b_result.png';
import { useSelector } from 'react-redux';

import { Goods, GetCoinType } from '../../components/gameui/goods';
// import { getCookie, setCookie } from '../../lib/cookie'

// let powerballinfo = null;
let ballResult = [];

export default function BettingResultModal({ isOpen, round, rewardOddEven }) {
  const { lngT } = useLang('powerball');
  const { lngT : lngTetc } = useLang('etcs');
  const whiteballStr = lngT('lottery:pad:whiteball');
  const powerballStr = lngT('lottery:pad:powerball');
   
  const [ballResultView,setballResultView] = useState([]);
  const [totalReward,setTotalReward] = useState(0);
  const modalStyle = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.4)',
      zIndex: 1000,
    },
    content: {
      zIndex: 1001,
    },
  };

  const Powerball = useSelector(state => state.Powerball);
  const coinType = GetCoinType();
  useEffect(() => {
    // powerballinfo = getCookie('prevgameinfo');
    // powerballinfo = powerballinfo === "null" ? null : powerballinfo;

    let ballTotal = 0;
    for (let i = 0; i < Powerball.ball.length; ++i) {
      ballTotal += Powerball.ball[i];
      ballResult[0] = ballTotal % 2 === 0 ? 'E' : 'O';
      ballResult[1] = ballTotal <= 77 ? 'U' : 'O';
      ballResult[2] = ballTotal <= 69 ? 'S' : ballTotal <= 85 ? 'M' : 'L';
    }

    let ballResultViewTmp = [];
    for (let i = 0; i < ballResult.length; ++i) {
      ballResultViewTmp.push(
        <div className="w-7 h-7 flex items-center justify-center rounded-full bg-white text-black border border-[#eee] max-[600px]:text-sm max-[600px]:w-4 max-[600px]:h-4">
          {ballResult[i]}
        </div>
      );
      setballResultView(ballResultViewTmp);
      // console.log('count : ' + ballResult.length);
    }

    setTotalReward(rewardOddEven?.reduce((total, item) => total + item.reward, 0));
  }, []);

  useEffect(() => {
    if(Powerball)
    {
      // powerballinfo = getCookie('prevgameinfo');
      // powerballinfo = powerballinfo === "null" ? null : powerballinfo;
      // if(powerballinfo)
      // console.log('gameframe : ' + powerballinfo.gameframe + ' ' + Powerball.gameframe);
    }
  }, [Powerball]);  

  // const powerBallResult = 'O';
  rewardOddEven.sort((a, b) => a.userBall.localeCompare(b.userBall));
  return (
    <Modal
      // isOpen={isBetting ? isOpen : false}
      isOpen={isOpen}
      className="absolute w-[800px] h-[600px] max-[900px]:w-[90%] max-[900px]:h-[500px] max-[600px]:h-[420px] max-[450px]:h-[320px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-3xl"
      style={modalStyle}
    >
      <img alt="bg" src={ResultBg} className="w-full h-full absolute top-0 left-0 object-fit" />
      {/* <button onClick={closeResultPopup} className="absolute -top-9 right-0 w-10 h-10 z-[99]">
        <CloseIcon className="w-5 h-5" />
      </button> */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex flex-col justify-start py-2 max-[900px]:py-2 max-[600px]:py-1 items-center w-full h-[100%] space-y-6 max-[450px]:space-y-1 pb-[80px] max-[450px]:w-[90%]">
        <h2 className="text-white text-3xl font-medium max-[900px]:text-xl max-[786px]:text-lg max-[500px]:text-base max-[450px]:text-sm pb-1">
          Result
        </h2>
        <div className="flex flex-col justify-start items-center  text-white h-full max-[450px]:h-fit">
          <div class="absoulte top-0 z-10 overflow-x-auto w-full h-full flex flex-col items-center justify-center my-8 space-y-4 max-[600px]:space-y-2 max-[600px]:my-3 max-[450px]:space-y-2 max-[450px]:my-2 custom-scrollbar">
            <div className="flex flex-row gap-2">
              <div className="flex flex-row gap-2">
                {ballResultView}
              </div>
              <div className="w-7 h-7 flex items-center justify-center rounded-full bg-violet-400 border border-[#eee] max-[600px]:text-sm max-[600px]:w-4 max-[600px]:h-4">
                {Powerball && (Powerball.powerBall % 2 === 0 ? 'E' : 'O')}
              </div>
            </div>
            <table class="w-full text-sm max-[555px]:text-xs text-left rtl:text-right text-white font-normal space-y-2 max-[450px]:space-y-0">
              <thead class="flex flex-row uppercase text-white/80 pb-4 max-[600px]:pb-2">
                <tr>
                  {/* <th scope="col" class="px-8 py-2 max-[680px]:px-4 max-[555px]:px-2">
                    Round
                  </th> */}
                  <th
                    scope="col"
                    class="text-center w-[120px] max-[680px]:w-[120px] py-2 max-[680px]:px-4 max-[555px]:px-2 "
                  >
                    Bet
                  </th>
                  <th
                    scope="col"
                    class="text-center w-[120px] max-[680px]:w-[60px] py-2 max-[680px]:px-4 max-[555px]:px-2"
                  >
                    Result
                  </th>
                  <th
                    scope="col"
                    class="text-center w-[120px] max-[680px]:w-[120px] py-2 max-[680px]:px-4 max-[555px]:px-2"
                  >
                    Reward
                  </th>
                </tr>
              </thead>
              {rewardOddEven &&
                rewardOddEven?.map((items, i) => {
                  return (
                    <tbody>
                      <tr class="flex flex-row justify-evenly border-b border-white/20 w-full max-[555px]:text-xs">
                        <td class="w-[120px] max-[680px]:w-[120px] py-2 max-[680px]:px-4 max-[555px]:px-2 flex flex-row justify-center items-center gap-2 font-medium">
                          <div className="flex flex-col items-center justify-center text-white">{items.userBall}</div>
                          <div className="w-6 h-6 max-[680px]:w-5 max-[680px]:h-5 max-[555px]:w-4 max-[555px]:h-4 rounded-full flex flex-col items-center justify-center border border-purple-400 bg-purple-400/10 text-white roudned-full bg-purple-400">
                            {items.userOdd}
                          </div>
                        </td>
                        <td class="w-[120px] max-[680px]:w-[120px] py-2 max-[680px]:px-4 max-[555px]:px-1 text-center text-white flex flex-row justify-center items-center gap-2 max-[555px]:gap-1 ">
                          <div className="flex flex-col items-center justify-center text-white">{items.userBall}</div>
                          <div className="w-6 h-6 max-[680px]:w-5 max-[680px]:h-5 max-[555px]:w-4 max-[555px]:h-4 rounded-full flex flex-col items-center justify-center border border-purple-400 bg-purple-400/10 text-white uppercase roudned-full bg-purple-400 max-[555px]:px-0">
                            {items.userBall === powerballStr
                              ? items.resultOdd[1]
                              : (items.userBall === whiteballStr && items.userOdd === 'O') || items.userOdd === 'E'
                              ? items.resultOdd[0][0]
                              : items.userOdd === 'U' || items.userOdd === 'o'
                              ? items.resultOdd[0][1]
                              : items.userOdd === 'S' || items.userOdd === 'M' || items.userOdd === 'L'
                              ? items.resultOdd[0][2]
                              : null}
                          </div>
                        </td>
                        <td class="w-[120px] max-[680px]:w-[120px]  py-2 max-[680px]:px-4 max-[555px]:px-2 text-center">
                          {true ? (
                            <div className="flex flex-row items-center justify-center gap-1">
                              <Goods goodsType={coinType} size={5} />
                              {items.reward}
                            </div>
                          ) : (
                            <div>{items.reward}</div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
            <div className="flex flex-row pt-10 max-[600px]:pt-2">
              {rewardOddEven && (
                <div>
                  <div className="flex flex-col items-center justify-center space-y-3 max-[600px]:space-y-1 ">
                    <div className="text-base max-[600px]:text-sm max-[450px]:text-xs">{lngTetc('common:totalreward')}</div>
                  </div>
                </div>
              )}
            </div>
            {/* <strong className="text-lg font-medium text-yellow-400 max-[600px]:text-sm max-[450px]:text-xs">                  
              <div className="flex flex-row items-center justify-center gap-1"></div>
                <Goods goodsType={coinType} size={5} /> {rewardOddEven?.reduce((total, item) => total + item.reward, 0)}
            </strong> */}
            <div className="flex flex-row items-center justify-center gap-1">
                <Goods goodsType={coinType} size={5} />
                {totalReward}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
