'use client';

import { useState } from 'react';
import styles from './faq.module.css';

import useLang from '@/lib/hook/useLang';

const FAQSection = () => {
  const { lngT } = useLang('landingPage:faq');
  const [active, setActive] = useState<number | null>(null);

  const handleClick = (index: number) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const faqItems = [
    {
      question: lngT('faq:items:1:question'),
      answer: lngT('faq:items:1:answer'),
    },
    {
      question: lngT('faq:items:2:question'),
      answer: lngT('faq:items:2:answer'),
    },
    {
      question: lngT('faq:items:3:question'),
      answer: lngT('faq:items:3:answer'),
    },
    {
      question: lngT('faq:items:4:question'),
      answer: lngT('faq:items:4:answer'),
    },
    {
      question: lngT('faq:items:5:question'),
      answer: lngT('faq:items:5:answer'),
    },
    {
      question: lngT('faq:items:6:question'),
      answer: lngT('faq:items:6:answer'),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3>
          {lngT('faq:title')}
          <br />
          <span>{lngT('faq:subTitle')}</span>
        </h3>
      </div>

      <div className={styles.faqContainer}>
        {faqItems.map((item, index) => (
          <div key={index} className={styles.content} onClick={() => handleClick(index)}>
            <div className={styles.description}>
              <div className={styles.number}>0{index + 1}</div>
              <div className={styles.title}>{item.question}</div>
              <button className={active === index ? styles.buttonActive : styles.buttonDisable}>
                {active === index ? '-' : '+'}
              </button>
            </div>
            <div className={active === index ? styles.active : styles.answer}>{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
