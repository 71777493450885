import React from 'react';
import useSWR from 'swr';

import useLang from '@/lib/hook/useLang';

import tw from 'tailwind-styled-components';

const InputContainer = tw.div`
  flex flex-col space-y-3
`;

const Label = tw.label`
 text-white font-sm`;

const PointSection = tw.div`
  h-10 w-full rounded-md
  bg-white/10
  text-white/100 text-sm font-light
  pr-[66px]
  flex flex-row items-center justify-end
 "
`;

export default function UserPoints() {
  const { lngT } = useLang('horse:stake');
  const { data, isLoading, error, mutate } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  return (
    <div>
      <InputContainer className="flex flex-col">
        <Label>{/*My Points*/ lngT('mypoints')}</Label>
        <div className="relative">
          <PointSection className="" type="text" disabled>
            <p className="text-right">{(data?.coin ?? 0).toLocaleString()}</p>
            <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-50 text-white/80 text-[12px]">
              {lngT('points')}
            </div>
          </PointSection>
        </div>
      </InputContainer>
    </div>
  );
}
