import React, { useEffect, useState } from 'react';
import axios from 'axios';
import tw from 'tailwind-styled-components';

import useUser from '../lib/hook/useUser';

import { NEED_REFRESH_STORE_NAME } from '../config';
import { useLang } from '@/lib/hook';
import { revalidateEvents } from 'swr/_internal';

const CancelButton = tw.button`
  bg-white
  text-[#860099]
  w-[104px] h-[40px]
  rounded-md
  hover:opacity-70
`;

const RefreshButton = tw.button`
  bg-[#860099]
  text-white
  w-[104px] h-[40px]
  rounded-md
  hover:opacity-70
`;

export default function RevalidateToken() {
  const { lngT } = useLang('etcs');
  const [user, { login }] = useUser();
  const [noNeedRefresh, setNodNeedRefresh] = useState(false);

  const handleCancel = () => {
    localStorage.setItem(NEED_REFRESH_STORE_NAME, true);
    setNodNeedRefresh(true);
  };

  const handleRefresh = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/revalidate`, { withCredentials: true });

      if (data.ok) {
        login(data.accessToken);
      }
      setNodNeedRefresh(false);
      localStorage.removeItem();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const needRefresh = localStorage.getItem(NEED_REFRESH_STORE_NAME);

    if (JSON.stringify(needRefresh) === true) {
      setNodNeedRefresh(true);
    }
  }, []);

  if (!user) return null;

  if (!user?.needRefresh) return null;

  if (noNeedRefresh) return null;

  if (user?.needRefresh) {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black/50 space-x-5 z-[99]">
        <div className="flex flex-col items-center px-10 py-7 rounded-lg border border-white space-y-5 bg-black/70 mx-[10px]">
          <div className="text-white text-center">
            {/* Less than 10 minutes  */ lngT('revalidateToken:1')}
            <br /> {/* Would you like*/ lngT('revalidateToken:2')}
          </div>
          <div className="flex w-fit flex-row space-x-2">
            <CancelButton onClick={handleCancel}> {/* cancel  */ lngT('revalidateToken:Cancel')}</CancelButton>
            <RefreshButton onClick={handleRefresh}> {/* refresh  */ lngT('revalidateToken:Refresh')}</RefreshButton>
          </div>
        </div>
      </div>
    );
  }
}
