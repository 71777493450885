import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import { useEffect, useRef, useState } from 'react';
import ChargeHistory from './ChargeHistory';
import WithdrawalHistory from './WithdrawalHistory';
import useLang from '../lib/hook/useLang';

const CoinTypeBtn = tw.button`
  flex justify-center items-center
  flex-1 h-[40px]
  bg-white/10
  text-white text-sm
  border-opacity-80
  rounded-md
  border-2 ${({ $isSelected }) => ($isSelected ? 'border-white/80' : 'border-transparent')}
`;

export default function AccountHistory() {
  const { lngT } = useLang('mypage:account');
  const [historyType, setHistoryType] = useState('charge');
  const scrollerContainerRef = useRef(null);
  const [scrollerWidth, setScrollerWidth] = useState(0);

  useEffect(() => {
    if (!scrollerContainerRef.current) return;
    setScrollerWidth(scrollerContainerRef.current?.clientWidth);
  }, [scrollerContainerRef.current]);

  return (
    <div className="space-y-8">
      <div ref={scrollerContainerRef} className="w-full">
        <div className="flex flex-row space-x-6">
          <CoinTypeBtn $isSelected={historyType === 'charge'} onClick={() => setHistoryType('charge')}>
            {lngT('history:charge')}
          </CoinTypeBtn>
          <CoinTypeBtn
            disabled
            className="opacity-40"
            $isSelected={historyType === 'withdrawal'}
            onClick={() => setHistoryType('withdrawal')}
          >
            {lngT('history:withdrawal')} ({lngT('history:preparing')})
          </CoinTypeBtn>
        </div>
        <div className={`flex pt-[20px] overflow-x-auto ${scrollerWidth ? `w-[${scrollerWidth}px]` : 'w-0'}`}>
          <div>{historyType === 'charge' ? <ChargeHistory /> : <WithdrawalHistory />}</div>
        </div>
      </div>
    </div>
  );
}
