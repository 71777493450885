import useSWR from 'swr';
import { useEffect, useState } from 'react';
import useLang from '../../../../lib/hook/useLang';

import {
  LotteryTicket,
  useIconStore,
  usePowerBallLotteryStore,
  usePowerModalStore,
  useTitleStore,
} from '@/store/powerball';

import { LottoBox } from './LotteryPadBoard';
import BallSelectorBox from './LotteryBallSelectorBox';
import LotteryTicketCard from './LotteryTicketCard';
import LotteryTicketIssueButton from './LotteryTicketIssueButton';

import { ReactComponent as FailIcon } from '../../../../img/FailIcon.svg';

const { webSocketIO } = require('../../../../socketGameClient');

type Props = {
  round: number;
  canBet: boolean;
  lottoBox: LottoBox;
};

const shuffleArray = (array: number[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const getRandomNumberArray = (totalLength: number, pickLength: number): number[] => {
  const allNumbers = Array.from({ length: totalLength }, (_, index) => index + 1);
  const shuffledNumbers = shuffleArray(allNumbers);

  return shuffledNumbers.slice(0, pickLength);
};

export default function LotterySelectorTicketBox({ round, canBet, lottoBox }: Props) {
  const { selectedTicketList, addTicket } = usePowerBallLotteryStore();
  const { openPowerModalPopup } = usePowerModalStore();
  const { setIconComponent } = useIconStore();
  const { setTitle, setPhrase, onSubmit } = useTitleStore();
  const [balls, setBalls] = useState<number[]>([]);
  const [powerBall, setPowerBall] = useState<number[]>([]);

  const { lngT } = useLang('powerball');
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  const selectBall = (ball: number) => {
    if (balls.length < 5) {
      setBalls(balls.includes(ball) ? balls.filter(item => item !== ball) : [...balls, ball]);
    } else {
      if (balls.includes(ball)) {
        setBalls(balls.filter(item => item !== ball));
      }
    }
  };

  const selectPowerBall = (ball: number) => setPowerBall(powerBall.includes(ball) ? [] : [ball]);

  const generateRandomBall = () => setBalls(getRandomNumberArray(30, 5));

  const generateRandomPowerBall = () => setPowerBall(getRandomNumberArray(10, 1));

  const resetSelectorBox = () => {
    setBalls([]);
    setPowerBall([]);
  };

  const issueTicket = () => {
    if (balls.length === 5 && powerBall.length === 1) {
      if(balance?.data.coin < 100)
      {
        alert('you need to charge you point');
        return;
      }

      const powerball = powerBall[0];

      webSocketIO.send(
        JSON.stringify({
          protocol: 'powerball-reqBuyTicket',
          type: 0,
          value: 0,
          balls: JSON.stringify(balls),
          powerball,
          price: 100,
        })
      );

      const ticket: LotteryTicket = {
        balls,
        powerBall: powerball,
        price: 100,
      };
      addTicket(ticket);
      resetSelectorBox();
      openPowerModalPopup();
    } else {
      openPowerModalPopup();
      setIconComponent(<FailIcon />);
      setTitle(/*'Fail'*/ lngT('fail:title'));
      setPhrase(/*You need to pick ball more'*/ lngT('fail:phrase2'));
      onSubmit();
    }
  };

  useEffect(() => {
    if (lottoBox.data) {
      setBalls([]);
      setPowerBall([]);
    } else {
      setBalls([]);
      setPowerBall([]);
    }
  }, [lottoBox]);

  return (
    <div
      className={`${
        canBet ? '' : 'opacity-50'
      } flex flex-col justify-center items-center w-full max-[1440px]:px-10 max-[450px]:px-2 pt-3 pb-1  max-[1440px]:pt-10 max-[1440px]:pb-8  z-10`}
    >
      <div
        className={`${
          lottoBox.data === undefined ? 'pb-4' : 'pb-4'
        } relative flex flex-col items-center justify-center space-y-0 max-[1440px]:w-full`}
      >
        <BallSelectorBox
          canBet={canBet}
          type="ball"
          title=/*"White Ball"*/ {lngT('lottery:pad:whiteball')}
          selectedBalls={balls}
          selectBall={selectBall}
          generateRandomNumber={generateRandomBall}
        />
        <BallSelectorBox
          canBet={canBet}
          type="powerball"
          title=/*"PowerBall"*/ {lngT('lottery:pad:powerball')}
          selectedBalls={powerBall}
          selectBall={selectPowerBall}
          generateRandomNumber={generateRandomPowerBall}
        />
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        <LotteryTicketIssueButton canBet={canBet} onClick={issueTicket} />        
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        {/* <LotteryTicketIssueButton canBet={canBet} onClick={issueTicket} /> */}
        <LotteryTicketCard />
      </div>                  
    </div>
  );
}
