import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';

import TextInputWrapper from './TextInputWrapper';
import TextNavLinker from './TextLinker';
import PrimaryButton from './PrimaryButton';

import useUser from '../lib/hook/useUser';
import useLang from '../lib/hook/useLang';
import { useLocation } from 'react-router-dom';

export default function EmailLoginForm({}) {
  const { lngT: passwordLngT } = useLang('auth:password');
  const { lngT: loginLngT } = useLang('auth:login');

  const [_, { login, updateUser }] = useUser();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const hasInputError = !!Object.keys(errors).length;
  const location = useLocation();

  const onSubmit = async payload => {
    setLoading(true);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, payload);
      if (!data.ok) {
        return toast(data.message, { type: 'error', theme: 'dark' });
      }
      const { accessToken } = data;
      login(accessToken);
      toast(loginLngT('login:2:toast:1'), { theme: 'dark', type: 'success' });
      updateUser();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col space-y-[40px] mb-[35px]">
        <TextInputWrapper
          title={loginLngT('login:1:email')}
          isEmpty={!watch('email')}
          errorText={errors.email?.message}
        >
          <input
            type="text"
            {...register('email', {
              required: loginLngT('login:3:error:1'),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                message: loginLngT('login:3:error:2'),
              },
            })}
            placeholder={loginLngT('login:1:type')}
            autoComplete="off"
          />
        </TextInputWrapper>
        <TextInputWrapper title={passwordLngT('title:1')} isEmpty={!watch('password')}>
          <input
            type="password"
            {...register('password', { required: passwordLngT('type:3') })}
            placeholder={passwordLngT('type:1')}
            autoComplete="off"
          />
        </TextInputWrapper>
      </div>
      <div className="flex flex-row w-full justify-end mb-[20px]">
        <TextNavLinker
          text={/*Forgot your*/ passwordLngT('forgot:smallTitle')}
          href={`/password/forgot${location.search}`}
          hrefText={/*Password*/ passwordLngT('forgot:smallTitle_1')}
          className="text-[12px]"
        />
      </div>
      <PrimaryButton
        type="submit"
        title={isLoading ? loginLngT('button:2') : loginLngT('button:1')}
        isLoading={isLoading}
        error={hasInputError}
      />
    </form>
  );
}
