import useSWR from 'swr';

import { API_URL } from '@/env';

import HorseStakeRewardHistoryItemUI from '../ui/HorseStakeRewardHistoryItemUI';
import { useModalStore } from '@/lib/custom-lib/modal';
import { useState } from 'react';

type Props = {
  horseStakeId: number;
};
type HorseStakeRewardHistoryResponse = {
  data: { rewardHistoryList: HorseStakeRewardHistoryItem[]; total: number; prevAcc: number };
};

export default function HorseStakeRewardHistoryContainer({ horseStakeId }: Props) {
  const [currentPage, setCurrentPage] = useState(1);
  // console.log('currentPage : ' + currentPage);
  const { data } = useSWR<HorseStakeRewardHistoryResponse>(
    `${API_URL}/horse/stake/reward-history?horseStakeId=${horseStakeId}&page=${currentPage}`
  );
  console.log('horse reward-history data: ', data);
  const rewardHistoryItems = data?.data.rewardHistoryList || [];
  const total = data?.data.total || 0;
  const prevAcc = data?.data.prevAcc || 0;

  const { closeModal } = useModalStore();

  const handleCloseModal = () => {
    closeModal();
  };
  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="absolute z-[90] w-[90%] T:w-[85%] D:w-[80%] h-[70%] max-D:min-h-fit LD:w-[60%] top-1/2 -translate-y-1/2 max-[900px]:landscape:overflow-scroll ">
      <HorseStakeRewardHistoryItemUI
        rewardHistoryItems={rewardHistoryItems}
        total={total}
        prevAcc={prevAcc}
        itemsPerPage={10}
        handleCloseModal={handleCloseModal}
        handleChangePage={handleChangePage}
      />
    </div>
  );
}
