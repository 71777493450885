/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/lottomachine_background.glb 
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Model_Red(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/powerball/background_red.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(()=>{
    let action = actions["Animation"];    

    if(action !== undefined)
    {
      action.play();     
    }
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh name="red_01_01" geometry={nodes.red_01_01.geometry} material={materials.red_256_01_001} position={[0, 0.945, -0.325]} />
        <mesh name="red_01_02" geometry={nodes.red_01_02.geometry} material={materials.red_256_01_002} position={[0.039, 0.945, 0.187]} />
        <mesh name="red_02_01" geometry={nodes.red_02_01.geometry} material={materials.red_256_01_001} position={[0.039, 0.945, 0.187]} />
        <mesh name="red_02_02" geometry={nodes.red_02_02.geometry} material={materials.red_256_01_002} position={[0, 0.945, -0.325]} />
        <mesh name="red_03_01" geometry={nodes.red_03_01.geometry} material={materials.red_256_01_001} position={[0.039, 0.945, 0.187]} />
        <mesh name="red_03_02" geometry={nodes.red_03_02.geometry} material={materials.red_256_01_002} position={[0, 0.945, -0.325]} />
        <mesh name="red_04_01" geometry={nodes.red_04_01.geometry} material={materials.red_256_01_001} position={[0.039, 0.945, 0.187]} />
        <mesh name="red_04_02" geometry={nodes.red_04_02.geometry} material={materials.red_256_01_002} position={[0, 0.945, -0.325]} />
        <mesh name="red_line" geometry={nodes.red_line.geometry} material={materials.red_256_01} position={[0.039, 0.945, 0.187]} />
        <mesh name="red_main" geometry={nodes.red_main.geometry} material={materials.red_256_01} position={[0.039, 0.945, 0.187]} />
      </group>
    </group>
  )
}

// useGLTF.preload('/powerball/background_red.glb')
