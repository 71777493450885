import { makeStyles } from '@mui/styles';
import { NonceProvider } from 'react-select';

export const useBettingStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    //background:'linear-gradient(90deg, #420a64 0%,#060015 50%, #420a64 100%)',
    boxSizing: 'border-box',
    '& .MuiTabPanel-root': {
      padding: 10,
    },
  },
  container: {},
  timeStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0 32px',
    color: '#fff',
    '& svg': {
      width: 20,
      animation: '$vibration .1s infinite',
      '& path': {
        fill: '#af99ca',
      },
    },
    '& span': {
      color: '#af99ca',
      fontSize: '1rem',
      margin: '0 6px',
    },
  },
  progressBar: {
    color: 'success',
  },

  '@keyframes vibration': {
    from: {
      transform: 'rotate(6deg)',
    },
    to: {
      transform: 'rotate(-6deg)',
    },
  },

  '@keyframes blinkEffect': {
    '50%': {
      opacity: '0',
    },
  },

  tabListStyle: {
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      '& button': {
        minWidth: 120,
        minHeight: 40,
        color: '#f0d264',
        borderRadius: '12px 0 12px 0',
        border: '1px solid #f0d264',
        backgroundColor: '#0A0119',
        marginLeft: 20,
        fontSize: '1rem',
        '&:first-child': {
          marginLeft: 0,
          color: '#03c8a8',
          borderColor: '#03c8a8',
          '&.Mui-selected': {
            backgroundColor: '#03c8a8',
          },
        },
        '&:nth-child(2)': {
          color: '#ff9700',
          borderColor: '#ff9700',
          '&.Mui-selected': {
            backgroundColor: '#ff9700',
          },
        },
        '&:nth-child(3)': {
          color: '#00acee',
          borderColor: '#00acee',
          '&.Mui-selected': {
            backgroundColor: '#00acee',
          },
        },
        '&.Mui-selected': {
          color: '#fff',
          fontWeight: 'bold',
        },
        '@media all and (max-width: 510px)': {
          minWidth: 80,
          marginLeft: 12,
        },
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  selectStyle: {
    marginTop: 60,
    marginBottom: 20,
    width: '100%',
    color: '#1f073f',
    backgroundColor: '#f0d264',
    textAlign: 'center',
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      fontWeight: 700,
      minHeight: '1rem!important',
    },
    '&.MuiInputBase-root': {
      borderRadius: 0,
    },
    '& .MuiSelect-select, svg': {
      color: '#1f073f',
    },
    '& fieldset': {
      border: '0 none',
    },
  },
  textBoxStyle: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '1.125rem',
    marginBottom: 40,
  },
  pStyle: {
    fontSize: '1.25rem',
    '& b': {
      fontSize: '1.5rem',
      color: '#EAD40C',
      marginLeft: 8,
    },
  },
  tableStyle: {
    marginTop: 20,
    border: '2px solid #ffffff6b',
    borderWidth: '2px 0',
    '& .MuiTableHead-root th': {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '0.938rem',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.063rem',
      color: '#fff',
      borderColor: 'rgba(224, 224, 224, 0.6)',
    },
  },

  // MuiDialogContent-root

  dialogStyle: {
    '& .MuiPaper-root': {
      background: '#060015',
      border: '1px solid #af99ca',
      color: '#fff',
    },
    '& .MuiDialogContent-root': {
      fontSize: '1.5rem',
      lineHeight: '1.75',
    },
    '& .MuiDialogActions-root': {
      padding: '12px 8px 24px 8px',
      justifyContent: 'space-around',
      '& > button': {
        textTransform: 'none',
        fontSize: '1.125rem',
        borderRadius: '12px 0 12px',
      },
    },
  },
  btnConfirmStyle: {
    '&.MuiButton-root': {
      color: '#fff',
      borderColor: '#8734ec',
      backgroundColor: '#8734ec',
      '&:hover': {
        backgroundColor: '#8734ec',
        borderColor: '#8734ec',
      },
    },
  },
  btnCancelStyle: {
    '&.MuiButton-root': {
      borderColor: 'transparent',
      color: '#8734ec',
      '&:hover': {
        borderColor: 'transparent',
      },
    },
  },

  // 테이블 컬러
  mint: {
    '& > th, td': {
      color: '#03c8a8!important',
    },
  },
  orange: {
    '& > th, td': {
      color: '#ff9700!important',
    },
  },
  blue: {
    '& > th, td': {
      color: '#00acee!important',
    },
  },

  numberStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    marginRight: 0,
    fontSize: '0.875rem',
    borderRadius: 50,
    color: '#fff',
    backgroundColor: '#718f46',
    // boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
    '@media (min-width:518px) and (max-width:1024px)': {
      flexWrap: 'wrap',
      marginTop: 0,
      width: 28,
      height: 28,
    },
    '@media (min-width:260px) and (max-width:517px)': {
      marginTop: 0,
      fontSize: '0.75rem',
      width: 22,
      height: 22,
    },
  },
  numOne: {
    backgroundColor: '#fac02a',
    // boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
  },
  numTwo: {
    backgroundColor: '#9c9c9c',
    // boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
  },
  numThree: {
    backgroundColor: '#855845',
    // boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
  },

  numberStyleRank: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    marginRight: 0,
    fontSize: '0.875rem',
    borderRadius: 50,
    color: '#fff',
    backgroundColor: '#546739',
    // boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',

    '@media (min-width:518px) and (max-width:1440px)': {
      flexWrap: 'wrap',
      marginTop: 0,
      width: 24,
      height: 24,
    },
    '@media (min-width:260px) and (max-width:517px)': {
      flexWrap: 'wrap',
      marginTop: 0,
      fontSize: '0.75rem',
      width: 18,
      height: 18,
    },
  },
  numOneRank: {
    backgroundColor: '#DFA100',
    boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
  },
  numTwoRank: {
    backgroundColor: '#7E7E7E',
    boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
  },
  numThreeRank: {
    backgroundColor: '#734A39',
    boxShadow: '4px 4px 10px rgba(255, 255, 255, 0.25) inset, 2px 4px 4px #1F251E',
  },
  numHide: {
    '@media (min-width:1024px) and (max-width:1550px)': {
      display: 'none',
    },
    '@media (min-width:260px) and (max-width:517px)': {
      display: 'none',
    },
  },
}));

/*import { makeStyles } from "@mui/styles";


export const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        minHeight:'100vh',
        width:'100%',
        //background:'linear-gradient(340deg, rgba(20,5,35,1) 0%,rgb(37 10 60) 72%, rgba(135,83,207,1) 100%)',
        boxSizing:'border-box',
        '& .MuiTabPanel-root':{
            padding:10,
        }
    },
    container:{   
    },
    timeStyles:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        margin:'20px 0 32px',
        color:'#fff',
        '& svg':{
            width:20,
            animation:'$vibration .1s infinite',
        },
        '& span':{
            color:'#af99ca',
            fontSize:'1rem',
            margin:'0 6px',
        },
        '& b':{
            color:'#EAD40C',
            fontSize:'1.25rem',
            fontWeight:'bold',
        }
    },
   
    '@keyframes vibration': {
        'from': {
          transform: 'rotate(4deg)',
        },
        'to': {
          transform: 'rotate(-4deg)',
        }
    },

    tabListStyle:{
        '& .MuiTabs-flexContainer':{
            justifyContent:'center',
            '& button':{
                minWidth:120,
                minHeight:40,
                color:'#8753CF',
                borderRadius:50,
                border:'1px solid #8753CF',
                backgroundColor:'#0A0119',
                marginLeft:20,
                '&:first-child':{
                    marginLeft:0,
                },
                '&.Mui-selected':{
                    color:'#fff',
                    backgroundColor:'#8753CF',
                    fontWeight:'bold',
                },
                '@media all and (max-width: 510px)': {
                    minWidth:80,
                    marginLeft:12,
                },
            }
        },
        '& .MuiTabs-indicator':{
            display:'none',
        },
    },
}));*/
