import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        position:'absolute',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        width:'calc(100% - 32px)',
        padding:'16px 0',
        margin:'0 16px',
        zIndex:2,
        '@media all and (min-width:1120px)':{
            // flexDirection:'column',
            alignItems:'flex-start'
        }
    },
    avatarStyle:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        '& .MuiAvatar-root':{
            width:50,
            height:50,
            border:'3px solid #000',
            borderRadius:50,
        },
        '& > p':{
            fontSize:'0.815rem',
            lineHeight:1,
        }
    },
    avatar:{
        width:72,
    },
    btnStyle:{
        width:60,
        height:56,    
        left:0,    
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        marginLeft:'20px!important',
        padding:'8px 20px!important',
        backgroundColor:'#111321!important',
        border:'1px solid #41434d!important',
        borderRadius:'8px!important',
        color:'#fff!important',
        // boxShadow:'0 0 4px #4d526f',
        '&>div':{
            width:22,
            height:22,
            borderRadius:6,
        },
        '& .MuiTypography-root':{
            marginTop:4,
            fontSize:'0.75rem',
            lineHeight:'1',
        },
        '@media all and (min-width:1120px)':{
            // maxWidth:1100,
            // display:'flex',
            // flexDirection:'column',
            left:-400,
        },        
    },
    popper:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        padding:'10px 8px',
        color:'#fff',
        backgroundColor:'rgb(35 43 54 / 82%)',
        border:'1px solid #949494',
        borderRadius:6,
        zIndex:100,
    },
    btnDefaultStyle:{
        display:'flex',
        alignItems:'center',
        color:'#bdbdbd',
        backgroundColor:'#444b54',
        border:'1px solid #0e1116',
        borderRadius:4,
        padding:'4px 8px',
        fontSize:'0.688rem',
        letterSpacing:-0.5,
        textTransform:'uppercase',
        zIndex:100,
        '& > svg':{
            width:15,
            marginRight:2,
        }
    },
    glossMenuStyles:{
        marginTop:14,
        marginBottom:14,
        cursor:'pointer',
        '& > button':{
            display:'flex',
            alignItems:'center',
            flexDirection:'column',
            marginTop:16,
            '&:first-child':{
                marginTop:-8,
            },
            '& > p':{
                color:'#fff',
                fontSize:'0.815rem',
                textTransform:'uppercase',
                marginTop:4,
            }
        },
        
    },
    glossStyle:{
        width:32,
        height:32,
        '& img':{
           width:'100%',
        },
    },
    colorMenuStyles:{
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-around',
        width:80,
        paddingBottom:5,
        cursor:'pointer',
        '& > button':{
            display:'flex',
            alignItems:'center',
            maxWidth:'50%',
            minWidth:30,
            width:30,
            height:30,
            marginTop:10,
            '&:first-child':{
                // marginTop:0,
            }
        },
    },
    colorPicker:{
        borderRadius:3,
    },
}));

