var config = require('./config');
var g_WebSocketIO = null;

class WebSocketIO {
  constructor() {
    // const params = new URLSearchParams(window.location.search);
    // var user = params.get("user")
    // if (user === null || user === '') {
    //     user = '0';
    // }
    // this.idUser = parseInt(user); // is the number 123

    var prefixURL = config.GetPrefixURL();
    var url = config.GetBetServerURL(); //'localhost:8080';
    var scheme = prefixURL == 'https://' ? 'wss://' : 'ws://';
    this.webSocketUri = scheme + url + '/ws' + (window.location.search ? window.location.search : '');
    this.websocket = null;

    this.remaintime = -1;

    function createData(betting, currency, odds) {
      return { betting, currency, odds };
    }
    this.data = {
      selectOddEven: 0,
      selectDigit: 0,
      selectShowDigit: 0,
      total: [0, 0, 0],
      listRow: [
        [
          createData(0, '', 99),
          createData(0, '', 99),

          createData(0, '', 99),
          createData(0, '', 99),

          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),

          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
        ],
        [
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
        ],
        [
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
          createData(0, '', 99),
        ],
      ],
      listBettingValue: [],
      //  [50, 100, 200, 500, 1000, 2000, 3000, 5000, 7000, 10000],
      isCounterOpen: true,
      block: 0,

      tabTimeTable: 0,
      pageCurrent: 0,
      rowsPerPageCurrent: 20,
      pageCurrentMy: 0,
      rowsPerPageCurrentMy: 20,
      bettingOddEven: [0, 0],

      listChat: [
        {
          idUser: 'data.idUser',
          name: 'data.idUser',
          type: 'data.type',
          msg: 'data.msg',
        },
      ],
      onCounter: null,
    };
    // this.clientData = {
    //   game:0,
    //   user:'',
    //   progress:0,
    //   lan:''
    // };
    this.oncloseCallback = undefined;
    g_WebSocketIO = this;
  }

  InitData() {
    this.data.listBettingValue = [];
    this.data.block = 0;
    this.data.isCounterOpen = true;
    this.data.tabTimeTable = 0;
    this.data.pageCurrent = 0;
    this.data.rowsPerPageCurrent = 20;
    this.data.pageCurrentMy = 0;
    this.data.rowsPerPageCurrentMy = 20;
    this.data.bettingOddEven = [0, 0];
  }

  ping() {
    setTimeout(function () {
      g_WebSocketIO.send(
        JSON.stringify({
          protocol: 'reqPing',
        })
      );

      console.log('web socket ping');

      g_WebSocketIO.ping();
    }, 20 * 1000);
  }
  connect(user, game, coin) {

    console.log('connect : ' + coin);
    if (this.websocket !== null) {
      //this.websocket.close();
      //this.websocket = null;
      return;
    }

    var url = this.webSocketUri + `?user=${this.data.tokenUser}&game=${this.data.game}&coin=${coin}`;
    this.websocket = new WebSocket(url); //(this.webSocketUri);

    this.websocket.onopen = this.onOpen;
    this.websocket.onclose = this.onClose;
    this.oncloseCallback = undefined;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onerror = this.onError;
    this.websocket.selectOddEven = 0;
    this.websocket.selectDigit = 0;
    this.websocket.selectShowDigit = 0;

    this.ping();
  }

  onOpen() {
    console.log('Connected');
  }
  onClose() {
    console.log('Closed');    

    if (webSocketIO.oncloseCallback !== undefined) {
      webSocketIO.oncloseCallback();
    }
    
    if (this.websocket === null || this.websocket === undefined) {
      return;
    }
    // this.websocket.close();
    // this.websocket = null;
  }
  onMessage(e) {
    console.log(e.data, 'Message received');
  }
  onError(e) {
    console.log('Error (see console)');
    console.log(e);
  }

  send(data) {
    if (this.websocket === null || this.websocket === undefined) {
      console.log('websocket disconnected');
      return;
    }

    this.websocket.send(data);
  }
}

new WebSocketIO();
export const webSocketIO = g_WebSocketIO;
