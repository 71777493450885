import { GetCoinType, Goods, USDPerGoods } from '@/components/gameui/goods';
import { useLang } from '../../../../lib/hook';

type Props = {
  onClick: () => void;
  disabled: boolean;
  goodsType: number;
};

export default function FinalBettingButton({ onClick, disabled, goodsType }: Props) {
  const { lngT } = useLang('powerball');

  return (
    <button
      onClick={onClick}
      className="rounded-2xl relative w-[100%] max-[486px]:w-[90%] h-[60px] overflow-hidden hover:brightness-90 mx-4"
      disabled={disabled}
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-row items-center justify-center z-10 text-center text-white text-xl font-bold uppercase w-full gap-1">
        {/* BET*/ lngT('betting:pad:bet')}
        <div className="flex flex-row text-base ">
          (<USDPerGoods size={5}/>)            
        </div>
      </div>
      <div className="w-full h-full absolute top-0 left-0 z-10"></div>
      <div
        className="w-full h-full absolute top-0 left-0 z-1"
        style={{
          background: 'linear-gradient(102deg, #EA624C 0%, #E38D00 100%)',
        }}
      ></div>
    </button>
  );
}
