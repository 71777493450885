import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import tw from 'tailwind-styled-components';
import useSWR from 'swr';
import axios from 'axios';
import { toast } from 'react-toastify';

import { WEBSERVERPORT } from '../../config';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang';

const QuestionWriteBtn = tw.button`
  flex justify-center items-center
  h-[38px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

export default function QuestionDetail() {
  const { lngT } = useLang('contact:question');
  const navigate = useNavigate();
  const params = useParams();
  const [user] = useUser();
  const { data, isLoading, error } = useSWR(`${process.env.REACT_APP_API_URL}/question/${params.questionId}`);
  const { register, setValue, handleSubmit } = useForm();
  const [isEditing, setEditing] = useState(false);

  const onSubmit = async payload => {
    if (
      data?.questionItem.questionTitle === payload.title &&
      data?.questionItem.questionDescription === payload.description
    ) {
      return;
    }

    try {
      const { data } = await axios.patch(`${process.env.REACT_APP_API_URL}/question/${params.questionId}`, payload, {
        withCredentials: true,
      });

      if (data.ok) {
        toast(lngT('toast:updateSuccess'), { type: 'success', theme: 'dark' });
        setEditing(false);
        navigate('/contact/question');
      }
    } catch (error) {
      toast(lngT('toast:updateFail'), { type: 'error', theme: 'dark' });
      console.log(error);
    }
  };
  console.log('datadata', data);

  const handleClickDeleteQuestionItem = async () => {
    if (!window.confirm(lngT('write:section:checkDelete'))) return;

    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API_URL}/question/${params.questionId}`, {
        withCredentials: true,
      });

      if (data.ok) {
        toast(lngT('toast:deleteSuccess'), { type: 'success', theme: 'dark' });
        setEditing(false);
        navigate('/contact/question');
      }
    } catch (error) {
      toast(lngT('toast:deleteFail'), { type: 'error', theme: 'dark' });
      console.log(error);
    }
  };

  useEffect(() => {
    if (data) {
      setValue('title', data.questionItem.questionTitle);
      setValue('description', data.questionItem.questionDescription);
    }
  }, [data, setValue]);

  return (
    <form className="flex flex-col D:mx-[64px]" onSubmit={handleSubmit(onSubmit)}>
      <div className="hidden D:flex flex-row D:justify-between items-center text-[24px] font-medium text-white px-[6px] D:pb-[24px]">
        <div className="flex text-[20px] h-[38px]">{lngT('title')}</div>
        {isEditing && (
          <QuestionWriteBtn type="submit" className="text-[14px] px-[20px] rounded-xl">
            {lngT('save')}
          </QuestionWriteBtn>
        )}
      </div>
      <div className="hidden D:flex border-b-2 border-white/40 mb-[20px]"></div>
      <div className="flex flex-col p-[16px] mt-4 bg-[#2d0a42]">
        <div className="flex flex-row justify-between items-center">
          <div
            className={`rounded-xl px-3 py-1 text-[12px] ${
              data?.questionItem.answerId === null ? 'text-[#FF6767] bg-white/10' : 'text-[#8bfdf2] bg-gray-700 '
            }`}
          >
            {data?.questionItem.answerId === null ? 'inProgress' : 'complete'}
          </div>
          {console.log('questionUserId', data?.questionItem.questionUserId)}
          {user?.id && user.id === data?.questionItem.questionUserId && (
            <div className="flex flex-row text-[14px] text-[#CCCCCC]/80">
              {!isEditing && (
                <div className="cursor-pointer hover:opacity-70" onClick={() => setEditing(true)}>
                  {lngT('write:section:edit')}
                </div>
              )}
              {!isEditing && <div className="px-[8px] text-[#CCCCCC]/50">|</div>}
              {isEditing ? (
                <div className="cursor-pointer hover:opacity-70" onClick={() => setEditing(false)}>
                  {lngT('write:section:cancelEdit')}
                </div>
              ) : (
                <div className="cursor-pointer hover:opacity-70" onClick={handleClickDeleteQuestionItem}>
                  {lngT('write:section:delete')}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center py-[8px] text-[16px] border-b border-white/20 text-white space-x-2">
          <span className="font-semibold text-[16px] text-[#860099]">Q.</span>
          <input
            className={`w-full bg-transparent font-semibold text-[16px] rounded-lg p-[8px] border ${
              isEditing ? 'border-white/40' : 'border-transparent'
            }`}
            disabled={!isEditing}
            {...register('title')}
          />
        </div>
        <div className="p-[8px] text-white">
          <textarea
            className={`w-full bg-transparent font-normal text-[16px] rounded-lg p-[8px] border resize-none capitalize ${
              isEditing ? 'border-white/40' : 'border-transparent'
            }`}
            disabled={!isEditing}
            {...register('description')}
          />
        </div>
        <div className="flex justify-end text-white/90 text-[14px]">
          {data?.questionItem.questionDate && new Date(data.questionItem.questionDate).toISOString().substring(0, 10)}
        </div>
        {isEditing && (
          <QuestionWriteBtn type="submit" className="T:hidden py-[12px] text-white rounded-lg mt-5">
            {lngT('save')}
          </QuestionWriteBtn>
        )}
      </div>
      {data && data?.questionItem?.answerDescription !== null && data?.questionItem?.answerDate !== null && (
        <AnswerItem description={data.questionItem.answerDescription} date={data.questionItem.answerDate} />
      )}
    </form>
  );
}

const AnswerItem = ({ description, date }) => {
  return (
    <div className="flex flex-col bg-white/10 p-[16px]">
      <div className="flex flex-row items-center py-[15px] text-[16px] text-white space-x-3">
        <span className="font-bold">A</span>
        <div className="text-[14px] capitalize">{description}</div>
      </div>
      <div className="flex justify-end text-white/90 text-[14px]">{new Date(date).toISOString().substring(0, 10)}</div>
    </div>
  );
};
