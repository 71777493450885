import tw from 'tailwind-styled-components';

import type { ReactNode } from 'react';

import useLang from '@/lib/hook/useLang';

const InfoTitle = tw.p`
  text-xs text-white font-light uppercase
`;

const InfoText = tw.p`
  text-xs text-[#F14EA7] font-medium
`;

const LeaseInfoTitle = tw.p`
 text-xs text-white/40
`;

type Props = {
  item: HorseStakeInfoItem;
  modalContent: ReactNode;
};

const HorseStakeNoItemUI = () => {
  const { lngT } = useLang('horse:info');
  return (
    <div className="flex flex-col w-full">
      {/* horse info */}
      <div
        className="relative w-full h-[600px] mb-2
        LD:h-[300px] bg-[#48096D] bg-opacity-30 T:rounded-tl-lg T:rounded-tr-lg"
      >
        <div className="absolute w-full h-full flex flex-col LD:flex-row items-start LD:items-end LD:bottom-0 top-0 px-8 pt-4 LD:space-x-4">
          <div className="relative h-[290px] w-full">
            <img
              className="h-[290px] w-full object-cover mix-blend-luminosity brightness-50 opacity-80"
              src="../../images/horse_bg.png"
            ></img>
            <div className="absolute w-full T:w-[500px] flex flex-col justify-center text-xs T:text-base top-1/2 left-1/2 -translate-x-1/2 space-x-2">
              <p className="text-white/90 text-center">
                {/*There are no horses currently on lease.*/ lngT('noContent:noHorse')}
              </p>
              <p className="text-[#F14EA7] text-center text- ">
                {/*You need to lease to a horse*/ lngT('noContent:leaseHorse')}
              </p>
            </div>
          </div>
          <div className="w-full LD:w-[260px] h-[290px]">
            <div className="relative bg-[#48096D]/30 LD:w-[260px] h-[290px] px-4">
              <p className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 text-white/70 text-center text-xs">
                {/*No contents*/ lngT('noContent:noContents')}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Stake info */}
      <div className="w-full h-fit bg-[#554FFF]/10 rounded-bl-lg rounded-br-lg px-8 py-8 space-y-6 LD:flex LD:flex-row LD:space-y-0 LD:space-x-8">
        <div className="LD:w-2/3 flex flex-col space-y-6">
          {/* graph */}
          <div className="relative w-full h-[200px] bg-white bg-opacity-5 rounded-lg flex flex-col T:flex-row items-start T:justify-between T:items-center space-y-3 T:space-y-0 px-5 py-6">
            <p className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 text-white/70 text-center text-xs">
              {/*No contents*/ lngT('noContent:noContents')}
            </p>
          </div>
        </div>
        {/* button */}
        <div className="relative w-full LD:w-1/3 LD:flex-col h-[200px] bg-white bg-opacity-5 rounded-lg flex flex-col T:flex-row items-start  T:justify-start T:items-center T:space-x-8 space-y-3 T:space-y-0 LD:space-y-8 LD:space-x-0 px-5 py-7 ">
          <p className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 text-white/70 text-center text-xs">
            {/*No contents*/ lngT('noContent:noContents')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HorseStakeNoItemUI;
