import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';
import { useLocation } from 'react-router-dom';

import useSWR from 'swr';

import LogoButton from './LogoButton';
import UserAvatar from './UserAvatar';
import MobileNavigation from './MobileNavigation';
import LanguageManage from './LanguageManage';
import { useCoinDrop, useUser } from '@/lib/hook';
import { CoinDropResetButton } from './coindrop';

import { useCoindropStore } from '@/store/coindrop';
import * as config from '../config';
import CoinDropButton from './coindrop/CoinDropButton';
import CoinDropButtonMobile from './coindrop/CoinDropButtonMobile';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import SlotSpriteButton from "./SlotSpriteButton";

const Container = tw.nav`
  flex flex-row items-center justify-between
  w-full ${({ $isScrolled }) => ($isScrolled ? 'h-[100px]' : 'h-[90px]')}
  max-[480px]:px-[20px] px-[40px] D:px-[100px]
  ${({ $isScrolled }) => ($isScrolled ? 'bg-white shadow-sm' : 'bg-white')}
  transform transition-[height] duration-500
  ${({ $isColor }) => ($isColor ? 'bg-[#3E287A]' : 'bg-white')}
`;

const NavContainer = tw.ul`
  flex flex-row items-center justify-center
  flex-1 h-full
  space-x-[43px]
`;

const AuthBoxContainer = tw.div`
  flex flex-row
  space-x-3 T:space-x-[16px]
  mt-2
`;

const noHeaderUrlList = [
  '/login',
  '/signup',
  '/password',
  '/game',
  '/introbet',
  '/7CkmEx76EWp',
  '/DIEHB7O3WI5II',
  'GameOnePage',
  '/video',
  // '/powerball',
];

const purpleBgList = ['/powerball'];

export default function Gnb() {
  const { pathname } = useLocation();
  const [isScrolled, setScrolled] = useState(false);
  const { openGamePopup } = useCoindropStore();
  const [user, { isLoading, error, logout }] = useUser();
  const navigate = useCustomNavigate();
  const [isChangeColor, setIsChangeColor] = useState(false);

  const [isMobile, setIsMobile] = useState(false); // 모바일 화면 여부를 저장하는 상태

  useEffect(() => {
    const checkIfMobile = () => {
      const breakpoint = 768; // T 브레이크포인트 값, 프로젝트 설정에 맞게 조정
      setIsMobile(window.innerWidth <= breakpoint);
    };

    // 컴포넌트 마운트 시와 화면 크기 변경 시 모바일 여부 확인
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY !== 0);
    window.addEventListener('scroll', handleScroll);

    if (purpleBgList.find(url => pathname?.includes(url))) {
      setIsChangeColor(true);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // if (pathname?.includes('/powerball/lottery') || pathname?.includes('/powerball/betting')) {
  //   return <div className="w-full h-[70px] bg-black"></div>;
  // }

  if (noHeaderUrlList.find(url => pathname?.includes(url))) {
    return null;
  }

  return (
    <Container $isScrolled={isScrolled} $isColor={isChangeColor}>
      <LogoButton />
      <div className="flex flex-row items-center">
        {user ?
            <SlotSpriteButton isMobile={isMobile} isLogin={true} navigate={navigate}/>
            :
            <SlotSpriteButton isMobile={isMobile} isLogin={false} navigate={navigate}/>
        }
        {user ? (
            // isMobile 상태에 따라 컴포넌트 선택적 렌더링
            isMobile ? (
                <CoinDropButtonMobile onClick={() => openGamePopup()} />
            ) : (
                <CoinDropButton onClick={() => openGamePopup()} />
            )
        ) : (
            isMobile ? (
                    <CoinDropButtonMobile onClick={() => navigate('login')} />
                ) : (
                <CoinDropButton onClick={() => navigate('login')} />
            )
        )}

        <AuthBoxContainer>
          <LanguageManage />
          <UserAvatar />
          <MobileNavigation isScrolled={isScrolled} />
        </AuthBoxContainer>
      </div>
    </Container>
  );
}
