import tw from 'tailwind-styled-components';

const BallContainer = tw.button<{ $isSelected: boolean }>`
  w-11 h-11 rounded-full flex flex-col items-center justify-center
  max-[594px]:w-9 max-[594px]:h-9
  ${({ $isSelected }) =>
    $isSelected
      ? 'bg-gradient-to-r from-yellow-300 to-yellow-600'
      : 'bg-gradient-to-r from-gray-400 via-gray-300 to-gray-500'}
  `;

const PowerBallContainer = tw.button<{ $isSelected: boolean }>`
  w-11 h-11 rounded-full flex flex-col items-center justify-center 
  max-[594px]:w-9 max-[594px]:h-9
  ${({ $isSelected }) =>
    $isSelected
      ? 'bg-gradient-to-r from-red-400 to-red-600'
      : 'bg-gradient-to-r from-gray-400 via-gray-300 to-gray-500'}
`;

type Props = {
  type: 'ball' | 'powerball';
  canBet: boolean;
  isSelected: boolean;
  number: number;
  onClick: () => void;
};

export default function LotteryBallButton({ type, canBet, isSelected, number, onClick }: Props) {
  if (type === 'ball') {
    return (
      <BallContainer disabled={!canBet} $isSelected={isSelected} onClick={onClick}>
        {
          <div className="bg-white w-6 h-6 max-[594px]:w-5 max-[594px]:h-5 max-[594px]:text-sm rounded-full flex flex-row justify-center items-center">
            {number}
          </div>
        }
      </BallContainer>
    );
  } else {
    return (
      <PowerBallContainer disabled={!canBet} $isSelected={isSelected} onClick={onClick}>
        {
          <div className="bg-white w-6 h-6 max-[594px]:w-5 max-[594px]:h-5 max-[594px]:text-sm rounded-full flex flex-row justify-center items-center">
            {number}
          </div>
        }
      </PowerBallContainer>
    );
  }
}
