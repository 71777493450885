import useSWR from 'swr';
import tw from 'tailwind-styled-components';

import ChargeHistoryItem from './ChargeHistoryItem';
import useLang from '../lib/hook/useLang';

import { API_URL } from '@/env';

const ListHeader = tw.div`
  flex flex-row
`;

const ListHeaderItem = tw.div`
  flex flex-row
  py-[10px] px-[16px]
  text-white text-[12px] font-medium
  bg-black/30
`;

export default function ChargeHistory() {
  const { lngT } = useLang('mypage:account');
  const { data } = useSWR(`${API_URL}/payment/charge`);

  return (
    <div className="">
      <ListHeader>
        <ListHeaderItem className="w-[100px]">{lngT('history:index')}</ListHeaderItem>
        <ListHeaderItem className="w-[180px]">{lngT('history:paymentType')}</ListHeaderItem>
        <ListHeaderItem className="w-[180px]">{lngT('history:exchangeRate')}</ListHeaderItem>
        <ListHeaderItem className="w-[180px]">{lngT('history:chargePoint')}</ListHeaderItem>
        <ListHeaderItem className="w-[180px]">{lngT('history:chargeAmount')}</ListHeaderItem>
        <ListHeaderItem className="w-[180px]">{lngT('history:status')}</ListHeaderItem>
        <ListHeaderItem className="w-[180px]">{lngT('history:qr')}</ListHeaderItem>
        <ListHeaderItem className="w-[220px]">{lngT('history:created')}</ListHeaderItem>
        <ListHeaderItem className="w-[220px]">{lngT('history:expired')}</ListHeaderItem>
      </ListHeader>
      <div className="w-full">
        {data?.charges?.map((charge, i) => (
          <ChargeHistoryItem
            key={i}
            index={i + 1}
            paymentType={charge.paymentType}
            exchangeRate={charge.exchangeRate}
            chargePoint={charge.chargePoint}
            transaction_amount={charge.transaction_amount}
            status={charge.status}
            qrCodeBase64={charge.qrCodeBase64}
            created={charge.created}
            expired={charge.expired}
          />
        ))}
      </div>
    </div>
  );
}
