export const SpinState = 
{
  NONE : 0,
  // SPINN_TRY : 1,
  SPINNING : 1,
  STOPPING : 2,
  REWARD : 3,
}

export function CanSpin(spinState)
{
    return (spinState === SpinState.NONE || spinState === SpinState.REWARD);
}

export const AUTOSTOP_DELAY = 2000;

export const ITEM_SIZE = 0.00024;
export const SLOT_SCALE = 23.1;
// export const SLOT_SCALE = 20.02; DEBUGTEST

export const REEL_ZINDEX =
{
  UI_CANVAS_BOTTOM : 0,
  UI_CANVAS_UNDER : 20,
  CANVAS : 30,
  UI_CANVAS_OVER : 40,
  UI_TOP : 50
}

export const FreeRollState = 
{
  NORMAL : 0,
  FREEROLL_START : 1,
  FREEROLL_PLAYING : 2,  
  FREEROLL_END : 3,
}

export function GetFreeRollState(freeroll)
{
  // let remainFreeRoll = freeroll.size - freeroll.count;
  let rollState; 
  if(freeroll.size === 0)
  {
      rollState = FreeRollState.NORMAL;
  }

  else if(freeroll.size > 0)
  {
    if(freeroll.count === freeroll.size)
      rollState = FreeRollState.FREEROLL_END;
    else if(freeroll.count === 0)
      rollState = FreeRollState.FREEROLL_START; 
    else
      rollState = FreeRollState.FREEROLL_PLAYING;
  }

  // console.log(`rollState=> count/size (rollState) ${freeroll.count}/${freeroll.size} (${rollState})`);
  return rollState;
}