import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';

import TextInputWrapper from '../TextInputWrapper';
import PrimaryButton from '../PrimaryButton';

import useLang from '../../lib/hook/useLang';

export default function CheckEmailExist({ setNextPhase, setEmail, setVerificationCode }) {
  const { lngT } = useLang('auth:signup');
  const {
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const hasInputError = !!Object.keys(errors).length;

  const checkEmailExist = async ({ email }) => {
    setLoading(true);

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/checkEmailExist`, {
        email,
      });

      if (!data.ok) {
        return toast(data.message, { type: 'error', theme: 'dark' });
      }
      setEmail(email);
      setVerificationCode(data.code);
      toast(lngT('signup:4:toast:1'), {
        theme: 'dark',
        type: 'success',
      });
      return setNextPhase();
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  // const signupUser = async payload => {
  //   setLoading(true);

  //   try {
  //     const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, payload);
  //     if (!data.ok) {
  //       return toast(data.message, { type: 'error', theme: 'dark' });
  //     }
  //     const { accessToken } = data;
  //     login(accessToken);
  //     toast('you are registered', { theme: 'dark', type: 'success' });
  //     navigate('/');
  //   } catch (error) {
  //     //토스트 알람
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const onSubmit = data => {
    checkEmailExist(data);
  };

  useEffect(() => {
    resetField('name');
    resetField('password');
  }, [resetField]);

  return (
    <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
      <TextInputWrapper title="Email" isEmpty={!watch('email')} errorText={errors.email?.message}>
        <input
          type="email"
          {...register('email', {
            required: lngT('signup:1:type:error:1'),
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
              message: lngT('signup:1:type:error:2'),
            },
          })}
          placeholder={lngT('signup:1:type:email')}
          autoComplete="off"
        />
      </TextInputWrapper>
      {/* {isUserInfoSettingPhase && (
        <div className="flex flex-col space-y-[20px] pt-[40px]">
          <TextInputWrapper title="UserName" isEmpty={!watch('name')} errorText={errors.name?.message}>
            <input
              type="text"
              {...register('name', { required: 'UserName is a required' })}
              placeholder="Enter your username"
              autoComplete="off"
            />
          </TextInputWrapper>
          <TextInputWrapper title="Password" isEmpty={!watch('password')} errorText={errors.password?.message}>
            <input
              type="password"
              {...register('password', {
                required: 'Password is a required',
              })}
              placeholder="Enter your password"
              autoComplete="off"
            />
          </TextInputWrapper>
        </div>
      )} */}
      <PrimaryButton
        type="submit"
        title={isLoading ? lngT('button:2') : lngT('button:1')}
        isLoading={isLoading}
        error={hasInputError}
        className="mt-[40px]"
      />
    </form>
  );
}
