import tw from 'tailwind-styled-components';

type PowerBallBettingValue = {
  id: number;
  name: string;
  odds: number;
  detail?: string;
};

type Props = {
  bettingValue: PowerBallBettingValue;
  isSelected: boolean;
  disabled: boolean;
  stickerPrice: number | null;
  onClick: () => void;
};

const Container = tw.button<{ $isSelected: boolean }>`
  flex flex-col items-center justify-center
  w-full h-11
  rounded-lg
  brightness-105 transiation-all
  hover:brightness-100 to-indigo-400
  ${({ $isSelected }) =>
    $isSelected
      ? 'bg-gradient-to-b from-indigo-200 via-violet-100 to-indigo-300'
      : 'bg-gradient-to-b from-[#C69BE0] via-[#C69BE0]/80 to-[#C69BE0]'}
`;

export default function BettingValueButton({ bettingValue, isSelected, disabled, stickerPrice, onClick }: Props) {
  return (
    <Container
      className={`relative ${bettingValue.id >= 5 ? 'col-span-2 h-[52px]' : 'col-span-3 '}`}
      $isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      {stickerPrice !== null && (
        <div className="absolute flex justify-center items-center -top-3 right-4">
          <img src={`/images/b_sticker.png`} alt="sticker" className="relative w-[60px] max-[450px]:w-[50px]" />
          <div className="absolute w-full h-full flex justify-center items-center text-white -rotate-12 text-xs -top-[2px] z-[99] max-[450px]:text-xs">
            {stickerPrice}
          </div>
        </div>
      )}
      <div className="w-full flex items-center justify-between px-2 max-[386px]:px-1 h-full">
        {bettingValue.detail ? (
          <div
            className={`flex ${
              bettingValue.id >= 5
                ? 'flex-col justify-start items-start'
                : 'flex-row justify-start items-center gap-5 max-[390px]:gap-1'
            }`}
          >
            <div className="w-8 h-6 rounded flex flex-row items-center justify-start text-left text-sm max-[450px]:text-xs">
              {bettingValue.name}
            </div>
            <div className="text-xs max-[450px]:text-[9px]">{bettingValue.detail}</div>
          </div>
        ) : (
          <div className="w-8 h-6 rounded flex flex-row items-center justify-start text-left text-sm max-[450px]:text-xs">
            {bettingValue.name}
          </div>
        )}
        <div className="bg-white w-8 h-6 rounded flex flex-row justify-center items-center text-sm text-violet-500 max-[390px]:text-xs">
          {bettingValue.odds}
        </div>
      </div>
    </Container>
  );
}

// 중복배팅 안되게
// 게임이 다시 시작했을 때 배팅판 리셋
// 배팅 하면 배팅판 리셋
