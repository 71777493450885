import React, { useState } from 'react';
import FreeCoin from '../../img/coin_free.png';
import CashCoin from '../../img/coin_point.png';
import { useSelector } from 'react-redux';
import { GoodsType } from '../../define';
import { SetBalance } from '../../horsestall/progresscombine/jsUtil_combine';
import { useLang } from '../../lib/hook';

type Props = {
  size: number;
  toggleType : boolean;
};

export function Point({ size, toggleType }: Props) {
  const { lngT } = useLang('etcs');
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  
  let goodsInfo = { tip: lngT('goods:tooltip:cash'), img: CashCoin };
  let goodsType = GetCoinType();
  const classNameTip = `absolute z-10 top-1/2 left-10 -translate-y-1/2 px-2 py-1 bg-white rounded-md whitespace-nowrap text-xs text-gray-800`;
  
  return (
    <div
      className={`relative`}
      onMouseEnter={() => goodsInfo.tip.length > 0 && setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      <img src={goodsInfo.img} className={`w-${size} h-${size} ${goodsType === GoodsType.FREECOIN && toggleType ? 'opacity-30' : ''}`} alt="goods" />
      {isTooltipVisible && (
        <div className={classNameTip}>
          {goodsInfo.tip}
        </div>
      )}
    </div>
  );
}

export function GetCoinType() {
  const goodsType = useSelector((state: any) => state.goodsType);
  // console.log('goodsType11 : ' + goodsType);
  return goodsType;
}
