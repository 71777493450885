import { useState } from 'react';

const today = new Date();
const oneMonthAgo = new Date(today);

oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

type Props = {
  startDateInit: Date;
  endDateInit: Date;
};

const useDateSelector = (
  props: Props = {
    startDateInit: oneMonthAgo,
    endDateInit: today,
  }
) => {
  const [startDate, setStartDate] = useState(formatDate(props.startDateInit));
  const [endDate, setEndDate] = useState(formatDate(props.endDateInit));

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
};

export default useDateSelector;

function formatDate(date: Date) {
  let month = '' + (date.getMonth() + 1), // 월은 0부터 시작하므로 1을 더합니다.
    day = '' + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}
