import { create } from 'zustand';

export type LotteryTicket = {
  balls: number[];
  powerBall: number;
  price: number;
};

interface PowerBallLotteryState {
  selectedTicketList: LotteryTicket[];
  setSelectedTicketList: (selectedTicketList: LotteryTicket[]) => void;
  addTicket: (newTicket: LotteryTicket) => void;
}

export const usePowerBallLotteryStore = create<PowerBallLotteryState>(set => ({
  selectedTicketList: [],
  setSelectedTicketList: selectedTicketList => set({ selectedTicketList }),
  addTicket: newTicket =>
    set(state => ({
      selectedTicketList: [...state.selectedTicketList, newTicket],
    })),
}));

export type BettingTicketStore = {
  [key in number]: BettingTicket;
};

type BettingTicket = {
  id: number;
  price: number;
  result: {
    balls: number;
    value: string;
  } | null;
  reward: number;
};

interface PowerBallBettingState {
  ticketStore: BettingTicketStore;
  setTicketStore: (ticketStore: BettingTicketStore) => void;
  addTicket: (ticket: BettingTicket) => void;
}

export const usePowerBallBettingStore = create<PowerBallBettingState>(set => ({
  ticketStore: {},
  setTicketStore: (ticketStore: BettingTicketStore) => set({ ticketStore }),
  addTicket: ticket =>
    set(state => ({
      ticketStore: {
        ...state.ticketStore,
        [ticket.id]: ticket,
      },
    })),
}));

interface PowerBallResultTriggerState {
  trigger: boolean;
  callTrigger: () => void;
}

export const usePowerBallResultTrigger = create<PowerBallResultTriggerState>((set, get) => ({
  trigger: false,
  callTrigger: () => set({ trigger: !get().trigger }),
}));

//////////

interface PowerballModalState {
  isPowerballModalOpen: boolean;
  openPowerModalPopup: () => void;
  closePowerModalPopup: () => void;
  isHashModalOpen: boolean;
  openHashModalPopup: () => void;
  closeHashModalPopup: () => void;
}

export const usePowerModalStore = create<PowerballModalState>(set => ({
  isPowerballModalOpen: false,
  openPowerModalPopup: () => set({ isPowerballModalOpen: true }),
  closePowerModalPopup: () => set({ isPowerballModalOpen: false }),
  isHashModalOpen: false,
  openHashModalPopup: () => set({ isHashModalOpen: true }),
  closeHashModalPopup: () => set({ isHashModalOpen: false }),
}));

interface IconStore {
  iconComponent: React.ReactNode;
  setIconComponent: (icon: React.ReactNode) => void;
}

export const useIconStore = create<IconStore>(set => ({
  iconComponent: null,
  setIconComponent: icon => set({ iconComponent: icon }),
}));

interface TitleStore {
  title: string;
  setTitle: (newTitle: string) => void;
  phrase: string;
  setPhrase: (newPhrase: string) => void;
  submit: boolean;
  onSubmit: () => void;
  noSubmit: () => void;
}

export const useTitleStore = create<TitleStore>(set => ({
  title: '',
  setTitle: newTitle => set({ title: newTitle }),
  phrase: '',
  setPhrase: newPhrase => set({ phrase: newPhrase }),
  submit: false,
  onSubmit: () => set({ submit: true }),
  noSubmit: () => set({ submit: false }),
}));

// 결과창
interface ResultPowerballState {
  isResultPopupOpen: boolean;
  openResultPopup: () => void;
  closeResultPopup: () => void;
}

export const useResultStore = create<ResultPowerballState>(set => ({
  isResultPopupOpen: false,
  openResultPopup: () => set({ isResultPopupOpen: true }),
  closeResultPopup: () => set({ isResultPopupOpen: false }),
}));
