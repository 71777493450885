import React, { useState, useEffect } from 'react';
import tw from 'tailwind-styled-components';
import { ReactComponent as ArrowLeftIcon } from '../../img/ArrowLineLeftIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../img/ArrowLineRightIcon.svg';
import HistoryBg from '../../img/powerball/lottohistory_bg.png';
import RewardBg from '../../img/powerball/reward_bg.png';
import RewardBtn from '../../img/powerball/reward_button.png';
import RewardClose from '../../img/powerball/reward_close.png';
import { useSelector } from 'react-redux';
import { usePowerModalStore } from '@/store/powerball';
import NumberHash from './NumberHash';

import { useLang } from '../../lib/hook';

const InfoContainer = tw.div`
  relative w-full h-full
  max-[984px]:w-full
  `;
const InfoHeader = tw.div`
 relative
  w-full h-[50px]
 flex flex-row justify-center items-center
 z-10
`;

const InfoTitleText = tw.div`
text-white text-base font-medium tracking-wider
uppercase
max-[360px]:text-sm
`;

const ArrowWrap = tw.div`
w-[90%] absolute flex flex-row justify-between
`;
export default function NumberHistoryBoard({ resPowerballHistory, resPowerballFromHash }) {
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isRewardOpen, setIsRewardOpen] = useState(false);
  const [numberPadWidth, setNumberPadWidth] = useState(0);
  const { isHashModalOpen, openHashModalPopup } = usePowerModalStore();
  const [blockHash, setBlockHash] = useState({
    block: 0,
    hash: '',
  });

  const { lngT } = useLang('powerball');

  //해시 버튼을 누르면 해쉬모달창이 뜬다
  //해당 모달창 인덱스에 맞는 블럭과 해쉬를 받는다
  //그 데이터를 넘긴다

  // console.log('resPowerballFromHash', resPowerballFromHash);
  const handleClickOpenHash = index => {
    openHashModalPopup();
    setBlockHash({
      block: resPowerballHistory[index]?.block,
      hash: resPowerballHistory[index]?.hash,
      ball: resPowerballHistory[index]?.ball,
      powerBall: resPowerballHistory[index]?.powerBall,
      // checkHash: resPowerballFromHash,
    });
  };

  useEffect(()=>{
    console.log('hash set1 : ' + JSON.stringify(resPowerballFromHash));
    
    setBlockHash({
      block: blockHash.block,
      hash: blockHash.hash,
      ball: resPowerballFromHash.balls,
      powerBall: resPowerballFromHash.powerBall,
      // checkHash: resPowerballFromHash,
    });        

  },[resPowerballFromHash])

  useEffect(() => {
    const handleResize = () => {
      const newWidth = document.body.offsetWidth;
      setNumberPadWidth(newWidth);
      console.log('newWidth', newWidth);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [numberPadWidth]);

  const handleClickShowHistory = () => {
    if (numberPadWidth < 970) {
      setIsHistoryOpen(!isHistoryOpen);
    } else if (numberPadWidth > 970 && numberPadWidth < 1426) {
      setIsHistoryOpen(!isHistoryOpen);
      setIsRewardOpen(!isRewardOpen);
    }
  };

  useEffect(() => {
    if (numberPadWidth > 1426) {
      setIsHistoryOpen(true);
    } else {
      setIsHistoryOpen(false);
    }

    if (numberPadWidth > 1426) {
      setIsRewardOpen(true);
    } else {
      setIsRewardOpen(false);
    }
  }, [numberPadWidth]);

  return (
    <InfoContainer>
      <img
        alt="historybg"
        src={`${isHistoryOpen ? HistoryBg : RewardClose}`}
        className="w-full h-full absolute z-[1]"
      />
      <InfoHeader
        className={isRewardOpen ? 'rounded-tl-2xl rounded-tr-2xl' : 'rounded-2xl'}
        onClick={handleClickShowHistory}
      >
        {/* <ArrowWrap>
          <ArrowLeftIcon />
          <ArrowRightIcon />
        </ArrowWrap> */}
        <InfoTitleText>{/*Lottery History */ lngT('lottery:history:title')} </InfoTitleText>
      </InfoHeader>
      {numberPadWidth < 1440 ? (
        isHistoryOpen && (
          <HistoryTable resPowerballHistory={resPowerballHistory} handleClickOpenHash={handleClickOpenHash} />
        )
      ) : (
        <HistoryTable resPowerballHistory={resPowerballHistory} handleClickOpenHash={handleClickOpenHash} />
      )}
      {/* hash */}
      {isHashModalOpen && <NumberHash open={isHashModalOpen} blockHash={blockHash} />}
    </InfoContainer>
  );
}

const HistoryTable = ({ resPowerballHistory, handleClickOpenHash }) => {
  const { lngT } = useLang('powerball');

  return (
    <div class="absoulte top-0 z-10 overflow-x-auto max-[420px]:overflow-x-hidden w-full h-fit flex items-center justify-center my-4 mx-2">
      <table class="w-full max-[486px]:w-[90%] text-sm text-left rtl:text-right text-gray-500 font-medium z-10 ">
        <thead class="text-xs uppercase text-white" style={{ fontWeight: 'lighter' }}>
          <tr>
            <th scope="col" class="px-6 py-2 max-[420px]:px-0 max-[480px]:text-xs">
              {/*Round*/ lngT('lottery:history:round')}
            </th>
            <th scope="col" class="text-center px-6 py-2 max-[420px]:px-0 max-[480px]:text-xs">
              {/*Number*/ lngT('lottery:history:number')}
            </th>
            <th scope="col" class="text-center px-6 py-2 max-[420px]:px-0 max-[480px]:text-xs">
              {/*Hash*/ lngT('lottery:history:hash')}
            </th>
          </tr>
        </thead>
        {resPowerballHistory &&
          resPowerballHistory
            .sort((a, b) => b.index - a.index)
            .map((item, index) => (
              <tbody>
                <tr>
                  <td class="px-6 py-3 max-[420px]:px-0 text-white max-[420px]:text-center max-[480px]:text-xs">
                    {item.index}
                  </td>
                  <td class="px-6 py-3 max-[420px]:px-0 flex flex-row justify-evenly items-center gap-1 ">
                    {item.ball.map(item => {
                      return (
                        <div className="w-6 h-6 rounded-full flex flex-col items-center justify-center border border-[#eee] roudned-full bg-white max-[480px]:w-5 max-[480px]:h-5">
                          {item}
                        </div>
                      );
                    })}
                    <div className="w-6 h-6 rounded-full flex flex-col items-center justify-center border border-red-500 text-red-500 roudned-full bg-white max-[480px]:w-5 max-[480px]:h-5">
                      {item.powerBall}
                    </div>
                  </td>
                  <td class="px-6 py-3 max-[420px]:px-0 text-center">
                    <button
                      onClick={() => handleClickOpenHash(index)}
                      className="w-14 h-7 py-1 rounded-lg justify-center items-center gap-2.5 inline-flex hover:brightness-75 bg-white max-[380px]:w-10 max-[380px]:h-5 max-[380px]:text-xs max-[380px]:rounded-md"
                    >
                      {/*Hash*/ lngT('lottery:history:hash')}
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
      </table>
    </div>
  );
};
