import React, { useRef, useEffect, useState } from 'react';
import gamebgmSound from './resources/gamebgm.mp3';
import betbgmSound from './resources/betbgm.mp3';
import shuffleSound from './resources/shuffle.mp3';
import ballmoveSound from './resources/ballmove.mp3';
import laserSound from './resources/laser.mp3';



import SoundMute from '../../../img/powerball/sound_mute.svg';
import Sound from '../../../img/powerball/sound_loud.svg';

import useSound from 'use-sound';
import { useDispatch, useSelector } from 'react-redux';
import * as define from '../../../define'

// let isSoundOn = false;
function BgmSound(props) {
  const soundOn = useSelector(state => state.soundOn);
  const soundlist = [gamebgmSound, betbgmSound];
  //
  const [play, { pause }] = useSound(soundlist[props.bgm], {
    loop: true,
  });

  useEffect(() => {
    if (soundOn === undefined) return;

    console.log('props.soundOn : ' + soundOn);

    if (soundOn) {
      play();
      console.log('playSound');
    } else {
      pause();
    }
  }, [soundOn]);

  return <div></div>;
}

const ShuffleSound = () => {
  const soundOn = useSelector(state => state.soundOn);
  const [play, { pause }] = useSound(shuffleSound, {
    loop: true,
  });

  useEffect(() => {
    if (soundOn === undefined) return;

    console.log('props.soundOn : ' + soundOn);

    if (soundOn) {
      play();
      console.log('playSound');
    } else {
      pause();
    }
  }, [soundOn]);

  return <div></div>;
};

export const SoundManager = (props) => {
  const soundOn = useSelector(state => state.soundOn);
  const sfxRefs = [useRef(), useRef()]; // ballmove, laser, shuffle
  const getSFX = useSelector((state)=>state.curSFX);
//   const sfx = useSelector(state => state.sfx);
  // var sfxRefs = useSound();
  const [isSound, setisSound] = useState(false);
  const [sndBtn, setsndBtn] = useState(false);

  useEffect(() => {
    sfxRefs[define.SFX_BALLMOVE].current = new Audio(ballmoveSound);
    sfxRefs[define.SFX_LASER].current = new Audio(laserSound);

    sfxRefs[define.SFX_BALLMOVE].current.pause();
    sfxRefs[define.SFX_LASER].current.pause();

    
    setTimeout(() => {      
      setsndBtn(true);
    }, 5000);
  }, []);

  useEffect(() => {
    if (getSFX && getSFX.sfx >= 0) 
    {
      // console.log('GET SFX START:' + getSFX.sfx);
      if (sfxRefs[getSFX.sfx].current && soundOn) 
      {
        
        // console.log('GET SFX END:' + getSFX.sfx);
        sfxRefs[getSFX.sfx].current.play();
      }

    }        
  }, [getSFX]);

  const dispatch = useDispatch();

  

  return (
    <div>
      { sndBtn===true && <button
        className="absolute top-2 left-2 bg-indigo-400 w-[40px] h-[40px] rounded-xl z-[12] flex flex-col justify-center items-center cursor-pointer border-white/30 border hover:bg-indigo-500"
        onClick={() => {
          dispatch({ type: 'SOUND', soundOn: 0 });
          setisSound(!isSound);
        }}
      >
        <img src={isSound ? Sound : SoundMute } alt="sound" />
      </button>
      }

      {/* <button
        onClick={() => {
          dispatch({ type: 'SOUND', soundOn: 0 });
          isSoundOn = !isSoundOn;
          console.log('soundOn : ' + isSoundOn);

          if(isSoundOn)
          {
              bgmplay();
              shufflePlay();
          }
          else
          {
              pause();
              pauseShuffle();
          }
          ShuffleSound(isSoundOn, customUseSound);
        }}
      >
        Sound On/Off
      </button> */}

      <BgmSound
        bgm={props.bgm}
      >   
      </BgmSound>
      {/* <ShuffleSound>                    
            </ShuffleSound> */}
    </div>
  );
};

export default SoundManager;
