import styles from './cta.module.css';
import Arrow from '@/img/landingPage/arrowButton.svg';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

import useLang from '@/lib/hook/useLang';
import { useLocation } from 'react-router-dom';
import { PlayGame, GetDefaultGoods } from '../../../../../../horsestall/progresscombine/jsUtil_combine';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';
const CTABetButton = () => {
  const { lngT } = useLang('landingPage:guideSection');
  const navigate = useCustomNavigate();
  const { search } = useLocation();
  const [user] = useUser();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);
  return (
    <div className={styles.container}>
      <button onClick={() => 
        PlayGame(navigate, user?.name, GetDefaultGoods(balance?.data))
        } className={styles.buttonBet}>
        <img src={Arrow} alt="Make a bet horse" className={styles.arrow} />
        <div className={styles.description}>
          <h3>{lngT('guideBet:guide:betTitle')}</h3>
          <p>{lngT('guideBet:guide:betSubTitle')}</p>
        </div>
      </button>
    </div>
  );
};

export default CTABetButton;
