import { useState, useEffect } from 'react';
import tw from 'tailwind-styled-components';

import * as config from '../config';
import { useLocation } from 'react-router-dom';
import useUser from '../lib/hook/useUser';
import useLang from '../lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

import Facebook from '@/img/facebook.svg';
import Instagram from '@/img/instagram.svg';
import Telegram from '@/img/telegram.svg';
import HorseBanner from '@/img/landingPage/horsebanner.png';
import UpodiumWhite from '@/img/landingPage/upodium.png';
import Arrow from '@/img/landingPage/arrowButton.svg';

import styles from '../css/footer.module.css';
import { GetSNSLink } from '../util/serviceInfo';
import AnjouanLicenseLogo from './AnjouanLicenseLogo';
const SmallTitle = tw.h4`
    text-white text-lg font-bold pb-6
  `;

const Text = tw.p`
  text-white font-light cursor-pointer hover:text-white/90 transition-color
  `;

const AtagText = tw.a`
  text-white font-light cursor-pointer hover:text-white/90 transition-color
`;

export default function Footer() {
  const { lngT } = useLang('landingPage:footer');
  const { lngT: etclngT } = useLang('landing:footer');

  const { pathname } = useLocation();
  const navigate = useCustomNavigate();
  const [user, { isLoading, error, logout }] = useUser();

  const MenuItem = ({ link, title }) => <Text onClick={() => navigate(link)}>{title}</Text>;

  const HomeMenus = [
    { link: '/', title: etclngT('nav:1') },
    { link: '/community/freeboard', title: etclngT('nav:5:menu:4') },
    { link: '', title: etclngT('nav:5:menu:5') },
    { link: '/contact/faq', title: etclngT('nav:5:menu:1') },
    { link: '/contact/question', title: etclngT('nav:5:menu:2') },
    { link: '/terms', title: lngT('copyRight:terms') },
    { link: '/privacy', title: lngT('copyRight:privacy') },
  ];

  const [isMobile, setIsMobile] = useState(false);
  const [mobileHomeMenuView, setmobileHomeMenuView] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 769 ? setIsMobile(true) : setIsMobile(false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    let tempList = [];
    HomeMenus.forEach(element => {
      tempList.push(element.link === '' ? <a>{element.title}</a> : <a href={element.link}>{element.title}</a>);
    });
    setmobileHomeMenuView(tempList);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (
    pathname.includes('7CkmEx76EWp') ||
    pathname.includes('DIEHB7O3WI5II') ||
    pathname.includes('GameOnePage') ||
    pathname.includes('video')
  ) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <footer
          className={styles.container}
          style={{
            background: `${
              Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING)
                ? 'linear-gradient(270.12deg, #3e095f 0.13%, #1e0e3f 37.45%, #310c53 81.63%, #46096a 99.93%)'
                : '#000'
            }`,
          }}
        >
          {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? (
            <img src="../../../../../images/newLogo.png" width={100} height={100} alt={'logo white'} />
          ) : (
            <img src="/images/upowerball/up7bet_logo.png" width={140} height={140} alt={'logo powerball'} />
          )}
          <div className={styles.socialLinks}>
            <a href={GetSNSLink('Telegram')} className={styles.icons}>
              <img width={20} height={20} src={Telegram} alt={'Telegram'} />
            </a>
            <a href={GetSNSLink('Instagram')} className={styles.icons}>
              <img width={20} height={20} src={Instagram} alt={'Instagram'} />
            </a>
            <a href={GetSNSLink('Facebook')} className={styles.icons}>
              <img width={10} height={20} src={Facebook} alt={'Facebook'} />
            </a>
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={() => navigate('login')} className={styles.loginBtn}>
              {lngT('login')}
            </button>
            <button onClick={() => navigate('signup')} className={styles.registerBtn}>
              {lngT('signUp')}
            </button>
          </div>

          <div className={styles.links}>
            <div className={styles.linkGroup}>
              {mobileHomeMenuView}
              {/* {HomeMenus.forEach(element => {
                  return (<a href={element.link}>{element.title}</a>)
                  // element.link === '' ?
                  // <div>{element.title}</div> : 
                  // <a href={element.link}>{element.title}</a>
                })} */}

              {/* {HomeMenus.map(({ link, title }) => (
                  <a href={link}>{title}</a>
                  // <MenuItem key={title} link={link} title={title} />
                ))}      */}
            </div>
          </div>

          {/* <div>
            <div className={styles.links}>
              <div className={styles.linkGroup}>
                <h4> {lngT('nav:home:title')}</h4>
                <a href="">{lngT('nav:home:1')}</a>
                <a href="/signup">{lngT('nav:home:3')}</a>
              </div>

              <div className={styles.linkGroup}>
                <h4>{lngT('nav:contact:title')}</h4>
                <a href="#">{lngT('nav:contact:1')}</a>
                <a href={GetSNSLink('Telegram')}>{lngT('nav:contact:2')}</a>
              </div>

              <div className={styles.linkGroup}>
                <h4>{lngT('nav:community:title')}</h4>
                <a href={GetSNSLink('Telegram')}>{lngT('nav:community:1')}</a>
              </div>
            </div>
          </div> */}

          {/* <div className={styles.terms}>
            <a target="_blank" href="/privacy">
              {lngT('copyRight:privacy')}
            </a>
            <a target="_blank" href="/terms">
              {lngT('copyRight:terms')}
            </a>
          </div> */}

          <div className="flex justify-center">
            <AnjouanLicenseLogo />
          </div>
          <a className={styles.copyright}>
            {lngT('copyRight:p:1')}
            {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? '© 2023 U.podium. ' : '© 2024 Up7BET '}
            {lngT('copyRight:p:2')}
          </a>
        </footer>
      ) : (
        <div
          className="w-full px-6 sm:px-10 md:px-16 lg:px-32 min-[1720px]:px-[256px] py-[50px]"
          style={{
            background: `${
              Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING)
                ? 'linear-gradient(270.12deg,#3e095f 0.13%,#1e0e3f 37.45%,#310c53 81.63%,#46096a 99.93%)'
                : '#000'
            }`,
          }}
        >
          <div className="space-y-8">
            <div className="flex flex-row items-center justify-between">
              <img src="/images/upowerball/up7bet_logo.png" width={147} height={32} alt={'logo powerball'} />
              {!user ? (
                <div className="flex gap-4">
                  <button
                    className="px-3 py-2 border-[2px] border-white cursor-pointer rounded-[20px] w-[157px] h-[42px] font-semibold text-white text-[16px]"
                    alt=""
                    onClick={() => navigate('login')}
                  >
                    {lngT('login')}
                  </button>
                  <button
                    className="px-3 py-2 bg-white border-[1px] border-black cursor-pointer rounded-[20px] w-[157px] h-[42px] font-semibold text-[#9c4eff]"
                    alt=""
                    onClick={() => navigate('login')}
                  >
                    {lngT('signUp')}
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="space-y-8 flex xl:flex-row">
              <div className="w-full flex flex-row  max-D:flex-col  justify-between items-start gap-11">
                <div className="space-y-4">
                  <div className="flex felx-row items-center text-xl gap-10">
                    <a
                      href={GetSNSLink('Telegram')}
                      alt="Telegram"
                      className="bg-[#7d00ff] hover:bg-[#b173ff] flex items-center justify-center w-[60px] h-[60px] rounded-xl"
                    >
                      <img width={20} height={20} src={Telegram} alt={'Telegram'} />
                    </a>
                    <a
                      href={GetSNSLink('Instagram')}
                      alt="Instagram"
                      className="bg-[#7d00ff] hover:bg-[#b173ff] flex items-center justify-center w-[60px] h-[60px] rounded-xl"
                    >
                      <img width={20} height={20} src={Instagram} alt={'Instagram'} />
                    </a>
                    <a
                      href={GetSNSLink('Facebook')}
                      alt="Facebook"
                      className="bg-[#7d00ff] hover:bg-[#b173ff] flex items-center justify-center w-[60px] h-[60px] rounded-xl"
                    >
                      <img width={10} height={20} src={Facebook} alt={'Facebook'} />
                    </a>
                  </div>
                </div>
                <div className="w-fit flex md:justify-between gap-10 text-white"></div>
              </div>
            </div>
            <br></br>
            <div className="block border-b-[1.0px] border-white/120" />
            <div className="flex flex-row justify-between">
              <div className="w-fit flex md:justify-between gap-10 text-white">
                {HomeMenus.map(({ link, title }) => (
                  <MenuItem key={title} link={link} title={title} />
                ))}
              </div>
            </div>
            <div className="block border-b-[1.0px] border-white/120" />
            <div className="flex flex-col justify-between">
              <div className="flex justify-center">
                <AnjouanLicenseLogo />
              </div>
              <p className="text-white font-light py-4 text-xs">
                {lngT('copyRight:p:1')}
                {Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? '© 2023 U.podium. ' : '© 2024 Up7BET '}
                {lngT('copyRight:p:2')}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
