import React, { Component } from 'react';
import { t } from "i18next";
import "../css/betting.css";
import * as jsUtil from "../util/jsUtil";
const tbodywd1 = '25%';
const tbodywd2 = '25%';
const tbodywd3 = '20%';
const tbodywd4 = '30%';

class ResultTable extends Component {
    render() {
        const{bettingresult, userbettingresult} = this.props;        
        var index = 0;
        var bet_no_bettings=[];
        var bet_betting_values=[];
        var bet_reward_rates=[];
        var bet_reward_values=[];
        var no_bettings=[];
        var betting_values=[];
        var reward_rates=[];
        var reward_values=[];        
        var betLength = bettingresult.length;

        if(betLength>0)
        {
            for(let i = 0; i < betLength; ++i)
            {            
                //bettingresult[`${betLength-1-i}`].forEach((obj)=>
                bettingresult[i].forEach((obj)=>
                {
                    ++index;

                    if(index==1)
                    {
                        bet_no_bettings.push(
                        <div className='tb-header-th bg-red'>{t('victory_number')}</div>)                        
                    }

                    bet_no_bettings.push(
                        <li className='tb-body-td'>{jsUtil.GetNumberName(obj.type,obj.no, true)}{t('number_horse')}</li>                            
                        )

                    if(index==1)
                    {
                        bet_betting_values.push(
                        <div className='tb-header-th bg-yellow'>{t('id')}</div>)
                    }
                    bet_betting_values.push(
                    <li className='tb-body-td'>{jsUtil.ContractName(obj.nameUser)}</li>)

                    if(index==1)
                    {
                        bet_reward_rates.push(
                        <div className='tb-header-th bg-blue'>{t('bet_rate')}</div>)
                    }
                    bet_reward_rates.push(
                    <li className='tb-body-td'>X{jsUtil.Round(obj.odds, 2)}</li>)

                    if(index==1)
                    {
                        bet_reward_values.push(
                        <div className='tb-header-th bg-purple'>{t('price')}</div>)
                    }
                    bet_reward_values.push(
                        <li className='tb-body-td'>{jsUtil.Round(obj.reward, 2)}</li>)      
                });
            }
        }

        var bettinglist = <div className='tb-body'>                    
            <ul className='tb-body-tr color-red' style={{width:tbodywd1}}>
                {bet_no_bettings}
            </ul>
            <ul className='tb-body-tr color-yellow' style={{width:tbodywd2}}>
                {bet_betting_values}
            </ul>
            <ul  className='tb-body-tr color-blue' style={{width:tbodywd3}}>
                {bet_reward_rates}
            </ul>
            <ul  className='tb-body-tr color-purple' style={{width:tbodywd4}}>
                {bet_reward_values}
            </ul>
        </div>

        var userBetLength = userbettingresult == undefined ? 0 : userbettingresult.length;
        index = 0;
        if(userbettingresult != undefined && userbettingresult.length > 0)
        {
            userbettingresult.forEach((obj)=>{
                ++index;
                if(index===1)
                {
                    no_bettings.push(
                    <div className='tb-header-th bg-red'>{t('victory_number')}</div>) 
                }
                
                no_bettings.push(
                    <li className='tb-body-td'>{jsUtil.GetNumberName(obj.type,obj.no, true)}{t('number_horse')}</li>                            
                    )
    
                if(index===1)
                {    
                    betting_values.push(         
                    <div className='tb-header-th bg-yellow'>{t('betting_price')}</div>)
                }
                betting_values.push(<li className='tb-body-td'>{obj.value}</li>)
                
                if(index===1)
                {
                    reward_rates.push(
                    <div className='tb-header-th bg-blue'>{t('bet_rate')}</div>)
                }
                reward_rates.push(<li className='tb-body-td'>X{jsUtil.Round(obj.odds, 2)}</li>)
                
                if(index===1)
                {
                    reward_values.push(
                    <div className='tb-header-th bg-purple'>{t('price')}</div>)
                }
                reward_values.push(<li className='tb-body-td'>{jsUtil.Round(obj.reward,2)}</li>)             
            });
        }        

        var userResult = (userbettingresult != undefined && userbettingresult.length > 0) ? 
            <div className='table_box'>
                <h3>{t('my_result')}</h3>
                <div className='tb-body'>
                    <ul className='tb-body-tr color-red' style={{width:tbodywd1}}>
                        {no_bettings}
                    </ul>
                    <ul className='tb-body-tr color-yellow' style={{width:tbodywd2}}>
                        {betting_values}
                    </ul>
                    <ul  className='tb-body-tr color-blue' style={{width:tbodywd3}}>
                        {reward_rates}
                    </ul>
                    <ul  className='tb-body-tr color-purple' style={{width:tbodywd4}}>
                        {reward_values}
                    </ul>
                </div>
            </div> : "";

        var renderInfo = bet_no_bettings.length == 0 && userBetLength == 0 ? <h3>No Bet Result</h3> : 
        <div className="betting betting-result">
            <div className='table_box'>
                <h3>{t('total_victory_list')}</h3>
                {bettinglist}
            </div>                
            {userResult}                
        </div>
    
        return (
            <div>
                {renderInfo}
            </div>
        );
    }
}

export default ResultTable;