import tw from 'tailwind-styled-components';

const Layer = tw.div`
  flex flex-row
  w-full T:h-[150px]
  bg-white/10
  rounded-md
  border border-white/10
  cursor-pointer
  hover:opacity-90
`;

const InnerLayer = tw.div`
  flex flex-col T:flex-row
  w-full h-full
  p-1 gap-2
`;

const ImageLayer = tw.div`
  relative
  h-full aspect-square
  bg-white/10
  rounded-md
  overflow-hidden
  p-1
`;

export const Style = {
  Layer,
  InnerLayer,
  ImageLayer,
};
