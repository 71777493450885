import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { useNavigate } from 'react-router-dom';

import { WEBSERVERPORT } from '../../config';
import useLang from '../../lib/hook/useLang';
import useUser from '../../lib/hook/useUser';

const Container = tw.div`
  D:mx-[64px]
`;

const QuestionWriteBtn = tw.div`
  flex justify-center items-center
  h-[48px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

export default function FreeBoard() {
  const { lngT } = useLang('community:freeboard');
  const navigate = useNavigate();
  const [user] = useUser();
  const { data: freeboardData, isLoading, error } = useSWR(`${process.env.REACT_APP_API_URL}/freeboard`);
  const { data: noticeData } = useSWR(`${process.env.REACT_APP_API_URL}/notice/discussionBoardNotices`);
  console.log(freeboardData);
  console.log(noticeData);
  return (
    <Container>
      {/* <div className="hidden D:flex flex-row D:justify-between items-center text-[24px] font-medium text-white px-[6px] D:pb-[24px]">
        <div className="text-[20px]"> {lngT('freeBoard')}</div>
        <QuestionWriteBtn
          className="text-[14px] px-[20px] rounded-xl"
          onClick={() => navigate(user ? '/community/freeboard/write' : '/login')}
        >
          {lngT('makePost')}
        </QuestionWriteBtn>
      </div> */}
      <div className="hidden D:flex flex-row border-t-2 border-b-[1px] border-white/20 py-[8px] text-white/80 text-[14px] font-medium uppercase">
        <div className="flex flex-1 pl-[15px] items-center">{lngT('title')}</div>
        <div className="flex items-center w-[170px]">{lngT('author')}</div>
        <div className="flex items-center w-[80px]">{lngT('date')}</div>
      </div>
      {noticeData?.data?.discussionBoardNotices?.map(item => (
        <div key={item.id}>
          <div
            className="flex T:flex-row py-[16px] text-white px-[16px] text-[14px] font-medium space-y-[10px] T:space-y-0 cursor-pointer hover:bg-[#FF6058]/20 hover:opacity-80"
            onClick={() => navigate(`/community/freeboard/notice/${item.id}`)}
          >
            <div className="text-[16px] font-medium T:flex-1 text-[#FF6058] pr-2">[Notice] &nbsp; {item.title}</div>
            <div className="flex flex-row space-x-3 D:justify-between justify-end items-center">
              <div className="flex flex-row items-center space-x-[8px] T:w-[150px]">
                <div className="hidden T:block text-[14px] text-white/70">U-podium</div>
              </div>
              <div className="hidden T:block text-white/70 T:text-[14px] T:w-[85px]">
                {new Date(item.createdAt).toISOString().substring(0, 10)}
              </div>
            </div>
          </div>
          <div className="block border-b-[0.5px] border-white/20" />
        </div>
      ))}
      {freeboardData?.freeBoardItems?.map(item => (
        <>
          <div
            key={item.id}
            className="flex flex-col T:flex-row py-[16px] text-white px-[16px] text-[14px] font-medium space-y-[4px] T:space-y-0 cursor-pointer hover:bg-white/10 hover:opacity-70"
            onClick={() => navigate(`/community/freeboard/${item.id}`)}
          >
            <div className="text-[16px] font-normal T:flex-1 pr-2">{item.title}</div>
            <div className="flex flex-row space-x-3 D:justify-between justify-end items-center">
              <div className="flex flex-row items-center space-x-[8px] T:w-[150px]">
                <img className="w-[18px] h-[18px]" src={item.author.imageUrl ?? '/images/icons/avatar-default.svg'} />
                <div className="text-[14px] text-white/70">{item.author.name}</div>
              </div>
              <div className="text-white/70 text-[14px] T:w-[85px]">
                {new Date(item.date).toISOString().substring(0, 10)}
              </div>
            </div>
          </div>
          <span className="block border-b-[0.5px] border-white/20"></span>
        </>
      ))}
      <QuestionWriteBtn
        className="fixed bottom-0 w-full T:hidden"
        onClick={() => navigate(user ? '/community/freeboard/write' : '/login')}
      >
        {lngT('makePost')}
      </QuestionWriteBtn>
    </Container>
  );
}
