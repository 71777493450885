import useLang from '@/lib/hook/useLang';
import { SpineRenderCommonByID } from '../../util/SpineRenderCommon';
import * as define from '../../define';

type Props = {
  onClick: () => void;
};

export default function CoinDropButton({ onClick }: Props) {
  const { currentLang } = useLang();

  if(!define.SHOW_METACROSS_ONLY)
    return;

  return (
    <div className="flex flex-row justify-end flex-1 px-1" onClick={onClick}>
      <SpineRenderCommonByID
        path="powerball/landing/free_button"
        file="free_button"
        aniName={currentLang === 'en' ? 'bp_en_ani' : 'bp_po_ani'}
        id="player-container4"
        className="cursor-pointer rounded-full text-purple-600 w-[100px] T:w-[120px] h-16 flex flex-row items-center justify-end mt-2"
      ></SpineRenderCommonByID>
    </div>
  );
}
