import styles from './guideSectionApostas.module.css';
import HorseImage from '@/img/landingPage/horse_image.webp';
import MakeABetWithOutAHorse from '@/img/landingPage/makeABetWithoutHorse.svg';
import Arrow from '@/img/landingPage/arrowButton.svg';

import useLang from '@/lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useLocation } from 'react-router-dom';
import { PlayGame, GetDefaultGoods } from '../../../horsestall/progresscombine/jsUtil_combine';
import useSWR from 'swr';
import { useUser } from '@/lib/hook';

export default function GuideSectionApostas() {
  const { lngT } = useLang('landingPage:guideSection');
  const { search } = useLocation();
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);

  return (
    <div className="flex flex-col items-start py-4 space-y-4 max-[1280px]:ml-[80px] max-[922px]:ml-[20px] max-[922px]:pt-[100px]">
      <header className="relative flex items-center gap-4 -left-[60px] ">
        <img
          src={HorseImage}
          alt="Cavalo"
          width={140}
          className="z-10 absolute -left-[30px] rounded-full max-[922px]:left-[50px] max-[922px]:-top-[150px] "
        />
        <div className="text-[32px] font-bold pl-[120px] uppercase max-[922px]:text-[28px] max-[922px]:pl-[60px] h-[80px]">
          {lngT('guideBet:header:title')}
        </div>
      </header>

      <div className="pl-[40px] pt-[50px] space-y-2 max-[922px]:py-4 max-[922px]:pl-0">
        <p className="text-[25px] font-bold uppercase max-[1280px]:text-[20px]">{lngT('guideBet:header:subTitle')}</p>
        <p className="text-[16px]">{lngT('guideBet:header:subsubTitle')}</p>
      </div>

      <div className="relative space-y-6">
        <div className="absolute w-[3.5px] bg-[#9c4eff] h-[660px] -left-6 -top-[130px] z-1  max-[922px]:hidden"></div>
        <div className="relative flex flex-col w-[440px] bg-[#1a0234] rounded-[16px] py-[30px] px-[40px] h-[180px] max-[1280px]:w-[340px] max-[1280px]:h-[200px] max-[1280px]:px-[28px]">
          <div className="absolute text-[36px] text-[#9c4eff] bg-[#260844] border-[3px] border-[#9c4eff] flex justify-center items-center font-bold rounded-full w-[75px] h-[75px] -left-[60px] top-[50px] max-[1280px]:text-[30px] max-[1280px]:h-[60px] max-[1280px]:w-[60px] max-[1280px]:-left-[53px]  max-[922px]:-left-4  max-[922px]:-top-8">
            01
          </div>
          <div className="space-y-1">
            <div className="text-[#a36aff] text-[24px] font-bold max-[1280px]:text-[20px] uppercase">
              {lngT('guideBet:guide:1:title')}
            </div>
            <div className="">
              {lngT('guideBet:guide:1:description')}
              &nbsp;
              <a onClick={() => PlayGame(navigate, user?.name)} className="cursor-pointer text-[#2ff3ff]">
                {lngT('guideBet:guide:1:a')}
              </a>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col w-[440px] bg-[#1a0234] rounded-[16px] py-[30px] px-[40px] h-[180px] max-[1280px]:w-[340px] max-[1280px]:h-[200px] max-[1280px]:px-[28px]">
          <div className="absolute text-[36px] text-[#9c4eff] bg-[#260844] border-[3px] border-[#9c4eff] flex justify-center items-center font-bold rounded-full w-[75px] h-[75px] -left-[60px] top-[50px] max-[1280px]:text-[30px] max-[1280px]:h-[60px] max-[1280px]:w-[60px] max-[1280px]:-left-[53px] max-[922px]:-left-4  max-[922px]:-top-8">
            02
          </div>
          <div className="space-y-1">
            <div className="text-[#a36aff] text-[24px] font-bold max-[1280px]:text-[20px]">
              {lngT('guideBet:guide:2:title')}
            </div>
            <div className="">{lngT('guideBet:guide:2:description')}</div>
          </div>
        </div>
        <div className="relative flex flex-col w-[440px] bg-[#1a0234] rounded-[16px] py-[36px] px-[40px] h-[220px] max-[1280px]:w-[340px] max-[1280px]:h-[240px] max-[1280px]:px-[28px]">
          <div className="absolute text-[36px] text-[#9c4eff] bg-[#260844] border-[3px] border-[#9c4eff] flex justify-center items-center font-bold rounded-full w-[75px] h-[75px] -left-[60px] top-[50px] max-[1280px]:text-[30px] max-[1280px]:h-[60px] max-[1280px]:w-[60px] max-[1280px]:-left-[53px] max-[922px]:-left-4  max-[922px]:-top-8">
            03
          </div>
          <div className="space-y-1">
            <div className="text-[#a36aff] text-[24px] font-bold max-[1280px]:text-[20px]">
              {lngT('guideBet:guide:3:title')}
            </div>
            <div className="">
              <strong>{lngT('guideBet:guide:3:strong')}</strong> {lngT('guideBet:guide:3:description')}
            </div>
          </div>
        </div>
      </div>
      {/* <Image src={MakeABetWithOutAHorse} alt="Cavalo" className={styles.betImage} />
       */}
      <a
        onClick={
          // () => navigate('/LobbyGame')
          () => PlayGame(navigate, user?.name, GetDefaultGoods(balance?.data))
        }
        className="flex flex-start pt-[50px] "
      >
        <div
          className="w-[446px] h-[100px] rounded-2xl cursor-pointer text-[18px] relative font-bold flex pl-8 gap-5 items-center max-[1280px]:w-[340px]"
          style={{
            boxShadow: '-15px 2px 85px 19px rgba(107, 19, 216, 1) inset',
            background: 'linearGradient(101.61deg, rgba(47, 243, 255, 0.78) -14.72%, rgba(77, 244, 255, 0.67) 66.97%)',
          }}
        >
          <img src={Arrow} alt="Arrow" width={50} className="" />
          <div className="">
            <p className="uppercase text-[28px] max-[1280px]:text-[24px]">{lngT('guideBet:guide:betTitle')}</p>
            <p className="text-[1rem] font-medium">{lngT('guideBet:guide:betSubTitle')}</p>
          </div>
        </div>
      </a>
    </div>
  );
}
