import tw from 'tailwind-styled-components';
import useSWR from 'swr';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import useLang from '@/lib/hook/useLang';
import useUser from '../../lib/hook/useUser';
import { useModalStore } from '@/lib/custom-lib/modal';
import { API_URL } from '@/env';

import UserPoints from '../../components/userInfo/UserPoints';
import Pincode from '@/components/Pincode';
import StakeHorseOptionItem from '../../components/StakeHorseOptionItem';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

const Line = tw.span`
  border border-white/20 block
`;

const Button = tw.button`
  flex justify-center items-center
  h-[40px] w-1/3 T:w-[200px] rounded-lg
  bg-[#860099]
  text-white
  cursor-pointer
`;

const Text = tw.p`
  text-white text-lg
`;

const HeadText = tw.p`
  text-white/40 text-xs font-extralight
`;

const UnitText = tw.p`
text-white/80 text-xs font-light`;

const Stake = () => {
  const { lngT } = useLang('horse:stake');
  const [user] = useUser();
  const { openModal } = useModalStore();
  const navigate = useCustomNavigate();
  const { data } = useSWR<{ stakeTypes: StakeType[] }>(`${process.env.REACT_APP_API_URL}/horse/stake/type`);

  const handleClickStakeHorse = (stakeType: StakeType) => () => {
    openModal(<StakeDetailPopup stakeType={stakeType} />, false);
  };

  const handleClickPage = () => {
    navigate('/mypage/Account');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, []);

  return (
    <div className="flex flex-col rounded-xl D:ml-[64px]">
      <div className="hidden D:flex flex-row T:justify-between items-center text-[24px] font-medium text-white T:pb-[24px]">
        <div className="text-[20px]">{lngT('title')}</div>
      </div>
      <div className="flex justify-center max-[400px]:h-fit min-h-screen">
        <div className="flex flex-col w-full  space-y-[40px]">
          <section className="w-full flex flex-col mt-[8px] space-y-[30px]">
            <div className="w-full flex flex-col space-y-[30px]">
              {user ? (
                <div className="w-full flex flex-col T:flex-row items-end T:space-x-2 space-y-2">
                  <div className="relative w-full T:w-4/5">
                    <div>
                      <UserPoints />
                    </div>
                  </div>
                  <Button className="w-full T:w-1/5 text-[14px] rounded-lg" onClick={handleClickPage}>
                    {/*Charge Points*/ lngT('charge')}
                  </Button>
                </div>
              ) : (
                <div className="w-full flex flex-row T:justify-center T:flex-col items-end T:space-x-2 space-y-2">
                  <div className="relative flex flex-col justify-center items-center space-y-4 w-full">
                    <div className="text-white/80">
                      {/*If you log in, you can lease a horse and enjoy the game right now*/ lngT('login')}
                    </div>
                    <Button className="w-full T:w-1/5 text-[14px] rounded-lg" onClick={handleClickPage}>
                      {/*Charge Points*/ lngT('charge')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <Line />
            <div className="flex flex-col space-y-10 justify-center items-center LD:space-y-20">
              <h2 className="text-white font-medium mt-10 text-3xl"> {/*Lease Option*/ lngT('leaseOption')}</h2>
              <div className="T:space-x-1 mt-[8px] LD:mb-0 T:grid T:grid-cols-3 LD:gap-20">
                {data?.stakeTypes.map(stakeType => (
                  <StakeHorseOptionItem
                    key={stakeType.id}
                    stakeType={stakeType}
                    onClick={handleClickStakeHorse(stakeType)}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Stake;

const StakeDetailPopup = ({ stakeType }: { stakeType: StakeType }) => {
  const { lngT } = useLang('horse:stake');
  const navigate = useNavigate();
  const { closeModal } = useModalStore();
  const { data } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleClickPay = async () => {
    if (isButtonDisabled) {
      return;
    }
    setIsButtonDisabled(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/horse/stake`,
        {
          stakeTypeId: stakeType.id,
          price: stakeType.stakePoint,
        },
        { withCredentials: true }
      );
      if (data.ok) {
        closeModal();
        toast(/*Leased successfully.*/ `${lngT('success')}`, { theme: 'dark', type: 'success' });
        navigate('/horse/info');
      }
    } catch (error) {
      toast(/*Failed to lease.*/ `${lngT('fail')}`, { theme: 'dark', type: 'error' });
      console.log(error);
    } finally {
      setPaymentLoading(false);
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="relative flex flex-col w-[440px] h-fit text-white p-8 border border-[#8E00CB]/30 bg-[#1C012E] rounded-lg">
      <button className="absolute top-2 right-2 text-white" onClick={closeModal}>
        <img src="../../images/icons/close.svg" />
      </button>
      <div className="flex flex-col items-center justify-center space-y-6">
        <div
          className={`text-2xl font-medium ${
            stakeType.title === 'Regular'
              ? ' text-amber-600/60 border-amber-600/60'
              : stakeType.title === 'Siver'
              ? 'text-gray-400 border-gray-400'
              : stakeType.title === 'Gold'
              ? ' text-yellow-500 border-yellow-500'
              : ''
          }`}
        >
          {stakeType.title}
        </div>
        <div className="flex flex-col items-stretch justify-start w-full bg-[#EEC3FF]/5 rounded-md pt-6 pb-2 px-10">
          <div className="flex flex-row justify-start space-x-4">
            <div className="w-[120px] mb-4">
              <HeadText>{/*Price*/ lngT('popup:price')}</HeadText>
              <div className="flex flex-row items-center space-x-1">
                <Text>{stakeType.stakePoint.toLocaleString()} </Text>
                <UnitText> {/*points*/ lngT('points')}</UnitText>
              </div>
            </div>
            <div className="w-[120px] mb-4">
              <HeadText>{/*Performance*/ lngT('popup:performance')}</HeadText>
              <div className="flex flex-row items-center space-x-1">
                <Text>{Math.floor(((stakeType.returnPoint - stakeType.stakePoint) * 100) / stakeType.stakePoint)}</Text>
                <UnitText>%</UnitText>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-start space-x-4">
            <div className="w-[120px] mb-4">
              <HeadText>{/*Reward*/ lngT('popup:reward')} </HeadText>
              <div className="flex flex-row items-center space-x-1">
                <Text>{stakeType.returnPoint.toLocaleString()} </Text>
                <UnitText> {/*points*/ lngT('points')}</UnitText>
              </div>
            </div>
            {/* <div className="w-[120px] mb-4">
              <HeadText> lngT('popup:payment')}</HeadText>
              <div className="flex flex-row items-center space-x-1">
                <Text>{stakeType.stakePoint.toLocaleString()} </Text>
                <UnitText> {lngT('points')}</UnitText>
              </div>
            </div> */}
            <div className="mb-4">
              <HeadText>{/*Current Points*/ lngT('popup:current')}</HeadText>
              <div className="flex flex-row items-center space-x-1">
                <Text> {data.data.coin?.toLocaleString()}</Text>
                <UnitText>{/*points*/ lngT('points')}</UnitText>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start"></div>
        </div>
        {data.data.coin >= stakeType.stakePoint ? (
          <Button onClick={handleClickPay} disabled={isButtonDisabled}>
            {paymentLoading ? /*Loading.....*/ `${lngT('popup:loading')}` : /*Pay*/ `${lngT('popup:pay')}`}
          </Button>
        ) : (
          <div className="flex flex-col">
            <div className="flex justify-center text-[#FF5656]/80 text-sm font-light pb-4">
              {/*Insufficient available points.*/ lngT('popup:insufficient')}
            </div>
            <Button
              className="bg-[#8E00CB]"
              onClick={() => {
                navigate('/mypage/account');
                closeModal();
              }}
            >
              {/*Charge Point*/ lngT('charge')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
