export const fetcher = url => fetch(url, { credentials: 'include' }).then(r => r.json());

export const debounce = (callback, delay = 400) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback.apply(this, args), delay);
  };
};

function pad(number, length) {
  var str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}

export const getTimestamp = () => {
  var today = new Date();
  var yyyy = today.getFullYear().toString();
  var MM = pad(today.getMonth() + 1, 2);
  var dd = pad(today.getDate(), 2);
  var hh = pad(today.getHours(), 2);
  var mm = pad(today.getMinutes(), 2);
  var ss = pad(today.getSeconds(), 2);

  return yyyy + MM + dd + hh + mm + ss;
};

export const getBettingResultValue = (rank, type, no, lngT) => {
  if (rank === null) return '';
  rank = JSON.parse(rank);
  const winOdd = rank[0] % 2 === 1 ? /*'ODD'*/ lngT('contents:odd') : /*'EVEN'*/ lngT('contents:even');
  const winUnder = rank[0] <= 6 ? /*'UNDER'*/ lngT('contents:under') : /*'OVER'*/ lngT('contents:over');
  const winOddUnder =
    rank[0] % 2 === 1
      ? rank[0] <= 6
        ? /*'ODD UNDER'*/ lngT('contents:oddUnder')
        : /*'ODD OVER'*/ lngT('contents:oddOver')
      : rank[0] <= 6
      ? /*'EVEN UNDER'*/ lngT('contents:evenUnder')
      : /*'EVEN OVER'*/ lngT('contents:evenOver');
  const winOddSmall =
    rank[0] % 2 === 1
      ? rank[0] <= 4
        ? /*'ODD SMALL'*/ lngT('contents:oddSmall')
        : rank[0] <= 8
        ? /*'ODD MEDIUM'*/ lngT('contents:oddMedium')
        : /*'ODD LARGE'*/ lngT('contents:oddLarge')
      : rank[0] <= 4
      ? /*'EVEN SMALL'*/ lngT('contents:evenSmall')
      : rank[0] <= 8
      ? /*'EVEN MEDIUM'*/ lngT('contents:evenMedium')
      : /*'EVEN LARGE'*/ lngT('contents:evenLarge');
  const winSingle = `${rank[0]} - ${lngT('contents:single')}`; //SINGLE`
  const winShow = `${rank[0]}, ${rank[1]}, ${rank[2]} - ${lngT('contents:show')}`; //SHOW`
  const listIndexType0 = [2, 4, 8];

  return type === 'oddeven'
    ? no <= listIndexType0[0]
      ? winOdd
      : no <= listIndexType0[1]
      ? winUnder
      : no <= listIndexType0[2]
      ? winOddUnder
      : winOddSmall
    : type === 'single'
    ? winSingle
    : type === 'show'
    ? winShow
    : '';
};

export function formatDateToLocal(date) {
  // 현지 시간으로 변환
  const localDate = new Date(date);

  // 년, 월, 일, 시간, 분 추출
  const year = localDate.getFullYear();
  const month = String(localDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = String(localDate.getDate()).padStart(2, '0');
  const hours = String(localDate.getHours()).padStart(2, '0');
  const minutes = String(localDate.getMinutes()).padStart(2, '0');

  // 포맷에 맞게 반환
  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
