'use client';

import { useState } from 'react';
import styles from './faq.module.css';
import VideoOverlay from '../videoOverlay';
import ApostouGanhou from './betWin';

import useLang from '@/lib/hook/useLang';

export default function FaqPage() {
  const { lngT } = useLang('landingPage:faq');
  const [activeFaq, setActiveFaq] = useState(null);

  const handleFaqClick = (index: any) => {
    if (activeFaq === index) {
      setActiveFaq(null);
    } else {
      setActiveFaq(index);
    }
  };

  const faqItems = [
    {
      pergunta: lngT('faq:items:1:question'),
      resposta: lngT('faq:items:1:answer'),
    },
    {
      pergunta: lngT('faq:items:2:question'),
      resposta: lngT('faq:items:2:answer'),
    },
    {
      pergunta: lngT('faq:items:3:question'),
      resposta: lngT('faq:items:3:answer'),
    },
    {
      pergunta: lngT('faq:items:4:question'),
      resposta: lngT('faq:items:4:answer'),
    },
    {
      pergunta: lngT('faq:items:5:question'),
      resposta: lngT('faq:items:5:answer'),
    },
    {
      pergunta: lngT('faq:items:6:question'),
      resposta: lngT('faq:items:6:answer'),
    },
  ];

  return (
    <div className={styles.pageContainer}>
      <div className="h-[1000px] max-[1284px]:h-[1200px] w-[1200px] max-[1024px]:w-[1100px] relative">
        <VideoOverlay />
        <ApostouGanhou />
      </div>
      <p className={styles.pPerguntas}>
        {lngT('faq:title')} <span className={styles.spanDuvidas}> {lngT('faq:subTitle')}</span>
      </p>
      <div className={styles.faqs}>
        {faqItems.map((question, index) => (
          <div
            key={index}
            className={`${styles.faqItem} ${activeFaq === index ? styles.faqActive : ''}`}
            onClick={() => handleFaqClick(index)}
          >
            <div className={styles.faqQuestionContainer}>
              <div className={styles.faqNumber}>0{index + 1}</div>
              <div className={styles.faqQuestionTitle}>{question.pergunta}</div>
            </div>
            <div className={styles.faqIcon}></div>
            <div className={styles.faqAnswer}>{question.resposta}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
