import { Children } from 'react';
import tw from 'tailwind-styled-components';

import HeaderItem from './HeaderItem';
import RowItem from './RowItem';
import ErrorMessage from './ErrorMessage';
import SpinnerLoader from './SpinnerLoader';
import Paginator from './Paginator';

const ListPagerContainer = tw.div`
  w-full
  space-y-[4px]
  pb-10
`;

const ListContainer = tw.div`
  w-full
  overflow-x-scroll
`;

const RowsContainer = tw.div<{ $minHeight?: number }>`
  flex flex-col
  space-y-[4px]
  w-full ${({ $minHeight }) => ($minHeight ? `min-h-[${$minHeight}px]` : 'min-h-[500px]')}
`;

const LoadingErrorContainer = tw.div`
  flex justify-center items-center
  w-full flex-1
  bg-white/10
`;

const NoItemBox = tw.div`
  flex justify-center items-center
  w-full h-[300px]
  bg-white/10
  text-white
  `;

type Props = {
  header: React.ReactElement[] | React.ReactElement;
  rows: React.ReactElement[] | React.ReactElement | undefined;
  dataLength: number;
  pager: {
    totalPageCount: number;
    perPage: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
  };
  isLoading: boolean;
  error?: boolean;
  options?: {
    $minHeight?: number;
  };
};

export default function ListPager({
  header,
  rows,
  dataLength,
  pager: { totalPageCount, perPage, currentPage, setCurrentPage },
  isLoading,
  error,
  options,
}: Props) {
  Children.forEach(header, child => {
    if (child.type !== HeaderItem.Container) {
      throw new Error('<ListPager>의 props.header는 <Header.Container> 컴포넌트만 자식으로 가져야 합니다.');
    }
  });

  Children.forEach(rows, child => {
    if (child && child.type !== RowItem.Container) {
      throw new Error('<ListPager>의 props.rows는 <Row.Container> 컴포넌트만 자식으로 가져야 합니다.');
    }
  });

  return (
    <ListPagerContainer>
      <ListContainer>
        {header}
        <RowsContainer $minHeight={options?.$minHeight}>
          {isLoading || error ? (
            <LoadingErrorContainer>
              {isLoading ? (
                <SpinnerLoader className="w-[30px] h-[30px] fill-white" />
              ) : (
                <ErrorMessage error="something wrong" />
              )}
            </LoadingErrorContainer>
          ) : dataLength === 0 ? (
            <NoItemBox>No item</NoItemBox>
          ) : (
            rows
          )}
        </RowsContainer>
      </ListContainer>
      <Paginator
        totalItems={totalPageCount}
        itemsPerPage={perPage}
        currentPage={currentPage}
        onPageChange={selectedPage => setCurrentPage(selectedPage)}
      />
    </ListPagerContainer>
  );
}
