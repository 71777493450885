import CloseIcon from './CloseIcon';
import RightArrow from './RightArrow';
import AlertIcon from './AlertIcon';
import PluseIcon from './PlusIcon';
import MinusIcon from './MinusIcon';

const Icon = {
  CloseIcon,
  RightArrow,
  AlertIcon,
  PluseIcon,
  MinusIcon,
};

export default Icon;
