import { type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

import Icons from '@/page/common/icons';

const Container = tw.div`
  relative
  flex flex-col
  bg-metacross-bg
  py-10 px-4 T:px-8
  rounded-lg
  m-2
  w-full T:w-fit
  h-[90%]
  overflow-y-scroll
`;

const CloseButton = tw.button`
  absolute top-2 right-2
  disabled:opacity-25
`;

type Props = {
  children: ReactNode;
  closeModal: () => void;
  closeDisabled: boolean;
};

export default function PopupLayout({ children, closeModal, closeDisabled }: Props) {
  return (
    <Container>
      <CloseButton disabled={closeDisabled}>
        <Icons.CloseIcon className="text-white" size={24} onClick={closeModal} />
      </CloseButton>
      <div className="h-fit overflow-y-auto">{children}</div>
    </Container>
  );
}
