import { makeStyles } from "@material-ui/styles";
import vsImage from '../../img/vsImageTop.svg';

export const useStylesVersusFigure = makeStyles(theme => ({
    figureBox: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent:'space-between',
        height:'100px',
        backgroundImage: `url(${vsImage})`,
        /* 이미지추가 및 이미지경로 수정해서 올려주세요 */
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center top',
        '&:before':{
            content: '""',
            position: 'absolute',
            left:0,
            bottom:0,
            width: '50%',
            height: 38,
            borderRadius: 50,
            background:'linear-gradient(180deg, #8F8F8F -21.4%, #989898 -19.4%, #B4B4B4 -11.92%, #CBCBCB -4.07%, #DADADA 4.66%, #E4E4E4 14.51%, #E7E7E7 28.11%, #737373 102.8%)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            zIndex: -10,
        },
        '&:after':{
            content: '""',
            position: 'absolute',
            right:0,
            bottom:0,
            width: '50%',
            height: 38,
            borderRadius: 50,
            background:'linear-gradient(180deg, #8F8F8F -21.4%, #989898 -19.4%, #B4B4B4 -11.92%, #CBCBCB -4.07%, #DADADA 4.66%, #E4E4E4 14.51%, #E7E7E7 28.11%, #737373 102.8%)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            zIndex: -10,
        },
        '@media all and (max-width:416px)':{
            fontSize:'0.875rem',
            backgroundSize:'78% 76%',
            marginTop:'20px!important',
        },
        '@media all and (max-width:321px)':{
            backgroundSize:'90% 90%',
            backgroundPosition:'center -5px',
        }
    },
    oddsText:{
        position: 'absolute',
        left:'48%',
        top:35,
        color: '#fff',
        fontSize: '1.25rem',
        fontWeight: 'bold',
        '@media all and (max-width:406px)':{
            top:43,
            fontSize:'1rem',
        }
    },
    barWrap:{
        display: 'block',
        height: 38,
        width: '100%',
        position: 'absolute',
        top: 0,
    },
    bar:{
        width:'100%',
        height:31,
    },
    oddTextStyle:{
        position: 'relative',
        width:'50%',
        height:38,
        '& > span':{
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-start',
            color:'#004bac',
            fontWeight:700,
            paddingTop:8,
            paddingLeft:8,
            textShadow:'0 1px 1px #192e57',
            zIndex:-1,
        }
    },
    oddBarWrap:{
        display:'flex',
        justifyContent:'flex-end',
        alignItems:'center',
        left:0,
    },
    oddBar:{
        borderRadius:'32px 0 0 32px',
        background:'linear-gradient(180deg, #25437B 1.9%, #214584 4.71%, #1351AB 17.94%, #085AC8 30.17%, #0260D9 40.68%, #0062E0 48.47%, #0160DB 57.26%, #055DCF 66.04%, #0D56BB 74.83%, #174E9F 83.62%, #24437B 92.32%, #25437B 92.5%)',
    },
    evenTextStyle:{
        position: 'relative',
        width:'50%',
        height:38,
        '& > span':{
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-end',
            color:'#e24343',
            fontWeight:700,
            paddingTop:8,
            paddingRight:8,
            textShadow:'0 1px 1px #8d3536',
            zIndex:-1,
        }
    },
    evenBarWrap:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        right:0,
    },
    evenBar:{
        borderRadius:'0 32px 32px 0',
        background:'linear-gradient(180deg, #A84042 -4.8%, #B94647 1.49%, #D75152 14.69%, #ED5959 27.78%, #FA5E5E 40.25%, #FF6060 51.6%, #F95E5E 61.18%, #EA5858 73.04%, #D14F50 86.13%, #B34446 98.3%)',  
    },
    persentText:{
        position:'absolute',
        top:10,
        color:'#000',
    },
    oddPersentText:{
        right:8,
    },
    evenPersentText:{
        left:8,
    },
    vsCenter:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:100,
        height:38,
        backgroundColor:'#000',
        color:'#fff',
        '@media all and (max-width:406px)':{
            widht:'60px!important'
        }
    }
}));