import { create } from 'zustand';

interface ReelGameState {
    isAutoSpin: boolean;
    jackpotspinCount: number;
    freespinCount: number;
    normalspinCount: number;
    useCoin: number;
    rewardCoin: number;
    freespinentercount: number;
    jackpotspinentercount: number;
    isIphone: boolean;
    isIOSMute: boolean;

    setAutoSpin : (value: boolean) => void;
    setJackpotSpinCount : (value: number) => void;
    setFreeSpinCount : (value: number) => void;
    setNormalSpinCount : (value: number) => void;
    setUserCoin : (value: number) => void;
    setRewardCoin : (value: number) => void;
    setFreeSpinEnterCount : (value: number) => void;
    setJackpotSpinEnterCount : (value: number) => void;
    setIsIphone : (value: boolean) => void;
    setIsIOSMute : (value: boolean) => void;

}

export const useReelGameStore = create<ReelGameState>(set => ({
    isAutoSpin : false,
    setAutoSpin : (value: boolean) => set({isAutoSpin : value}),

    jackpotspinCount : 0,
    setJackpotSpinCount : (value: number) => set({jackpotspinCount : value}),

    freespinCount : 0,
    setFreeSpinCount : (value: number) => set({freespinCount : value}),

    normalspinCount : 0,
    setNormalSpinCount : (value: number) => set({normalspinCount : value}),

    useCoin : 0,
    setUserCoin : (value: number) => set({useCoin : value}),

    rewardCoin : 0,
    setRewardCoin : (value: number) => set({rewardCoin : value}),

    freespinentercount : 0,
    setFreeSpinEnterCount : (value: number) => set({freespinentercount : value}),

    jackpotspinentercount : 0,
    setJackpotSpinEnterCount : (value: number) => set({jackpotspinentercount : value}),

    isIphone : false,
    setIsIphone : (value: boolean) => set({isIphone : value}),

    isIOSMute : true,
    setIsIOSMute : (value: boolean) => set({isIOSMute : value}),
}));
