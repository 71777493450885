import React, { useEffect, useRef } from "react";
import { SpinePlayer } from "@esotericsoftware/spine-player";
import Lottie, { LottieAnimationItem } from "lottie-web";
import './particle/css/particle.css'

export const SpineRender=(props)=>
{  
  const path = `${props.filepath}/cardspine`;
  const file = 'result'
  const ani = props.aniName;

  useEffect(()=>{
    new SpinePlayer("player-container", {
      jsonUrl: `/${path}/${file}.json`,
      atlasUrl: `/${path}/${file}.atlas`,
      animation: ani,
      backgroundColor: "#00000000",
      alpha: true,
      showControls : false
    });
  }, [])
  
  return <div id="player-container" style={{width:'640px', height: '480px', zIndex:'5'}}>
    {props.success && <LottieParticle></LottieParticle>}
  </div>
  
}


function LottieParticle(props){
  return (<div>
    {/* <LottieParticle1></LottieParticle1>
    <LottieParticle2></LottieParticle2> */}
    <LottieParticleCombine></LottieParticleCombine>
  </div>)
}

function LottieParticleCombine(porps) {
  const container = useRef(null); 
  useEffect(() => {
    Lottie.loadAnimation({
      animationData: require("./particle/particle.json"),
      autoplay: true,
      container: container.current,
      loop: true,
      renderer: "svg",
    })
  }, [])

  return (
    <div className="lottie1">      
      <div ref={container} id="animation-container"></div>
    </div>
  );
}

// function LottieParticle1(porps) {
//   const container = useRef(null); 
//   useEffect(() => {
//     Lottie.loadAnimation({
//       animationData: require("./particle/Particle_1.json"),
//       autoplay: true,
//       container: container.current,
//       loop: true,
//       renderer: "svg",
//     })
//   }, [])

//   return (
//     <div className="lottie1">         
//       <div ref={container} id="animation-container"></div>
//     </div>
//   );
// }

// function LottieParticle2(porps) {
//   const container = useRef(null); 
//   useEffect(() => {
//     Lottie.loadAnimation({
//       animationData: require("./particle/Particle_2.json"),
//       autoplay: true,
//       container: container.current,
//       loop: true,
//       renderer: "svg",
//     })
//   }, [])

//   return (
//     <div className="lottie1">      
//       <div ref={container} id="animation-container"></div>
//     </div>
//   );
// }