import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import TextNavLinker from '../../components/TextLinker';
// import OauthBox from '../../components/OauthBox';
import CheckEmailExist from '../../components/auth/CheckEmailExist';
import LogoButton from '../../components/LogoButton';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang';
import EmailVerification from '../../components/auth/EmailVerification';
import UserInfoSetting from '../../components/auth/UserInfoSetting';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import * as config from '../../config'

const Container = tw.section`
  flex flex-col justify-center
  w-[400px]
`;

const AuthTitle = tw.div`
  font-semibold text-[30px]
  mb-5
`;

// 1. 이메일 회원가입 되어 있는지 확인 (checkEmailExist)
// 2. 이메일 인증받기 (emailVerification)
// 3.

export default function Signup() {
  const { lngT } = useLang('auth:signup');
  const navigate = useCustomNavigate();
  const [user] = useUser();
  const { changeLang } = useLang();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [phase, setPhase] = useState('checkEmailExist');
  const [verificationCode, setVerificationCode] = useState();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const firstRun = useRef(true);

  const recommenderCode = searchParams.get('recommenderCode');
  const lng = searchParams.get('lng');
  const lngtParam = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'racing' : 'powerball';

  useEffect(() => {
    if (lng) {
      changeLang(lng);
    }
  }, []);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    if (user) navigate('/');
  }, [user]);

  return (
    <div className="flex justify-center items-center w-full h-full">
      <Container>
        <div className="mb-[60px]">
          <LogoButton signup={true} />
        </div>
        <>
          <AuthTitle>{lngT('title')}</AuthTitle>
          {phase === 'checkEmailExist' ? (
            <CheckEmailExist
              setNextPhase={() => setPhase('emailVerification')}
              setEmail={setEmail}
              setVerificationCode={setVerificationCode}
            />
          ) : phase === 'emailVerification' ? (
            <EmailVerification
              email={email}
              verificationCode={verificationCode}
              setPassword={setPassword}
              setNextPhase={() => setPhase('userInfoSetting')}
            />
          ) : phase === 'userInfoSetting' ? (
            <UserInfoSetting email={email} password={password} code={recommenderCode} />
          ) : null}
        </>
        <TextNavLinker
          text={lngT(`user:${lngtParam}:1`)}
          href={`/login${location.search}`}
          hrefText={lngT(`user:${lngtParam}:2`)}
          className="text-[16px] mt-[20px]"
        />
      </Container>
    </div>
  );
}
