import {
  Html,
  ContactShadows,
  Environment,
  OrbitControls,
  useProgress,
  Lightformer,
  useHelper,
  SoftShadows,
} from '@react-three/drei';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import * as define from '../../src/define'
// import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
// import { Direction } from "react-toastify/dist/utils";

export function CustomLight(props) {
  return (
    <group>      
      {/* <CustomShadowLight
        page={props.page}      
      ></CustomShadowLight> */}
      <CustomAmbientLight></CustomAmbientLight>
      <CustomSpotLight></CustomSpotLight>
      <CustomDirectionLight
        page={props.page}   
      ></CustomDirectionLight>

      <CustomAmbientLight></CustomAmbientLight>
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={0.1} intensity={0.2}/>
      <pointLight position={[-10, -10, -10]}  intensity={0.2}/>


      {/* <CustomAmbientLight></CustomAmbientLight> */}
    </group>
  );
}

export function CustomEnv({ page }) {  
  var fileName = `/horsestall/bg/2d/${page}.png`;   
  var loader = new THREE.TextureLoader();
  var texture = loader.load(fileName);
  var revision = (1190 / 550) * 2.9;
  var w = 2103;
  var h = 1190;

  return ( 
    <group  
      position={[100,-5,-100]}
      //scale={[753/2.5, 426/2.5,1]}>
      scale={[w/revision, h/revision ,1]}>
      <sprite>
        <spriteMaterial 
        map={texture} />
      </sprite>

      <mesh  scale={[1,1]}>
        <planeBufferGeometry/>
        <meshStandardMaterial map={texture}/>
      </mesh>
    </group>   
  );
}


// export function CustomEnv({ perfSucks }) {  
  
//   return (
//     <Environment      
//       // position={[10,0,0]}
//       SoftShadows={true}
//       receiveShadow={true}
//       files={'/horsestall/bg/bg2.hdr'}
//       ground={{ height: 25, radius: 110, scale: 1300 }}
      
//       // ground={{ height: 32, radius: 130 }}
//       // ground={{ height: 12, radius: 90 }}
//     >     
//     </Environment>
//   );
// }

export function CustomPointLight() {
  const positions = [
    [-0.1, 0.1, 0],
    [0.2, -0.0, 0],
  ];
  const size = 0.3;
  const intensity = 50;
  const color = 'white';

  const pointLightRefs = [useRef(null), useRef(null)];

  // useHelper(pointLightRefs[0], PointLightHelper, size, color);
  // useHelper(pointLightRefs[1], PointLightHelper, size, color);

  return (
    <group>
      <pointLight ref={pointLightRefs[0]} intensity={intensity} distance={size} position={positions[0]} color={color} />
      <pointLight ref={pointLightRefs[1]} intensity={intensity} distance={size} position={positions[1]} color={color} />
    </group>
  );
}

// export function CustomShadowLight(){
//   return (
//     <spotLight angle={0.5} castShadow position={[-80, 200, -100]} intensity={0} shadow-mapSize={[515, 512]} />
//   )
// }

export function CustomShadowLight(props){
  //-24, 34, -12
  // const shadowResolution = 0.5;
  return (
    <spotLight 
      angle={0.5} 
      castShadow 
      position={define.settings[props.page].shadowlightPosition}
      //position={[-120*shadowResolution, 170*shadowResolution, -60*shadowResolution]} 
      intensity={0} 
      shadow-mapSize={[515, 512]} />
  )
}

export function CustomSpotLight() {
  const targetObject = new THREE.Object3D();
  targetObject.position.set(0, 0, 0);

  const positions = [
    [0.0, 0.0, 0.7],
    [0.0, 0.0, -0.7],
  ];
  const size = 1.0;
  const intensity = 2.5;
  const color = 'white';
  const angle = 1.7;
  //   penumbra?: number,
  // decay?: number,

  const spotLightRefs = [useRef(null), useRef(null)];

  // useHelper(spotLightRefs[0], SpotLightHelper, size, color);
  // useHelper(spotLightRefs[1], SpotLightHelper, size, color);

  return (
    <group>
      <spotLight
        ref={spotLightRefs[0]}
        intensity={intensity}
        distance={size}
        position={positions[0]}
        color={color}
        angle={angle}
        target={targetObject}
      />
      <spotLight
        ref={spotLightRefs[1]}
        intensity={intensity}
        distance={size}
        position={positions[1]}
        color={color}
        angle={angle}
        target={targetObject}
      />
    </group>
  );
}

function CustomAmbientLight() {
  return (<group>
    <ambientLight intensity={1.0} color={'white'} />
  </group>)
}

function CustomDirectionLight(props) {
  // const targetObject = new THREE.Object3D();
  // targetObject.position.set(0,0,0);

  // const positions = [[0.0,0.0,0.7], [0.0,0.0,-0.7]];
  // const size = 1.0;
  // const intensity = 2.5;
  // const color = 'white';
  // const angle = 1.7;
  const targetObject = new THREE.Object3D();
  targetObject.position.set(0,0,0);

  return (
    <group>
      {/* <directionalLight position={[0,10,0]} intensity={1.0}/>  */}
      {/* <fog attach="fog" args={["white", 1, 1]} /> */}
      <directionalLight
        name="Directional_Light"
        intensity={3.14}
        decay={2}
        color="#fffaec"
        position={define.settings[props.page].lightPosition}
        target={targetObject}

        castShadow={false}
        shadow-mapSize-height={512}
        shadow-mapSize-width={512}        
      >
        <group position={[0, 0, 0]} />
      </directionalLight>
    </group>
  );
}

export function CustomPostEffect() {
  return (
    <group></group>
    // <EffectComposer>
    //   <DepthOfField focusDistance={0} focalLength={0.02} bokehScale={2} height={480} />
    //   <Bloom luminanceThreshold={0} luminanceSmoothing={2.0} height={500} intensity={1.5} />
    //   <Vignette eskil={false} offset={0.1} darkness={0.1} />
    // </EffectComposer>
  );
}

export function CustomOrbit() {
  var PolarAngle = Math.PI / 2.25-0.1;
  var AzimuthAngle = -0.87;
  var minPolarAngle;
  var maxPolarAngle;
  var minAzimuthAngle;
  var maxAzimuthAngle;

  minPolarAngle = PolarAngle;
  maxPolarAngle = PolarAngle;
  minAzimuthAngle = AzimuthAngle;
  maxAzimuthAngle = AzimuthAngle;

  return (
    <OrbitControls     
    target={[0,14,0]}
    enableZoom={false} 
    enablePan={false} 
    minPolarAngle={minPolarAngle} 
    maxPolarAngle={maxPolarAngle} 
    minAzimuthAngle={minAzimuthAngle}
    maxAzimuthAngle={maxAzimuthAngle}
    makeDefault />
  );
}