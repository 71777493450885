export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

export const API_URL = process.env.REACT_APP_API_URL;

export const GOOGLE_OAUTH_ID = process.env.REACT_APP_GOOGLE_OAUTH_ID;

export const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;

export const NOTICE_TOKEN_NAME = process.env.REACT_APP_NOTICE_TOKEN_NAME;

export const NEED_REFRESH_STORE_NAME = process.env.REACT_APP_NEED_REFRESH_STORE_NAME;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const PIXEL_ACCESS_TOKEN = process.env.REACT_APP_PIXEL_ACCESS_TOKEN;

export const ADMIN_ACCESS_TOKEN_NAME = process.env.REACT_APP_ADMIN_ACCESS_TOKEN_NAME;

export const DISTRIBUTOR_ACCESS_TOKEN_NAME = process.env.REACT_APP_DISTRIBUTOR_ACCESS_TOKEN_NAME;

function required(key: string, defaultValue?: string) {
  const value = process.env[key] || defaultValue;

  if (value === undefined || value === null) {
    throw new Error(`Missing env.${key}`);
  }
  return value;
}

export const env = {
  client: {
    host: required('REACT_APP_API_URL'),
    domain: required('REACT_APP_DOMAIN'),
  },
  api: {
    host: required('REACT_APP_API_URL'),
  },
  aws: {
    baseUrl: required('REACT_APP_AWS_S3_BASEURL'),
  },
};
