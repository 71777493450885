import { useEffect, useState } from 'react';

import { env } from '@/env';
import { useLang } from '@/lib/hook';
import { formatToBrazilTime } from '@/lib/util';

import { MainButton, SpinnerLoader } from '@/page/common/component';
import { Style } from './style';

type Props = {
  item: Omit<EventModel, 'content'>;
  onClick?: () => void;
};

export default function EventListItem({ item, onClick }: Props) {
  const { currentLang } = useLang();
  const [imgLoading, setImgLoadings] = useState(false);

  useEffect(() => {
    setImgLoadings(true);
  }, [item.thumbnailKey]);

  return (
    <Style.Layer onClick={onClick}>
      <Style.InnerLayer>
        <Style.ImageLayer>
          {imgLoading && (
            <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full bg-white/05 backdrop-blur-md">
              <SpinnerLoader className="w-[40px] h-[40px] fill-white" />
            </div>
          )}
          <img
            src={`${env.aws.baseUrl}/${item.thumbnailKey}`}
            className="w-full h-full object-cover"
            alt="thumbnail"
            onLoad={() => setImgLoadings(false)}
          />
        </Style.ImageLayer>
        <div className="flex flex-col T:flex-row items-center flex-1 gap-4">
          <div className="flex flex-row items-center w-full h-[60px] T:h-full text-[10px] T:text-lg text-white font-medium px-4 T:px-8 bg-white/10 rounded-md">
            {currentLang === 'en' ? item.title_en : item.title_pt}
          </div>
          <div className="flex flex-col justify-center items-center gap-y-1 w-[150px] h-full text-xs text-white">
            <MainButton content={currentLang === 'en' ? 'Read More' : 'Consulte Mais'} />
            <div className="flex flex-row items-center justify-center w-full h-[50px]">
              created: {formatToBrazilTime(item.createdAt)}
            </div>
          </div>
        </div>
      </Style.InnerLayer>
    </Style.Layer>
  );
}
