import { useRef, useState } from 'react';
import tw from 'tailwind-styled-components';

import { ReactComponent as ChevronRightIcon } from '../assets/icons/chevron-right.svg';
import useLang from '../lib/hook/useLang';
import useCustomNavigate from '@/lib/hook/useCustomNavigate';

const Container = tw.div`
  relative
`;

const SelectedMenuContainer = tw.div`
  min-w-[160px] max-w-[160px]
  flex flex-row justify-between items-center
  px-[8px] py-[6px]
  border border-white/60 rounded-lg
  cursor-pointer
`;

const SelectedMenu = tw.div`
  text-white text-[14px]
`;

const DrawerMenuContainer = tw.div`
  absolute
  w-full
  border border-white/60 rounded-lg
  p-[12px]
  bg-[#1C012E]
  z-[99]
  overflow-x-hidden

`;

const DrawerMenuItem = tw.div`
  w-full
  text-white text-[14px]
  cursor-pointer
  hover:bg-white/10
  p-[8px]
  overflow-x-hidden
  truncate
`;

export default function DrawerMenu({ menus, selectedText, className }) {
  const navigate = useCustomNavigate();
  const { lngT } = useLang('titles');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleSelectMenu = menu => () => {
    menus.onClick(menu);
    navigate(menu.route);
    setMenuOpen(false);
  };

  return (
    <Container>
      <SelectedMenuContainer className={className ?? ''} ref={menuRef} onClick={() => setMenuOpen(true)}>
        <SelectedMenu onClick={() => setMenuOpen(true)}>{selectedText}</SelectedMenu>
        <ChevronRightIcon fill="white" className="rotate-90 w-[16px] ml-3" />
      </SelectedMenuContainer>
      {isMenuOpen && (
        <>
          <div className="fixed top-0 left-0 right-0 bottom-0 z-[98]" onClick={() => setMenuOpen(false)} />
          <DrawerMenuContainer>
            {menus?.map((menu, i) => (
              <DrawerMenuItem key={i} onClick={() => menu.onClick(menu.categoryId)}>
                {menu.title}
              </DrawerMenuItem>
            ))}
          </DrawerMenuContainer>
        </>
      )}
    </Container>
  );
}
