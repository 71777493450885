import { USE_LANDING_POWERBALL } from '../../../../define';
import styles from './Banner.module.css';
import Banner from '@/img/landingPage/mobile/bg-mobile.png';

import useLang from '@/lib/hook/useLang';
import { SpineRenderCommon } from '../../../../util/SpineRenderCommon';

import useCustomNavigate from '../../../../lib/hook/useCustomNavigate';

const BannerSection = () => {
  const { lngT: LngT } = useLang('landingPage:firstPage');
  const { lngT: lobbyLngT } = useLang('powerball');
  const navigate = useCustomNavigate();

  return (
    <section className={styles.bannerContainer}>
      <img src={Banner} alt={''} className={styles.image} />
      <div className={styles.descriptionContainer}>
        <p className={styles.semiTitle}>{LngT('title')}</p>
        <h1 className={styles.title}>
          {LngT('subTitle:1')} <br />
          {LngT('subTitle:2')} <b>{LngT('subTitle:3')}</b>
          <span className="text-xl text-[#a565f9] font-semibold tracking-tighter">{LngT('subTitle:4')}</span>
          <br />
          {LngT('subTitle:5')}
        </h1>
        <p className={styles.subtitle}>
          {LngT('mobile:1')}
          {LngT('mobile:2')}
          {LngT('mobile:3')}
        </p>
        {USE_LANDING_POWERBALL && (
          <div className="h-[100px] relative">
            <button
              onClick={() => navigate('/powerball/lobbyy')}
              className="w-[300px] min-[560px]:w-[340px]  h-[180px] absolute z-10 -top-[40px] -right-10"
            >
              <div className="absolute z-20 text-white top-1/2 translate-x-[50px] -translate-y-2 space-y-[1px] max-[560px]:translate-x-10">
                <h2 className="uppercase text-[16px] font-semibold">{lobbyLngT('lottery:pad:powerball')}</h2>
                <p className="text-xs w-full tracking-tight">{lobbyLngT('lobby:phrase1')}</p>
              </div>
              <SpineRenderCommon path="powerball/landing" file="newgame_export" aniName="ball_ani"></SpineRenderCommon>
            </button>
          </div>
        )}
        <p className={styles.cta}>{LngT('cta')} </p>
      </div>
    </section>
  );
};

export default BannerSection;
