import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { useForm } from 'react-hook-form';
import tw from 'tailwind-styled-components';
import { useNavigate, useParams } from 'react-router-dom';

import { WEBSERVERPORT } from '../../config';
import useUser from '../../lib/hook/useUser';
import useLang from '../../lib/hook/useLang';

const QuestionWriteBtn = tw.button`
  flex justify-center items-center
  h-[38px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

export default function NoticeDetail() {
  const { lngT } = useLang('community:freeboard');
  const navigate = useNavigate();
  const params = useParams();
  const [user] = useUser();
  const { data, isLoading, error } = useSWR(
    `${process.env.REACT_APP_API_URL}/notice/discussionBoardNotice/${params.noticeId}`
  );
  console.log(data);
  const [isPublished, setPublished] = useState(true);
  // const [isNotice, setNotice] = useState(true);

  // if (data && !data.freeBoartItem) {
  //   navigate('/freeboard');
  // }

  return (
    <div>
      {isPublished && (
        <form className="flex flex-col D:mx-[64px]">
          <div className="flex flex-col">
            <div className="flex flex-col space-y-4">
              <div className="hidden D:flex flex-row D:justify-between">
                <div className="flex text-[20px] text-white h-[38px]">{lngT('freeBoard')}</div>
              </div>
              <div className="border-b D:border border-white/40 D:rounded-lg T:px-3 D:py-4">
                <div className="space-y-2 pb-4 D:pt-2 px-[16px]">
                  <div className="flex flex-row items-center text-[20px]">
                    <div className="text-[#FF6058]">[Notice]</div>
                    <div className="text-white font-medium pb-2 pt-2 D:ml-2 pl-2 D:flex rounded-md bg-transparent">
                      {data?.data.discussionBoardNotice.title}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-row items-center space-x-3">
                      <div className="text-white/70 text-[14px]">U-podium</div>
                      <div className="flex justify-end text-white/70 text-[14px]">
                        {data && new Date(data?.data.discussionBoardNotice.createdAt).toISOString().substring(0, 10)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-white text-[12px] border-b border-white/20 block" />
                <div className="px-[15px] py-[32px] text-white text-[14px]">
                  <div className="w-full font-normal text-[14px] p-[8px] leading-7">
                    {data?.data.discussionBoardNotice.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
