import tw from 'tailwind-styled-components';

import useModalStore, { ModalInstance } from './store';
import { useEffect } from 'react';

const Container = tw.div`
  fixed top-0 right-0 bottom-0 left-0
  z-[99]
  bg-white/10
  backdrop-blur-md
  flex justify-center items-center
  max-h-screen
  px-1 T:px-4
`;

const Modal = () => {
  const { modals, closeModal, closeAll } = useModalStore();

  const handleDimClickToClose = (modal: ModalInstance) => () => {
    modal.useCloseDim && closeModal(modal.id);
  };

  const handleEvent = () => {
    closeAll();
  };

  useEffect(() => {
    window.addEventListener('popstate', handleEvent);

    return () => {
      window.removeEventListener('popstate', handleEvent);
    };
  }, []);

  return (
    <>
      {modals.map(modal => (
        <Container key={modal.id} onClick={handleDimClickToClose(modal)}>
          {modal.content}
        </Container>
      ))}
    </>
  );
};

export default Modal;
