import tw from "tailwind-styled-components";

import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";

const Container = tw.div`
`;

const Title = tw.div`
  text-[13px] text-[#999999]
  mb-[5px]
`;

const InputBox = tw.div`
  flex flex-row items-center
`;

const InputWrapper = tw.div`
  flex-1
  py-[7px] pl-[7px]
  child-input:w-full
  child-input:text-[16px] child-input:text-[#999999] child-input:font-semibold
  child-input:placeholder:font-light child-input:placeholder:text-[#999999]
  child-input:bg-white
`;

const BottomBorder = tw.div`
  w-full h-[2px]
  ${({ $error, $success }) =>
    $error ? "bg-[#DA1E28]" : $success ? "bg-[#8E00CB]" : "bg-[#79717D]"}
`;

const ErrorText = tw.div`
  w-full
  pl-[7px]
  text-[12px] text-[#DA1E28]
`;

export default function TextInputWrapper({
  children,
  isEmpty,
  title,
  errorText,
  className,
}) {
  const success = !isEmpty && !errorText;

  return (
    <Container className={className}>
      <Title>{title}</Title>
      <InputBox>
        <InputWrapper>{children}</InputWrapper>
        {success && <CheckIcon color="#8E00CB" />}
      </InputBox>
      <BottomBorder $error={!!errorText} $success={success} />
      <ErrorText>{errorText}</ErrorText>
    </Container>
  );
}
