import { type ChangeEvent } from 'react';

import { HeaderItem, ListPager, RowItem, useListPager } from '@/lib/custom-lib/ListPager';
import { formatDateToLocal } from '@/lib/util';
import { useSelector, useLang, useDateSelector } from '@/lib/hook';
import useToggle from '@/lib/hook/useToggle';
import { env } from '@/env';
import * as config from '../../../config';

import { DateFilter } from '@/page/common/component';
import { ContentLayout } from '@/page/common/layout';
import { generateContainer } from '@/page/common/lib';

import redBall from '@/img/powerball/ball_red.png';
import whiteBall from '@/img/powerball/ball_white.png';
import Point from '@/assets/images/coin_point.png';
import Free from '@/assets/images/coin_free.png';

type ListHeaderProps = {
  width: number;
  title: string;
};

const headers: ListHeaderProps[] = [
  {
    width: 50,
    title: /*No*/ 'table:title:no',
  },
  {
    width: 250,
    title: /*Date*/ 'table:title:date',
  },
  {
    width: 200,
    title: /*Tyoe*/ 'table:title:type',
  },
  {
    width: 100,
    title: /*Round*/ 'table:title:round',
  },
  {
    width: 400,
    title: /*Bet*/ 'table:title:bet',
  },
  {
    width: 400,
    title: /*Winner*/ 'table:title:winner',
  },
  {
    width: 100,
    title: /*Result*/ 'table:title:result',
  },
  {
    width: 150,
    title: /*Betting(-)*/ 'table:title:betting',
  },
  {
    width: 150,
    title: /*Odds*/ 'table:title:odds',
  },
  {
    width: 150,
    title: /*Reward(+)*/ 'table:title:reward',
  },
];

const slotHeaders: ListHeaderProps[] = [
  {
    width: 50,
    title: /*No*/ 'table:title:no',
  },
  {
    width: 250,
    title: /*입장 시간*/ 'table:title:startTime',
  },
  {
    width: 250,
    title: /*퇴장 시간*/ 'table:title:endTime',
  },
  {
    width: 200,
    title: /*Type*/ 'table:title:type',
  },
  {
    width: 200,
    title: /*Bet*/ 'table:title:spin',
  },
  {
    width: 200,
    title: /*Bet*/ 'table:title:bet',
  },
  {
    width: 200,
    title: /*Winner*/ 'table:title:win',
  },
  {
    width: 100,
    title: /*Free Spin*/ 'table:title:freeSpin',
  },
  {
    width: 100,
    title: /*Free Spin win*/ 'table:title:freeSpinWin',
  },
  {
    width: 150,
    title: /*Start Point*/ 'table:title:startPoint',
  },
  {
    width: 150,
    title: /*End Point*/ 'table:title:endPoint',
  },
  {
    width: 150,
    title: /*결과*/ 'table:title:result',
  },
];

const serviceTypeMenus = [['racing', 'slot'], ['powerball', 'lottery', 'slot']];

export default function BettingHistory() {
  const { lngT } = useLang('mypage:gameHistory');
  const { currentPage, setCurrentPage } = useListPager();
  const { selectorItems, selectedValue, selectItem } = useSelector(serviceTypeMenus[Number(config.SERVICE_TYPE)]);
  const { startDate, endDate, setStartDate, setEndDate } = useDateSelector();

  const { Toggle, isToggled } = useToggle({
    init: false,
  });

  const listHeaderProps: ListHeaderProps[] = headers.map(header => ({ ...header, title: lngT(header.title) }));
  const slotListHeaderProps: ListHeaderProps[] = slotHeaders.map(header => ({ ...header, title: lngT(header.title) }));
  console.log("listHeaderProps : ", listHeaderProps);
  console.log("slotListHeaderProps : ", slotListHeaderProps);
  const PaginateUserBettingHistoryContainer = generateContainer<BettingHistoryItem[]>(
    `${
      env.api.host
    }/user/history/betting/${selectedValue}?currentPage=${currentPage}&perPage=${20}&sortBy=DESC&startDate=${startDate}&endDate=${endDate}&coinType=${
      isToggled ? 'free' : 'coin'
    }`
  );

  const handleChangeFilterState =
    (setState: (state: any) => void) => (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      setState(e.target.value);
      setCurrentPage(1);
    };

  const convertLotteryBetValueToComponent = (bet: string, userBet?: string) => {
    const betLottery = bet.split('-');
    const betBall: number[] = betLottery.length === 2 ? JSON.parse(betLottery[0]) : [];

    const betPowerball = betLottery.length === 2 ? Number(betLottery[1]) : '';

    let userBetLottery;
    let userBetBall: number[] = [];
    let userBetPowerball: number = 0;

    if (userBet) {
      userBetLottery = userBet.split('-');
      userBetBall = JSON.parse(userBetLottery[0]);
      userBetPowerball = Number(userBetLottery[1]);
    }

    return (
      <div className="flex flex-row space-x-2">
        {betBall.map((item, id) => (
          <div
            className={`relative flex justify-center items-center w-8 h-8 bg-white rounded-full text-black border-2 ${
              userBetBall.includes(item) ? 'border-yellow-400' : 'border-transparent'
            }`}
            key={id}
          >
            <img alt="ball" src={whiteBall} className="w-full h-full absolute" />
            <div className="text-black relative">{item}</div>
          </div>
        ))}
        {betPowerball && (
          <div
            className={`relative flex justify-center items-center w-8 h-8 bg-red-500 rounded-full text-white border-2 ${
              userBetPowerball === betPowerball ? 'border-yellow-400' : 'border-transparent'
            }`}
          >
            <img alt="ball" src={redBall} className="w-full h-full absolute" />
            <div className="text-white relative">{betPowerball}</div>
          </div>
        )}
      </div>
    );
  };

  const convertPowerBallBetValueToComponent = (bet: string) => {
    const isPowerBall = bet.includes('power');

    const value = isPowerBall ? bet.split('-')[1] : bet;

    return (
      <div className="flex flex-row items-center space-x-2 w-[100px]">
        <div className="relative flex items-center justify-center w-8 h-8">
          <img alt="ball" src={isPowerBall ? redBall : whiteBall} className="w-full h-full absolute" />
          <div className={`${isPowerBall ? 'text-white' : 'text-black'} relative`}>{isPowerBall ? 'P' : 'W'}</div>
        </div>
        <div className="flex items-center text-white">{value === '' ? '?' : lngT(`contents:${value}`)}</div>
      </div>
    );
  };

  const convertNumberToDPFst = (number: number) => {
    return  (Math.floor(number * 100) / 100).toFixed(1);
  }

  const addPlusOrMinusWithResult = (result: number) => {
    const plus = <div><span style={{color: '#0ba1ce', fontWeight: 600}}>+</span><span>{convertNumberToDPFst(Math.abs(result))}</span></div>
    const minus = <div><span style={{color: '#f60505', fontWeight: 600}}>-</span><span>{convertNumberToDPFst(Math.abs(result))}</span></div>
    if(result >= 0){
      return plus;
    } else {
      return minus;
    }
  }

  return (
    <ContentLayout title={lngT('title')}>
      <div className="flex justify-end w-full pb-8 text-white gap-x-4">
        <div className={`flex flex-row items-center gap-x-2 ${isToggled ? 'opacity-30' : ''}`}>
          <img src={Point} alt="point" className="w-[28px] h-[28px]" />
          <span className="text-lg">{lngT('table:points')}</span>
        </div>
        <Toggle />
        <div className={`flex flex-row items-center gap-x-2 ${!isToggled ? 'opacity-30' : ''}`}>
          <img src={Free} alt="point" className="w-[28px] h-[28px]" />
          <span className="text-lg">{lngT('table:freePoints')}</span>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col T:flex-row justify-between w-full pb-5 gap-2">
          <select
            className="rounded-md px-2 w-fit h-fit"
            value={selectedValue}
            onChange={handleChangeFilterState(selectItem)}
          >
            {selectorItems.map((item, i) => (
              <option key={i} value={item.value}>
                {item.value.toUpperCase()}
              </option>
            ))}
          </select>

          <DateFilter
            startDate={startDate}
            endDate={endDate}
            onChangeStartDate={handleChangeFilterState(setStartDate)}
            onChnageEndDate={handleChangeFilterState(setEndDate)}
          />
        </div>
        {/* 컴포넌트로 만들어주세요 */}
        <PaginateUserBettingHistoryContainer>
          {({ data, isLoading, error }) => {
            const totalPageCount = data?.meta?.paginate?.totalCount ?? 0;
            const perPage = data?.meta?.paginate?.perPage ?? 0;
            const filteredData = data?.data.filter(item => {
              const gameType = item.type.toUpperCase();

              if (gameType === 'LOTTERY' || gameType === 'POWERBALL')
                return Number(config.SERVICE_TYPE) === Number(config.SERVICE_POWERBALL);
              else if(gameType === 'RACING')
                return Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING);
              else if(gameType === 'SLOT')
                return true;
            });

            return (
                selectedValue !== 'slot' ?
              <ListPager
                header={
                  <HeaderItem.Container>
                    {listHeaderProps.map((header, i) => (
                      <HeaderItem.Box key={i} $width={header.width}>
                        {header.title}
                      </HeaderItem.Box>
                    ))}
                  </HeaderItem.Container>
                }
                dataLength={filteredData?.length ?? 0}
                rows={filteredData?.map((item, index) => {
                  const isEven = !(index % 2);
                  const indexNumber = totalPageCount - (index + (currentPage - 1) * perPage);
                  const createdAt = formatDateToLocal(item.createdAt);
                  const gameType = item.type.toUpperCase();
                  const round = item.round;
                  const bet =
                    gameType === 'LOTTERY'
                      ? convertLotteryBetValueToComponent(item.bettingValue)
                      : gameType === 'POWERBALL'
                      ? convertPowerBallBetValueToComponent(item.bettingValue)
                      : item.bettingValue;
                  const winner =
                    gameType === 'LOTTERY'
                      ? convertLotteryBetValueToComponent(item.winnerValue, item.bettingValue)
                      : gameType === 'POWERBALL'
                      ? convertPowerBallBetValueToComponent(item.winnerValue)
                      : item.winnerValue;
                  const result =
                    gameType === 'LOTTERY'
                      ? item.result === ''
                        ? null
                        : Number(item.result) === 0
                        ? lngT('fail')
                        : Number(item.result) === 9
                        ? 'bonus'
                        : `${item.result}th`
                      : item.result === 'miss'
                      ? lngT('fail')
                      : lngT('success');
                  const bettingAmount = item.bettingAmount;
                  const odds = gameType === 'LOTTERY' ? (Number(item.result) === 0 ? '-' : item.odds) : item.odds;
                  const rewardAmount = item.rewardAmount;

                  return (
                    <RowItem.Container key={index} $isEven={isEven} className={isToggled ? 'bg-opacity-50' : ''}>
                      <RowItem.Box $width={listHeaderProps[0].width}>{indexNumber}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[1].width}>{createdAt}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[2].width}>{gameType}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[3].width}>{round}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[4].width}>{bet}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[5].width}>{winner}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[6].width}>{result}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[7].width}>{bettingAmount}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[8].width}>{odds}</RowItem.Box>
                      <RowItem.Box $width={listHeaderProps[9].width}>{rewardAmount}</RowItem.Box>
                    </RowItem.Container>
                  );
                })}
                pager={{
                  totalPageCount,
                  perPage,
                  currentPage,
                  setCurrentPage,
                }}
                isLoading={isLoading}
                error={error}
              />
              :
              <ListPager
                  header={
                    <HeaderItem.Container>
                      {slotListHeaderProps.map((header, i) => (
                          <HeaderItem.Box key={i} $width={header.width}>
                            {header.title}
                          </HeaderItem.Box>
                      ))}
                    </HeaderItem.Container>
                  }
                  dataLength={filteredData?.length ?? 0}
                  rows={filteredData?.map((item, index) => {
                    const isEven = !(index % 2);
                    const indexNumber = totalPageCount - (index + (currentPage - 1) * perPage);
                    const createdAt = formatDateToLocal(item.createdAt);
                    const endedAt = formatDateToLocal(item.endedAt);
                    const gameType = item.type.toUpperCase();
                    const spin = item.spin;
                    const bet = item.bettingAmount;
                    const win = convertNumberToDPFst(item.rewardAmount);
                    const freeSpin = item.freeSpin;
                    const freeSpinWin = convertNumberToDPFst(item.freeSpinWin);
                    const startPoint = convertNumberToDPFst(item.startPoint);
                    const endPoint = convertNumberToDPFst(item.endPoint);
                    const slotResult = item.slotResult;

                    return (
                        <RowItem.Container key={index} $isEven={isEven} className={isToggled ? 'bg-opacity-50' : ''}>
                          <RowItem.Box $width={slotListHeaderProps[0].width}>{indexNumber}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[1].width}>{createdAt}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[2].width}>{endedAt}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[3].width}>{gameType}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[4].width}>{spin}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[5].width}>{bet}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[6].width}>{win}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[7].width}>{freeSpin}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[8].width}>{freeSpinWin}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[9].width}>{startPoint}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[10].width}>{endPoint}</RowItem.Box>
                          <RowItem.Box $width={slotListHeaderProps[11].width}>{addPlusOrMinusWithResult(slotResult)}</RowItem.Box>
                        </RowItem.Container>
                    );
                  })}
                  pager={{
                    totalPageCount,
                    perPage,
                    currentPage,
                    setCurrentPage,
                  }}
                  isLoading={isLoading}
                  error={error}
              />
            );
          }}
        </PaginateUserBettingHistoryContainer>
      </div>
    </ContentLayout>
  );
}
