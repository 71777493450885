import React, { Component, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { useStyles } from './style/HashDialogStyles';
import { ReactComponent as CloseIcon } from '../../img/CloseIcon.svg';
import clsx from 'clsx';

import * as config from '../../config';
import { GetAddHttpURL, SafeLog, ChangeProgress } from '../../util/jsUtil';
import useLang from '../../lib/hook/useLang';
import * as define from '../../define'
import CopyToClipboard from 'react-copy-to-clipboard';

const rows = [3, 1, 4, 7, 8, 9, 11, 12, 2, 10, 5, 6];

export default function HashDialog(props) {
  const { lngT } = useLang('racing:timetable');
  const classes = useStyles();
  const { onClose, open } = props;

  const [rowsBlock, setRowsBlock] = React.useState([]);
  const [rowsHash, setRowsHash] = React.useState([]);

  const [strHash, setStrHash] = React.useState('');
  const [isCopied, setIsCopied] = useState(false);
  const hashUrl = define.BLOCKCHAIN_LINK();
  const hashValue = open.block;
  

  useEffect(() => {
    var url = config.GetPrefixURL() + config.GetBetServerURL();
    fetch(`${url}/api/v1/getrankfromhash`, {
      method: 'POST', // 또는 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hash: props.open.hash,
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setRowsBlock(data);
        setRowsHash([]);
      })
      .catch(e => {
        //SafeLog(e);
      });
  }, [props.open.hash]);

  const clickHashProof = () => {
    var url = config.GetPrefixURL() + config.GetBetServerURL();
    fetch(`${url}/api/v1/getrankfromhash`, {
      method: 'POST', // 또는 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        hash: strHash, //props.open.hash
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setRowsHash(data);
      })
      .catch(e => {
        //SafeLog(e);
      });
  };

  const onChange = event => {
    setStrHash(event.target.value);
    //console.log("change");
  };

  const onStart = () => {
    props.onClose();
    //progress: {
    //    game: props.row.id,
    //    userName: userName,
    //    progress: data.Progress,
    //    lang: i18n.language
    //  }

    if (props.open.progress.game === undefined) {
      return;
    }
    // ChangeProgress(
    //   props.open.progress.game,
    //   props.open.progress.userName,
    //   props.open.progress.progress,
    //   props.open.progress.lang
    // );
  };

  return (
    <Dialog open={open.open} onClose={onClose} className={classes.root}>
      <IconButton disableRipple className={classes.buttonClose} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.dialogStyle}>
        <div className="relative bg-gradient-to-r from-white/0 via-white to-white/0 w-96 mx-auto max-M:mx-0">
          <DialogTitle className="text-[#222]">{/*HASH VALUE*/ lngT('hashDialog:hashValue')}</DialogTitle>
        </div>
        <DialogContent>
          <span className={classes.gutter}></span>
          <Box mb={2}>
            <Typography variant="h5" className={classes.titleStyle}>
              {define.format(lngT('hashDialog:ethereumBlock'), define.BLOCKCHAIN_NAME())}
            </Typography>
            <Typography className={classes.textBoxStyle2}>
              <a href={hashUrl + hashValue} target="_blank">
                {open.block}
              </a>
            </Typography>
          </Box>
          <Typography variant="h5" className={classes.titleStyle}>
            {/*Hash Code*/ lngT('hashDialog:hashCoed')}
          </Typography>
          <Box mb={4}>
            <Typography className={classes.textBoxStyle}>
              <CopyToClipboard
                className="hover:opacity-70 cursor-pointer"
                text={open.hash}
                onCopy={() => {
                  setTimeout(() => setIsCopied(false), 2000);
                  setIsCopied(true);
                }}
              >
                <div className="hover:opacity-70">{isCopied ? 'Copied!!' : open.hash}</div>
              </CopyToClipboard>

              {/* {open.hash} */}
              {/* <a href={hashUrl + hashValue} target="_blank" className="hover:opacity-70">
                {open.hash}
              </a> */}
            </Typography>
            <div className="flex flex-row space-x-2 justify-center">
              {rowsBlock.map(row => (
                <span key={row} className={classes.numberStyle}>
                  {row}
                </span>
              ))}
            </div>
          </Box>
          <span className={classes.gutter}></span>
          <Box className={classes.headerStyle}>
            <Typography variant="h3">{/*Verify Hash Code*/ lngT('hashDialog:verifyHashCoed')}</Typography>
            <Typography variant="subtitle">
              {
                /*If you went to prove the hash code,
                  You can perform a hash verification*/ lngT('hashDialog:verifySubtitle')
              }
            </Typography>
          </Box>
          <Box className={classes.ftFieldStyle}>
            <Typography variant="h5" className={classes.titleStyle} mb={1}>
              {/*Hash code*/ lngT('hashDialog:hashCoed')}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <TextField
                className={classes.inputStyle}
                onChange={onChange}
                placeholder={
                  /*"oxdkjfl3f93j3948f93w402jfe9r309f39fjsklfjw39fdkjf9fdkfjslkjfslkdf"*/ /*'input hash'*/ lngT(
                    'hashDialog:inputHash'
                  )
                }
                variant="outlined"
              />
              <Button className={classes.buttonStyle} onClick={clickHashProof} disableRipple>
                {/*Check*/ lngT('hashDialog:check')}{' '}
              </Button>
            </Box>
            <div className="flex flex-row space-x-2 justify-center">
              {rowsHash.map(row => (
                <span key={row} className={classes.numberStyle2}>
                  {row}
                </span>
              ))}
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={onStart} className={classes.btnConfirmStyle} variant="outlined" autoFocus disableRipple>
            {lngT('hashDialog:gameStart')}
          </Button>
        </DialogActions> */}
      </Box>
    </Dialog>
  );
}
