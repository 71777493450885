import { makeStyles } from "@material-ui/styles";


export const useResultBoxComponentStyles = makeStyles(theme => ({
    resultBox:{
        margin:'20px 0 10px',
        textAlign:'center',
        '& .MuiTypography-root':{
            color:'#ffffffd9',
            fontSize:'1.12rem',
            '& b':{
                color:'#fff',
            },
            '@media all and (max-width:360px)':{
                fontSize:'1rem!important',
            }
        }
    },
}));