import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  text?: string;
};

export default function EventDetailLayout({ children, text }: Props) {
  return (
    <div className="flex flex-col items-center w-full h-full mt-4 T:mt-0 mb-16">
      {text && <div className="flex w-full max-w-[600px] text-lg font-semibold text-white mb-4">{text}</div>}
      <div className="w-full max-w-[600px] bg-white/10 rounded-md">{children}</div>
    </div>
  );
}
