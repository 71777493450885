import React, { Component } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useStyles } from './styles/RateStyles';
import { ReactComponent as OddsGradient } from '../../assets/oddsGradient.svg';

export default function Rate(props) {
  return (
    <div className="relative flex flex-row justify-center items-center w-full h-[70px]">
      <div className="bg-[#C4F322] absolute w-[300px] h-full rounded-lg"></div>
      <div className="relative flex flex-row justify-center items-center text-[#3B25BB] space-x-2">
        <div className="text-xl">ODDS</div>
        <div>:</div>
        <div className="text-3xl font-semibold tracking-wide">{`${props.odds ? props.odds : '0'}`}</div>
      </div>
    </div>
  );
}
