import React from 'react';
import tw from 'tailwind-styled-components';

const TextContainer = tw.div`
  flex flex-row pl-6 space-x-6
`;

const TextWrap = tw.div`
  flex flex-row pl-10 space-x-6
`;

const Title = tw.h2`
    text-white text-[48px]
`;
const SmallTitle = tw.h4`
text-white text-[20px]
`;
const Text = tw.p`
    text-[14px] text-[#B9B8BB]
`;
const ColorText = tw.span`
    text-[#D470FF]
`;

const UnderLine = tw.span`
  underline decoration-solid text-[#F7E3FF]
`;
export default function Privacy() {
  return (
    <div className="mx-8 T:mx-[100px] D:mx-[267px] flex flex-col justify-center items-center space-y-[88px] py-[88px]">
      <Title>POLÍTICA DE PRIVACIDADE</Title>
      <div className="space-y-8">
        <Text>
          O
          <ColorText>
            <ColorText>U.Podium</ColorText>
          </ColorText>
          e seus funcionários estão comprometidos em coletar e armazenar seus dados pessoais de acordo com as mais
          rígidas normas internacionais e regulamentações aplicáveis para garantir sua segurança e gestão adequada.
          Pretendemos informá-lo sobre como as informações que coletamos de você são usadas e armazenadas. Esta política
          de privacidade descreve as práticas de processamento de dados usadas por nossa empresa e as opções que você
          tem quando se trata de suas informações serem processadas por nós.
        </Text>
        <Text>
          O
          <ColorText>
            <ColorText>U.Podium</ColorText>
          </ColorText>
          tem o compromisso de fornecer informações detalhadas e precisas sobre o uso de cookies, para que todos os que
          visitam nosso site possam tomar uma decisão informada sobre armazenar ou não cookies em seu dispositivo. Se
          você tiver alguma dúvida, preocupação ou sugestão sobre os dados que coletamos de você, ou perguntas gerais
          sobre nossos serviços, não hesite em nos contatar via nosso email:
          <UnderLine>support@u-podium.com</UnderLine>. Se você estiver preocupado com o uso de cookies em qualquer uma
          das páginas do nosso site, sinta-se à vontade para nos enviar detalhes sobre isso para nosso email:
          <UnderLine>support@u-podium.com</UnderLine>. O
          <ColorText>
            <ColorText>U.Podium</ColorText>
          </ColorText>
          , como empresa, está empenhado em garantir a total segurança dos dados que lhe são confiados. Esta Política de
          Privacidade explica exatamente como coletamos suas informações quando você opta por usar nossos serviços e
          como as usamos posteriormente. Esta Política de Privacidade será acordada entre você e o
          <ColorText>
            <ColorText>U.Podium</ColorText>
          </ColorText>
          ("nós" ou "nosso", conforme aplicável). Observe que, ao usar nosso site e seus serviços, você está agindo como
          parte desta Política de Privacidade. Esta Política de Privacidade pode mudar de tempos em tempos. Você poderá
          se manter atualizado com essas mudanças revisando nossos sites e plataformas. É sua responsabilidade verificar
          nossa Política de Privacidade e manter-se atualizado sobre as últimas alterações. Priorizamos a proteção de
          informações físicas e reais e controlamos constantemente o acesso às informações por meio de uma variedade de
          sistemas à prova de falhas, bem como métodos avançados de criptografia e criptografia de dados. Por isso o
          <ColorText>
            <ColorText>U.Podium</ColorText>
          </ColorText>
          utiliza os sistemas mais seguros do mercado. A empresa é responsável e obrigada a proteger a privacidade do
          usuário. Qualquer informação que recebermos será usada apenas para garantir nossos serviços. A menos que você
          nos tenha dado permissão prévia para divulgar suas informações pessoais, nenhuma informação obtida durante o
          registro, depósito ou saque será fornecida a terceiros para qualquer outro uso não inerente à funcionalidade
          do site, e todas as informações serão armazenadas como com segurança possível.
        </Text>
        <Text>
          O
          <ColorText>
            <ColorText>U.Podium</ColorText>
          </ColorText>
          não compartilha, aluga, vende ou empresta seu banco de dados com quaisquer outras empresas, instituições ou
          associações, exceto fornecedores e parceiros de negócios que tenham firmado compromisso contratual de
          cumprimento da Política de Privacidade da Empresa. Adotámos todas as medidas de segurança recomendadas pelo
          setor e investimos em tecnologia avançada de proteção de dados para mantê-lo seguro. Todos os dados em nosso
          sistema são criptografados e arquivados em locais com acesso exclusivo.
        </Text>
        <SmallTitle>CONSENTIMENTO PARA PROCESSAR SEUS DADOS PESSOAIS</SmallTitle>
      </div>

      {/* 1. SEUS DIREITOS  */}
      <div className="space-y-6">
        <SmallTitle>1. SEUS DIREITOS COMO TITULAR DE DADOS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            O &nbsp;
            <ColorText>
              <ColorText>U.Podium&nbsp;</ColorText>
            </ColorText>
            só começará a processar seus dados pessoais após seu consentimento explícito. Você concorda que tal
            consentimento constitui consentimento informado, explícito e dado livremente (doravante referido como
            'Consentimento') para o processamento de seus dados pessoais conforme estabelecido nesta Política de
            Privacidade.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>2.</Text>
            <Text>
              Seu consentimento para o processamento de dados pessoais pode ser dado por você ou seu representante
              designado de qualquer uma das seguintes maneiras:
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>1.</Text>
              <Text>Por escrito, no caso em que o seu Consentimento deve incluir o seguinte:</Text>
            </TextWrap>
            <div className="pl-10 space-y-2">
              <TextWrap>
                <Text>&bull;</Text>
                <Text>
                  nome, nome próprio e patronímico (se aplicável), morada de residência, número do documento utilizado
                  para identificar o titular dos dados, data de emissão e autoridade do documento;
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>&bull;</Text>
                <Text>
                  Se o consentimento for dado por um representante, uma procuração com firma reconhecida ou outro
                  documento que comprove a autoridade competente do representante;
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>&bull;</Text>
                <Text>
                  Endereço postal do &nbsp;
                  <ColorText>U.Podium</ColorText>&nbsp; para receção do Consentimento dado pelo Titular dos Dados;
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>&bull;</Text>
                <Text>As finalidades para as quais os dados pessoais fornecidos serão processados;</Text>
              </TextWrap>
              <TextWrap>
                <Text>&bull;</Text>
                <Text>
                  O nome e endereço da pessoa ou entidade habilitada a tratar os dados pessoais para os quais é dado o
                  consentimento em nome do
                  <ColorText>
                    <ColorText>U.Podium</ColorText>
                  </ColorText>
                  , caso o tratamento lhe tenha sido delegado;
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>&bull;</Text>
                <Text>
                  A duração do Consentimento, durante o qual os dados podem ser processados e como o Consentimento pode
                  ser retirado;
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>&bull;</Text>
                <Text>A assinatura da pessoa que dá o Consentimento ou do seu representante legal.</Text>
              </TextWrap>
            </div>

            <TextWrap>
              <Text>2.</Text>
              <Text>
                Ao realizar determinadas ações que indiquem expressamente o desejo de estabelecer uma relação jurídica
                com o <ColorText>U.Podium</ColorText>, como se cadastrar no <ColorText>U.Podium</ColorText> e criar uma
                conta, registrar-se nos serviços oferecidos ou realizar transações com uma conta previamente criada.
                Isso permitirá que uma pessoa participe nas apostas no <ColorText>U.Podium</ColorText> de acordo com os
                Termos e Condições do <ColorText>U.Podium</ColorText> e faça apostas e ganhos através da conta criada de
                acordo com as regras estabelecidas pelo operador de jogo.
              </Text>
            </TextWrap>
            <div className="pl-10 space-y-2">
              <TextWrap>
                <Text>1.</Text>
                <Text>
                  O seu consentimento não é necessário para o tratamento dos seus dados em nenhuma das seguintes
                  situações:
                </Text>
              </TextWrap>
              <div className="pl-10 space-y-2">
                <TextWrap>
                  <Text>1.</Text>
                  <Text>
                    Quando o tratamento dos dados for necessário para os fins previstos em acordos internacionais,
                    legislação local, para o bom desempenho dos deveres e funções legais;
                  </Text>
                </TextWrap>
                <TextWrap>
                  <Text>2.</Text>
                  <Text>
                    Quando o processamento de dados pessoais for necessário para a administração da justiça ou a
                    execução de uma decisão formal de um tribunal ou outra decisão da autoridade judiciária competente
                  </Text>
                </TextWrap>
                <TextWrap>
                  <Text>3.</Text>
                  <Text>
                    Quando o processamento de dados pessoais for necessário para proteger os interesses vitais do
                    titular dos dados pessoais, incluindo, entre outros, sua saúde e vida
                  </Text>
                </TextWrap>
                <TextWrap>
                  <Text>4.</Text>
                  <Text>
                    Quando os dados pessoais devam ser processados para exercer os direitos e interesses legítimos da
                    Empresa ou de terceiros, desde que os seus direitos liberdades não sejam infringidos;
                  </Text>
                </TextWrap>
                <TextWrap>
                  <Text>5.</Text>
                  <Text>
                    Onde os dados pessoais devam ser processados para fins de obtenção de dados estatísticos
                    específicos, bem como para outros fins de pesquisa. Nesse caso, seus dados pessoais serão
                    anonimizados, a menos que exigido de outra forma para fins de marketing;
                  </Text>
                </TextWrap>
                <TextWrap>
                  <Text>6.</Text>
                  <Text>
                    Quando um círculo não restrito de pessoas tem acesso livre aos dados pessoais processados,
                    fornecidos por você;
                  </Text>
                </TextWrap>
                <TextWrap>
                  <Text>7.</Text>
                  <Text>
                    Onde os dados pessoais que você processa estão sujeitos a TextWrapulgação obrigatória de acordo com
                    a legislação local.
                  </Text>
                </TextWrap>
              </div>
            </div>
            <div className="pl-10 space-y-2">
              <TextWrap>
                <Text>2.</Text>
                <Text>
                  A Empresa cumpre integralmente com as disposições do Regulamento Geral de Proteção de Dados (RGPD) em
                  relação aos procedimentos para retirar o consentimento já dado, obter informações sobre a
                  disponibilidade de seus dados pessoais e alterar, bloquear ou excluir permanentemente suas informações
                  pelo <ColorText>U.Podium</ColorText>.
                </Text>
              </TextWrap>
            </div>
          </div>
        </div>
      </div>

      {/* 2. PORQUE  */}
      <div className="space-y-6">
        <SmallTitle>2. PORQUE COLETAMOS INFORMAÇÃO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            As informações que coletamos incluem informações usadas para identificá-lo pessoalmente, como nome, data de
            nascimento, informações de cartão de crédito, endereço residencial, endereço de e-mail, número de telefone e
            outras informações que possam ser relevantes.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>2.</Text>
            <Text>
              Você pode ser solicitado a fornecer informações pessoais ao usar o site, criar uma conta ou usar os
              serviços que fornecemos. As informações pessoais que podem ser solicitadas a você podem incluir:
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>1.</Text>
              <Text>Detalhes de contato, que incluem seu endereço de e-mail e número de celular;</Text>
            </TextWrap>
            <TextWrap>
              <Text>2.</Text>
              <Text>Informações de residência (como seu endereço residencial);</Text>
            </TextWrap>
            <TextWrap>
              <Text>3.</Text>
              <Text>Informação de pagamento;</Text>
            </TextWrap>
            <TextWrap>
              <Text>4.</Text>
              <Text>Histórico de transações;</Text>
            </TextWrap>
            <TextWrap>
              <Text>5.</Text>
              <Text>Preferências do site;</Text>
            </TextWrap>
            <TextWrap>
              <Text>6.</Text>
              <Text>Feedback sobre os serviços que você usa;</Text>
            </TextWrap>
            <TextWrap>
              <Text>7.</Text>
              <Text>Qualquer outra informação que você nos forneça ao usar nosso site.</Text>
            </TextWrap>
          </div>
        </div>

        <TextContainer>
          <Text>3.</Text>
          <Text>
            Coletamos, processamos e armazenamos informações em nossos servidores de acordo com as leis aplicáveis.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>4.</Text>
            <Text>
              Quando você interage com nossos sites e usa nossos serviços, mantemos um registro de seu cadastro de
              atividades exclusivo, que inclui as seguintes informações:
            </Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>1.</Text>
              <Text>Endereço de IP;</Text>
            </TextWrap>
            <TextWrap>
              <Text>2.</Text>
              <Text>Tempo gasto usando o site;</Text>
            </TextWrap>
            <TextWrap>
              <Text>3.</Text>
              <Text>Data de acesso;</Text>
            </TextWrap>
            <TextWrap>
              <Text>4.</Text>
              <Text>Páginas específicas visualizadas;</Text>
            </TextWrap>
            <TextWrap>
              <Text>5.</Text>
              <Text>Preferência de idioma;</Text>
            </TextWrap>
            <TextWrap>
              <Text>6.</Text>
              <Text>Relatórios de falhas de software;</Text>
            </TextWrap>
            <TextWrap>
              <Text>7.</Text>
              <Text>O tipo de navegador utilizado;</Text>
            </TextWrap>
            <TextWrap>
              <Text>8.</Text>
              <Text>Qualquer outra informação que você nos forneça ao usar nosso site.</Text>
            </TextWrap>
          </div>
        </div>
      </div>

      {/* 3. COMO COLETAMOS E PROCESSAMOS INFORMAÇÃO */}
      <div className="space-y-6">
        <SmallTitle>3. COMO COLETAMOS E PROCESSAMOS INFORMAÇÃO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Podemos coletar automaticamente determinadas informações conforme estabelecido acima e também podemos
            receber suas informações pessoais sempre que você as fornecer voluntariamente ao interagir com o site&nbsp;
            <ColorText>U.Podium</ColorText> ou ao usar outros serviços e comunicações. As informações pessoais também
            podem ser obtidas de outros provedores de serviços e listas de clientes obtidas de provedores de serviços
            terceirizados de maneira estritamente legal e em total conformidade com todas as leis e regulamentos
            aplicáveis. Provedores de serviços terceirizados podem ser usados para dar suporte técnico às suas
            transações de dinheiro e atender adequadamente sua conta. Quaisquer informações pessoais coletadas das
            formas acima de provedores de serviços, provedores de serviços terceirizados e serviços de comércio
            eletrônico serão usadas estritamente de acordo com as disposições descritas nesta Política de Privacidade.
            Suas informações pessoais serão divulgadas a terceiros estritamente de acordo com esta Política de
            Privacidade e a lei aplicável.
          </Text>
        </TextContainer>
      </div>

      {/* 4. USANDO OS DADOS COLETADOS  */}
      <div className="space-y-6">
        <SmallTitle>2. PORQUE COLETAMOS INFORMAÇÃO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            As informações pessoais que você nos fornece destinam-se a nos ajudar a oferecer serviços de suporte de alta
            qualidade, garantir sua segurança em toda a sua experiência no site, verificar e confirmar sua identidade,
            processar sua transação adequadamente, facilitar sua participação em promoções de terceiros e para vários
            outros fins relacionados com a provisão dos nossos serviços de jogo.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Podemos, portanto, compartilhar suas informações pessoais com nossos parceiros cuidadosamente selecionados
            (incluindo quaisquer outras partes que tenham celebrado um acordo com você para compartilhar suas
            informações) durante a prestação de nossos serviços.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>3.</Text>
            <Text>Também podemos usar as informações pessoais que você fornece para:</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>1.</Text>
              <Text>Enviar ofertas promocionais e informações sobre produtos e serviços oferecidos</Text>
            </TextWrap>
            <TextWrap>
              <Text>2.</Text>
              <Text>
                Enviar ofertas promocionais e informações sobre produtos e serviços oferecidos por nossos parceiros para
                o atender melhor e melhorar a experiência do cliente.
              </Text>
            </TextWrap>
            <TextWrap>
              <Text>3.</Text>
              <Text>
                Ocasionalmente, podemos solicitar que você nos forneça informações em pesquisas e concursos especiais.
                Você tem o direito de se recusar a participar em tais eventos e não TextWrapulgar nenhuma informação
                pessoal.
              </Text>
            </TextWrap>
            <TextWrap>
              <Text>4.</Text>
              <Text>
                As informações que podemos solicitar podem incluir detalhes de contato (incluindo seu nome, endereço de
                e-mail, número de celular), informações geográficas (como seu endereço e código postal) e sua idade
                exata.
              </Text>
            </TextWrap>
            <TextWrap>
              <Text>5.</Text>
              <Text>
                Entende-se que, ao participar de qualquer competição organizada pela Empresa e aceitar prêmios ou
                reivindicar seus ganhos, você consente que usemos seu nome para fins promocionais sem direito a qualquer
                remuneração adicional, a menos que tal remuneração seja exigida por lei.
              </Text>
            </TextWrap>
          </div>
        </div>
      </div>

      {/* 5. DIVULGAÇÃO DE INFORMAÇÃO  */}
      <div className="space-y-6">
        <SmallTitle>5. DIVULGAÇÃO DE INFORMAÇÃO</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Suas informações pessoais não serão compartilhadas com outras empresas, organizações ou pessoas fora
            do&nbsp;
            <ColorText>U.Podium</ColorText>.
          </Text>
        </TextContainer>

        <TextContainer>
          <Text>2.</Text>
          <Text>
            Suas informações pessoais podem ser divulgadas a outras empresas, organizações e pessoas não afiliadas
            ao&nbsp;
            <ColorText>U.Podium</ColorText> se você nos der seu consentimento para fazê-lo.
          </Text>
        </TextContainer>
        <div className="space-y-4">
          <TextContainer>
            <Text>3.</Text>
            <Text>
              Suas informações pessoais podem ser divulgadas se formos obrigados a fazê-lo por lei ou se acreditarmos de
              boa fé que é necessário:
            </Text>
          </TextContainer>
          <TextWrap>
            <Text>1.</Text>
            <Text>Proteger nossos direitos ou propriedade;</Text>
          </TextWrap>
          <TextWrap>
            <Text>2.</Text>
            <Text>Proteger outros clientes que usam nossos produtos ou serviços;</Text>
          </TextWrap>
          <TextWrap>
            <Text>3.</Text>
            <Text>
              Se tivermos motivos para acreditar que alguém fez ou está prestes a fazer algo injusto ou ilegal, como:
            </Text>
          </TextWrap>
          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>0.</Text>
              <Text>Adulteração de jogos,</Text>
            </TextWrap>
            <TextWrap>
              <Text>1.</Text>
              <Text>Cometer fraude de pagamento (que inclui o uso de cartões roubados)</Text>
            </TextWrap>
            <TextWrap>
              <Text>2.</Text>
              <Text>Solicitar o cancelamento e retorno de pagamentos,</Text>
            </TextWrap>
            <TextWrap>
              <Text>3.</Text>
              <Text>Transações proibidas (incluindo lavagem de dinheiro),</Text>
            </TextWrap>
            <TextWrap>
              <Text>4.</Text>
              <Text>
                Reservamo-nos o direito de transmitir essas informações e seus dados pessoais a outros sites de jogos,
                autoridades policiais, empresas de cartão de crédito e outros órgãos reguladores relevantes.
              </Text>
            </TextWrap>
            <TextWrap>
              <Text>5.</Text>
              <Text>
                Suas informações pessoais podem ser compartilhadas com as agências relevantes para fins de pesquisa
                pública e prevenção do vício em jogos de azar.
              </Text>
            </TextWrap>
          </div>
        </div>
      </div>

      {/* 6. ACESSO AOS SEUS DADOS  */}
      <div className="space-y-6">
        <SmallTitle>6. ACESSO AOS SEUS DADOS</SmallTitle>
        <div className="space-y-4">
          <TextContainer>
            <Text>1.</Text>
            <Text>Você pode entrar em contato conosco a qualquer momento para</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>Descobrir quais informações coletamos, processamos e mantemos e de quais fontes as obtivemos.</Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>
                Para confirmar a exatidão das informações pessoais fornecidas por você ou obtidas por nós de qualquer
                outra forma.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>Para atualizar as informações pessoais, coletamos e atualmente mantemos.</Text>
            </TextContainer>
            <TextContainer>
              <Text>4.</Text>
              <Text>Fazer uma reclamação sobre como suas informações pessoais estão sendo usadas.</Text>
            </TextContainer>
            <TextContainer>
              <Text>5.</Text>
              <Text>
                Atualizaremos qualquer informação já mantida se você puder mostrar que tais alterações são razoáveis e
                necessárias e fornecer evidências suficientes de sua identidade.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>6.</Text>
              <Text>
                Para esclarecer, nada nesta Política de Privacidade nos isenta de compartilhar suas informações pessoais
                se formos obrigados a fazê-lo por uma solicitação formal feita pela autoridade estatutária relevante em
                seu país.
              </Text>
            </TextContainer>
          </div>
        </div>
      </div>

      {/* 7. COOKIES*/}
      <div className="space-y-6">
        <SmallTitle>7. COOKIES</SmallTitle>
        <div className="space-y-4">
          <TextContainer>
            <Text>1.</Text>
            <Text>INFORMAÇÃO GUARDADA NO SEU DISPOSITIVO</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>
                Quando você usa nossos serviços, certas informações são armazenadas em seu dispositivo com o seu
                consentimento. Essas informações são conhecidas como cookies, que é um pequeno arquivo de texto que
                armazena suas preferências. As cookies são sempre armazenados no seu dispositivo quando você usa os
                serviços enquanto visita o site.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>2.</Text>
              <Text>
                Cookies pode ser usado para acessar e usar qualquer informação armazenada em seu dispositivo ou
                computador. Cookies são usados exclusivamente para fins de monitoramento.
              </Text>
            </TextContainer>
            <TextContainer>
              <Text>3.</Text>
              <Text>
                Eles também são usados para descobrir exatamente como você usa nossos serviços e quais são suas
                preferências. As cookies são úteis para monitorar o tráfego do site e melhorar os serviços para aumentar
                seu interesse em permanecer conosco como cliente. Cookies do navegador também nos ajudam a garantir que
                apenas publicidade relevante e direcionada com precisão seja exibida a você.
              </Text>
            </TextContainer>
          </div>
        </div>
        <div className="space-y-4">
          <TextContainer>
            <Text>2.</Text>
            <Text>COOKIES ESTRITAMENTE NECESSÁRIAS</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>
                Cookies estritamente necessárias são o termo usado para cookies necessárias para que o usuário navegue
                no site e use todas as funções disponíveis, incluindo o acesso às seções de pagamento do site. Essas
                cookies são usadas para garantir o bom funcionamento do site.
              </Text>
            </TextContainer>
          </div>
        </div>
        <div className="space-y-4">
          <TextContainer>
            <Text>3.</Text>
            <Text>COOKIES DE REGISTRO</Text>
          </TextContainer>
          <div className="pl-10 space-y-2">
            <TextContainer>
              <Text>1.</Text>
              <Text>
                Essas cookies armazenam as informações que você insere ao se registrar e nos permitem fornecer um
                serviço com qualidade. Esses dados são usados para que possamos entender melhor o que você precisa como
                cliente e garantir que você aproveite sua visita ao site e nossos serviços.
              </Text>
            </TextContainer>
          </div>
        </div>
        <div className="space-y-4">
          <TextContainer>
            <Text>4.</Text>
            <Text>COOKIES DO SITE</Text>
          </TextContainer>

          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>1.</Text>
              <Text>
                Essas cookies são usadas para coletar informações sobre como os visitantes estavam usando os serviços
                oferecidos. Existem diferentes tipos de cookies com base na finalidade a que se destinam:
              </Text>
            </TextWrap>
            <div className="pl-10 space-y-2">
              <TextWrap>
                <Text>0.</Text>
                <Text>
                  Cookies 'baseados na sessão'. Essas cookies são armazenadas no seu dispositivo durante a sua visita ao
                  site e ajudam em uma navegação mais rápida. A sua utilização permite oferecer aos nossos usuários
                  registados informação e experiência mais relevantes. Essas cookies expiram automaticamente quando seu
                  navegador é fechado.
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>1.</Text>
                <Text>
                  Cookies 'persistentes'. Essas cookies permanecem no seu computador durante um período de tempo
                  específico.
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>2.</Text>
                <Text>
                  Cookies 'analíticas'. Essas cookies estão em vigor para contar quantos visitantes acessaram o site e
                  usaram nossos serviços, o que nos ajuda a melhorar sua experiência de navegação e permite que você
                  encontre as coisas mais rapidamente.
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>3.</Text>
                <Text>Você toma uma decisão e sempre tem a opção de aceitar ou recusar cookies.</Text>
              </TextWrap>
            </div>
          </div>
          <div>
            <TextWrap>
              <Text>2.</Text>
              <Text>
                A maioria dos navegadores da web aceita cookies por padrão, mas se você optar por isso, sempre poderá
                modificar as configurações do seu navegador para ter controle total sobre as cookies aceitas.
              </Text>
            </TextWrap>
          </div>
          <div>
            <TextWrap>
              <Text>3.</Text>
              <Text>
                Usando seu navegador da web, você pode excluir, bloquear ou permitir todos as cookies, bloquear cookies
                de terceiros, limpar todos as cookies ao fechar o navegador, iniciar sessão no modo de navegação anônima
                para navegar na internet sem armazenar dados em seu dispositivo, bem como estender a opções disponíveis
                em seu navegador instalando complementos e plug-ins.
              </Text>
            </TextWrap>
          </div>
          <div className="pl-10 space-y-2">
            <TextWrap>
              <Text>4.</Text>
              <Text>
                Abaixo você pode encontrar links para informações sobre o gerenciamento de cookies em vários
                navegadores:
              </Text>
            </TextWrap>
            <div className="pl-10 space-y-2">
              <TextWrap>
                <Text>0.</Text>
                <Text>
                  Gerenciar cookies no <UnderLine>Internet Explorer</UnderLine>
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>1.</Text>
                <Text>
                  Gerenciar cookies no <UnderLine>Chrome</UnderLine>
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>2.</Text>
                <Text>
                  Gerenciar cookies no <UnderLine>Firefox</UnderLine>
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>3.</Text>
                <Text>
                  Gerenciar cookies no <UnderLine>Safari</UnderLine>
                </Text>
              </TextWrap>
              <TextWrap>
                <Text>4.</Text>
                <Text>
                  Gerenciar cookies no <UnderLine>Opera</UnderLine>
                </Text>
              </TextWrap>
            </div>
          </div>
          <TextWrap>
            <Text>5.</Text>
            <Text>
              Para obter mais informações, visite www.aboutcookies.org para obter instruções sobre como controlar e
              excluir cookies. Observe que não somos responsáveis pelo conteúdo de outros sites. Além disso, devemos
              informar que ao desabilitar as cookies você não poderá acessar todas as funcionalidades do&nbsp;
              <ColorText>U.Podium</ColorText>.
            </Text>
          </TextWrap>
        </div>
      </div>

      {/* 8. CONSENTIMENTO PARA A TRANSFERÊNCIA DE INFORMAÇÕES A PRESTADORES DE SERVIÇOS ELETRÔNICOS*/}
      <div className="space-y-6">
        <SmallTitle>
          8. CONSENTIMENTO PARA A TRANSFERÊNCIA DE INFORMAÇÕES A PRESTADORES DE SERVIÇOS ELETRÔNICOS
        </SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Para poder jogar jogos com dinheiro real em nosso site, você precisará transferir dinheiro para sua conta de
            jogador. Você também precisará sacar fundos da sua conta. Para facilitar esses processos, podemos recorrer a
            sistemas de pagamento eletrônico de terceiros. Ao aceitar esta Política de Privacidade, você também fornece
            seu consentimento para que suas informações pessoais sejam transferidas para terceiros conforme necessário
            para o processamento adequado de suas transações, inclusive fora de seu país em alguns casos. Temos medidas
            em vigor para garantir a segurança quando sistemas de terceiros são usados para processamento de pagamentos.
          </Text>
        </TextContainer>
      </div>

      {/* 9. CONSENTIMENTO PARA AUDITORIAS DE SEGURANÇA*/}
      <div className="space-y-6">
        <SmallTitle>9. CONSENTIMENTO PARA AUDITORIAS DE SEGURANÇA</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Reservamo-nos o direito de realizar verificações de segurança a nosso critério, a fim de verificar as
            informações pessoais que você fornece ao se cadastrar, verificar transações financeiras e garantir que você
            esteja fazendo uso adequado dos serviços que fornecemos. Isso pode ser para garantir que não haja violações
            das leis aplicáveis ou de nossos Termos e Condições. Ao continuar a usar nossos serviços e, assim, aceitar
            nossos Termos e Condições na íntegra, você nos autoriza a usar e divulgar suas informações pessoais a
            terceiros para realizar tais verificações de segurança a nosso critério, que podem incluir a transferência
            de suas informações para fora de seu país. As verificações de segurança podem incluir (mas não estão
            limitadas a) solicitação de relatórios de crédito e verificação das informações enviadas em relação a bancos
            de dados de terceiros disponíveis.
          </Text>
        </TextContainer>
      </div>

      {/* 10. SEGURANÇA*/}
      <div className="space-y-6">
        <SmallTitle>10. SEGURANÇA</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Entendendo a importância de garantir total segurança para nossos usuários, nossa empresa se compromete a
            utilizar todos os métodos disponíveis para garantir sua privacidade e acessibilidade de qualquer informação
            necessária. Todas as informações pessoais que recebemos de você são armazenadas em um banco de dados
            criptografado, protegido por senha e atualizado regularmente por software de firewall. Nossos Serviços usam
            criptografia SSL de 128 bits, firewalls para proteger os dados e um sistema de segurança de cinco etapas.
            Além disso, o&nbsp;<ColorText>U.Podium</ColorText> usa programas especiais para ajudar a detectar
            instantaneamente qualquer atividade fraudulenta ou manipulação.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Também fazemos todos os esforços para garantir medidas de segurança adequadas pelos nossos afiliados,
            subsidiárias, agentes, fornecedores, etc. No entanto, você deve estar ciente de que o envio de informações
            pela Internet, por definição, nunca é totalmente seguro e, como resultado, não podemos garantir a segurança
            dos seus dados durante o envio.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>3.</Text>
          <Text>
            Portanto, avisamos que você envia quaisquer dados por sua conta e risco. Assim que seus dados forem
            recebidos, o&nbsp;<ColorText>U.Podium</ColorText> utilizará procedimentos e práticas adequadas para garantir
            sua segurança.
          </Text>
        </TextContainer>
      </div>

      {/* 11. PROTEÇÃO JUVENIL*/}
      <div className="space-y-6">
        <SmallTitle>11. PROTEÇÃO JUVENIL</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Nossos serviços não se destinam e, portanto, não devem ser usados por menores (pessoas menores de 18 anos ou
            idade legal com base em sua jurisdição). Qualquer pessoa que se cadastre conosco e utilize nossos serviços
            certifica, diretamente ou através dos nossos serviços, que tem pelo menos 18 anos de idade ou a idade legal
            com base na sua jurisdição. É nossa política que sejam realizadas verificações de segurança para detectar
            quaisquer tentativas de uso de nossos serviços por menores. Se tomarmos conhecimento de qualquer tentativa
            de menores de fornecer informações pessoais e começar a usar nossos serviços, tomaremos todas as medidas
            necessárias para remover essas informações de nossos registros.
          </Text>
        </TextContainer>
      </div>

      {/* 12. TRANSFERÊNCIAS INTERNACIONAIS DE DADOS*/}
      <div className="space-y-6">
        <SmallTitle>12. TRANSFERÊNCIAS INTERNACIONAIS DE DADOS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Observe que as informações pessoais que coletamos de você podem ser armazenadas e processadas em qualquer
            país, dependendo de onde os serviços de jogo são fornecidos pelos nossos afiliados, fornecedores, parceiros
            ou agentes. Ao usar nossos serviços, você concorda com a transferência de qualquer informação que nos
            forneça para fora do seu país, inclusive para países cujas leis de privacidade podem não ser consideradas
            adequadas. No entanto, fazemos todos os esforços para garantir que nossos afiliados, agentes, parceiros e
            fornecedores cumpram os padrões internacionais de privacidade e cumpram esta Política de Privacidade.
          </Text>
        </TextContainer>
      </div>

      {/* 13. RELAÇÕES COM TERCEIROS*/}
      <div className="space-y-6">
        <SmallTitle>13. RELAÇÕES COM TERCEIROS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Quando você envia qualquer uma de suas informações para um site de terceiros vinculado aos nossos serviços
            ou suas informações pessoais são processadas e gerenciadas por um terceiro vinculado a nós ou de outra
            forma, não podemos garantir a proteção de tais informações, pois os sites dessas partes não são de
            propriedade ou operados por nós. Tenha cuidado ao fornecer suas informações pessoais a terceiros e saiba que
            o uso dessas informações estará sujeito às políticas aplicáveis dessas partes.
          </Text>
        </TextContainer>
      </div>

      {/* 14. AVISO LEGAL*/}
      <div className="space-y-6">
        <SmallTitle>14. AVISO LEGAL</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Nossa empresa não é responsável por quaisquer eventos além do nosso controle direto. Não seremos
            responsáveis por quaisquer danos, diretos ou indiretos, decorrentes do uso indevido ou roubo de suas
            informações pessoais. Como a tecnologia moderna é complexa e está em constante mudança, fazemos todos os
            esforços para garantir que suas informações estejam livres de erros e sejam confidenciais quando você for
            redirecionado para outros sites. No entanto, não podemos dar nenhuma garantia sobre sites que não estão sob
            nosso controle e não são cobertos por esta Política de Privacidade. Ao visitar esses sites, esteja ciente de
            que os operadores desses sites também podem coletar, usar e processar suas informações pessoais de acordo
            com suas políticas de privacidade, que podem ser diferentes das nossas. Certifique-se de ler esta política
            antes de concordar com a coleta e uso de suas informações pessoais. Não podemos garantir a segurança de suas
            informações pessoais enquanto estão sendo transmitidas pelos vários canais, embora façamos todos os esforços
            para garantir seu processamento e transmissão seguros. Você é avisado de que envia quaisquer dados por sua
            conta e risco.
          </Text>
        </TextContainer>
      </div>

      {/* 15. CONSENTIMENTO DA POLÍTICA DE PRIVACIDADE*/}
      <div className="space-y-6">
        <SmallTitle>15. CONSENTIMENTO DA POLÍTICA DE PRIVACIDADE</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Ao usar nossos serviços, você consente expressa e livremente com nossa Política de Privacidade e nos concede
            o direito de coletar, processar e armazenar suas informações pessoais. Esta Política de Privacidade deve ser
            lida em conjunto com nossos Termos e Condições e quaisquer Termos e Condições publicados em nossas outras
            plataformas e sites. Esta Política de Privacidade pode ser alterada de tempos em tempos e você pode ser
            notificado de tais alterações revisando regularmente nossos Termos e Condições. Seu uso continuado de nossos
            serviços após tais mudanças constituirá sua aceitação da nova Política de Privacidade em sua totalidade.
            Esta é a Política de Privacidade completa e exclusiva do nosso site <ColorText>u-podium</ColorText>.com, em
            vigor a partir de 23.05.2023, e substitui todas as versões anteriores. É sua responsabilidade verificar
            nossos termos e condições e manter-se atualizado com as últimas alterações.
          </Text>
        </TextContainer>
      </div>

      {/* 16. POLÍTICA AML*/}
      <div className="space-y-6">
        <SmallTitle>16. POLÍTICA AML</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            O&nbsp;<ColorText>U.Podium</ColorText> possui medidas para prevenir e combater a lavagem de dinheiro e o
            terrorismo internacional (a Política AML). A Empresa tem uma política de tolerância zero em relação a
            quaisquer atividades ilegais. Para cumprir suas obrigações de acordo com a política AML, a Empresa se
            compromete a informar todas as autoridades competentes se houver alguma razão para suspeitar que quaisquer
            fundos depositados por quaisquer usuários em qualquer ponto foram vinculados, diretamente ou de outra forma,
            a quaisquer atividades ilegais, incluindo, mas não limitado ao terrorismo. A empresa se compromete a
            bloquear tais fundos e tomar as medidas apropriadas descritas na política AML.
          </Text>
        </TextContainer>
      </div>

      {/* 17. RETENÇÃO DE DADOS*/}
      <div className="space-y-6">
        <SmallTitle>17. RETENÇÃO DE DADOS</SmallTitle>
        <TextContainer>
          <Text>1.</Text>
          <Text>
            Conforme estabelecido em nossos Termos e Condições, você e a Empresa podem decidir encerrar sua Conta de
            Jogador a qualquer momento. Assim que sua conta for encerrada, ficaremos seus dados pessoais pelo tempo
            permitido por lei. Esses dados só serão usados quando exigidos pelas autoridades competentes em relação a
            investigações de registros financeiros e fiscais, fraude, lavagem de dinheiro ou investigação de qualquer
            outra atividade ilegal.
          </Text>
        </TextContainer>
        <TextContainer>
          <Text>2.</Text>
          <Text>
            Você deve estar ciente de que, de acordo com os regulamentos de combate à lavagem de dinheiro nas
            jurisdições de jogos de azar licenciados da União Europeia, somos obrigados a reter os dados de
            identificação do jogador fornecidos no cadastro e quaisquer dados transferidos durante o período de
            transição da conta do jogador por um mínimo de cinco anos a partir do último transação ou encerramento de
            conta. Solicitações de exclusão de dados antes da expiração desse período não serão concretizadas.
          </Text>
        </TextContainer>
      </div>
    </div>
  );
}
