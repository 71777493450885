import { Outlet } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import LogoButton from '../LogoButton';
import useLang from '../../lib/hook/useLang';
import * as config from '../../config'

const Container = tw.div`
  flex flex-row
  w-full h-screen
  overflow-hidden
`;

const AuthSection = tw.section`
  flex justify-center items-center
  w-full h-full T:w-[600px]
  px-[20px]
  bg-white
  z-50
`;

const ArtworkSection = tw.section`
  relative
  hidden T:flex
  flex-1 h-full
  bg-black
`;

const LogoButtonContainer = tw.div`
  absolute top-[65px] right-[55px]
`;

const MainArticle = tw.div`
  absolute left-[100px] bottom-[200px]
  w-[400px]
  text-white font-[500] text-[56px] leading-[74px]
`;

const SubArticle = tw.div`
  absolute left-[100px] bottom-[100px]
  w-[300px]
  text-white
`;

export default function RootLayout() {
  const { lngT } = useLang('auth:login');
  const lngtParam = Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING) ? 'racing' : 'powerball';

  return (
    <Container>
      <AuthSection>
        <Outlet />
      </AuthSection>
      <ArtworkSection>
        <LogoButtonContainer>
          <LogoButton />
        </LogoButtonContainer>
          <MainArticle>{lngT(`bg:${lngtParam}:1`)}</MainArticle>
          <SubArticle>{lngT(`bg:${lngtParam}:2`)}</SubArticle>      
        <div className="absolute -bottom-[410px] -left-[100px] w-[500px] h-[700px] bg-[rgba(132,0,203,0.78)] blur-[150px] transform rotate-[-32deg]" />
        <div className="absolute -bottom-[510px] left-[700px] w-[630px] h-[760px] bg-[#8400CB]/70 blur-[150px] transform rotate-[43deg]" />
        <div className="absolute -bottom-[410px] -left-[100px] w-[300px] h-[360px] bg-[#CDAEFF] blur-[125px] transform rotate-[37deg]" />
        <div className="absolute -bottom-[210px] left-[780px] w-[300px] h-[360px] bg-[rgba(212,185,255,0.8)] blur-[150px] transform rotate-[37deg]" />
      </ArtworkSection>
    </Container>
  );
}
