import React, { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';

export default function generateContainer<T>(url: string) {
  const Container = React.memo(function Container({ children, dev }: CointainerProps<T>) {
    const { data, error, isLoading, mutate } = useSWRImmutable<ResWithQueryMeta<T>>(url);

    const refresh = () => mutate();

    useEffect(() => {
      mutate();
    }, [mutate]);

    return (
      <>
        {children({
          isLoading: dev?.forceLoading ? true : isLoading,
          error: dev?.forceError ? true : error,
          data,
          refresh,
        })}
      </>
    );
  });

  return Container;
}
