import { useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components';

import * as config from '../config';

const Container = tw.div`
  absolute top-0 left-0 right-0 bottom-0
  [&>*]:w-full [&>*]:h-full
`;

const Landing = (
  <div
    style={{
      background: `${
        Number(config.SERVICE_TYPE) === Number(config.SERVICE_RACING)
          ? 'radial-gradient(115.05% 275.33% at 3.47% 8.73%, #4D0775 0%, #251337 22.71%, #040013 47.19%, #1A0229 65.21%, #2A033F 82.41%)'
          : '#000'
      }`,
    }}
  />
);

const Normal = <div className="bg-[#1C012E]" />;
const PowerBall = <div className="bg-[#171739] -z-30 relative" />;

const backgroundList = [
  {
    path: '/login',
    background: null,
  },
  {
    path: '/signup',
    background: null,
  },
  {
    path: '/password',
    background: null,
  },
  {
    path: '/mypage',
    background: Normal,
  },
  {
    path: '/contact/faq',
    background: Normal,
  },
  {
    path: '/contact/question',
    background: Normal,
  },
  {
    path: '/community/freeboard',
    background: Normal,
  },
  {
    //path: '/timetablewithstyle',
    path: '/timetable',
    background: Landing,
  },
  {
    path: '/introbet',
    background: Normal,
  },
  {
    path: '/powerballnumber',
    background: PowerBall,
  },
  {
    path: '/powerballbetting',
    background: PowerBall,
  },
  {
    path: '/event',
    background: Normal,
  },
];

export default function Background() {
  const { pathname } = useLocation();
  const bgItem = backgroundList.find(backgroundItem => pathname?.includes(backgroundItem.path));

  return <Container>{bgItem ? bgItem.background : Landing}</Container>;
}
