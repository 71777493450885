import * as config from '../../../src/config';
import * as define from '../../../src/define';
import { Unity, useUnityContext } from 'react-unity-webgl';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { SafeLog, GetAddHttpURL } from './jsUtil_combine';

import { Fragment } from 'react';
// import '../css/unitystyle.css';
import { useSelector, useDispatch } from 'react-redux';
var { webSocketIO } = require('../../socketClient.js');
//var { clientData } = require('../../clientData.js');

const UNITY_STATE_DEFAULT = 0;
const UNITY_STATE_INITIALIZE = 1;
const UNITY_STATE_RENDER = 2;
const GAME_PROGRESS = 3;
// const WINDOW_HEIGHT = 800;

var _unityProvider = undefined;
var _loadingProgression;
var _sendMessage;
var _addEventListener;
var _removeEventListener;
var soundOnVal = 0;
var _unityState = UNITY_STATE_DEFAULT;
var _isLoaded;
var _isGameOver = false;
var _unload;
var windowsizex = 0;
var windowsizey = 0; //'0%';
function SetXY(progress, isLoadingPage, needRender) {
  //console.log(`SetXY : progress=${progress} needRender=${needRender}`);

  if (config.TEST_PROGRESS_COMBINE) windowsizex = 100 + `%`;
  else windowsizex = _isGameOver ? 0 + `%` : 100 + `%`;

  var isLandscape = false;
  if (window.matchMedia('(orientation: portrait)').matches) {
  } else {
    isLandscape = true;
  }

  // INTRO BET
  if (progress <= 2) {
    //windowsizey = isLandscape ? window.innerWidth / 2 + 'px' : window.innerHeight / 2 + 'px';
    if (isLandscape) windowsizey = window.innerHeight * (2 / 3) + 'px';
    else windowsizey = window.innerWidth / 2 + 'px';
  }
  // GAME or REPLAY
  else {
    if (config.TEST_PROGRESS_COMBINE) {
      windowsizey = isLandscape ? window.innerHeight + 'px' : window.innerWidth - 80 + 'px';
    } else {
      if (_isGameOver) windowsizey = 0;
      else windowsizey = isLandscape ? window.innerHeight + 'px' : window.innerWidth - 80 + 'px';
    }
  }
}
//var _isLandscape = false;
var needRerender = null;
var clientData = {};
var callTime = 0;
export function UnityRender(props) {
  // clientData.user = useSelector((state) => state.user);
  // clientData.progress = useSelector((state) => state.progress);
  // clientData.lan = useSelector((state) => state.lan);
  // clientData.game = useSelector((state) => state.game);

  clientData = props.clientData;

  const [devicePixelRatio, setDevicePixelRatio] = useState(window.devicePixelRatio);
  const [_isOnPage, set_isOnPage] = useState(false);
  ///var urlparam = getCookie('urlparam');
  const [game, setGame] = useState(clientData.game);
  const [user, setUser] = useState(clientData.user);
  const [progress, setProgress] = useState(clientData.progress);
  //const[rerender,setrerender]=useState(false);
  //const [needLoading,setneedLoading] = useState(true);

  function handleClick() {
    _unload();
  }

  useEffect(() => {
    //document.documentElement.requestFullscreen();
    //console.log("document : " + document.body);
    //document.body.requestFullscreen();
    //document.exitFullscreen();
    //Screen.orientation.lock('landscape');

    set_isOnPage(true);
    SafeLog('USEEFFECT LOAD', config.LOG_DEVELOPER_1);

    return () => {
      console.log('unityrender return : ' + progress);

      set_isOnPage(false);
      SafeLog('USEEFFECT UNLOAD', config.LOG_DEVELOPER_1);
      // <LoadingPageTest></LoadingPageTest>
      if (_unload !== undefined && _isLoaded) {
        handleClick();
      }
    };
  }, []);

  // Init Unity wasm & data
  const needLoad = false;
  const MOBILE_BUILD_URL = 'Build_Mobile';
  const OTHER_BUILD_URL = 'Build_Other';
  var buildUrl = OTHER_BUILD_URL;
  // if (/iPhone|iPod|Android/i.test(navigator.userAgent))
  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    //if (/iPhone|iPod|Android/i.test(navigator.userAgent))
    buildUrl = MOBILE_BUILD_URL;
  }

  var useGZip = false;
  var zipString = useGZip ? '.gz' : '';
  //var fileserverURL = `http://192.168.0.29:7788/hfsfilefolder`;

  var streamfileserverURL = define.FILE_SERVER_URL; //`http://192.168.0.29:5502/files`;
  var fileserverURL = define.FILE_SERVER_URL; //`http://192.168.0.29:5502/files`;
  //var fileserverURL = `http://192.168.0.29:3000/files`;

  const { unload, unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression } =
    useUnityContext({
      loaderUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.loader.js`,
      dataUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.data${zipString}`,
      frameworkUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.framework.js${zipString}`,
      codeUrl: `${fileserverURL}/Build/${buildUrl}/Build/build_unity.wasm${zipString}`,
      streamingAssetsUrl: `${streamfileserverURL}/Build/${buildUrl}/StreamingAssets`,
      cacheControl: handleCacheControl,
      productName: 'upodium',
      productVersion: '1.0',
      companyName: 'racestudio',
    });

  _unityProvider = unityProvider;
  _sendMessage = sendMessage;
  _addEventListener = addEventListener;
  _removeEventListener = removeEventListener;
  _loadingProgression = loadingProgression;
  _isLoaded = isLoaded;
  _unityState = UNITY_STATE_INITIALIZE;
  _unload = unload;

  // console.log(
  //   `progresscombine UnityRender game:${game} _isLoaded:${_isLoaded} needRerender:${needRerender} props.rerender:${props.rerender} progress:${clientData.progress}`
  // );
  if(callTime === 0 && clientData.progress === define.PROGRESS_LIVE)
  {
    callTime = Date.now();
  }

  var introRemainTime = <IntroRemainSeconds></IntroRemainSeconds>;
  if (_isLoaded && (needRerender === null || props.rerender !== needRerender)) 
  {
    const executeTime = Date.now();
    var executetimediff = executeTime - callTime;    
    callTime = 0;

    _isGameOver = false;
    needRerender = props.rerender;
    console.log(
      `progresscombine UnityRender RestartGame needRerender:${needRerender} props.rerender:${props.rerender} progress : ${clientData.progress} isHistory : ${clientData.isHistory} oldprogress : ${clientData.oldprogress}`
    );
    let isIntro = needRerender === 0;

    if((clientData.progress === define.PROGRESS_REPLAY && !clientData.isHistory))
    {
      clientData.progress = define.PROGRESS_LIVE;
      console.log("force intro 1");
    }

    if((clientData.oldprogress === -1 && executetimediff > 2000))
    {
      clientData.oldprogress = 0;
      console.log("force intro 2");
    }

    RestartGame(
      isIntro || clientData.progress !== define.PROGRESS_REPLAY ? 0 : clientData.game,
      clientData.progress,
      clientData.lan
    );
    // introRemainTime = isIntro ? <IntroRemainSeconds></IntroRemainSeconds> : '';
  }

  // if (_isLoaded && props.setunityloaded != null) props.setunityloaded(true);

  var loadingInfo = _isLoaded ? '' : <LoadingPage progress={_loadingProgression} gameprogress={progress} />;
  // var introRemainTime = _isLoaded && progress === 1 ? <IntroRemainSeconds></IntroRemainSeconds> : '';

  SetXY(clientData.progress, false, props.rerender);

  var unityx = _isLoaded ? windowsizex : 0 + 'px';
  var unityy = _isLoaded ? windowsizey : 0 + 'px';

  function handleCacheControl(url) {
    if (url.match(/\.data/) || url.match(/\.wasm/) || url.match(/\.bundle/)) {
      return 'must-revalidate';
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return 'immutable';
    }
    return 'no-store';
  }

  return (
    <div>
      {loadingInfo}
      {/* <OnRUTest></OnRUTest> */}
      {/* <OnRUSoudOn></OnRUSoudOn> */}
      {introRemainTime}

      <OnURCallback setGameState={props.setGameState} game={game} user={user} progress={progress}></OnURCallback>
      <div className="scale-y-100">
        <Unity
          unityProvider={_unityProvider}
          scrolling="no"
          frameBorder={0}
          allowFullScreen
          style={{
            width: unityx,
            maxHeight: unityy,
            height: unityy,
            border: 0,
            padding: 0,
            margin: 0,
            left: 0,
            top: 0,

            // left: 30,
            // top: 30,
          }}
          //devicePixelRatio={devicePixelRatio}
        />
      </div>
    </div>
  );
}

function LoadingPage(props) {
  const [container, setcontainer] = useState(null);
  const [canvas, setcanvas] = useState(null);
  const [loadingBar, setloadingBar] = useState(null);
  const [progressBarFull, setprogressBarFull] = useState(null);
  var loadingbarcss;
  useEffect(() => {
    loadingbarcss = 'unity-loading-bar';
    setcontainer(document.querySelector('#unity-container'));
    setcanvas(document.querySelector('#unity-canvas'));
    setloadingBar(document.querySelector(`#${loadingbarcss}`));
    setprogressBarFull(document.querySelector('#unity-progress-bar-full'));

    if (progressBarFull != null) {
      progressBarFull.style.width = 100 * props.progress + '%';
      loadingBar.style.display = 'block';

      // if(props.gameprogress <= 2)
      //   loadingBar.style.top =  (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) ? 0+`%` : 10+`%`;
      // console.log("progress : " + props.progress);
    }
  }, [props.progress]);

  var winysizes = windowsizey.split('px');
  var winysize = winysizes[0] / 2;

  var loadinghtml = (
    <div
      id="unity-loading-bar"
      style={{
        top: winysize,
      }}
    >
      <div
        id="unity-Web_loading_2"
        style={{
          position: 'absolute',
          top: -20,
          left: -60,
        }}
      ></div>
      <div
        id="unity-Web_loading_1"
        style={{
          position: 'absolute',
          top: -20,
          left: -60,
        }}
      ></div>
      <div id="unity-progress-bar-empty">
        <div id="unity-progress-bar-full"></div>
      </div>
    </div>
  );

  return (
    <div>
      {/* <div id="unity-container" className="unity-desktop"> */}
      <canvas
        id="unity-canvas"
        style={{
          width: windowsizex,
          maxHeight: windowsizey,
          height: windowsizey,
          border: 0,
          padding: 0,
          margin: 0,
          left: 0,
          top: 0,

          // left: 30,
          // top: 30,
        }}
      ></canvas>
      {loadinghtml}
    </div>
  );
  // </div>
}

function OnURCallback(props) {
  const [userName, setUserName] = useState();
  const [isIntro, setIsIntro] = useState();
  const [endState, setendState] = useState();

  const handleGameOver = useCallback((username, isintro, endstate) => {
    console.log(`0927 LOGTEST : handleGameOver ${username} ${isintro} ${endstate}`);
    if (!isIntro) props.setGameState(endstate);

    _isGameOver = true;
    setUserName(username);
    setIsIntro(isintro);
    setendState(endstate);

    // if(isIntro)
    //   window.location.href = `/betting?game=${props.game}&user=${props.user}`;
  }, []);

  const handleFullScreen = useCallback(isFull => {
    if (window.matchMedia('(orientation: portrait)').matches && isFull) {
      //enterFullscreen();
      document.documentElement.style.transform = 'rotate(90deg)';
      document.documentElement.style.transformOrigin = 'left top';
    } else {
      //exitFullscreen();
      document.documentElement.style.transform = 'none';
      document.documentElement.style.transformOrigin = 'initial';
    }

    console.log('LOGTEST : handleFullScreen');
  }, []);

  useEffect(() => {
    _addEventListener('GameOver', handleGameOver);
    // _addEventListener("FullScreen", handleFullScreen);
    return () => {
      _removeEventListener('GameOver', handleGameOver);
      // _removeEventListener("FullScreen", handleFullScreen);
    };
  }, [_addEventListener, _removeEventListener, handleGameOver]);
  //}, [_addEventListener, _removeEventListener, handleGameOver, handleFullScreen]);

  return (
    <Fragment>
      {/* {_isGameOver === true ? 
      props.progress === 1 ? ChangeProgress(props.game, props.user, 2) : "" :
      ""} */}
    </Fragment>
  );
}

var changeValue = 0;
function OnRUTest() {
  return (
    <div>
      <button
        onClick={() => {
          console.log('Click!! : ' + changeValue);
          RUExample(changeValue);
          ++changeValue;
        }}
      >
        +
      </button>
    </div>
  );
}

function IntroRemainSeconds() {
  const [needUpdate, setneedUpdate] = useState(false);
  var remaintime = webSocketIO.data.counter; //webSocketIO.remaintime;
  
  useEffect(() => {
    const requestTerm = 1000;
    Interval();
    function Interval() {
      setTimeout(() => {
        // if(!savebetinfo.betable)
        // {
        //   // state가 항상 0이어서 전역변수 사용 함
        //   ChangeProgress(_game, _user, 3);
        // }

        // console.log('setneedUpdate ' + remaintime);
        setneedUpdate(true);
        Interval();

        return () => {
          clearTimeout();
        };
      }, requestTerm);
    }
  }, []);

  
  if (needUpdate && remaintime !== undefined) 
  {
    // console.log('react remaintime : ' + remaintime);
    RUMessage('ReactToUnityReceiver', 'IntroRemainSeconds', remaintime);
    setneedUpdate(false);
  }

  return <div></div>;
}

function OnRUSoudOn() {
  const myInput = useRef(null);

  const [time, setTime] = React.useState(0);
  function interval() {
    setTimeout(() => {
      setTime(prevTime => prevTime + 1); // <-- Change this line!
      console.log('Time Call');
      interval();
      ++soundOnVal;
      if (soundOnVal > 20) {
        myInput.current.click();
        //RUMessage("ReactToUnityReceiver", "SoundOn", "param test");
        console.log('Time Call 10!! Sound On!');
      }
    }, 1000);
  }

  useEffect(() => {
    interval();
    console.log('UnityRender Call in React');
    //RUMessage("ReactToUnityReceiver", "SoundOn", "param test");
  }, []);

  return (
    <div>
      <button
        onClick={() => {
          console.log('SoundOn');
          RUMessage('ReactToUnityReceiver', 'SoundOn', 'param test');
        }}
      >
        S
      </button>
      <input ref={myInput} />
    </div>
  );
}

const RestartGame = (game, progress, lan) => {
  // console.log(`RestartGame RestartGame ${game} ${progress} ${progress}`);
  // var urlparam = getCookie('urlparam');
  // const [game, setGame]=useState(urlparam.game.game);
  // const [user, setUser]=useState(urlparam.user.user);
  // const [progress, setProgress]=useState(urlparam.progress.progress);
  console.log(`progresscombine RestartGame game:${game} progress:${progress}`);
  _isGameOver = false;
  RURestartGame(game, progress, progress < GAME_PROGRESS ? 0 : define.GAMEPLAY_EVNETINDEX, lan);
  RUInit(game, progress, progress < GAME_PROGRESS ? 0 : define.GAMEPLAY_EVNETINDEX, lan);
};

const ReactToUnityReceiver = 'ReactToUnityReceiver';
export function RUExample(param) {
  RUMessage('ReactToUnityReceiver', 'FrameUpdate', param);
}

var useTimeSync = true;
var useRaceChangeLine = false;
var testHighQualityTimeSync = false;
// var minLoadingTime = 0;
var showresult1Delay = 4.0;
var showresult2Delay = 4.0; // 4.0 -> 5.0

export function RURestartGame(racingCount, progress, eventIndex, lang) {
  //http://192.168.0.33:8080/api/v1/raceprogress?id=1890
  //http://192.168.0.33:8080/api/v1/racedetail?id=1890

  console.log(
    `RURestartGame IEnumUTOSracedetail useRaceChangeLine:${useRaceChangeLine} progress : ${progress} racingCount:${racingCount}`
  );

  const tempUseTimeSync = useTimeSync && !(clientData.oldprogress === -1);
  var fullurl = GetAddHttpURL() + '/';
  var unitygameserverurl = fullurl;
  var parameters = `?racingCount=${racingCount}&eventIndex=${eventIndex}&servertype=${config.SERVERTYPE}&gameserverurl=${unitygameserverurl}&raceprogress=api/v1/raceprogress&racedetail=api/v1/racedetail&gamedata=api/v1/gamedata&debugLV=${config.DEBUG_LV}&progress=${progress}&useTimeSync=${tempUseTimeSync}&useRaceChangeLine=${useRaceChangeLine}&showresult1Delay=${showresult1Delay}&showresult2Delay=${showresult2Delay}&nation=${lang}`;

  var testTimeGap = -45;
  var mobileTimeDiffMulti = 4.0;
  var timeScaleMin = 2;
  var timeScaleMax = 100;
  var parameters_debug = `testHighQualityTimeSync=${testHighQualityTimeSync}&testTimeGap=${testTimeGap}&mobileTimeDiffMulti=${mobileTimeDiffMulti}&timeScaleMin=${timeScaleMin}&timeScaleMax=${timeScaleMax}`;

  var finalParams = `${parameters}&${parameters_debug}`;
  //console.log("LOGTEST : RUInit");

  RUMessage('ReactToUnityReceiver', 'RuRestartGame', finalParams);
}

export function RUInit(racingCount, progress, eventIndex, lang) {
  console.log(
    `RUInit IEnumUTOSracedetail useRaceChangeLine:${useRaceChangeLine} progress : ${progress} racingCount:${racingCount}`
  );
  //console.log('RUInit useRaceChangeLine : ' + useRaceChangeLine);
  //http://192.168.0.33:8080/api/v1/raceprogress?id=1890
  //http://192.168.0.33:8080/api/v1/racedetail?id=1890
  const tempUseTimeSync = useTimeSync && !(clientData.oldprogress === -1);
  var fullurl = GetAddHttpURL() + '/';
  var unitygameserverurl = fullurl;
  var parameters = `?racingCount=${racingCount}&eventIndex=${eventIndex}&servertype=${config.SERVERTYPE}&gameserverurl=${unitygameserverurl}&raceprogress=api/v1/raceprogress&racedetail=api/v1/racedetail&gamedata=api/v1/gamedata&debugLV=${config.DEBUG_LV}&progress=${progress}&useTimeSync=${tempUseTimeSync}&useRaceChangeLine=${useRaceChangeLine}&showresult1Delay=${showresult1Delay}&showresult2Delay=${showresult2Delay}&nation=${lang}`;

  var testTimeGap = -45;
  var mobileTimeDiffMulti = 4.0;
  var timeScaleMin = 2;
  var timeScaleMax = 200;
  var parameters_debug = `testHighQualityTimeSync=${testHighQualityTimeSync}&testTimeGap=${testTimeGap}&mobileTimeDiffMulti=${mobileTimeDiffMulti}&timeScaleMin=${timeScaleMin}&timeScaleMax=${timeScaleMax}`;

  var finalParams = `${parameters}&${parameters_debug}`;
  //console.log("LOGTEST : RUInit");

  RUMessage('ReactToUnityReceiver', 'RUInit', finalParams);
}

function RUMessage(gameObjectName, funcName, param) {
  //console.log("LOGTEST : _sendMessage");
  _sendMessage(gameObjectName, funcName, param);
}

function URMessage(gameObjectName, funcName, param) {
  _sendMessage(gameObjectName, funcName, param);
}
