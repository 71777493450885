import { useEffect } from 'react';

import tw from 'tailwind-styled-components';
import BettingResultDisplay from '../../../components/powerball/BettingResultDisplay';

import PowerBallCanvas from '../canvas/PowerBallCanvas';
import BettingHeader from '../../../components/powerball/BettingHeader';

import Line from '../../../img/powerball/b_line.png';

import { PowerBallZoomCanvas } from '../canvas/PowerBallCanvas';
import { useDispatch } from 'react-redux';
import { useResultStore } from '@/store/powerball';
import usePowerBallSocket from '../usePowerBallSocket';
import PowerBallModal from '../../../components/powerball/PowerBallModal';
import { usePowerModalStore } from '@/store/powerball';
import BettingResultModal from '../../../components/powerball/BettingResultModal';
import { getTime } from '@/lib/util';

import SoundManager from '../sound/soundManager';
import BettingHistoryBoard from '../../../components/powerball/BettingHistoryBoard';
import BettingPadBoard from '../../../components/powerball/component/betting/BettingPadBoard';
import { DispatchCoinType } from '../../../components/gameui/goods';
import { setCookie, getCookie } from '../../../lib/cookie';
// import useCustomNavigate from '@/lib/hook/useCustomNavigate';
import { useNavigate } from 'react-router-dom';
var curState = 0;

const GameBoard = tw.div`
  relative max-[1024px]:w-full max-w-[1000px] w-full h-[960px]
  rounded-2xl
  py-2 space-y-5
  max-[1440px]:h-fit
  max-[768px]:py-0  max-[768px]:space-y-3
  max-[480px]:px-0 max-[480px]:rounded-none
`;

const PadBoard = tw.div`
  w-[430px] h-[1058px]
  rounded-2xl
  max-[1440px]:w-[1000px] max-[1440px]:h-fit
  max-[1024px]:w-[98%]
  flex flex-col justify-start items-center
  overflow-hidden
  max-[768px]:w-[100%]
`;

const HistoryBoard = tw.div`
  relative w-full h-[386px] py-6
  max-[1440px]:h-fit
  max-[1440px]:py-0
`;

const Canvas = tw.div`
  w-full h-[500px] bg-white
  rounded-2xl border-4 border-[#FFC801]
  overflow-hidden
  flex justify-end items-end
  max-[1024px]:h-[420px]
  max-[768px]:h-[360px]
  max-[640px]:h-[320px]
  max-[580px]:h-[260px]
  max-[480px]:h-[240px]
  max-[420px]:h-[200px]
  max-[380px]:h-[180px]
  max-[360px]:h-[160px]
  max-[320px]:h-[140px]
  max-[280px]:h-[120px]
`;

export default function PowerBallBetting() {
  const navigate = useNavigate();
  const { isPowerballModalOpen } = usePowerModalStore();
  const { isResultPopupOpen } = useResultStore();
  let callUseDispatch = useDispatch();
  const {
    canBet,
    count,
    round,
    openSuccess,
    rewardOddEven,
    historyOddEven,
    resPowerballFromHash,
    setRewardOddEven,
    connectSocket,
    ReqOddsCondition,
    // setGoodsType,
  } = usePowerBallSocket('betting');

  var urlparam = getCookie('urlparam');
  const goodsType = urlparam !== undefined ? urlparam.goodsType : 0;
  const CallDispatch = val => {
    // console.log('set CURFRAME : ' + val);
    callUseDispatch({
      type: 'CURFRAME',
      curState: val,
    });
  };

  const CallDispatchSFX = val => {
    callUseDispatch({
      type: 'CURFRAMESFX',
      curSFX: val,
    });
  };

  useEffect(() => {
    DispatchCoinType(callUseDispatch, goodsType);    
    const closeSocket = connectSocket();    
    return () => closeSocket();
  }, []);



  return (
    <div className="max-[1024px]:w-[98%] max-[1024px]:mx-2 max-[1440px]:max-w-[1000px] h-full mx-auto LD:mx-[128px] max-[768px]:my-4 my-[100px] overflow-x-hidden overflow-y-hidden flex flex-row justify-center items-center space-x-4 max-[1440px]:flex-col max-[1440px]:space-y-4 max-[1440px]:space-x-0 ">
      <div className="w-[1000px] max-[1024px]:w-[98%] space-y-4">
        <BettingHeader count={count} round={round} formattedToday={getTime()} />
        <GameBoard>
          <img src={Line} alt="Line" className="w-full object-fit absolute z-[1] max-[768px]:hidden " />
          <Canvas className="relative">
            <SoundManager bgm={1}></SoundManager>
            <PowerBallZoomCanvas
              callUseDispatch={callUseDispatch}
              curState={curState}
              CallDispatch={CallDispatch}
              CallDispatchSFX={CallDispatchSFX}
              whiteballShowState={8}
            ></PowerBallZoomCanvas>
            <PowerBallCanvas
              callUseDispatch={callUseDispatch}
              curState={curState}
              CallDispatch={CallDispatch}
              CallDispatchSFX={CallDispatchSFX}
              ReqOddsCondition={ReqOddsCondition}
              rewardOddEven={rewardOddEven}
              setRewardOddEven={setRewardOddEven}
              gameType={1}
              navigate={navigate}
            ></PowerBallCanvas>
            <BettingResultDisplay isResultPopupOpen={isResultPopupOpen} canBet={canBet} />
          </Canvas>
          <img src={Line} alt="Line" className="w-full object-fit absolute z-[1] max-[1440px]:hidden" />
          <HistoryBoard>
            <BettingHistoryBoard historyOddEven={historyOddEven} resPowerballFromHash={resPowerballFromHash} />
          </HistoryBoard>
        </GameBoard>
      </div>
      <PadBoard>
        <BettingPadBoard goodsType={goodsType} canBet={canBet} rewardOddEven={rewardOddEven} />
      </PadBoard>

      {/* result popup */}
      {isResultPopupOpen && (
        <BettingResultModal isOpen={isResultPopupOpen} round={round} rewardOddEven={rewardOddEven} />
      )}
      {/* {<BettingResultModal isOpen={true} round={round} rewardOddEven={rewardOddEven} />} */}
      {/* success popup*/}
      {isPowerballModalOpen && <PowerBallModal isOpen={openSuccess} submit={true} />}
      {/* block popup*/}
      {isPowerballModalOpen && <PowerBallModal isOpen={isPowerballModalOpen} />}
    </div>
  );
}
